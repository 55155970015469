import React from 'react';

import styled from 'styled-components/macro';
import Text from '../../../../components/style/text.component';
import Button from '../../../../components/forms/button.component';
import SectionTitle from './shared/section-title.component';

import useLocale from '../../../../utils/locale/locale.hook';
import { getLocation } from '../../../../utils/geolocation.utils';
import colors from '../../../../themes/colors-v2.theme';

interface LocationProps {
  onNext: (locationOptin: boolean) => void;
}

export default function Location(props: LocationProps) {
  const locale = useLocale();

  const activationHandler = (choice: 'activate' | 'skip') => {
    if (choice === 'activate') {
      getLocation((data) => {
        const locationOptin = Object.keys(data);
        props?.onNext(locationOptin.length > 0);
      });
    } else {
      props?.onNext(false);
    }
  };

  return (
    <FormWrapper>
      <ContentWrapper>
        <Text style={{ marginBottom: 16 }}>{locale('signupt.location.text')}</Text>
        <MarginWrapper>
          <Button
            text={locale('signup.location.authorize.title')}
            onClick={() => activationHandler('activate')}
            style={{
              width: '100%',
              borderRadius: 8,
              color: '#000',
              backgroundColor: 'white',
              border: '1px solid white',
            }}
          />
        </MarginWrapper>
        <MarginWrapper>
          <Button
            text={locale('global.skip')}
            onClick={() => activationHandler('skip')}
            style={{ width: '100%', borderRadius: 8 }}
          />
        </MarginWrapper>
      </ContentWrapper>
    </FormWrapper>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const MarginWrapper = styled.div`
  margin-bottom: 24px;

  & > * {
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
  width: 369px;
`;
