import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { debounce } from 'lodash';

export const debouncedCitySearch = (mapProvider: OpenStreetMapProvider, setAddressAutocomplete) => {
  return debounce(async (citySearch) => {
    const results = await mapProvider.search({
      query: citySearch,
    });

    const cityResults = results.filter(result => result.raw && ['city', 'town', 'village', 'hamlet'].includes(result.raw.addresstype));
    setAddressAutocomplete(cityResults);
  }, 500);
};

export const getCityFromPosition = async (latitude: number, longitude: number) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );

    const data = await response.json();
    return data.address?.village ?? data.address?.town ?? data.address?.city ?? data.address?.municipality ?? '';
  } catch (e) {
    console.error(e);
  }
};

export const debouncedAddressSearch = (mapProvider: OpenStreetMapProvider, setAddressAutocomplete) => {
  return debounce(async (address: string) => {
    const results = await mapProvider.search({
      query: address,
    });

    setAddressAutocomplete(results);
  }, 500);
};
