import React, { useState } from "react";
import styled from "styled-components/macro";
import Text from "../../../../components/style/text.component";
import useLocale from "../../../../utils/locale/locale.hook";

import RatingBar from "../rating-bar.component";
import Stepper from "../stepper.comonent";

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  width: 101%;
  height: 101%;
  filter: blur(4px);
  background: linear-gradient(180.05deg, #fedd63 -52.94%, #000000 99.96%);
`;

const Top = styled.div`
  width: 100%;
  height: 40%;
  margin-bottom: 50px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const ContinueButton = styled.div`
  width: 142px;
  border: 2px solid white;
  color: white;
  padding: 5px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled(Text)`
  font-size: 22px;
  line-height: 33px;
`;

const Step1 = (props) => {
  const locale = useLocale();
  return (
    <Container>
      <Background />
      <Top>
        {[1, 2, 3, 4, 5].map((el) => {
          return (
            <img
              alt=""
              src={`/assets/imgs/moderation/0-${el}.png`}
              style={{
                height: "100%",
                objectFit: "cover",
                width: "20%",
              }}
            />
          );
        })}
      </Top>
      <RatingBar clickable={false} />
      <Text h1 style={{ zIndex: 2 }}>
        {locale(`moderation.tutorial.title.1`)}
      </Text>
      <Description style={{ zIndex: 2 }}>
        {locale(`moderation.tutorial.description.1.1`)}
      </Description>
      <Description style={{ zIndex: 2 }}>
        {locale(`moderation.tutorial.description.1.2`)}
      </Description>
      <Bottom style={{ zIndex: 2 }}>
        <ContinueButton onClick={props.onClick}>
          <Text style={{ fontSize: "16px" }}>
            {locale(`moderation.tutorial.continue`)}
          </Text>
        </ContinueButton>
        <Stepper currentStep={1} />
      </Bottom>
    </Container>
  );
};

export default Step1;
