import styled from 'styled-components/macro';

import Text from '../../components/style/text.component';

import { useLogin } from '../../utils/auth.utils';
import useLocale from '../../utils/locale/locale.hook';

import fonts from '../../themes/fonts.theme';
import ProfileGridVertical from '../../components/miscellaneous/profile-grid-vertical.component';
import { useEffect, useState } from 'react';
import { getUserFavoredBy, getUserFavorites } from '../../services/favorite.service';
import { FancyScrollbar } from '../../components/style/fancy-scrollbar.style';
import { CustomGrid } from '../nearby/components/custom-grid.component';
import { NoFavorites } from './components/no-favorites.component';
import { Flex } from '../../components/style/flex.component';
import { useMediaQuery } from 'react-responsive';

export enum ModalMode {
  FAVORITES = 'FAVORITES',
  FAVORITES_ONLINE = 'FAVORITES_ONLINE',
  FAVORED_BY = 'FAVORED_BY',
}

const UserFavorites = ({ mode }) => {
  const locale = useLocale();
  const { me } = useLogin();

  const [favorites, setFavorites] = useState([]);
  const [favoritesOnline, setFavoritesOnline] = useState([]);
  const [favoredBy, setFavoredBy] = useState([]);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  useEffect(() => {
    if (mode === ModalMode.FAVORITES) {
      // DEBUG: pagination
      getUserFavorites(me.id, 100, 0).then((favorites) => {
        if (Array.isArray(favorites)) {
          setFavorites(favorites);
        }
      });
    }
    if (mode === ModalMode.FAVORITES_ONLINE) {
      getUserFavorites(me.id, 100, 0).then((favorites) => {
        if (Array.isArray(favorites)) {
          const favoritesOnline = favorites.filter((favorite) => favorite.status === 'online');
          setFavoritesOnline(favoritesOnline);
        }
      });
    } else {
      // DEBUG: pagination
      getUserFavoredBy(me.id, 100, 0).then((admirers) => {
        if (Array.isArray(admirers)) {
          setFavoredBy(admirers);
        }
      });
    }
  }, [mode]);

  return (
    <MainWrapper>
      {mode === ModalMode.FAVORITES ? (
        favorites.length > 0 ? (
          <CustomGrid
            users={favorites}
            cellType={isDesktop ? 'bigSquare' : 'rectangle'}
            showBoostAd
            premiumTitle={locale('viewers.premium.title')}
            showSubscribeAd={false}
          />
        ) : (
          <Flex alignItems="center" justify="center" style={{ height: '100%' }}>
            <NoFavorites />
          </Flex>
        )
      ) : mode === ModalMode.FAVORITES_ONLINE ? (
        favoritesOnline.length > 0 ? (
          <CustomGrid
            users={favoritesOnline}
            cellType={isDesktop ? 'bigSquare' : 'rectangle'}
            showBoostAd
            premiumTitle={locale('viewers.premium.title')}
            showSubscribeAd={false}
          />
        ) : (
          <Flex alignItems="center" justify="center" style={{ height: '100%' }}>
            <NoFavorites />
          </Flex>
        )
      ) : favoredBy.length > 0 ? (
        <CustomGrid
          users={favoredBy}
          cellType={isDesktop ? 'bigSquare' : 'rectangle'}
          showBoostAd
          premiumTitle={locale('viewers.premium.title')}
          showSubscribeAd={false}
        />
      ) : (
        <Flex alignItems="center" justify="center" style={{ height: '100%' }}>
          <NoFavorites />
        </Flex>
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  ${FancyScrollbar}
`;

export default UserFavorites;
