import styled from 'styled-components/macro';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../utils/clickOutside.utils';
import useLocale from '../../../utils/locale/locale.hook';
import { useDispatch } from 'react-redux';
import ModalSlice from '../../../store/slices/modal.slice';
import { VideoCustom } from '../../messages/components/messages/video.component';

interface Props {
  user: any;
  video: any;
  isFirst?: boolean;
}

const ProfileVideo = (props: Props) => {
  const { user, video } = props;
  const [actionsVisible, setActionsVisible] = useState<boolean>(false);
  const locale = useLocale();
  const dispatch = useDispatch();

  const videoActionsMenuRef = useRef(null);
  useOnClickOutside([videoActionsMenuRef], () => setActionsVisible(false));

  return (
    <VideoContainer>
      <ActionsMenu visible={actionsVisible} ref={videoActionsMenuRef}>
        <ActionsItem
          onClick={() =>
            dispatch(
              ModalSlice.actions.setSubmitReport({
                visible: true,
                type: 'user',
                key: user?.id,
              })
            )
          }
        >
          <p>{`${locale('report.general')} ${user.pseudo}`}</p>
        </ActionsItem>
        <ActionsItem
          onClick={() =>
            dispatch(
              ModalSlice.actions.setBlockUser({
                visible: true,
                userId: user?.id ?? null,
              })
            )
          }
        >
          <p>{`${locale('block.general')} ${user.pseudo}`}</p>
        </ActionsItem>
      </ActionsMenu>

      {video.isPorn ? (
        <Iframe src={video.path} title={video.title} />
      ) : (
        <VideoCustom src={video.path} style={{ width: 300, borderRadius: 8 }} title={video.title} />
      )}
      {!props.isFirst && <VideoDescription>{video.title}</VideoDescription>}
    </VideoContainer>
  );
};

export default ProfileVideo;

const VideoContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
  margin: 16px 0;
  position: relative;
`;

const VideoDescription = styled.p`
  font-family: ${fonts.regular.name};
  font-weight: 500;
  font-size: 12px;
  color: white;
`;

const Iframe = styled.iframe`
  border: none;
  margin: 0;
  box-sizing: border-box;
  height: 224px;
`;

const ActionsMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
  width: 244px;
  top: 50px;
  right: 16px;
  padding: 4px 8px;
  background-color: #eee;
  border: solid 1px #ddd;
  z-index: 1;
`;

const ActionsItem = styled.div`
  border-radius: 4px;
  cursor: pointer;
  padding: 7px;

  &:hover {
    background-color: white;
  }

  & > p {
    margin: 0;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 12px;
    color: ${colors.darkerGrey};
  }
`;
