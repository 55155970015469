import { createSlice } from "@reduxjs/toolkit";

let initialState: {
  language: null;
  keys: Record<string, { message: string, description: string }>;
};

const LocaleSlice = createSlice({
  name: "locale",
  initialState: {
    ...initialState,
  },
  reducers: {
    setKeys: (state, { payload }) => ({
      ...state,
      keys: payload,
    }),
    setLanguage: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
    resetLocale: () => initialState,
  },
});

export default LocaleSlice;
