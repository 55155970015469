import { useState, useEffect } from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { updateUser } from '../../../../services/users.service';
import { useMediaQuery } from 'react-responsive';
import { useDebounce } from 'use-debounce';
import { StyledInput } from '../../../../components/forms/input.component';

type FirstnameProps = {
  me?: any;
  onChange?: () => void;
};

const Firstname = (props: FirstnameProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [firstname, setFirstname] = useState(props?.me?.firstname);
  const [debouncedValue] = useDebounce(firstname, 500);

  async function updateFirstname() {
    await updateUser({ firstname: debouncedValue });
    props?.onChange?.();
  }

  useEffect(() => {
    if (debouncedValue !== props?.me?.firstname) {
      updateFirstname();
    }
  }, [debouncedValue]);

  return (
    <>
      <StyledInput
        value={firstname}
        onChange={(e) => {
          setFirstname(e.target.value);
        }}
        placeholder={locale('profil_edit.no_firstname')}
      />
    </>
  );
};
export default Firstname;
