import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';
import useLocale from '../../utils/locale/locale.hook';

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: any;
  zIndex?: number;
}
const MobileFilterOverlay = (props: Props) => {
  const { setIsOpen, children, zIndex } = props;
  const locale = useLocale();
  return (
    <Container zIndex={zIndex}>
      <Header>
        <Close onClick={() => setIsOpen(false)}>
          <img alt="close" width="14" height="14" src={`/assets/icons/arrow-left-black.svg`} />
          <p>{locale('global.back')}</p>
        </Close>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'fit-content',
          }}
          onClick={() => console.log('Reset search')}
        >
          <img
            style={{ marginRight: '14px' }}
            alt=""
            width="16"
            height="16"
            src="/assets/icons/explore/icons-actions-refresh-dark.svg"
          />
          {locale('android.text.reset.password')}
        </div>
      </Header>
      {children}
    </Container>
  );
};

export default MobileFilterOverlay;

const Container = styled.div<{ zIndex: number }>`
  background-color: ${colors.backgroundLightGrey};
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
  padding: 21px 17px;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.darkGrey1};
  font-size: 14px;
  font-weight: ${fonts.bold.weight};
  padding-right: 12px;

  & p {
    margin: 0;
    padding: 0;
    color: ${colors.black};
    font-weight: ${fonts.semiBold.weight};
  }
`;
