import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import ModalTemplate from "../modal-template.component";
import Text from "../../style/text.component";
import Button from "../../forms-v2/button.component";
import { Loader } from "../../style/loader.component";

import { store } from "../../../store/configure.store";
import ModalSlice from "../../../store/slices/modal.slice";
import NavbarSlice from "../../../store/slices/navbar.slice";
import MeSlice from "../../../store/slices/me.slice";
import { activateAccount } from "../../../services/auth.service";
import useLocale from "../../../utils/locale/locale.hook";

enum ActivationStatus {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface ModalProps {
  visible: boolean;
}

export default function AccountActivation({ visible }: ModalProps) {
  const locale = useLocale();
  const history = useHistory();
  const [status, setStatus] = useState(ActivationStatus.LOADING);
  const [token, setToken] = useState("");

  const activationHandler = async () => {
    const res = await activateAccount({ token });
    if (res.success) {
      setStatus(ActivationStatus.SUCCESS);
      store.dispatch(NavbarSlice.actions.setShowAccountActivationBanner(false));
      store.dispatch(MeSlice.actions.updateMe({ accountActivated: true }));
      history.push("/");
    } else {
      setStatus(ActivationStatus.ERROR);
    }
  };

  useEffect(() => {
    if (!!token) {
      activationHandler();
    }
  }, [token]);

  useEffect(() => {
    if (visible) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      if (!token) {
        store.dispatch(ModalSlice.actions.setAccountActivationVisible(false));
      } else {
        setToken(token);
      }
    }
  }, [visible]);

  return (
    <ModalTemplate
      visible={visible}
      closeModal={() =>
        store.dispatch(ModalSlice.actions.setAccountActivationVisible(false))
      }
      canCloseModal={false}
      width="412px"
    >
      <ContentWrapper>
        {status === ActivationStatus.LOADING && (
          <>
            <Loader />
            <Text>{locale("email_activation.loading_message")}</Text>
          </>
        )}
        {status === ActivationStatus.SUCCESS && (
          <>
            <img
              alt=""
              src="/assets/icons/check-filled-white.svg"
              width="24"
              height="24"
            />
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              {locale("email_activation.email_validated")}
            </Text>
            <ButtonWrapper>
              <Button
                text="Clear"
                onClick={() =>
                  store.dispatch(
                    ModalSlice.actions.setAccountActivationVisible(false)
                  )
                }
              />
            </ButtonWrapper>
          </>
        )}
        {status === ActivationStatus.ERROR && (
          <>
            <img
              alt=""
              src="/assets/icons/error-filled-white.svg"
              width="24"
              height="24"
            />
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              {locale("email_activation.error.validation_failed")}
            </Text>
            <ButtonWrapper>
              <Button
                text="OK"
                onClick={() =>
                  store.dispatch(
                    ModalSlice.actions.setAccountActivationVisible(false)
                  )
                }
              />
            </ButtonWrapper>
          </>
        )}
      </ContentWrapper>
    </ModalTemplate>
  );
}

const ContentWrapper = styled.div`
  max-width: 330px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;

  & > * {
    margin: 12px 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 100px;
`;
