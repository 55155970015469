import { get } from "./utils.service";

export const getLanguages = async () => {
  const res = await get(`/languages`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSexualPractices = async () => {
  const res = await get(`/sexual-practices`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSexualRole = async () => {
  const res = await get(`/sexual-role`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getfacialHair = async () => {
  const res = await get(`/facial-hair`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getCommunities = async () => {
  const res = await get(`/communities`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getEthnicities = async () => {
  const res = await get(`/ethnicities`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getAppearances = async () => {
  const res = await get(`/appearances`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
