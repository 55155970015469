import { post, get, put, del } from './utils.service';

export const initiatePrivateConversation = async (contactId: string | number) => {
  const res = await get(`/chat/initiate-private-conversation/${contactId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserConversations = async (page: number | string, limit: number | string) => {
  const res = await get(`/chat/user-conversations?page=${page}&limit=${limit}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getConversation = async (conversationId: number | string) => {
  const res = await get(`/chat/conversations/${conversationId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const sendMessage = async (body: any) => {
  const res = await post('/chat/send-message', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const sendTyping = async (body: { conversationId: number }) => {
  const res = await post('/chat/typing', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const postConversationSeen = async (body: { conversationId: number }) => {
  const res = await post('/chat/conversation-seen', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getConversationMessages = async (conversationId: number | string, page: number, limit?: number) => {
  const res = await get(`/chat/conversation-messages/${conversationId}?page=${page}&limit=${limit ?? 20}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserMessagesNotSeen = async () => {
  const res = await get('/chat/user-messages-not-seen');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteConversation = async (conversationId: string | number) => {
  const res = await post(`/chat/delete-conversation/${conversationId}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getConversationMedias = async (conversationId: number | string, page: number | string) => {
  const res = await get(`/chat/conversation-medias/${conversationId}?page=${page}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

/* ******** QUICKSHARE ******** */

export const sendQuickshare = async (body: any) => {
  const res = await post('/chat/quickshare/send', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const sendQuickshareConfirmation = async (body: any) => {
  const res = await post('/chat/quickshare/sendConfirmation', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleQuickshareStatus = async (body: any) => {
  const res = await post('/chat/quickshare/toggle-status', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const checkQuickshare = async (body: { messageId: number | string }) => {
  const res = await post('/chat/quickshare/check', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSharedByOtherQuickshare = async () => {
  const res = await get('/chat/quickshare/users-shared-by');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSharedWithQuickshare = async () => {
  const res = await get('/chat/quickshare/users-shared-with');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getQuickshareUnseenCount = async () => {
  const res = await get('/chat/quickshare/unseen-count');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateQuickshareAccess = async (action: 'give' | 'revoke', shareWith: string) => {
  const res = await post(`/chat/quickshare/access/${action}`, { shareWith });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const revokeAllQuickshareAccess = async () => {
  const res = await del(`/chat/quickshare/revoke-all`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getQuickshare = async (otherUserId: string) => {
  const res = await get(`/chat/quickshare/${otherUserId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

/* ******* EPHEMERAL ******** */

export const sendEphemeral = async (body: any) => {
  const res = await post('/chat/ephemeral/send', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const checkEphemeral = async (body: { messageId: number | string }) => {
  const res = await post('/chat/ephemeral/check', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

/* ******** LOCATION ******** */

export const sendLocation = async (body: any) => {
  const res = await post('/chat/location/send', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

/* ******** WOOF ******** */

export const sendWoof = async (body: any) => {
  const res = await post('/chat/woof/send', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getWoofs = async (page: number | string = 0, limit: number | string = 20) => {
  const res = await get(`/chat/user-woofs?page=${page}&limit=${limit}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteWoof = async (messageWoofId: number) => {
  const res = await put(`/chat/woof/ack/${messageWoofId}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

/* ******** SHOUT-OUT ******** */

export const getShoutoutRecipients = async (body: any) => {
  const res = await post(`/chat/shoutout-recipients`, body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
