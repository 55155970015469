import React from "react";
import styled from "styled-components/macro";
import fonts from "../../themes/fonts.theme";
import Icons from "../style/icons.component";
import colors from "../../themes/colors-v2.theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid ${colors.lightGrey10};
  border-radius: 48px;
  background: ${colors.darkGray};
  width: 230px;
`;

const Input = styled.input<{ width?: string }>`
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background: inherit;
  margin-left: 8px;
  color: ${colors.lightGrey7};

  ::placeholder {
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 14px;
    color: ${colors.lightGrey7};
    letter-spacing: 0.1px;
  }

  ${(props) => props.width && `width: ${props.width}`}

  :focus {
    outline: none;
  }
`;

type SearchInputProps = {
  onChange: (text: string) => void;
  placeholder?: string;
  value?: string;
  width?: string;
  style?: React.CSSProperties;
};

const SearchBar = (props: SearchInputProps) => {
  return (
    <Container style={props.style}>
      <img
        src="/assets/icons/sidebar/navbar/search.svg"
        alt="radar"
        width={16}
        height={16}
      />
      <Input
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
      />
      <img
        src="/assets/icons/nearby/grey-cross.svg"
        alt="radar"
        width={16}
        height={16}
        onClick={() => props.onChange("")}
        style={{ paddingRight: 6, cursor: "pointer"}}
      />
    </Container>
  );
};

export default SearchBar;
