import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import PagesNavigation from '../src/pages/pages.navigation';
import { store } from './store/configure.store';
import ModalSlice from './store/slices/modal.slice';
import { setMe } from './utils/auth.utils';

import './App.css';
import { OpenStreetMapsProvider } from './context/openstreetmaps';

import "./i18n";
import { BillingParamsProvider } from './context/billing-params-context';

const mapParamToModalAction = {
  resetPassword: ModalSlice.actions.setResetPasswordVisible(true),
  accountActivation: ModalSlice.actions.setAccountActivationVisible(true),
};

function App() {
  const [loading, setLoading] = React.useState(true);

  const init = async () => {
    document.querySelector("[name='description']")?.remove();
    await setMe();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!loading) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('showModal')) {
        const storeAction = mapParamToModalAction[params.get('showModal')];
        if (storeAction) {
          store.dispatch(storeAction);
        }
      }
    }
  }, [loading]);

  return (
    <Provider store={store}>
      <BillingParamsProvider>
        <OpenStreetMapsProvider>{loading ? <></> : <PagesNavigation />}</OpenStreetMapsProvider>
      </BillingParamsProvider>
    </Provider>
  );
}

export default App;
