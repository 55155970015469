import React, { useState } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const Circle = styled("div")<{ color: string }>`
  width: 18px;
  height: 18px;
  background-color: ${(props) => (props.color ? props.color : "white")};
  border-radius: 50%;
  margin: 2px 7px; ;
`;

const Stepper = (props) => {
  return (
    <Container style={{ ...props.style }}>
      {[1, 2, 3, 4, 5, 6, 7].map((el) => {
        return <Circle color={props.currentStep >= el ? "white" : "#92929D"} />;
      })}
    </Container>
  );
};

export default Stepper;
