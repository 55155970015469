import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

let cachedHistoryStack = [location.pathname];

export const useRouterNavigationTracker = () => {
  const history = useHistory();

  const [historyStack, setHistoryStack] = useState(cachedHistoryStack);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      cachedHistoryStack = [location.pathname, ...cachedHistoryStack];
      setHistoryStack(cachedHistoryStack);
    });

    return () => unlisten();
  }, []);

  return historyStack;
};
