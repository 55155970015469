import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import Image from '../../../components/image.component';
import { updateQuickshareAccess } from '../../../services/chat.service';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { getStatusColor, Status } from '../../../utils/status.utils';
import { openProfile } from '../../user-profile/profile.utils';
import { Flex } from '../../../components/style/flex.component';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';

export interface SharedWithAlbum {
  createdAt: Date;
  status: string;
  updatedAt: Date;
  shared_with: User;
}

interface User {
  albums_checked_at: Date;
  pseudo: string;
  profilePicture: ProfilePicture;
  role: string;
  status: Status;
  id: string;
}

interface ProfilePicture {
  id: string;
  path: string;
  description: string | null;
  uploadDate: Date;
  order: number;
  moderationHive: string;
  moderationRating: string;
  moderationAdmin: string;
  adminCheck: boolean;
  moderation: string;
}

interface Props {
  sharedWithAlbum: SharedWithAlbum;
  refreshSharedWithAlbums: () => void;
}
export const SharedWithAlbum = (props: Props) => {
  const { sharedWithAlbum, refreshSharedWithAlbums } = props;
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const locale = useLocale();

  const [isLoading, setIsLoading] = useState(false);

  const revokeAlbumAccess = async () => {
    setIsLoading(true);
    await updateQuickshareAccess('revoke', sharedWithAlbum.shared_with?.id);
    refreshSharedWithAlbums();
    setIsLoading(false);
  };

  return (
    <Container isDesktop={isDesktop}>
      <Image
        src={
          sharedWithAlbum.shared_with?.profilePicture
            ? sharedWithAlbum.shared_with?.profilePicture.path
            : '/assets/imgs/no-profile-picture.svg'
        }
        alt={`Profile picture of ${sharedWithAlbum.shared_with.pseudo}`}
        width={'72px'}
        height={'72px'}
        onClick={() => {
          openProfile(sharedWithAlbum.shared_with?.pseudo, false);
        }}
      />
      <Flex direction="column" alignItems="flex-start" justify="flex-start" gap="4px">
        <Content>
          <StatusCircle color={getStatusColor(sharedWithAlbum.shared_with.status).centerCircle} />
          <Pseudo>{sharedWithAlbum.shared_with.pseudo}</Pseudo>
        </Content>
        <PrimaryButton
          onClick={() => revokeAlbumAccess()}
          disabled={isLoading}
          style={{ background: 'black', boxSizing: 'content-box' }}
        >
          <img src="/assets/icons/controls/bin-gray.svg" style={{ marginRight: 4 }} />
          {locale('shared.album.revoke.access')}
        </PrimaryButton>
      </Flex>
    </Container>
  );
};

const Container = styled.div<{
  isDesktop: boolean;
}>`
  display: flex;
  width: 350px;
  height: 95px;
  gap: 16px;
  overflow: hidden;
  border-bottom: 1px solid rgba(102, 112, 133, 0.2);

  & img {
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  width: fit-content;
  gap: 4px;
  align-items: center;
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  margin-left: 6px;
`;

const Pseudo = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: ${fonts.bold.weight};
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button<{ disabled: boolean }>`
  border: 1px solid ${({ disabled }) => (disabled ? colors.lightGrey11 : colors.darkOrange3)};
  background-color: ${({ disabled }) => (disabled ? colors.lightGrey11 : colors.darkOrange1)};
  color: ${({ disabled }) => (disabled ? colors.darkGrey2 : colors.white)};
  padding: 8px 14px;
  font-size: 14px;
  font-weight: ${fonts.bold.weight};
  outline: none;
  border-radius: 8px;
  cursor: pointer;
`;
