import React from "react";
import styled from "styled-components/macro";

const PicturesWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const LeftPanel = styled.div`
  width: calc(70% - 2.5px);
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightPanel = styled.div`
  width: calc(30% - 2.5px);
  position: absolute;
  height: 100%;
  right: 0px;
  top: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Image = styled("div")`
  // max-height: 100%;
  // background-size: cover;
  // min-width: 30%;
  // min-height: 80%;
`;

const PictureWrapperComponent = (props) => {
  return (
    <PicturesWrapper>
      <LeftPanel>
        <Row
          style={{
            height: "calc(52% - 1px)",
          }}
        >
          <Image
            style={{
              backgroundImage: `url("/assets/imgs/moderation/${
                props.step - 1
              }-1.png")`,
              height: "100%",
              width: "calc(33% - 5px)",
            }}
          />
          <Image
            style={{
              backgroundImage: `url("/assets/imgs/moderation/${
                props.step - 1
              }-2.png")`,
              height: "100%",
              width: "40%",
            }}
          />
          <Image
            style={{
              backgroundImage: `url("/assets/imgs/moderation/${
                props.step - 1
              }-3.png")`,
              height: "100%",
              width: "calc(27% - 5px)",
            }}
          />
        </Row>
        <Row style={{ height: "calc(47% - 1px)" }}>
          <Image
            style={{
              backgroundImage: `url("/assets/imgs/moderation/${
                props.step - 1
              }-5.png")`,
              height: "100%",
              width: "59%",
            }}
          />
          <Image
            style={{
              backgroundImage: `url("/assets/imgs/moderation/${
                props.step - 1
              }-6.png")`,
              height: "100%",
              width: "40%",
            }}
          />
        </Row>
      </LeftPanel>
      <RightPanel>
        <Image
          style={{
            backgroundImage: `url("/assets/imgs/moderation/${
              props.step - 1
            }-4.png")`,
            height: "100%",
          }}
        />
      </RightPanel>
    </PicturesWrapper>
  );
};

export default PictureWrapperComponent;
