import { Icon } from '@material-ui/core';
import styled from 'styled-components/macro';
import colors from '../../../../themes/colors-v2.theme';
import useLocale from '../../../../utils/locale/locale.hook';
import { TextOptions } from './text-option.component';
import { useEffect, useState } from 'react';

type WithPictureFilterProps = {
  onChange: (value) => void;
  value?: boolean;
  isDisabled?: boolean;
};

const WithPictureFilter = (props: WithPictureFilterProps) => {
  const [isWithPhotos, setIsWithPhotos] = useState(props.value ?? false);
  const locale = useLocale();

  const handleClick = () => {
    props.onChange(!isWithPhotos);
  };

  useEffect(() => {
    setIsWithPhotos(props.value ?? false);
  }, [props.value]);

  return (
    <Container onClick={() => !props.isDisabled && handleClick()}>
      <div style={{ marginRight: 8, display: 'flex' }}>
        {isWithPhotos && !props.isDisabled ? (
          <img src="/assets/icons/nearby/checkbox-blue.svg" alt="checkbox" />
        ) : (
          <img src="/assets/icons/nearby/checkbox.svg" alt="checkbox" width={24} height={24} />
        )}
      </div>
      <TextOptions disabled={false}>{locale('android.filter.profil_picture')}</TextOptions>
    </Container>
  );
};

export default WithPictureFilter;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0px;
  cursor: pointer;
  font-family: SegoeUI;
`;

const WhiteCircle = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;
