import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import useLocale from '../../utils/locale/locale.hook';
import Text from '../style/text.component';

interface Props {
  style?: any;
}

const NewLabel = (props: Props) => {
  const { style } = props;
  const locale = useLocale();

  return <Content style={style}>{locale('nearby.new')}</Content>;
};

export default NewLabel;

const Content = styled(Text)`
  height: 18px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${colors.white};
  padding: 2px 4px;
  font-family: 'Inter';
  font-weight: 600;
  background-color: ${colors.blue};
  border-radius: 5px;
  border: 1px solid ${colors.blue};
`;
