import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

import { Loader } from "../../components/style/loader.component";

interface DragDropProps {
  placeholder: string;
  onUpload: (files: File[]) => void;
  typesAccepted?: string[];
  loading?: boolean;
  allowMultipleFiles?: boolean; // permet l'upload de plusieurs fichiers
}

export default function Dropzone({
  placeholder,
  typesAccepted = ["image/jpeg", "image/jpg", "image/png"], // par défaut, uniquement des images
  onUpload,
  loading,
  allowMultipleFiles = true,
}: DragDropProps) {
  const [filename, setFilename] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      let name = acceptedFiles[0].name;
      setFilename(name);
      onUpload(acceptedFiles);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: typesAccepted.join(", "),
    multiple: allowMultipleFiles,
  });

  return (
    <div {...getRootProps()}>
      <DropZoneWrapper>
        <input {...getInputProps()} />
        {loading ? (
          <Loader />
        ) : (
          <>
            {isDragActive ? (
              <p>Drop your file(s) here !</p>
            ) : filename ? (
              <p className="filename">{filename}</p>
            ) : (
              <p style={{ textAlign: "center" }}>{placeholder}</p>
            )}
          </>
        )}
      </DropZoneWrapper>
    </div>
  );
}

const DropZoneWrapper = styled.div`
  border: 1px dashed #2f3338;
  background-color: rgba(47, 51, 56, 0.3);
  box-sizing: border-box;
  width: 100%;
  padding: 28px 35px;
  border-radius: 4px;
  cursor: pointer;
  min-height: 100px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > p {
    margin: 0;
    font-size: 12px;
    color: #fefffe;
    font-family: "Inter";
  }

  & > p.filename {
    font-size: 14px;
  }
`;
