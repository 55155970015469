import React from "react";
import styled from "styled-components/macro";
import colors from "../../themes/colors.theme";
import Icons from "../style/icons.component";
import Text from "../style/text.component";

const Container = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  background-color: ${colors.danger};
  width: calc(100% - 32px);
  padding: 16px;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 2px solid ${colors.danger};
  z-index: 10;
`;

type ErrorProps = {
  errorMsg: string;
  style?: object;
};

const Error = (props: ErrorProps) => {
  return (
    <Container style={props.style}>
      <Icons
        name="info-outlined-white"
        width="29"
        height="29"
        style={{ marginRight: 10 }}
      />
      <Text>{props.errorMsg}</Text>
    </Container>
  );
};

export default Error;
