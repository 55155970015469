import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';

export const Subtitle = styled.div`
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  color: #fcfcfd;
  flex: 1;
`;
