import React from 'react';
import styled from 'styled-components/macro';

import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';

type TextareaInputProps = {
  rows?: number;
  onChange: (text: string) => void;
  onBlur?: (text: string) => void;
  placeholder?: string;
  value?: string;
  style?: any;
  textareaStyle?: any;
  maxLength?: number;
  placeholderColor?: string;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
};

export default function TextareaInput(props: TextareaInputProps) {
  return (
    <Container style={props.style}>
      <Textarea
        rows={props?.rows || 1}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onBlur={(e) => (props?.onBlur ? props?.onBlur(e.target.value) : null)}
        placeholder={props.placeholder}
        style={props?.textareaStyle}
        maxLength={props?.maxLength}
        placeholderColor={props?.placeholderColor}
        resize={props?.resize}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Textarea = styled.textarea<{
  width?: number;
  placeholderColor?: string;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
}>`
  width: ${(props) => props.width || '100%'};
  min-height: 40px;
  max-height: 300px;
  outline: none;
  border-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  font-family: 'Inter';
  background: ${colors.darkGray};
  resize: ${(props) => props?.resize || 'none'};
  border: 1px solid ${colors.lightGrey10};
`;
