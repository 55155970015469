import styled from "styled-components";
import { CellType } from "../../nearby/nearby.screenv2";
import { RadarUser } from "../../nearby/components/radar-user.component";
import { Flex } from "../../../components/style/flex.component";
import Text from "../../../components/style/text.component";
import colors from "../../../themes/colors-v2.theme";
import useLocale from "../../../utils/locale/locale.hook";
import { useScrollDisplayArrows } from "../../../utils/useScroll";
import { useRef } from "react";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";
import { useMediaQuery } from "react-responsive";

type Props = {
  users: any[];
  onViewMore: () => void;
  title: string;
};

export const UsersCategory = ({ users, onViewMore, title }: Props) => {
  const locale = useLocale();
  const picturesRef = useRef(null);
  const {
    displayLeftArrow,
    displayRightArrow,
    handleScroll,
    handleArrowClick,
  } = useScrollDisplayArrows(picturesRef);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <Flex direction="column" gap="12px">
      <Flex
        gap="12px"
        alignItems="center"
        justify={isDesktop ? "flex-start" : "space-between"}
      >
        <Text bold fontSize="16px">
          {title}
        </Text>
        <Flex style={{ cursor: "pointer" }} onClick={onViewMore}>
          <Text
            fontSize="12px"
            color={isDesktop ? colors.lightGrey3 : colors.white}
            style={{ marginRight: 8 }}
          >
            {locale("global.see.more")}
          </Text>
          <img src="/assets/icons/nearby/chevron-right.svg" alt="chevron"></img>
        </Flex>
      </Flex>
      <Flex onScroll={handleScroll} style={{ position: "relative" }}>
        {displayLeftArrow ? (
          <PrimaryButton
            size="medium"
            isActive={true}
            style={{
              position: "absolute",
              left: 6,
              top: 56,
              zIndex: 10,
              transform: "rotate(180deg)",
              height: "36px",
              width: "36px",
              border: 0,
              boxShadow: "0px 0px 10px black",
            }}
            onClick={() => handleArrowClick("left")}
          >
            <img
              src="/assets/icons/nearby/chevron-right-white.svg"
              alt="chevron-right"
              width={12}
              height={12}
              style={{ marginRight: -2 }}
            />
          </PrimaryButton>
        ) : null}
        {displayRightArrow ? (
          <PrimaryButton
            size="medium"
            isActive={true}
            style={{
              position: "absolute",
              right: 6,
              top: 56,
              zIndex: 10,
              height: "36px",
              width: "36px",
              border: 0,
              boxShadow: "0px 0px 10px black",
            }}
            onClick={() => handleArrowClick("right")}
          >
            <img
              src="/assets/icons/nearby/chevron-right-white.svg"
              alt="chevron-right"
              width={12}
              height={12}
              style={{ marginLeft: 2 }}
            />
          </PrimaryButton>
        ) : null}
        <GridContainer
          cellType="rectangle"
          ref={picturesRef}
          isDesktop={isDesktop}
        >
          {users?.map((user: any, index: number) => {
            return (
              <div key={`${user?.id}-${index}`}>
                <RadarUser height="148px" width="110px" user={user} />
              </div>
            );
          })}
        </GridContainer>
      </Flex>
    </Flex>
  );
};

const GridContainer = styled.div<{ cellType: CellType; isDesktop: boolean }>`
  display: grid;
  grid-template-columns: repeat(10, 110px);
  grid-auto-rows: 148px;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: ${(props) => (props.isDesktop ? "calc(100vw - 250px - 33px)" : "")};

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
