import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";

import Text from "../style/text.component";
import Button from "../forms/button.component";
import PhotoEditor from "../photos/photo-editor/photo-editor.component";
import PhotoSelection from "../photos/photo-selection/photo-selection.component";
import ModalTemplate from "../miscellaneous/modal-template.component";

import useLocale from "../../utils/locale/locale.hook";
import { uploadFile } from "../../utils/s3.utils";
import { addPictures } from "../../services/pictures.service";
import { setProfilePicture } from "../../services/users.service";
import { setMe, useLogin } from "../../utils/auth.utils";
import ModalSlice from "../../store/slices/modal.slice";

import colors from "../../themes/colors.theme";

interface ProfilePictureModerationProps {
  visible: boolean;
}

export default function ProfilePictureModeration(
  props: ProfilePictureModerationProps
) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();
  const [file, setFile] = useState(null);
  const [fileBeforeCrop, setFileBeforeCrop] = useState(null);
  const [preview, setPreview] = useState(null);
  const [photoSelectionVisible, setPhotoSelectionVisible] = useState(false);
  const [sendingLoader, setSendingLoader] = useState(false);
  const [error, setError] = useState("");

  const submitNewPhoto = async () => {
    setError("");
    setSendingLoader(true);
    const res: any = await uploadFile(file);
    if (res.error) {
      setError(locale(res.error));
      setSendingLoader(false);
      return;
    }

    const pictures = await addPictures([
      {
        path: res.path as string,
        album: me.albums.find((album) => album.type === "myPhotos"),
      },
    ]);

    if (pictures.error) {
      setError(locale(pictures.error));
      setSendingLoader(false);
      return;
    }

    await setProfilePicture(pictures[0].id);
    await setMe();
    dispatch(ModalSlice.actions.setModerationProfilePictureVisible(false));
  };

  const getFile = () => {
    setPhotoSelectionVisible(true);
  };

  if (!props.visible) return <></>;

  return (
    <ModalTemplate
      visible={props.visible}
      canCloseModal={false}
      width="751px"
      style={{
        height: 451,
      }}
      zIndex={500}
    >
      <Content>
        <ContentModal>
          <ColumnFirst>
            <Picture onClick={getFile}>
              {preview ? (
                <ImageProfile>
                  <img
                    alt=""
                    style={{ borderRadius: 4 }}
                    src={preview}
                    width="195"
                  />
                  <Text
                    primary
                    style={{ position: "absolute", bottom: -30, fontSize: 16 }}
                  >
                    {locale("signup.picture.modify")}
                  </Text>
                </ImageProfile>
              ) : (
                <ImageAdd
                  alt=""
                  src="/assets/imgs/signup/profile-picture-add.svg"
                />
              )}
            </Picture>
          </ColumnFirst>
          <Column>
            <Title h2 style={{ maxWidth: 255, fontSize: 20 }}>
              {locale("moderation.photo.title")}
            </Title>
            <Explanation>{locale("moderation.photo.explanation")}</Explanation>
            <Examples>{locale("signup.picture.these_good")}</Examples>
            <ImagesExemple>
              <ImageExample>
                <img
                  alt=""
                  style={{ borderRadius: 4 }}
                  src="/assets/imgs/signup/example-1.png"
                  width="60"
                  height="60"
                />
              </ImageExample>
              <ImageExample>
                <img
                  alt=""
                  style={{ borderRadius: 4 }}
                  src="/assets/imgs/signup/example-2.png"
                  width="60"
                  height="60"
                />
              </ImageExample>
              <ImageExample>
                <img
                  alt=""
                  style={{ borderRadius: 4 }}
                  src="/assets/imgs/signup/example-3.png"
                  width="60"
                  height="60"
                />
              </ImageExample>
              <ImageExample>
                <img
                  alt=""
                  style={{ borderRadius: 4 }}
                  src="/assets/imgs/signup/example-4.png"
                  width="60"
                  height="60"
                />
              </ImageExample>
            </ImagesExemple>
          </Column>
        </ContentModal>
        <ButtonSend>
          <Button
            onClick={() => submitNewPhoto()}
            loading={sendingLoader}
            disabled={!file}
            text={locale("moderation.photo.submit")}
            color="primary"
          />
        </ButtonSend>
        {error && <ErrorMessage color="#e91817">{error}</ErrorMessage>}
      </Content>
      {fileBeforeCrop && (
        <PhotoEditor
          photo={URL.createObjectURL(fileBeforeCrop)}
          onDone={(file) => {
            setPreview(URL.createObjectURL(file));
            setFile(file);
            setFileBeforeCrop(null);
          }}
          onClose={() => setFileBeforeCrop(null)}
        />
      )}
      {photoSelectionVisible && (
        <PhotoSelection
          onClose={() => setPhotoSelectionVisible(false)}
          onDone={(file) => setFileBeforeCrop(file)}
        />
      )}
    </ModalTemplate>
  );
}

const Content = styled.div`
  padding: 39px 60px;
  height: calc(100% - 39px - 39px);
  width: calc(100% - 60px - 60px);
  @media (max-width: 700px) {
    padding: 20px 30px;
    height: calc(100% - 20px - 20px);
    width: calc(100% - 30px - 30px);
  }
  @media (max-width: 500px) {
    padding: 10px 12px;
    height: calc(100% - 10px - 10px);
    width: calc(100% - 12px - 12px);
  }
`;

const ContentModal = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 690px) {
    flex-direction: column-reverse;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageExample = styled.div`
  width: 65px;
  height: 65px;
  border: 2px solid ${colors.primary};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  @media (max-width: 690px) {
    margin-right: 7px;
  }
`;

const ImageProfile = styled.div`
  width: 210px;
  height: 210px;
  border: 4px solid ${colors.primary};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
`;

const ImagesExemple = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Text)`
  margin-bottom: 15px;
  color: ${colors.darkGrey};
  @media (max-width: 690px) {
    margin-bottom: 7px;
  }
`;

const Explanation = styled(Text)`
  margin-bottom: 40px;
  color: ${colors.darkGrey};
  @media (max-width: 690px) {
    margin-bottom: 12px;
  }
`;

const Examples = styled(Text)`
  margin-bottom: 15px;
  color: ${colors.darkGrey};
  @media (max-width: 690px) {
    margin-bottom: 12px;
  }
`;

const ErrorMessage = styled(Text)`
  position: absolute;
  bottom: 10px;
  right: 50px;
  margin-bottom: 16px;
`;

const ButtonSend = styled.div`
  width: 240px;
  position: absolute;
  bottom: 50px;
  right: 150px;
  @media (max-width: 690px) {
    bottom: 40px;
    right: 70px;
  }
  @media (max-width: 500px) {
    bottom: 62px;
    right: -52px;
  }
`;

const ImageAdd = styled.img`
  width: 238px;
  @media (max-width: 690px) {
    width: 138px;
  }
  @media (max-width: 500px) {
    width: 88px;
  }
`;

const Picture = styled.div`
  cursor: pointer;
`;

const ColumnFirst = styled.div`
  margin-right: 52px;
  margin-top: 60px;
`;
