import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import * as TwilioVideo from "twilio-video";
import {
  leaveVideoCall,
  reportUserDisconnection,
} from "../../services/video-call.service";

const VideoCallUsers = ({ room }: { room: TwilioVideo.Room }) => {
  const { videoCall } = useSelector((state: any) => state.modalStore);

  useEffect(() => {
    if (videoCall.callId && room) {
      return setupRoom(videoCall.callId, room);
    }
    return () => {};
  }, [videoCall, room]);

  useEffect(() => {
    displaySelfVideo();
  }, []);

  return <VideoContainer id="users" />;
};

const trackSubscribed = (div, track) => {
  const trackElement = track.attach();
  div.appendChild(trackElement);
};

const trackUnsubscribed = (track) => {
  track.detach().forEach((element) => {
    element.remove();
  });
};

const addUserTrackDiv = () => {
  const tracksDiv = document.createElement("div");
  const participantsElement = document.getElementById("users");
  participantsElement.appendChild(tracksDiv);
  return tracksDiv;
};

const displaySelfVideo = async () => {
  const videoTrack = await TwilioVideo.createLocalVideoTrack();
  const tracksDiv = addUserTrackDiv();
  trackSubscribed(tracksDiv, videoTrack);
};

const setupRoom = (videoCallId: number, room: TwilioVideo.Room) => {
  room.participants.forEach(participantConnected);
  room.on("participantConnected", participantConnected);
  room.on("participantDisconnected", participantDisconnected(videoCallId));

  const onWindowClose = () => {
    room.disconnect();
    leaveVideoCall(videoCallId);
  };

  window.addEventListener("beforeunload", onWindowClose);
  window.addEventListener("pagehide", onWindowClose);

  return () => {
    window.removeEventListener("beforeunload", onWindowClose);
    window.removeEventListener("pagehide", onWindowClose);
    room.removeAllListeners();
  };
};

const participantConnected = (participant: TwilioVideo.RemoteParticipant) => {
  const tracksDiv = addUserTrackDiv();
  tracksDiv.setAttribute("id", participant.sid);
  tracksDiv.setAttribute("class", "other");

  participant.tracks.forEach((publication) => {
    if (publication.isSubscribed) {
      trackSubscribed(tracksDiv, publication.track);
    }
  });

  participant.on("trackSubscribed", (track) =>
    trackSubscribed(tracksDiv, track)
  );
  participant.on("trackUnsubscribed", trackUnsubscribed);
};

const participantDisconnected =
  (videoCallId: number) => (participant: TwilioVideo.RemoteParticipant) => {
    reportUserDisconnection(videoCallId);
    document.getElementById(participant.sid).remove();
  };

const VideoContainer = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  margin: 12px 0;
  max-height: calc(100vh - 160px);

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  > div > video {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    display: flex;
    position: relative;
    max-height: unset;
    margin: 0;

    > div:not(.other) {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 100px;
      > video {
        max-width: 100px;
      }
    }

    > div.other {
      width: 100%;
      height: 100%;
      > video {
        max-width: 100%;
        aspect-ratio: 4/3;
        object-fit: cover;
        height: 100%;
      }
    }
  }
`;

export default VideoCallUsers;
