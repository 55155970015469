import React from 'react';
import styled from 'styled-components/macro';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import colors from '../../themes/colors-v2.theme';
import useLocale from '../../utils/locale/locale.hook';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Content = styled.div`
  width: calc(100% - 16px);
  position: relative;
`;

const MinMaxValues = styled.div`
  position: absolute;
  right: 0;
  top: -36px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: white;
`;

type RangeSliderProps = {
  onChange?: (value) => void;
  onAfterChange?: (value) => void;
  label: string;
  min: number;
  max: number;
  defaultValue: number[];
  value?: number[];
  style?: any;
};

const RangeSlider = (props: RangeSliderProps) => {
  const locale = useLocale();
  const [value, setValue] = React.useState(
    props.defaultValue ? [props.defaultValue[0], props.defaultValue[1]] : [props.min, props.max]
  );

  const onSliderChange = (value) => {
    setValue(value);
    if (props.onChange) props?.onChange(value);
  };

  const onAfterChange = (value) => {
    if (props.onAfterChange) props?.onAfterChange(value);
  };

  React.useEffect(() => {
    if (props.value?.length > 0) setValue(props.value);
  }, [props.value]);

  return (
    <Container style={props.style}>
      <Content>
        <MinMaxValues>
          {`${value[0]} ${props.label} ${locale('nearby.filters.to')} ${value[1]} ${props.label}`}
        </MinMaxValues>
        <Range
          defaultValue={props.defaultValue ? props.defaultValue : [props.min, props.max]}
          min={props.min}
          max={props.max}
          value={value}
          step={1}
          allowCross={false}
          onChange={onSliderChange}
          onAfterChange={onAfterChange}
          railStyle={{
            backgroundColor: colors.lightGrey7,
            height: '8px',
          }}
          trackStyle={[
            {
              backgroundColor: colors.darkBlue,
              height: 8,
              borderRadius: 20,
            },
          ]}
          handleStyle={[
            {
              height: 24,
              width: 24,
              borderRadius: 24,
              top: 2,
              border: `1.5px solid ${colors.darkBlue}`,
              backgroundColor: colors.backgroundLight,
              boxShadow:
                '0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15)',
            },
            {
              height: 24,
              width: 24,
              borderRadius: 24,
              top: 2,
              border: `1.5px solid ${colors.darkBlue}`,
              backgroundColor: colors.backgroundLight,
              boxShadow:
                '0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.15)',
            },
          ]}
        />
      </Content>
    </Container>
  );
};

export default RangeSlider;
