import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import useLocale from '../../../../utils/locale/locale.hook';
import colors from '../../../../themes/colors-v2.theme';
import Text from '../../../../components/style/text.component';
import TextInput from '../../../../components/forms/text.input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { phoneIsFree } from '../../../../services/users.service';
import fonts from '../../../../themes/fonts.theme';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import Image from '../../../../components/image.component';

export default function Captcha(props) {
  const locale = useLocale();

  const [phone, setPhone] = useState<string>(props.values.phone || '');
  const [validPhone, setValidPhone] = useState(isPossiblePhoneNumber(props.values.phone || '') || false);
  const [phoneError, setPhoneError] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const onBlurPhone = async () => {
    if (!isPossiblePhoneNumber(phone || '')) {
      setPhoneError(true);
    } else {
      const isPhoneFree = await phoneIsFree(phone);
      if (!isPhoneFree) {
        setValidPhone(false);
        setPhoneError(true);
        setErrorMsg(locale('signup.credentials.phone_already_use'));
        return;
      }
      setErrorMsg('');
    }
  };

  const onChangePhone = (text: string) => {
    if (isPossiblePhoneNumber(text || '')) {
      setValidPhone(true);
      setPhoneError(false);
    } else {
      setValidPhone(false);
    }
    setPhone(text);
  };

  useEffect(() => {
    try {
      //@ts-ignore
      window.mtcaptcha.remove();
    } catch (err) {
      console.error(err);
    }
    try {
      //@ts-ignore
      window.mtcaptcha.renderUI();
    } catch (err) {
      console.error(err);
    }
    //@ts-ignore
    window.mt_verifiedcb = (state) => {
      setCaptchaResponse(state.verifiedToken);
    };
  }, []);

  const handleNextStep = () => {
    props.onNext({
      token: captchaResponse,
      phone: phone,
    });
  };

  return (
    <FormWrapper>
      <ContentWrapper>
        <LoginHeader>
          <HeaderLogoWrapper>
            <Image
              src="/assets/icons/logo/logo-white-with-text.svg"
              alt="Bearwww logo"
              width={'185px'}
              height={'35px'}
            />
          </HeaderLogoWrapper>
          <HeaderTitleWrapper>
            <Text
              style={{ fontSize: 14, fontWeight: fonts.bold.weight }}
              dangerouslySetInnerHTML={{
                __html: locale('signin.header.connect_to_bears_and_their_friends'),
              }}
            />
          </HeaderTitleWrapper>
        </LoginHeader>
        <MarginWrapper>
          <Text bold fontSize="16px" style={{ marginBottom: 8 }}>
            {locale('signup.phone')}
          </Text>
          <Text style={{ fontWeight: fonts.regular.weight, fontSize: 10, marginBottom: 8, color: '#D0D5DD' }}>
            {locale('signup.credentials.phone_disclaimer')}
          </Text>
          <TextInput
            isPhoneInput
            placeholder={locale('signup.credentials.phone')}
            value={phone}
            success={validPhone}
            onChange={onChangePhone}
            onBlur={onBlurPhone}
            error={phoneError}
          />

          {errorMsg ? <Text style={{ color: colors.error, fontWeight: 400, margin: 4 }}>{errorMsg}</Text> : null}
        </MarginWrapper>
        <div className="mtcaptcha"></div>
        <PrimaryButton
          onClick={handleNextStep}
          size="large"
          isActive={validPhone && captchaResponse !== ''}
          disabled={!validPhone || captchaResponse === ''}
          style={{ width: '100%' }}
        >
          Continue
        </PrimaryButton>
      </ContentWrapper>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
  width: 369px;
`;

const LoginHeader = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;

  @media (max-width: 1000px) {
    text-align: center;
    padding: 0 16px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: auto;
  color: black;
`;

const MarginWrapper = styled.div`
  margin-bottom: 12px;
`;

const HelpText = styled.div`
  color: ${colors.white};
`;
