import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useLogin } from '../../utils/auth.utils';
import { Coordinates } from '../../pages/explore/explore.screenv2';
import debounce from 'lodash/debounce';
import { ErrorBoundary } from '../error-boundary';

type Props = {
  exploreLatitude?: number;
  exploreLongitude?: number;
  setSelectedAddress?: (address: any) => void;
  style?: React.CSSProperties;
};

const MapExploreV2 = ({ exploreLatitude, exploreLongitude, setSelectedAddress, style }: Props) => {
  const { me } = useLogin();

  // Set default longlat in Paris
  const defaultLatitude = 48.866667;
  const defaultLongitude = 2.333333;

  const [map, setMap] = useState<L.Map | null>(null);
  const [coords, setCoords] = useState<Coordinates>({
    latitude: exploreLatitude ?? me?.latitude ?? defaultLatitude,
    longitude: exploreLongitude ?? me?.longitude ?? defaultLongitude
  });

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (exploreLatitude && exploreLongitude) {
      setCoords({ latitude: exploreLatitude, longitude: exploreLongitude });
    }
  }, [exploreLatitude, exploreLongitude]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!map) {
      const newMap = L.map(mapRef.current, { zoomControl: false }).setView([coords.latitude, coords.longitude], 13);
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', {
        attribution: '© CARTO',
      }).addTo(newMap);
      setMap(newMap);
    }

    return () => {
      map?.remove();
    };
  }, [mapRef.current]);

  useEffect(() => {
    if (!mapRef.current || !map) return;

    const customIcon = L.icon({
      iconUrl: '/assets/icons/explore/map-marker.svg',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });
    const marker = L.marker([coords.latitude, coords.longitude], { icon: customIcon }).addTo(map);

    map.on('move', () => {
      const { lat, lng } = map.getCenter();
      marker.setLatLng([lat, lng]);
    });

    map.on('moveend', (e) => {
      const { lat, lng } = map.getCenter();
      setSelectedAddress?.({ raw: { lat: lat, lon: lng } });
    });

    return () => {
      marker.remove();
      map.off('moveend');
    };
  }, [coords, mapRef.current]);

  useEffect(() => {
    if (!map) return;
    map.flyTo(new L.LatLng(coords.latitude, coords.longitude));
  }, [coords, map]);

  return <div ref={mapRef} style={{ height: '100dvh', width: '100%', zIndex: 1, ...style }} />;
};

export default MapExploreV2;
