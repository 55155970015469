import { useEffect, useState } from "react";
import { Flex } from "../../components/style/flex.component";
import ScreenWrapper from "../../components/style/screen-wrapper.component";
import { Tab } from "../../components/tabs/Tab";
import { Tabs } from "../../components/tabs/Tabs";
import useLocale from "../../utils/locale/locale.hook";
import colors from "../../themes/colors-v2.theme";
import { getForYouCategory } from "../../services/discover.service";
import { UsersCategory } from "./components/users-category.component";
import { CustomGrid } from "../nearby/components/custom-grid.component";
import styled from "styled-components";
import { useLogin } from "../../utils/auth.utils";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../components/style/loader.component";
import GuideScreen from "../guide/guide.screenv2";
import { useRedirectUnsignedUser } from "../../utils/useRedirectUnsignedUser";
import { getTrendingHashtags } from "../../services/hashtags.service";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import Text from "../../components/style/text.component";
import { useMediaQuery } from "react-responsive";

type Tabs = "forYou" | "bestRank" | "guide";
const tabs: Tabs[] = ["forYou", "bestRank", "guide"];

export type Category =
  | "woofedYou"
  | "talkedTo"
  | "video"
  | "likedYou"
  | "mostActive"
  | "meetNow"
  | "spotlight"
  | "mostSeen"
  | "hashtags"
  | "mostWoofed";

export const DiscoverScreen = () => {
  useRedirectUnsignedUser();

  const locale = useLocale();
  const { isLogin, me } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const history = useHistory();
  const { path } = useRouteMatch();

  const [activeTab, setActiveTab] = useState<Tabs>("forYou");
  const [selected, setSelected] = useState<number>(
    tabs.indexOf(activeTab) ?? 0
  );
  const [category, setCategory] = useState<Category | undefined>();
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);

  const [usersSpotlight, setUsersSpotlight] = useState<any[]>([]);
  const [usersWoofedYou, setUsersWoofedYou] = useState<any[]>([]);
  const [usersTalkedTo, setUsersTalkedTo] = useState<any[]>([]);
  const [usersVideo, setUsersVideo] = useState<any[]>([]);
  const [usersLikedYou, setUsersLikedYou] = useState<any[]>([]);
  const [usersMostActive, setUsersMostActive] = useState<any[]>([]);
  const [usersMeetNow, setUsersMeetNow] = useState<any[]>([]);
  const [usersMostSeen, setUsersMostSeen] = useState<any[]>([]);
  const [usersMostWoofed, setUsersMostWoofed] = useState<any[]>([]);
  const [trendingHashtags, setTrendingHashtags] = useState<any[]>([]);
  const [page, setPage] = useState(0);

  const [usersViewMore, setUsersViewMore] = useState<any[]>([]);
  const [hashtagsViewMore, setHashtagsViewMore] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const [isMorePageOpen, setIsMorePageOpen] = useState<boolean>(false);
  const [title, setTitle] = useState("");

  function resetStates() {
    setActiveTab("forYou");
    setCategory(undefined);
    setInfiniteScrollKey(0);
    setUsersViewMore([]);
    setHasMore(true);
    setIsMorePageOpen(false);
    setPage(0);
    fetchPreviewDatas();
  }

  // on Back button
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (
        history.location.pathname === "/discover" ||
        history.location.pathname === "/discover/bestRank"
      ) {
        resetStates();
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  async function fetchPreviewDatas() {
    const query: Record<string, string | number> = {
      limit: 10,
    };
    if (isLogin) {
      query.latitude = me.latitude;
      query.longitude = me.longitude;
    }
    query.page = 0;

    if (history.location.pathname === "/discover") {
      const spotlightPromise = getForYouCategory("spotlight", query);
      const woofedYouPromise = getForYouCategory("woofedYou", query);
      const talkedToPromise = getForYouCategory("talkedTo", query);
      const videoPromise = getForYouCategory("video", query);
      const likedYouPromise = getForYouCategory("likedYou", query);

      const meetNowPromise = getForYouCategory("meetNow", query);

      const [spotlight, woofedYou, talkedTo, video, likedYou, meetNow] =
        await Promise.all([
          spotlightPromise,
          woofedYouPromise,
          talkedToPromise,
          videoPromise,
          likedYouPromise,
          meetNowPromise,
        ]);

      setUsersSpotlight(spotlight.users);
      setUsersWoofedYou(woofedYou.users);
      setUsersTalkedTo(talkedTo.users);
      setUsersVideo(video.users);
      setUsersLikedYou(likedYou.users);
      setUsersMeetNow(meetNow.users);
    }

    if (history.location.pathname === "/discover/bestRank") {
      const mostActivePromise = getForYouCategory("mostActive", query);
      const mostSeenPromise = getForYouCategory("mostSeen", query);
      const mostWoofedPromise = getForYouCategory("mostWoofed", query);
      const trendingHashtagsPromise = getTrendingHashtags(query);

      const [mostActive, mostSeen, mostWoofed, trendingHashtags] =
        await Promise.all([
          mostActivePromise,
          mostSeenPromise,
          mostWoofedPromise,
          trendingHashtagsPromise,
        ]);

      setUsersMostActive(mostActive.users);
      setUsersMostSeen(mostSeen.users);
      setUsersMostWoofed(mostWoofed.users);
      setTrendingHashtags(trendingHashtags);
    }
  }

  async function fetchCategoryData(initial?: boolean) {
    const limit = 70;
    const query: Record<string, string | number> = {
      limit,
    };
    if (isLogin) {
      query.latitude = me.latitude;
      query.longitude = me.longitude;
    }
    query.page = page;

    setTitle(locale(`discover.category.forYou.${category}`));
    if (category === "hashtags") {
      query.limit = 200;
      const hashtags = await getTrendingHashtags(query);
      setHashtagsViewMore([...hashtagsViewMore, ...hashtags]);
      setHasMore(hashtags.length !== 0);
      setPage((page) => page + 1);
    } else {
      const users = await getForYouCategory(category, query);
      if (!initial) {
        setUsersViewMore((usersViewMore) => [...usersViewMore, ...users.users]);
        setHasMore(users.users.length !== 0);
        setPage((page) => page + 1);
      } else {
        setUsersViewMore(users.users);
        setHasMore(users.users.length !== 0);
        setPage(1);
      }
    }
  }

  const handleViewMore = (category: Category) => {
    return async function () {
      setIsMorePageOpen(true);
      setCategory(category);
      setInfiniteScrollKey(Math.random());
      history.push(`${path}/${category}`);
    };
  };

  useEffect(() => {
    if (isLogin) {
      fetchPreviewDatas();
    }
  }, [isLogin]);

  useEffect(() => {
    const tab = history.location.pathname.split("/")[2] as Tabs;
    if (tab === "bestRank" || tab === "guide") {
      setActiveTab(tab);
      setSelected(tabs.indexOf(tab) ?? 0);
    }
    return () => {
      setActiveTab("forYou");
      setSelected(tabs.indexOf("forYou") ?? 0);
    };
  }, [history.location.pathname]);

  useEffect(() => {
    if (locale) {
      setTitle(locale(`discover.category.forYou.${category}`));
    }
  }, [locale]);

  useEffect(() => {
    if (history.location.pathname.startsWith("/discover/")) {
      const category = history.location.pathname.split("/")[2];
      if (category && category !== "bestRank" && category !== "guide") {
        setCategory(category as Category);
        setIsMorePageOpen(true);
        setTitle(locale(`discover.category.forYou.${category}`));
        setInfiniteScrollKey(Math.random());
      }
    }
  }, []);

  useEffect(() => {
    if (category) {
      fetchCategoryData(true);
    }
  }, [category]);

  return (
    <>
      {!isMorePageOpen ? (
        <ScreenWrapper style={{ marginBottom: !isDesktop ? 110 : "" }}>
          {isDesktop ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 16px 16px 16px",
                width: "calc(100vw - 260px)",
                boxSizing: "border-box",
                position: "fixed",
                zIndex: 1000,
                top: 0,
                background: colors.darkGradient,
                backgroundAttachment: "fixed",
              }}
            >
              <Flex alignItems="center" gap="16px">
                <img
                  src="/assets/icons/sidebar/navbar/discover-white.svg"
                  alt="radar"
                  width={24}
                  height={24}
                />
                <div
                  style={{ fontWeight: 600, fontSize: "16px", color: "white" }}
                >
                  {locale("global.mobile.discover")}
                </div>
              </Flex>
            </div>
          ) : null}

          <Tabs
            isFixed
            selectedTab={selected}
            styleHeaders={!isDesktop ? { top: 0, paddingTop: 20 } : null}
          >
            <Tab
              title={locale("discover.category.forYou")}
              onClick={() => {
                setActiveTab("forYou");
                history.push("/discover");
              }}
              style={{ flex: 1, padding: "0 8px 8px 12px" }}
            >
              <Flex direction="column" gap="16px" style={{ marginTop: 8 }}>
                {usersSpotlight.length > 0 ? (
                  <UsersCategory
                    users={usersSpotlight}
                    onViewMore={handleViewMore("spotlight")}
                    title={locale("discover.category.forYou.spotlight")}
                  />
                ) : null}
                {usersWoofedYou.length > 0 ? (
                  <UsersCategory
                    users={usersWoofedYou}
                    onViewMore={handleViewMore("woofedYou")}
                    title={locale("discover.category.forYou.woofedYou")}
                  />
                ) : null}

                {usersTalkedTo.length > 0 ? (
                  <UsersCategory
                    users={usersTalkedTo}
                    onViewMore={handleViewMore("talkedTo")}
                    title={locale("discover.category.forYou.talkedTo")}
                  />
                ) : null}

                {usersVideo.length > 0 ? (
                  <UsersCategory
                    users={usersVideo}
                    onViewMore={handleViewMore("video")}
                    title={locale("discover.category.forYou.video")}
                  />
                ) : null}
                {usersMeetNow.length > 0 ? (
                  <UsersCategory
                    users={usersMeetNow}
                    onViewMore={handleViewMore("meetNow")}
                    title={locale("discover.category.forYou.meetNow")}
                  />
                ) : null}
                {usersLikedYou.length > 0 ? (
                  <UsersCategory
                    users={usersLikedYou}
                    onViewMore={handleViewMore("likedYou")}
                    title={locale("discover.category.forYou.likedYou")}
                  />
                ) : null}
              </Flex>
            </Tab>
            <Tab
              title={locale("discover.category.bestRank")}
              onClick={() => {
                setActiveTab("bestRank");
                history.push("/discover/bestRank");
              }}
              style={{ flex: 1, padding: "0 8px 8px 12px" }}
            >
              <Switch>
                <Route
                  path="/discover/bestRank"
                  render={() => {
                    return (
                      <Flex
                        direction="column"
                        gap="16px"
                        style={{ marginTop: 8 }}
                      >
                        {usersMostActive.length > 0 ? (
                          <UsersCategory
                            users={usersMostActive}
                            onViewMore={handleViewMore("mostActive")}
                            title={locale(
                              "discover.category.forYou.mostActive"
                            )}
                          />
                        ) : null}
                        {usersMostSeen.length > 0 ? (
                          <UsersCategory
                            users={usersMostSeen}
                            onViewMore={handleViewMore("mostSeen")}
                            title={locale("discover.category.forYou.mostSeen")}
                          />
                        ) : null}
                        {usersMostWoofed.length > 0 ? (
                          <UsersCategory
                            users={usersMostWoofed}
                            onViewMore={handleViewMore("mostWoofed")}
                            title={locale(
                              "discover.category.forYou.mostWoofed"
                            )}
                          />
                        ) : null}
                        {trendingHashtags.length > 0 ? (
                          <Flex direction="column" gap="16px">
                            <Flex gap="12px" alignItems="center">
                              <Text bold fontSize="16px">
                                {locale("discover.category.forYou.hashtags")}
                              </Text>
                              <Flex
                                style={{ cursor: "pointer" }}
                                onClick={handleViewMore("hashtags")}
                              >
                                <Text
                                  fontSize="12px"
                                  color={
                                    isDesktop ? colors.lightGrey3 : colors.white
                                  }
                                  style={{ marginRight: 8 }}
                                >
                                  {locale("global.see.more")}
                                </Text>
                                <img
                                  src="/assets/icons/nearby/chevron-right.svg"
                                  alt="chevron"
                                ></img>
                              </Flex>
                            </Flex>
                            <Flex style={{ flexWrap: "wrap", gap: 4 }}>
                              {trendingHashtags.map((hashtag) => {
                                return (
                                  <PrimaryButton
                                    key={hashtag.id}
                                    onClick={() =>
                                      history.push({
                                        pathname: "/hashtags",
                                        state: {
                                          id: hashtag.id,
                                          name: locale(hashtag.traductionKey),
                                          previousPath: location.pathname,
                                          category: "hashtags",
                                        },
                                      })
                                    }
                                  >
                                    {locale(hashtag.traductionKey)}
                                  </PrimaryButton>
                                );
                              })}
                            </Flex>
                          </Flex>
                        ) : null}
                      </Flex>
                    );
                  }}
                />
              </Switch>
            </Tab>
            <Tab
              title={locale("discover.category.guides")}
              onClick={() => {
                setActiveTab("guide");
                history.push(`${path}/guide/events`);
              }}
              style={{ flex: 1 }}
            >
              <GuideScreen />
            </Tab>
          </Tabs>
        </ScreenWrapper>
      ) : (
        <ScreenWrapper>
          <Switch>
            <Route
              path={`/discover/:category`}
              render={() => (
                <Flex direction="column" gap="16px" style={{ marginTop: 8 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px 16px 0px 16px",
                      width: isDesktop ? "calc(100vw - 260px)" : "100%",
                      boxSizing: "border-box",
                      position: "fixed",
                      zIndex: 10,
                      top: 0,
                      background: colors.primary,
                      paddingBottom: 16,
                    }}
                  >
                    <Flex alignItems="center" gap="16px">
                      <img
                        src="/assets/icons/nearby/chevron-right-white.svg"
                        alt="radar"
                        width={16}
                        height={16}
                        style={{
                          transform: "rotate(180deg)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetStates();
                          history.goBack();
                        }}
                      />
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "white",
                        }}
                      >
                        {title}
                      </div>
                    </Flex>
                  </div>
                  <SectionContainer>
                    <InfiniteScroll
                      dataLength={
                        category === "hashtags"
                          ? hashtagsViewMore.length
                          : usersViewMore.length
                      }
                      next={fetchCategoryData}
                      hasMore={hasMore}
                      key={infiniteScrollKey}
                      loader={
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                          key={Math.random().toString()}
                        >
                          <Loader
                            color={colors.primary}
                            key={Math.random().toString()}
                          />
                        </div>
                      }
                    >
                      {category !== "hashtags" ? (
                        <CustomGrid
                          cellType="rectangle"
                          users={usersViewMore}
                          showSubscribeAd={false}
                        />
                      ) : (
                        <Flex style={{ flexWrap: "wrap", gap: 4 }}>
                          {hashtagsViewMore.map((hashtag) => {
                            return (
                              <PrimaryButton
                                key={hashtag.id}
                                onClick={() =>
                                  history.push({
                                    pathname: "/hashtags",
                                    state: {
                                      id: hashtag.id,
                                      name: locale(hashtag.traductionKey),
                                      previousPath: location.pathname,
                                      category: "hashtags",
                                    },
                                  })
                                }
                              >
                                {locale(hashtag.traductionKey)}
                              </PrimaryButton>
                            );
                          })}
                        </Flex>
                      )}
                    </InfiniteScroll>
                  </SectionContainer>
                </Flex>
              )}
            ></Route>
          </Switch>
        </ScreenWrapper>
      )}
    </>
  );
};

const SectionContainer = styled.div`
  background-color: transparent;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 60px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  padding: 8px;

  @media (max-width: 1000px) {
    border-radius: 0;
    margin-bottom: 120px;
  }
`;
