import React from 'react';
import styled from 'styled-components/macro';
import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';

type TextInputProps = {
  onChange?: (text: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  style?: Object;
  inputStyle?: React.CSSProperties;
  type?: 'text' | 'password';
};

export default function TextInput(props: TextInputProps) {
  const { onChange, onBlur, onKeyDown, placeholder, value, maxLength, style, type, ...otherProps } = props;

  const [derivedType, setDerivedType] = React.useState(type);
  return (
    <Container style={style} {...otherProps}>
      <Input
        type={derivedType || 'text'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength || null}
        placeholder={placeholder}
        style={{ ...props.inputStyle }}
      />
      {type === 'password' ? (
        <div
          style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
          onClick={() => {
            derivedType === 'password' ? setDerivedType('text') : setDerivedType('password');
          }}
        >
          <img src="/assets/imgs/signin/eye.svg" />
        </div>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: ${(props) => props.width || '100%'};
  outline: none;
  border-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightGrey10};
  background: ${colors.darkGray};
  color: white;
  font-size: 12px;
`;
