import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Button from '../../../../components/forms-v2/button.component';
import Text from '../../../../components/style/text.component';
import { updateUser } from '../../../../services/users.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { Subtitle } from '../../../../components/settings/subtitle.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { useDebounce } from 'use-debounce';
import { StyledTextAreaInput } from '../../../../components/forms/input.component';

type DescriptionProps = {
  me?: any;
  onChange?: () => void;
};

const Description = (props: DescriptionProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const inputRef = useRef();

  const [description, setDescription] = useState(props?.me?.description);
  const locale = useLocale();
  const [debouncedValue] = useDebounce(description, 500);

  async function updateDescription() {
    await updateUser({ description: debouncedValue });
    props?.onChange?.();
  }

  useEffect(() => {
    if (debouncedValue !== props?.me?.description) {
      updateDescription();
    }
  }, [debouncedValue]);

  return (
    <>
      <StyledTextAreaInput
        ref={inputRef}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={locale('profil_edit.no_description')}
      />
    </>
  );
};

export default Description;
