import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';

import useLocale from '../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { Flex } from '../../../components/style/flex.component';

type Props = {
  filtersOpen: boolean;
  setFiltersOpen: (str: string) => void;
  setFilters: Dispatch<SetStateAction<any>>;
  filtersCount: number;
  areFiltersDeactivated: boolean;
};

const Headers = ({ filtersOpen, setFiltersOpen, filtersCount, areFiltersDeactivated }: Props) => {
  const locale = useLocale();

  return (
    <Container filtersOpen={filtersOpen}>
      <Left>
        <PrimaryButton
          onClick={() => setFiltersOpen('')}
          isActive={Boolean(filtersCount) && !areFiltersDeactivated}
          style={{ height: '100%' }}
        >
          {filtersCount > 0 && !areFiltersDeactivated ? (
            <Flex alignItems="center" gap="6px">
              <Icon
                alt=""
                width="20px"
                height="20px"
                src={filtersCount > 0 ? '/assets/icons/nearby/filter.svg' : '/assets/icons/nearby/search-dark.svg'}
              />
              <div>{filtersCount}</div>
              {filtersCount > 1 ? locale('filtres') : locale('nearby.filter.title')}
            </Flex>
          ) : (
            <div>{locale('nearby.advanced_filters')}</div>
          )}
        </PrimaryButton>
      </Left>
    </Container>
  );
};

export default Headers;

const Container = styled.div<{ filtersOpen: boolean }>`
  right: 0;
  z-index: 3;
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 16px 10px;
    max-width: ${`${window.innerWidth}px`};
    overflow-x: scroll;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
