import { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import Icons from "../../../components/style/icons.component";
import Text from "../../../components/style/text.component";

import { useLogin } from "../../../utils/auth.utils";
import useLocale from "../../../utils/locale/locale.hook";
import ModalSlice from "../../../store/slices/modal.slice";
import { openProfile } from "../../user-profile/profile.utils";
import PlayStoreButton from "../../../components/miscellaneous/play-store-button.component";
import AppStoreButton from "../../../components/miscellaneous/app-store-button.component";
import colors from "../../../themes/colors-v2.theme";
import Notifications from "../Notifications/Notifications";
import { getUrl } from "../../../utils/locale/hreflang.utils";

const SidebarDesktop = () => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(
    getUrl().pathname.split("/")[1] || "/"
  );

  const overlayRef = useRef(null);
  const notifStore = useSelector((state: any) => state.notifStore);

  useEffect(() => {
    setActiveTab(getUrl().pathname.split("/")[1] || "/");
  }, [history.location.pathname]);

  return (
    <OverlayWrapper>
      <Container ref={overlayRef}>
        <BearIcon>
          <Link to="/discover">
            <img
              alt="Bearwww Logo"
              width="150"
              height="30"
              src="/assets/icons/logo/logo-white-with-text.svg"
            />
          </Link>
        </BearIcon>

        <LinksContainer>
          <Links>
            <StyledLink
              to="/discover"
              onClick={() => {
                setActiveTab("discover");
              }}
              isActive={
                activeTab === "discover" || history.location.pathname === "/"
              }
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/discover${
                        activeTab === "discover" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.discover")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/nearby"
              isActive={activeTab === "nearby"}
              onClick={() => {
                setActiveTab("nearby");
              }}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/nearby${
                        activeTab === "nearby" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.nearby")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/explore"
              isActive={activeTab === "explore"}
              onClick={() => {
                setActiveTab("explore");
              }}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/explore${
                        activeTab === "explore" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.explore")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/trips"
              onClick={() => {
                setActiveTab("trips");
              }}
              isActive={activeTab === "trips"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/calendar${
                        activeTab === "trips" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.trip")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/messages"
              onClick={() => {
                setActiveTab("messages");
              }}
              isActive={activeTab === "messages"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/message${
                        activeTab === "messages" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.messages")}
                  </Item>
                  {notifStore.messagesNotSeen > 0 && (
                    <Notifications
                      notificationNumber={notifStore.messagesNotSeen}
                      maxNumberOfChar={1}
                      position="relative"
                    />
                  )}
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/viewers"
              onClick={() => {
                setActiveTab("viewers");
              }}
              isActive={activeTab === "viewers"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/view${
                        activeTab === "viewers" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.mobile.viewers")}
                  </Item>
                  {notifStore.viewsNotSeen > 0 && (
                    <Notifications
                      notificationNumber={notifStore.viewsNotSeen}
                      maxNumberOfChar={1}
                      left={12}
                      top={-8}
                    />
                  )}
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/favorites"
              onClick={() => {
                setActiveTab("favorites");
              }}
              isActive={activeTab === "favorites"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/star${
                        activeTab === "favorites" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.mobile.favorites")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            <StyledLink
              to="/classification"
              onClick={() => {
                setActiveTab("classification");
              }}
              isActive={activeTab === "classification"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/classification${
                        activeTab === "classification" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.classification")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>
            <StyledLink
              to="/shared-albums"
              onClick={() => setActiveTab("shared-albums")}
              isActive={activeTab === "shared-albums"}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/shared-album${
                        activeTab === "shared-albums" ? "-active" : ""
                      }`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                    />
                    {locale("global.access-album")}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLink>

            {/* <StyledLinkNoRedirect
              onClick={() => {
                dispatch(ModalSlice.actions.setSexualContentWarning({ visible: true, redirectTo: '/live' }));
              }}
              isActive={activeTab === 'live'}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/live${activeTab === 'live' ? '-active' : ''}`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: '5px 0px 3px 0px' }}
                    />
                    {locale('global.live')}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLinkNoRedirect> */}

            {/* <StyledLinkNoRedirect
              onClick={() => {
                dispatch(ModalSlice.actions.setSexualContentWarning({ visible: true, redirectTo: '/members-videos' }));
              }}
              isActive={activeTab === 'members-videos'}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/members-videos${activeTab === 'members-videos' ? '-active' : ''}`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: '5px 0px 3px 0px' }}
                    />
                    {locale('live.home.members_videos')}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLinkNoRedirect> */}

            {/* <StyledLinkNoRedirect
              onClick={() => {
                dispatch(ModalSlice.actions.setSexualContentWarning({ visible: true, redirectTo: '/porn-videos' }));
              }}
              isActive={activeTab === 'porn-videos'}
            >
              <SubmenuTitle>
                <SubMenuItem>
                  <Item>
                    <Icons
                      name={`sidebar/navbar/porn-video${activeTab === 'porn-videos' ? '-active' : ''}`}
                      width="22"
                      height="22"
                      style={{ marginRight: 16, padding: '5px 0px 3px 0px' }}
                    />
                    {locale('global.porno')}
                  </Item>
                </SubMenuItem>
              </SubmenuTitle>
            </StyledLinkNoRedirect> */}
          </Links>
        </LinksContainer>

        <Footer>
          {["admin", "premium"].includes(me.role) ? null : (
            <PremiumWrapper
              onClick={() => {
                history.push("/memberships");
              }}
            >
              <PremiumContainer>
                <PremiumTitle>
                  <img
                    src="/assets/icons/logo/bearwwx.svg"
                    width={72}
                    height={18}
                  />
                </PremiumTitle>
                <PremiumSubtitle>
                  {locale("global.premium.description")}
                </PremiumSubtitle>
                <PremiumButton>
                  <Text
                    style={{
                      boxShadow: "0px 1px 2px 0px #A36715",
                      fontSize: "12px",
                    }}
                  ></Text>
                  {locale("global.premium.subscribe")}
                </PremiumButton>
              </PremiumContainer>
            </PremiumWrapper>
          )}

          <StyledLink
            to="/memberships"
            onClick={() => setActiveTab("memberships")}
            isActive={activeTab === "memberships"}
          >
            <SubmenuTitle>
              <SubMenuItem>
                <Item>
                  <Icons
                    name={
                      activeTab === "memberships"
                        ? "sidebar/navbar/premiumX"
                        : "sidebar/navbar/membership"
                    }
                    width="22"
                    height="22"
                    style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                  />
                  {locale("global.my_subscription")}
                </Item>
              </SubMenuItem>
            </SubmenuTitle>
          </StyledLink>

          <StyledLink
            to="/pictures"
            onClick={() => setActiveTab("pictures")}
            isActive={activeTab === "pictures"}
          >
            <SubmenuTitle>
              <SubMenuItem>
                <Item>
                  <Icons
                    name={`sidebar/navbar/lock-album${
                      activeTab === "pictures" ? "-active" : ""
                    }`}
                    width="22"
                    height="22"
                    style={{ marginRight: 16, padding: "5px 0px 3px 0px" }}
                  />
                  {locale("account.side_menu.my_pictures")}
                </Item>
              </SubMenuItem>
            </SubmenuTitle>
          </StyledLink>

          <StyledLink
            to="/settings/edit-profile"
            onClick={() => setActiveTab("settings")}
            isActive={activeTab === "settings"}
          >
            <SubmenuTitle>
              <SubMenuItem>
                <Item>
                  <ProfilePictureWrapper>
                    <ProfilePicture
                      alt=""
                      onClick={() => openProfile(me?.pseudo)}
                      src={me?.profilePicture?.path}
                      width="30"
                      height="30"
                    />
                  </ProfilePictureWrapper>
                  {locale("account.edit_profile")}
                </Item>
              </SubMenuItem>
            </SubmenuTitle>
          </StyledLink>
          <FooterTop>
            <Logos>
              <AppStoreButton style={{ width: 138, height: 38 }} />
              <PlayStoreButton style={{ width: 138, height: 38 }} />
            </Logos>
          </FooterTop>
        </Footer>
      </Container>
    </OverlayWrapper>
  );
};

const OverlayWrapper = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(46, 52, 56, 0.73);
  border-right: 1px solid #0d121c;
  z-index: 1005;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 250px;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${colors.dark};
  display: flex;
  flex-direction: column;
  just-content: flex-between;
  z-index: 100;
  color: ${colors.darkText};
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    top: 0;
    right: unset;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
`;

const PremiumWrapper = styled.div`
  cursor: pointer;
  width: 200px;
  height: 132px;
  display: flex;
  background: linear-gradient(
    to right,
    #d93427,
    #f69101,
    #dfd902,
    #14aa5f,
    #2d56c5,
    #4c2e7b
  );
  border-radius: 8px;
  padding: 2px;
  overflow: hidden;
  margin-bottom: 16px;
`;

const PremiumContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-image: url("/assets/icons/sidebar/navbar/premium-background.png");
  background-position: center;
  background-size: 180%;
  border-radius: 8px;
  padding: 0 16px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    z-index: 1;
  }
`;

const PremiumTitle = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  margin: 0;
`;

const PremiumSubtitle = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  font-weight: 500;
  margin: 0;
  z-index: 2;
`;

const PremiumButton = styled.button`
  color: white;
  background: linear-gradient(209.2deg, #fdb022 9.53%, #ff7a00 111.75%);
  padding: 8px 14px 8px 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  z-index: 2;
`;

const LinksContainer = styled.div`
  width: 100%;
  background-color: ${colors.dark};
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

const Links = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.dark};
  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  border-bottom: 1px solid rgba(52, 53, 54, 0.5);
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);

  & > div {
    margin-bottom: 0;
  }
`;

const StyledLinkNoRedirect = styled.div<{ isActive?: boolean }>`
  width: 100%;
  padding-left: 15px;
  &:hover {
    background-color: rgba(16, 107, 242, 0.2);
    border-left: 2px solid blue;
    // & > * {
    //   color: ${colors.darkBlue};
    // }
  }
  & * {
    color: ${(props) => (props.isActive ? colors.darkBlue : "")};
  }
  box-sizing: border-box;
`;

const StyledLink = styled(Link)<{ isActive?: boolean }>`
  width: 100%;
  padding-left: 15px;
  &:hover {
    background-color: rgba(16, 107, 242, 0.2);
    border-left: 2px solid blue;
    // & > * {
    //   color: ${colors.darkBlue};
    // }
  }
  & * {
    color: ${(props) => (props.isActive ? colors.darkBlue : "")};
  }
  box-sizing: border-box;
`;

const SubmenuTitle = styled.div<{ withoutMarginBottom?: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 9px 0;

  color: ${colors.darkText};
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
`;

const Footer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 24px 0px;
  margin-top: 100px;
`;

const FooterTop = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 18px;
`;

const BearIcon = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: 20px;
  margin-left: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Logos = styled.div`
  margin-top: 26px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

const SubMenuItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Item = styled.div`
  align-items: center;
  display: flex;
`;

const ProfilePictureWrapper = styled.div`
  margin-right: 16px;

  @media (max-width: 1000px) {
    padding-top: 0;
  }
`;

const ProfilePicture = styled.img`
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
`;

export default SidebarDesktop;
