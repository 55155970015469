import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import TextInput from '../../../../components/forms-v2/text.input';
import ChatMessage, { Message } from './chat-message.component';

import { joinLive, leaveLive, sendMessage } from '../../../../services/live.service';
import { useLogin } from '../../../../utils/auth.utils';
import useLocale from '../../../../utils/locale/locale.hook';
import { getWebsocketConnection } from '../../../../utils/websocket.utils';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import { FancyScrollbar } from '../../../../components/style/fancy-scrollbar.style';
import Text from '../../../../components/style/text.component';

interface ComponentProps {
  live: any;
  stopLiveHandler?: () => void;
}

export default function LiveChat(props: ComponentProps) {
  const { live } = props;
  const { me, isLogin } = useLogin();
  const locale = useLocale();
  const bottomRef = useRef(null);
  const [messageInputed, setMessageInputed] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);

  const sendMessageHandler = () => {
    if (!messageInputed.trim()) {
      return;
    }

    sendMessage(live.id, { content: messageInputed }).then((res) => {
      if (res?.success) {
        setMessageInputed('');
      }
    });
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessageHandler();
    }
  };

  const onNewMessage = (e: any) => {
    const data = JSON.parse(e.data);
    if (data.type === 'live_message' && data.message.live.id === live?.id) {
      setMessages((messages) => [...messages, data.message]);
      if (bottomRef) {
        bottomRef.current.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    setMessages([]);
    const ws = getWebsocketConnection();
    if (!!live && !!ws) {
      ws.removeEventListener('message', onNewMessage);
      ws.addEventListener('message', onNewMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onNewMessage);
      }
    };
  }, [live]);

  useEffect(() => {
    if (!!live && isLogin) {
      setMessages([]);
      joinLive(live.id);
    }

    return () => {
      if (!!live && isLogin) {
        leaveLive(live.id);
      }
    };
  }, [live, isLogin]);

  return (
    <Wrapper>
      <ChatRules>
        <BearbotRules>
          <img src="/assets/icons/live/bearbot.svg" height={18} width={15.27} />
          <Text bold>Bearwwwbot</Text>
        </BearbotRules>
        {live?.user.id === me.id ? locale('live.chat.rule.streamer') : locale('live.chat.rule.viewer')}
      </ChatRules>
      <ChatMessages>
        {live?.user &&
          messages.map((message) => <ChatMessage key={message.id} message={message} streamerId={live.user.id} />)}
        <div ref={bottomRef} style={{ padding: 0 }}></div>
      </ChatMessages>
      {!!live && (
        <>
          <HorizontalSeparator />
          <ChatActions>
            <MessageInput
              type="text"
              placeholder={locale('chat.input.placeholder')}
              value={messageInputed}
              onChange={(e) => setMessageInputed(e.target.value)}
              onKeyDown={keyDownHandler}
            />
            <MessageAction
              onClick={() => {
                sendMessageHandler();
              }}
            >
              <img alt="add" src="/assets/icons/messages/icons-actions-send-blue.svg" height={24} width={24} />
            </MessageAction>
          </ChatActions>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --margin-bottom: 16px;

  width: 370px;
  position: fixed;
  right: 0;
  height: 100vh;
  background-color: ${colors.dark};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.graySeparator2};

  @media (max-width: 1000px) {
    height: 80vh;
    width: 100%;
    position: relative;
  }
`;

const ChatRules = styled.div`
  margin-bottom: var(--margin-bottom);
  font-size: 12px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  color: ${colors.white};
  background-color: ${colors.green};
  padding: 12px;
  width: 100%;
  display: grid;
  place-items: center;
  box-sizing: border-box;
`;

const BearbotRules = styled.div`
  display: flex;
  justify-self: flex-start;
  align-items: center;
  gap: 5px;
  padding-bottom: 6px;

  & p {
    margin: 0;
    font-family: ${fonts.extraBold.name};
    font-weight: ${fonts.extraBold.weight};
    font-size: 10px;
  }
`;

const ChatMessages = styled.div`
  margin-bottom: var(--margin-bottom);
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > * {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ${FancyScrollbar};
`;

const HorizontalSeparator = styled.div`
  margin-bottom: var(--margin-bottom);
  border: 1px solid ${colors.graySeparator2};
  opacity: 0.05;
`;

const ChatActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -1px 2px rgba(16, 24, 40, 0.05);
  background: ${colors.dark};
  border-top: 1px solid ${colors.graySeparator2};

  @media (max-width: 1000px) {
    position: absolute;
    bottom: 60px;
    width: 100vw;
  }
`;

const MessageInput = styled.input`
  flex: 1;
  margin: 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${colors.lightGrey10};
  padding: 0 14px;
  box-sizing: border-box;
  height: 100%;
  min-height: 42px;
  max-height: 42px;
  outline: none;
  color: ${colors.white};
  font-size: 13px;
  background: ${colors.darkGray};

  &::placeholder {
    color: ${colors.darkText};
  }
`;

const MessageAction = styled.div<{ rotate?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;
