import { get, put } from "./utils.service";

export const getNotifications = async () => {
  const res = await get(`/notifications`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getNotSeenNotifications = async () => {
  const res = await get(`/notifications/not-seen`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const setSeenNotifications = async (notifications) => {
  const res = await put(`/notifications/seen`, notifications);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const setReadNotifications = async (notifications) => {
  const res = await put(`/notifications/read`, notifications);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
