import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Dispatch,
  SetStateAction,
} from "react";
import styled from "styled-components/macro";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";

import Icons from "../style/icons.component";
import colors from "../../themes/colors-v2.theme";

interface ComponentProps {
  id?: string;
  placeholder: string;
  setAddressData: (data: { lat: number; lng: number; address: string }) => void;
  initialAddress?: string;
  searchBarStyle?: boolean;
  squared?: boolean;
  down?: boolean;
  style?: any;
  setSearchValue?: Dispatch<SetStateAction<string>>;
}

const GoogleAutocomplete = forwardRef(
  (
    {
      id,
      placeholder,
      setAddressData,
      initialAddress,
      searchBarStyle,
      squared,
      down,
      style,
      setSearchValue,
    }: ComponentProps,
    ref
  ) => {
    const [address, setAddress] = useState("");

    useEffect(() => {
      if (initialAddress) {
        setAddress(initialAddress);
      }
    }, [initialAddress]);

    const handleChange = (address: string) => {
      setAddress(address);
      setSearchValue(address);
    };

    useImperativeHandle(ref, () => ({
      handleResetField() {
        setAddress("");
      },
    }));

    const handleSelect = (selected: string) => {
      setAddress(selected);
      geocodeByAddress(selected)
        .then((results: any) => getLatLng(results[0]))
        .then((latLng: any) => setAddressData({ ...latLng, address: selected }))
        .catch((error: any) =>
          console.error("[Google Places Autocomplete] error", error)
        );
    };

    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ["geocode"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: "relative" }}>
            {down ? (
              <></>
            ) : (
              <div>
                <AutocompleteDropdownContainer
                  searchBarStyle={searchBarStyle}
                  down={down}
                >
                  {suggestions.map((suggestion: any) => {
                    return (
                      <SuggestionItem
                        key={suggestion.description}
                        {...getSuggestionItemProps(suggestion)}
                      >
                        <span>{suggestion.description}</span>
                      </SuggestionItem>
                    );
                  })}
                </AutocompleteDropdownContainer>
              </div>
            )}
            <Input
              id={id}
              searchBarStyle={searchBarStyle}
              squared={squared}
              {...getInputProps({
                placeholder: placeholder,
              })}
            />
            {down ? (
              <div>
                <AutocompleteDropdownContainer
                  searchBarStyle={searchBarStyle}
                  down={down}
                >
                  {suggestions.map((suggestion: any) => {
                    return (
                      <SuggestionItem
                        key={suggestion.description}
                        {...getSuggestionItemProps(suggestion)}
                      >
                        <span>{suggestion.description}</span>
                      </SuggestionItem>
                    );
                  })}
                </AutocompleteDropdownContainer>
              </div>
            ) : (
              <></>
            )}
            {searchBarStyle && (
              <Icons
                name="search-outlined-grey"
                width="24"
                height="24"
                style={{
                  position: "absolute",
                  top: 4,
                  left: 140,
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
);

const Input = styled.input<{ searchBarStyle?: boolean; squared?: boolean }>`
  width: 100%;
  border: 1px solid ${colors.lightGrey9};
  outline: none;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  color: ${colors.darkGrey2};
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  height: 32px;
  padding-left: 12px;

  &::placeholder {
    color: ${colors.darkGrey1};
  }

  &:hover {
    background-color: #fcfcfc;
  }
  &:focus {
    background-color: #fcfcfc;
  }

  ${(props) =>
    props.searchBarStyle &&
    `
    width: calc(100% - 16px - 36px);
    border-radius: 16px;
    height: 32px;
    background-color: white;

    padding-left: 16px;
    padding-right: 36px;
    font-size: 12px;
    color: #090c0f;
    &:focus {
        color:#090c0f;
        background-color: white;
    }
  `}
`;

const AutocompleteDropdownContainer = styled.div<{
  searchBarStyle?: boolean;
  down?: boolean;
}>`
  background-color: #fff;
  border-radius: 5px;
  color: #101113;
  position: absolute;
  z-index: 10;
  ${(p) => (p.down ? "" : "bottom: 40px;")}

  ${(props) =>
    props.searchBarStyle &&
    `
      background-color: white;
      border-radius: 5px;
      font-size: 12px;
      color: #090c0f;
      position: absolute;
      left: 11px;
  `}
`;

const SuggestionItem = styled.div`
  cursor: pointer;
  padding: 8px;
`;

export default GoogleAutocomplete;
