import { get, post } from "./utils.service";

const moduleName = 'favorite';

const getFavorites = async (type: 'favorites' | 'favored-by', userId: string, limit: number, page: number) => {
  const res = await get(`/${moduleName}/${type}/${userId}?limit=${limit}&page=${page}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleFavorite = async (targetId: string) => {
  const res = await post(`/${moduleName}/toggle-favorite`, { targetId: targetId });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
}

export const getUserFavorites = async (userId, limit, page) => await getFavorites('favorites', userId, limit, page);
export const getUserFavoredBy = async (userId, limit, page) => await getFavorites('favored-by', userId, limit, page);
