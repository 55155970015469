import React from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { useSelector } from 'react-redux';
import { getSexualPractices } from '../../../../services/caracteristics.service';
import { updateUser } from '../../../../services/users.service';
import Tag from '../../../../components/style/tag.component';
import Button from '../../../../components/forms-v2/button.component';
import { Values } from '../../../../components/settings/values.component';
import { Tags } from '../../../../components/settings/tags.component';
import { Subtitle } from '../../../../components/settings/subtitle.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import styled from 'styled-components';

type SexualPracticesProps = {
  me?: any;
  onChange?: (sexualPractices) => void;
};

const SexualPractices = (props: SexualPracticesProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [sexualPracticesCheckbox, setSexualPracticesCheckbox] = React.useState([]);
  const [sexualPractices, setSexualPractices] = React.useState(props?.me?.sexualPractices);
  const keys = useSelector((state: any) => state.localeStore.keys);
  const locale = useLocale();

  const getData = async () => {
    const sexualPractices = await getSexualPractices();
    setSexualPracticesCheckbox(sexualPractices);
  };

  const onSelect = async (selectedSexualPractice) => {
    if (sexualPractices) {
      const index = sexualPractices.findIndex(
        (practice) => practice.sexualPracticeKey === selectedSexualPractice.sexualPracticeKey
      );
      let sexualPracticesCopy = [...sexualPractices];
      if (index === -1) {
        sexualPracticesCopy.push(selectedSexualPractice);
      } else {
        sexualPracticesCopy.splice(index, 1);
      }
      setSexualPractices(sexualPracticesCopy);
      await updateUser({
        sexualPractices: sexualPracticesCopy,
      });
      props?.onChange(sexualPracticesCopy);
    }
  };

  React.useEffect(() => {
    if (keys) {
      getData();
    }
  }, [keys]);

  return (
    <>
      <Title>{locale('global.sexual_practice')}</Title>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tags>
          {sexualPracticesCheckbox?.length > 0 &&
            sexualPracticesCheckbox.map((practice, idx) => (
              <PrimaryButton
                key={idx}
                onClick={() => onSelect(practice)}
                isActive={
                  sexualPractices?.findIndex(
                    (sexualPracticeSelected) => sexualPracticeSelected.sexualPracticeKey === practice.sexualPracticeKey
                  ) !== -1
                }
              >
                {locale('sexual_practice.name.' + practice.sexualPracticeKey)}
              </PrimaryButton>
            ))}
        </Tags>
      </Values>
    </>
  );
};

export default SexualPractices;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
`;
