import { useState, useEffect } from 'react';

import Button from '../../../../components/forms-v2/button.component';
import Error from '../../../../components/forms/error.component';
import TextInput from '../../../../components/forms/text.input';
import Text from '../../../../components/style/text.component';
import { Values } from '../../../../components/settings/values.component';
import { Subtitle } from '../../../../components/settings/subtitle.component';

import useLocale from '../../../../utils/locale/locale.hook';
import { updateUser } from '../../../../services/users.service';
import Datepicker from '../../../../components/forms/datepicker.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { Flex } from '../../../../components/style/flex.component';

type AgeProps = {
  me?: any;
  onChange?: () => void;
};

const Age = (props: AgeProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [editOpen, setEditOpen] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [birthdate, setBirthdate] = useState<Date>(props?.me?.birthdate);
  const [validDate, setValidDate] = useState(props?.me?.birthdate !== null);

  const onSubmit = async (birthdate: Date) => {
    setErrorMsg(null);
    const res = await updateUser({ birthdate });
    if (res?.error) {
      setErrorMsg(locale(res?.error?.message));
    }
    props?.onChange();
    setEditOpen(false);
  };

  const onChangeDate = async (date: Date) => {
    setErrorMsg('');
    if (date !== null) {
      setBirthdate(date);
      setValidDate(true);
      // condition to avoid notification trigger on first render
      if (date.getTime() !== new Date(props?.me?.birthdate).getTime()) {
        onSubmit(date);
      }
    } else {
      setValidDate(false);
    }
  };

  const getAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - new Date(birthDate).getFullYear();
    const m = today.getMonth() - new Date(birthDate).getMonth();
    if (m < 0 || (m === 0 && today.getDate() < new Date(birthDate).getDate())) {
      age--;
    }
    return age;
  };

  const age = getAge(birthdate);

  return (
    <Flex alignItems="center">
      <Subtitle>{locale('android.filter.age')}</Subtitle>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Datepicker
          value={new Date(birthdate)}
          error={!!errorMsg}
          onChange={validDate && onChangeDate}
          isSettingsScreen
        />
        {/* ) : (
          <Text>{birthdate ? age : locale('profil_edit.no_firstname')}</Text>
        )} */}
      </Values>
      {errorMsg && <Error errorMsg={errorMsg} style={{ zoom: 0.8, marginTop: 10 }} />}
    </Flex>
  );
};

export default Age;
