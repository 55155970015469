import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import ChipList from '../../../../components/miscellaneous/chip-list.component';
import PictureModeration from '../../../../components/picture-moderation.component';
import Options from '../../../../components/options.component';

import { openProfile } from '../../../user-profile/profile.utils';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import ModalSlice from '../../../../store/slices/modal.slice';
import useLocale from '../../../../utils/locale/locale.hook';
import ProfileRound from '../../../../components/user/ProfileRound';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import SubmitReportModal from '../../../../components/miscellaneous/modals/submit-report.modal';
import Text from '../../../../components/style/text.component';

interface ComponentProps {
  live: any;
}

export default function LiveInfo(props: ComponentProps) {
  const { live } = props;
  const locale = useLocale();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const modalStore = useSelector((state: any) => state.modalStore);

  const [isShareOpen, setIsShareOpen] = useState(false);

  const copyLinkHandler = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setIsShareOpen(false);
  };

  return (
    <>
      {!!live && (
        <StreamerInfos>
          <SubmitReportModal visible={modalStore.submitReportVisible} />
          <Streamer>
            <img
              src={
                live.user.profilePicture?.path ? live.user.profilePicture?.path : '/assets/imgs/no-profile-picture.svg'
              }
              height={29}
              width={29}
              alt="Streamer profile picture"
            />
            <InfosContainer>
              <StreamerUsername>{live.user.pseudo}</StreamerUsername>
              <StreamerCategory>{locale('key_' + live.categories.find((category) => category))}</StreamerCategory>
            </InfosContainer>
          </Streamer>
          <ButtonsContainer>
            {isDesktop && (
              <PrimaryButton
                size="medium"
                onClick={() => openProfile(live.user.pseudo)}
                style={{ background: 'white', borderRadius: 8 }}
              >
                <Text bold color="black">
                  {locale('live.access_profile')}
                </Text>
              </PrimaryButton>
            )}
            <PrimaryButton
              size="large"
              isActive
              style={{ borderRadius: 8 }}
              onClick={() => {
                dispatch(
                  ModalSlice.actions.setSubmitReport({
                    visible: true,
                    type: 'live',
                    key: live.id,
                  })
                );
              }}
            >
              <Text bold>{isDesktop && locale('chat.report_user')}</Text>
            </PrimaryButton>
          </ButtonsContainer>
        </StreamerInfos>
      )}
    </>
  );
}

const StreamerInfos = styled.div`
  background-color: ${colors.dark};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  box-sizing: border-box;

  & img {
    border-radius: 4px;
  }
`;

const Streamer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StreamerUsername = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: ${fonts.bold.weight};
  color: ${colors.white};
`;

const StreamerCategory = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.lightGrey9};
`;

const CustomButton = styled.button<{ accent?: boolean }>`
  padding: 8px 32px;
  color: ${({ accent }) => (accent ? colors.white : colors.darkGrey2)};
  background-color: ${({ accent }) => (accent ? colors.darkOrange1 : colors.white)};
  border: 1px solid ${({ accent }) => (accent ? colors.darkOrange3 : colors.lightGrey9)};
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 10px 15px;
  }
`;
