import styled from "styled-components/macro";

import Text from "../../../components/style/text.component";
import Icons from "../../../components/style/icons.component";

import colors from "../../../themes/colors.theme";

type NotificationsSubMenuProps = {
  options: any[];
  closeMenu: () => void;
  style?: any;
};

const NotificationsSubMenu = ({
  options,
  closeMenu,
  style,
}: NotificationsSubMenuProps) => (
  <Menu style={style || undefined}>
    {options?.map((option) => (
      <MenuItem
        onClick={() => {
          option.onClick();
          closeMenu();
        }}
      >
        <Icons
          name={option.iconName}
          width="17"
          height="17"
          style={{ marginRight: 12 }}
        />
        <InlineText>{option.text}</InlineText>
      </MenuItem>
    ))}
  </Menu>
);

const Menu = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;

  background-color: ${colors.darkGreyHover};
  border-radius: 4px;
  padding: 4px;
  z-index: 10;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 30px;
  align-items: center;
  padding: 4px 16px;
  border-radius: 4px;

  &:hover {
    background-color: ${colors.darkGrey};
  }
`;

const InlineText = styled(Text)`
  white-space: nowrap;
`;

export default NotificationsSubMenu;
