import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Text from '../../../components/style/text.component';

import { initiatePrivateConversation } from '../../../services/chat.service';
import { toggleFavorite } from '../../../services/favorite.service';
import MeSlice from '../../../store/slices/me.slice';
import ModalSlice from '../../../store/slices/modal.slice';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import { useLogin } from '../../../utils/auth.utils';
import useLocale from '../../../utils/locale/locale.hook';

import { MenusShowed } from '../types/menus-showed.interface';
import { Mode } from '../types/mode.enum';
import { Flex } from '../../../components/style/flex.component';

enum ToggleFavoriteStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

interface ComponentProps {
  user: any;
  menusShowed: MenusShowed;
  setMenusShowed: React.Dispatch<React.SetStateAction<MenusShowed>>;
  openConversationHandler: (conversationId: number) => void;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  isAnonymous?: boolean;
}

export default function ProfileActions({
  user,
  menusShowed,
  setMenusShowed,
  openConversationHandler,
  mode,
  setMode,
  isAnonymous,
  children,
}: PropsWithChildren<ComponentProps>) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();
  const [toggleFavoriteStatus, setToggleFavoriteStatus] = useState(ToggleFavoriteStatus.IDLE);

  const isFavorite = useMemo(() => !!me?.favorites?.find((favorite: any) => favorite.id === user.id), [me?.favorites]);

  const toggleFavoriteHandler = (isFavorite: boolean) => {
    if (toggleFavoriteStatus === ToggleFavoriteStatus.LOADING) {
      return;
    }
    setToggleFavoriteStatus(ToggleFavoriteStatus.LOADING);

    toggleFavorite(user.id).then((res) => {
      setToggleFavoriteStatus(ToggleFavoriteStatus.IDLE);
      if (!res.error) {
        if (isFavorite) {
          dispatch(
            MeSlice.actions.updateMe({
              favorites: me.favorites.filter((favorite: any) => favorite.id !== user.id),
            })
          );
        } else {
          dispatch(
            MeSlice.actions.updateMe({
              favorites: me.favorites.concat(user),
            })
          );
        }
      }
    });
  };

  const togglePrivateConversationHandler = () => {
    if (mode === Mode.MESSAGES) {
      setMode(Mode.INFORMATIONS);
    } else {
      initiatePrivateConversation(user.id).then((res) => {
        if (!!res.error && !!res.message) {
          return;
        }
        openConversationHandler(res.conversationId || null);
      });
    }
  };

  return (
    <ActionsWrapper
      isAnonymous={isAnonymous}
      onClick={() => {
        isAnonymous && dispatch(ModalSlice.actions.setPremium({ visible: true }));
      }}
    >
      {children}
      {!isAnonymous ? (
        <>
          <Flex direction="column" alignItems="center">
            <img
              alt=""
              src={
                mode === Mode.MESSAGES
                  ? '/assets/icons/sidebar/navbar/message-active.svg'
                  : '/assets/icons/sidebar/navbar/message.svg'
              }
              title={locale('user_profile.actions.message.title')}
              onClick={togglePrivateConversationHandler}
            />
            <Text
              bold
              fontSize="8px"
              style={{ color: mode === Mode.MESSAGES ? colors.darkBlue : colors.darkText, marginTop: 4 }}
            >
              Chat
            </Text>
          </Flex>
          <Flex direction="column" alignItems="center">
            <img
              alt=""
              src={
                menusShowed.woof ? '/assets/icons/user-profile/woof-active.svg' : '/assets/icons/user-profile/woof.svg'
              }
              title={locale('user_profile.actions.woff.title')}
              onClick={() =>
                setMenusShowed((prevState) => ({
                  quickshare: false,
                  woof: !prevState.woof,
                }))
              }
            />
            <Text bold fontSize="8px" style={{ color: menusShowed.woof ? 'white' : colors.darkText, marginTop: 4 }}>
              Woof
            </Text>
          </Flex>

          <Flex direction="column" alignItems="center">
            <img
              alt=""
              src={
                isFavorite
                  ? '/assets/icons/user-profile/actions-profil-favorite-active.svg'
                  : '/assets/icons/user-profile/actions-profil-favorite.svg'
              }
              title={locale('user_profile.actions.favorite.title')}
              onClick={() => toggleFavoriteHandler(isFavorite)}
            />
            <Text
              bold
              fontSize="8px"
              style={{ color: isFavorite ? colors.darkOrange2 : colors.darkText, marginTop: 4 }}
            >
              Favoris
            </Text>
          </Flex>

          <Flex direction="column" alignItems="center">
            <img
              alt=""
              src={
                menusShowed.quickshare
                  ? '/assets/icons/sidebar/navbar/lock-album-active.svg'
                  : '/assets/icons/sidebar/navbar/lock-album.svg'
              }
              title={locale('user_profile.actions.quickshare.title')}
              onClick={() =>
                setMenusShowed((prevState) => ({
                  quickshare: !prevState.quickshare,
                  woof: false,
                }))
              }
            />
            <Text
              bold
              fontSize="8px"
              style={{ color: menusShowed.quickshare ? colors.darkBlue : colors.darkText, marginTop: 4 }}
            >
              Albums privés
            </Text>
          </Flex>
        </>
      ) : (
        <>
          <LockIconContainer>
            <img src="/assets/icons/user-profile/lock.svg" alt="Lock icon" />
          </LockIconContainer>
          <Text
            style={{
              color: colors.white,
              fontWeight: fonts.bold.weight,
              fontSize: 18,
            }}
          >
            Déverrouiller le profil
          </Text>
        </>
      )}
      {/* <Unavailable>
        <img
          alt=""
          src="/assets/icons/user-profile/actions-profil-vote.svg"
          title={locale("user_profile.actions.vote.title")}
        />
        <p>The votes for the Bear of the week will be available soon</p>
      </Unavailable> */}
    </ActionsWrapper>
  );
}

const ActionsWrapper = styled.div<{ isAnonymous: boolean }>`
  z-index: 2;
  position: absolute;
  padding: 12px 20px;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #16191e;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 347px;
  border-radius: 48px;
  border: 1px solid ${colors.lightGrey10};
  ${({ isAnonymous }) => isAnonymous && 'cursor: pointer; padding: 8px; justify-content: flex-start'};

  & img {
    cursor: pointer;
    width: 28px;
    height: 26px;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 1000px) {
    top: unset;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 7.5px;
    padding: ${({ isAnonymous }) => (isAnonymous ? '8px' : '4px 20px')};
    display: flex;
    align-items: center;
    justify-content: ${({ isAnonymous }) => (isAnonymous ? 'flex-start' : 'space-around')};
    width: 100%;
    max-width: 347px;
    box-sizing: border-box;
    height: 56px;
    position: fixed;

    & img {
      width: 24px;
      height: 24px;
    }
  }
`;

const Unavailable = styled.div`
  position: relative;

  & > p {
    width: 250px;
    position: absolute;
    right: 0px;
    margin: 0;
    background-color: #ffffff;
    color: #101113;
    font-size: 10px;
    padding: 7px;
    visibility: hidden;
  }

  & > img {
    cursor: pointer;
    width: 28px;
    height: 26px;
  }

  &:hover {
    & > p {
      visibility: visible;
    }
  }
`;

const LockIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.premiumYellow};
  border: 1px solid ${colors.darkOrange2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;

  & img {
    width: 18px;
    height: 18px;
  }
`;
