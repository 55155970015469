import ModalSlice from '../../store/slices/modal.slice';
import { store } from '../../store/configure.store';
import { getUrlLanguage } from '../../utils/locale/hreflang.utils';

export const openProfile = (
  pseudo: string,
  fromProfile = false,
  isAnonymous?: boolean,
  isFromSharedAlbum?: boolean
) => {
  const isMobile = window.innerWidth < 1000;
  if (!(window as any).savedTop) {
    if (isMobile) {
      document.body.style.position = 'fixed';
    }
  }
  window.history.pushState(null, '', getUrlLanguage() + '/user/' + pseudo);

  const encodedPseudo = encodeURIComponent(pseudo);

  store.dispatch(
    ModalSlice.actions.setIsUserProfileAnonymousAndPseudo({
      isAnonymous: isAnonymous,
      pseudo: encodedPseudo,
      userProfileVisible: true,
      isFromSharedAlbum: isFromSharedAlbum,
    })
  );
};

export const closeProfile = () => {
  const isMobile = window.innerWidth < 1000;
  if (isMobile) {
    document.body.style.position = '';
  }

  store.dispatch(
    ModalSlice.actions.setIsUserProfileAnonymousAndPseudo({
      isAnonymous: false,
      pseudo: '',
      userProfileVisible: false,
    })
  );
};

export const closeProfileGoBackHistory = () => {
  const isMobile = window.innerWidth < 1000;
  if (isMobile) {
    document.body.style.position = '';
  }

  window.history.back();

  store.dispatch(
    ModalSlice.actions.setIsUserProfileAnonymousAndPseudo({
      isAnonymous: false,
      pseudo: '',
      userProfileVisible: false,
    })
  );
};
