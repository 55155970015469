import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../../components/forms-v2/button.component';
import Text from '../../../components/style/text.component';

import ModalSlice from '../../../store/slices/modal.slice';
import useLocale from '../../../utils/locale/locale.hook';
import { toggleBlock } from '../../../services/users.service';

import fonts from '../../../themes/fonts.theme';
import colors from '../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { UserModalTemplate } from '../../../pages/user-profile/components/user-modal.component';
import { Flex } from '../../style/flex.component';
import { PrimaryButton } from '../../forms/primaryButton.component';
import { useNotification } from '../../../utils/useNotification';

enum BlockStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
}

export default function BlockUserModal({ visible, onClose, style }: ModalProps) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { blockUser } = useSelector((state: any) => state.modalStore);
  const [status, setStatus] = useState(BlockStatus.ERROR);
  const [errorMessage, setErrorMessage] = useState('');
  const { triggerNotification } = useNotification();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const submitBlockHandler = async () => {
    if (status === BlockStatus.LOADING || status === BlockStatus.SUCCESS) {
      return;
    }

    setStatus(BlockStatus.LOADING);

    const res = await toggleBlock(blockUser.userId);
    if (res?.blocked) {
      triggerNotification({
        visible: true,
        title: locale('blocking.profile_tootip.success_notice'),
      });
      closeModal();
    } else {
      const errorMessage = locale(res.error) || locale('report.errors.error_occured');
      setErrorMessage(errorMessage);
      setStatus(BlockStatus.ERROR);
    }
  };

  const closeModal = () => {
    if (!onClose) {
      dispatch(
        ModalSlice.actions.setBlockUser({
          visible: false,
          userId: null,
        })
      );
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!!visible && blockUser.key === null) {
      closeModal();
    }
  }, [visible, blockUser]);

  useEffect(() => {
    if (!visible) {
      setStatus(BlockStatus.IDLE);
      setErrorMessage('');
    }
  }, [visible]);

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModal}
      style={{
        width: isDesktop ? '376px' : '95%',
        borderRadius: 16,
        height: 'fit-content',
        background: colors.darkGray,
        bottom: 80,
        border: `1px solid ${colors.grayBorder}`,
        padding: '14px 24px',
        ...style,
      }}
      title={
        <Flex alignItems="center" gap="16px">
          <img
            src="/assets/icons/nearby/chevron-right.svg"
            alt="chevron"
            style={{ width: 8, height: 14, transform: 'rotate(180deg)' }}
            onClick={closeModal}
          />
          <Title>{locale('blocking.profile_tootip.title')}</Title>
        </Flex>
      }
    >
      <ContentWrapper>
        <>
          <MarginWrapper>
            <Text color="white">{locale('blocking.profile_tootip.warning')}</Text>
          </MarginWrapper>
          <MarginWrapper>
            <Text color="white">{locale('blocking.profile_tootip.info_text')}</Text>
          </MarginWrapper>
          {status === BlockStatus.ERROR && (
            <MarginWrapper>
              <Text>{errorMessage}</Text>
            </MarginWrapper>
          )}
          <PrimaryButton
            size="large"
            style={{
              width: '100%',
            }}
            isActive={true}
            onClick={() => submitBlockHandler()}
          >
            {locale('blocking.profile_tootip.confirmation_message')}
          </PrimaryButton>
        </>
      </ContentWrapper>
    </UserModalTemplate>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: ${colors.black};

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & p {
    font-size: 12px;
  }
`;

const Title = styled.div`
  margin: 0 auto;
  font-size: 16px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
`;

const MarginWrapper = styled.div`
  margin-bottom: 16px;
`;
