import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";

import ModalTemplate from "../../components/miscellaneous/modal-template.component";
import colors from "../../themes/colors-v2.theme";
import fonts from "../../themes/fonts.theme";
import useLocale from "../../utils/locale/locale.hook";

export const PremiumSuccessModal = (props) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [toClose, setToClose] = useState(false);

  const onClose = () => {
    setToClose(true);
  };

  if (toClose) return <Redirect to="/" />;

  return (
    <ModalTemplate
      visible={props.visible}
      closeModal={onClose}
      style={{ borderRadius: isDesktop ? "8px" : 0 }}
      fullsize={!isDesktop}
    >
      <ModalContent>
        <Title>{locale("premium.subscription_thank_you")}</Title>
        <Line>
          <img
            width="20px"
            height="20px"
            src="/assets/icons/user-profile/premium.svg"
          />
          <Text>{locale("premium.subscription_success")}</Text>
        </Line>
        <Button onClick={onClose}>{locale("global.close")}</Button>
      </ModalContent>
    </ModalTemplate>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 20px;
`;

const Title = styled.div`
  color: ${colors.black};
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 30px;
  padding-bottom: 25px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  text-align: center;

  & img {
    margin-right: 12px;
  }
`;

const Text = styled.div`
  color: ${colors.black};
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 18px;
`;

const Button = styled.button`
  width: 250px;
  height: 32px;
  background-color: ${colors.darkOrange1};
  color: ${colors.white};
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
