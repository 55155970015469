import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

// import ModalSlice from "../../../../../store/slices/modal.slice";
// import { checkEphemeral } from "../../../../../services/chat.service";
// import ChatSlice from "../../../../../store/slices/chat.slice";
import { getConversationInfo } from '../../../../../utils/chat.utils';
import { useLogin } from '../../../../../utils/auth.utils';
import ChatSlice from '../../../../../store/slices/chat.slice';

import fonts from '../../../../../themes/fonts.theme';
import colors from '../../../../../themes/colors.theme';

interface EphemeralMessageProps {
  message: any;
  sender: 'other' | 'me';
  conversation: any;
}

export default function LocationMessage({ message, sender, conversation }: EphemeralMessageProps) {
  const dispatch = useDispatch();
  const { me } = useLogin();
  const { conversationName } = getConversationInfo(me, conversation);

  return (
    <Wrapper sender={sender}>
      <Preview
        onClick={() =>
          dispatch(
            ChatSlice.actions.setMapLocation({
              visible: true,
              latitude: message.location.latitude,
              longitude: message.location.longitude,
            })
          )
        }
      >
        <img alt="location preview" src="/assets/imgs/messages/location-preview.png" width="100" height="100" />
      </Preview>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ sender: 'me' | 'other' }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px 8px;
  max-width: 90%;
  min-width: 200px;

  & > *:first-child {
    margin-bottom: 4px;
  }

  & > *:only-child {
    margin-bottom: 0;
  }
`;

const Preview = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;

  & > img {
    border-radius: 4px;
    object-fit: contain;
  }
`;
