import { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';

import PasswordInput from '../../../../components/forms/password.input';
import Text from '../../../../components/style/text.component';
import { InlineForm } from './inline-form.component';

import { updatePassword } from '../../../../services/auth.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { SubmitButton } from '../settings-styled.component';
import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { useNotification } from '../../../../utils/useNotification';

interface Props {}
const PasswordGestion = (props: Props) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { triggerNotification } = useNotification();

  const submit = async () => {
    setErrorMsg(null);
    const res = await updatePassword(oldPassword, newPassword);
    if (res?.error) {
      if (res?.error === 'global.error.unauthorized') {
        setErrorMsg('wrong_password_entered');
      } else {
        setErrorMsg(res?.error);
      }
      return;
    }
    triggerNotification({
      title: locale('settings.modification_success'),
    });

    setOldPassword('');
    setNewPassword('');
  };

  return (
    <Container>
      <SectionTitle>{locale('account.account_gestion.password.title')}</SectionTitle>
      <InlineForm style={!isDesktop ? { display: 'flex', flexDirection: 'column', gap: 12 } : null}>
        <PasswordInput
          value={oldPassword}
          onChange={(text) => setOldPassword(text)}
          placeholder={locale('account.account_gestion.old_password')}
          style={{
            gridColumn: 1,
          }}
          borderRadius={8}
        />
        <PasswordInput
          value={newPassword}
          onChange={(text) => setNewPassword(text)}
          placeholder={locale('account.account_gestion.new_password')}
          style={{
            gridColumn: 2,
          }}
          borderRadius={8}
        />
        {errorMsg && <Error>{locale(errorMsg)}</Error>}
      </InlineForm>
      <PrimaryButton
        isActive
        disabled={!oldPassword || !oldPassword.length || !newPassword || newPassword.length <= 1}
        onClick={() => submit()}
        size="large"
        style={{ marginTop: 12, height: 36 }}
      >
        {locale('account.account_gestion.submit')}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionTitle = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
`;

const Error = styled.div`
  grid-column: 1;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: #ff5050;
  margin: 15px 0 8px;
  padding: 12px 14px;
  font-size: 12px;
`;

export default PasswordGestion;
