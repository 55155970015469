import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import { getNearbyCategory } from '../../services/nearby.service';
import { useLogin } from '../../utils/auth.utils';
import { getLocation } from '../../utils/geolocation.utils';
import { getQueryFilters } from './filters.utils';

import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { debounce } from 'lodash';
import { PrimaryButton } from '../../components/forms/primaryButton.component';
import SearchBar from '../../components/forms/search.component';
import MetaData from '../../components/seo/meta-data.component';
import { Flex } from '../../components/style/flex.component';
import { Loader } from '../../components/style/loader.component';
import ScreenWrapper from '../../components/style/screen-wrapper.component';
import { Tab } from '../../components/tabs/Tab';
import { Tabs } from '../../components/tabs/Tabs';
import { getCommunities, getSexualPractices, getSexualRole } from '../../services/caracteristics.service';
import { getUserBySearch, updateUser } from '../../services/users.service';
import ModalSlice from '../../store/slices/modal.slice';
import NavbarSlice from '../../store/slices/navbar.slice';
import colors from '../../themes/colors-v2.theme';
import { useOnClickOutside } from '../../utils/clickOutside.utils';
import useLocale from '../../utils/locale/locale.hook';
import { CustomGrid } from './components/custom-grid.component';
import { RightSidebarAdvancedFilters } from './components/filters/right-sidebar-filters.component';
import { useFiltersCount } from './components/filters/useHasFilters';
import Headers from './components/headers.component';

import { useHistory } from 'react-router-dom';
import IosSwitch from '../../components/ios-switch/ios-switch.component';
import { Divider } from '../../components/miscellaneous/divider.component';
import Icons from '../../components/style/icons.component';
import Text from '../../components/style/text.component';
import { getHashtags } from '../../services/hashtags.service';
import MeSlice from '../../store/slices/me.slice';
import UserFiltersSlice from '../../store/slices/userfilter.slice';
import { useRedirectUnsignedUser } from '../../utils/useRedirectUnsignedUser';
import { useScrollDisplayArrows, useScrollUpState } from '../../utils/useScroll';
import { UserModalTemplate } from '../user-profile/components/user-modal.component';
import { HashtagQuickFiltersSidebar } from './components/filters/hashtag-quick-filters-sidebar.component';
import { QuickFiltersSidebar } from './components/filters/quick-filters-sidebar.component';
import { RandomProfilesFilters } from './components/filters/random-profiles-filters.component';

type Tabs = 'aroundMe' | 'newFaces' | 'communities';

export type FiltersType = {
  status?: string[];
  withProfilePictureOnly?: boolean;
  isNew?: boolean;
  ageMin?: number;
  ageMax?: number;
  heightMin?: number;
  heightMax?: number;
  weightMin?: number;
  weightMax?: number;
  sexualRole?: string[];
  communities?: any[];
  lookingFor?: any[];
  sexualPractices?: any[];
  hashtags?: number[];
  ethnicities?: any[];
  facialHair?: any[];
  appearance?: any[];
  languages?: any[];
  lat?: number;
  lng?: number;
  withVideos?: boolean;
  isMeetNow?: boolean;
};

export type CellType = 'rectangle' | 'square' | 'bigSquare';

const NearbyScreenV2 = () => {
  useRedirectUnsignedUser();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { isLogin, me } = useLogin();
  const dispatch = useDispatch();
  const locale = useLocale();
  const history = useHistory();

  const [users, setUsers] = useState({ users: [] });
  const [coords, setCoords] = useState(null);
  const filters = useSelector((state: any) => state.userFiltersStore);
  const setFilters = (_filters: any) => dispatch(UserFiltersSlice.actions.setFilters(_filters));
  const setFiltersWithReset = (_filters: any) => dispatch(UserFiltersSlice.actions.setFiltersWithReset(_filters));

  const [areFiltersDeactivated, setAreFiltersDeactivated] = useState(false);
  const [displayGeoloPerm, setDisplayGeoloPerm] = useState(false);
  const [activeTab, setActiveTab] = useState<Tabs>('aroundMe');

  const [cellType, setCellType] = useState<CellType>('rectangle');
  const [communityKey, setCommunityKey] = useState('All');
  const [hasMore, setHasMore] = useState(true);
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult>();
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [communityChoiceOpen, setCommunityChoiceOpen] = useState(false);
  const [sexualRoleOpen, setSexualRoleOpen] = useState(false);
  const [sexualPracticesOpen, setSexualPracticesOpen] = useState(false);
  const [hashtagsOpen, setHashtagsOpen] = useState(false);
  const [isNowModalOpen, setIsNowModalOpen] = useState(false);
  const [isMeetNow, setIsMeetNow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const visible = useScrollUpState();

  const isLoadingRef = useRef(false);
  const filtersRef = useRef(null);
  const filtersRandomRef = useRef(null);

  const quickFiltersRef = useRef(null);
  const navbarStore = useSelector((state: any) => state.navbarStore);
  const nearbyStore = useSelector((state: any) => state.nearbyStore);
  const filtersCount = useFiltersCount(filters as any);

  const { displayLeftArrow, displayRightArrow, handleScroll, handleArrowClick } = useScrollDisplayArrows(filtersRef);
  const {
    displayLeftArrow: displayLeftArrowRandom,
    displayRightArrow: displayRightArrowRandom,
    handleScroll: handleScrollRandom,
    handleArrowClick: handleArrowClickRandom,
  } = useScrollDisplayArrows(filtersRandomRef);

  const handleSearchUser = async (search: string, pageParam: number) => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    const query: any = getQueryFilters(areFiltersDeactivated ? {} : filters);
    if (selectedAddress?.raw.lat && selectedAddress?.raw.lon) {
      query.latitude = selectedAddress.raw.lat;
      query.longitude = selectedAddress.raw.lon;
    } else {
      if (isLogin) {
        query.latitude = me.latitude;
        query.longitude = me.longitude;
      } else {
        if (coords) {
          query.latitude = coords.latitude;
          query.longitude = coords.longitude;
        }
      }
    }
    query.limit = 70;
    query.page = pageParam; // using page from react-infinite-scroller causes a bug
    query.query = search;
    const data =
      filters.isNew && activeTab === 'aroundMe'
        ? await getNearbyCategory('newFaces', query)
        : await getUserBySearch(query);
    setUsers(data);
    setHasMore(data?.length === query.limit);
    setPage(page + 1);
    isLoadingRef.current = false;
    setIsSearchResults(true);
  };

  const loadMoreProfiles = async (pageParam: number, activeTabParam: Tabs) => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    const query: any = getQueryFilters(areFiltersDeactivated ? {} : filters);
    if (activeTabParam == 'communities') {
      if (communityKey !== 'All') {
        query['communities'] = communityKey;
      }
    } else {
      if (selectedAddress?.raw.lat && selectedAddress?.raw.lon) {
        query.latitude = selectedAddress.raw.lat;
        query.longitude = selectedAddress.raw.lon;
      } else {
        if (isLogin) {
          query.latitude = me.latitude;
          query.longitude = me.longitude;
        } else {
          if (coords) {
            query.latitude = coords.latitude;
            query.longitude = coords.longitude;
          }
        }
      }
    }
    if (activeTabParam == 'aroundMe') {
      query.withSoonTravellers = true;
    }
    query.limit = 140;
    query.page = pageParam;

    const data = filters.isNew
      ? await getNearbyCategory('newFaces', query)
      : await getNearbyCategory(activeTabParam, query);
    setUsers((_data) => ({ users: _data?.users.concat(data?.users) }));
    setHasMore(data.users?.length === query.limit);
    setPage((page) => page + 1);
    isLoadingRef.current = false;
  };

  const handleSwitchChange = async (isOn: boolean) => {
    const status = isOn ? 'incognito' : 'online';
    const res = await updateUser({ status: status });
    if (res.success === true) {
      dispatch(MeSlice.actions.updateMe({ status: status }));
    } else if (res.error === 'premium.incognitoMode.error') {
      dispatch(ModalSlice.actions.setPremium({ visible: true }));
    } else {
      // DEBUG: server error to be handled
    }
  };

  const getPermission = async () => {
    await getLocation((res) => setCoords(res), setDisplayGeoloPerm);
  };

  useEffect(() => {
    if (searchValue.length !== 0) return;
    setPage(0);
    setUsers({ users: [] });
    loadMoreProfiles(0, activeTab);
  }, [filters, areFiltersDeactivated, searchValue]);

  useEffect(() => {
    setUsers({ users: [] });
  }, [activeTab]);

  useEffect(() => {
    if (!isDesktop) dispatch(NavbarSlice.actions.setFiltersOpen('close'));
  }, [isDesktop]);

  useEffect(() => {
    const debouncedSearch = debounce((value: string) => {
      if (value.length > 2) {
        setPage(0);
        handleSearchUser(value, 0);
      }
    }, 500);

    debouncedSearch(searchValue);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    const query = location.search;
    const search = query.split('=');
    const paymentResult = search[0] === '?payment' && search.length > 1 ? search[1] : '';
    if (paymentResult === 'success') {
      dispatch(ModalSlice.actions.setPremiumSuccess({ visible: true }));
    }
    if (paymentResult === 'failure') {
      dispatch(ModalSlice.actions.setPremiumError({ visible: true }));
    }
  }, []);

  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    const twoHoursAgo = new Date(new Date().getTime() - 2 * 60 * 60 * 1000);
    setIsMeetNow(new Date(me.nowCreatedAt) > twoHoursAgo);
  }, [me.nowCreatedAt]);

  useLayoutEffect(() => {
    if (users.users.length > 0) {
      window.scroll({ top: (window as any).savedTop });
      // if finished scrolling on multiple pages
      if (window.scrollY === (window as any).savedTop) {
        (window as any).savedTop = undefined;
      }
    }
  }, [users]);

  useOnClickOutside([quickFiltersRef], () => {
    if (isDesktop) {
      setCommunityChoiceOpen(false);
      setSexualPracticesOpen(false);
      setSexualRoleOpen(false);
      setHashtagsOpen(false);
    }
  });

  const handleMeetNow = async () => {
    if (isMeetNow) {
      await updateUser({ nowCreatedAt: null });
      setIsMeetNow(false);
    } else {
      await updateUser({ nowCreatedAt: new Date() });
      setIsMeetNow(true);
    }
  };

  const resetFiltersState = () => {
    setAreFiltersDeactivated(false);
  };

  if (!isLogin) return <div></div>;

  const refresh = () => {
    setUsers({ users: [] });
    loadMoreProfiles(0, activeTab);
    setPage(0);
  };

  return (
    <ScreenWrapper>
      {/* {displayGeoloPerm && <NoGeolocationPermission onDone={() => setDisplayGeoloPerm(false)} />} */}
      <MetaData title={locale('app_name')} description={locale('signin.landing.description1')} />
      {users && (
        <MainWrapper>
          <div ref={quickFiltersRef}>
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setAreFiltersDeactivated(false);
                resetFiltersState();

                if (!areFiltersDeactivated) {
                  setFilters({ communities: changeFilters });
                } else {
                  setFiltersWithReset({ communities: changeFilters });
                }
              }}
              dataSelected={filters['communities'] || []}
              getData={async () => await getCommunities()}
              keyName={'communityKey'}
              phraseKey={'community'}
              isOpen={communityChoiceOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
                setHashtagsOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setAreFiltersDeactivated(false);
                resetFiltersState();

                if (!areFiltersDeactivated) {
                  setFilters({ sexualRole: changeFilters });
                } else {
                  setFiltersWithReset({ sexualRole: changeFilters });
                }
              }}
              dataSelected={filters['sexualRole'] || []}
              getData={async () => await getSexualRole()}
              keyName={'sexualRoleKey'}
              phraseKey={'sexual_role'}
              isOpen={sexualRoleOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
                setHashtagsOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setAreFiltersDeactivated(false);
                resetFiltersState();

                if (!areFiltersDeactivated) {
                  setFilters({ sexualPractices: changeFilters });
                } else {
                  setFiltersWithReset({ sexualPractices: changeFilters });
                }
              }}
              dataSelected={filters['sexualPractices'] || []}
              getData={async () => await getSexualPractices()}
              keyName={'sexualPracticeKey'}
              phraseKey={'sexual_practice'}
              isOpen={sexualPracticesOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
                setHashtagsOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />

            <HashtagQuickFiltersSidebar
              onChange={(changeFilters) => {
                setAreFiltersDeactivated(false);
                resetFiltersState();
                if (!areFiltersDeactivated) {
                  setFilters({ hashtags: changeFilters });
                } else {
                  setFiltersWithReset({ hashtags: changeFilters });
                }
              }}
              dataSelected={filters['hashtags'] || []}
              getData={async () => await getHashtags()}
              isOpen={hashtagsOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
                setHashtagsOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
          </div>
          <RightSidebarAdvancedFilters
            filters={filters}
            setFilters={setFilters}
            filtersOpen={navbarStore.filtersOpen}
            setFiltersOpen={(str: string) => dispatch(NavbarSlice.actions.setFiltersOpen(str))}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            setIsSearchResults={setIsSearchResults}
            handleSearchUser={setSearchValue}
            searchUser={searchValue}
            areFiltersDeactivated={areFiltersDeactivated}
            setAreFiltersDeactivated={setAreFiltersDeactivated}
          />
          <Content filtersOpen={navbarStore.filtersOpen} isDesktop={isDesktop}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 16px 10px 16px',
                width: isDesktop ? 'calc(100vw - 250px)' : '100%',
                boxSizing: 'border-box',
                position: 'fixed',
                zIndex: 10,
                background: colors.darkGradient,
                backgroundAttachment: 'fixed',
                top: 0,
              }}
            >
              {isDesktop ? (
                <Flex gap={'16px'} alignItems="center" justify="space-between" style={{ width: '100%' }}>
                  <Flex alignItems="center" gap="16px">
                    <img src="/assets/icons/sidebar/navbar/nearby-white.svg" alt="radar" width={24} height={24} />
                    <div style={{ fontWeight: 600, fontSize: '16px', color: 'white' }}>{locale('title_radar')}</div>
                  </Flex>
                  {me.role !== 'premium' ? (
                    <PremiumButton
                      onClick={() =>
                        dispatch(ModalSlice.actions.setPremium({ visible: true }))
                      }
                    >
                      {locale("grid.premium.subscribe")}
                    </PremiumButton>
                  ) : null}
                  <Flex alignItems="center" gap="16px">
                    <Flex alignItems="center">
                      <IosSwitch isOn={me.status === 'incognito'} onChange={handleSwitchChange} />

                      <Icons
                        name="status-incognito-dark"
                        width="16"
                        height="16"
                        style={{
                          marginRight: 7,
                          marginLeft: 7,
                          padding: '5px 0px 3px 0px',
                        }}
                      />
                      <Text>{locale('account.status.incognito')}</Text>
                    </Flex>
                    <PrimaryButton size="medium" onClick={() => setIsNowModalOpen(true)} isActive={isMeetNow}>
                      {isMeetNow ? (
                        <img src="/assets/icons/nearby/lightning-white.svg" alt="refresh icon" width={18} height={18} />
                      ) : (
                        <img src="/assets/icons/nearby/lightning-gray.svg" alt="refresh icon" width={18} height={18} />
                      )}
                      {locale('filter_now')}
                    </PrimaryButton>

                    <SearchBar
                      onChange={setSearchValue}
                      value={searchValue}
                      placeholder={locale('radar.search.placeholder')}
                    />
                  </Flex>
                </Flex>
              ) : null}

              {!isDesktop ? (
                <MobileHeader visible={visible}>
                  <ProfilePictureWrapper onClick={() => history.push('/profile')}>
                    <ProfilePicture alt="" src={me?.profilePicture?.path} width="36" height="36" />
                  </ProfilePictureWrapper>
                  <SearchInputContainer onClick={() => history.push('/explore')}>
                    <ResetLocationSearch
                      width="9px"
                      height="13.33px"
                      src="/assets/icons/sidebar/navbar/explore-gray.svg"
                    />
                    <LocationTextInput placeholder={locale('guide.form.city.label')} />
                  </SearchInputContainer>
                  <PrimaryButton size="medium" onClick={() => setIsNowModalOpen(true)} isActive={isMeetNow}>
                    {isMeetNow ? (
                      <img src="/assets/icons/nearby/lightning-white.svg" alt="refresh icon" width={18} height={18} />
                    ) : (
                      <img src="/assets/icons/nearby/lightning-gray.svg" alt="refresh icon" width={18} height={18} />
                    )}
                  </PrimaryButton>
                  <PrimaryButton
                    size="medium"
                    onClick={() =>
                      history.push({
                        pathname: '/trips',
                        state: { tab: 'myTrips' },
                      })
                    }
                  >
                    <img src="/assets/icons/sidebar/navbar/calendar-white.svg" />
                  </PrimaryButton>
                </MobileHeader>
              ) : null}
            </div>
            <Tabs isFixed isScrollUp={true}>
              <Tab
                title={locale('nearby.category.aroundYou')}
                onClick={() => {
                  loadMoreProfiles(0, 'aroundMe');
                  setActiveTab('aroundMe');
                }}
                style={{ flex: 1 }}
              >
                <FilterContainer
                  visible={visible}
                  style={{
                    width: isDesktop ? 'calc(100vw - 250px)' : 'calc(100vw)',
                    position: 'fixed',
                    alignItems: 'center',
                    top: 88,
                    background: colors.darkGradient,
                    backgroundAttachment: 'fixed',
                    zIndex: 10,
                    padding: '16px 8px',
                    boxSizing: 'border-box',
                  }}
                >
                  <CellFormatContainer>
                    <img
                      src={`/assets/icons/nearby/rectangle${cellType === 'rectangle' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('rectangle')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/square${cellType === 'square' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('square')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/bigSquare${cellType === 'bigSquare' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('bigSquare')}
                      width={24}
                      height={24}
                    />
                  </CellFormatContainer>
                  <Divider />
                  <FiltersListArrowWrapper onScroll={handleScroll}>
                    {displayLeftArrow ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', left: 0, top: 0, zIndex: 10, transform: 'rotate(180deg)' }}
                        onClick={() => handleArrowClick('left')}
                      >
                        <img
                          src="/assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-left"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    {displayRightArrow ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                        onClick={() => handleArrowClick('right')}
                      >
                        <img
                          src="/assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-right"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    <FiltersListWrapper ref={filtersRef}>
                      <Headers
                        setFilters={setFilters}
                        filtersOpen={navbarStore.filtersOpen}
                        setFiltersOpen={(str: string) => dispatch(NavbarSlice.actions.setFiltersOpen(str))}
                        filtersCount={filtersCount}
                        areFiltersDeactivated={areFiltersDeactivated}
                      />

                      <PrimaryButton
                        isActive={!areFiltersDeactivated && filters.status?.includes('online')}
                        onClick={() => {
                          setAreFiltersDeactivated(false);
                          resetFiltersState();
                          const isOnline = filters.status?.includes('online');
                          if (!areFiltersDeactivated) {
                            setFilters({ status: isOnline ? [] : ['online'] });
                          } else {
                            setFiltersWithReset({ status: ['online'] });
                          }
                        }}
                      >
                        {locale('profile.status.connected')}
                      </PrimaryButton>

                      <PrimaryButton
                        isActive={filters.isMeetNow && !areFiltersDeactivated}
                        onClick={() => {
                          setAreFiltersDeactivated(false);
                          resetFiltersState();
                          if (!areFiltersDeactivated) {
                            setFilters({ isMeetNow: !filters.isMeetNow });
                          } else {
                            setFiltersWithReset({ isMeetNow: true });
                          }
                        }}
                      >
                        {locale('filters.meet_now')}
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setSexualRoleOpen(false);
                          setCommunityChoiceOpen(false);
                          setSexualPracticesOpen(false);
                          setHashtagsOpen((open) => !open);
                        }}
                        isActive={filters['hashtags']?.length > 0 && !areFiltersDeactivated}
                      >
                        Hashtags
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>

                      <PrimaryButton
                        isActive={filters.isNew && !areFiltersDeactivated}
                        onClick={() => {
                          setAreFiltersDeactivated(false);
                          resetFiltersState();
                          if (!areFiltersDeactivated) {
                            setFilters({ isNew: !filters.isNew });
                          } else {
                            setFiltersWithReset({ isNew: true });
                          }
                        }}
                      >
                        {locale('nearby.new_faces')}
                      </PrimaryButton>

                      <PrimaryButton
                        isActive={filters.withProfilePictureOnly && !areFiltersDeactivated}
                        onClick={() => {
                          setAreFiltersDeactivated(false);
                          resetFiltersState();
                          if (!areFiltersDeactivated) {
                            setFilters({ withProfilePictureOnly: !filters.withProfilePictureOnly });
                          } else {
                            setFiltersWithReset({ withProfilePictureOnly: true });
                          }
                        }}
                      >
                        {locale('radar.search.with_picture.title')}
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setCommunityChoiceOpen((open) => !open);
                          setSexualPracticesOpen(false);
                          setSexualRoleOpen(false);
                        }}
                        isActive={filters['communities']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('nearby.communities')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setSexualRoleOpen((open) => !open);
                          setCommunityChoiceOpen(false);
                          setSexualPracticesOpen(false);
                        }}
                        isActive={filters['sexualRole']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('radar.filter.positions')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setSexualPracticesOpen((open) => !open);
                          setCommunityChoiceOpen(false);
                          setSexualRoleOpen(false);
                        }}
                        isActive={filters['sexualPractices']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('sexualPractices')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>
                    </FiltersListWrapper>
                  </FiltersListArrowWrapper>
                </FilterContainer>

                <SectionContainer>
                  <InfiniteScroll
                    dataLength={users?.users?.length}
                    next={() => loadMoreProfiles(page, 'aroundMe')}
                    hasMore={hasMore}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Refresh</h3>}
                    releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Refresh</h3>}
                    refreshFunction={() => {
                      setUsers({ users: [] });
                      loadMoreProfiles(0, 'aroundMe');
                    }}
                    key={infiniteScrollKey}
                    loader={
                      <div style={{ display: 'flex', justifyContent: 'center' }} key={Math.random().toString()}>
                        <Loader color={colors.primary} key={Math.random().toString()} />
                      </div>
                    }
                    style={{ marginTop: !isDesktop ? 25 : 0 }}
                  >
                    <CustomGrid users={users?.users} cellType={cellType} showBoostAd={!me.isBoosted} showProfilePicture />
                  </InfiniteScroll>
                </SectionContainer>
              </Tab>
              <Tab
                title={locale('nearby.category.random')}
                onClick={() => {
                  setUsers({ users: [] });
                  loadMoreProfiles(0, 'communities');
                  setActiveTab('communities');
                }}
                style={{
                  flex: 1,
                }}
              >
                <FilterContainer
                  visible={visible}
                  style={{
                    width: isDesktop ? 'calc(100vw - 250px)' : 'calc(100vw)',
                    position: 'fixed',
                    display: 'flex',
                    top: 88,
                    alignItems: 'center',
                    background: colors.darkGradient,
                    backgroundAttachment: 'fixed',
                    zIndex: 10,
                    padding: '16px 8px',
                    boxSizing: 'border-box',
                  }}
                >
                  <CellFormatContainer>
                    <img
                      src={`/assets/icons/nearby/rectangle${cellType === 'rectangle' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('rectangle')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/square${cellType === 'square' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('square')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/bigSquare${cellType === 'bigSquare' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('bigSquare')}
                      width={24}
                      height={24}
                    />
                  </CellFormatContainer>
                  <Divider />
                  <FiltersListArrowWrapper onScroll={handleScrollRandom}>
                    {displayLeftArrowRandom ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', left: 0, top: 0, zIndex: 10, transform: 'rotate(180deg)' }}
                        onClick={() => handleArrowClickRandom('left')}
                      >
                        <img
                          src="assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-left"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    {displayRightArrowRandom ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                        onClick={() => handleArrowClickRandom('right')}
                      >
                        <img
                          src="assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-right"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    <FiltersListWrapper ref={filtersRandomRef}>
                      <RandomProfilesFilters
                        onChange={(_filters) => {
                          const lastFilter = _filters.splice(-1) || [];
                          setFilters({
                            ...filters,
                            communities: lastFilter,
                          });
                        }}
                        dataSelected={filters['communities'] || []}
                        getData={async () => await getCommunities()}
                        keyName={'communityKey'}
                        phraseKey={'community'}
                      />
                    </FiltersListWrapper>
                  </FiltersListArrowWrapper>
                </FilterContainer>

                <SectionContainer>
                  <InfiniteScroll
                    dataLength={users?.users?.length}
                    next={() => loadMoreProfiles(page, 'communities')}
                    hasMore={hasMore}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Refresh</h3>}
                    releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Refresh</h3>}
                    refreshFunction={() => {
                      setUsers({ users: [] });
                      loadMoreProfiles(0, 'communities');
                    }}
                    key={infiniteScrollKey}
                    loader={
                      <div style={{ display: 'flex', justifyContent: 'center' }} key={Math.random().toString()}>
                        <Loader color={colors.primary} key={Math.random().toString()} />
                      </div>
                    }
                    style={{ marginTop: !isDesktop ? 25 : 0, overflowY: 'hidden' }}
                  >
                    <CustomGrid users={users?.users} cellType={cellType} />
                  </InfiniteScroll>
                </SectionContainer>
              </Tab>
            </Tabs>
          </Content>
          {!isDesktop && (
            <RoundButtonsContainer>
              <RoundButtonContainer onClick={refresh}>
                <img src="/assets/icons/nearby/refresh.svg" alt="refresh icon" width={20} height={18} />
              </RoundButtonContainer>
              <RoundButtonContainer onClick={() => scrollTo({ top: 0 })}>
                <img src="/assets/icons/nearby/arrow-up.svg" alt="refresh icon" width={24} height={24} />
              </RoundButtonContainer>
            </RoundButtonsContainer>
          )}
        </MainWrapper>
      )}
      <UserModalTemplate
        isOpen={isNowModalOpen}
        onClose={() => {
          setIsNowModalOpen(false);
        }}
        style={{
          borderRadius: 8,
          width: isDesktop ? '465px' : '90%',
          height: 'fit-content',
          background: colors.darkGray,
          position: 'fixed',
          border: `1px solid ${colors.lightGrey10}`,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <ModalContainer>
          <PrimaryButton size="medium" isActive={isMeetNow} style={{ cursor: 'default' }}>
            {isMeetNow ? (
              <img src="/assets/icons/nearby/lightning-white.svg" alt="refresh icon" width={18} height={18} />
            ) : (
              <img src="/assets/icons/nearby/lightning-gray.svg" alt="refresh icon" width={18} height={18} />
            )}
            {locale('filter_now')}
          </PrimaryButton>
          <Text bold fontSize="16px" style={{ textAlign: 'center' }}>
            {!isMeetNow ? locale('nearby.modal.now.title-deactivated') : locale('nearby.modal.now.title-activated')}
          </Text>
          <Text style={{ textAlign: 'center' }}>{locale('nearby.modal.now.description-deactivated')}</Text>
          <PrimaryButton
            size="large"
            isActive={true}
            onClick={handleMeetNow}
            style={{ background: isMeetNow && 'white', color: isMeetNow && 'black' }}
          >
            {isMeetNow ? locale('nearby.modal.now.deactivate') : locale('nearby.modal.now.activate')}
          </PrimaryButton>
        </ModalContainer>
      </UserModalTemplate>
    </ScreenWrapper>
  );
};

export default NearbyScreenV2;

const ModalContainer = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  border-radius: 16px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 32px 0;
  }
`;

const CellFormatContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  background: ${colors.darkBlue};
  cursor: pointer;
  padding: 1px 4px;
  border-radius: 8px;
  margin-right: 8px;
`;

const FiltersListArrowWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-left: 8px;

  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  // overflow: scroll;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FiltersListWrapper = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SectionContainer = styled.div`
  background-color: transparent;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 48px;
  padding: 0 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
`;

const FilterContainer = styled(Flex)<{ visible: boolean }>`
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1000px) {
    // background: ${colors.darkGradient};
    // background-attachment: fixed;
    justify-content: space-around;
    transition: transform 0.3s ease;
    ${({ visible }) =>
      !visible &&
      `
    transform: translateY(calc(-100%));
  `}
  }
`;

const MainWrapper = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    padding-bottom: 75px;
  }
`;

const Content = styled.div<{ filtersOpen: boolean; isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  ${(p) =>
    p.isDesktop &&
    `
      width: ${p.filtersOpen ? 'calc(100% - 362px)' : '100%'};
      /* Chrome / Safari / etc. */
  `}
`;

const RoundButtonsContainer = styled.div`
  position: fixed;
  bottom: 120px;
  right: 8px;
  display: flex;
  gap: 8px;
`;

const RoundButtonContainer = styled.div`
  width: 56px;
  height: 56px;
  background-color: rgba(22, 25, 30, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${colors.grayBorder};
`;

const SearchInputContainer = styled.div`
  position: relative;
  flex: 1;
  z-index: 500;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 48px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #d93427, #f69101, #dfd902, #14aa5f, #2d56c5, #4c2e7b) border-box;
  color: white;
`;

const LocationTextInput = styled.input`
  padding-left: 28px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
  height: 33.5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
`;

const ProfilePictureWrapper = styled.div`
  margin-right: 8px;
  display: flex;
`;

const ProfilePicture = styled.img`
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const MobileHeader = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 6px;
  @media (max-width: 1000px) {
    justify-content: space-around;
    transition: transform 0.3s ease;
    ${({ visible }) =>
      !visible &&
      `
    transform: translateY(calc(-100% - 64px));
  `}
  }
`;

const PremiumButton = styled.button`
  color: white;
  background: linear-gradient(209.2deg, #fdb022 9.53%, #ff7a00 111.75%);
  padding: 8px 14px 8px 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  z-index: 2;
  width: 200px;
  min-width: 150px;

  @media (max-width: 1000px) {
    width: auto;
  }
`;
