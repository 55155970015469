import { useMemo } from 'react';
import styled from 'styled-components/macro';
import colors from '../../../themes/colors-v2.theme';

const NotifPastille = styled.div<{
  number: string;
  left: number;
  top: number;
  right: number;
  position: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 8px;
  height: 19px;
  width: 28px;
  margin-right: 8px;
  position: ${({ position }) => (position ? position : 'absolute')};
  top: ${({ top }) => (top ? `${top}px` : '0px')};
  left: ${({ left }) => (left ? `${left}px` : '')};
  right: ${({ right }) => (right ? `${right}px` : '')};
  color: ${colors.white} !important;

  background-color: ${({ number }) => (number === '0' ? colors.lightGrey8 : colors.darkBlue)};
  font-family: 'Inter';
`;

type NotificationsProps = {
  notificationNumber: number;
  maxNumberOfChar: number;
  style?: React.CSSProperties;
  left?: number;
  top?: number;
  right?: number;
  position?: string;
};

const Notifications = (props: NotificationsProps) => {
  const { left, top, right, notificationNumber, maxNumberOfChar, style, position } = props;
  const number = useMemo(() => {
    const notifStr = notificationNumber.toString();
    return notifStr.length > maxNumberOfChar ? new Array(maxNumberOfChar + 1).join('9') + '+' : notifStr;
  }, [notificationNumber, maxNumberOfChar]);

  return (
    <NotifPastille style={style} number={number} left={left} top={top} right={right} position={position}>
      {number}
    </NotifPastille>
  );
};

export default Notifications;
