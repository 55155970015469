import styled from 'styled-components/macro';
import Text from '../../../../components/style/text.component';
import fonts from '../../../../themes/fonts.theme';

export const TextOptions = styled(Text)<{ disabled?: boolean }>`
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 12px;
  color: ${(props) => (props.disabled ? '#5c6773' : '#fffefe')};
`;
