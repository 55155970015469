import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import LiveStreamCard from './live-stream-card.component';

import useLocale from '../../../../utils/locale/locale.hook';
import { getCommunities } from '../../../../services/caracteristics.service';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';

interface ComponentProps {
  liveStreams: any[];
}

const fakeLiveStreams = [
  {
    id: 'mon-id',
    stream_name: 'mon premier stream',
    user: {
      pseudo: 'grizzlydaddy',
      profilePicture: null,
    },
    thumbnail_path: null,
    categoryKey: null,
    categories: [],
  },
  {
    id: 'mon-id',
    stream_name: 'mon premier stream',
    user: {
      pseudo: 'grizzlydaddy',
      profilePicture: null,
    },
    thumbnail_path: null,
    categoryKey: null,
    categories: [],
  },
  {
    id: 'mon-id',
    stream_name: 'mon premier stream',
    user: {
      pseudo: 'grizzlydaddy',
      profilePicture: null,
    },
    thumbnail_path: null,
    categoryKey: null,
    categories: [],
  },
  {
    id: 'mon-id',
    stream_name: 'mon premier stream',
    user: {
      pseudo: 'grizzlydaddy',
      profilePicture: null,
    },
    thumbnail_path: null,
    categoryKey: null,
    categories: [],
  },
];

export default function LiveStreamsDisplayer({ liveStreams }: ComponentProps) {
  const locale = useLocale();
  const [communities, setCommunitites] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getCommunities();
      setCommunitites(data);
    };
    getData();
  }, []);

  useEffect(() => {
    liveStreams.map((live) => {
      live.categoryKey = communities.find((community) => community.communityKey === live.category)?.phraseKey;
    });
  }, [communities, liveStreams]);

  return (
    <>
      {liveStreams.length > 0 ? (
        <MainWrapper>
          {liveStreams.map((live) => (
            <LiveStreamCard key={live.id} live={live} />
          ))}
        </MainWrapper>
      ) : (
        <NoLiveWrapper>
          <p>{locale('live.home.no_livestreams')}</p>
        </NoLiveWrapper>
      )}
    </>
  );
}

const MainWrapper = styled.div`
  margin-bottom: 24px;
  display: grid;
  gap: 10px 26px;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;

  @media (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 120px;
  }
`;

const NoLiveWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-sizing: border-box;

  & > p {
    margin: 0;
    font-size: 22px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
  }

  @media (max-width: 1000px) {
    padding: 32px;
    text-align: center;
  }
`;
