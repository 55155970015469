import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import ModalTemplate from '../modal-template.component';
import TextInput from '../../forms-v2/text.input';
import Text from '../../style/text.component';
import Button from '../../forms-v2/button.component';

import { store } from '../../../store/configure.store';
import ModalSlice from '../../../store/slices/modal.slice';
import { resetPassword } from '../../../services/auth.service';
import { isValidPassword } from '../../../utils/regex.util';
import useLocale from '../../../utils/locale/locale.hook';
import { useMediaQuery } from 'react-responsive';
import colors from '../../../themes/colors-v2.theme';
import Image from '../../image.component';
import fonts from '../../../themes/fonts.theme';

enum ResetStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface ModalProps {
  visible: boolean;
}

export default function ResetPasswordModal({ visible }: ModalProps) {
  const locale = useLocale();
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const history = useHistory();
  const [status, setStatus] = useState(ResetStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');

  const submitHandler = async () => {
    if (status === ResetStatus.LOADING || status === ResetStatus.SUCCESS) {
      return;
    }
    if (!password.trim()) {
      return;
    }

    if (!isValidPassword(password)) {
      setStatus(ResetStatus.ERROR);
      setErrorMessage(locale('reset_password.error.invalid_password'));
      return;
    }

    setStatus(ResetStatus.LOADING);
    const res = await resetPassword({ password, token });
    if (res.success) {
      setStatus(ResetStatus.SUCCESS);
    } else {
      setStatus(ResetStatus.ERROR);
      setErrorMessage(locale('reset_password.error.something_went_wrong'));
    }
  };

  useEffect(() => {
    if (visible) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      if (!token) {
        store.dispatch(ModalSlice.actions.setResetPasswordVisible(false));
      } else {
        setToken(token);
      }
    }
  }, [visible]);

  return (
    <ModalTemplate
      visible={visible}
      closeModal={() => store.dispatch(ModalSlice.actions.setResetPasswordVisible(false))}
      width="100vw"
      style={{ height: '100vh', backgroundColor: colors.lightGrey11 }}
      closeOutside
      mobileWidth="100vw"
    >
      <Header>
        <HeaderLogoWrapper>
          <Image src="/assets/imgs/logo-black.svg" alt="Bearwww logo" width={'262px'} height={'52px'} style={{}} />
        </HeaderLogoWrapper>
      </Header>
      <FormWrapper>
        {status === ResetStatus.SUCCESS ? (
          <>
            <img alt="" src="/assets/icons/check-black.svg" />
            <Text
              color={colors.black}
              style={{
                fontSize: 18,
                fontWeight: fonts.bold.weight,
                textAlign: 'center',
              }}
            >
              {locale('reset_password.success_title')}
            </Text>
            <Text color={colors.black}>{locale('reset_password.success_message')}</Text>
            {isMobile ? null : (
              <ButtonWrapper width="100%">
                <Button
                  text={locale('reset_password.go_to_signin')}
                  onClick={() => {
                    store.dispatch(ModalSlice.actions.setResetPasswordVisible(false));
                    store.dispatch(ModalSlice.actions.setSigninVisible(true));
                    history.push('/');
                  }}
                />
              </ButtonWrapper>
            )}
          </>
        ) : (
          <>
            <Text
              style={{
                fontSize: 18,
                fontWeight: fonts.bold.weight,
                textAlign: 'center',
              }}
            >
              {locale('reset_password.title')}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: fonts.semiBold.weight,
                color: colors.darkGrey2,
                marginBottom: 6,
                alignSelf: 'flex-start',
              }}
            >
              {locale('signup.password.explanation')}
            </Text>
            <TextInput
              placeholder={locale('reset_password.input.placeholder')}
              type="password"
              value={password}
              onChange={(text) => setPassword(text)}
            />
            {status === ResetStatus.ERROR && <Text style={{ color: colors.darkOrange1 }}>{errorMessage}</Text>}
            <ButtonWrapper>
              <Button
                text={locale('signin.reset_password.submit')}
                loading={status === ResetStatus.LOADING}
                onClick={submitHandler}
              />
            </ButtonWrapper>
          </>
        )}
      </FormWrapper>
    </ModalTemplate>
  );
}

const FormWrapper = styled.div`
  width: 286px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: ${colors.white};
  padding: 24px 32px;
  border-radius: 16px;

  & input {
    border-color: ${colors.lightGrey9};
  }

  & > * {
    margin-bottom: 16px;
  }
`;

const ButtonWrapper = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '100%'};

  & button {
    border-radius: 8px;
  }
`;

const Header = styled.div`
  margin-top: 20px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
`;
