import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import { openProfile } from '../../../user-profile/profile.utils';
import ModalSlice from '../../../../store/slices/modal.slice';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import { generateHueWithUuid } from '../../../../utils/color.utils';

interface ComponentProps {
  message: Message;
  streamerId: string;
}

export interface Message {
  sender: User;
  content: string;
  id: string;
}

interface User {
  id: string;
  pseudo: string;
  profilePicture: ProfilePicture;
  isFavorite: boolean;
}

interface ProfilePicture {
  id: string;
  path: string;
  description: string | null;
  uploadDate: Date;
  order: number;
  moderationHive: string;
  moderationRating: string;
  moderationAdmin: string;
  adminCheck: boolean;
  moderation: string;
}

export default function ChatMessage(props: ComponentProps) {
  const { message, streamerId } = props;
  const dispatch = useDispatch();

  return (
    <Wrapper>
      {streamerId === message.sender.id && (
        <img
          src="/assets/icons/live/camera.svg"
          alt="Camera icon"
          height={16}
          width={16}
          style={{ marginRight: 6, alignSelf: 'center' }}
        />
      )}
      {message.sender.profilePicture?.path !== null ? (
        <img
          src={message.sender.profilePicture?.path?.replace('pictures', 'resized-pictures/w_100')}
          width="16px"
          height="16px"
          style={{ borderRadius: '4px', marginRight: 8, marginTop: 3 }}
          onClick={() => {
            openProfile(message.sender.pseudo);
          }}
        />
      ) : (
        <img
          src={'/assets/imgs/no-profile-picture.svg'}
          width="16px"
          height="16px"
          style={{ borderRadius: '4px', marginRight: 8, marginTop: 3 }}
          onClick={() => {
            openProfile(message.sender.pseudo);
          }}
        />
      )}
      <MessageContent>
        <SenderUsernameAndMessage>
          <span
            style={{
              color:
                streamerId === message.sender.id
                  ? colors.accent2
                  : `hsl(${generateHueWithUuid(message.sender.id)}, 100%, 50%)`,
            }}
          >
            {message.sender.pseudo || 'A Bearwww User'}
          </span>
          {' : '}
          {message.content}
        </SenderUsernameAndMessage>
      </MessageContent>
      <MessageActions>
        <img
          alt=""
          src="/assets/icons/controls/block.svg"
          onClick={() => {
            dispatch(
              ModalSlice.actions.setBlockUser({
                visible: true,
                userId: message.sender.id,
              })
            );
          }}
        />
      </MessageActions>
    </Wrapper>
  );
}

const MessageActions = styled.div`
  display: none;

  & > * {
    user-select: none;
    cursor: pointer;
    width: 13.33px;
    height: 13.33px;
    margin: 0 4px;
  }
`;

const SenderUsernameAndMessage = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${fonts.regular.weight};

  & > span {
    font-weight: ${fonts.bold.weight};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;

  & img {
    align-self: flex-start;
  }

  &:hover {
    ${MessageActions} {
      display: flex;
      align-self: flex-start;
      transform: translateY(2px);
      padding-right: 12px;
    }
    ${SenderUsernameAndMessage} > span {
      text-decoration: underline;
    }
  }
`;

const MessageContent = styled.div`
  flex: 1;
  display: flex;
  gap: 4px;
  max-width: calc(100% - 60px);

  & > .sender {
    font-size: 12px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.lightGrey3};
    margin-bottom: 4px;
  }

  & > .content {
    font-size: 12px;
    color: ${colors.lightGrey3};
  }
`;
