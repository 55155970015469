import { useEffect, useRef, useState, useContext } from 'react';
import Dropdown from '../../../components/forms/dropdown.component';
import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import colors from '../../../themes/colors-v2.theme';
import { UserModalTemplate } from '../../user-profile/components/user-modal.component';
import { getShoutoutRecipients } from '../../../services/chat.service';
import styled from 'styled-components';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import useLocale from '../../../utils/locale/locale.hook';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { debouncedCitySearch } from '../../../utils/search.utils';
import { useOpenStreetMapsContext } from '../../../context/openstreetmaps';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { useMediaQuery } from 'react-responsive';
import { useLogin } from '../../../utils/auth.utils';
import { getCurrentLanguage } from '../../../utils/locale/hreflang.utils';
import { getPrice } from '../../../services/subscription.service';
import ModalSlice from '../../../store/slices/modal.slice';
import { useDispatch } from 'react-redux';
import { BillingParamsContext } from '../../../context/billing-params-context';
import { BillingParams } from '../types/billing-params.type';
import { BillingAddress } from '../types/billing-address.type';
import { DurationPricingColumn } from '../types/duration-pricing-column.enums';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface Radius {
  value: string;
  label: string;
}

const radiusEntries: Radius[] = [
  {
    value: DurationPricingColumn.FIVE_KM,
    label: 'Radius : 5km',
  },
  {
    value: DurationPricingColumn.TEN_KM,
    label: 'Radius : 10km',
  },
  {
    value: DurationPricingColumn.TWENTY_KM,
    label: 'Radius : 20km',
  },
];

export const ShoutoutModal = ({ isOpen, onClose }: Props) => {
  const context = useContext(BillingParamsContext);

  if (!context) {
    throw new Error("ShoutoutModal must be used within a BillingParamsProvider");
  }

  const { setBillingParams } = context
  const [radius, setRadius] = useState<Radius>();
  const [usersTargetedCount, setUsersTargetedCount] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult>();
  const [coords, setCoords] = useState<{ lat: number; lon: number }>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<SearchResult<RawResult>[]>([]);
  const locale = useLocale();
  const locationInputRef = useRef(null);
  const mapProvider = useOpenStreetMapsContext();
  const [message, setMessage] = useState('');
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { me } = useLogin();
  const dispatch = useDispatch();
  const billingParams = useRef<BillingParams>()

  const debouncedSearch = useRef(debouncedCitySearch(mapProvider, setAddressAutocomplete)).current;

  const [priceValue, setPriceValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [price, setPrice] = useState('');

  const getInitialPriceHandler = async () => {
    const response = await getPrice(DurationPricingColumn.FIVE_KM);
    setCurrency(response.currency);
    setPriceValue(response.value);
  };

  useEffect(() => {
    getInitialPriceHandler();
  }, [])

  useEffect(() => {
    if (currency && priceValue) {
      setPrice(`${priceValue} ${currency}`);
    }
  }, [currency, priceValue]);

  const handleShoutoutAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setCoords({ lat: address.raw.lat, lon: address.raw.lon });
    setAddressAutocomplete([]);
    if (locationInputRef.current) {
      locationInputRef.current.value = address.label;
    }
  };

  const priceAndRadiusHandler = async (value: Radius) => {
    setRadius(value);
    const response = await getPrice(value as unknown as string)
    setCurrency(response.currency);
    setPriceValue(response.value);
  }


  useEffect(() => {
    async function fetchShoutoutRecipients(radius: Radius) {
      const body: Record<string, any> = {};
      if (radius) {
        const radiusValue = (Number((radius as unknown as string).slice(3,-2)));
        body.radius = radiusValue
      }
      if (coords?.lon && coords?.lat) {
        body.pointOfReferenceCoords = {
          lon: Number(coords.lon),
          lat: Number(coords.lat),
        };
      }
      const res = await getShoutoutRecipients(body);
      setUsersTargetedCount(res?.recipientsCount);
    }
    fetchShoutoutRecipients(radius);
  }, [radius, coords]);

  const handleClose = () => {
    dispatch(ModalSlice.actions.setShoutoutModalVisible(false));
    onClose();
  }

  const handleMoneticoBillingAddressComponent = () => {
    const address: BillingAddress = {
      addressLine1: '',
      country: '',
      city: '',
      postalCode: '',
      stateOrProvince: '',
    };

    const billingParams: BillingParams = {
      subscription: false,
      lang: getCurrentLanguage(),
      email: me.email,
      userID: me.id,
      duration: radius as unknown as DurationPricingColumn,
      address: address,
      message: message,
      coords: coords,
    };
    setBillingParams(billingParams);

    dispatch(ModalSlice.actions.setShoutoutModalVisible(false))
    dispatch(ModalSlice.actions.setMoneticoBillingAddressVisible(true))
  }

  return (
    <ShoutOut
      isOpen={isOpen}
      onClose={handleClose}
      style={{
        borderRadius: 8,
        width: isDesktop ? '700px' : '90%',
        height: isDesktop ? 'fit-content' : '95%',
        background: colors.darkGray,
        position: 'fixed',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 32,
        zIndex: 1050,
        overflowY: 'auto',
      }}
    >
      <Flex direction="column">
        <Flex alignItems="center" gap="13px">
          <img src="/assets/icons/messages/shout-out-purple.svg" width={40} height={40} />
          <Flex direction="column" gap="5px">
            <Text fontSize="20px" bold>
              Shout-out
            </Text>
            <Text color={colors.darkText}>{locale('messages.shoutout.title')}</Text>
          </Flex>
        </Flex>
        <Flex direction="column" gap="6px" style={{ marginTop: 8 }}>
          <SearchInputContainer>
            <ResetLocationSearch width="11px" height="13.33px" src="/assets/icons/sidebar/navbar/explore-gray.svg" />
            {/* FIXME: City does not update after selecting city. 
            Moreover, a country can be selected instead of a valid city or a more precise address as well. 
            What should be the behaviour in this case ? */}
            <LocationTextInput
              placeholder={
                selectedAddress?.raw?.address?.city ? selectedAddress.raw.address.city : locale('guide.form.city.label')
              }
              onChange={(e) => debouncedSearch(e.target.value)}
              ref={locationInputRef}
            />
            {addressAutocomplete.length > 0 && (
              <Autocomplete>
                {addressAutocomplete.slice(0, 5).map((address, index) => (
                  <Line key={`${address.label}-${index}`} onClick={() => handleShoutoutAddress(address)}>
                    {address.label}
                  </Line>
                ))}
              </Autocomplete>
            )}
          </SearchInputContainer>
          <Dropdown
            value={radius}
            placeholder={'Radius'}
            lines={radiusEntries}
            onSelect={(value) => priceAndRadiusHandler(value)}
          />
          <Row>{locale('messages.shoutout.targeted', { usersCount: usersTargetedCount })}</Row>
          <Row>{locale('messages.shoutout.message')}</Row>
          <TextArea
            placeholder={locale('messages.shoutout.message-placeholder')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></TextArea>
          <PrimaryButton
              isActive={true}
              size="large"
              onClick={() => handleMoneticoBillingAddressComponent()}
              type="button"
              disabled={message.length === 0 || coords === undefined || radius ===  undefined }
            >
              {locale('messages.shoutout.price', { price: price })}
            </PrimaryButton>
          <Flex alignItems="center" justify="space-between">
            <Flex direction="column" style={{ flex: 1 }}>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_1')}
              </PaymentRow>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_2')}
              </PaymentRow>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_3')}
              </PaymentRow>
            </Flex>
            <PaymentIconsGrid>
              <img src="/assets/icons/messages/mastercard.svg" width={32} height={32} />
              <img src="/assets/icons/messages/visa.svg" width={32} height={32} />
              <img src="/assets/icons/messages/american-express.svg" width={32} height={32} />
              <img src="/assets/icons/messages/CB.svg" width={32} height={32} />
            </PaymentIconsGrid>
          </Flex>
        </Flex>
      </Flex>
    </ShoutOut>
  );
};

const ShoutOut = styled(UserModalTemplate)``;

const Row = styled.div`
  border: none;
  border-radius: 8px;
  height: 40px;
  border: 1px solid ${colors.grayBorder};
  color: ${colors.white};
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  padding: 0 12px;
`;

const PaymentRow = styled.div`
  gap: 4px;
  border: none;
  border-radius: 8px;
  height: 24px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  padding: 0 12px;
  @media (max-width: 1000px) {
    height: 100%;
  }
`;

const PaymentIconsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

const TextArea = styled.textarea`
  border: none;
  border-radius: 8px;
  height: 140px;
  outline: none;
  color: ${colors.white};
  border: 1px solid ${colors.grayBorder};
  background: ${colors.darkGray};
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  padding: 4px 12px;
`;

const SearchInputContainer = styled.div`
  position: relative;
  z-index: 500;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 248px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid ${colors.grayBorder};
  color: white;
`;

const LocationTextInput = styled.input`
  padding-left: 28px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  height: 37.5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 13px;
  cursor: pointer;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};

  @media (max-width: 1000px) {
    top: 60px;
    width: 93%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;
