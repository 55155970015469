import { Fragment } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";
import Text from "../../../components/style/text.component";
import ModalSlice from "../../../store/slices/modal.slice";
import colors from "../../../themes/colors-v2.theme";
import { useLogin } from "../../../utils/auth.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { CellType } from "../nearby.screenv2";
import { RadarUser } from "./radar-user.component";

type Props = {
  users: any[] | undefined;
  cellType: CellType;
  size?: number;
  strictSize?: boolean;
  showBoostAd?: boolean;
  showSubscribeAd?: boolean;
  premiumTitle?: string;
  isFromSharedAlbum?: boolean;
  showProfilePicture?: boolean;
};

export const CustomGrid = ({
  users,
  cellType,
  size,
  strictSize,
  showBoostAd,
  showSubscribeAd = true,
  premiumTitle,
  isFromSharedAlbum,
  showProfilePicture,
}: Props) => {
  const { me } = useLogin();
  const locale = useLocale();
  const dispatch = useDispatch();

  const isPremium = me?.role?.includes("premium") ?? false;
  const firstBlurredUserIndex = users?.findIndex((user) => user?.isAnonymous);
  const unblurredUsers =
    !isPremium && firstBlurredUserIndex !== -1
      ? users?.slice(0, firstBlurredUserIndex)
      : users;
  const blurredUsers =
    firstBlurredUserIndex !== -1
      ? users?.slice(users?.findIndex((user) => user.isAnonymous))
      : [];

  return (
    <GridContainer cellType={cellType} size={size} strictSize={strictSize}>
      {showBoostAd && unblurredUsers?.length > 0 ? (
        <BoostContainer
          key="boost"
          onClick={() =>
            dispatch(ModalSlice.actions.setBoostModalVisible(true))
          }
          style={{ gap: cellType === "square" && "0px" }}
        >
          <BoostIcon src="/assets/icons/user-profile/boost-white.svg" />
          <Text fontSize="9px" style={{ textAlign: "center" }}>
            {cellType === "square"
              ? locale("boost.description-short")
              : locale("boost.description")}
          </Text>
          <PrimaryButton
            size="small"
            style={{
              borderColor: "#134E48",
              background: "#00CA99",
            }}
          >
            Boost
          </PrimaryButton>
        </BoostContainer>
      ) : null}

      {/* <BoostModal isOpen={isBoostOpen} onClose={() => setIsBoostOpen(false)} /> */}

      {unblurredUsers?.map((user: any, index: number) => {
        return (
          <GridItem key={`${user?.id}-${index}`}>
            <RadarUser
              height="100%"
              width="100%"
              user={user}
              isFromSharedAlbum={isFromSharedAlbum}
              optiWidth={cellType === "bigSquare" ? 400 : 200}
            />
          </GridItem>
        );
      })}

      {!isPremium && (
        <Fragment>
          {showSubscribeAd && unblurredUsers.length !== 0 ? (
            <Divider isBigSquare={cellType === "bigSquare"}>
              <img
                src="/assets/icons/logo/bearwwx.svg"
                width={81}
                height={18}
              />
              <Text bold>{premiumTitle}</Text>
              <Text fontSize="12px" style={{ textAlign: "center" }}>
                {locale("grid.premium.description")}
              </Text>
            </Divider>
          ) : null}
          {blurredUsers?.map((user: any, index: number) => {
            return (
              <GridItem key={`${user?.id}-${index}`}>
                <RadarUser
                  height="100%"
                  width="100%"
                  user={user}
                  blurred={true}
                  isFromSharedAlbum={isFromSharedAlbum}
                  showProfilePicture={showProfilePicture}
                />
              </GridItem>
            );
          })}
        </Fragment>
      )}
    </GridContainer>
  );
};

const Divider = styled.div<{ isBigSquare?: boolean }>`
  grid-column: 1 / -1;
  grid-row: ${(props) => (props.isBigSquare ? "span 1" : "span 2")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: ${colors.darkGray};
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  height: 200px;
`;

const GridContainer = styled.div<{
  cellType: CellType;
  size?: number;
  strictSize?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ cellType, size, strictSize }) =>
    cellType === "rectangle" || cellType === "square"
      ? `repeat(${size ? size : "auto-fill"}, ${
          strictSize ? "110px" : "minmax(110px, 1fr)"
        })`
      : `repeat(${size ? size : "auto-fill"}, ${
          strictSize ? "110px" : "minmax(170px, 1fr)"
        })`};
  grid-auto-rows: auto;
  * > img {
    aspect-ratio: ${({ cellType }) =>
      cellType === "rectangle"
        ? "110/148"
        : cellType === "square"
        ? "1/1"
        : "188/262"};
    width: 100%;
  }
  & > :first-child {
    aspect-ratio: ${({ cellType }) =>
      cellType === "rectangle"
        ? "110/148"
        : cellType === "square"
        ? "1/1"
        : "188/262"};
    width: 100%;
  }

  gap: 10px;
`;

const GridItem = styled.div``;

const PremiumButton = styled.button`
  color: white;
  background: linear-gradient(209.2deg, #fdb022 9.53%, #ff7a00 111.75%);
  padding: 8px 14px 8px 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  z-index: 2;
  width: 347px;

  @media (max-width: 1000px) {
    width: auto;
  }
`;

const BoostContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  border: 2px solid #00ca99;
  background-image: url("/assets/icons/user-profile/boost-background.svg");
  background-position: 50% 50%;
  padding: 20px 8px;
  box-sizing: border-box;
`;

const BoostIcon = styled.img`
  border-radius: 4px;
  object-fit: cover;
  width: 26px !important;
  height: 26px !important;
`;
