const geocoderPromisified = (
  geocoderRequest: google.maps.GeocoderRequest
): Promise<google.maps.GeocoderResult[]> =>
  new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(geocoderRequest, (results, status) => {
      if (status !== "OK") {
        reject(status);
        return;
      }
      resolve(results);
    });
  });

export const getCityAndCountry = async (
  latitude,
  longitude
): Promise<{
  city: google.maps.GeocoderResult;
  country: google.maps.GeocoderResult;
  cityName: string;
  countryName: string;
} | null> => {
  const geocoderResults: google.maps.GeocoderResult[] =
    await geocoderPromisified({
      location: { lat: latitude, lng: longitude },
    });
  const city =
    geocoderResults.find(
      (e) => e.types.includes("locality") && e.types.includes("political")
    ) ||
    geocoderResults.find(
      (e) =>
        e.types.includes("administrative_area_level_1") &&
        e.types.includes("political")
    );
  const country = geocoderResults.find(
    (e) => e.types.includes("country") && e.types.includes("political")
  );
  const cityName = (
    city.address_components.find(
      (e) => e.types.includes("locality") && e.types.includes("political")
    ) ||
    city.address_components.find(
      (e) =>
        e.types.includes("administrative_area_level_1") &&
        e.types.includes("political")
    )
  ).long_name;
  const countryName = country.address_components.find(
    (e) => e.types.includes("country") && e.types.includes("political")
  ).long_name;

  if (city && country) {
    return {
      city,
      country,
      cityName,
      countryName,
    };
  }
  return null;
};
