import { useRef } from 'react';
import useLocale from '../../../utils/locale/locale.hook';
import PictureModeration from '../../../components/picture-moderation.component';
import styled from 'styled-components';
import Icon from '../../../components/user/PremiumSoonIcon';
import NewLabel from '../../../components/user/NewLabel';
import { getStatusColor } from '../../../utils/status.utils';
import { getDistance } from '../../../utils/unities.utils';
import colors from '../../../themes/colors-v2.theme';
import { openProfile } from '../../user-profile/profile.utils';
import { useDispatch } from 'react-redux';
import ModalSlice from '../../../store/slices/modal.slice';

interface UserProps {
  user: any;
  width: string;
  height: string;
  blurred?: boolean;
  smallPremium?: boolean;
  filters?: any;
  hideDistance?: boolean;
  isUnblockGrid?: boolean;
  isFromSharedAlbum?: boolean;
  optiWidth?: number;
  showProfilePicture?: boolean;
}

export const RadarUser = (props: UserProps) => {
  const locale = useLocale();
  const userRef = useRef(null);
  const dispatch = useDispatch();
  const isPremium = props.user?.role === 'premium';
  const isBoosted = props.user?.isBoosted ?? false;
  const isMeetNow = props.user?.isMeetNow ?? false;

  return (
    <Container
      key={props.user?.id + '-user'}
      ref={userRef}
      onClick={() => {
        if (props.blurred) {
          dispatch(ModalSlice.actions.setPremium({ visible: true }));
        } else if (!props.user?.isAnonymous) {
          openProfile(props.user?.pseudo, false, false, props.isFromSharedAlbum ?? false);
        } else {
          openProfile(props.user?.anonymousPseudo, false, true, props.isFromSharedAlbum ?? false);
        }
      }}
      isUnblockGrid={props.isUnblockGrid}
      isBoosted={isBoosted}
      blurred={props.blurred}
    >
      <LinearGradient />
      <PictureModeration
        alt=""
        style={{
          objectFit: 'cover',
          borderRadius: 2,
          cursor: 'pointer',
          filter: props.blurred && !props.showProfilePicture ? 'blur(8px)' : 'none',
        }}
        src={props.user?.profilePicture?.path ?? '/assets/imgs/no-profile-picture.svg'}
        width={props.width}
        height={props.height}
        optiWidth={props.optiWidth ?? 300}
        moderation={props.user?.profilePicture?.moderation}
      />
      <ProfileIconsContainer>
        {isPremium && <Icon icon="premium" />}
        {(props.user?.isSoonTraveller || props.user?.isTravelling) && <Icon icon="soon" />}
        {isBoosted && <Icon icon="boost" />}
        {isMeetNow && <Icon icon="meet-now" />}
      </ProfileIconsContainer>
      {props.user?.new_face === '1' && (
        <NewLabel
          style={{
            position: 'absolute',
            top: 4,
            left: 4,
            fontSize: 9,
            height: 17,
          }}
        />
      )}
      <Bottom style={{ filter: props.blurred ? 'blur(8px)' : 'none' }}>
        <PseudoContainer>
          <Status>
            <StatusCircle
              color={getStatusColor(props.user?.status)?.centerCircle}
              border={getStatusColor(props.user?.status)?.circle}
            />
          </Status>
          <Pseudo>
            <p style={{ textOverflow: 'ellipsis', width: '90px', overflow: 'hidden' }}>{props.user?.pseudo}</p>
          </Pseudo>
        </PseudoContainer>
        {props.user?.distance && props.user?.distance !== 0 && !props.hideDistance ? (
          <DistanceAndLabelContainer>
            <Distance>
              {locale('nearby.at-distance')} {getDistance(props?.user?.distance || 0)}
            </Distance>
          </DistanceAndLabelContainer>
        ) : null}
      </Bottom>
    </Container>
  );
};

const Container = styled.div<{
  isUnblockGrid?: boolean;
  isExploreGrid?: boolean;
  isBoosted?: boolean;
  blurred?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${colors.black};
  border-radius: 4px;
  display: block;
  flex-direction: column;
  overflow: hidden;
  ${(props) => (!props.blurred ? `cursor: pointer;` : ``)}
  ${(props) => props.isBoosted && `border: 2px solid #00CA99;`}
  }
`;

const LinearGradient = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 40%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
`;

const ProfileIconsContainer = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  flex-direction: column;
  gap: 3px;

  @media (max-width: 1000px) {
    top: 4px;
    right: 4px;
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
`;

const PseudoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  margin-right: 7px;
`;

const StatusCircle = styled.div<{ color: string; border: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.border ? props.border : 'transparent')};
`;

const Pseudo = styled.div`
  & > p {
    margin: 0;
    font-family: 'Inter';
    line-height: '12px';
    font-weight: 600;
    font-size: 9px;
    color: ${colors.white};
    text-shadow: 0px 0.4px 0px rgba(0, 0, 0, 0.7);
  }
`;

const DistanceAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Distance = styled.p`
  font-family: 'Inter';
  font-size: 8px;
  line-height: 12px;
  font-weight: 600;
  color: ${colors.white};
  text-shadow: 0px 0.4px 0px rgba(0, 0, 0, 0.7);
  margin: 0;
  margin-right: 8px;
`;
