import { PropsWithChildren } from "react";
import styled from "styled-components";
import colors from "../../themes/colors-v2.theme";

type Props = {
  isActive?: boolean;
  size?: "small" | "medium" | "large";
  onClick?: (e?: any) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  form?: string;
  value?: string;
};

export const PrimaryButton = ({
  children,
  isActive,
  size = "small",
  onClick,
  style,
  disabled,
  type,
  form,
  value,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      isActive={isActive}
      size={size}
      onClick={onClick}
      style={style}
      disabled={disabled}
      type={type}
      form={form}
      value={value}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  outline: none;
  margin: 0;
  font-size: ${(props) => (props.size === "large" ? "16px" : "12px")};
  font-weight: ${(props) => (props.size === "large" ? 600 : 500)};
  height: ${(props) => (props.size === "small" ? "26px" : "")};
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled
      ? `${colors.darkGray}`
      : props.isActive
      ? `${colors.darkBlue}`
      : `${colors.darkGray}`};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? `${colors.lightGrey1}`
        : props.isActive
        ? "#194185"
        : `${colors.lightGrey10}`};
  color: ${(props) => (props.disabled ? `${colors.lightGrey1}` : "white")};
  padding: ${(props) =>
    props.size === "medium"
      ? "9px 10px"
      : props.size === "large"
      ? "10px 18px 10px 18px"
      : "2px 14px"};
  border-radius: ${(props) => (props.size === "medium" ? "48px" : "8px")};
  transition: 0.3s;
`;
