import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Image from '../image.component';
import { getDistance } from '../../utils/unities.utils';
import { openProfile } from '../../pages/user-profile/profile.utils';
import ModalSlice from '../../store/slices/modal.slice';
import useLocale from '../../utils/locale/locale.hook';
import colors from '../../themes/colors-v2.theme';
import Icon from '../user/PremiumSoonIcon';
import NewLabel from '../user/NewLabel';
import { useMediaQuery } from 'react-responsive';
import PictureModeration from '../picture-moderation.component';
import fonts from '../../themes/fonts.theme';
import { getStatusColor } from '../../utils/status.utils';

interface UserProps {
  user: any;
  style?: any;
  blurred?: boolean;
  smallPremium?: boolean;
  filters?: any;
  hideDistance?: boolean;
  isUnblockGrid?: boolean;
  isExploreGrid?: boolean;
}

const User = (props: UserProps) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const userRef = useRef(null);
  const isPremium = props.user?.role === 'premium';

  return (
    <Container
      key={props.user?.id + '-user'}
      style={props.style}
      ref={userRef}
      width={props.style?.width ? props.style.width : 124.5}
      isExploreGrid={props.isExploreGrid}
      onClick={() => {
        if (!props.user?.isAnonymous) {
          openProfile(props.user?.pseudo, false, false);
        } else {
          openProfile(props.user?.anonymousPseudo, false, true);
        }
      }}
      isUnblockGrid={props.isUnblockGrid}
    >
      {props.user?.profilePicture === null || !props.user?.profilePicture?.path ? (
        <Image
          alt=""
          style={{
            objectFit: 'cover',
            borderRadius: 4,
            padding: 2,
            boxSizing: 'border-box',
            objectPosition: 'center',
          }}
          src="/assets/imgs/no-profile-picture.svg"
          width="100%"
          optiWidth={300}
        />
      ) : (
        <PictureModeration
          alt=""
          style={{
            objectFit: 'cover',
            borderRadius: 4,
          }}
          src={props.user?.profilePicture?.path}
          width={props.style?.width ? props.style?.width : '100%'}
          height={props.style?.width ? props.style?.width : 'auto'}
          optiWidth={300}
          moderation={props.user?.profilePicture?.moderation}
        />
      )}
      <PremiumSoonIconsContainer>
        {isPremium && <Icon icon="premium" />}
        {(props.user?.isSoonTraveller || props.user?.isTravelling) && <Icon icon="soon" />}
      </PremiumSoonIconsContainer>
      {props.user?.new_face === '1' && (
        <NewLabel
          style={{
            position: 'absolute',
            top: window.innerWidth > 1000 ? 10 : 4,
            left: window.innerWidth > 1000 ? 12 : 4,
            fontSize: 16,
            height: 17,
          }}
        />
      )}
      <Bottom isExploreGrid={props.isExploreGrid}>
        <PseudoContainer>
          <Status isPremium={false} isIcon={!['offline', 'online'].includes(props.user?.status)}>
            <StatusCircle
              color={getStatusColor(props.user?.status)?.centerCircle}
              border={getStatusColor(props.user?.status)?.circle}
            />
          </Status>
          <Pseudo isPremium={false}>
            <p>{props.user?.pseudo}</p>
          </Pseudo>
        </PseudoContainer>
        {props.user?.distance !== 0 && !props.hideDistance && (
          <DistanceAndLabelContainer>
            <Distance>
              {locale('nearby.at-distance')} {getDistance(props?.user?.distance || 0)}
            </Distance>
          </DistanceAndLabelContainer>
        )}
      </Bottom>
      {props.isExploreGrid && (
        <Location>
          {props.user?.travels[0].city.nameKey[0].toUpperCase() + props.user?.travels[0].city.nameKey.substring(1)}
        </Location>
      )}
    </Container>
  );
};

export default User;

const Container = styled.div<{
  width: number;
  isUnblockGrid?: boolean;
  isExploreGrid?: boolean;
}>`
  position: relative;
  width: ${({ isExploreGrid, width }) => (!isExploreGrid ? '100%' : width ? `${width}px` : '124.5px')};
  max-width: ${({ isExploreGrid, width }) => (!isExploreGrid ? '100%' : width ? `${width}px` : '124.5px')};
  max-height: ${(props) =>
    props.style?.width ? (props.isExploreGrid ? `calc(${props.style.width} + 21px)` : props.style.width) : 124.5};
  height: ${(props) =>
    props.style?.width ? (props.isExploreGrid ? `calc(${props.style.width} + 21px)` : props.style.width) : 124.5};
  background-color: ${colors.white};
  border-radius: 4px;
  display: block;
  flex-direction: column;

  &:hover {
    cursor: 'pointer';
  }
`;

const PseudoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Bottom = styled.div<{ isExploreGrid?: boolean }>`
  position: absolute;
  bottom: ${({ isExploreGrid }) => (isExploreGrid ? '29px' : '8px')};
  left: 9px;
`;

const Status = styled.div<{ isPremium: boolean; isIcon: boolean }>`
  margin-right: 7px;
`;

const Pseudo = styled.div<{ isPremium: boolean }>`
  & > p {
    margin: 0;
    line-height: '12px';
    font-weight: 600;
    font-size: 9px;
    color: ${colors.white};
    text-shadow: 0px 0.4px 0px rgba(0, 0, 0, 0.7);
  }
`;

const PremiumSoonIconsContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (max-width: 1000px) {
    top: 4px;
    right: 4px;
  }
`;

const DistanceAndLabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

const Distance = styled.p`
  font-size: 8px;
  line-height: 12px;
  font-weight: 600;
  color: ${colors.white};
  text-shadow: 0px 0.4px 0px rgba(0, 0, 0, 0.7);
  margin: 0;
  margin-right: 8px;
`;

const Location = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${fonts.bold.weight};
  color: ${colors.black};

  @media (max-width: 1000px) {
    margin-left: 8px;
  }
`;

const StatusCircle = styled.div<{ color: string; border: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.border ? props.border : 'transparent')};
`;
