import React from 'react';
import styled from 'styled-components/macro';
import RangeSlider from '../../../../components/forms/range-slider.component';
import useLocale from '../../../../utils/locale/locale.hook';
import {
  getCmBddToClient,
  getCmClientToBdd,
  getKgBddToClient,
  getKgClientToBdd,
} from '../../../../utils/unities.utils';
import { TitleOptions } from './title-option.component';
import colors from '../../../../themes/colors-v2.theme';

const Container = styled.div`
  width: 97%;
  margin: 16px auto 0 auto;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.darkGrey2};
`;

type CaracteristicFilterProps = {
  onChange: (status) => void;
  ageRange: number[];
  heightRange: number[];
  weightRange: number[];
  rangeType?: 'age' | 'height' | 'weight';
};

const CaracteristicFilter = (props: CaracteristicFilterProps) => {
  const locale = useLocale();
  const units = localStorage.getItem('units');
  const [ageRange, setAgeRange] = React.useState(props.ageRange || [18, 99]);
  const [heightRange, setHeightRange] = React.useState(props.heightRange || [140, 210]);
  const [weightRange, setWeightRange] = React.useState(props.weightRange || [45, 250]);

  const updateAgeRange = (value) => {
    props.onChange({
      ageMax: value[1],
      ageMin: value[0],
    });
    setAgeRange(value);
  };

  const updateHeight = (value) => {
    props.onChange({
      heightMax: value[1],
      heightMin: value[0],
    });
    setHeightRange(value);
  };

  const updateWeight = (value) => {
    props.onChange({
      weightMax: value[1],
      weightMin: value[0],
    });
    setWeightRange(value);
  };

  React.useEffect(() => {
    setAgeRange(props.ageRange || [18, 99]);
  }, [props.ageRange]);

  React.useEffect(() => {
    setHeightRange(props.heightRange || [140, 210]);
  }, [props.heightRange]);

  React.useEffect(() => {
    setWeightRange(props.weightRange || [45, 250]);
  }, [props.weightRange]);

  return (
    <Container>
      {(props.rangeType === 'age' || props.rangeType === undefined) && (
        <>
          <TitleOptions>{locale('nearby.title.age')}</TitleOptions>
          <RangeSlider
            style={{ marginTop: '20px' }}
            label={locale('nearby.filter.years')}
            min={18}
            max={99}
            value={ageRange}
            defaultValue={[ageRange[0], ageRange[1]]}
            onAfterChange={(value) => {
              updateAgeRange(value);
            }}
          />
        </>
      )}
      {(props.rangeType === 'height' || props.rangeType === undefined) && (
        <>
          <TitleOptions style={{ marginTop: 32 }}>{locale('global.height')}</TitleOptions>
          <RangeSlider
            style={{ marginTop: '20px' }}
            label={units === 'metric' ? locale('global.cm.metric') : locale('global.cm.imperial')}
            min={getCmBddToClient(140)}
            max={getCmBddToClient(210)}
            value={[getCmBddToClient(heightRange[0]), getCmBddToClient(heightRange[1])]}
            defaultValue={[getCmBddToClient(heightRange[0]), getCmBddToClient(heightRange[1])]}
            onAfterChange={(value) => {
              updateHeight([getCmClientToBdd(value[0]), getCmClientToBdd(value[1])]);
            }}
          />
        </>
      )}
      {(props.rangeType === 'weight' || props.rangeType === undefined) && (
        <>
          <TitleOptions style={{ marginTop: 32 }}>{locale('global.weight')}</TitleOptions>
          <RangeSlider
            style={{ marginTop: '20px' }}
            label={units === 'metric' ? locale('global.kg.metric') : locale('global.kg.imperial')}
            min={getKgBddToClient(45)}
            max={getKgBddToClient(250)}
            value={[getKgBddToClient(weightRange[0]), getKgBddToClient(weightRange[1])]}
            defaultValue={[getKgBddToClient(weightRange[0]), getKgBddToClient(weightRange[1])]}
            onAfterChange={(value) => {
              updateWeight([getKgClientToBdd(value[0]), getKgClientToBdd(value[1])]);
            }}
          />
        </>
      )}
    </Container>
  );
};

export default CaracteristicFilter;
