import { Redirect, Route, Switch } from 'react-router-dom';
import { useRouterNavigationTracker } from '../utils/history.utils';
import NotFoundScreen from './404/not-found.screen';
import SigninScreen from './auth/signin/signin.screen';
import ClassificationScreen from './classification/classification.screen';
import { DiscoverScreen } from './discover/discover.screen';
import ExploreScreenv2 from './explore/explore.screenv2';
import TripsScreen from './explore/trips.screen';
import FavoritesScreen from './favorites/favorites.screen';
import { HahstagsScreen } from './hashtags/hashtags.screen';
import LiveScreenV2 from './live/live.screenV2';
import MessagesScreenV2 from './messages/messages.screenV2';
import NearbyScreenV2 from './nearby/nearby.screenv2';
import { PicturesScreen } from './pictures/pictures.screen';
import { PremiumScreenV2 } from './premium/premiumv2.screen';
import ProfileMobile from './profile-mobile/profile-mobile.screen';
import SettingsScreen from './settings/settings.screen';
import SharedAlbumsScreen from './shared-albums/shared-albums.screen';
import ViewersScreen from './viewers/viewers.screen';

export const RoutesWrapper = () => {
  useRouterNavigationTracker();
  return (
    <Switch>
      <Route exact path="/signin" component={SigninScreen} />

      {/* <Route exact path="/user/:pseudo" component={UserProfileScreen} /> */}

      <Redirect exact from="/" to="/" />
      <Route path="/discover">
        <DiscoverScreen />
      </Route>
      <Route exact path="/nearby" component={NearbyScreenV2} />
      <Route exact path="/explore" component={ExploreScreenv2} />
      <Route exact path="/trips" component={TripsScreen} />
      <Route path="/messages" component={MessagesScreenV2} />
      <Route path="/viewers" component={ViewersScreen} />
      <Route exact path="/favorites" component={FavoritesScreen} />
      <Route exact path="/classification" component={ClassificationScreen} />
      <Route path="/shared-albums" component={SharedAlbumsScreen} />
      <Route path="/live" component={LiveScreenV2} />
      {/* <Route path="/members-videos" component={MembersVideosScreen} /> */}
      {/* <Route path="/porn-videos" component={PornVideosScreen} /> */}

      {/* <Route path="/feed" component={FeedScreen} /> */}
      {/* <Route path="/posts/:postId" component={PostScreen} /> */}
      {/* <Route path="/guys-of-the-week" component={GuysScreen} /> */}
      {/* <Route exact path="/premium/success" component={PremiumSuccessScreen} />
              <Route exact path="/premium/error" component={PremiumErrorScreen} />
              <Route exact path="/premium" component={PremiumScreen} />*/}

      <Route exact path="/hashtags" component={HahstagsScreen} />

      <Route exact path="/memberships" component={PremiumScreenV2} />

      <Route path="/profile" component={ProfileMobile} />
      <Route path="/settings" component={SettingsScreen} />
      <Route path="/pictures" component={PicturesScreen} />

      <Route exact path="/404" component={NotFoundScreen} />
    </Switch>
  );
};
