import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';

import Picture from './picture.component';
import { FancyScrollbar } from '../../../../../components/style/fancy-scrollbar.style';

import { deletePictures, setOrderPictures, updatePictures } from '../../../../../services/pictures.service';
import { setProfilePicture } from '../../../../../services/users.service';
import { uploadAndChangeMainPicture } from './edit-pictures.utils';
import { setMe } from '../../../../../utils/auth.utils';
import colors from '../../../../../themes/colors-v2.theme';
import { PrimaryButton } from '../../../../../components/forms/primaryButton.component';
import UploadFile from './upload-file.component';

type AlbumPreviewProps = {
  selectedAlbum?: any;
  myAlbums?: any;
  getData?: () => void;
  onUpdateAlbum?: (pictures: any[]) => void;
  handlePictureTransfer?: (newAlbum: 'myPhotos' | 'myPrivatePhotos' | 'quickshare', pictureId: string) => Promise<void>;
  setError: (error: string) => void;
  setFetching?: (boolean) => void;
  handleFileUpload?: (e: any) => void;
};

const AlbumPreview = (props: AlbumPreviewProps) => {
  const [pictures, setPictures] = useState<any[]>(props.selectedAlbum.pictures);

  const isMyPhotos = props.myAlbums?.myPhotos?.id === props.selectedAlbum?.id;
  const isPrivatePhotos = props.myAlbums?.myPrivatePhotos?.id === props.selectedAlbum?.id;
  const isQuickshare = props.myAlbums?.quickshare?.id === props.selectedAlbum?.id;

  useEffect(() => {
    if (pictures && props.selectedAlbum.pictures !== pictures) {
      setPictures(props.selectedAlbum.pictures);
    }
  }, [props.selectedAlbum.pictures]);

  const updateNewOrder = async () => {
    await setOrderPictures(pictures);
  };

  const movePicture = useCallback(
    (dragIndex, hoverIndex) => {
      if (isMyPhotos && (hoverIndex === 0 || dragIndex === 0)) {
        return;
      }
      const dragPicture = pictures[dragIndex];
      const picturesCopy = [...pictures];
      picturesCopy.splice(dragIndex, 1);
      picturesCopy.splice(hoverIndex, 0, dragPicture);
      const newPictures = picturesCopy.map((picture, index) => {
        return {
          ...picture,
          order: index,
        };
      });
      setPictures(newPictures);
      props.onUpdateAlbum(newPictures);
    },
    [pictures]
  );

  const setMainPicture = async (picture: any) => {
    if (props.selectedAlbum.type !== 'myPhotos') return;

    const res: any = await setProfilePicture(picture.id);
    if (res.error) {
      props.setError(res.error);
      return;
    }
    setMe();
    props.getData();
  };

  const updateDescription = async (text: string, picture: any) => {
    const res = await updatePictures(picture.id, {
      description: text,
    });
    if (res.error) {
      props.setError(res.error);
      return;
    }
    props.getData();
  };

  const deletePicture = async (picture: any) => {
    const res = await deletePictures(picture?.id);
    if (res.error) {
      props.setError(res.error);
      return;
    }
    props.getData();
  };

  const onChangeMainPicture = async (e: any) => {
    props.setFetching(true);
    await uploadAndChangeMainPicture(e.target.files[0], props.setError);
    setMe();
    props.getData();
  };

  return (
    <GridWrapper>
      <UploadContainer>
        <UploadFile onChange={props.handleFileUpload} />
      </UploadContainer>

      {pictures &&
        props.myAlbums &&
        pictures.map((picture, index) => (
          <Picture
            key={picture.id}
            index={index}
            movePicture={movePicture}
            onDragEnd={updateNewOrder}
            id={picture.id}
            onMainPicture={() => setMainPicture(picture)}
            onChangeMainPicture={onChangeMainPicture}
            moderation={picture.moderationRating}
            description={picture.description ? picture.description : ''}
            onDescription={(text) => {
              updateDescription(text, picture);
            }}
            onDelete={() => deletePicture(picture)}
            isMain={index === 0 && props.myAlbums?.myPhotos?.id === props.selectedAlbum?.id}
            isMyPhotos={isMyPhotos}
            isPrivatePhotos={isPrivatePhotos}
            isQuickshare={isQuickshare}
            handlePictureTransfer={props.handlePictureTransfer}
            path={picture.path}
          />
        ))}
    </GridWrapper>
  );
};

const GridWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 4px;
  ${FancyScrollbar}
`;

const UploadContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  margin: 0;
`;

const UploadImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.darkGray};
  border: 1px solid ${colors.darkGrey2};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default AlbumPreview;
