import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import Button from '../../../../../components/forms/button.component';

import { checkQuickshare, toggleQuickshareStatus } from '../../../../../services/chat.service';
import ModalSlice from '../../../../../store/slices/modal.slice';
import ChatSlice from '../../../../../store/slices/chat.slice';
import { useLogin } from '../../../../../utils/auth.utils';

import colors from '../../../../../themes/colors-v2.theme';
import fonts from '../../../../../themes/fonts.theme';
import { getConversationInfo } from '../../../../../utils/chat.utils';
import useLocale from '../../../../../utils/locale/locale.hook';
import CountdownComponent from '../countdown.component';

enum ToggleLoadState {
  IDLE = 'IDLE',
  LOADING_ACCEPT = 'LOADING_ACCEPT',
  LOADING_REFUSE = 'LOADING_REFUSE',
}

interface QuickshareMessageProps {
  message: any;
  sender: 'other' | 'me';
  conversation: any;
  setConversationMessages: React.Dispatch<React.SetStateAction<any[]>>;
  showQuickshareForm?: boolean;
}

export default function QuickshareMessage({
  message,
  sender,
  conversation,
  setConversationMessages,
}: QuickshareMessageProps) {
  const locale = useLocale();
  const { me } = useLogin();
  const { conversationName } = getConversationInfo(me, conversation);
  const dispatch = useDispatch();
  const [hasTimer, setHasTimer] = useState(false);
  const [toggleLoadState, setToggleLoadState] = useState(ToggleLoadState.IDLE);
  const [timeLeft, setTimeLeft] = useState<number>(null);

  if (message.isAnonymous) return null;

  const shouldSeePictures =
    (message.quickshare.status === 'accepted' && message.quickshare.requested === true && sender === 'me') ||
    (message.quickshare.status === 'accepted' && message.quickshare.requested === false && sender === 'other');

  const shouldAlignLeft = me.id !== message.sender.id;

  const toggleStatusHandler = (newStatus: 'accepted' | 'refused') => {
    if (toggleLoadState === ToggleLoadState.LOADING_ACCEPT || toggleLoadState === ToggleLoadState.LOADING_REFUSE) {
      return;
    }

    setToggleLoadState(newStatus === 'accepted' ? ToggleLoadState.LOADING_ACCEPT : ToggleLoadState.LOADING_REFUSE);

    toggleQuickshareStatus({
      messageId: message.id,
      status: newStatus,
    })
      .then((res) => {
        setToggleLoadState(ToggleLoadState.IDLE);
        if (res?.success) {
          setConversationMessages((prevMessages) => {
            const newMessages: any = JSON.parse(JSON.stringify(prevMessages));
            const foundMessage = newMessages.find((_message: any) => _message.id === message.id);
            if (!!foundMessage) {
              foundMessage.quickshare.status = newStatus;
            }
            return newMessages;
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    const checkQuickshareHandler = () => {
      checkQuickshare({
        messageId: message.id,
      }).then((res) => {
        if (res?.success && res.available) {
          setConversationMessages((prevMessages) => {
            const newMessages: any[] = JSON.parse(JSON.stringify(prevMessages));
            const messageIndex = newMessages.findIndex((_message) => _message.id === message.id);
            const foundMessage = newMessages[messageIndex];
            if (message.quickshare.status === 'asked') {
              foundMessage.quickshare.status = res.available ? 'accepted' : 'ended';
            }
            if (!!foundMessage) {
              setTimeLeft(res.timeRemaining);
              setHasTimer(true);
              foundMessage.pictures = res.pictures;
              if (messageIndex + 10 > newMessages.length) {
                dispatch(ChatSlice.actions.setScrollToBottom(true));
              }
            }
            return newMessages;
          });
        }
      });
    };

    if (shouldSeePictures) {
      checkQuickshareHandler();
    }
  }, [shouldSeePictures, message.id, message.quickshare.status, setConversationMessages, dispatch]);

  useEffect(() => {
    let interval = null;

    if (hasTimer) {
      interval = setInterval(() => {
        setTimeLeft((current) => {
          if (current <= 0) {
            clearInterval(interval);
            setHasTimer(false);
            return 0;
          }

          return current - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [hasTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      setConversationMessages((prevMessages) => {
        const newMessages: any[] = JSON.parse(JSON.stringify(prevMessages));
        const foundMessage = newMessages.find((_message) => _message.id === message.id);
        if (!!foundMessage) {
          foundMessage.quickshare.status = 'ended';
        }
        return newMessages;
      });
    }
  }, [timeLeft, message.id, setConversationMessages]);

  const mapStatusToLabel = (pseudo: string) => ({
    left: {
      asked: locale('chat.quickshare.asked.other', { pseudo }),
      accepted: locale('chat.quickshare.accepted.other', { pseudo }),
      ended: locale('chat.quickshare.ended.other', { pseudo }),
    },
    right: {
      asked: locale('chat.quickshare.asked.me', { pseudo }),
      accepted: locale('chat.quickshare.accepted.me', { pseudo }),
      ended: locale('chat.quickshare.ended.me', { pseudo }),
    },
  });

  return (
    <>
      {message.quickshare.status !== 'refused' && (
        <>
          <Message align={shouldAlignLeft}>
            <TitleContainer align={shouldAlignLeft}>
              <img alt="" width="16px" height="16px" src={'/assets/icons/sidebar/navbar/lock-album-white.svg'} />
              <Title align={shouldAlignLeft}>{locale('chat.actions.share_album')}</Title>
            </TitleContainer>
            <div>
              {mapStatusToLabel(conversationName)[shouldAlignLeft ? 'left' : 'right'][message.quickshare.status]}
            </div>
            {message.quickshare.status === 'asked' && sender === 'other' && (
              <ToggleStatusButtons>
                <Button
                  text={locale('global.no')?.toUpperCase()}
                  outlined
                  onClick={() => toggleStatusHandler('refused')}
                  loading={toggleLoadState === ToggleLoadState.LOADING_REFUSE}
                  style={buttonStyle}
                />
                <Button
                  text={locale('global.yes')?.toUpperCase()}
                  onClick={() => toggleStatusHandler('accepted')}
                  loading={toggleLoadState === ToggleLoadState.LOADING_ACCEPT}
                  style={buttonStyle}
                />
              </ToggleStatusButtons>
            )}
          </Message>
          {shouldSeePictures && (
            <MessagePictures align={shouldAlignLeft}>
              {message.pictures.map((picture: any, index: number) => (
                <Picture
                  key={index}
                  onClick={() => {
                    dispatch(
                      ModalSlice.actions.setFullSizeImageVisibleVisible({
                        visible: true,
                        images: message.pictures,
                        start: index,
                        timeLeft: timeLeft,
                        type: 'quickshare',
                      })
                    );
                  }}
                  isAlone={message.pictures.length < 2}
                >
                  <img alt="" src={picture.path} />
                </Picture>
              ))}
              <CountdownComponent timeleft={timeLeft} style={{ top: '8px', left: '8px', position: 'absolute' }} />
            </MessagePictures>
          )}
        </>
      )}
    </>
  );
}

const buttonStyle = {
  flex: 1,
  display: 'flex',
  height: '27px',
  padding: '6px 8px',
  borderRadius: '4px',
  backgroundColor: colors.darkBlue,
  color: colors.white,
  margin: '0 4px',
};

const Message = styled.div<{ align: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${colors.white};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;

  & > p {
    margin: 0;
  }

  & > .timer {
    margin-right: 8px;
    position: relative;
  }
`;

const TitleContainer = styled.div<{ align: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${colors.white};
  margin-bottom: 14px;
  margin-top: 8px;

  & > img {
    margin-right: 8px;
  }

  body {
    display: grid;
    height: 100vh;
  }
`;

const Title = styled.div<{ align: boolean }>`
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 12px;
  color: ${colors.white};
`;

const ToggleStatusButtons = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
  width: 180px;
  display: flex;

  & > * {
    flex: 1;
    margin-right: 8px;
  }
`;

const MessagePictures = styled.div<{ align: boolean }>`
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
`;

const Picture = styled.div<{ isAlone: boolean }>`
  cursor: pointer;
  width: ${(p) => (p.isAlone ? '280px' : '140px')};
  height: ${(p) => (p.isAlone ? '280px' : '140px')};

  & > img {
    border-radius: 4px;
    object-fit: cover;
    width: ${(p) => (p.isAlone ? '280px' : '136px')};
    height: ${(p) => (p.isAlone ? '280px' : '136px')};
    padding-left: 2px;
  }
`;
