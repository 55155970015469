import { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/forms/button.component';
import colors from '../../../../../themes/colors-v2.theme';
import { useLogin } from '../../../../../utils/auth.utils';
import useLocale from '../../../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../../../components/forms/primaryButton.component';

type UploadFileProps = {
  onChange: (e?: any) => void;
};

const UploadFile = ({ onChange }: UploadFileProps) => {
  const fileInput = useRef(null);

  const getFile = () => {
    fileInput.current.click();
  };

  return (
    <UploadImage onClick={getFile}>
      <input
        ref={fileInput}
        style={{ display: 'none' }}
        type="file"
        id="myFile"
        name="filename"
        onChange={onChange}
        multiple
        accept="image/*, .heic"
      />
      <PrimaryButton isActive>
        <div style={{ transform: 'scale(1.4)' }}>+</div>
      </PrimaryButton>
    </UploadImage>
  );
};

const UploadImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.darkGray};
  border: 1px solid ${colors.darkGrey2};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default UploadFile;
