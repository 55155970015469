import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Dropdown from './dropdown.component';
import moment from 'moment';
import useLocale from '../../utils/locale/locale.hook';

interface DatepickerProps {
  onChange?: (date: Date) => void;
  value?: Date;
  error?: boolean;
  isSettingsScreen?: boolean;
}

const Datepicker = (props: DatepickerProps) => {
  const [day, setDay] = useState(Number(props?.value?.getDate()));
  const [month, setMonth] = useState(Number(props?.value?.getMonth() + 1));
  const [year, setYear] = useState(Number(props?.value?.getFullYear()));
  const [dateError, setDateError] = useState(props.error);
  const locale = useLocale();

  useEffect(() => {
    if (day && month && year) {
      var date = new Date(year, month - 1, day);
      if (!(date instanceof Date)) props?.onChange(null);
      else props?.onChange(date);
    } else {
      props?.onChange(null);
    }
  }, [day, month, year]);

  useEffect(() => {
    setDateError(props.error);
  }, [props.error]);

  const days = new Array(31).fill(0).map((a, index) => ({ value: index + 1, label: index + 1 + '' }));
  const months = moment.months().map((month, index) => ({ value: index + 1, label: month }));
  const yearsMin = moment().year() - 18;
  const yearsMax = moment().year() - 100;
  const years = new Array(yearsMin - yearsMax)
    .fill(yearsMin)
    .map((year, index) => ({ value: year - index, label: year - index + '' }));

  return (
    <Container>
      <Dropdown
        lines={days}
        error={dateError}
        value={day}
        placeholder={locale('global.date.day')}
        onSelect={(value) => setDay(value)}
        style={{
          width: props.isSettingsScreen ? 30 : 84,
        }}
      />
      <Dropdown
        lines={months}
        error={dateError}
        value={month}
        placeholder={locale('global.date.month')}
        onSelect={(value) => setMonth(value)}
        style={{
          width: props.isSettingsScreen ? 80 : 84,
        }}
      />
      <Dropdown
        lines={years}
        error={dateError}
        placeholder={locale('global.date.year')}
        value={year}
        onSelect={(value) => setYear(value)}
        style={{ width: props.isSettingsScreen ? 40 : 84 }}
      />
    </Container>
  );
};

export default Datepicker;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
