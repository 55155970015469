import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import colors from '../../../themes/colors-v2.theme';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';

interface Props {
  text: string;
  isActiveLink: boolean;
  setActiveCategory?: Dispatch<SetStateAction<string>>;
}

export const CategoryButton = (props: Props) => {
  const { text, isActiveLink, setActiveCategory } = props;
  return (
    <PrimaryButton size="small" isActive={isActiveLink} onClick={() => setActiveCategory && setActiveCategory(text)}>
      {text}
    </PrimaryButton>
  );
};

const Wrapper = styled.div<{ isActiveLink: boolean }>`
  width: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey9};
  color: ${({ isActiveLink }) => (isActiveLink ? colors.accent : colors.darkGrey2)};
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: ${colors.lightGrey8};
  }
`;
