export type Status = "offline" | "online" | "drink" | "love" | "now";

export const getStatusColor = (
  status: Status
): { circle: string; centerCircle: string } => {
  const colorStatus = {
    offline: {
      circle: "#ffffff",
      centerCircle: "#000000",
    },
    online: {
      circle: "#027A48",
      centerCircle: "#12B76A",
    },
    incognito: {
      circle: "#ffffff",
      centerCircle: "#ffffff",
    },
    recently_online: {
      circle: "#F79009",
      centerCircle: "#F79009",
    },
  };

  return colorStatus[status] ?? colorStatus.online;
};
