import { User } from '../models/user.model';
import { del, put, post, get, queryBuilder } from './utils.service';
import * as queryString from 'query-string';

export const getMyTravels = async (query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/travel?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const addATravel = async (travel) => {
  const res = await post(`/travel`, travel);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const removeATravel = async (travelUID) => {
  const res = await del(`/travel`, travelUID);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateATravel = async (newTravel) => {
  const res = await put(`/travel`, newTravel);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getTravellersNumber = async (cityUid) => {
  const res = await get(`/travel/travellers-number/${cityUid}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getCurrentTravelers = async (cityUid) => {
  const res = await get(`/travel/travellers?travellingToCityUid=${cityUid}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSoonTravelers = async (cityUid) => {
  const res = await get(`/travel/soon-travellers?travellingToCityUid=${cityUid}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getAroundTravellers = async (query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/travel/around-travellers?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();

  return payload.status === 'success' ? payload.data : { error: payload.message };
};
