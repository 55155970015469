import styled from "styled-components/macro";

const Gradient = styled.div`
  position: absolute;
  bottom: 0px;
  height: 38px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(6, 5, 5, 0), #111213 130%);
`;

export default Gradient;
