import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import useLocale from '../../utils/locale/locale.hook';
import styled from 'styled-components';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';
import {
  getSharedByOtherQuickshare,
  getSharedWithQuickshare,
  revokeAllQuickshareAccess,
  updateQuickshareAccess,
} from '../../services/chat.service';
import { SharedByAlbum } from '../messages/components/shared-by-album-item.component';
import { SharedWithAlbum } from '../messages/components/shared-with-album-item.component';
import { useLogin } from '../../utils/auth.utils';
import { CustomGrid } from '../nearby/components/custom-grid.component';
import { Flex } from '../../components/style/flex.component';
import Text from '../../components/style/text.component';
import { PrimaryButton } from '../../components/forms/primaryButton.component';
import { useRedirectUnsignedUser } from '../../utils/useRedirectUnsignedUser';

export default function SharedAlbumsScreen() {
  useRedirectUnsignedUser();

  const locale = useLocale();

  const { isLogin } = useLogin();
  const sharedByAlbumsRef = useRef(null);

  const [hasNoSharedByAlbum, setHasNoSharedByAlbum] = useState(true);
  const [sharedByAlbums, setSharedByAlbums] = useState<SharedByAlbum[]>([]);
  const [hasNoSharedWithAlbum, setHasNoSharedWithAlbum] = useState(true);
  const [sharedWithAlbums, setSharedWithAlbums] = useState<SharedWithAlbum[]>([]);
  const [activeTab, setActiveTab] = useState<'my-access' | 'shared-with'>('my-access');

  /**
   * Fonction permettant de récupérer les albums pour lesquels l'utilisateur a un accès partagé.
   */
  const refreshSharedByAlbums = () => {
    if (setHasNoSharedByAlbum) {
      setHasNoSharedByAlbum(false);
    }
    getSharedByAlbums().then((sharedByAlbums) => {
      if (Array.isArray(sharedByAlbums)) {
        setSharedByAlbums(sharedByAlbums);
        if (setHasNoSharedByAlbum) {
          setHasNoSharedByAlbum(sharedByAlbums.length === 0);
        }
      }
    });
  };

  const getSharedByAlbums = async () => {
    const res = await getSharedByOtherQuickshare();
    if (!Array.isArray(res) || res.length === 0) {
      setHasNoSharedByAlbum(true);
      return;
    }
    setSharedByAlbums(res);
  };

  useImperativeHandle(sharedByAlbumsRef, () => ({
    refreshSharedByAlbums: () => {
      refreshSharedByAlbums();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshSharedByAlbums();
    }
  }, [isLogin]); // eslint-disable-line

  /**
   * Fonction permettant de récupérer les utilisateurs avec lesquels l'utilisateur a partagé son album.
   */
  const refreshSharedWithAlbums = () => {
    if (setHasNoSharedWithAlbum) {
      setHasNoSharedWithAlbum(false);
    }
    getSharedWithAlbums().then((sharedByAlbums) => {
      if (Array.isArray(sharedByAlbums)) {
        setSharedWithAlbums(sharedByAlbums);
        if (setHasNoSharedWithAlbum) {
          setHasNoSharedWithAlbum(sharedByAlbums.length === 0);
        }
      }
    });
  };

  const getSharedWithAlbums = async () => {
    const res = await getSharedWithQuickshare();
    if (!Array.isArray(res) || res.length === 0) {
      setHasNoSharedWithAlbum(true);
      return;
    }
    setSharedWithAlbums(res);
  };

  const revokeAlbumAccessForAll = async () => {
    try {
      await revokeAllQuickshareAccess();
      refreshSharedWithAlbums();
    } catch (error) {
      console.error('Error revoking access for all users:', error);
    }
  };

  useImperativeHandle(sharedByAlbumsRef, () => ({
    refreshSharedWithAlbums: () => {
      refreshSharedWithAlbums();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshSharedWithAlbums();
    }
  }, [isLogin]); // eslint-disable-line

  if (!isLogin) return <div></div>;

  return (
    <div style={{ height: '100%' }}>
      <FixedHeader>
        <TitleWrapper>
          <img src="assets/icons/sidebar/navbar/shared-album-white.svg" width={26} height={24} />
          <Title>{locale('global.access-album')}</Title>
        </TitleWrapper>
        <Header>
          <Row>
            <FilterButton active={activeTab === 'my-access'} onClick={() => setActiveTab('my-access')}>
              {locale('chat.album.my_access')}
            </FilterButton>
            <FilterButton active={activeTab === 'shared-with'} onClick={() => setActiveTab('shared-with')}>
              {locale('chat.album.shared_with')}
            </FilterButton>
          </Row>
        </Header>
      </FixedHeader>
      <Content>
        {activeTab === 'my-access' && (
          <CustomGrid
            users={sharedByAlbums.map((sharedBy) => sharedBy.shared_by)}
            cellType="square"
            isFromSharedAlbum={true}
            showSubscribeAd={false}
          />
        )}
        {activeTab === 'shared-with' && (
          <Flex direction="column" gap="8px">
            <Flex
              direction="column"
              gap="16px"
              style={{ background: '#15191FE5', border: '1px solid #202939', padding: 14 }}
            >
              <Flex alignItems="flex-start" gap="18px">
                <img src="/assets/icons/shared-albums/info.svg" />
                <Flex direction="column" gap="4px">
                  <Text style={{ fontWeight: 700 }}> {locale('shared-albums.warning.title')}</Text>
                  <Text fontSize="12px" bold color={colors.darkText}>
                    {locale('shared-albums.warning.label')}
                  </Text>
                </Flex>
              </Flex>
              <PrimaryButton
                size="large"
                style={{
                  background: 'white',
                  color: 'black',
                  width: 'fit-content',
                  fontSize: 14,
                  padding: '8px 14px 8px 14px',
                }}
                onClick={revokeAlbumAccessForAll}
              >
                {locale('shared-albums.revoke_access')}
              </PrimaryButton>
            </Flex>
            <Flex style={{ flexWrap: 'wrap' }} gap="8px 32px">
              {sharedWithAlbums.map((sharedWithAlbum) => (
                <SharedWithAlbum
                  key={sharedWithAlbum.shared_with.id}
                  sharedWithAlbum={sharedWithAlbum}
                  refreshSharedWithAlbums={refreshSharedWithAlbums}
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Content>
    </div>
  );
}

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: ${fonts.bold.weight};
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
    width: 100%;
    margin: 0;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 100px 10px 10px 10px;
  width: fit-content;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: ${colors.darkGradient};

  @media (max-width: 1000px) {
    padding-top: 0;
  }
`;
