import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';

interface FullHeightPaneProps {
  isDesktop: boolean;
  width?: number;
  children?: any;
  height?: string;
  hasNoBorderRadius?: boolean;
  padding?: string;
}

const FullHeightPane = ({ isDesktop, width, children, height, hasNoBorderRadius, padding }: FullHeightPaneProps) => (
  <Wrapper isDesktop={isDesktop} width={width} height={height} hasNoBorderRadius={hasNoBorderRadius} padding={padding}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div<{
  isDesktop: boolean;
  width: number;
  height: string;
  hasNoBorderRadius: boolean;
  padding: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: ${colors.darkGradient};
  width: ${(props) => (props.isDesktop ? `${props.width ?? '375'}px` : '100%')};
  height: ${(props) => (props.isDesktop ? (props.height ? props.height : '100vh') : '')};
  border-radius: ${(props) => `${props.hasNoBorderRadius || !props.isDesktop ? 0 : 16}px`};
  padding: ${({ padding }) => (padding ? padding : '32px')};

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

export default FullHeightPane;
