import React from "react";
import styled from "styled-components/macro";
import Button from "../../../components/forms/button.component";
import Text from "../../../components/style/text.component";
import { useDispatch, useSelector } from "react-redux";
import ModalSlice from "../../../store/slices/modal.slice";
import useLocale from "../../../utils/locale/locale.hook";
import { Link, useHistory } from "react-router-dom";
import TextInput from "../../../components/forms/text.input";
import colors from "../../../themes/colors.theme";
import { signin } from "../../../services/auth.service";
import AuthSlice from "../../../store/slices/auth.slice";
import { setMe } from "../../../utils/auth.utils";
import Checkbox from "../../../components/forms/checkbox.input";
import PasswordInput from "../../../components/forms/password.input";
import fonts from "../../../themes/fonts.theme";
import Dropdown from "../../../components/forms/dropdown.component";
import {
  getAvailableLanguage,
  setLocale,
} from "../../../utils/locale/locale.utils";
import moment from "moment";
import AppStoreButton from "../../../components/miscellaneous/app-store-button.component";
import PlayStoreButton from "../../../components/miscellaneous/play-store-button.component";
import Cookies from "universal-cookie";

const Container = styled.div`
  height: calc(100vh - 100px);
  min-height: 685px;
  width: 100%;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  z-index: -1;
  height: calc(100vh - 100px);
  min-height: 685px;
  width: 100%;
  background: radial-gradient(
    53.99% 80.64% at 53.99% 50%,
    #3f3f3f 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  filter: blur(5px);
`;

const Top = styled.div`
  width: calc(100% - 152px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 48px 71px 0px 81px;
`;

const Content = styled.div`
  margin-left: 13%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 600px;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0px;
  right: 20%;
`;

const Navbar = styled.div`
  height: 100px;
  width: calc(100% - 159px);
  position: relative;
  background: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 77px 0px 82px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Forgot = styled.div`
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  text-decoration: underline;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  font-size: 16px;
  color: white;
  cursor: pointer;
`;

const NavLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 70px;
  color: white;
`;

const ForgotRemember = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 22px 0px;
  margin-left: 4px;
`;

const SigninScreen = () => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const [pseudo, setPseudo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [languageDropdown, setLanguageDropdown] = React.useState(null);
  const keys = useSelector((state: any) => state.localeStore.keys);
  const [language] = React.useState(localStorage.getItem("language"));

  const submit = async () => {
    setLoading(true);

    const res = await signin(pseudo, password);
    if (res.jwt) {
      localStorage.setItem("onboarding", "ok");
      if (rememberMe) {
        const cookies = new Cookies();
        cookies.set("token", res.jwt, {
          domain: ".bearwww.com",
          path: "/",
          secure: true,
        });
        localStorage.setItem("token", res.jwt);
      }
      dispatch(AuthSlice.actions.setToken(res.jwt));
      setMe();
      setPseudo("");
      setPassword("");
      dispatch(ModalSlice.actions.setSigninVisible(false));
      history.push("/");
    } else {
      //setErrorMsg("invalid creds")
    }
    setLoading(false);
  };

  const register = () => {
    dispatch(ModalSlice.actions.setSigninVisible(false));
    dispatch(ModalSlice.actions.setSignup({ visible: true }));
  };

  React.useEffect(() => {
    if (keys) {
      const availableLanguages = getAvailableLanguage();
      const res = availableLanguages.map((language) => ({
        label: locale("language." + language),
        value: language,
      }));
      setLanguageDropdown(res);
    }
  }, [keys, locale]);

  return (
    <>
      <Container>
        <Background />
        <Top>
          <div>
            <img alt="" width="77" height="72" src={"/assets/imgs/logo.svg"} />
          </div>
          <div>
            {languageDropdown && (
              <Dropdown
                value={language}
                onSelect={(lang) => {
                  localStorage.setItem("language", lang);
                  setLocale(lang);
                  moment.locale(lang);
                }}
                style={{ width: 100 }}
                lines={languageDropdown}
                transparant
              />
            )}
          </div>
        </Top>

        <Content>
          <Title style={{ marginBottom: 11 }}>
            {locale("signin.landing.h1")}
          </Title>
          <Text h1 style={{ marginBottom: 30 }}>
            BEAR<span style={{ opacity: 0.8 }}>W</span>
            <span style={{ opacity: 0.6 }}>W</span>
            <span style={{ opacity: 0.4 }}>W</span>
          </Text>
          <TextInput
            onChange={(text) => setPseudo(text)}
            style={{
              marginBottom: 20,
              width: 472,
            }}
            placeholder={locale("signin.placeholder.pseudo")}
          />
          <PasswordInput
            style={{
              width: 472,
            }}
            value={password}
            onChange={(text) => setPassword(text)}
            placeholder={locale("signup.credentials.password")}
          />

          <ForgotRemember style={{ width: 472 }}>
            <Forgot>{locale("signin.forgot_password")}</Forgot>
            <Checkbox
              onChange={(value) => setRememberMe(value)}
              value={rememberMe}
              text={locale("global.remember")}
            />
          </ForgotRemember>

          <Button
            style={{
              width: 271,
              marginLeft: -5,
            }}
            onClick={submit}
            loading={loading}
            text={locale("global.login")}
            color="primary"
          />
          <Text style={{ fontSize: 16, marginTop: 25 }}>
            {locale("signin.landing.new ")}
            <span
              onClick={register}
              style={{
                fontWeight: 600,
                color: colors.primary,
                marginLeft: 6,
                cursor: "pointer",
              }}
            >
              {locale("signin.landing.join")}
            </span>
          </Text>
        </Content>

        <Image height="90%" src={"/assets/imgs/landing/1-model.png"} />
        <PlayStoreButton
          style={{
            position: "absolute",
            bottom: 24,
            right: 182,
            width: 132,
            height: 38,
          }}
        />
        <AppStoreButton
          style={{
            position: "absolute",
            bottom: 24,
            right: 35,
            width: 132,
            height: 38,
          }}
        />
      </Container>
      <Navbar>
        <NavLeft>
          <Link to="#" style={{ marginRight: 64 }}>
            <Text bodyBold>{locale("signin.landing.privacy")}</Text>
          </Link>
          <Link to="#" style={{ marginRight: 64 }}>
            <Text bodyBold>{locale("signin.landing.terms")}</Text>
          </Link>
          <Link to="#">
            <Text bodyBold>{locale("signin.landing.guidelines")}</Text>
          </Link>
        </NavLeft>
        <Button
          style={{ marginLeft: -3 }}
          text={locale("signin.loading.learn_more")}
          color="primary"
          outlined
        />
      </Navbar>
    </>
  );
};

export default SigninScreen;
