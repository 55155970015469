import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { useLogin } from '../../utils/auth.utils';

interface ScreenWrapperProps {
  children: React.ReactNode;
  shouldHavePadding?: boolean;
  style?: React.CSSProperties;
}

export default function ScreenWrapper({ children, shouldHavePadding = true, style, ...props }: ScreenWrapperProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { isLogin } = useLogin();
  const [showGetTheApp, setShowGetTheApp] = useState(false);

  useEffect(() => {
    if (isLogin) {
      setShowGetTheApp(false);
      return;
    }
    setTimeout(() => {
      setShowGetTheApp(true);
    }, 60000);
  }, []);

  return (
    <Wrapper
      isDesktop={isDesktop}
      shouldHavePadding={shouldHavePadding}
      isLogin={isLogin}
      showGetTheApp={showGetTheApp}
      style={style}
      {...props}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  isDesktop: boolean;
  shouldHavePadding: boolean;
  isLogin: boolean;
  showGetTheApp: boolean;
}>`
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 0px;
`;
