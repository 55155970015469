import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Text from "../../components/style/text.component";
import Notif from "./components/notif.component";
import Icons from "../../components/style/icons.component";
import { FancyScrollbar } from "../../components/style/fancy-scrollbar.style";
import FullHeightPane from "../../components/full-height-pane/full-height-pane.component";
import NotificationsSubMenu from "./components/notifications-sub-menu.component";

import {
  getNotifications,
  setReadNotifications,
  setSeenNotifications,
} from "../../services/notifications.service";
import NotifSlice from "../../store/slices/notification.slice";
import useLocale from "../../utils/locale/locale.hook";
import { useLogin } from "../../utils/auth.utils";
import { useOnClickOutside } from "../../utils/clickOutside.utils";
import fonts from "../../themes/fonts.theme";
import colors from "../../themes/colors-v2.theme";
import { useMediaQuery } from "react-responsive";

type OverlayNotificationsProps = {
  visible: boolean;
  onClose: () => void;
};

const OverlayNotifications = (props: OverlayNotificationsProps) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { isLogin } = useLogin();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [visible, setVisible] = useState(false);
  const [notificationsNew, setNotificationsNew] = useState([]);
  const [notificationsOld, setNotificationsOld] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const headerRef = useRef(null);
  const fullHeightRef = useRef(null);

  const getData = async () => {
    if (!isLogin) {
      history.push("/nearby");
      return;
    }
    const res = await getNotifications();
    if (Array.isArray(res)) {
      setNotificationsNew(res.filter((notif) => !notif.seen));
      setNotificationsOld(res.filter((notif) => notif.seen));
      setSeenNotifications(res);
      dispatch(NotifSlice.actions.setNotificationsNotSeen(0));
    }
  };

  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      getData();
    }
  }, [props.visible]);

  useOnClickOutside([headerRef], () => {
    setMenuIsOpen(false);
  });

  const handleClickOutside = (e) => {
    if (!fullHeightRef?.current?.contains(e.target)) {
      dispatch(NotifSlice.actions.setOverlayVisibility(false));
    }
  };

  const setNotifRead = () => {
    const notReadNew = notificationsNew.filter((notif) => notif.read == null);
    const notReadOld = notificationsOld.filter((notif) => notif.read == null);
    const notRead = notReadNew.concat(notReadOld);
    const notificationsNewCopy = notificationsNew.map((notif) => ({
      ...notif,
      read: new Date(),
    }));
    const notificationsOldCopy = notificationsOld.map((notif) => ({
      ...notif,
      read: new Date(),
    }));
    setNotificationsNew(notificationsNewCopy);
    setNotificationsOld(notificationsOldCopy);
    setReadNotifications(notRead);
  };

  if (!isLogin) return <></>;
  if (!visible) return <></>;

  const options = [
    {
      iconName: "check-outlined-white",
      text: locale("notifications.menu.read"),
      onClick: () => {
        setNotifRead();
      },
    },
    {
      iconName: "settings-outlined-white",
      text: locale("notifications.menu.settings"),
      onClick: () => {
        history.push("/settings/privacy");
        props.onClose();
      },
    },
  ];

  return (
    <OverlayWrapper onClick={handleClickOutside}>
      <NotifModal ref={fullHeightRef}>
        <FullHeightPane
          isDesktop={isDesktop}
          width={375}
          hasNoBorderRadius
          padding={"32px 24px"}
        >
          <Header ref={headerRef}>
            <HeaderTitle active={false}>
              {locale("global.notifications")}
            </HeaderTitle>
            <HeaderActions>
              <div
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icons
                  name="menu-outlined-white"
                  width={"16"}
                  height={"4"}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
              <img
                alt=""
                src="/assets/icons/icons-actions-close-dark.svg"
                onClick={() => {
                  dispatch(NotifSlice.actions.setOverlayVisibility(false));
                }}
              />
            </HeaderActions>
            {menuIsOpen && (
              <NotificationsSubMenu
                options={options}
                closeMenu={() => setMenuIsOpen(false)}
                style={{ top: "50px", right: "70px" }}
              />
            )}
          </Header>
          <Container>
            {notificationsNew.length > 0 && (
              <>
                <SectionTitleWrapper>
                  <Title>{locale("notifications.new")}</Title>
                </SectionTitleWrapper>
                <Grid style={{ marginBottom: 19 }}>
                  {notificationsNew.map((notification, index) => {
                    return (
                      <Notif
                        onClose={() => props.onClose()}
                        key={notification.id + "/" + index}
                        notif={notification}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
            {notificationsOld.length > 0 && (
              <>
                <SectionTitleWrapper>
                  <Title topBorder={notificationsNew.length > 0}>
                    {locale("notifications.earlier")}
                  </Title>
                </SectionTitleWrapper>
                <Grid>
                  {notificationsOld.map((notification, index) => {
                    return (
                      <Notif
                        onClose={() => props.onClose()}
                        key={notification.id + "/" + index}
                        notif={notification}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
          </Container>
        </FullHeightPane>
      </NotifModal>
    </OverlayWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${FancyScrollbar}

  @media (max-width: 1000px) {
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

const NotifModal = styled.div`
  position: absolute;
  right: 0;

  @media (max-width: 1000px) {
    position: fixed;
    top: 60px;
    width: calc(100% + 1px);
    padding-top: 12px;
    background-color: ${colors.backgroundLightGrey};
    box-sizing: border-box;
  }
`;

const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 500;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background: none;
  }
`;

const Header = styled.div`
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  letter-spacing: 0.6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 32px 24px;
  }
`;

const HeaderTitle = styled.p<{ active: boolean }>`
  position: relative;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 24px;
  color: ${colors.black};
  margin: 0;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
    width: 10px;
    height: 10px;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 64px;
`;

const Title = styled(Text)<{ topBorder?: boolean }>`
  text-transform: uppercase;
  font-family: ${fonts.light.name};
  font-weight: ${fonts.light.weight};
  color: #5c6773;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #343536;
  border-top: ${(props) => (props.topBorder ? "1px solid #343536" : "none")};
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export default OverlayNotifications;
