import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import ModalTemplate from "../../../components/miscellaneous/modal-template.component";

import { useLogin } from "../../../utils/auth.utils";
import { getConversationInfo } from "../../../utils/chat.utils";
import { sendLocation } from "../../../services/chat.service";

import { AddMediaStatus } from "../types/add-media-status.enum";

import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import { getLocation } from "../../../utils/geolocation.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { useMediaQuery } from "react-responsive";

enum SendStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  conversation: any;
  setAddMediaStatus: React.Dispatch<React.SetStateAction<AddMediaStatus>>;
}

export default function SendLocationModal({
  visible,
  closeModal,
  conversation,
  setAddMediaStatus,
}: ModalProps) {
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { conversationName } = getConversationInfo(me, conversation);
  const [sendStatus, setSendStatus] = useState(SendStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const locale = useLocale();

  const sendLocationHandler = () => {
    setIsLoading(true);
    if (sendStatus === SendStatus.LOADING) {
      setIsLoading(false);
      return;
    }

    setErrorMessage("");
    if (!me.locationOptin) {
      setErrorMessage(
        `Please allow us to access your location to share this type of message. Go to your "Settings" then to "Privacy" to fix that.`
      );
      setSendStatus(SendStatus.ERROR);
      setIsLoading(false);
      return;
    }

    getLocation((data) => {
      if (!data.latitude || !data.longitude) {
        setSendStatus(SendStatus.ERROR);
        setIsLoading(false);
        return;
      }
      setSendStatus(SendStatus.LOADING);
      sendLocation({
        conversationId: conversation.id,
        latitude: data.latitude,
        longitude: data.longitude,
      }).then((res) => {
        if (res?.success) {
          setAddMediaStatus(AddMediaStatus.HIDDEN);
          setIsLoading(false);
          closeModal();
        } else {
          setIsLoading(false);
          setSendStatus(SendStatus.ERROR);
        }
      });
    });
  };

  useEffect(() => {
    if (!visible) {
      setSendStatus(SendStatus.IDLE);
      setErrorMessage("");
    }
  }, [visible]);

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModal}
      width="400px"
      style={{ borderRadius: isDesktop ? 8 : 0 }}
      fullsize={!isDesktop}
    >
      <ModalContent>
        <Header>{locale("message.send-location")}</Header>
        {sendStatus === SendStatus.ERROR ? (
          <Warning>
            {errorMessage ||
              "Something went wrong while sending your location, please try again later."}
          </Warning>
        ) : (
          <Warning>
            {locale("message.send-location-confirm")} {conversationName}.
          </Warning>
        )}
      </ModalContent>
      <ButtonsWrapper>
        <Button
          outlined
          onClick={closeModal}
          style={{
            backgroundColor: colors.white,
            border: `solid 1px ${colors.darkOrange3}`,
            color: colors.darkOrange3,
            width: "100%",
            borderRadius: 8,
            fontSize: 14,
          }}
        >
          {locale("cancel")}
        </Button>
        <Button
          onClick={!isLoading && sendLocationHandler}
          style={{
            backgroundColor: isLoading
              ? colors.lightGrey11
              : colors.darkOrange1,
            border: `solid 1px ${
              isLoading ? colors.lightGrey11 : colors.darkOrange3
            }`,
            color: isLoading ? colors.darkGrey2 : colors.white,
            width: "100%",
            borderRadius: 8,
            fontSize: 14,
          }}
        >
          {locale("filters.confirm")}
        </Button>
      </ButtonsWrapper>
    </ModalTemplate>
  );
}

const ModalContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
  color: ${colors.darkerGrey};

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  font-size: 18px;
`;

const Warning = styled.div`
  font-size: 12px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  margin-bottom: 24px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  width: 80%;
  margin: 0 auto 12px auto;
  display: flex;

  & > * {
    margin: 0 8px;
    flex: 1;
  }
`;

const Button = styled.div<{ outlined?: boolean }>`
  background-color: ${colors.accent};
  display: flex;
  cursor: pointer;
  height: 32px;
  color: ${({ outlined }) =>
    outlined ? colors.accent : colors.backgroundLight};
  border: solid 1px ${colors.accent};
  padding: 5px 32px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin: 2px 5px;
  ${(p) => p.outlined && "background-color: transparent;"}
`;
