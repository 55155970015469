import styled from "styled-components/macro";
import colors from "../../../../themes/colors.theme";

import TypingLoader from "./typing-loader.component";

interface ComponentProps {
  users: any[];
}

export default function UsersTyping({ users }: ComponentProps) {
  return (
    <>
      {users.length > 0 && (
        <Wrapper>
          <UsersWrapper>
            {users.map((user) => (
              <img alt="" key={user.id} src={user.profilePicture?.path} />
            ))}
          </UsersWrapper>
          <TypingLoader />
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const UsersWrapper = styled.div`
  margin-right: 12px;
  display: flex;

  & > img {
    margin-right: -5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid ${colors.backgroundDark};
    object-fit: cover;

    &:last-child {
      margin-right: 0;
    }
  }
`;
