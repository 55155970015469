import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Image from '../../../components/image.component';
import ModalSlice from '../../../store/slices/modal.slice';
import { Picture } from '../types/picture';

interface Props {
  pictures: Picture[];
  albumAvailable?: boolean;
}
const PicturesDisplay = (props: Props) => {
  const { pictures, albumAvailable } = props;
  const dispatch = useDispatch();

  const openFullSizeImages = (pictureSelected: any) => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: true,
        images: pictures,
        start: pictures.findIndex((picture: any) => picture === pictureSelected),
        type: 'pictures',
      })
    );
  };

  return (
    <Wrapper>
      {pictures.map((picture) => (
        <ImageContainer key={picture.id} albumAvailable={albumAvailable}>
          <Image
            alt={picture.description || ''}
            src={picture.path}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              cursor: 'pointer',
            }}
            optiWidth={100}
            onClick={() => albumAvailable && openFullSizeImages(picture)}
          />
        </ImageContainer>
      ))}
    </Wrapper>
  );
};

export default PicturesDisplay;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 6px;
  margin-top: 24px;
`;

const ImageContainer = styled.div<{ albumAvailable: boolean }>`
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1/1;

  & img {
    ${({ albumAvailable }) => !albumAvailable && 'filter: blur(8px);'}
  }
`;
