import styled from 'styled-components/macro';
import Text from '../../../components/style/text.component';
import { ADD, useNotifContext } from '../../../context/notif-context';
import { removeATravel } from '../../../services/travel.service';
import colors from '../../../themes/colors-v2.theme';
import { getFormatedDate } from '../../../utils/date.utils';
import useLocale from '../../../utils/locale/locale.hook';
import { Dispatch, SetStateAction, useState } from 'react';
import fonts from '../../../themes/fonts.theme';
import { useMediaQuery } from 'react-responsive';
import { Flex } from '../../../components/style/flex.component';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { useNotification } from '../../../utils/useNotification';

interface Props {
  trips: Trip[];
  getDataTrips: () => void;
  setTripsOpen: Dispatch<SetStateAction<boolean>>;
  setIsModifyingTrip: Dispatch<SetStateAction<boolean>>;
  setTrip: Dispatch<SetStateAction<Trip>>;
}

interface Trip {
  startDate: Date;
  endDate: Date;
  uid: string;
  city: City;
}

interface City {
  uid: string;
  nameKey: string;
  latitude: number;
  longitude: number;
  placeId: string;
  country: Country;
}

interface Country {
  nameKey: string;
  placeId: string;
  uid: string;
}

export const MyTrips = (props: Props) => {
  const { trips, getDataTrips, setTripsOpen, setIsModifyingTrip, setTrip } = props;
  const locale = useLocale();
  const { triggerNotification } = useNotification();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const removeTravel = async (uid: string) => {
    const travelUid = {
      travelUid: uid,
    };
    await removeATravel(travelUid);
    getDataTrips();
    triggerNotification({
      visible: true,
      title: locale('travel.deleted'),
    });
  };

  return (
    <Wrapper>
      {trips.map((trip) => (
        <Container key={trip.uid}>
          <TextContainer>
            <Text bold>{`${trip.city.nameKey[0].toUpperCase() + trip.city.nameKey.substring(1)}`}</Text>
            <Flex alignItems="center" style={{ fontSize: '12px', fontWeight: 400 }}>
              {locale('guide.date.from')?.toLowerCase()}
              <img
                src="/assets/icons/sidebar/navbar/calendar-mini.svg"
                alt="calendar"
                style={{ margin: '0 3px 0 8px' }}
                width={12}
                height={12}
              />
              <div style={{ marginRight: 8 }}>{getFormatedDate(trip.startDate, 'DD/MM/YYYY')}</div>
              {locale('guide.date.to')?.toLowerCase()}
              <img
                src="/assets/icons/sidebar/navbar/calendar-mini.svg"
                alt="calendar"
                style={{ margin: '0 3px 0 8px' }}
                width={12}
                height={12}
              />
              {getFormatedDate(trip.endDate, 'DD/MM/YYYY')}
            </Flex>
          </TextContainer>
          <IconsContainer>
            <PrimaryButton
              size="large"
              style={{ background: 'white', color: 'black' }}
              onClick={() => {
                setTrip(trip);
                setIsModifyingTrip(true);
                setTripsOpen(true);
              }}
            >
              {locale('global.edit')}
            </PrimaryButton>
            <PrimaryButton size="large" isActive onClick={() => removeTravel(trip.uid)}>
              {locale('trips.delete.title')}
            </PrimaryButton>
          </IconsContainer>
        </Container>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  margin: auto;
  width: 600px;
  @media (max-width: 1000px) {
    margin-bottom: 150px;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 14.5px 16px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  max-width: 546px;

  @media (max-width: 1000px) {
    box-shadow: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;

const TitleAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: ${fonts.bold.weight};
  margin: 0;
  padding: 0;
`;

const CreateTripButton = styled.button`
  outline: none;
  border: 1px solid ${colors.darkOrange3};
  background-color: ${colors.darkOrange1};
  color: white;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: ${fonts.bold.weight};
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 12px;

  & img {
    cursor: pointer;
  }
`;
