import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import useLocale from "../../../utils/locale/locale.hook";

import fonts from "../../../themes/fonts.theme";
import { logout, useLogin } from "../../../utils/auth.utils";
import colors from "../../../themes/colors-v2.theme";
import { openProfile } from "../../user-profile/profile.utils";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";

interface HeaderProps {
  selectedTab: string;
}

const Header = (props: HeaderProps) => {
  const { selectedTab } = props;
  const locale = useLocale();
  const { me } = useLogin();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const handleSeeProfile = (e) => {
    e.preventDefault();
    openProfile(me.pseudo);
  };

  return (
    <FixedHeader>
      <TitleWrapper>
        <img
          src={me?.profilePicture?.path}
          width={24}
          height={24}
          style={{ objectFit: "cover", borderRadius: 4 }}
        />
        <Title>{locale("account.edit_profile")}</Title>
      </TitleWrapper>
      <HeaderStyle>
        <Row>
          {!isDesktop ? (
            <img
              src="/assets/icons/nearby/chevron-right-white.svg"
              width={16}
              height={16}
              style={{
                marginLeft: 12,
                paddingLeft: 24,
                paddingRight: 8,
                paddingBottom: 10,
                transform: "rotate(180deg)",
              }}
              onClick={() => history.push("/profile")}
            ></img>
          ) : null}
          <FilterButton
            active={selectedTab === "edit-profile" ? true : undefined}
            to="/settings/edit-profile"
          >
            {locale("global.settings.edit_profile")}
          </FilterButton>
          <FilterButton
            active={selectedTab === "" ? true : undefined}
            to="/settings"
          >
            {locale("global.settings.my_settings")}
          </FilterButton>
          {isDesktop ? (
            <Fragment>
              <FilterButton
                active={false}
                to={``}
                onClick={(e) => handleSeeProfile(e)}
              >
                {locale("global.settings.see_my_profile")}
              </FilterButton>
              <FilterButton active={false} to={``} onClick={() => logout()}>
                {locale("account.logout")}
              </FilterButton>
            </Fragment>
          ) : null}
        </Row>
      </HeaderStyle>
    </FixedHeader>
  );
};

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: ${fonts.bold.weight};
`;

const HeaderStyle = styled.div`
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 4px 0px 0px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    padding-top: 8px;
    height: 44px;
    width: 100%;
    margin: 0;
    gap: 0px;
  }
`;

const FilterButton = styled(Link)<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};

  @media (max-width: 1000px) {
    padding: 12px 4px 10px 4px;
    width: 100%;
  }
`;

export default Header;
