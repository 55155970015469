import { del, post, put, get } from "./utils.service";

export const getBySlug = async (slug: string) => {
  const res = await get(`/posts/${slug}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const deletePost = async (postId: any) => {
  const res = await del(`/posts/${postId}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const updatePost = async (body: any) => {
  const res = await put("/posts", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const addComment = async (body: any) => {
  const res = await post("/posts/comments", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const removeComment = async (commentId: any) => {
  const res = await del(`/posts/comments/${commentId}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const removeLike = async (likeId: any) => {
  const res = await del(`/posts/likes/${likeId}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const addLike = async (body: any) => {
  const res = await post("/posts/likes", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const createPost = async (body: any) => {
  const res = await post("/posts", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
