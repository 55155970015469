import React from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { useSelector } from 'react-redux';
import { getLanguages } from '../../../../services/caracteristics.service';
import { updateUser } from '../../../../services/users.service';
import { Subtitle } from '../../../../components/settings/subtitle.component';
import { Values } from '../../../../components/settings/values.component';
import { Tags } from '../../../../components/settings/tags.component';
import Tag from '../../../../components/style/tag.component';
import Button from '../../../../components/forms-v2/button.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import styled from 'styled-components';

type LanguagesProps = {
  me?: any;
  onChange?: (languages) => void;
};

const Languages = (props: LanguagesProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [editOpen, setEditOpen] = React.useState(null);
  const [languagesCheckbox, setLanguagesCheckbox] = React.useState([]);
  const [languages, setLanguages] = React.useState(props?.me?.languages);
  const keys = useSelector((state: any) => state.localeStore.keys);
  const locale = useLocale();

  const getData = async () => {
    const languages = await getLanguages();
    setLanguagesCheckbox(languages);
  };

  const onSelect = async (selectedLanguage) => {
    const index = languages.findIndex((language) => language.languageKey === selectedLanguage.languageKey);
    let languagesCopy = [...languages];
    if (index === -1) {
      languagesCopy.push(selectedLanguage);
    } else {
      languagesCopy.splice(index, 1);
    }
    setLanguages(languagesCopy);
    await updateUser({
      languages: languagesCopy,
    });
    props?.onChange(languagesCopy);
  };

  React.useEffect(() => {
    if (keys) {
      getData();
    }
  }, [keys]);

  return (
    <>
      <Title>{locale('signup.language.title')}</Title>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tags>
          {languagesCheckbox?.length > 0 &&
            languagesCheckbox.map((language, idx) => (
              <PrimaryButton
                key={idx}
                onClick={() => onSelect(language)}
                isActive={
                  languages?.findIndex((languageSelected) => languageSelected.languageKey === language.languageKey) !==
                  -1
                }
              >
                {locale('language.name.' + language.languageKey)}
              </PrimaryButton>
            ))}
        </Tags>
      </Values>
    </>
  );
};

export default Languages;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
`;
