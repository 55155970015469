import { getAvailableLanguage, getDefaultLanguage } from "./locale.utils";

export const getUrlLanguage = () => {
  const pathname = window.location.pathname;
  const urlLanguage =
    getAvailableLanguage().includes(pathname.substring(1, 3)) &&
    pathname[3] === "/"
      ? pathname.substring(1, 3)
      : "";
  return urlLanguage
}

export const getCurrentLanguage = () => {
  return getUrlLanguage() || "en"
};

export const getUrl = () => {
  const languages = getAvailableLanguage();
  const languageIndex = languages.indexOf("en");
  languages.splice(languageIndex, 1);

  let currentUrl = new URL(window.location.href);
  let pathname = currentUrl.pathname;

  for (let language of languages) {
    if (pathname.includes(`/${language}/`)) {
      pathname = pathname.substring(3);
    }
  }
  return new URL(currentUrl.origin + pathname);
};