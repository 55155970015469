import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { createContext, useContext } from 'react';

const OpenStreetMapsContext = createContext(undefined);

const OpenStreetMapsProvider = ({ children }) => {
  const contextValue = new OpenStreetMapProvider({
    params: {
      addressdetails: 1,
    },
  });

  return <OpenStreetMapsContext.Provider value={contextValue}>{children}</OpenStreetMapsContext.Provider>;
};

function useOpenStreetMapsContext() {
  const context = useContext<OpenStreetMapProvider>(OpenStreetMapsContext);
  if (context === undefined) {
    throw new Error('useOpenStreetMapsContext must be used within an OpenStreetMapsProvider');
  }
  return context;
}

export { OpenStreetMapsProvider, useOpenStreetMapsContext };
