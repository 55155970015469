import React from 'react';
import styled from 'styled-components/macro';

import Icons from '../style/icons.component';
import { Loader } from '../style/loader.component';
import Text from '../style/text.component';

import colors from '../../themes/colors-v2.theme';

type ContainerProps = {
  outlined: boolean;
  disabled: boolean;
  small: boolean;
};

type ButtonProps = {
  text: string;
  outlined?: boolean;
  style?: any;
  small?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  loading?: boolean;
  disabled?: boolean;
  iconLeft?: string;
};

export default function Button(props: ButtonProps) {
  return (
    <Container
      disabled={props?.disabled}
      outlined={props.outlined}
      small={props?.small}
      style={props.style}
      onClick={props.onClick}
    >
      {props.loading ? (
        <Loader color={props.outlined ? colors.darkBlue : colors.backgroundDark} />
      ) : (
        <>
          {props?.iconLeft && <Icons name={props?.iconLeft} width="20" height="20" style={{ marginRight: 11 }} />}
          <Text
            style={{
              color: 'inherit',
              fontWeight: 600,
              fontSize: props.style?.fontSize || 12,
            }}
          >
            {props.text}
          </Text>
        </>
      )}
    </Container>
  );
}

export const Container = styled.button<ContainerProps>`
  border: none;
  outline: none;
  user-select: none;
  width: 100%;
  height: 32px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  background-color: ${colors.darkBlue};
  color: ${colors.backgroundLight};
  transition: all 200ms ease;

  ${(props) =>
    props.small &&
    `
    height: auto;
    width: auto;
    padding: 1px 13px;
    border-radius: 4px;
  `}

  ${(props) =>
    props.outlined &&
    `
      color: ${colors.primary};
      background-color: transparent;
    `}

  :hover {
    ${(props) =>
      props.outlined &&
      `
      color: ${colors.primaryHover};
      background-color: transparent;
    `}
  }
`;
