import styled from 'styled-components';

interface Props {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  customStyles?: string;
  gap?: string;
}
export const Flex = styled.div<Props>`
  color: white;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'stretch'};
  gap: ${(props) => props.gap || '0px'};
  ${(props) => props.customStyles};
`;
