import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Text from '../../../../components/style/text.component';
import { Subtitle } from '../../../../components/settings/subtitle.component';

import { updateUser } from '../../../../services/users.service';
import { getHeight, getWeight } from '../../../../utils/unities.utils';
import useLocale from '../../../../utils/locale/locale.hook';
import { useMediaQuery } from 'react-responsive';
import { Flex } from '../../../../components/style/flex.component';
import { useDebounce } from 'use-debounce';
import { StyledInput } from '../../../../components/forms/input.component';

type WeightHeightCaracteristicsProps = {
  me?: any;
  onChange?: () => void;
  isWeight?: boolean;
  isHeight?: boolean;
};

const WeightHeightCaracteristics = (props: WeightHeightCaracteristicsProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const heightRef = React.useRef();
  const weightRef = React.useRef();
  const [height, setHeight] = useState(props?.me.height);
  const [weight, setWeight] = useState(props?.me.weight);
  const [debouncedHeight] = useDebounce(height, 500);
  const [debouncedWeight] = useDebounce(weight, 500);

  async function updateHeight() {
    await updateUser({ height: debouncedHeight });
    props?.onChange?.();
  }

  async function updateWeight() {
    await updateUser({ weight: debouncedWeight });
    props?.onChange?.();
  }

  const weighDropdownLines = [];
  for (let i = 30; i < 300; i++) {
    weighDropdownLines.push({ label: getWeight(i), value: i });
  }
  const heightDropdownLines = [];
  for (let i = 50; i < 250; i++) {
    heightDropdownLines.push({ label: getHeight(i), value: i });
  }

  useEffect(() => {
    if (debouncedHeight !== props.me.height) {
      updateHeight();
    }
  }, [debouncedHeight]);

  useEffect(() => {
    if (debouncedWeight !== props.me.weight) {
      updateWeight();
    }
  }, [debouncedWeight]);

  return (
    <Flex alignItems="center" style={{ width: '100%' }}>
      <Subtitle>{props.isHeight ? locale('global.height') : locale('global.weight')}</Subtitle>
      <Row>
        {props.isHeight && (
          <Line>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <StyledInput
                ref={heightRef}
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                placeholder={locale('profil_edit.no_firstname')}
              />

              <Text style={{ width: 20, textAlign: 'center' }}>
                {locale('global.metter.' + localStorage.getItem('units'))}
              </Text>
            </div>
          </Line>
        )}
        {props.isWeight && (
          <Line>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <StyledInput
                ref={weightRef}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                placeholder={locale('profil_edit.no_firstname')}
              />
              <Text style={{ width: 20, textAlign: 'center' }}>
                {locale('global.kg.' + localStorage.getItem('units'))}
              </Text>
            </div>
          </Line>
        )}
      </Row>
    </Flex>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default WeightHeightCaracteristics;
