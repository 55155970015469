import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import CustomTooltip from '../../../components/miscellaneous/custom-tooltip.component';
import PictureModeration from '../../../components/picture-moderation.component';
import Icons from '../../../components/style/icons.component';

import ModalSlice from '../../../store/slices/modal.slice';
import { useLogin } from '../../../utils/auth.utils';

import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';

import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import NewLabel from '../../../components/user/NewLabel';
import Icon from '../../../components/user/PremiumSoonIcon';
import useLocale from '../../../utils/locale/locale.hook';
import { getStatusColor } from '../../../utils/status.utils';
import { MenusShowed } from '../types/menus-showed.interface';
import { Mode } from '../types/mode.enum';
import { getDistance } from '../../../utils/unities.utils';

interface ProfilePicturesProps {
  isScreen: boolean;
  user: any;
  openConversationHandler: (conversationId: number) => void;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  timerData: {
    hasTimer: boolean;
    timeLeft: number;
  };
  cancelVisitHandler: () => void;
  menusShowed: MenusShowed;
  setMenusShowed: React.Dispatch<React.SetStateAction<MenusShowed>>;
  scaleMainPicture?: number;
  isAnonymous?: boolean;
}

export default function ProfilePictures(props: ProfilePicturesProps) {
  const { user, isScreen, timerData, cancelVisitHandler, scaleMainPicture, isAnonymous } = props;
  const { me } = useLogin();
  const dispatch = useDispatch();
  const locale = useLocale();
  const carousselRef = useRef(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [pictureSelected, setPictureSelected] = useState(null);
  const [isRightEnd, setIsRightEnd] = useState(false);
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [needCaroussel, setNeedCaroussel] = useState(false);

  const openFullSizeImages = (pictureSelected: any) => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: true,
        images: user.picturesVisible,
        start: user.picturesVisible.findIndex((picture: any) => picture === pictureSelected),
        type: 'pictures',
        isLikeable: true,
      })
    );
  };

  const onScrollRight = () => {
    //width to scroll
    setIsLeftEnd(false);
    let left = carousselRef.current.scrollLeft + carousselRef.current.getBoundingClientRect().width - 74;

    if (left >= carousselRef.current.scrollWidth - carousselRef.current.getBoundingClientRect().width - 74) {
      left = carousselRef.current.scrollWidth;
      setIsRightEnd(true);
    } else {
      setIsRightEnd(false);
    }
    carousselRef.current.scroll({
      top: 0,
      left: left,
      behavior: 'smooth',
    });
  };

  const onScrollLeft = () => {
    setIsRightEnd(false);
    //width to scroll
    let left = carousselRef.current.scrollLeft - carousselRef.current.getBoundingClientRect().width - 74;

    if (left < 50) {
      left = 0;
      setIsLeftEnd(true);
    } else {
      setIsLeftEnd(false);
    }

    carousselRef.current.scroll({
      top: 0,
      left: left,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setPictureSelected(user.picturesVisible[0]);
  }, [user]);

  useEffect(() => {
    if (pictureSelected === null) {
      setPictureSelected(user.picturesVisible[0]);
    }
  }, [pictureSelected, user.picturesVisible]);

  useEffect(() => {
    if (user.picturesVisible?.length) {
      setNeedCaroussel(
        user.picturesVisible.length >= Math.round((carousselRef.current?.getBoundingClientRect().width || 0) / 74)
      );
    }
    setIsRightEnd(false);
    setIsLeftEnd(true);
  }, [user.picturesVisible]);

  const [maskedVisit, setMaskedVisit] = useState(false);

  return (
    <MainWrapper isScreen={!!isScreen}>
      {user && user.picturesVisible?.length > 0 && (
        <>
          <MainPicture
            onClick={() => {
              openFullSizeImages(user.picturesVisible[0]);
            }}
            scaleMainPicture={scaleMainPicture}
          >
            <PictureModeration
              alt=""
              moderation={pictureSelected?.moderation}
              src={pictureSelected?.path}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', }}
            />

            <PremiumVisitCancellerText hasCarousel={user.picturesVisible.length > 1 ? true : false}>
                  {maskedVisit ? locale('global.visit_hidden') : locale('radar.user-profile.hide-visit')}
            </PremiumVisitCancellerText>

            {!!me.role && timerData.hasTimer && isDesktop && (
              <div style={{ zIndex: 10000 }}>
                <PremiumVisitCanceller
                  onClick={(e) => { 
                    e.preventDefault(); 
                    e.stopPropagation(); 
                    setMaskedVisit(true);
                    cancelVisitHandler();
                  }}
                  hasSeveralPictures={user.picturesVisible.length > 1}
                  hasCarousel={user.picturesVisible.length > 1 ? true : false}
                >
                  <p className="timer">{timerData.timeLeft}</p>
                  <StyledCircularProgress
                    variant="determinate"
                    value={100}
                    style={{
                      width: 40,
                      height: 40,
                      color: '#ffffff',
                      position: 'absolute',
                      top: '4px',
                      left: '4px',
                    }}
                  />
                  <StyledCircularProgress
                    variant="determinate"
                    style={{
                      width: 40,
                      height: 40,
                      color: '#23c06c',
                      position: 'relative',
                    }}
                    value={Math.round((100 * timerData.timeLeft) / 30)}
                  />
                </PremiumVisitCanceller>
              </div>
            )}
          </MainPicture>

          {isDesktop && (
            <UserInfo
              hasCarousel={user.picturesVisible.length > 1 ? true : false}
              isAgeVisible={user.ageVisibility === 'public'}
            >
              <PremiumSoonIconsContainer>
                {props.user.createdAt && (Date.now() - Date.parse(props.user.createdAt)) / 3600000 <= 24 && (
                  <NewLabel />
                )}
                {user.role === 'premium' && (
                  <CustomTooltip
                    arrow={true}
                    placement="bottom-end"
                    title={
                      <div style={{ maxWidth: 210, fontSize: 10, margin: 8 }}>{locale('premium.description')}</div>
                    }
                  >
                    <>
                      <Icon icon="premium" width={22} height={22} />
                    </>
                  </CustomTooltip>
                )}
                {props.user?.travels[0] && <Icon icon="soon" width={22} height={22} />}
              </PremiumSoonIconsContainer>
              <Flex alignItems="center" style={{ width: 'fit-content' }}>
                <Text fontSize="53px" bold>
                  {user.ageVisibility === 'public' && isDesktop && <div className="age"> {user.age} </div>}
                </Text>

                <PseudoDistance>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                  >
                    {!isAnonymous ? props.user.pseudo : ''}
                  </div>
                  <div style={{ display: 'flex', gap: 2, alignItems: 'center', marginTop: 6 }}>
                    <StatusCircle
                      color={getStatusColor(props.user.status).centerCircle}
                      style={{ borderColor: getStatusColor(props.user.status).circle }}
                    />
                    {props.user.status === 'online' ? (
                      <Text semiBold style={{ fontSize: 10 }}>
                        {locale('account.status.online')}
                      </Text>
                    ) : props.user.status === 'recently_online' ? (
                      <Text semiBold style={{ fontSize: 10 }}>
                        {locale('profile.status.connected.today')}
                      </Text>
                    ) : null}

                    {props.user?.distance !== '-1.0' && props.user?.distance !== -1 && (
                      <p
                        className="distance"
                        style={{
                          margin: props.user.status === 'recently_online' ? 0 : 'unset',
                          fontSize: 10,
                        }}
                      >
                        {`${locale('nearby.at-distance')} ${getDistance(props.user.distance)}`}
                      </p>
                    )}
                  </div>
                </PseudoDistance>
              </Flex>
            </UserInfo>
          )}
          {user.picturesVisible.length > 1 && isDesktop && (
            <Carousel>
              <PicturesSelector>
                {!isLeftEnd && (
                  <CarouselArrow side="left" onClick={() => onScrollLeft()}>
                    <Icons name="arrow-left-black" width="14" height="14" />
                  </CarouselArrow>
                )}
                <PicturesWrapper picturesLength={user.picturesVisible.length} ref={carousselRef}>
                  {user.picturesVisible.map((picture: any, index: number) => (
                    <PictureSelect onClick={() => openFullSizeImages(picture)} key={index}>
                      <PictureModeration
                        moderation={picture.moderation}
                        src={picture.path}
                        width="100%"
                        height="100%"
                        style={{ objectFit: 'cover' }}
                        noText={true}
                      />
                    </PictureSelect>
                  ))}
                </PicturesWrapper>

                {!isRightEnd && needCaroussel && (
                  <CarouselArrow side="right" onClick={() => onScrollRight()}>
                    <Icons name="arrow-right-black" width="14" height="14" />
                  </CarouselArrow>
                )}
              </PicturesSelector>
            </Carousel>
          )}
        </>
      )}
    </MainWrapper>
  );
}

const MainWrapper = styled.div<{ isScreen: boolean }>`
  position: relative;
  flex: 1;
  ${(props) =>
    !props.isScreen &&
    `
        height: 100%;
        overflow: hidden;
    `}

  @media (max-width: 1000px) {
    width: 100vw;
    overflow: initial;
    max-height: none;
    min-height: 500px;
    position: fixed;
  }
`;

const MainPicture = styled.div<{ scaleMainPicture: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 16px 0 0 16px;
  overflow: hidden;

  @media (max-width: 1000px) {
    border-radius: 0;
    min-height: 500px;
    scale: 1.1;
    transition: scale cubic-bezier(0.075, 0.82, 0.165, 1) 200ms;
    ${({ scaleMainPicture }) => scaleMainPicture && `scale: ${scaleMainPicture <= 1 ? 1 : scaleMainPicture};`}
  }
`;

const Carousel = styled.div`
  background-color: #000000;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  border-radius: 0 0 0 16px;

  @media (max-width: 1000px) {
    border-radius: 0;
  }
`;

const PicturesSelector = styled.div`
  position: relative;
`;

const PicturesWrapper = styled.div<{ picturesLength: number }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(${({ picturesLength }) => picturesLength || '1'}, 80px);
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 1000px) {
    height: 93px;
    grid-template-columns: repeat(${({ picturesLength }) => picturesLength || '1'}, 93px);
  }
`;

const CarouselArrow = styled.div<{ side: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 56px;
  height: 56px;
  background-color: ${colors.white};
  cursor: pointer;
  z-index: 2;
  top: calc(50% - 28px);
  margin-right: 14px;
  border-radius: 50%;

  ${(p) =>
    p.side === 'right' &&
    `
      right: 0px;
    `}
  ${(p) =>
    p.side === 'left' &&
    `
      left: 0px;
    `}

@media (max-width: 1000px) {
    ${({ side }) => (side === 'right' ? 'margin-right: 12px' : 'margin-left: 12px')};
  }
`;

const PictureSelect = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  opacity: 0.95;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    width: 93px;
    height: 93px;
  }
`;

const PremiumSoonIconsContainer = styled.div`
  display: flex;
  gap: 6px;

  @media (max-width: 1000px) {
    top: 11px;
    right: unset;
    left: 7px;
    display: flex;
    align-items: center;
  }
`;

const PremiumVisitCancellerText = styled.div<{ hasCarousel: boolean }>`
  position: absolute;
  bottom: ${({ hasCarousel }) => `${hasCarousel ? 90 : 10}px`};
  right: 10px;
`;

const PremiumVisitCanceller = styled.div<{ hasSeveralPictures: boolean; hasCarousel: boolean }>`
  position: absolute;
  right: 10px;
  bottom: ${({ hasCarousel }) => `${hasCarousel ? 110 : 30}px`};
  cursor: pointer;
  padding: 4px;
  background-color: #1fe0;
  height: 40px;
  border-radius: 50%;

  display: grid;
  place-items: center;
  text-align: center;

  & > .timer {
    position: absolute;
    margin: 0;
    color: #f1f1f1;
    font-size: 16px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    top: unset;
    left: unset;
    bottom: ${({ hasSeveralPictures }) => `${hasSeveralPictures ? 107 : 24}px`};
    right: 16px;
    z-index: 1003;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-colorPrimary {
      color: ${colors.backgroundDark};
    }
  }
`;

const UserInfo = styled.div<{ hasCarousel: boolean; isAgeVisible: boolean }>`
  width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: ${({ hasCarousel }) => `${hasCarousel ? 80 : 10}px`};
  padding-left: 16px;
  padding-bottom: ${({ isAgeVisible }) => `${!isAgeVisible ? 16 : 0}px`};
  ${({ hasCarousel }) => !hasCarousel && 'border-radius: 0 0 0 16px'};

  & > p.age {
    margin: 0;
    font-size: 54px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.white};
  }

  @media (max-width: 1000px) {
    background: none;
    bottom: ${({ hasCarousel }) => `${hasCarousel ? 110 : 24}px`};
  }
`;

const PseudoDistance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 6px;

  & > p.distance {
    margin: 0;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 16px;
    color: #f1f1f1;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colors.white};
  }
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  margin-right: 6px;
`;
