import { useEffect, useState } from "react";
import styled from "styled-components/macro";

import Icons from "../style/icons.component";
import Text from "../style/text.component";

type CheckboxProps = {
  style?: any;
  text?: string;
  value?: boolean;
  displayCase?: boolean;
  onChange?: (value: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = () => {
    props?.onChange(!value);
    setValue(!value);
  };

  return (
    <Container onClick={() => onChange()} style={props?.style}>
      <Case displayCase={props.displayCase}>
        {value && <Icons name="check-outlined-white" width="15" height="15" />}
      </Case>
      <Text style={{ fontSize: props?.style?.fontSize || 16 }}>
        {props?.text}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Case = styled.div<{ displayCase: boolean }>`
  width: 18px;
  height: 18px;
  border: solid 1px white;
  border-radius: 2px;
  cursor: pointer;
  display: ${({ displayCase }) => (displayCase === false ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

export default Checkbox;
