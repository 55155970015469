import { useState, useEffect } from 'react';

import Error from '../../../../components/forms/error.component';
import Text from '../../../../components/style/text.component';
import { Values } from '../../../../components/settings/values.component';
import { Subtitle } from '../../../../components/settings/subtitle.component';

import useLocale from '../../../../utils/locale/locale.hook';
import { updateUser } from '../../../../services/users.service';
import { useMediaQuery } from 'react-responsive';
import { Flex } from '../../../../components/style/flex.component';
import { useDebounce } from 'use-debounce';
import { StyledInput } from '../../../../components/forms/input.component';

type Props = {
  type: 'city' | 'country' | 'video1' | 'video2';
  me?: any;
  onChange?: () => void;
};

const Attribute = (props: Props) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [editOpen, setEditOpen] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [attribute, setAttribute] = useState(props?.me?.[props.type]);
  const [videoTitle, setVideoTitle] = useState(props?.me?.[`videoTitle${props.type.split('video')[1]}`]);
  const [videoLink, setVideoLink] = useState(props?.me?.[`videoLink${props.type.split('video')[1]}`]);
  const [debouncedTitle] = useDebounce(videoTitle, 500);
  const [debouncedVideoLink] = useDebounce(videoLink, 500);

  const mapTypeToTitle = {
    city: {
      titleKeyPhrase: 'guide.form.city.label',
      noAttributeKeyPhrase: 'profil_edit.no_city',
    },
    country: {
      titleKeyPhrase: 'global.country',
      noAttributeKeyPhrase: 'profil_edit.no_country',
    },
    video1: {
      titleKeyPhrase: 'profil_edit.youtube_video',
      noAttributeKeyPhrase: 'profil_edit.no_youtube_video',
    },
    video2: {
      titleKeyPhrase: 'profil_edit.youtube_video',
      noAttributeKeyPhrase: 'profil_edit.no_youtube_video',
    },
  };

  const handleChangeVideoTitle = (e) => {
    if (e.target.value.length <= 50) {
      setVideoTitle(e.target.value);
    }
  };

  useEffect(() => {
    setVideoTitle(props?.me?.[`videoTitle${props.type.split('video')[1]}`]);
    setVideoLink(props?.me?.[`videoLink${props.type.split('video')[1]}`]);
  }, [props?.me?.[props.type]]);

  useEffect(() => {
    updateUser({
      [`videoTitle${props.type.split('video')[1]}`]: debouncedTitle,
    });
  }, [debouncedTitle]);

  useEffect(() => {
    updateUser({
      [`videoLink${props.type.split('video')[1]}`]: debouncedVideoLink,
    });
  }, [debouncedVideoLink]);

  <Text style={!isDesktop ? { marginTop: 8 } : null}></Text>;
  return (
    <Flex direction="column">
      <Subtitle>{`${locale(mapTypeToTitle[props.type].titleKeyPhrase)}${props.type === 'video1' ? ' 1' : ''}${
        props.type === 'video2' ? ' 2' : ''
      }`}</Subtitle>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '8px',
        }}
      >
        <Flex direction="column" gap="16px" style={{ flex: 1 }}>
          <StyledInput
            value={videoTitle}
            onChange={handleChangeVideoTitle}
            placeholder={locale('live.start.title.label')}
          />
          <StyledInput
            onChange={(e) => setVideoLink(e.target.value)}
            value={videoLink}
            placeholder={locale('videos.link')}
          />
        </Flex>
      </Values>
      {errorMsg && <Error errorMsg={errorMsg} style={{ zoom: 0.8, marginTop: 10 }} />}
    </Flex>
  );
};

export default Attribute;
