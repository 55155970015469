import { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';

import RadioBox from '../../../../components/forms/radiobox.input';
import Text from '../../../../components/style/text.component';
import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';

import useLocale from '../../../../utils/locale/locale.hook';
import { useNotification } from '../../../../utils/useNotification';

interface Props {}
const Units = (props: Props) => {
  const [units, setUnits] = useState(localStorage.getItem('units'));
  const locale = useLocale();
  const { triggerNotification } = useNotification();

  return (
    <>
      <Container>
        <Subtitle>{locale('account.account_gestion.units_title')}</Subtitle>
        <Form>
          <RadioBox
            value={units === 'metric'}
            onChange={() => {
              setUnits('metric');
              localStorage.setItem('units', 'metric');
              triggerNotification({
                title: locale('settings.modification_success'),
              });
            }}
            text={locale('account.account_gestion.units_metric')}
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              marginRight: '44px',
            }}
          />
          <RadioBox
            value={units === 'imperial'}
            onChange={() => {
              setUnits('imperial');
              localStorage.setItem('units', 'imperial');
              triggerNotification({
                title: locale('settings.modification_success'),
              });
            }}
            text={locale('account.account_gestion.units_imperial')}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          />
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Subtitle = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
`;

export default Units;
