import { useRef, useState, useContext, useEffect } from 'react';
import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import colors from '../../../themes/colors-v2.theme';
import { UserModalTemplate } from '../../user-profile/components/user-modal.component';
import styled from 'styled-components';
import useLocale from '../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { useMediaQuery } from 'react-responsive';
import { environment } from '../../../environments';
import { generateBillingParams } from '../../../services/subscription.service';
import { useDispatch } from 'react-redux';
import ModalSlice from '../../../store/slices/modal.slice';
import { BillingParamsContext } from '../../../context/billing-params-context';
import { BillingAddress } from '../types/billing-address.type';
import { BillingParams } from '../types/billing-params.type';
import { DurationPricingColumn } from '../types/duration-pricing-column.enums';
import { Country, ICountry, City, ICity } from 'country-state-city';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MoneticoBillingAddressModal = ({ isOpen, onClose }: Props) => {
  const locale = useLocale();
  const cityRef = useRef(null);
  const countryRef = useRef(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [postalCode, setPostalCode] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const dispatch = useDispatch();
  const context = useContext(BillingParamsContext);
  const requiredStateOrProvinceCountries = ["US", "CA"]

  const [selectedCountry, setSelectedCountry] = useState<ICountry>(null);
  const [selectedCity, setSelectedCity] = useState<ICity>(null);
  const [countryAutocomplete, setCountryAutocomplete] = useState([]);
  const [cityAutocomplete, setCityAutocomplete] = useState([]);
  const [inputCountry, setInputCountry] = useState('');
  const [inputCity, setInputCity] = useState('')
  

  if (!context) {
    throw new Error("MoneticoBillingAddressModal must be used within a BillingParamsProvider");
  };
  let { billingParams } = context;

  const handleSearchCountry = (newInput: string): void => {
    if (inputCountry.length > newInput.length) {
      setSelectedCountry(null);
    }
    setInputCountry(newInput);
    searchCountry(newInput);
  }

  const searchCountry = (input: string): void => {
    let filteredList = [];
    if (input !== ''){
      filteredList = Country.getAllCountries().filter(country => country.name.toLowerCase().startsWith(input.toLowerCase()));
    }
    setCountryAutocomplete(filteredList);
  }

  const handleSearchCity = (newInput: string): void => {
    if (inputCity.length > newInput.length) {
      setSelectedCity(null);
    }
    setInputCity(newInput);
    searchCity(newInput);
  }
  
  const searchCity = (input: string): void=> {
    let filteredList = [];
    if (input !== '' && selectedCountry){
      filteredList = City.getCitiesOfCountry(selectedCountry.isoCode || '').filter(city => city.name.toLowerCase().startsWith(input.toLowerCase()));
    }
    else if (input !== '') {
      filteredList = City.getAllCities().filter(city => city.name.toLowerCase().startsWith(input.toLowerCase()));
    }
    setCityAutocomplete(filteredList);
  }

  const updateAddressInBillingParams = (billingParams: BillingParams, billingAddress: BillingAddress) => {
    billingParams.address = billingAddress;
    return billingParams
  }

  const getBillingAddress = (): BillingAddress => {
    const countryCode = selectedCountry.isoCode;
    const billingAddress: BillingAddress = {
      addressLine1: addressLine,
      city: selectedCity.name,
      country: countryCode,
      postalCode: postalCode,
    }

    // https://www.monetico-paiement.fr/fr/info/documentations/Monetico_Paiement_documentation_technique_v2.0.pdf
    // According to the Monetico documentation, the 'stateOrProvince' field
    // should only be provided for countries that require it (federal states).
    // Warning : If you provide the 'stateOrProvince' field for a non required country, 
    // an exception occur
    
    if (requiredStateOrProvinceCountries.includes(countryCode)) {
      const stateOrProvince = `${countryCode}-${selectedCity.stateCode}`;
      billingAddress.stateOrProvince = stateOrProvince;
    }

    return billingAddress;
  }

  const handleCountrySelection = (country: ICountry) => {
    setSelectedCountry(country)
    setCountryAutocomplete([])
    if (countryRef.current) {
      countryRef.current.value = country.name;
    }
  }

  const handleCitySelection = (city: ICity) => {
    setSelectedCity(city)
    setCityAutocomplete([])
    if (countryRef.current) {
      cityRef.current.value = city.name;
    }
  }

  const [moneticoParams, setMoneticoParams] = useState<
    Array<{ name: string; value: string }>
  >([]);

  const handleClose = () => {
    dispatch(ModalSlice.actions.setMoneticoBillingAddressVisible(false));
    onClose();
  };

  const backToComponent = () => {
    dispatch(ModalSlice.actions.setMoneticoBillingAddressVisible(false))
    if (billingParams.duration === DurationPricingColumn.BOOST) {
      dispatch(ModalSlice.actions.setBoostModalVisible(true));
    }
    if ([DurationPricingColumn.FIVE_KM, DurationPricingColumn.TEN_KM, DurationPricingColumn.TWENTY_KM].includes(billingParams.duration)) {
      dispatch(ModalSlice.actions.setShoutoutModalVisible(true));
    }
  }

  const handleSubmitForm = async () => {
    const billingAddress = getBillingAddress();
    billingParams = updateAddressInBillingParams(billingParams, billingAddress)
    await generateBillingParams(billingParams).then((res) => {
      if (res?.success) {
        setMoneticoParams(res.params);
        const form = document.getElementById("moneticoParams") as HTMLFormElement | null;
        try {
          form.submit();
        } catch (error) {
          console.error(error)
        }
      }
    });
  }

  return (
    <UserModalTemplate
      isOpen={isOpen}
      onClose={handleClose}
      style={{
        borderRadius: 8,
        width: isDesktop ? '700px' : '90%',
        height: isDesktop ? 'fit-content' : '95%',
        background: colors.darkGray,
        position: 'fixed',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 32,
        zIndex: 1050,
        overflowY: 'auto',
      }}
    >
      <Flex direction="column">
        <Flex alignItems="center" gap="13px">
          <Flex direction="column" gap="5px">
            <Text fontSize="20px" bold>
              {locale('messages.billing.title')}
            </Text>
            <Text color={colors.darkText}>{locale('messages.billing.explanation')}</Text>
          </Flex>
        </Flex>
        <Flex direction="column" gap="6px" style={{ marginTop: 8 }}>
          <SearchInputContainer>
            <LineTextInput
              placeholder={locale('messages.billing.address')}
              value={addressLine}
              onChange={(e) => setAddressLine(e.target.value)}
            />
            </SearchInputContainer>
            <SearchInputContainer>
              <LineTextInput
                placeholder={locale('messages.billing.country')}
                onChange={(e) => handleSearchCountry(e.target.value)}
                ref={countryRef}
              />
              {countryAutocomplete.length > 0 && (
                <Autocomplete>
                  {countryAutocomplete.slice(0, 5).map((country, index) => (
                    <Line key={`${country.name}-${index}`} onClick={() => handleCountrySelection(country)}>
                      {country.name}
                    </Line>
                  ))}
                </Autocomplete>
              )}
            </SearchInputContainer>
            <SearchInputContainer>
              <LineTextInput
                placeholder={locale('messages.billing.city')}
                onChange={(e) => handleSearchCity(e.target.value)}
                ref={cityRef}
              />
              {cityAutocomplete.length > 0 && (
                <Autocomplete>
                  {cityAutocomplete.slice(0, 6).map((city, index) => (
                    <Line key={`${city.name}-${index}`} onClick={() => handleCitySelection(city)}>
                      {`${city.name}, ${Country.getCountryByCode(city.countryCode).name}`}
                    </Line>
                  ))}
                </Autocomplete>
              )}
            </SearchInputContainer>
            <SearchInputContainer>
              <LineTextInput
                placeholder={locale('messages.billing.postal-code')}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </SearchInputContainer>
            <PaymentButtons>
              <FirstButton>
                <PrimaryButton
                  isActive={false}
                  size="large"
                  onClick={backToComponent}
                  style={{
                    backgroundColor: 'lightgray',
                    color: 'black'
                  }}
                >
                  {locale('messages.billing.back')}
                </PrimaryButton>
              </FirstButton>
              <SecondButton>
                <div>
                  <form
                    method="post"
                    action={`${environment.monetico.baseUrl}/paiement.cgi`}
                    id="moneticoParams"
                  >
                    {moneticoParams.map((param) => (
                      <input
                        key={param.name}
                        name={param.name}
                        value={param.value}
                        type="hidden"
                      />
                    ))}
                  </form>
                </div>
              <PrimaryButton
                  isActive={true}
                  size="large"
                  onClick={handleSubmitForm}
                  value="submit"
                  type="submit"
                  disabled={addressLine === "" || selectedCity === null || selectedCountry === null || postalCode === ""}
                >
                  {locale('messages.billing.next')}
                </PrimaryButton>
              </SecondButton>
            </PaymentButtons>
          <Flex alignItems="center" justify="space-between">
            <Flex direction="column" style={{ flex: 1 }}>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_1')}
              </PaymentRow>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_2')}
              </PaymentRow>
              <PaymentRow>
                <img src="/assets/icons/check-outlined-white.svg" width={16} height={16} />
                {locale('messages.shoutout.payment_3')}
              </PaymentRow>
            </Flex>
            <PaymentIconsGrid>
              <img src="/assets/icons/messages/mastercard.svg" width={32} height={32} />
              <img src="/assets/icons/messages/visa.svg" width={32} height={32} />
              <img src="/assets/icons/messages/american-express.svg" width={32} height={32} />
              <img src="/assets/icons/messages/CB.svg" width={32} height={32} />
            </PaymentIconsGrid>
          </Flex>
        </Flex>
      </Flex>
    </UserModalTemplate>
  );
};

const PaymentRow = styled.div`
  gap: 4px;
  border: none;
  border-radius: 8px;
  height: 24px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  padding: 0 12px;
  @media (max-width: 1000px) {
    height: 100%;
  }
`;

const PaymentIconsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;


const SearchInputContainer = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 248px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid ${colors.grayBorder};
  color: white;
`;

const LineTextInput = styled.input`
  padding-left: 28px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  height: 37.5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const Autocomplete = styled.div`
  display: flex;
  z-index: 600;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};

  @media (max-width: 1000px) {
    top: 60px;
    width: 93%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;

const PaymentButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  gap: 10px
`;

const FirstButton = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  display: grid
`;

const SecondButton = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  width: 100%;
  display: grid;
`;