import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Guide from './guide-card.component';

import { getGuides } from '../../../services/guide.service';
import { useLogin } from '../../../utils/auth.utils';
import MapExplore from '../../../components/explore/map-explore.component';
import colors from '../../../themes/colors-v2.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { useMediaQuery } from 'react-responsive';
import { TitleOptions } from '../../nearby/components/filters/title-option.component';
import MobileFilterOverlay from '../../../components/mobile/mobile-filter-overlay.component';
import MobileFilterButton from '../../../components/mobile/mobile-filter-button.component';

export enum GuideType {
  EVENTS = 'EVENTS',
  BARS = 'BARS',
  RESTAURANTS = 'RESTAURANTS',
  SHOPPING = 'SHOPPING',
  ASSOCIATIONS = 'ASSOCIATIONS',
  ACCOMMODATIONS = 'ACCOMMODATIONS',
  ART_CULTURE = 'ART_CULTURE',
  ACTIVITIES = 'ACTIVITIES',
}

export enum DisplayMode {
  ALL = 'ALL',
  UPCOMING = 'UPCOMING',
  MY_GUIDES = 'MY_GUIDES',
  MY_ACTIVITIES = 'MY_ACTIVITIES',
}

export enum SortOption {
  LOCATION = 'location',
  DATE = 'date',
}

export enum ActivityDisplay {
  GOING,
  INTERESTED,
  CREATED,
  NOT_INTERESTED,
  ALL,
}

const mapTypeToHref = {
  [GuideType.EVENTS]: 'guide/events',
  [GuideType.BARS]: 'guide/bars',
  [GuideType.RESTAURANTS]: 'guide/restaurants',
  [GuideType.SHOPPING]: 'guide/shopping',
  [GuideType.ASSOCIATIONS]: 'guide/associations',
  [GuideType.ACCOMMODATIONS]: 'guide/accommodations',
  [GuideType.ART_CULTURE]: 'guide/art-culture',
  [GuideType.ACTIVITIES]: 'guide/activities',
};

const mapTypeToApiType = {
  [GuideType.EVENTS]: 'event',
  [GuideType.BARS]: 'bar_night_club',
  [GuideType.RESTAURANTS]: 'restaurant',
  [GuideType.SHOPPING]: 'shop',
  [GuideType.ASSOCIATIONS]: 'association',
  [GuideType.ACCOMMODATIONS]: 'accommodation',
  [GuideType.ART_CULTURE]: 'art_culture',
  [GuideType.ACTIVITIES]: 'all',
};

interface Props {
  openSubmitEventModal: () => void;
  setShowSubmitModal: Dispatch<SetStateAction<boolean>>;
  coords: { lat: number; lng: number };
  setCoords: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
  mapOpen: boolean;
  setMapOpen: Dispatch<SetStateAction<boolean>>;
  weekOnly: boolean;
  setWeekOnly: Dispatch<SetStateAction<boolean>>;
  type: GuideType;
  setType: Dispatch<SetStateAction<GuideType>>;
  activityDisplay: ActivityDisplay;
  setActivityDisplay: Dispatch<SetStateAction<ActivityDisplay>>;
  setThisWeek: Dispatch<SetStateAction<boolean>>;
  isSearchOpen: boolean;
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
  setIsGuidesCategoriesOpen: Dispatch<SetStateAction<boolean>>;
  activeCategory: string;
}

export default function DisplayedGuides(props: Props) {
  const {
    type,
    setType,
    activityDisplay,
    weekOnly,
    isSearchOpen,
    setIsSearchOpen,
    setIsGuidesCategoriesOpen,
    activeCategory,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const { me, isLogin } = useLogin();
  const [mode, setMode] = useState<DisplayMode>(DisplayMode.ALL);
  const [sort, setSort] = useState<SortOption>(null);
  const [displayedGuides, setDisplayedGuides] = useState([]);
  const [isLocationOpen, setIsLocationOpen] = useState<boolean>(false);
  const { coords, setCoords, mapOpen, setMapOpen, setThisWeek } = props;
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const hasDuration = type === GuideType.EVENTS || type === GuideType.ACCOMMODATIONS || type === GuideType.ART_CULTURE;

  /**
   * Fonction permettant de mettre à jour le status de l'utilisateur s'il vient de changer sa participation.
   */
  const updateGuideParticipationStatus = (guideId: string, newStatus: string) => {
    let copiedGuides = JSON.parse(JSON.stringify(displayedGuides));
    const guideModified = copiedGuides.find((guide: any) => guide.id === guideId);
    if (newStatus === 'leaving') {
      if (mode === DisplayMode.UPCOMING) {
        copiedGuides = copiedGuides.filter((guide: any) => guide.id !== guideId);
      } else {
        guideModified.participants = guideModified.participants.filter(
          (participant: any) => participant.user?.id !== me.id
        );
      }
    } else {
      const participant = guideModified.participants.find((participant: any) => participant.user?.id === me.id);
      if (participant) {
        participant.status = newStatus;
      } else {
        guideModified.participants.push({ status: newStatus, user: me });
      }
    }
    setDisplayedGuides(copiedGuides);
  };

  /**
   * Fonction permettant de récupérer les guides à afficher, en fonction du type,
   * du mode d'affichage & du tri à effectuer.
   */
  const getGuidesHandler = () => {
    if (!!type) {
      if (type === GuideType.ACTIVITIES) {
        getMyActivities();
      } else {
        const hasDuration =
          type === GuideType.EVENTS || type === GuideType.ACCOMMODATIONS || type === GuideType.ART_CULTURE;
        if (isLogin) {
          if (coords.lat !== 0 || coords.lng !== 0) {
            getGuides({
              type: mapTypeToApiType[type],
              mode: activityDisplay === ActivityDisplay.CREATED ? 'my_guides' : mode.toLowerCase(),
              sort: SortOption.LOCATION,
              latitude: coords.lat,
              longitude: coords.lng,
              state:
                activityDisplay === ActivityDisplay.GOING
                  ? 'going'
                  : activityDisplay === ActivityDisplay.INTERESTED
                  ? 'interested'
                  : activityDisplay === ActivityDisplay.NOT_INTERESTED
                  ? 'not_interested'
                  : activityDisplay === ActivityDisplay.CREATED
                  ? null
                  : activityDisplay === ActivityDisplay.ALL
                  ? null
                  : null,
            }).then((guides) => {
              if (Array.isArray(guides)) {
                if (weekOnly && hasDuration) {
                  guides = guides.filter((x) => {
                    const date = new Date();
                    date.setDate(date.getDate() + 7);
                    return x.start_datetime <= date.toISOString();
                  });
                }
                setDisplayedGuides(guides);
              }
            });
          } else {
            getGuides({
              type: mapTypeToApiType[type],
              mode: activityDisplay === ActivityDisplay.CREATED ? 'my_guides' : mode.toLowerCase(),
              sort: SortOption.LOCATION,
              latitude: me.latitude,
              longitude: me.longitude,
              state:
                activityDisplay === ActivityDisplay.GOING
                  ? 'going'
                  : activityDisplay === ActivityDisplay.INTERESTED
                  ? 'interested'
                  : activityDisplay === ActivityDisplay.NOT_INTERESTED
                  ? 'not_interested'
                  : activityDisplay === ActivityDisplay.CREATED
                  ? null
                  : activityDisplay === ActivityDisplay.ALL
                  ? null
                  : null,
            }).then((guides) => {
              if (Array.isArray(guides)) {
                if (weekOnly && hasDuration) {
                  guides = guides.filter((x) => {
                    const date = new Date();
                    date.setDate(date.getDate() + 7);
                    return x.start_datetime <= date.toISOString();
                  });
                }
                setDisplayedGuides(guides);
              }
            });
          }
        } else {
          getGuides({
            type: mapTypeToApiType[type],
            mode: activityDisplay === ActivityDisplay.CREATED ? 'my_guides' : mode.toLowerCase(),
            sort: sort?.toLowerCase(),
            state:
              activityDisplay === ActivityDisplay.GOING
                ? 'going'
                : activityDisplay === ActivityDisplay.INTERESTED
                ? 'interested'
                : activityDisplay === ActivityDisplay.NOT_INTERESTED
                ? 'not_interested'
                : activityDisplay === ActivityDisplay.CREATED
                ? null
                : activityDisplay === ActivityDisplay.ALL
                ? null
                : null,
          }).then((guides) => {
            if (Array.isArray(guides)) {
              if (weekOnly && hasDuration) {
                guides = guides.filter((x) => {
                  const date = new Date();
                  date.setDate(date.getDate() + 7);
                  return x.start_datetime <= date.toISOString();
                });
              }
              setDisplayedGuides(guides);
            }
          });
        }
      }
    }
  };

  const getMyActivities = () => {
    if (isLogin) {
      switch (activityDisplay) {
        case ActivityDisplay.CREATED:
          getGuides({
            type: 'all',
            mode: 'my_guides',
            sort: sort?.toLocaleLowerCase(),
          }).then((guides) => {
            setDisplayedGuides(guides);
          });
          break;
        case ActivityDisplay.GOING:
          getGuides({
            type: 'all',
            mode: 'upcoming',
            sort: sort?.toLocaleLowerCase(),
            state: 'going',
          }).then((guides) => {
            setDisplayedGuides(guides);
          });
          break;
        case ActivityDisplay.INTERESTED:
          getGuides({
            type: 'all',
            mode: 'upcoming',
            sort: sort?.toLocaleLowerCase(),
            state: 'interested',
          }).then((guides) => {
            setDisplayedGuides(guides);
          });
          break;
        case ActivityDisplay.NOT_INTERESTED:
          getGuides({
            type: 'all',
            mode: 'upcoming',
            sort: sort?.toLocaleLowerCase(),
            state: 'not_interested',
          }).then((guides) => {
            setDisplayedGuides(guides);
          });
      }
    }
  };

  // Hook permettant de sélectionner le bon mode
  useEffect(() => {
    let currentType = null;
    for (const type in mapTypeToHref) {
      if (location.pathname === `/discover/${mapTypeToHref[type]}`) {
        currentType = type;
        break;
      }
    }
    if (currentType) {
      setType(currentType);
    } else {
      // history.push('/guide/events');
    }
  }, [location]); // eslint-disable-line

  useEffect(() => {
    if (!!type) {
      // dans le cadre où est déjà sur le mode "all", alors on récupère depuis ce hook les guides à afficher
      if (mode === DisplayMode.ALL) {
        getGuidesHandler();
      }
      setMode(DisplayMode.ALL);
      if (
        activityDisplay === ActivityDisplay.INTERESTED ||
        activityDisplay === ActivityDisplay.NOT_INTERESTED ||
        activityDisplay === ActivityDisplay.GOING
      ) {
        setMode(DisplayMode.UPCOMING);
      }
      setSort(null);
    }
  }, [type, activityDisplay]); // eslint-disable-line

  useEffect(() => {
    // si le mode change, on récupère depuis ce hook les guides à afficher
    getGuidesHandler();
  }, [mode]);

  useEffect(() => {
    getGuidesHandler();
  }, [sort]);

  useEffect(() => {
    getGuidesHandler();
  }, [coords]);

  useEffect(() => {
    getGuidesHandler();
  }, [weekOnly]);

  useEffect(() => {
    getGuidesHandler();
  }, [activityDisplay]);

  React.useEffect(() => {
    if (me) setCoords({ lat: me.latitude, lng: me.longitude });
    getGuidesHandler();
  }, [me]);

  return (
    <Wrapper>
      {isSearchOpen && !isDesktop && (
        <MobileFilterOverlay setIsOpen={setIsSearchOpen}>
          <TitleOptions
            style={{
              fontSize: 18,
              fontWeight: 600,
              margin: '0 0 0 12px',
              color: colors.black,
            }}
          >
            {locale('nearby.search')}
          </TitleOptions>
          <MobileSection>
            <Row>
              <FilterButton active={!weekOnly} onClick={() => setThisWeek(false)}>
                {locale('guide.filters.all')}
              </FilterButton>
              <FilterButton active={weekOnly} onClick={() => setThisWeek(true)}>
                {locale('guide.filters.this_week')}
              </FilterButton>
            </Row>
            <MobileFilterButton
              title={`${locale('nearby.location')} : `}
              setIsOpen={() => setIsLocationOpen(false)}
              value="Fonctionnalité bientôt disponible"
            />
            <MobileFilterButton
              title={`${locale('guide.form.type.label')} : `}
              setIsOpen={setIsGuidesCategoriesOpen}
              value={activeCategory || locale('guide.filters.choose-category')}
            />
          </MobileSection>
        </MobileFilterOverlay>
      )}
      {mapOpen && (
        <MapExplore
          style={{
            marginLeft: '-24px',
            top: 125,
            width: 'calc(100% - 250px)',
            height: 'calc(100vh - 150px)',
          }}
          styleMobile={{
            marginLeft: '-24px',
          }}
          onClose={() => setMapOpen(false)}
        />
      )}
      {hasDuration && isDesktop && (
        <Row>
          <FilterButton active={!weekOnly} onClick={() => setThisWeek(false)}>
            {locale('guide.filters.all')}
          </FilterButton>
          <FilterButton active={weekOnly} onClick={() => setThisWeek(true)}>
            {locale('guide.filters.this_week')}
          </FilterButton>
        </Row>
      )}
      <GuidesWrapper type={mapTypeToApiType[type]}>
        {displayedGuides.map((guide) => (
          <Link
            to={{
              pathname: `/discover/guide/${guide.slug}/details/`,
              state: { guideId: guide.id },
            }}
            key={guide.id}
          >
            <Guide guide={guide} />
          </Link>
        ))}
      </GuidesWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Row = styled.div`
  min-width: 50vw;
  height: 30px;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid ${colors.grayBorder};
  gap: 8px;
  padding: 4px 4px 0 0;
  margin: 0 0 16px 0px;

  @media (max-width: 1000px) {
    margin: 0 0 10px;
    height: 44px;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : colors.darkGrey1)};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
`;

const GuidesWrapper = styled.div<{ type: string }>`
  display: grid;
  ${(p) => p.type === 'all' && 'margin-top: 49px;'}
  gap: 14px 9px;
  background-color: transparent;
  grid-template-columns: repeat(auto-fill, minmax(386px, 1fr));
  width: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;
    box-sizing: border-box;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 16px;
    box-sizing: border-box;
  }
`;

const MobileSection = styled.div`
  background-color: ${colors.white};
  width: 100%;
  padding: 16px 12px;
  box-sizing: border-box;
  margin-top: 16px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
`;
