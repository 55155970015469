import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import TextMessage from './message-type/text.component';
import QuickshareMessage from './message-type/quickshare.component';
import EphemeralMessage from './message-type/ephemeral.component';
import LocationMessage from './message-type/location.component';
import WoofMessage from './message-type/woof.component';

import { useLogin } from '../../../../utils/auth.utils';

import colors from '../../../../themes/colors-v2.theme';
import ModalSlice from '../../../../store/slices/modal.slice';
import { useDispatch } from 'react-redux';
import PostMessage from './message-type/post.component';

interface MessageProps {
  message: any;
  sender: any;
  conversation: any;
  setConversationMessages: React.Dispatch<React.SetStateAction<any[]>>;
  showQuickshareForm?: boolean;
}

type ExistingMessageType = 'text' | 'quickshare' | 'ephemeral' | 'location' | 'woof' | 'video' | 'post';

const mapMessageTypeToComponent: Record<ExistingMessageType, any> = {
  text: TextMessage,
  quickshare: QuickshareMessage,
  ephemeral: EphemeralMessage,
  location: LocationMessage,
  woof: WoofMessage,
  video: TextMessage,
  post: PostMessage,
};

export const ReadReceiptIndicator = ({ isReadByOther } : {
  isReadByOther: boolean;
}) => {
  const style: CSSProperties = {
    position: 'relative',
    top: 2,
    width: 16,
    height: 16,
    marginLeft: 6,
  };
  if (isReadByOther) {
    return <img alt="" style={style} src="/assets/icons/messages/icons-read.svg" />;
  } else {
    return <img alt="" style={style} src="/assets/icons/messages/icons-sent.svg" />;
  }
};

export default function ChatMessage({
  message,
  sender,
  conversation,
  setConversationMessages,
  showQuickshareForm,
}: MessageProps) {
  const { me } = useLogin();
  const dispatch = useDispatch();
  const senderType = sender.id === me.id ? 'me' : 'other';

  const RenderMessage = mapMessageTypeToComponent[message.type] as React.FC<MessageProps>;
  const shouldHideMessage = message.type === 'quickshare' && message.quickshare?.status === 'refused';
  const shouldHideTimestamp = message.type === 'quickshare';

  const shouldHideBackgroundColorMessage =
    (message.type === 'text' || 'ephemeral') && message.pictures.length > 0 && message.content === '';

  const shouldTimeStampBeHover =
    ((message.type === 'text' || 'ephemeral') && message.pictures.length > 0) || message.type === 'woof';

  return (
    <>
      {!shouldHideMessage && (
        <MessageWrapper>
          {/*!shouldHideOtherSender && senderType === "other" && (
            <div className="otherSender">
              <img alt="" src={sender.profilePicture?.path} />
            </div>
          )*/}
          <MessageLine sender={senderType}>
            {!!RenderMessage && (
              <MessageDiv
                sender={senderType}
                isAnonymous={message.isAnonymous}
                showBackgroundColor={!shouldHideBackgroundColorMessage}
                onClick={() => {
                  if (message.isAnonymous) {
                    dispatch(ModalSlice.actions.setPremium({ visible: true }));
                  }
                }}
                style={{ overflow: 'hidden' }}
              >
                <RenderMessage
                  message={message}
                  sender={senderType}
                  conversation={conversation}
                  setConversationMessages={setConversationMessages}
                />
                {!shouldHideTimestamp && (
                  <MessageBottom timeStampHover={shouldTimeStampBeHover}>
                    <MessageTimestamp sender={senderType}>{moment(message.createdAt).format('LT')}</MessageTimestamp>
                    {me?.role === 'premium' && senderType === 'me' ? (
                      <ReadReceiptIndicator
                        isReadByOther={conversation.isReadByOther}
                      />
                    ) : null}
                  </MessageBottom>
                )}
              </MessageDiv>
            )}
          </MessageLine>
        </MessageWrapper>
      )}
    </>
  );
}

const MessageWrapper = styled.div`
  margin: 4px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  & > .otherSender {
    margin-right: 12px;
    margin-top: 1px;

    & > img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

const MessageTimestamp = styled.div<{ sender: 'me' | 'other' }>`
  margin-top: 4px;
  font-size: 10px;
  color: ${({ sender }) => (sender === 'me' ? colors.lightGrey4 : colors.darkGrey)};
  width: fit-content;
`;

const MessageLine = styled.div<{ sender: 'me' | 'other' }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${(p) => (p.sender === 'me' ? 'flex-end' : 'flex-start')};
`;

const MessageDiv = styled.div<{
  sender: 'me' | 'other';
  showBackgroundColor: boolean;
  isAnonymous?: boolean;
}>`
  position: relative;
  width: fit-content;
  min-width: 80px;
  width: 290px;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  background-color: ${(p) =>
    p.showBackgroundColor === false ? null : p.sender === 'me' ? '#106BF280' : colors.darkGray};

  & p {
    color: ${colors.lightGrey8};
  }

  ${(p) => p.isAnonymous && 'cursor: pointer;'}
  ${(p) => p.isAnonymous && 'filter: blur(3px);'}
  ${(p) => p.isAnonymous && '-webkit-filter: blur(3px);'}

  border-radius: 8px;
  ${(p) => (p.sender === 'me' ? 'right: 12px;' : 'left: 12px;')}
`;

const MessageBottom = styled.div<{ timeStampHover: boolean }>`
  ${(p) =>
    p.timeStampHover === false
      ? `display: flex;
    flex-direction: row;
    margin: 0px 8px 8px auto;
    align-items: center;
    justify-content: flex-end;`
      : `position: absolute;
     margin: 8px;
     right: 0px;
     bottom: 0px;
     display: flex;`}
`;
