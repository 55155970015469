import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Text from '../../../../components/style/text.component';

import { useLogin } from '../../../../utils/auth.utils';
import ModalSlice from '../../../../store/slices/modal.slice';
import useLocale from '../../../../utils/locale/locale.hook';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import { getViewerCount } from '../../../../services/live.service';

interface CardProps {
  live: any;
}

export default function LiveStreamCard({ live }: CardProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { isLogin, me } = useLogin();
  const [viewerCount, setViewerCount] = useState('-');
  const viewerCountInterval = useRef(null);

  useEffect(() => {
    clearInterval(viewerCountInterval.current);
    viewerCountInterval.current = setInterval(() => {
      getViewerCount(live.stream_name).then((data) => {
        setViewerCount(data.success ? data.viewerCount : '-');
      });
    }, 2000);
    return () => {
      clearInterval(viewerCountInterval.current);
    };
  }, [live.stream_name]);

  return (
    <Link
      to={`/live/${live.user.pseudo}`}
      onClick={(e) => {
        if (!isLogin) {
          e.stopPropagation();
          e.preventDefault();
          dispatch(ModalSlice.actions.setSigninVisible(true));
        } else if (me.role === 'user') {
          e.stopPropagation();
          e.preventDefault();
          dispatch(ModalSlice.actions.setPremium({ visible: true }));
        }
      }}
    >
      <CardWrapper>
        <CardThumbnail>
          {/* !! TODO : récupérer une image par défaut pour le thumbnail */}
          <Thumbnail
            alt=""
            src={
              live?.thumbnail_path ||
              'https://d2tiq3wo24jtl5.cloudfront.net/pictures/afdfe26f-3059-4bd6-a8ed-a99a7234c294.jpeg'
            }
          />
          <BadgesContainer>
            <LiveBadge>
              <Text style={{ fontWeight: 800 }}>{locale('live.badge')}</Text>
            </LiveBadge>
            <ViewersBadge>
              <img src="/assets/icons/sidebar/viewers-white.svg" height={12} width={12} alt="Viewers icon" />
              <Text color={colors.white}>{viewerCount}</Text>
            </ViewersBadge>
          </BadgesContainer>
        </CardThumbnail>
        <CardContent>
          <div className="profilePicture">
            <img
              alt=""
              className="picture"
              src={
                live?.user.profilePicture?.path
                  ? live?.user.profilePicture?.path
                  : '/assets/imgs/no-profile-picture.svg'
              }
              width={29}
              height={29}
            />
          </div>
          <CardInfo>
            <Text className="pseudo">{live?.user.pseudo}</Text>
            <Text className="category">
              {live?.categoryKey
                ? locale(live?.categoryKey)
                : live?.categories.length > 0
                ? locale('key_' + live.categories.find((cat) => cat).category)
                : ''}
            </Text>
          </CardInfo>
        </CardContent>
      </CardWrapper>
    </Link>
  );
}

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 195px;
`;

const AspectRatioContainer = styled.div`
  width: 50%; /* Set the initial width */
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 61.73%; /* (242 / 392) * 100% = 61.73% */
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 195px;
  object-fit: cover;
  border-radius: 4px;
`;

const BadgesContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 8px;
  left: 0;
  padding: 0 8px;
  box-sizing: border-box;
`;

const LiveBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  background: #d92d20;
  padding: 2px 8px 2px 8px;
`;

const ViewersBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${colors.lightGrey9};
  padding: 2px 8px;
  border-radius: 25px;
  color: ${colors.white};
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;

  & > .profilePicture {
    display: flex;
    align-items: center;
    margin-right: 8px;

    & > .picture {
      border-radius: 4px;
      object-fit: cover;
    }
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;

  & > .pseudo {
    font-weight: ${fonts.bold.weight};
    font-size: 14px;
    color: ${colors.white};
  }
  & > .category {
    font-weight: ${fonts.semiBold.weight};
    font-size: 12px;
    color: ${colors.lightGrey9};
  }
`;
