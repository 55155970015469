import React from 'react';
import styled from 'styled-components/macro';
import Text from '../../../components/style/text.component';
import fonts from '../../../themes/fonts.theme';
import { getDurationDate } from '../../../utils/date.utils';
import useLocale from '../../../utils/locale/locale.hook';
import colors from '../../../themes/colors.theme';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { setReadNotifications } from '../../../services/notifications.service';
import PictureModeration from '../../../components/picture-moderation.component';

type NotifProps = {
  notif: any;
  onClose?: () => void;
};

const Notif = (props: NotifProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const openNotif = () => {
    setReadNotifications([props.notif]);
    if (props.onClose) props.onClose();
  };

  return (
    <Container
      to={props.notif.link}
      onClick={(e) => {
        e.stopPropagation();
        openNotif();
      }}
    >
      <PictureModeration
        src={props.notif.path}
        moderation={props.notif.moderation}
        width="73"
        height="73"
        style={{
          minWidth: 73,
          borderRadius: 4,
          objectFit: 'cover',
        }}
      />
      <TextContainer>
        <NotificationText isDesktop={isDesktop}>
          {props.notif.type === 'likePostNotif' && (
            <>
              <b>{props.notif.originUserPseudo + ' '}</b>
              {+props.notif.numberOfOtherLikesOnPost > 0 && (
                <>
                  {locale('global.and') + ' '}
                  <b>
                    {+props.notif.numberOfOtherLikesOnPost + ' '}
                    {+props.notif.numberOfOtherLikesOnPost > 1
                      ? locale('global.person.other')
                      : locale('global.person.one')}
                  </b>
                </>
              )}
              {locale('notif.post.like')}
            </>
          )}
          {props.notif.type === 'commentPostNotif' && (
            <>
              <b>{props.notif.originUserPseudo + ' '}</b>
              {+props.notif.numberOfOtherCommentsOnPost > 0 && (
                <>
                  {locale('global.and') + ' '}
                  <b>
                    {+props.notif.numberOfOtherCommentsOnPost + ' '}
                    {+props.notif.numberOfOtherCommentsOnPost > 1
                      ? locale('global.person.other')
                      : locale('global.person.one')}{' '}
                  </b>
                </>
              )}
              {locale('notif.post.comment')}
            </>
          )}
          {props.notif.type === 'guideAcceptationNotif' && (
            <span>
              {locale('notif.guide.review', {
                status: locale(`notif.guide.${props.notif.guideStatus}`),
              })}
            </span>
          )}
          {props.notif.type === 'postSanctionNotif' && <>{locale('notif.post.sanction')}</>}
        </NotificationText>
        <TimestampText isDesktop={isDesktop} read={Boolean(props.notif.read)}>
          {getDurationDate(props.notif.timestamp)}
        </TimestampText>
      </TextContainer>
    </Container>
  );
};

export default Notif;

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  width: calc(100% - 16px);
  position: relative;
  :hover {
    background-color: ${colors.darkGreyHover};
  }
`;

const TextContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotificationText = styled(Text)<{ isDesktop: boolean }>`
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  line-height: 18px;
  max-height: 36px;

  ${(props) =>
    props.isDesktop &&
    `
        max-height: 78px;
        font-size: 17px;
        line-height: 25px;
    `}

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const TimestampText = styled(Text)<{ isDesktop: boolean; read: boolean }>`
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;

  ${(props) =>
    props.isDesktop &&
    `
        font-size: 13px;
        line-height: 18px;
        opacity: 0.8;
    `}

  ${(props) =>
    !props.read &&
    `
        color: ${colors.primary};
        opacity: 1;
    `}
`;
