import styled from 'styled-components/macro';

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 1000px) {
    gap: 2px;
  }
`;
