import { useState, useEffect } from 'react'

export const useScript = (url, name) => {

  const [lib, setLib] = useState({} as any)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => setLib({ [name]: window[name] })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return lib

}