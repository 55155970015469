import React from "react";
import styled from "styled-components/macro";
import Button from "../../../components/forms/button.component";
import Text from "../../../components/style/text.component";
import useLocale from "../../../utils/locale/locale.hook";
import { useDispatch } from "react-redux";
import ModalSlice from "../../../store/slices/modal.slice";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const UserNotVisible = () => {
  const locale = useLocale();
  const dispatch = useDispatch();

  const showSignin = () => {
    dispatch(ModalSlice.actions.setSigninVisible(true));
  };

  return (
    <Container>
      <Text h2 style={{ width: "70%", textAlign: "center" }}>
        {locale("user_profile.not_visible")}
      </Text>
      <Button
        style={{ marginTop: 20 }}
        text={locale("global.login")}
        outlined
        onClick={showSignin}
      />
    </Container>
  );
};

export default UserNotVisible;
