import React from "react";
import styled from "styled-components/macro";
import Button from "../../../components/forms/button.component";

import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import useLocale from "../../../utils/locale/locale.hook";

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  errorMessage: string;
}

export default function VideoCallErrorModal({
  visible,
  closeModal,
  errorMessage,
}: ModalProps) {
  const locale = useLocale();
  return (
    <ModalTemplate visible={visible} closeModal={closeModal} width="500px">
      <ModalBody>
        <MarginWrapper>
          <Title>{locale("video_call.errors.cannot_call")}</Title>
        </MarginWrapper>
        <MarginWrapper>
          <ErrorDescription>{errorMessage}</ErrorDescription>
        </MarginWrapper>
        <ButtonWrapper>
          <Button text={locale("global.close")} onClick={closeModal} />
        </ButtonWrapper>
      </ModalBody>
    </ModalTemplate>
  );
}

const ModalBody = styled.div`
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
`;

const MarginWrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.p`
  width: 100%;
  font-size: 18px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  margin: 0;
  text-align: center;
`;

const ErrorDescription = styled.p`
  margin: 0;
  font-size: 13px;
  color: ${colors.darkGrey};
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 40%;
  margin: auto;

  & > * {
    width: 100%;
  }
`;
