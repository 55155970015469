import { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import ModalSlice from '../../../../../store/slices/modal.slice';
import useLocale from '../../../../../utils/locale/locale.hook';
import { getRandomAnonymousText } from '../../../../../utils/chat.utils';

import fonts from '../../../../../themes/fonts.theme';
import Gradient from '../gradient';
import Image from '../../../../../components/image.component';
import colors from '../../../../../themes/colors-v2.theme';
import { VideoCustom } from '../video.component';
import { useLogin } from '../../../../../utils/auth.utils';
import { PrimaryButton } from '../../../../../components/forms/primaryButton.component';

interface TextMessageProps {
  message: any;
  sender: 'other' | 'me';
  setConversationMessages: Dispatch<SetStateAction<any[]>>;
}

const MorePictures = (props: { size: number; id: number }) => {
  if (props.id !== 3 || props.size <= 4) {
    return null;
  }
  const value = props.size - 3;

  return <MoreValue>{`+${value}`}</MoreValue>;
};

export default function TextMessage({ message, sender, setConversationMessages }: TextMessageProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();

  const [hasDisabledBlur, setHasDisabledBlur] = useState(!me.hasBlockedNudes);

  const handleDeletePicture = (message: any, picture: any) => {
    setConversationMessages((prevMessages: any) => {
      const newMessages: any = JSON.parse(JSON.stringify(prevMessages));
      const foundMessage = newMessages.find((_message: any) => _message.id === message.id);
      const foundPicture = foundMessage.pictures.find((_picture: any) => _picture.id === picture.id);
      foundPicture.deleted = true;
      delete foundPicture.path;
      return newMessages;
    });
  };

  const anonymousText: string | null = useMemo(() => {
    if (!message.isAnonymous) {
      return null;
    }
    return getRandomAnonymousText();
  }, [message.isAnonymous]);

  return (
    <>
      {message.isAnonymous ? (
        <Message sender={sender}>{anonymousText}</Message>
      ) : (
        !!message.content?.trim() && (
          <Message sender={sender}>
            <p>{message.isPhraseKey ? locale(message.content) : message.content}</p>
          </Message>
        )
      )}
      {!message.pictures.length ? null : message.isAnonymous ? (
        <MessagePictures sender={sender}>
          {message.pictures.slice(0, 4).map((picture: any) => {
            return (
              <Fragment key={picture.id}>
                {picture.deleted ? (
                  <DeletedPicture isAlone={message.pictures.length === 1}>
                    {locale('chat.messages.message_deleted')}
                  </DeletedPicture>
                ) : !picture.isVideo ? (
                  <Picture isAlone={message.pictures.length === 1}>
                    <Image
                      alt=""
                      src={picture.path}
                      width={message.pictures.length === 1 ? '290px' : '138px'}
                      height={message.pictures.length === 1 ? '280px' : '138px'}
                      style={{
                        borderRadius: '8px',
                        objectFit: 'cover',
                      }}
                      optiWidth={message.pictures.length === 1 ? 300 : 200}
                    />
                    <Gradient />
                    <MorePictures
                      id={message.pictures.findIndex((_picture: any) => _picture.id === picture.id)}
                      size={message.pictures.length}
                    />
                  </Picture>
                ) : (
                  <VideoCustom
                    src={picture.path}
                    style={{ maxHeight: 150, maxWidth: 150, borderRadius: 8 }}
                    title={picture.title}
                  />
                )}
              </Fragment>
            );
          })}
        </MessagePictures>
      ) : (
        <MessagePictures sender={sender}>
          {message.pictures.slice(0, 4).map((picture: any) => (
            <Fragment key={picture.id}>
              {picture.deleted ? (
                <DeletedPicture isAlone={message.pictures.length === 1}>
                  {locale('chat.messages.message_deleted')}
                </DeletedPicture>
              ) : !picture.isVideo ? (
                <Picture
                  onClick={() => {
                    if (!hasDisabledBlur) return setHasDisabledBlur(true);
                    const picturesAvailable = message.pictures.filter((_picture: any) => !_picture.deleted);
                    const indexSelected = picturesAvailable.findIndex((_picture: any) => _picture.id === picture.id);
                    dispatch(
                      ModalSlice.actions.setFullSizeImageVisibleVisible({
                        visible: true,
                        images: picturesAvailable,
                        start: indexSelected,
                        type: 'pictures',
                      })
                    );
                  }}
                  isAlone={message.pictures.length === 1}
                >
                  <Image
                    alt=""
                    src={picture.path}
                    width={message.pictures.length === 1 ? '280px' : '138px'}
                    height={message.pictures.length === 1 ? '280px' : '138px'}
                    style={{
                      borderRadius: '8px',
                      objectFit: 'cover',
                      filter: picture.isNude && !hasDisabledBlur ? 'blur(10px)' : '',
                    }}
                    optiWidth={message.pictures.length === 1 ? 300 : 200}
                  />

                  {picture.isNude && !hasDisabledBlur ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                      <PrimaryButton style={{ height: 'fit-content' }}>Click to Open</PrimaryButton>
                    </div>
                  ) : null}
                  <MorePictures
                    id={message.pictures.findIndex((_picture: any) => _picture.id === picture.id)}
                    size={message.pictures.length}
                  />
                </Picture>
              ) : (
                // <VideoWrapper isAlone={message.pictures.length === 1}>
                //   <video
                //     src={picture.path}
                //     controls
                //     style={{
                //       width: message.pictures.length === 1 ? '100%' : '138px',
                //       height: message.pictures.length === 1 ? '100%' : '138px',
                //       borderRadius: 8,
                //     }}
                //     title={picture.title}
                //   />
                // </VideoWrapper>
                <VideoCustom
                  src={picture.path}
                  style={{
                    width: message.pictures.length === 1 ? '100%' : '138px',
                    height: message.pictures.length === 1 ? '100%' : '138px',
                    borderRadius: 8,
                  }}
                  title={picture.title}
                />
              )}
            </Fragment>
          ))}
        </MessagePictures>
      )}
    </>
  );
}

const Message = styled.div<{ sender: 'me' | 'other'; isAnonymous?: boolean }>`
  padding: 12px 12px 8px;
  border-radius: 8px;

  font-size: 14px;
  color: ${(props) => (props.sender === 'me' ? '#d8dadc' : '#fff')};

  & > p {
    margin: 0;
    overflow-wrap: break-word;
    font-size: 14px;
  }
`;

const MessagePictures = styled.div<{ sender: 'me' | 'other' }>`
  margin: ${(p) => (p.sender === 'me' ? '0 0 0 auto' : '0 auto 0 0')};
  max-width: 400px;

  display: flex;
  flex-wrap: wrap;
  justify-content: 'flex-start';
  & > * {
    margin: 1px;
  }
`;

const Picture = styled.div<{ isAlone: boolean }>`
  display: flex;
  position: relative;
  width: ${(p) => (p.isAlone ? '290px' : '138px')};
  height: ${(p) => (p.isAlone ? '280px' : '138px')};
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const VideoWrapper = styled.div<{ isAlone: boolean }>`
  display: flex;
  position: relative;
  width: ${(p) => (p.isAlone ? '100%' : '138px')};
  height: ${(p) => (p.isAlone ? '100%' : '138px')};
  cursor: pointer;
`;

const DeletedPicture = styled.div<{ isAlone: boolean }>`
  user-select: none;
  width: ${(p) => (p.isAlone ? '280px' : '138px')};
  height: ${(p) => (p.isAlone ? '280px' : '138px')};
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.black};
  font-size: 14px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MoreValue = styled.p`
  margin: 0;
  left: 24px;
  bottom: 29px;
  font-size: 64px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: #f1f1f1;
  position: absolute;
`;
