import React from 'react';
import styled from 'styled-components/macro';
import useLocale from '../../../../utils/locale/locale.hook';
import { getSexualRole } from '../../../../services/caracteristics.service';
import Text from '../../../../components/style/text.component';
import { TitleOptions } from './title-option.component';
import { Flex } from '../../../../components/style/flex.component';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';

const sexualRoleIconMapping = {
  top: 'arrow-up',
  bottom: 'arrow-down',
  versatile: 'arrows-switch',
  oral_only: 'circle',
  jack_off_only: 'circles-two',
  top_versatile: 'arrow-up-right',
  bottom_versatile: 'arrow-down-left',
  not_said: 'cross',
};

type SexualRoleFilterProps = {
  onChange: (sexualRole) => void;
  sexualRole: string[];
  style?: any;
  filters?: any;
  isDisabled?: boolean;
};

const SexualRoleFilter = (props: SexualRoleFilterProps) => {
  const [sexualRole, setSexualRole] = React.useState(props.sexualRole || []);
  const [sexualRoleList, setSexualRoleList] = React.useState([]);
  const locale = useLocale();

  const onSelect = (practice) => {
    const sexualRoleCopy = [...sexualRole];
    const index = sexualRoleCopy.findIndex((_practice) => _practice === practice);
    if (index !== -1) {
      sexualRoleCopy.splice(index, 1);
    } else {
      sexualRoleCopy.push(practice);
    }
    setSexualRole(sexualRoleCopy);
    props.onChange({
      sexualRole: sexualRoleCopy,
    });
  };

  React.useEffect(() => {
    const getData = async () => {
      const data = await getSexualRole();
      setSexualRoleList(data);
    };
    getData();
  }, []);

  React.useEffect(() => {
    setSexualRole(props.sexualRole || []);
  }, [props.sexualRole]);

  return (
    <Wrapper>
      <Flex style={{ cursor: 'pointer', gap: 8, marginBottom: 16 }} alignItems="center">
        <img src="/assets/icons/nearby/checkbox-blue.svg" alt="checkbox" />
        <TitleOptions>{locale('radar.filter.position')}</TitleOptions>
      </Flex>
      <Container style={props.style}>
        <ButtonGrid>
          {sexualRoleList.map((sr) => {
            return (
              <PrimaryButton
                key={sr.sexualRoleKey}
                style={{ height: 64 }}
                onClick={() => !props.isDisabled && onSelect(sr.sexualRoleKey)}
                isActive={sexualRole.includes(sr.sexualRoleKey) && !props.isDisabled}
              >
                {sr.sexualRoleKey === 'not_said' ? (
                  <img src="/assets/icons/cross-outlined-white.svg" />
                ) : (
                  <img src={`assets/icons/nearby/${sexualRoleIconMapping[sr.sexualRoleKey]}.svg`} />
                )}

                <Text>{locale(sr.phraseKey).replace('/', ' / ')}</Text>
              </PrimaryButton>
            );
          })}
        </ButtonGrid>
      </Container>
    </Wrapper>
  );
};

export default SexualRoleFilter;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

const Wrapper = styled.div`
  margin-top: 18px;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
