/**
 * Fonction permettant d'obtenir les informations (nom, image, ...) d'une conversation.
 * @param me L'utilisateur connecté
 * @param conversation La conversation dont on souhaite obtenir les infos
 */
export const getConversationInfo = (me: any, conversation: any) => {
  if (!conversation || !me) {
    return {};
  }

  const conversationMembers = conversation.conversation_users.filter(
    (member: any) => member.user.id !== me.id
  );
  const firstConversationUser = conversationMembers[0]?.user;
  // const secondConversationUser = conversationMembers[1]?.user;

  const conversationImageUrl =
    conversation.type === "private"
      ? firstConversationUser?.profilePicture?.path?.replace(
          "pictures",
          "resized-pictures/w_100"
        )
      : "/TODO_GROUP_IMAGE.png";
  const conversationName =
    conversation.type === "private" &&
    (conversation.name === "N/A" || conversation.name === "")
      ? firstConversationUser?.pseudo
      : conversation.name;
  const conversationStatus =
    conversation.type === "private" ? firstConversationUser?.status : null;
  return {
    conversationImageUrl,
    conversationName,
    conversationStatus,
    firstConversationUser,
  };
};

/**
 * Generates a random unique text when a message is anonymous.
 */
export const getRandomAnonymousText = () => {
  const blurredLines = [
    "That text is blurred, get premium!",
    "Sadly, you won't read anything, get premium",
    "If you were premium, you would have seen that text",
    "Maecenas vel nulla aliquet, commodo tortor ac",
    "Duis orci est,",
    "Duis diam nisi, faucibus vel lacus in, consequat auctor leo. Aliquam hendrerit nulla sit amet nunc consequat dignissim.",
    "Proin eu enim vel urna tincidunt vestibulum in sit amet turpis. ",
  ];
  const index = Math.floor(Math.random() * blurredLines.length);
  return blurredLines[index];
};
