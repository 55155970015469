import React, { SetStateAction, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { FancyScrollbar } from "../../../components/style/fancy-scrollbar.style";
import { Mode } from "../types/mode.enum";
import fonts from "../../../themes/fonts.theme";
import UserCard from "../../../components/miscellaneous/user.component";
import {
  getUserFavorites,
  getUserFavoredBy,
} from "../../../services/favorite.service";
import useLocale from "../../../utils/locale/locale.hook";
import colors from "../../../themes/colors-v2.theme";

interface ComponentProps {
  user: any;
  mode: Mode;
  setMode: React.Dispatch<SetStateAction<Mode>>;
}

const FavoritesDisplay = (props: ComponentProps) => {
  const [users, setUsers] = useState([]);
  const locale = useLocale();

  useEffect(() => {
    const getData = async () => {
      if (props.mode === Mode.FAVORITES) {
        const result = await getUserFavorites(props.user.id, 30, 0);
        setUsers(result);
      } else {
        const result = await getUserFavoredBy(props.user.id, 30, 0);
        setUsers(result);
      }
    };
    getData();
  }, []);

  return (
    <Content>
      <Header>
        <img
          alt=""
          onClick={() => props.setMode(Mode.INFORMATIONS)}
          src="/assets/icons/user-profile/icons-navigation-angle-left.svg"
        />
        <p>
          {props.mode === Mode.FAVORITES
            ? locale("user_profile.favorites")
            : locale("user_profile.favored_by")}
        </p>
      </Header>
      <Grid>
        {users.length > 0 &&
          users.map((user) => (
            <BoxWrapper>
              <Box>
                <UserCard user={user} blurred={user.blurred} smallPremium />
              </Box>
            </BoxWrapper>
          ))}
      </Grid>
    </Content>
  );
};

const Content = styled.div`
  overflow-y: auto;
  ${FancyScrollbar}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  margin-top: 16px;
  align-items: center;

  & > img {
    width: 8px;
    height: 12px;
    cursor: pointer;
    margin-right: 16px;
  }

  & > p {
    margin: 0;
    font-size: 12px;
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    color: ${colors.darkGrey};
    text-transform: uppercase;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 3px;
  grid-template-columns: 98px 98px 98px;
  padding: 15px 12px 0;

  ${FancyScrollbar}
`;

const BoxWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const Box = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
`;

export default FavoritesDisplay;
