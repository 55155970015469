import React, { useState } from "react";
import styled from "styled-components/macro";
import Text from "../../../components/style/text.component";
import useLocale from "../../../utils/locale/locale.hook";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
`;

const RatingItem = styled("div")<{ clickable: boolean }>`
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const RatingBar = (props) => {
  const locale = useLocale();
  return (
    <Container>
      {[
        "nonsexual",
        "someskin",
        "softcore",
        "hardcore",
        "illegal",
        "unAuthorized",
      ].map((el, index) => {
        return (
          <RatingItem
            clickable={props.clickable || false}
            onClick={() => {
              if (props.clickable) {
                props.ratePicture(
                  [
                    "nonSexual",
                    "someSkin",
                    "softcore",
                    "hardcore",
                    "illegal",
                    "unAuthorized",
                  ][index]
                );
              }
            }}
          >
            <img
              alt=""
              src={`/assets/icons/moderation/Logo-${index + 1}.png`}
            />
            <Text
              style={{
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {locale("global." + el)}
            </Text>
          </RatingItem>
        );
      })}
    </Container>
  );
};

export default RatingBar;
