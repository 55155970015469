import { createSlice } from "@reduxjs/toolkit";

const FavoriteSlice = createSlice({
  name: "favorite",
  initialState: {
    // messagesNotSeen: {},
    // scrollToBottom: false,
    overlayVisible: false,
  },
  reducers: {
    // setMessagesNotSeen: (state, { payload }) => {
    //   return {
    //     ...state,
    //     messagesNotSeen: payload,
    //   };
    // },
    // setScrollToBottom: (state, { payload }) => {
    //   return {
    //     ...state,
    //     scrollToBottom: payload,
    //   };
    // },
    setOverlayVisibility: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        overlayVisible: payload,
      };
    },
  },
});

export default FavoriteSlice;
