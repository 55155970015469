import { useEffect, useState } from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import styled from 'styled-components';
import colors from '../../../../themes/colors-v2.theme';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import Text from '../../../../components/style/text.component';
import { Flex } from '../../../../components/style/flex.component';

type Props = {
  onChange: (data) => void;
  dataSelected: string[];
  getData: () => Promise<any>;
  keyName: string;
  phraseKey: string;
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
};

export const QuickFiltersSidebar = (props: Props) => {
  const [dataSelected, setDataSelected] = useState(props.dataSelected || []);
  const [data, setData] = useState([]);
  const locale = useLocale();

  const onSelect = (item) => {
    let dataSelectedCopy = [...dataSelected];
    const index = dataSelectedCopy.findIndex((_item) => _item === item);
    if (props.isDisabled) {
      dataSelectedCopy = [item];
    } else if (index !== -1) {
      dataSelectedCopy.splice(index, 1);
    } else {
      dataSelectedCopy.push(item);
    }
    setDataSelected(dataSelectedCopy);
    props.onChange(dataSelectedCopy);
  };

  const getData = async () => {
    const _data = await props.getData();
    setData(_data);
  };

  const handleReset = () => {
    setDataSelected([]);
    props.onChange([]);
  };

  useEffect(() => {
    setDataSelected(props.dataSelected);
  }, [props.dataSelected]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {props.isOpen && (
        <Container>
          <Header isDesktop={true}>
            <Flex alignItems="center" gap="8px">
              <CloseButtonWrapper
                onClick={() => {
                  props.onClose();
                }}
              >
                <img alt="" src="/assets/icons/cross-outlined-white.svg" width={24} height={24} />
              </CloseButtonWrapper>
              <Text bold fontSize="16px">
                {locale('radar.quickfilter.sidebar.lookfor')}
              </Text>
            </Flex>

            {dataSelected.length > 0 && (
              <div onClick={handleReset} style={{ color: colors.darkBlue, fontWeight: 600, cursor: 'pointer' }}>
                {locale('radar.quickfilter.sidebar.reset')}
              </div>
            )}
          </Header>

          <Content>
            {data.map((dataItem) => {
              return (
                <PrimaryButton
                  size="large"
                  key={dataItem[props.keyName]}
                  onClick={() => onSelect(dataItem[props.keyName])}
                  isActive={dataSelected.includes(dataItem[props.keyName]) && !props.isDisabled}
                  style={{ margin: 4 }}
                >
                  {locale(props.phraseKey + '.name.' + dataItem[props.keyName])}
                </PrimaryButton>
              );
            })}
          </Content>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  width: 362px;
  height: 100vh;
  z-index: 1000;
  background-color: ${colors.dark};
  right: 0;
  top: 0;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.darkGrey2};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 362px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    background: #20252b;
  }
`;

const CloseButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
`;

const Content = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
`;

const Header = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 20px;
  ${(props) =>
    !props.isDesktop &&
    `
        justify-content: space-between;
    `};
`;
