import styled from 'styled-components/macro';
import colors from '../../themes/colors.theme';
import fonts from '../../themes/fonts.theme';

interface TextProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  dark?: boolean;
  primary?: boolean;
  bodyBold?: boolean;
  bodySemiBold?: boolean;
  body?: boolean;
  semiBold?: boolean;
  bold?: boolean;
  color?: string;
  fontSize?: string;
  center?: boolean;
}

const Text = styled.p<TextProps>`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : '14px')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin: 0px;

  ${(props) =>
    props.color
      ? `
        color: ${props.color};
    `
      : 'color: white;'}

  ${(props) =>
    props.semiBold &&
    `
        font-weight: 500;
    `}

  ${(props) =>
    props.bold &&
    `
        font-weight: 600;
    `}

    ${(props) =>
    props.primary &&
    `
        color: ${colors.primary};
    `}

    ${(props) =>
    props.dark &&
    `
        color: ${colors.dark};
    `}

    ${(props) =>
    props.h1 &&
    `
        font-family: "Inter";
        font-weight: 600;
        font-size: 3rem;
        line-height: 70px;
    `}

    ${(props) =>
    props.h2 &&
    `
        font-family: "Inter";
        font-size: 24px;
        color: ${colors.dark};
        letter-spacing: -0.408px;
        font-weight: 600;
    `}

    ${(props) =>
    props.h3 &&
    `
        font-family: "Inter";
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 35px;
        letter-spacing: -0.408px;
    `}
    

    ${(props) =>
    props.h4 &&
    `
        font-family: "Inter";
        font-size: 0.9rem;
        letter-spacing: -0.408px;
    `}

    ${(props) =>
    props.bodySemiBold &&
    `
        font-family: "Inter";
        font-size: 0.9rem;
        line-height: 22px;
        font-weight: ${fonts.semiBold.weight};
    `}

    ${(props) =>
    props.bodyBold &&
    `
        font-family: "Inter";
        font-size: 0.9rem;
        line-height: 24px;
        font-weight: ${fonts.bold.weight};
    `}
`;

export default Text;
