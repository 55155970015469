import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import FullHeightPane from '../../components/full-height-pane/full-height-pane.component';

import useLocale from '../../utils/locale/locale.hook';
import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import ClassificationSlice from '../../store/slices/classification.slice';
import Text from '../../components/style/text.component';
import { replace } from '../../utils/locale/locale.utils';
import Button from '../../components/forms-v2/button.component';
import Image from '../../components/image.component';
import {
  getNotModeratedPicturesCount,
  getRandomNotModeratedPicture,
  moderatePicture,
} from '../../services/pictures.service';
import { Loader } from '../../components/style/loader.component';
import { useLogin } from '../../utils/auth.utils';

const OverlayClassification = () => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const fullHeightRef = useRef(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { me } = useLogin();

  const [isLoading, setIsLoading] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [tutorialCategory, setTutorialCategory] = useState<
    'nonSexual' | 'someSkin' | 'softcore' | 'hardcore' | 'illegal' | 'forbidden'
  >('nonSexual');
  const [numberOfPicturesToClassify, setNumberOfPicturesToClassify] = useState<number>(0);
  const [currentPicture, setCurrentPicture] = useState(null);

  const handleClickOutside = (e) => {
    if (!fullHeightRef?.current?.contains(e.target)) {
      dispatch(ClassificationSlice.actions.setOverlayVisibility(false));
    }
  };

  const getRandomPictureToClassify = async () => {
    setIsLoading(true);
    const data = await getRandomNotModeratedPicture();
    if (!data || !data?.picture) {
      setIsLoading(false);
      return;
    }
    setCurrentPicture(data.picture);
    setIsLoading(false);
  };

  const getPicturesToClassifyCount = async () => {
    const data = await getNotModeratedPicturesCount();
    if (!data) {
      setNumberOfPicturesToClassify(0);
      return;
    }
    const picturesCount: number = data?.picturesCount || 0;
    setNumberOfPicturesToClassify(picturesCount);
  };

  const handleModeratePicture = async (
    moderation: 'skip' | 'nonSexual' | 'someSkin' | 'softcore' | 'hardcore' | 'illegal'
  ) => {
    setIsLoading(true);
    const data = await moderatePicture({
      picture: currentPicture,
      userId: me.id,
      moderation: moderation,
    });
    if (!data) {
      setIsLoading(false);
      console.log('ERROR: Could not moderate picture', data);
      return;
    }
    getRandomPictureToClassify();
  };

  useEffect(() => {
    getRandomPictureToClassify();
  }, []);

  useEffect(() => {
    getPicturesToClassifyCount();
  }, [currentPicture]);

  const user = {
    username: 'Bearwbot',
    profilePicture: '/assets/imgs/no-profile-picture.svg',
  };

  const mapClassificationToIconAndName = {
    nonSexual: {
      icon: '/assets/icons/classification/non-sexual.svg',
      name: 'global.nonsexual',
      classificationHtml: `<img src='/assets/icons/classification/non-sexual.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.nonSexual
      }; font-size: 16px; margin-right: 12px;'>${locale('global.nonsexual')}</span></b>`,
    },
    someSkin: {
      icon: '/assets/icons/classification/some-skin.svg',
      name: 'global.someskin',
      classificationHtml: `<img src='/assets/icons/classification/some-skin.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.someSkin
      }; font-size: 16px; margin-right: 12px;'>${locale('global.someskin')}</span></b>`,
    },
    softcore: {
      icon: '/assets/icons/classification/softcore.svg',
      name: 'global.softcore',
      classificationHtml: `<img src='/assets/icons/classification/softcore.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.softcore
      }; font-size: 16px; margin-right: 12px;'>${locale('global.softcore')}</span></b>`,
    },
    hardcore: {
      icon: '/assets/icons/classification/hardcore.svg',
      name: 'global.hardcore',
      classificationHtml: `<img src='/assets/icons/classification/hardcore.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.hardcore
      }; font-size: 16px; margin-right: 12px;'>${locale('global.hardcore')}</span></b>`,
    },
    illegal: {
      icon: '/assets/icons/classification/illegal.svg',
      name: 'global.illegal',
      classificationHtml: `<img src='/assets/icons/classification/illegal.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.illegal
      }; font-size: 16px; margin-right: 12px;'>${locale('global.illegal')}</span></b>`,
    },
    forbidden: {
      icon: '/assets/icons/classification/forbidden.svg',
      name: 'global.forbidden',
      classificationHtml: `<img src='/assets/icons/classification/forbidden.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.forbidden
      }; font-size: 16px; margin-right: 12px;'>${locale('global.forbidden')}</span></b>`,
    },
  };

  const usernameAndIconHtml = `<img src=${user.profilePicture} alt="profile picture" style="width: 18px; height: 18px; margin-left: 12px; margin-right: 4px; border-radius: 4px;" />
                              <span style="font-weight: bold;">
                                ${user.username}
                              </span>`;

  return (
    <OverlayWrapper onClick={handleClickOutside}>
      <Modal
        numberOfPicturesToClassify={numberOfPicturesToClassify}
        isTutorialOpen={isTutorialOpen}
        ref={fullHeightRef}
      >
        <FullHeightPane
          isDesktop={isDesktop}
          width={isDesktop ? 835 : undefined}
          height={isDesktop ? '90vh' : 'fit-content'}
        >
          {!isTutorialOpen && (
            <TitleAndClose>
              <Title>{locale('classification.modal_title')}</Title>
              <HeaderActions>
                <img
                  alt=""
                  src="/assets/icons/icons-actions-close-dark.svg"
                  width={10}
                  height={10}
                  onClick={() => {
                    dispatch(ClassificationSlice.actions.setOverlayVisibility(false));
                  }}
                />
              </HeaderActions>
            </TitleAndClose>
          )}
          {isTutorialOpen && (
            <TutorialHeader>
              <BackButtonContainer onClick={() => setIsTutorialOpen(false)}>
                <img src="/assets/icons/arrow-left.svg" alt="back button" />
                {!isDesktop && <Text style={{ marginLeft: 17 }}>{locale('classification.back')}</Text>}
              </BackButtonContainer>
              <TutorialTitle>Tutoriel</TutorialTitle>
            </TutorialHeader>
          )}
          <OverlayContent isTutorialOpen={isTutorialOpen}>
            {!isTutorialOpen && (
              <ActionsContainer>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: replace(locale('classification.pictures_remaining'), {
                      number: numberOfPicturesToClassify,
                    }),
                  }}
                />
                <HeaderButtonsContainer>
                  {numberOfPicturesToClassify > 0 && (
                    <Button
                      text="Passer l'image"
                      style={{
                        borderColor: colors.lightGrey9,
                        padding: '8px 14px',
                        backgroundColor: colors.white,
                        borderRadius: 8,
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        color: colors.darkGrey2,
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() => handleModeratePicture('skip')}
                    />
                  )}
                  <Button
                    onClick={() => setIsTutorialOpen(true)}
                    text="Lire le tutoriel"
                    style={{
                      borderColor: colors.lightGrey9,
                      padding: '8px 14px',
                      backgroundColor: colors.white,
                      borderRadius: 8,
                      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                      color: colors.darkGrey2,
                      whiteSpace: 'nowrap',
                    }}
                  />
                </HeaderButtonsContainer>
              </ActionsContainer>
            )}
            {!isTutorialOpen && (
              <ImageContainer>
                {!isLoading ? (
                  <>
                    {numberOfPicturesToClassify > 0 ? (
                      <Image
                        src={currentPicture?.path || '/assets/imgs/no-profile-picture.svg'}
                        width="auto"
                        height="100%"
                        alt=""
                        style={{
                          borderRadius: 8,
                          maxHeight: 320,
                        }}
                      />
                    ) : (
                      <NoImageContainer>
                        <CongratsContainer>
                          <img src="/assets/icons/classification/thumb.svg" alt="icon" />
                          <Text
                            style={{
                              color: colors.middleGreen,
                              fontSize: 38,
                              fontWeight: fonts.bold.weight,
                            }}
                          >
                            {locale('classification.bravo')}
                          </Text>
                        </CongratsContainer>
                        <Text
                          style={{
                            color: colors.darkGrey1,
                            fontSize: 18,
                          }}
                        >
                          {locale('classification.no_image_left_to_classify')}
                        </Text>
                      </NoImageContainer>
                    )}
                  </>
                ) : (
                  <Loader color={colors.primary} />
                )}
              </ImageContainer>
            )}
            {isTutorialOpen && (
              <TutorialContent isTutorialOpen={isTutorialOpen}>
                <TutorialSubHeader>
                  <Text
                    style={{
                      color: colors.classification[tutorialCategory],
                      fontSize: 26,
                      fontWeight: fonts.bold.weight,
                      gap: 17,
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 12,
                    }}
                  >
                    <img src={mapClassificationToIconAndName[tutorialCategory].icon} height={21.33} width={21.33} />
                    {locale(mapClassificationToIconAndName[tutorialCategory].name)}
                  </Text>
                  <Text
                    style={{
                      color: colors.darkGrey1,
                      marginBottom: 10,
                      textAlign: 'center',
                    }}
                  >
                    {locale(`classification.tutorial.${tutorialCategory}`)}
                  </Text>
                </TutorialSubHeader>
                <ImagesContainer isIllegalCategory={tutorialCategory === 'illegal'}>
                  {tutorialCategory === 'nonSexual' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/nonsexual1.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual2.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual3.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual4.jpg" />
                    </>
                  )}
                  {tutorialCategory === 'someSkin' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/someskin1.jpg" />
                      <TutorialImage src="/assets/imgs/classification/someskin2.webp" />
                      <TutorialImage src="/assets/imgs/classification/someskin3.jfif" />
                      <TutorialImage src="/assets/imgs/classification/someskin4.jpg" />
                    </>
                  )}
                  {tutorialCategory === 'softcore' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/soft1.webp" />
                      <TutorialImage src="/assets/imgs/classification/soft2.webp" />
                      <TutorialImage src="/assets/imgs/classification/soft3.jfif" />
                      <TutorialImage src="/assets/imgs/classification/soft4.jfif" />
                    </>
                  )}
                  {tutorialCategory === 'hardcore' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/hardcore1.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore2.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore3.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore4.webp" />
                    </>
                  )}
                  {tutorialCategory === 'illegal' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/illegal1.png" />
                    </>
                  )}
                  {tutorialCategory === 'forbidden' && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/forbidden1.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden2.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden3.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden4.png" />
                    </>
                  )}
                </ImagesContainer>
              </TutorialContent>
            )}
            {numberOfPicturesToClassify > 0 && !isTutorialOpen && !isLoading && (
              <Text
                style={{
                  marginTop: 27,
                  textAlign: 'center',
                  fontSize: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                dangerouslySetInnerHTML={{
                  __html: replace(locale('classification.considered_as'), {
                    classification:
                      mapClassificationToIconAndName[currentPicture?.moderationHive || 'softcore'].classificationHtml,
                    username: usernameAndIconHtml,
                  }),
                }}
              />
            )}
            {(numberOfPicturesToClassify > 0 || isTutorialOpen) && (
              <ClassificationButtonsContainer isTutorialOpen={isTutorialOpen}>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('nonSexual') : handleModeratePicture('nonSexual');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'nonSexual'}
                >
                  <img src={mapClassificationToIconAndName['nonSexual'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === 'nonSexual'
                          ? colors.white
                          : colors.classification.nonSexual,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['nonSexual'].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('someSkin') : handleModeratePicture('someSkin');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'someSkin'}
                >
                  <img src={mapClassificationToIconAndName['someSkin'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === 'someSkin'
                          ? colors.white
                          : colors.classification.someSkin,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['someSkin'].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('softcore') : handleModeratePicture('softcore');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'softcore'}
                >
                  <img src={mapClassificationToIconAndName['softcore'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === 'softcore'
                          ? colors.white
                          : colors.classification.softcore,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['softcore'].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('hardcore') : handleModeratePicture('hardcore');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'hardcore'}
                >
                  <img src={mapClassificationToIconAndName['hardcore'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === 'hardcore'
                          ? colors.white
                          : colors.classification.hardcore,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['hardcore'].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('illegal') : handleModeratePicture('illegal');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'illegal'}
                >
                  <img src={mapClassificationToIconAndName['illegal'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color: colors.classification.illegal,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['illegal'].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen ? setTutorialCategory('forbidden') : handleModeratePicture('illegal');
                  }}
                  active={isTutorialOpen && tutorialCategory === 'forbidden'}
                >
                  <img src={mapClassificationToIconAndName['forbidden'].icon} alt="icon" />
                  <Text
                    bold
                    style={{
                      color: colors.classification.forbidden,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName['forbidden'].name)}
                  </Text>
                </ClassificationButton>
              </ClassificationButtonsContainer>
            )}
          </OverlayContent>
        </FullHeightPane>
      </Modal>
    </OverlayWrapper>
  );
};

const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 500;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background-color: ${colors.backgroundLightGrey};
  }
`;

const Modal = styled.div<{
  numberOfPicturesToClassify: number;
  isTutorialOpen: boolean;
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1000px) {
    position: fixed;
    top: ${({ numberOfPicturesToClassify, isTutorialOpen }) =>
      isTutorialOpen ? '366px' : numberOfPicturesToClassify > 0 ? 'calc(50% - 12px)' : '240px'};
    width: calc(100% + 1px);
    padding-top: 12px;
    background-color: ${colors.backgroundLightGrey};
    box-sizing: border-box;
  }
`;

const TitleAndClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: ${fonts.bold.weight};
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
    margin: 0 6px;
    width: 24px;
    height: 24px;
  }
`;

const OverlayContent = styled.div<{ isTutorialOpen: boolean }>`
  box-sizing: border-box;
  padding-top: ${({ isTutorialOpen }) => (isTutorialOpen ? '0' : '12px')};
  width: fit-content;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    padding: 12px;
    padding-top: 0;
    height: calc(100% - 120px);
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 1000px) {
    max-width: calc(100vw - 24px);
    min-width: calc(100vw - 24px);

    & img {
      min-width: calc(100vw - 24px);
    }
  }
`;

const NoImageContainer = styled.div`
  background-color: ${colors.veryLightGrey};
  width: 100%;
  padding: 32px 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    text-align: center;
  }

  & img {
    width: 27px;
    height: 27px;
    min-width: unset;
  }
`;

const CongratsContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;
  width: 100%;
`;

const ClassificationButtonsContainer = styled.div<{ isTutorialOpen?: boolean }>`
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: space-between;
  margin-top: 27px;

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px 12px;
    ${({ isTutorialOpen }) => isTutorialOpen && 'order: 1'};
  }
`;

const ClassificationButton = styled.button<{ active?: boolean }>`
  outline: none;
  border: none;
  padding: 8px 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  background-color: ${({ active }) => (active ? colors.darkGrey1 : colors.veryLightGrey)};

  &:hover {
    background-color: ${colors.lightGrey9};
  }
`;

const TutorialHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    background-color: ${colors.backgroundLightGrey};
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & img {
    width: 14px;
    height: 14px;
  }

  @media (max-width: 1000px) {
    margin-left: 12px;
  }
`;

const TutorialTitle = styled(Title)`
  margin: 0 auto;
  transform: translateX(-12px);

  @media (max-width: 1000px) {
    margin-left: 24px;
    margin-top: 21px;
    margin-bottom: 21px;
    font-size: 19px;
  }
`;

const TutorialSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32.5px;
`;

const TutorialContent = styled.div<{ isTutorialOpen?: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    ${({ isTutorialOpen }) => isTutorialOpen && 'order: 2'};
  }
`;

const ImagesContainer = styled.div<{ isIllegalCategory: boolean }>`
  display: grid;
  grid-template-columns: ${({ isIllegalCategory }) => (isIllegalCategory ? 'unset' : 'repeat(2, 1fr)')};
  gap: 8px 8px;
  overflow: hidden;
`;

const TutorialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
`;

export default OverlayClassification;
