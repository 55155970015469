import React from "react";
import { Helmet } from "react-helmet";
import { getAvailableLanguage } from "../../utils/locale/locale.utils";

const Hreflang = () => {
  let currentUrl = new URL(window.location.href);
  const languages = getAvailableLanguage();

  let pathname = currentUrl.pathname;

  for (let language of languages) {
    if (currentUrl.pathname.includes(`/${language}/`)) {
      pathname = pathname.substring(3);
    }
  }

  return (
    <Helmet>
      <link
        rel="alternate"
        href={`${currentUrl.origin}${pathname}`}
        hrefLang="x-default"
      />
      {languages.map((language) => (
        <link
          rel="alternate"
          href={`${currentUrl.origin}/${language}${pathname}`}
          hrefLang={language}
          key={language}
        />
      ))}
    </Helmet>
  );
};

export default Hreflang;
