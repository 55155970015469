import React from "react";

interface IconsProps {
  name: string;
  width: string;
  height: string;
  style?: object;
  onClick?: (e?: React.MouseEvent<HTMLImageElement>) => void;
}

const Icons = (props: IconsProps) => {
  return (
    <img
      alt=""
      onClick={props?.onClick}
      style={props?.style}
      width={props?.width}
      height={props?.height}
      src={"/assets/icons/" + props.name + ".svg"}
    />
  );
};

export default Icons;
