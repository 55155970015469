import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import translationCA from "./locales/ca.json";
import translationDE from "./locales/de.json";
import translationEL from "./locales/el.json";
import translationIT from "./locales/it.json";
import translationJA from "./locales/ja.json";
import translationNL from "./locales/nl.json";
import translationPL from "./locales/pl.json";
import translationPT from "./locales/pt.json";
import translationES from "./locales/es.json";
import { getCurrentLanguage } from "./utils/locale/hreflang.utils";


const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ca: {
    translation: translationCA,
  },
  de: {
    translation: translationDE,
  },
  el: {
    translation: translationEL,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  nl: {
    translation: translationNL,
  },
  pl: {
    translation: translationPL,
  },
  pt: {
    translation: translationPT,
  },
  es: {
    translation: translationES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
