import { CircularProgress } from "@material-ui/core";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import TextareaInput from "../../../components/forms-v2/textarea.input";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";
import CustomTooltip from "../../../components/miscellaneous/custom-tooltip.component";
import PictureModeration from "../../../components/picture-moderation.component";
import { Flex } from "../../../components/style/flex.component";
import { Loader } from "../../../components/style/loader.component";
import Text from "../../../components/style/text.component";
import { Tab } from "../../../components/tabs/Tab";
import { Tabs } from "../../../components/tabs/Tabs";
import NewLabel from "../../../components/user/NewLabel";
import Icon from "../../../components/user/PremiumSoonIcon";
import {
  getQuickshare,
  initiatePrivateConversation,
  sendQuickshare,
} from "../../../services/chat.service";
import {
  GroupedHashtags,
  getHashtags,
} from "../../../services/hashtags.service";
import { getUserPresentationVideo } from "../../../services/user-videos.service";
import {
  getUserVideosByUserId,
  setPersonalNote,
} from "../../../services/users.service";
import ModalSlice from "../../../store/slices/modal.slice";
import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import { useLogin } from "../../../utils/auth.utils";
import { useOnClickOutside } from "../../../utils/clickOutside.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { getStatusColor } from "../../../utils/status.utils";
import {
  getDistance,
  getHeight,
  getWeight,
} from "../../../utils/unities.utils";
import { getEmbedLink } from "../../../utils/video.utils";
import GuideCard from "../../guide/components/guide-card.component";
import { UserVideo } from "../../settings/components/videos/section-videos.component";
import { MenusShowed } from "../types/menus-showed.interface";
import { Mode } from "../types/mode.enum";
import PicturesDisplay from "./pictures-display.component";
import ProfileVideo from "./profile-video.component";
import { closeProfile } from "../profile.utils";
import { isPornLink } from "../../../utils/regex.util";

interface ProfileInformationsProps {
  isScreen: boolean;
  user: any;
  setMode: Dispatch<SetStateAction<Mode>>;
  setMenusShowed: Dispatch<SetStateAction<MenusShowed>>;
  cancelVisitHandler: () => void;
  timerData: {
    hasTimer: boolean;
    timeLeft: number;
  };
  menusShowed?: MenusShowed;
  openConversationHandler?: (conversationId: number) => void;
  mode?: Mode.INFORMATIONS;
  isAnonymous?: boolean;
  closeModal?: () => void;
}

interface Album {
  available: boolean;
  pictures: Picture[];
  quickshareLength: number;
  timeRemaining: number;
}

interface Picture {
  adminCheck: boolean;
  description: string | null;
  id: string;
  moderationAdmin: string;
  moderationHive: string;
  moderationRating: string;
  order: number;
  path: string;
  uploadDate: Date;
}

enum SendStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

enum AskStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

type Tab = "infos" | "news" | "videos" | "album";

type InfoItemType = {
  type: string;
  value: any;
  isButton?: boolean;
};

const InformationItem = (props: InfoItemType) => {
  const locale = useLocale();
  let title = "";
  let value = null;
  switch (props.type) {
    case "firstname":
      title = locale("edit_profile.appearance.title");
      break;
    case "city":
      title = locale("profile.city.title");
      break;
    case "country":
      title = locale("global.country");
      break;
    case "age":
      title = locale("android.filter.age");
      break;
    case "weight":
      title = locale("android.filter.weight");
      break;
    case "height":
      title = locale("android.filter.height");
      break;
    case "description":
      title = locale("profile.description.title");
      break;
    case "community":
      title = locale("profile.i_am.title");
      break;
    case "looking_for":
      title = locale("profile.looking_for.title");
      break;
    case "sexual_practices":
      title = locale("profile.sexual_practices.title");
      break;
    case "sexual_role":
      title = locale("radar.filter.position");
      break;
    case "ethnicity":
      title = locale("global.ethnicity");
      break;
    case "body_hair":
      title = locale("edit_profile.bodyHair.title");
      break;
    case "facial_hair":
      title = locale("global.facial_hair");
      break;
    case "languages":
      title = locale("global.languages");
      break;
    case "member_since":
      title = locale("profile.member_since.title");
      break;
    case "profile_views":
      title = locale("profile.views.title");
      break;
    default:
      break;
  }

  if (Array.isArray(props.value)) {
    value = "";
    for (let i = 0; i < props.value.length - 1; i++) {
      value = value + props.value[i] + ", ";
    }
    value = value + props.value[props.value.length - 1];
  }

  return (
    <ItemWrapper>
      <p className="title">{title}</p>

      {props.isButton ? (
        <Flex gap="5px" style={{ flexWrap: "wrap" }}>
          {props.value.map((val: any, index: number) => (
            <PrimaryButton
              key={index}
              size="small"
              style={{ cursor: "default", border: "none" }}
            >
              <div className="value">{val}</div>
            </PrimaryButton>
          ))}
        </Flex>
      ) : (
        <p className="value">{value ? value : props.value}</p>
      )}
    </ItemWrapper>
  );
};

const getDate = (value: string) => {
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

const getMonth = (value: string) => {
  const date = new Date(value);
  const resultTemp = date.toDateString();
  const result = resultTemp.slice(4, 8) + resultTemp.slice(11);
  return result;
};

const ProfileInformations = (props: ProfileInformationsProps) => {
  const {
    isScreen,
    setMenusShowed,
    cancelVisitHandler,
    timerData,
    isAnonymous,
    closeModal,
  } = props;
  const { me, isLogin } = useLogin();
  const locale = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const modalStore = useSelector((state: any) => state.modalStore);

  const [hashtagCategories, setHashtagCategories] = useState<GroupedHashtags[]>(
    []
  );
  const [actionsVisible, setActionsVisible] = useState(false);
  const [isMyNoteOpen, setIsMyNoteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(
    modalStore.isUserProfileAnonymousAndPseudo.isFromSharedAlbum
      ? "album"
      : "infos"
  );
  const [askStatus, setAskStatus] = useState(AskStatus.IDLE);
  const [sendStatus, setSendStatus] = useState(SendStatus.IDLE);
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const [pictureSelected, setPictureSelected] = useState(null);
  const [isRightEnd, setIsRightEnd] = useState(false);
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const carousselRef = useRef(null);
  const [needCaroussel, setNeedCaroussel] = useState(false);
  const [personalNoteValue, setPersonalNoteValue] = useState("");
  const [album, setAlbum] = useState<Album | undefined>();
  const [userVideos, setUserVideos] = useState([]);
  const [seeMoreGuides, setSeeMoreGuides] = useState(false);
  const [presentationVideo, setPresentationVideo] = useState<UserVideo | null>(
    null
  );

  const actionsMenuRef = useRef(null);

  useOnClickOutside([actionsMenuRef], () => {
    setActionsVisible(false);
  });

  const handlePersonalNoteSubmit = () => {
    setPersonalNote({
      note: personalNoteValue,
      user: me.id,
      targetId: props.user.id,
    });
    setIsMyNoteOpen(false);
  };

  const askQuickshareHandler = async () => {
    if (askStatus === AskStatus.LOADING || sendStatus === SendStatus.LOADING) {
      return;
    }

    setAskStatus(AskStatus.LOADING);
    setSendStatus(SendStatus.LOADING);
    const conversationRes = await initiatePrivateConversation(props.user.id);

    if (conversationRes?.conversationId) {
      setIsLoading(true);
      const res = await sendQuickshare({
        conversationId: conversationRes?.conversationId,
        status: "asked",
      });
      if (res?.success) {
        setMenusShowed({ quickshare: false, woof: false });
        props.openConversationHandler(conversationRes?.conversationId);
        setIsLoading(false);
      } else {
        setAskStatus(AskStatus.ERROR);
        setSendStatus(SendStatus.ERROR);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const openFullSizeImages = (pictureSelected: any) => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: true,
        images: props.user.picturesVisible,
        start: props.user.picturesVisible.findIndex(
          (picture: any) => picture === pictureSelected
        ),
        type: "pictures",
      })
    );
  };

  const getUserVideos = async () => {
    const data = await getUserVideosByUserId(props.user.id);
    if (!data || data.length === 0 || data.error) {
      setUserVideos([]);
      return;
    }
    setUserVideos(data);
  };

  async function getPresentationVideo() {
    const video = await getUserPresentationVideo(props.user.id);
    if (!video.error) setPresentationVideo(video);
  }

  const getAllHashtags = async () => {
    const data = await getHashtags();
    if (!data || data.length === 0) {
      return;
    }
    setHashtagCategories(data);
  };

  useEffect(() => {
    getPresentationVideo();
  }, []);

  useEffect(() => {
    setPictureSelected(props.user.picturesVisible[0]);
  }, [props.user]);

  useEffect(() => {
    if (pictureSelected === null) {
      setPictureSelected(props.user.picturesVisible[0]);
    }
  }, [pictureSelected, props.user.picturesVisible]);

  useEffect(() => {
    if (props.user.picturesVisible?.length) {
      setNeedCaroussel(
        props.user.picturesVisible.length >=
          Math.round(
            (carousselRef.current?.getBoundingClientRect().width || 0) / 74
          )
      );
    }
    setIsRightEnd(false);
    setIsLeftEnd(true);
  }, [props.user.picturesVisible]);

  useEffect(() => {
    const quicksharePictures = async () => {
      const data = await getQuickshare(props.user.id);
      return data;
    };
    quicksharePictures().then((res) => setAlbum(res));
  }, []);

  useEffect(() => {
    getUserVideos();
    getAllHashtags();
  }, []);

  useEffect(() => {
    const iframes = document.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      iframe.setAttribute("credentialless", "true");
    });
  }, []);

  const userAttributes = useMemo(() => {
    const fields = [];
    if (props.user.ageVisibility === "public") {
      fields.push(props.user.age + " " + locale("global.year"));
    }
    if (props.user?.ethnicity) {
      fields.push(locale(props.user?.ethnicity.phraseKey));
    }
    if (props.user?.facialHair) {
      fields.push(locale(props.user?.facialHair.phraseKey));
    }
    if (props.user?.appearance) {
      fields.push(locale(props.user?.appearance.phraseKey));
    }
    return fields;
  }, []);

  return (
    <Container isScreen={isScreen}>
      {!isDesktop && (
        <>
          <UserInfo
            hasCarousel={props.user.picturesVisible.length > 1 ? true : false}
          >
            {!!me.role && timerData.hasTimer && (
              <div onClick={cancelVisitHandler}>
                <PremiumVisitCanceller
                  hasSeveralPictures={props.user.picturesVisible.length > 1}
                >
                  <p className="timer">{timerData.timeLeft}</p>
                  <StyledCircularProgress
                    variant="determinate"
                    value={100}
                    style={{
                      width: 40,
                      height: 40,
                      color: "#ffffff",
                      position: "absolute",
                      top: "4px",
                      left: "4px",
                    }}
                  />
                  <StyledCircularProgress
                    variant="determinate"
                    style={{
                      width: 40,
                      height: 40,
                      color: "#23c06c",
                      position: "relative",
                    }}
                    value={Math.round((100 * timerData.timeLeft) / 30)}
                  />
                </PremiumVisitCanceller>
                <PremiumVisitCancellerText>
                  {locale("radar.user-profile.hide-visit")}
                </PremiumVisitCancellerText>
              </div>
            )}
            <PremiumSoonIconsContainer>
              {props.user.createdAt &&
                (Date.now() - Date.parse(props.user.createdAt)) / 3600000 <=
                  24 && <NewLabel />}
              {props.user.role === "premium" && (
                <CustomTooltip
                  arrow={true}
                  placement="bottom-end"
                  title={
                    <div style={{ maxWidth: 210, fontSize: 10, margin: 8 }}>
                      {locale("premium.description")}
                    </div>
                  }
                >
                  <>
                    <Icon icon="premium" width={22} height={22} />
                  </>
                </CustomTooltip>
              )}
              {props.user?.travels[0] && (
                <Icon icon="soon" width={22} height={22} />
              )}
            </PremiumSoonIconsContainer>
            {props.user.ageVisibility === "public" && isDesktop && (
              <p className="age"> {props.user.age} </p>
            )}
          </UserInfo>
        </>
      )}
      <InfosContainer>
        <Flex alignItems="flex-start" style={{ width: "100%", paddingTop: 24 }}>
          <Tabs
            style={{ width: "100%" }}
            isDark={true}
            selectedTab={["infos", "album"].indexOf(activeTab)}
            endIcon={
              isDesktop ? (
                <Flex gap="8px">
                  {isLogin && me.id !== props.user?.id && (
                    <PrimaryButton
                      size="medium"
                      style={{ borderColor: colors.grayBorder }}
                      onClick={() => setActionsVisible((action) => !action)}
                    >
                      <img
                        alt=""
                        src={"/assets/icons/user-profile/flag-report.svg"}
                        width={16}
                        height={16}
                      />
                    </PrimaryButton>
                  )}
                  <PrimaryButton
                    size="medium"
                    style={{
                      borderColor: colors.grayBorder,
                      width: 36,
                      height: 36,
                    }}
                    onClick={() => closeModal?.()}
                  >
                    <img
                      alt="cross"
                      src="/assets/icons/cross-outlined-white.svg"
                      width={16}
                      height={16}
                    />
                  </PrimaryButton>
                </Flex>
              ) : null
            }
          >
            <Tab
              title={locale("user_profile.infos")}
              onClick={() => setActiveTab("infos")}
              titleStyle={{ flex: 1, display: !isDesktop ? "none" : "block" }}
            >
              {!isDesktop ? (
                <Flex
                  style={{
                    fontWeight: 500,
                    fontSize: "12px",
                    padding: "4px 0px",
                  }}
                  direction="column"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {!isAnonymous ? props.user.pseudo : ""}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      marginTop: 6,
                    }}
                  >
                    <StatusCircle
                      color={getStatusColor(props.user.status).centerCircle}
                      style={{
                        borderColor: getStatusColor(props.user.status).circle,
                      }}
                    />
                    {props.user.status === "online" ? (
                      <Text semiBold style={{ fontSize: 10 }}>
                        {locale("account.status.online")}
                      </Text>
                    ) : props.user.status === "recently_online" ? (
                      <Text semiBold style={{ fontSize: 10 }}>
                        {locale("profile.status.connected.today")}
                      </Text>
                    ) : null}

                    {props.user?.distance !== "-1.0" &&
                      props.user?.distance !== -1 && (
                        <p
                          className="distance"
                          style={{
                            margin:
                              props.user.status === "recently_online"
                                ? 0
                                : "unset",
                            fontSize: 10,
                          }}
                        >
                          {`${locale("nearby.at-distance")} ${getDistance(
                            props.user.distance
                          )}`}
                        </p>
                      )}
                  </div>
                </Flex>
              ) : null}
              <Flex
                style={{
                  fontWeight: 500,
                  fontSize: "12px",
                  padding: "4px 0px",
                }}
              >
                {!!props.user?.height &&
                  getHeight(props.user?.height) +
                    locale("global.metter." + localStorage.getItem("units"))}
                {!!props.user?.height && !!props.user?.weight && ", "}
                {!!props.user?.weight &&
                  getWeight(props.user?.weight) +
                    locale("global.kg." + localStorage.getItem("units"))}
              </Flex>
              <Flex
                style={{
                  fontWeight: 500,
                  fontSize: "12px",
                  padding: "4px 0px",
                  borderBottom: "1px solid #71727633",
                }}
              >
                {userAttributes.join(", ")}
              </Flex>

              {props.user.hashtags.length > 0 &&
                hashtagCategories.length > 0 && (
                  <Flex
                    direction="column"
                    style={{
                      borderBottom: "1px solid #71727633",
                      padding: "12px 0px",
                    }}
                  >
                    <Title>{locale("profile.hashtags")}</Title>
                    <Flex style={{ flexWrap: "wrap", gap: 8 }}>
                      {props.user.hashtags.map((hashtag) => {
                        const currentHashtag = hashtagCategories
                          .flatMap((category) => category.hashtags)
                          .find((h) => h.id === hashtag.id);
                        return (
                          <PrimaryButton
                            key={hashtag.id}
                            onClick={() => {
                              closeProfile();
                              history.push({
                                pathname: "/hashtags",
                                state: {
                                  id: hashtag.id,
                                  name: locale(currentHashtag.traductionKey),
                                  previousPath: location.pathname,
                                  category: "hashtags",
                                },
                              });
                            }}
                          >
                            {locale(currentHashtag.traductionKey)}
                          </PrimaryButton>
                        );
                      })}
                    </Flex>
                  </Flex>
                )}
              {presentationVideo ? (
                <Flex
                  direction="column"
                  style={{
                    borderBottom: "1px solid #71727633",
                    padding: "12px 0px",
                  }}
                >
                  <Title>{locale("profile.video")}</Title>
                  <ProfileVideo
                    user={props.user}
                    video={presentationVideo}
                    isFirst={true}
                  />
                </Flex>
              ) : null}

              {userVideos.length > 1 && (
                <Flex
                  direction="column"
                  style={{
                    borderBottom: "1px solid #71727633",
                    padding: "12px 0px",
                  }}
                >
                  <Title>{locale("profile.other-videos")}</Title>
                  {userVideos
                    .filter((video)=> !isPornLink(video.path))
                    .filter((userVideo) =>
                      presentationVideo
                        ? userVideo.uid !== presentationVideo.uid
                        : true
                    )
                    .map((userVideo, index) => (
                      <ProfileVideo
                        key={index}
                        user={props.user}
                        video={userVideo}
                      />
                    ))}
                </Flex>
              )}

              {!!props.user?.firstname && (
                <InformationItem
                  type="firstname"
                  value={props.user.firstname}
                />
              )}
              {/* {!!props.user?.community && (
                <InformationItem type="community" value={locale(props.user.community.phraseKey)} />
              )} */}
              {!!props.user?.community && (
                <Flex
                  direction="column"
                  style={{
                    borderBottom: "1px solid #71727633",
                    padding: "12px 0px",
                  }}
                >
                  <Title>{locale("profile.i_am.title")}</Title>
                  <PrimaryButton
                    style={{ width: "fit-content" }}
                    key={props.user.community.id}
                    onClick={() => {
                      closeProfile();
                      history.push({
                        pathname: "/hashtags",
                        state: {
                          id: props.user.community.communityKey,
                          name: locale(props.user.community.phraseKey),
                          previousPath: location.pathname,
                          category: "communities",
                        },
                      });
                    }}
                  >
                    {locale(props.user.community.phraseKey)}
                  </PrimaryButton>
                </Flex>
              )}
              {!!props.user?.communitiesLike &&
                props.user?.communitiesLike.length > 0 && (
                  <Flex
                    direction="column"
                    style={{
                      borderBottom: "1px solid #71727633",
                      padding: "12px 0px",
                    }}
                  >
                    <Title>{locale("profile.looking_for.title")}</Title>
                    <Flex style={{ flexWrap: "wrap", gap: 8 }}>
                      {props.user.communitiesLike.map((community) => {
                        return (
                          <PrimaryButton
                            key={community.id}
                            onClick={() => {
                              closeProfile();
                              history.push({
                                pathname: "/hashtags",
                                state: {
                                  id: community.communityKey,
                                  name: locale(community.phraseKey),
                                  previousPath: location.pathname,
                                  category: "communities",
                                },
                              });
                            }}
                          >
                            {locale(community.phraseKey)}
                          </PrimaryButton>
                        );
                      })}
                    </Flex>
                  </Flex>
                )}

              {props.user.description ? (
                <InformationItem
                  type="description"
                  value={props.user.description}
                />
              ) : null}

              {!!props.user?.city && (
                <InformationItem type="city" value={props.user.city} />
              )}
              {!!props.user?.country && (
                <InformationItem type="country" value={props.user.country} />
              )}
              {!!props.user?.sexualRole && (
                <InformationItem
                  type="sexual_role"
                  value={locale(props.user.sexualRole.phraseKey)}
                />
              )}
              {!!props.user?.sexualPractices &&
                props.user?.sexualPractices.length > 0 && (
                  <Flex
                    direction="column"
                    style={{
                      borderBottom: "1px solid #71727633",
                      padding: "12px 0px",
                    }}
                  >
                    <Title>{locale("profile.sexual_practices.title")}</Title>
                    <Flex style={{ flexWrap: "wrap", gap: 8 }}>
                      {props.user.sexualPractices.map((sexualPractice) => {
                        return (
                          <PrimaryButton
                            key={sexualPractice.id}
                            onClick={() => {
                              closeProfile();
                              history.push({
                                pathname: "/hashtags",
                                state: {
                                  id: sexualPractice.sexualPracticeKey,
                                  name: locale(sexualPractice.phraseKey),
                                  previousPath: location.pathname,
                                  category: "sexualPractices",
                                },
                              });
                            }}
                          >
                            {locale(sexualPractice.phraseKey)}
                          </PrimaryButton>
                        );
                      })}
                    </Flex>
                  </Flex>
                )}

              {props.user.picturesVisible.length > 1 && (
                <Flex
                  direction="column"
                  alignItems="flex-start"
                  style={{ marginTop: "16px" }}
                >
                  <Title>{locale("profile.pictures.title")}</Title>
                  <PicturesWrapperInfos
                    picturesLength={props.user.picturesVisible.length}
                  >
                    {props.user.picturesVisible
                      .slice(0, 6)
                      .map((picture: any, index: number) => (
                        <Flex key={index} justify="center" alignItems="center">
                          <PictureSelect
                            onClick={() => openFullSizeImages(picture)}
                          >
                            <PictureModeration
                              moderation={picture.moderation}
                              src={picture.path}
                              width={isDesktop ? "150px" : "93px"}
                              height={isDesktop ? "110px" : "80px"}
                              style={{
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                              noText={true}
                            />
                          </PictureSelect>
                        </Flex>
                      ))}
                  </PicturesWrapperInfos>
                </Flex>
              )}

              {props.user?.travels[0] && (
                <Flex
                  direction="column"
                  alignItems="flex-start"
                  style={{
                    marginTop: "16px",
                    borderBottom: "1px solid #71727633",
                  }}
                >
                  <Flex alignItems="center" gap="6px">
                    <img
                      alt=""
                      src="/assets/icons/user-profile/soon-square.svg"
                      width={14}
                      height={14}
                    />
                    <Title style={{ margin: 0 }}>
                      {locale("sidemenu.manageTrips")}
                    </Title>
                  </Flex>

                  {props.user.travels.map((travel: any, index: number) => (
                    <Flex
                      key={index}
                      justify="flex-start"
                      alignItems="center"
                      style={{ width: "100%", margin: "8px 0" }}
                    >
                      <Text bold style={{ flex: 1 }} className="city">
                        {travel.city.nameKey.charAt(0).toUpperCase() +
                          travel.city.nameKey.slice(1)}
                      </Text>
                      <Flex alignItems="center" gap="8px" style={{ flex: 1 }}>
                        <img
                          alt=""
                          src="/assets/icons/sidebar/navbar/calendar-mini.svg"
                          width={14}
                          height={14}
                        />
                        <div>
                          <Text
                            fontSize="12px"
                            style={{ fontWeight: 400 }}
                          >{`${getDate(travel.startDate)} - ${getDate(
                            travel.endDate
                          )}`}</Text>
                        </div>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )}
              <ItemWrapper
                style={{
                  marginBottom: 18,
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text
                  bold
                  onClick={() => setIsMyNoteOpen(!isMyNoteOpen)}
                  style={{ cursor: "pointer" }}
                >
                  {locale("user_profile.personal-note")}
                </Text>
                {isMyNoteOpen ? (
                  <PersonalNoteOverlay onClick={() => setIsMyNoteOpen(false)}>
                    <PersonalNoteContainer onClick={(e) => e.stopPropagation()}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Text>{locale("user_profile.personal-note")}</Text>
                        <PrimaryButton
                          size="medium"
                          style={{
                            borderColor: colors.grayBorder,
                            width: 36,
                            height: 36,
                          }}
                          onClick={() => setIsMyNoteOpen(false)}
                        >
                          <img
                            alt="cross"
                            src="/assets/icons/cross-outlined-white.svg"
                            width={16}
                            height={16}
                          />
                        </PrimaryButton>
                      </div>
                      <TextareaInput
                        onChange={(e) => setPersonalNoteValue(e)}
                        placeholder={
                          personalNoteValue !== ""
                            ? personalNoteValue
                            : props.user?.note
                            ? props.user.note
                            : ""
                        }
                        rows={8}
                      />
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        disabled={personalNoteValue === ""}
                        onClick={handlePersonalNoteSubmit}
                      >
                        {locale("valider")}
                      </PrimaryButton>
                    </PersonalNoteContainer>
                  </PersonalNoteOverlay>
                ) : (
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "flex-end",
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsMyNoteOpen(!isMyNoteOpen)}
                  >
                    <Text style={{ marginRight: 12 }}>
                      {personalNoteValue !== ""
                        ? personalNoteValue.slice(0, 30).concat("...")
                        : props.user?.note
                        ? props.user.note.slice(0, 30).concat("...")
                        : ""}
                    </Text>
                    <img
                      src="/assets/icons/user-profile/arrow-right.svg"
                      width={6}
                      height={12}
                    />
                  </div>
                )}
              </ItemWrapper>

              {props.user?.guides.length > 0 ? (
                <Flex
                  direction="column"
                  style={{
                    paddingBottom: 16,
                    borderBottom: "1px solid #71727633",
                    gap: 16,
                  }}
                >
                  <Flex justify="space-between">
                    <Title>{locale("events")}</Title>
                    <Text
                      bold
                      style={{ fontSize: 12, cursor: "pointer" }}
                      color={colors.darkBlue}
                      onClick={() => setSeeMoreGuides((seeMore) => !seeMore)}
                    >
                      {!seeMoreGuides
                        ? locale("guide.info.see_more")
                        : locale("guide.info.see_less")}
                    </Text>
                  </Flex>

                  {props.user?.guides
                    .slice(0, seeMoreGuides ? undefined : 1)
                    .map((guide) => {
                      return <GuideCard key={guide.id} guide={guide} />;
                    })}
                </Flex>
              ) : null}

              {props.user?.videoLink1 || props.user?.videoLink2 ? (
                <Flex
                  direction="column"
                  style={{
                    paddingBottom: 16,
                    borderBottom: "1px solid #71727633",
                  }}
                >
                  <Title style={{ marginBottom: 8 }}>
                    {locale("user_profile.youtube-videos")}
                  </Title>

                  {props.user?.videoLink1 && (
                    <Flex direction="column" style={{ marginBottom: 8 }}>
                      <Text fontSize="12px" style={{ margin: "8px 0" }}>
                        {props.user.videoTitle1}
                      </Text>
                      <Iframe
                        src={getEmbedLink(props.user?.videoLink1)}
                        credentialless
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                      />
                    </Flex>
                  )}
                  {props.user?.videoLink2 && (
                    <Flex direction="column">
                      <Text fontSize="12px" style={{ margin: "8px 0" }}>
                        {props.user.videoTitle2}
                      </Text>
                      <Iframe
                        src={getEmbedLink(props.user?.videoLink2)}
                        credentialless
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                      />
                    </Flex>
                  )}
                </Flex>
              ) : null}

              {!!props.user?.createdAt && (
                <InformationItem
                  type="member_since"
                  value={getMonth(props.user.createdAt)}
                />
              )}
              {!!props.user?.createdAt && (
                <InformationItem
                  type="profile_views"
                  value={props.user.viewCount}
                />
              )}
            </Tab>

            <Tab
              title={locale("album.quickshare.title")}
              onClick={() => setActiveTab("album")}
              titleStyle={{ flex: 1, display: !isDesktop ? "none" : "block" }}
            >
              {me.id !== props.user.id &&
                album &&
                album?.pictures.length > 0 && (
                  <AskAlbumButton
                    onClick={() => {
                      if (!isLoading) askQuickshareHandler();
                    }}
                  >
                    {isLoading ? (
                      <Loader size={20} />
                    ) : (
                      <>{locale("quickshare.ask.button")}</>
                    )}
                  </AskAlbumButton>
                )}
              {album && (
                <PicturesDisplay
                  pictures={album?.pictures ?? []}
                  albumAvailable={album.available || me.id === props.user.id}
                />
              )}
            </Tab>
          </Tabs>
        </Flex>
      </InfosContainer>
      {isDesktop ? (
        <ActionsMenu visible={actionsVisible} ref={actionsMenuRef}>
          <ActionsItem
            onClick={() =>
              dispatch(
                ModalSlice.actions.setSubmitReport({
                  visible: true,
                  type: "user",
                  key: props.user?.id,
                })
              )
            }
            style={{ marginBottom: 16 }}
          >
            <p>{`${locale("report.general")} ${props.user.pseudo}`}</p>
          </ActionsItem>
          <ActionsItem
            onClick={() =>
              dispatch(
                ModalSlice.actions.setBlockUser({
                  visible: true,
                  userId: props.user?.id ?? null,
                })
              )
            }
          >
            <p>{`${locale("block.general")} ${props.user.pseudo}`}</p>
          </ActionsItem>
        </ActionsMenu>
      ) : null}
      {activeTab === "infos" && <></>}
      {activeTab === "videos" && userVideos.length > 0 && (
        <>
          {userVideos.map((userVideo, index) => (
            <ProfileVideo key={index} user={props.user} video={userVideo} />
          ))}
        </>
      )}
      {activeTab === "album" && <></>}
      <div style={{ height: 50 }} />
    </Container>
  );
};

export default ProfileInformations;

const Container = styled.div<{ isScreen: boolean }>`
  position: relative;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0 12px 42px;
  border-radius: 0 16px 16px 0;
  background-color: ${colors.dark};
  box-sizing: border-box;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    width: 100%;
    height: fit-content;
    overflow-y: initial;
    top: 500px;
    padding: 0 12px 60px 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #15191fb2;
    backdrop-filter: blur(12px);
  }
`;

const UserInfo = styled.div<{ hasCarousel: boolean }>`
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  padding: 0 0 19px 6px;
  box-sizing: border-box;

  & > p.age {
    margin: 0;
    font-size: 54px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
  }
`;

const PremiumSoonIconsContainer = styled.div`
  display: flex;
  gap: 6px;
  position: absolute;
  bottom: 20px;
`;

const BackButton = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  padding: 10px;
  cursor: pointer;
`;

const PremiumVisitCanceller = styled.div<{ hasSeveralPictures: boolean }>`
  cursor: pointer;
  position: absolute;
  // top: -30px;
  bottom: 20px;
  padding: 4px;
  background-color: #1fe0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  right: 16px;
  z-index: 1003;

  display: grid;
  place-items: center;
  text-align: center;

  & > .timer {
    position: absolute;
    margin: 0;
    color: #f1f1f1;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 2px #000000;
  }
`;

const PremiumVisitCancellerText = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-colorPrimary {
      color: ${colors.backgroundDark};
    }
  }
`;

const PseudoDistance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 6px;
  margin-top: 14px;
  color: ${colors.white};

  & > p.distance {
    margin: 0;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 16px;
    color: ${colors.white};
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Carousel = styled.div`
  background-color: #000000;
  box-sizing: border-box;
  width: 100vw;
  max-height: 93px;
  overflow-y: hidden;
  transform: translateX(-12px);
`;

const PicturesSelecor = styled.div`
  position: relative;
`;

const PicturesWrapperInfos = styled.div<{ picturesLength: number }>`
  margin-top: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #71727633;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-auto-rows: minmax(110px, auto);
  gap: 10px;

  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 1000px) {
    height: 93px;
    grid-template-columns: repeat(
      ${({ picturesLength }) => picturesLength || "1"},
      93px
    );
  }
`;

const PicturesWrapper = styled.div<{ picturesLength: number }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(
    ${({ picturesLength }) => picturesLength || "1"},
    80px
  );
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 1000px) {
    height: 93px;
    grid-template-columns: repeat(
      ${({ picturesLength }) => picturesLength || "1"},
      93px
    );
  }
`;

const CarouselArrow = styled.div<{ side: "left" | "right" }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 56px;
  height: 56px;
  background-color: ${colors.white};
  cursor: pointer;
  z-index: 2;
  top: calc(50% - 28px);
  margin-right: 14px;
  border-radius: 50%;

  ${(p) =>
    p.side === "right" &&
    `
      right: 0px;
    `}
  ${(p) =>
    p.side === "left" &&
    `
      left: 0px;
    `}

@media (max-width: 1000px) {
    ${({ side }) =>
      side === "right" ? "margin-right: 12px" : "margin-left: 12px"};
  }
`;

const PictureSelect = styled.div`
  cursor: pointer;
  opacity: 0.95;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    width: 93px;
    height: 93px;
  }
`;

const Title = styled.p`
  margin: 0 13px 6px 0;
  color: ${colors.white};
  font-size: 14px;
  font-family: ${fonts.semiBold.name};
  font-weight: 600;
  flex: 1;
`;

const InfosContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  width: 244px;
  top: 60px;
  right: 16px;
  padding: 12px 16px 12px 16px;
  background-color: ${colors.darkGray};
  border: solid 1px ${colors.grayBorder};
  z-index: 1;
  border-radius: 8px;

  @media (max-width: 1000px) {
    top: 40px;
    right: 0px;
  }
`;

const ActionsItem = styled.div`
  cursor: pointer;
  & > p {
    margin: 0;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 12px;
    color: ${colors.white};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;
  border-bottom: 1px solid #71727633;
  & > p.title {
    margin: 0 13px 6px 0;
    color: ${colors.white};
    font-size: 14px;
    font-family: ${fonts.semiBold.name};
    font-weight: 600;
    text-align: right;
    flex: 1;
  }

  & > p.value {
    margin: 0;
    color: ${colors.white};
    font-size: 12px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    flex: 1;
  }
`;

const AskAlbumButton = styled.button`
  width: 100%;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  font-size: 14px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  padding: 10px 0;
  outline: none;
  border: none;
  border-radius: 8px;
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: ${colors.darkBlue};
  }
`;

const Iframe = styled.iframe<{
  credentialless?: boolean;
}>`
  border: none;
  margin: 0;
  box-sizing: border-box;
  height: 233px;
  border-radius: 4px;
  width: 100%;
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) =>
    props.color === "#000000" ? "transparent" : props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  margin-right: 6px;
`;

const PersonalNoteOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 100;
`;

const PersonalNoteContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: ${colors.darkGray};
  border: 1px solid ${colors.grayBorder};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 350px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 95%;
  }
`;
