import React, { createContext, useState, ReactNode } from 'react';
import { BillingParams } from '../pages/messages/types/billing-params.type';

interface BillingParamsType {
  billingParams: BillingParams;
  setBillingParams: (billingParams: BillingParams) => void;
}

export const BillingParamsContext = createContext({} as BillingParamsType);

interface ModalProviderProps {
  children: ReactNode;
}

export const BillingParamsProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [billingParams, setBillingParams] = useState<BillingParams>();

  return (
    <BillingParamsContext.Provider value={{ billingParams, setBillingParams }}>
      {children}
    </BillingParamsContext.Provider>
  );
};
