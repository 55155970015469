import { createSlice } from "@reduxjs/toolkit";

const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    messagesNotSeen: {},
    scrollToBottom: false,
    overlayVisible: false,
    mapLocation: {
      visible: false,
      latitude: null,
      longitude: null,
    },
    openedConversation: null,
  },
  reducers: {
    setMessagesNotSeen: (state, { payload }) => {
      return {
        ...state,
        messagesNotSeen: payload,
      };
    },
    setScrollToBottom: (state, { payload }) => {
      return {
        ...state,
        scrollToBottom: payload,
      };
    },
    setMapLocation: (
      state,
      {
        payload,
      }: { payload: { visible: boolean; latitude: number; longitude: number } }
    ) => {
      return {
        ...state,
        mapLocation: {
          visible: payload.visible,
          latitude: payload.latitude,
          longitude: payload.longitude,
        },
      };
    },
    setOverlayVisibility: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        overlayVisible: payload,
      };
    },
    setOpenedConversation: (state, { payload }) => {
      return {
        ...state,
        openedConversation: payload,
      };
    },
  },
});

export default ChatSlice;
