import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';
import { Dispatch, SetStateAction } from 'react';
import useLocale from '../../utils/locale/locale.hook';
import { Flex } from '../style/flex.component';

interface Props {
  setSelectedWoofCategory: Dispatch<SetStateAction<string>>;
  selectedWoofCategory: string;
  woofCategory: string;
  img: string;
  phraseKey: string;
}
export const WoofItem = (props: Props) => {
  const { setSelectedWoofCategory, selectedWoofCategory, img, phraseKey, woofCategory } = props;
  const locale = useLocale();

  return (
    <WoofButton onClick={() => setSelectedWoofCategory(woofCategory)} active={selectedWoofCategory === woofCategory}>
      <Flex alignItems="center" gap="16px" style={{ marginRight: 8 }}>
        <img src={img} alt="Woof icon" width={36} height={36} loading="lazy" />
        <p>{locale(phraseKey)}</p>
      </Flex>
      <img src="/assets/icons/nearby/chevron-right.svg" alt="Arrow right" width={16} height={16} loading="lazy" />
    </WoofButton>
  );
};

const WoofButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 16px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.darkGray};
  border-bottom: 1px solid ${colors.graySeparator};
  & > p {
    margin: 0;
    font-size: 14px;
    color: ${({ active }) => (active ? colors.white : colors.darkerGrey)};
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    margin-left: 12px;
  }
`;

const RadioButtonContainer = styled.div<{ active: boolean }>`
  width: 15px;
  height: 15px;
  background-color: ${colors.white};
  border: 1px solid ${({ active }) => (active ? colors.darkOrange1 : colors.lightGrey9)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const RadioButtonInside = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  background-color: ${colors.darkOrange1};
  display: ${({ active }) => (active ? 'block' : 'none')};
`;
