import { createSlice } from "@reduxjs/toolkit";
import { initWebsocketConnection } from "../../utils/websocket.utils";
import { store } from "../configure.store";

const WebsocketSlice = createSlice({
    name: "websocket",
    initialState: {
        ws: null
    },
    reducers: {
        setWebsocket: (state, { payload }) => {
            return {
                ws: payload
            }
        },
        resetWebsocket: (state) => {
            return {
                ws: null
            }
        }
    }
});

export default WebsocketSlice;