import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';

import VideoActions from './components/video-actions.component';
import Button from '../../components/forms/button.component';

import { getVideoCall, leaveVideoCall } from '../../services/video-call.service';
import ModalSlice from '../../store/slices/modal.slice';
import { getWebsocketConnection } from '../../utils/websocket.utils';
import useLocale from '../../utils/locale/locale.hook';

import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';

import { VideoCall } from './types/video-call.interface';
import VideoCallUsers from './video-call.users';
import TwilioVideoCall from './twilio-video-call';
import Text from '../../components/style/text.component';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

enum CallStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  JOINED = 'JOINED',
  ENDED = 'ENDED',
  NON_PREMIUM_LIMIT_REACHED = 'NON_PREMIUM_LIMIT_REACHED',
}

interface ModalProps {
  visible: boolean;
}

let joinTimeout = null;

export default function VideoCallModal({ visible }: ModalProps) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { videoCall } = useSelector((state: any) => state.modalStore);

  const [call, setCall] = useState<VideoCall>(null);
  const [callStatus, setCallStatus] = useState(CallStatus.LOADING);
  const [endReasonMessage, setEndReasonMessage] = useState('');
  // utile pour les contraintes non-premiums
  const [timeLeft, setTimeLeft] = useState<number>(null);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        closeModalHandler();
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const leaveCallHandler = () => {
    leaveVideoCall(call.id).then((res) => {
      if (res?.success) {
        dispatch(
          ModalSlice.actions.setVideoCall({
            visible: false,
            callId: null,
            timeRemaining: null,
            token: null,
          })
        );
      }
    });
  };

  const closeModalHandler = () => {
    dispatch(
      ModalSlice.actions.setVideoCall({
        visible: false,
        callId: null,
        timeRemaining: null,
        token: null,
      })
    );
  };

  useEffect(() => {
    if (visible && !!videoCall.callId) {
      getVideoCall(videoCall.callId).then((res) => {
        if (!res.error && !res.message) {
          setCall(res);
          setCallStatus(CallStatus.JOINED);
        } else {
          setCallStatus(CallStatus.ERROR);
        }
      });
    }
  }, [visible, videoCall]);

  useEffect(() => {
    let interval = null;
    if (visible && !!videoCall.timeRemaining) {
      setTimeLeft(videoCall.timeRemaining);
      interval = setInterval(() => {
        setTimeLeft((current) => {
          if (current <= 0) {
            clearInterval(interval);
            return 0;
          }
          return current - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [visible, videoCall]);

  useEffect(() => {
    const triggerNonPremiumLeaveHandler = () => {
      leaveVideoCall(call.id).then((res) => {
        if (res?.success) {
          setCallStatus(CallStatus.NON_PREMIUM_LIMIT_REACHED);
        }
      });
    };

    if (timeLeft === 0 && callStatus === CallStatus.JOINED) {
      triggerNonPremiumLeaveHandler();
    }
  }, [timeLeft, callStatus, call]);

  useEffect(() => {
    if (!visible) {
      setCallStatus(CallStatus.LOADING);
      setCall(null);
      setEndReasonMessage('');
      setTimeLeft(null);
    }
    return () => clearTimeout(joinTimeout);
  }, [visible]);

  useEffect(() => {
    const onWSMessage = (e: any) => {
      const data = JSON.parse(e.data);

      if (data.callId !== call.id) {
        return;
      }

      switch (data.type) {
        case 'video_call_stop':
          setCallStatus(CallStatus.ENDED);
          setEndReasonMessage((data.reasonPhraseKey ? locale(data.reasonPhraseKey) : data.reason) || '');
          break;
        case 'video_call_join':
          joinTimeout = setTimeout(() => {
            setCall((prevCall) => {
              if (prevCall === null) {
                return null;
              }

              const newCall = {
                ...prevCall,
              };

              const participantsUpdated = [...prevCall.participants];
              const participantIndex = participantsUpdated.findIndex(
                (participant) => participant.user.id === data.userId
              );

              if (participantIndex > -1) {
                const updatedParticipant = {
                  ...participantsUpdated[participantIndex],
                };
                updatedParticipant.status = 'joined';
                participantsUpdated[participantIndex] = updatedParticipant;
                newCall.participants = participantsUpdated;
              }

              return newCall;
            });
          }, 1000);
          break;
        case 'video_call_leave':
          // !! NOTE : code à faire ici lorsqu'on souhaitera faire des calls à plusieurs
          break;
        case 'video_call_refuse':
          // !! NOTE : code à faire ici lorsqu'on souhaitera faire des calls à plusieurs
          break;
        default:
          break;
      }
    };

    const ws = getWebsocketConnection();
    if (!!call && !!ws) {
      ws.removeEventListener('message', onWSMessage);
      ws.addEventListener('message', onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onWSMessage);
      }
    };
  }, [call, locale]);

  if (!visible) {
    return null;
  }

  return (
    <ModalWrapper windowHeight={!isDesktop && windowHeight}>
      <Header>
        {isDesktop ? (
          <img alt="logo" src="/assets/icons/logo/logo-white-with-text.svg" />
        ) : (
          <BackButtonContainer onClick={leaveCallHandler}>
            <img alt="logo" src="/assets/icons/messages/arrow-left-white.svg" />
            <Text style={{ color: colors.white }}>Terminer l'appel</Text>
          </BackButtonContainer>
        )}
      </Header>
      {callStatus === CallStatus.JOINED && <TwilioVideoCall call={call} />}
      {callStatus === CallStatus.ENDED && (
        <>
          <DisclaimerWrapper>
            {endReasonMessage || locale('video_call.events.call_ended')}
            <Button onClick={closeModalHandler} text={locale('video_call.actions.leave_call')} />
          </DisclaimerWrapper>
        </>
      )}
      {callStatus === CallStatus.ERROR && (
        <>
          <DisclaimerWrapper>
            {locale('video_call.errors.something_wrong')}
            <Button onClick={closeModalHandler} text={locale('video_call.actions.leave_call')} />
          </DisclaimerWrapper>
        </>
      )}
      {callStatus === CallStatus.NON_PREMIUM_LIMIT_REACHED && (
        <>
          <DisclaimerWrapper>
            {locale('video_call.errors.non_premium_limit')}
            <Button onClick={closeModalHandler} text={locale('video_call.actions.leave_call')} />
          </DisclaimerWrapper>
        </>
      )}
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div<{ windowHeight?: number }>`
  background-image: linear-gradient(to bottom, #0c0f16, #07090c);
  z-index: 5000;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  height: ${({ windowHeight }) => (windowHeight ? `${windowHeight}px` : '100vh')};
  box-sizing: border-box;
  padding: 0 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const Header = styled.div`
  @media (min-width: 1000px) {
    margin-top: 30px;
    height: 50px;

    & > img {
      height: 100%;
      width: auto;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 35px;
  }
`;

const LogoWrapper = styled.div`
  margin-top: 30px;
  height: 50px;

  & > img {
    height: 100%;
    width: auto;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  gap: 8px;
  padding: 10px;
  cursor: pointer;

  & > img {
    height: 16px;
    width: 16px;
    width: auto;
  }
`;

const DisclaimerWrapper = styled.div`
  flex: 1;
  width: 100%;
  place-items: center;
  font-size: 36px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.lightGrey3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @media (max-width: 1000px) {
    font-size: 18px;
    padding: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
  }
`;
