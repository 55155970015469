import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import Dropdown from '../../../../components/forms/dropdown.component';
import { Subtitle } from '../../../../components/settings/subtitle.component';
import Text from '../../../../components/style/text.component';

import { getAppearances, getEthnicities, getfacialHair } from '../../../../services/caracteristics.service';
import { updateUser } from '../../../../services/users.service';
import useLocale from '../../../../utils/locale/locale.hook';
import Button from '../../../../components/forms-v2/button.component';
import colors from '../../../../themes/colors-v2.theme';
import { useDebounce } from 'use-debounce';
import { useMediaQuery } from 'react-responsive';
import { Flex } from '../../../../components/style/flex.component';

type CaracteristicsProps = {
  me?: any;
  onChange?: () => void;
  characteristic: 'ethnicity' | 'facial-hair' | 'pilosity';
};

const Caracteristics = (props: CaracteristicsProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [facialHairDropdown, setFacialHairDropdown] = useState(null);
  const [appearancesDropdown, setAppearancesDropdown] = useState(null);
  const [ethnicitiesDropdown, setEthnicitiesDropdown] = useState(null);
  const [ethnicity, setEthnicity] = useState(props?.me.ethnicity?.ethnicityKey);
  const [facialHair, setFacialHair] = useState(props?.me.facialHair?.facialHairKey);
  const [appearance, setAppearance] = useState(props?.me.appearance?.appearanceKey);
  const [debouncedEthnicity] = useDebounce(ethnicity, 500);
  const [debouncedFacialHair] = useDebounce(facialHair, 500);
  const [debouncedAppearance] = useDebounce(appearance, 500);

  const [editOpen, setEditOpen] = useState(false);
  const keys = useSelector((state: any) => state.localeStore.keys);

  const getData = async () => {
    const appearances = await getAppearances();
    const resAppearances = appearances.map((appearance) => {
      return {
        label: locale('appearance.name.' + appearance.appearanceKey),
        value: appearance.appearanceKey,
      };
    });
    setAppearancesDropdown(resAppearances);

    const ethnicities = await getEthnicities();
    const resEthnicities = ethnicities.map((ethnicity) => {
      return {
        label: locale('ethnicity.name.' + ethnicity.ethnicityKey),
        value: ethnicity.ethnicityKey,
      };
    });
    setEthnicitiesDropdown(resEthnicities);

    const facialHair = await getfacialHair();
    const resFacialHair = facialHair.map((facialHair) => {
      return {
        label: locale('facial_hair.name.' + facialHair.facialHairKey),
        value: facialHair.facialHairKey,
      };
    });
    setFacialHairDropdown(resFacialHair);
  };

  useEffect(() => {
    if (keys) {
      getData();
    }
  }, [keys]);

  const onSelectEthnicity = async (value) => {
    setEthnicity(value);
  };

  const onSelectAppearances = async (value) => {
    setAppearance(value);
  };

  const onSelectFacialHair = async (value) => {
    setFacialHair(value);
  };

  async function updateEthnicity() {
    await updateUser({
      ethnicity: {
        ethnicityKey: debouncedEthnicity,
      },
    });
    props?.onChange?.();
  }

  async function udpateFacialHair() {
    updateUser({
      facialHair: {
        facialHairKey: debouncedFacialHair,
      },
    });
    props?.onChange?.();
  }

  async function updateAppearance() {
    updateUser({
      appearance: {
        appearanceKey: debouncedAppearance,
      },
    });
    props?.onChange?.();
  }

  useEffect(() => {
    if (debouncedEthnicity !== props.me.ethnicity?.ethnicityKey) {
      updateEthnicity();
    }
  }, [debouncedEthnicity]);

  useEffect(() => {
    if (debouncedFacialHair !== props.me.facialHair?.facialHairKey) {
      udpateFacialHair();
    }
  }, [debouncedFacialHair]);

  useEffect(() => {
    if (debouncedAppearance !== props.me.appearance?.appearanceKey) {
      updateAppearance();
    }
  }, [debouncedAppearance]);

  const findCharacteristicLabel = (charac: 'ethnicity' | 'facial-hair' | 'pilosity') => {
    if (charac === 'ethnicity' && ethnicity) {
      return ethnicitiesDropdown.find((e) => e.value === ethnicity).label;
    }
    if (charac === 'facial-hair' && facialHair) {
      return facialHairDropdown.find((e) => e.value === facialHair).label;
    }
    if (charac === 'pilosity' && appearance) {
      return appearancesDropdown.find((e) => e.value === appearance).label;
    }
  };

  return (
    <Flex>
      <Subtitle style={{ paddingBottom: isDesktop ? 0 : 8, flex: 1 }}>
        {props.characteristic === 'ethnicity'
          ? locale('global.ethnicity')
          : props.characteristic === 'facial-hair'
          ? locale('global.facial_hair')
          : locale('global.type')}
      </Subtitle>
      <div style={{ flex: 2 }}>
        {props.characteristic === 'ethnicity' && (
          <div>
            {ethnicitiesDropdown && (
              <Inline>
                <Dropdown
                  onSelect={(value) => onSelectEthnicity(value)}
                  lines={ethnicitiesDropdown}
                  value={ethnicity}
                  style={{
                    width: '100%',
                    zoom: 1,
                    height: 38,
                    boxSizing: 'border-box',
                  }}
                  fontSize={14}
                />
              </Inline>
            )}
          </div>
        )}
        {props.characteristic === 'pilosity' && (
          <div>
            {appearancesDropdown && (
              <Dropdown
                onSelect={(value) => onSelectAppearances(value)}
                lines={appearancesDropdown}
                value={appearance}
                style={{
                  width: '100%',
                  zoom: 1,
                  height: 38,
                  boxSizing: 'border-box',
                }}
                fontSize={14}
              />
            )}
          </div>
        )}
        {props.characteristic === 'facial-hair' && (
          <div>
            {facialHairDropdown && (
              <Inline>
                <Dropdown
                  onSelect={(value) => onSelectFacialHair(value)}
                  lines={facialHairDropdown}
                  value={facialHair}
                  style={{
                    width: '100%',
                    zoom: 1,
                    height: 38,
                    boxSizing: 'border-box',
                  }}
                  fontSize={14}
                />
              </Inline>
            )}
          </div>
        )}
      </div>
    </Flex>
  );
};

const Line = styled.div<{ editOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;

  @media (max-width: 1000px) {
    ${({ editOpen }) => editOpen && 'padding-bottom: 12px'};
  }
`;

const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  // @media (max-width: 1000px) {
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
`;

export default Caracteristics;
