import { post, del, get } from './utils.service';

export const getUserSubscriptionState = async () => {
  const res = await get('/subscriptions');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserBillingHistory = async () => {
  const res = await get('/subscriptions/billing-history');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const subscribeWithAccessCode = async (accessCode: string) => {
  const res = await post('/subscriptions', {
    method: 'PROMOTIONAL_CODE',
    code: accessCode,
  });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const generateBillingParams = async (body: any) => {
  const res = await post('/subscriptions/generate-billing-params', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getMobiyoParams = async () => {
  const res = await get('/subscriptions/generate-mobiyo-metadata');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getWebOffers = async () => {
  const res = await get('/subscriptions/web-offers');
  const payload = await res.json();
  return payload.data;
};

export const generateAnnulationParams = async () => {
  const res = await del('/subscriptions');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getPrice = async (column: string) => {
  const res = await get(`/subscriptions/prices/${column}`)
  const payload = await res.json();
  return payload.data;
};
