import { useState, useEffect, useRef, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { getNearbyCategory } from "../../services/nearby.service";
import { useLogin } from "../../utils/auth.utils";
import { getQueryFilters } from "./filters.utils";

import colors from "../../themes/colors-v2.theme";
import ScreenWrapper from "../../components/style/screen-wrapper.component";
import { Loader } from "../../components/style/loader.component";

import useLocale from "../../utils/locale/locale.hook";
import NavbarSlice from "../../store/slices/navbar.slice";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import MetaData from "../../components/seo/meta-data.component";
import { PrimaryButton } from "../../components/forms/primaryButton.component";

import { Flex } from "../../components/style/flex.component";
import {
  getCommunities,
  getSexualPractices,
  getSexualRole,
} from "../../services/caracteristics.service";
import { useOnClickOutside } from "../../utils/clickOutside.utils";
import { Divider } from "../../components/miscellaneous/divider.component";
import { useScrollDisplayArrows } from "../../utils/useScroll";
import { QuickFiltersSidebar } from "../nearby/components/filters/quick-filters-sidebar.component";
import { RightSidebarAdvancedFilters } from "../nearby/components/filters/right-sidebar-filters.component";
import { useFiltersCount } from "../nearby/components/filters/useHasFilters";
import NoGeolocationPermission from "../nearby/components/no-geolocation-permission.component";
import { CustomGrid } from "../nearby/components/custom-grid.component";
import Headers from "../nearby/components/headers.component";
import { getMyTravels } from "../../services/travel.service";
import {
  City,
  getMyCities,
  getPopularCities,
} from "../../services/city.service";
import MeSlice from "../../store/slices/me.slice";
import ModalTrips from "./components/modal-trips.component";
import MapExploreV2 from "../../components/explore/map-explorev2.component";
import {
  debouncedCitySearch,
  getCityFromPosition,
} from "../../utils/search.utils";
import { useOpenStreetMapsContext } from "../../context/openstreetmaps";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { useRedirectUnsignedUser } from "../../utils/useRedirectUnsignedUser";
import { useHistory } from "react-router-dom";

export type FiltersType = {
  status?: string[];
  withProfilePictureOnly?: boolean;
  isNew?: boolean;
  ageMin?: number;
  ageMax?: number;
  heightMin?: number;
  heightMax?: number;
  weightMin?: number;
  weightMax?: number;
  sexualRole?: string[];
  communities?: any[];
  lookingFor?: any[];
  sexualPractices?: any[];
  ethnicities?: any[];
  facialHair?: any[];
  appearance?: any[];
  languages?: any[];
  lat?: number;
  lng?: number;
  withVideos?: boolean;
  isMeetNow?: boolean;
};

export type CellType = "rectangle" | "square" | "bigSquare";

export type Coordinates = {
  latitude: number;
  longitude: number;
};
export type TabName = "popularCities" | "myCities" | "trips" | "exploreMap";
export type ExploreTabs =
  | "location"
  | "travellingNow"
  | "travellingSoon"
  | "searchCity";

const DEFAULT_PARIS_COORDINATES = {
  latitude: 48.866667,
  longitude: 2.333333,
};

export interface Travel {
  uid: string;
  startDate: Date;
  endDate: Date;
  creationDate: Date;
  city: City;
}

const ExploreScreenv2 = () => {
  useRedirectUnsignedUser();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { isLogin, me } = useLogin();
  const dispatch = useDispatch();
  const locale = useLocale();
  const locationInputRef = useRef(null);
  const history = useHistory();

  const { exploreLatitude, exploreLongitude } = useSelector(
    (state: any) => state.meStore
  );

  const mapProvider = useOpenStreetMapsContext();

  const [users, setUsers] = useState<{ users: any[] }>({ users: [] });
  const [filters, setFilters] = useState<FiltersType>({ status: ["online"] });
  const [areFiltersDeactivated, setAreFiltersDeactivated] = useState(false);
  const [displayGeoloPerm, setDisplayGeoloPerm] = useState(false);
  const [cellType, setCellType] = useState<CellType>("rectangle");
  const [hasMore, setHasMore] = useState(true);
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult>();
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [communityChoiceOpen, setCommunityChoiceOpen] = useState(false);
  const [sexualRoleOpen, setSexualRoleOpen] = useState(false);
  const [sexualPracticesOpen, setSexualPracticesOpen] = useState(false);
  const [currentCity, setCurrentCity] = useState<City>(null);

  const [popularCities, setPopularCities] = useState(true);
  const [cities, setCities] = useState(null);
  const [hasMyCitiesData, setHasMyCitiesData] = useState(false);
  const [location, setLocation] = useState<string>("");
  const [myTrips, setMyTrips] = useState([]);
  const [mapOpen, setMapOpen] = useState<boolean>(true);
  const [headerTab, setHeaderTab] = useState<ExploreTabs>("location");
  const [tripsOpen, setTripsOpen] = useState<boolean>(false);
  const [isModifyingTrip, setIsModifyingTrip] = useState(false);
  const [trip, setTrip] = useState<Travel>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<
    SearchResult<RawResult>[]
  >([]);
  const [currentCityName, setCurrentCityName] = useState("");

  const isLoadingRef = useRef(false);
  const filtersRef = useRef(null);

  const quickFiltersRef = useRef(null);
  const navbarStore = useSelector((state: any) => state.navbarStore);
  const filtersCount = useFiltersCount(filters as any);

  const {
    displayLeftArrow,
    displayRightArrow,
    handleScroll,
    handleArrowClick,
  } = useScrollDisplayArrows(filtersRef);

  const debouncedSearch = useRef(
    debouncedCitySearch(mapProvider, setAddressAutocomplete)
  ).current;

  const handleAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setAddressAutocomplete([]);
    handleRefresh();
  };

  const loadMoreProfiles = async (headerTab, refresh) => {
    if (headerTab === "location" && (!exploreLatitude || !exploreLongitude)) {
      return;
    }
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    if (refresh) {
      setUsers({ users: [] });
    }

    const query: any = getQueryFilters(areFiltersDeactivated ? {} : filters);

    query.limit = 100;
    query.page = refresh ? 0 : users.users.length / query.limit;
    const position = getMapPosition();
    query.latitude = position.lat;
    query.longitude = position.lng;
    query.isFromExplore = true;
    const data = filters.isNew
      ? await getNearbyCategory("newFaces", query)
      : await getNearbyCategory("aroundMe", query);
    setUsers((_users) => ({
      users: [...(refresh ? [] : _users.users), ...data.users],
    }));
    setHasMore(data.users?.length === query.limit);

    isLoadingRef.current = false;
  };

  const getPermission = async () => {
    // await getLocation((res) => setCoords(res), setDisplayGeoloPerm);
  };

  const refresh = () => {
    setUsers({ users: [] });
    loadMoreProfiles("location", true);
  };

  useEffect(() => {
    if (filters !== null) {
      setInfiniteScrollKey(Math.random());
      loadMoreProfiles(headerTab, true);
    }
  }, [filters]);

  useEffect(() => {
    if (exploreLatitude && exploreLongitude) {
      setInfiniteScrollKey(Math.random());
    }
    loadMoreProfiles(headerTab, true);
  }, [
    exploreLatitude,
    exploreLongitude,
    headerTab,
    currentCity,
    selectedAddress,
  ]);

  useEffect(() => {
    refresh();
  }, [areFiltersDeactivated]);

  useEffect(() => {
    if (!isDesktop) dispatch(NavbarSlice.actions.setFiltersOpen("close"));
  }, [isDesktop]);

  useEffect(() => {
    getPermission();
  }, []);

  useOnClickOutside([quickFiltersRef], () => {
    if (isDesktop) {
      setCommunityChoiceOpen(false);
      setSexualPracticesOpen(false);
      setSexualRoleOpen(false);
    }
  });

  const handleNewExploredCity = (city: City) => {
    if (typeof city.latitude !== "number")
      city.latitude = parseFloat(city.latitude);
    if (typeof city.longitude !== "number")
      city.longitude = parseFloat(city.longitude);
    setCurrentCity(city);
    setLocation(city.nameKey);
    dispatch(
      MeSlice.actions.updateMe({
        exploreLatitude: city.latitude,
        exploreLongitude: city.longitude,
      })
    );
  };

  const getDataCities = async () => {
    const popCityData = await getPopularCities();
    setPopularCities(popCityData);
    const query = {
      limit: 10, // DEBUG: pagination with scroll!
      page: 0, // DEBUG: pagination with scroll!
      random: false,
      popular: false,
      myCities: true,
    };
    const myCitiesData = await getMyCities(query);
    setCities(myCitiesData);
    // The default explored city is the first of the user's list
    // of cities or the first one of the random list of popular cities sent by the API
    if (myCitiesData.length > 0) {
      handleNewExploredCity(myCitiesData[0]);
      setHasMyCitiesData(true);
    } else {
      handleNewExploredCity(popCityData[0]);
      setHasMyCitiesData(false);
    }
  };

  const getMapPosition = useCallback(() => {
    if (selectedAddress?.raw?.lat && selectedAddress?.raw?.lon) {
      return {
        lat: parseFloat(selectedAddress.raw.lat),
        lng: parseFloat(selectedAddress.raw.lon),
      };
    } else {
      if (isLogin) {
        return { lat: me.latitude, lng: me.longitude };
      }
    }
    return {
      lat: DEFAULT_PARIS_COORDINATES.latitude,
      lng: DEFAULT_PARIS_COORDINATES.longitude,
    };
  }, [selectedAddress?.raw, me.latitude, me.longitude]);

  const handleRedirectTrips = () => {
    history.push({
      pathname: "/trips",
      state: { tab: "myTrips" },
    });
  };

  const handleRefresh = async () => {
    getDataCities();
    getDataTrips();
  };

  const getDataTrips = async () => {
    const query = {
      limit: 50,
      page: 0,
    };
    const tripsData = await getMyTravels(query);
    setMyTrips(tripsData);
  };

  useEffect(() => {
    getDataCities();
  }, []);

  useEffect(() => {
    getDataTrips();
  }, []);

  useEffect(() => {
    async function fetchCityName() {
      const position = getMapPosition();
      setCurrentCityName(await getCityFromPosition(position.lat, position.lng));
    }
    fetchCityName();
  }, [
    selectedAddress?.raw?.lat,
    selectedAddress?.raw?.lng,
    me.longitude,
    me.latitude,
  ]);

  if (!isLogin) {
    return <Loader />;
  }

  return (
    <ScreenWrapper style={{ padding: 0 }}>
      {displayGeoloPerm && (
        <NoGeolocationPermission onDone={() => setDisplayGeoloPerm(false)} />
      )}
      <MetaData
        title={locale("app_name")}
        description={locale("signin.landing.description1")}
      />

      {mapOpen && (
        <MapSearchContainer>
          {!isDesktop ? (
            <ModalTrips
              isOpen={tripsOpen}
              onClose={() => {
                setTripsOpen(false);
                setIsModifyingTrip(false);
              }}
              refresh={handleRefresh}
              isModifyingTrip={isModifyingTrip}
              setIsModifyingTrip={setIsModifyingTrip}
              trip={trip}
              setTrip={setTrip}
              getDataTrips={getDataTrips}
            />
          ) : null}
          <Flex
            justify={isDesktop ? "flex-start" : "space-around"}
            style={{
              position: "absolute",
              top: 20,
              left: isDesktop ? 10 : 0,
              width: "100%",
            }}
          >
            <SearchInputContainer>
              <ResetLocationSearch
                width="9px"
                height="13.33px"
                src="/assets/icons/sidebar/navbar/explore-gray.svg"
              />
              <LocationTextInput
                placeholder={
                  selectedAddress?.raw?.address?.city
                    ? selectedAddress.raw.address.city
                    : currentCityName
                    ? currentCityName
                    : locale("guide.form.city.label")
                }
                onChange={(e) => debouncedSearch(e.target.value)}
                ref={locationInputRef}
              />
              {addressAutocomplete.length > 0 && (
                <Autocomplete>
                  {addressAutocomplete.slice(0, 5).map((address, index) => (
                    <Line
                      key={`${address.label}-${index}`}
                      onClick={() => handleAddress(address)}
                    >
                      {address.label}
                    </Line>
                  ))}
                </Autocomplete>
              )}
            </SearchInputContainer>

            {!isDesktop ? (
              <PrimaryButton
                size="medium"
                style={{
                  borderColor: "white",
                  width: 36,
                  height: 36,
                  zIndex: 500,
                  boxSizing: "border-box",
                }}
                onClick={history.goBack}
              >
                <img
                  alt="cross"
                  src="/assets/icons/cross-outlined-white.svg"
                  width={16}
                  height={16}
                />
              </PrimaryButton>
            ) : null}
          </Flex>
          {isDesktop ? (
            <div
              style={{
                position: "absolute",
                top: "53%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                zIndex: 500,
              }}
            >
              <PrimaryButton isActive onClick={() => setMapOpen(false)}>
                {locale("explore.browse.profiles")}
              </PrimaryButton>
            </div>
          ) : (
            <Flex
              direction="column"
              alignItems="center"
              gap="4px"
              style={{
                position: "absolute",
                bottom: "10%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                zIndex: 500,
                width: "100%",
              }}
            >
              <Flex alignItems="center" gap="2px" style={{ width: "90%" }}>
                <PrimaryButton
                  size="large"
                  isActive
                  onClick={() => setMapOpen(false)}
                  style={{
                    width: "100%",
                    height: 38,
                    border: "none",
                    fontSize: 14,
                  }}
                >
                  {locale("explore.browse.profiles")}
                </PrimaryButton>
                <PrimaryButton
                  size="large"
                  isActive
                  onClick={() => setTripsOpen(true)}
                  style={{
                    width: "100%",
                    height: 38,
                    border: "none",
                    fontSize: 14,
                  }}
                >
                  {locale("travel_create_action")}
                </PrimaryButton>
              </Flex>
              {myTrips.length > 0 ? (
                <PrimaryButton
                  size="large"
                  onClick={handleRedirectTrips}
                  style={{
                    background: "white",
                    color: "black",
                    width: "90%",
                    height: 38,
                    border: "none",
                    fontSize: 14,
                  }}
                >
                  {locale("explore.manage.trips")}&nbsp;({myTrips.length})
                </PrimaryButton>
              ) : null}
            </Flex>
          )}
          <MapExploreV2
            exploreLatitude={selectedAddress?.raw.lat}
            exploreLongitude={selectedAddress?.raw.lon}
            setSelectedAddress={setSelectedAddress}
          ></MapExploreV2>
        </MapSearchContainer>
      )}
      {users && !mapOpen && (
        <MainWrapper>
          <div ref={quickFiltersRef}>
            {isDesktop && (
              <ModalTrips
                isOpen={tripsOpen}
                onClose={() => {
                  setTripsOpen(false);
                  setIsModifyingTrip(false);
                }}
                refresh={handleRefresh}
                isModifyingTrip={isModifyingTrip}
                setIsModifyingTrip={setIsModifyingTrip}
                trip={trip}
                setTrip={setTrip}
                getDataTrips={getDataTrips}
              />
            )}
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { communities: changeFilters }
                    : { ..._filters, communities: changeFilters };
                });
              }}
              dataSelected={filters["communities"] || []}
              getData={async () => await getCommunities()}
              keyName={"communityKey"}
              phraseKey={"community"}
              isOpen={communityChoiceOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { sexualRole: changeFilters }
                    : { ..._filters, sexualRole: changeFilters };
                });
              }}
              dataSelected={filters["sexualRole"] || []}
              getData={async () => await getSexualRole()}
              keyName={"sexualRoleKey"}
              phraseKey={"sexual_role"}
              isOpen={sexualRoleOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { sexualPractices: changeFilters }
                    : { ..._filters, sexualPractices: changeFilters };
                });
              }}
              dataSelected={filters["sexualPractices"] || []}
              getData={async () => await getSexualPractices()}
              keyName={"sexualPracticeKey"}
              phraseKey={"sexual_practice"}
              isOpen={sexualPracticesOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
          </div>
          <RightSidebarAdvancedFilters
            filters={filters}
            setFilters={setFilters}
            filtersOpen={navbarStore.filtersOpen}
            setFiltersOpen={(str: string) =>
              dispatch(NavbarSlice.actions.setFiltersOpen(str))
            }
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            setIsSearchResults={setIsSearchResults}
            areFiltersDeactivated={areFiltersDeactivated}
            setAreFiltersDeactivated={setAreFiltersDeactivated}
          />
          <Content filtersOpen={navbarStore.filtersOpen} isDesktop={isDesktop}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 16px 10px 16px",
                width: isDesktop ? "calc(100vw - 250px)" : "100%",
                boxSizing: "border-box",
                position: "fixed",
                zIndex: 10,
                background: colors.darkGradient,
                backgroundAttachment: "fixed",
                top: 0,
              }}
            >
              <Flex
                alignItems="center"
                justify="space-between"
                gap="16px"
                style={{ padding: " 0", width: "100%" }}
              >
                <Flex alignItems="center" gap="16px">
                  <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron"
                    width={24}
                    height={24}
                    style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                    onClick={() => {
                      setMapOpen(true);
                    }}
                  />
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    {locale("explore.profiles.label")}&nbsp;{currentCityName}
                  </div>
                </Flex>
                {isDesktop ? (
                  <Flex gap="8px">
                    <PrimaryButton
                      size="large"
                      onClick={handleRedirectTrips}
                      style={{ background: "white", color: "black" }}
                    >
                      {locale("explore.manage.trips")}&nbsp;({myTrips.length})
                    </PrimaryButton>
                    <PrimaryButton
                      size="large"
                      isActive
                      onClick={() => setTripsOpen(true)}
                    >
                      {locale("travel_create_action")}
                    </PrimaryButton>
                  </Flex>
                ) : null}
              </Flex>
            </div>
            <Flex
              style={{
                width: isDesktop ? "calc(100vw - 270px)" : "97%",
                position: "fixed",
                alignItems: "center",
                top: isDesktop ? 66 : 44,
                background: colors.darkGradient,
                backgroundAttachment: "fixed",
                zIndex: 10,
                padding: "16px 8px 16px 8px",
                borderTop: `1px solid ${colors.grayBorder}`,
              }}
            >
              <CellFormatContainer>
                <img
                  src={`/assets/icons/nearby/rectangle${
                    cellType === "rectangle" ? "-blue" : ""
                  }.svg`}
                  onClick={() => setCellType("rectangle")}
                  width={24}
                  height={24}
                />
                <img
                  src={`/assets/icons/nearby/square${
                    cellType === "square" ? "-blue" : ""
                  }.svg`}
                  onClick={() => setCellType("square")}
                  width={24}
                  height={24}
                />
                <img
                  src={`/assets/icons/nearby/bigSquare${
                    cellType === "bigSquare" ? "-blue" : ""
                  }.svg`}
                  onClick={() => setCellType("bigSquare")}
                  width={24}
                  height={24}
                />
              </CellFormatContainer>
              <Divider />
              <FiltersListArrowWrapper onScroll={handleScroll}>
                {displayLeftArrow ? (
                  <PrimaryButton
                    size="medium"
                    isActive={true}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      zIndex: 10,
                      transform: "rotate(180deg)",
                    }}
                    onClick={() => handleArrowClick("left")}
                  >
                    <img
                      src="/assets/icons/nearby/chevron-right-white.svg"
                      alt="chevron-left"
                      width={6}
                      height={6}
                    />
                  </PrimaryButton>
                ) : null}
                {displayRightArrow ? (
                  <PrimaryButton
                    size="medium"
                    isActive={true}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 10,
                    }}
                    onClick={() => handleArrowClick("right")}
                  >
                    <img
                      src="/assets/icons/nearby/chevron-right-white.svg"
                      alt="chevron-right"
                      width={6}
                      height={6}
                    />
                  </PrimaryButton>
                ) : null}
                <FiltersListWrapper ref={filtersRef}>
                  <Headers
                    setFilters={setFilters}
                    filtersOpen={navbarStore.filtersOpen}
                    setFiltersOpen={(str: string) =>
                      dispatch(NavbarSlice.actions.setFiltersOpen(str))
                    }
                    filtersCount={filtersCount}
                    areFiltersDeactivated={areFiltersDeactivated}
                  />

                  <PrimaryButton
                    isActive={
                      !areFiltersDeactivated &&
                      filters.status?.includes("online")
                    }
                    onClick={() => {
                      setFilters((_filters) => {
                        setAreFiltersDeactivated(() => {
                          return false;
                        });
                        return areFiltersDeactivated &&
                          _filters.status?.includes("online")
                          ? { status: ["online"] }
                          : _filters.status?.includes("online")
                          ? { ..._filters, status: [] }
                          : { ..._filters, status: ["online"] };
                      });
                    }}
                  >
                    {locale("profile.status.connected")}
                  </PrimaryButton>

                  <PrimaryButton
                    isActive={filters.isMeetNow && !areFiltersDeactivated}
                    onClick={() =>
                      setFilters((_filters) => {
                        setAreFiltersDeactivated(() => {
                          return false;
                        });
                        return areFiltersDeactivated
                          ? { isMeetNow: true }
                          : { ..._filters, isMeetNow: !_filters.isMeetNow };
                      })
                    }
                  >
                    {locale("filters.meet_now")}
                  </PrimaryButton>

                  <PrimaryButton>Hashtags</PrimaryButton>

                  <PrimaryButton
                    isActive={filters.isNew && !areFiltersDeactivated}
                    onClick={() =>
                      setFilters((_filters) => {
                        setAreFiltersDeactivated(() => {
                          return false;
                        });
                        return areFiltersDeactivated
                          ? { isNew: true }
                          : { ..._filters, isNew: !_filters.isNew };
                      })
                    }
                  >
                    {locale("nearby.new_faces")}
                  </PrimaryButton>

                  <PrimaryButton
                    isActive={
                      filters.withProfilePictureOnly && !areFiltersDeactivated
                    }
                    onClick={() =>
                      setFilters((_filters) => {
                        setAreFiltersDeactivated(() => {
                          return false;
                        });
                        return areFiltersDeactivated
                          ? { withProfilePictureOnly: true }
                          : {
                              ..._filters,
                              withProfilePictureOnly:
                                !_filters.withProfilePictureOnly,
                            };
                      })
                    }
                  >
                    {locale("radar.search.with_picture.title")}
                  </PrimaryButton>

                  <PrimaryButton
                    onClick={() => {
                      setCommunityChoiceOpen((open) => !open);
                      setSexualPracticesOpen(false);
                      setSexualRoleOpen(false);
                    }}
                    isActive={
                      filters["communities"]?.length > 0 &&
                      !areFiltersDeactivated
                    }
                  >
                    {locale("nearby.communities")}
                    <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                  </PrimaryButton>

                  <PrimaryButton
                    onClick={() => {
                      setSexualRoleOpen((open) => !open);
                      setCommunityChoiceOpen(false);
                      setSexualPracticesOpen(false);
                    }}
                    isActive={
                      filters["sexualRole"]?.length > 0 &&
                      !areFiltersDeactivated
                    }
                  >
                    {locale("radar.filter.positions")}
                    <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                  </PrimaryButton>

                  <PrimaryButton
                    onClick={() => {
                      setSexualPracticesOpen((open) => !open);
                      setCommunityChoiceOpen(false);
                      setSexualRoleOpen(false);
                    }}
                    isActive={
                      filters["sexualPractices"]?.length > 0 &&
                      !areFiltersDeactivated
                    }
                  >
                    {locale("sexualPractices")}
                    <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                  </PrimaryButton>
                </FiltersListWrapper>
              </FiltersListArrowWrapper>
            </Flex>

            <SectionContainer>
              <InfiniteScroll
                dataLength={users?.users?.length}
                next={() => loadMoreProfiles("location", false)}
                pullDownToRefresh
                refreshFunction={refresh}
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>&#8595; Refresh</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>&#8593; Refresh</h3>
                }
                hasMore={hasMore}
                key={infiniteScrollKey}
                loader={
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    key={Math.random().toString()}
                  >
                    <Loader
                      color={colors.primary}
                      key={Math.random().toString()}
                    />
                  </div>
                }
                style={{ marginTop: !isDesktop ? 25 : 0 }}
              >
                <CustomGrid
                  users={users?.users}
                  cellType={cellType}
                  showBoostAd
                  premiumTitle={`${locale(
                    "grid.premium.title"
                  )} ${currentCityName}`}
                />
              </InfiniteScroll>
            </SectionContainer>
          </Content>
          {!isDesktop && (
            <RoundButtonsContainer>
              <RoundButtonContainer onClick={refresh}>
                <img
                  src="/assets/icons/nearby/refresh.svg"
                  alt="refresh icon"
                  width={20}
                  height={18}
                />
              </RoundButtonContainer>
              <RoundButtonContainer onClick={() => scrollTo({ top: 0 })}>
                <img
                  src="/assets/icons/nearby/arrow-up.svg"
                  alt="refresh icon"
                  width={24}
                  height={24}
                />
              </RoundButtonContainer>
            </RoundButtonsContainer>
          )}
        </MainWrapper>
      )}
    </ScreenWrapper>
  );
};

export default ExploreScreenv2;

const MapSearchContainer = styled.div`
  position: relative;
  height: fit-content;
  overflow: hidden;
`;

const CellFormatContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  background: ${colors.darkBlue};
  cursor: pointer;
  padding: 1px 4px;
  border-radius: 8px;
  margin-right: 8px;
`;

const FiltersListArrowWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-left: 8px;

  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  // overflow: scroll;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FiltersListWrapper = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SectionContainer = styled.div`
  background-color: transparent;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 128px;
  padding: 0 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  @media (max-width: 1000px) {
    margin-top: 110px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
`;

const Content = styled.div<{ filtersOpen: boolean; isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

const RoundButtonsContainer = styled.div`
  position: fixed;
  bottom: 72px;
  right: 8px;
  display: flex;
  gap: 8px;
`;
const RoundButtonContainer = styled.div`
  width: 56px;
  height: 56px;
  background-color: rgba(22, 25, 30, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${colors.grayBorder};
`;

const SearchInputContainer = styled.div`
  position: relative;
  z-index: 500;
  height: 36px;
  display: flex;
  flex-direction: column;
  min-width: 249px;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 48px;
  border: 2px solid transparent;
  background: linear-gradient(
      to right,
      #d93427,
      #f69101,
      #dfd902,
      #14aa5f,
      #2d56c5,
      #4c2e7b
    )
    border-box;
  color: white;

  @media (max-width: 1000px) {
    width: 80%;
  }
`;

const LocationTextInput = styled.input`
  padding-left: 28px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
  height: 33.5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;
