import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';

type Props = {
  icon: 'premium' | 'soon' | 'boost' | 'lightning' | 'meet-now';
  height?: number;
  width?: number;
};

const Icon = ({ icon, width, height }: Props) => {
  return (
    <Container>
      <img alt="" width={width ?? 14} height={height ?? 14} src={`/assets/icons/user-profile/${icon}.svg`} />
    </Container>
  );
};

export default Icon;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
