import styled from 'styled-components';
import colors from '../../themes/colors-v2.theme';

export const StyledInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  outline: none;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey8};
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  padding: 8px 12px;
`;

export const StyledTextAreaInput = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  outline: none;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey8};
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  padding: 8px 12px;
  height: 100px;
`;
