import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Player } from '@twilio/live-player-sdk';

import LiveChat from './chat/live-chat.component';
import LiveInfo from './watch/live-info.component';

import { getUserLivestream, getViewerCount } from '../../../services/live.service';
import { useLogin } from '../../../utils/auth.utils';
import useLocale from '../../../utils/locale/locale.hook';
import ModalSlice from '../../../store/slices/modal.slice';
import { getWebsocketConnection } from '../../../utils/websocket.utils';

import fonts from '../../../themes/fonts.theme';
import colors from '../../../themes/colors-v2.theme';
import Text from '../../../components/style/text.component';
import GoBack from './go-back.component';

enum LiveStatus {
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
}

export default function WatchLive() {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useLocale();
  const { isLogin } = useLogin();
  const { pseudo } = useParams<{ pseudo: string }>();

  const [live, setLive] = useState(null);
  const [liveStreamName, setLiveStreamName] = useState('');
  const [liveStatus, setLiveStatus] = useState(LiveStatus.ONGOING);
  const [userIsLive, setUserIsLive] = useState<boolean>(null);
  const [viewerCount, setViewerCount] = useState('-');
  const viewerCountInterval = useRef(null);

  useEffect(() => {
    clearInterval(viewerCountInterval.current);
    viewerCountInterval.current = setInterval(() => {
      getViewerCount(live?.stream_name).then((data) => {
        setViewerCount(data.success ? data.viewerCount : '-');
      });
    }, 2000);
    return () => {
      clearInterval(viewerCountInterval.current);
    };
  }, [live?.stream_name]);

  const onWSMessage = (e: any) => {
    const data = JSON.parse(e.data);
    if (data.type === 'live_ended' && data.liveId === live?.id) {
      setLiveStatus(LiveStatus.ENDED);
      setLive(null);
    }
  };

  const getLiveStreamHandler = async () => {
    getUserLivestream(pseudo).then(async (res: any) => {
      if (!res.statusCode && !res.message) {
        setUserIsLive(!!res.live);
        if (!!res.live) {
          const formattedLive = {
            ...res.live,
            categories: res.live.categories.map((liveCategory: any) => liveCategory.category),
          };

          const player = await Player.connect(res.token, {
            playerWasmAssetsPath: '/livePlayer',
          });
          player.play();
          document.getElementById('player').appendChild(player.videoElement);

          setLive(formattedLive);
          setLiveStreamName(res.live.stream_name);
        }
      } else {
        history.push('/live');
      }
    });
  };

  useEffect(() => {
    const ws = getWebsocketConnection();
    if (!!live && !!ws) {
      ws.removeEventListener('message', onWSMessage);
      ws.addEventListener('message', onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onWSMessage);
      }
    };
  }, [live]);

  useEffect(() => {
    if (isLogin) {
      getLiveStreamHandler();
    } else {
      dispatch(ModalSlice.actions.setSigninVisible(true));
    }
  }, [isLogin, pseudo]); // eslint-disable-line

  useEffect(() => {
    if (liveStatus === LiveStatus.ENDED && userIsLive === true) {
      window.location.reload();
    }
  }, [userIsLive, liveStatus]);

  return (
    <MainWrapper>
      {isLogin && (
        <>
          <LiveContainer>
            {userIsLive === true && liveStatus === LiveStatus.ONGOING && (
              <>
                <GoBack />
                <LiveInfos>
                  <LiveBadge>
                    <Text style={{ fontWeight: 800 }}>{locale('live.badge')}</Text>
                  </LiveBadge>
                  <Viewers>
                    <img src="/assets/icons/sidebar/viewers-white.svg" height={12} width={12} alt="Viewers icon" />
                    <Text color={colors.white}>{viewerCount}</Text>
                  </Viewers>
                </LiveInfos>
                <StreamContainer id="player"></StreamContainer>
                <LiveInfo live={live} />
              </>
            )}
            {userIsLive === true && liveStatus === LiveStatus.ENDED && (
              <CenteredMessage>{locale('live.watch.live_stopped')}</CenteredMessage>
            )}
            {userIsLive === false && <CenteredMessage>{locale('live.watch.not_streaming')}</CenteredMessage>}
          </LiveContainer>
          <LiveChat live={live} />
        </>
      )}
    </MainWrapper>
  );
}

const StreamContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark};
  height: calc(100vh - 180px);

  > video {
    height: calc(100vh - 180px);
    width: 100%;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LiveContainer = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 120px);
  box-sizing: border-box;
  margin-right: 370px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${colors.darkGradient};

  @media (max-width: 1000px) {
    padding: 0 0px 18px 0px;
    margin-right: 0;
  }
`;

const CenteredMessage = styled.div`
  height: 70vh;
  box-sizing: border-box;
  padding: 0 20px;
  display: grid;
  place-items: center;
  text-align: center;

  font-size: 20px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.lightGrey3};

  @media (max-width: 1000px) {
    height: 200px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 20;
  top: 12px;
  left: 12px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const LiveInfos = styled.div`
  position: absolute;
  z-index: 20;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  & > .viewers {
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    font-size: 12px;
    color: #f1f1f1;
    text-transform: uppercase;
  }

  & > .category {
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 12px;
    color: #f1f1f1;
  }
`;

const Viewers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${colors.lightGrey9};
  padding: 2px 8px;
  border-radius: 25px;
  color: ${colors.white};
`;

const LiveBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  background: #d92d20;
  padding: 2px 8px 2px 8px;
`;
