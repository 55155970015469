export const removeFirstWord = (str: string) => {
  const indexOfSpace = str.indexOf(" ");
  if (indexOfSpace === -1) {
    return "";
  }
  return str.substring(indexOfSpace + 1);
};

export const randomString = (length: number) => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charLength = chars.length;
  let result = "";
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

// Function that replace accentuated characters by non accentuated ones. Ex: "éàè" => "eae"
export const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
