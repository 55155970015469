import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";

import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import Button from "../../../components/forms-v2/button.component";
import Text from "../../../components/style/text.component";

import useLocale from "../../../utils/locale/locale.hook";
import { appealSanction } from "../../../services/sanctions.service";
import ModalSlice from "../../../store/slices/modal.slice";
import { logout } from "../../../utils/auth.utils";

import fonts from "../../../themes/fonts.theme";

enum AppealStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface ModalProps {
  visible: boolean;
}

export default function SanctionAppealModal({ visible }: ModalProps) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { sanctionAppeal } = useSelector((state: any) => state.modalStore);
  const [status, setStatus] = useState(AppealStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    dispatch(
      ModalSlice.actions.setSanctionAppeal({
        visible: false,
        type: null,
        key: null,
        canAppealSanction: true,
      })
    );
  };

  const agreeAndLogout = () => {
    closeModal();
    logout();
  };

  const appealSanctionHandler = () => {
    if (status === AppealStatus.LOADING || status === AppealStatus.SUCCESS) {
      return;
    }

    setStatus(AppealStatus.LOADING);
    appealSanction(sanctionAppeal.key).then((res) => {
      if (res?.success) {
        setStatus(AppealStatus.SUCCESS);
      } else {
        const errorMessage =
          res?.localeErrorKey ||
          res?.errorMessage ||
          res?.message ||
          locale("sanction.appeal.error_occured");
        setErrorMessage(errorMessage);
        setStatus(AppealStatus.ERROR);
      }
    });
  };

  useEffect(() => {
    if (!!visible && sanctionAppeal.type === null) {
      closeModal();
    }
  }, [visible, sanctionAppeal]);

  useEffect(() => {
    if (!visible) {
      setStatus(AppealStatus.IDLE);
      setErrorMessage("");
    }
  }, [visible]);

  const canCloseModal =
    sanctionAppeal.type === "warning" ||
    sanctionAppeal.type === "post_banned" ||
    sanctionAppeal.type === "user_24h_blocked" ||
    sanctionAppeal.type === "user_72h_blocked" ||
    sanctionAppeal.type === "live_24h_blocked";

  const mapSanctionTypeToDescription = {
    warning: locale("sanction.warning.description"),
    user_24h_blocked: locale("sanction.user_24h_blocked.description"),
    user_72h_blocked: locale("sanction.user_72h_blocked.description"),
    user_banned: locale("sanction.user_banned.description"),
    post_banned: locale("sanction.post_banned.description"),
    live_24h_blocked: locale("sanction.live_24h_blocked.description"),
  };

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModal}
      width="600px"
      canCloseModal={canCloseModal}
    >
      <ContentWrapper>
        {sanctionAppeal.canAppealSanction ? (
          <Title>{locale("sanction.appeal.title")}</Title>
        ) : (
          <Title>{locale("sanction.no_appeal.title")}</Title>
        )}

        <MarginWrapper>
          <Text>{mapSanctionTypeToDescription[sanctionAppeal.type]}</Text>
        </MarginWrapper>
        {status === AppealStatus.ERROR && (
          <MarginWrapper>
            <Text>{errorMessage}</Text>
          </MarginWrapper>
        )}
        {status !== AppealStatus.SUCCESS ? (
          <>
            {sanctionAppeal.canAppealSanction && (
              <ButtonWrapper>
                <Button
                  text={locale("appeal")}
                  onClick={appealSanctionHandler}
                  loading={status === AppealStatus.LOADING}
                />
              </ButtonWrapper>
            )}
          </>
        ) : (
          <MarginWrapper>
            <Text>{locale("sanction.appeal.success")}</Text>
          </MarginWrapper>
        )}
        <ButtonWrapper>
          {canCloseModal ? (
            <Button
              text={locale("sanction.appeal.understand")}
              onClick={closeModal}
            />
          ) : (
            <Button
              text={locale("sanction.appeal.understand")}
              onClick={agreeAndLogout}
            />
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </ModalTemplate>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const Title = styled.div`
  margin: 0 auto;
  font-size: 18px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  margin-bottom: 24px;
`;

const MarginWrapper = styled.div`
  margin-bottom: 24px;
`;

const ButtonWrapper = styled(MarginWrapper)`
  width: 60%;
`;
