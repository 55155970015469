import { createSlice } from "@reduxjs/toolkit";

const NavbarSlice = createSlice({
  name: "navbar",
  initialState: {
    showMobileNavbar: true,
    showAccountActivationBanner: false,
    showExploreSideMenu: false,
    overlayAccountIsOpen: false,
    filtersOpen: false,
    activeMobileTab: "nearby",
  },
  reducers: {
    setShowMobileNavbar: (state, { payload }) => {
      return {
        ...state,
        showMobileNavbar: payload,
      };
    },
    setShowAccountActivationBanner: (state, { payload }) => {
      return {
        ...state,
        showAccountActivationBanner: payload,
      };
    },
    setShowExploreSideMenu: (state, { payload }) => {
      if (payload === "close") {
        return {
          ...state,
          showExploreSideMenu: false,
        };
      }
      return {
        ...state,
        showExploreSideMenu: !state.showExploreSideMenu,
      };
    },
    setOverlayAccountIsOpen: (state, { payload }) => {
      if (payload === "close") {
        return {
          ...state,
          overlayAccountIsOpen: false,
        };
      }
      return {
        ...state,
        overlayAccountIsOpen: !state.overlayAccountIsOpen,
      };
    },
    setFiltersOpen: (state, { payload }) => {
      if (payload === "close") {
        return {
          ...state,
          filtersOpen: false,
        };
      }
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
      };
    },
    setActiveMobileTab: (state, { payload }) => {
      return {
        ...state,
        activeMobileTab: payload,
      };
    },
  },
});

export default NavbarSlice;
