import React from 'react';
import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';
import { calculatePasswordStrength, isValidPassword } from '../../utils/regex.util';
import Icons from '../style/icons.component';
import Text from '../style/text.component';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ borderRadius?: number }>`
  width: 100%;
  border: 1px solid ${colors.grayBorder};
  outline: none;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${colors.darkGray};
  color: ${colors.white};
  font-size: 14px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  padding: 8px 12px;

  &::placeholder {
    font-weight: 400;
    font-size: 14px !important;
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  position: absolute;
  top: 63px;
  z-index: 10;
`;

const Hide = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 14px;
`;

const PasswordStrength = styled.div`
  width: calc(100% + 14px);
  margin-left: -7px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
`;

const StrengthLevel = styled.div<{ status: 'error' | 'success' | null }>`
  height: 7px;
  width: 100%;
  flex: 1;
  border-radius: 2px;
  background-color: ${colors.grey};
  ${(props) =>
    props.status === 'error' &&
    `
        background-color: ${colors.danger};
    `}
  ${(props) =>
    props.status === 'success' &&
    `
        background-color: ${colors.success};
    `}
    margin: 7px;
`;

type PasswordInputProps = {
  onChange?: (text: string, isValid: boolean) => void;
  placeholder?: string;
  value?: string;
  style?: Object;
  error?: boolean;
  errorMsg?: string;
  passwordStrength?: boolean;
  success?: boolean;
  onBlur?: (isValid: boolean) => void;
  borderRadius?: number;
};

const PasswordInput = (props: PasswordInputProps) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [strengthLevelArray, setStrangthLevelArray] = React.useState([null, null, null, null, null, null, null]);

  const onChange = (text: string) => {
    const strength = calculatePasswordStrength(text);
    if (props?.passwordStrength) {
      const strengthLevelArrayNew = strengthLevelArray.map((level, index) => {
        if (strength >= index + 1) {
          if (strength > 6) {
            return 'success';
          } else {
            return 'error';
          }
        } else {
          return null;
        }
      });
      setStrangthLevelArray(strengthLevelArrayNew);
    }
    props?.onChange(text, isValidPassword(text));
    props?.onBlur && props?.onBlur(isValidPassword(text));
  };

  return (
    <Container style={props.style}>
      <Input
        type={passwordVisible ? '' : 'password'}
        value={props.value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={props.placeholder}
        borderRadius={props.borderRadius}
      />
      {props?.passwordStrength && (
        <PasswordStrength>
          {strengthLevelArray.map((level, index) => (
            <StrengthLevel key={index} status={level} />
          ))}
        </PasswordStrength>
      )}
      <Hide onClick={() => setPasswordVisible(!passwordVisible)}>
        <Icons name="hide-outlined-grey" width="16" height="16" />
      </Hide>
      {props.errorMsg && (
        <ErrorText>
          <Icons name="info-outlined-white" width="20" height="20" style={{ marginRight: 10 }} />
          <Text> {props.errorMsg} </Text>
        </ErrorText>
      )}
    </Container>
  );
};

export default PasswordInput;
