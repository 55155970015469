import styled from 'styled-components/macro';
import Text from '../../../../components/style/text.component';

export const TitleOptions = styled(Text)`
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: white;
  cursor: pointer @media (max-width: 1000px) {
    margin-top: 0;
  }
`;
