import { Dispatch, SetStateAction } from 'react';
import { updateLocation } from '../services/users.service';
import { store } from '../store/configure.store';
import MeSlice from '../store/slices/me.slice';

export const updateUserLocation = async (callback = () => null) => {
  const me = store.getState().meStore as any;
  const token = store.getState().authStore.token;
  if (Boolean(token && !me.error)) {
    if (me.locationOptin) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const resLocation = await updateLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          store.dispatch(MeSlice.actions.updateMe(resLocation));
          callback();
        });
        return;
      }
    }
  }

  updateLocation({});
};

export const getLocation = async (
  callback: (data: { latitude?: any; longitude?: any }) => void,
  setDisplayGeoloPerm?: Dispatch<SetStateAction<boolean>>
) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setDisplayGeoloPerm && setDisplayGeoloPerm(false);
        callback({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (err) => {
        setDisplayGeoloPerm && setDisplayGeoloPerm(true);
        callback({});
      }
    );
  } else {
    setDisplayGeoloPerm && setDisplayGeoloPerm(false);
    callback({});
  }
};
