import React from 'react';

import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import colors from '../../../themes/colors-v2.theme';
import useLocale from '../../../utils/locale/locale.hook';

export function NoFavorites() {
  const history = useHistory();
  const locale = useLocale();

  return (
    <Flex direction="column" justify="center" alignItems="center" gap="8px" style={{ margin: 32 }}>
      <div
        style={{
          borderRadius: '100%',
          border: `1px solid ${colors.grayBorder}`,
          width: 54,
          height: 54,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src="/assets/icons/sidebar/navbar/star-white.svg" width={26} height={24} />
      </div>
      <Text fontSize="16px" bold style={{ textAlign: 'center' }}>
        {locale('favorites.no_favorites')}
      </Text>
      <Text fontSize="12px" style={{ color: '#98A2B3', textAlign: 'center' }}>
        {locale('messages.empty.label')}
      </Text>
      <PrimaryButton isActive size="large" onClick={() => history.push('/nearby')}>
        {locale('favorites.goto_radar')}
      </PrimaryButton>
    </Flex>
  );
}
