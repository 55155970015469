import { del, put, post, get, queryBuilder } from './utils.service';

export const getUserVideos = async (query) => {
  const res = await get(`/user-videos${queryBuilder(query)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const createUserVideo = async (video) => {
  const res = await post(`/user-videos`, video);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateUserVideo = async (video) => {
  const res = await put(`/user-videos`, video);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteUserVideo = async (video) => {
  const res = await del(`/user-videos`, video);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getAllPublicVideos = async (query) => {
  const res = await get(`/user-videos/member-videos${queryBuilder(query)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getAllPornVideos = async (query) => {
  const res = await get(`/user-videos/porn-videos${queryBuilder(query)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserPresentationVideo = async (userId: string) => {
  const res = await get(`/user-videos/presentation/${userId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
