import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@material-ui/core";

import Icons from "../../../components/style/icons.component";
import Text from "../../../components/style/text.component";
import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import Button from "../../../components/forms/button.component";
import PicturesDisplay from "./pictures-display.component";

import { useLogin } from "../../../utils/auth.utils";
import { uploadFile } from "../../../utils/s3.utils";
import useLocale from "../../../utils/locale/locale.hook";
import ModalSlice from "../../../store/slices/modal.slice";
import { updatePost } from "../../../services/posts.service";

import fonts from "../../../themes/fonts.theme";

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 14px;
`;

const Content = styled.div`
  padding: 6px 12px 9px 12px;
  display: flex;
  flex-direction: column;
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: row;
`;

const Footer = styled.div`
  padding: 0px 12px;
`;

const FooterContent = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Textarea = styled(TextareaAutosize)`
  max-height: calc(100vh - 450px - 100px);
  border-radius: 4px;
  padding: 8px 14px 8px 17px;
  background-color: #34383d;

  border: none;
  width: calc(100% - 14px - 17px);
  resize: none;

  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 12px;
  color: white;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: #cfd3da;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Adons = styled.div`
  padding: 0px 7px;
`;

type UpdatePostModalProps = {
  visible?: boolean;
  style?: any;
  post: any;
};

const UpdatePostModal = (props: UpdatePostModalProps) => {
  const locale = useLocale();
  const { isLogin, me } = useLogin();
  const [text, setText] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const dispatch = useDispatch();
  const fileInput = React.useRef(null);

  React.useEffect(() => {
    if (props.post.text) setText(props.post.text);
    if (props.post.pictures) setFiles(props.post.pictures);
  }, [props.post]);

  const onClose = () => {
    dispatch(ModalSlice.actions.setCreatePostVisible(false));
  };

  const addPicture = () => {
    fileInput.current.click();
  };

  const submit = async () => {
    const filesFormated = [];
    for (let file of files) {
      if (!file.id) {
        const { path, error } = await uploadFile(file);
        // DEBUG: error not handled
        if (error) return;

        filesFormated.push({
          path,
        });
      } else {
        filesFormated.push(file);
      }
    }
    await updatePost({
      id: props.post.id,
      text,
      pictures: filesFormated,
    });
    setText("");
    dispatch(
      ModalSlice.actions.setUpdatePostVisible({
        visible: false,
        post: {},
      })
    );
  };

  const onChange = async (e) => {
    const files = e.target.files;
    setFiles((_files) => _files.concat(Array.from(files)));
  };

  return (
    <ModalTemplate
      visible={props.visible}
      closeModal={() => {
        onClose();
      }}
      canCloseModal
      width="500px"
      style={{ maxHeight: "calc(100vh - 150px)" }}
      zIndex={300}
    >
      <input
        ref={fileInput}
        style={{ display: "none" }}
        type="file"
        id="myFile"
        name="filename"
        onChange={onChange}
        multiple
        accept="image/*"
      />
      <Header>
        <Text>{locale("post.update.title")}</Text>
      </Header>
      <Content>
        <ContentText>
          <img
            alt=""
            src={me?.profilePicture?.path}
            width="34"
            height="34"
            style={{
              objectFit: "cover",
              borderRadius: 34,
              marginRight: 9,
              marginTop: 5,
            }}
          />
          <TextAreaContainer>
            <Textarea
              placeholder={locale("feed.create_post.placeholder", {
                username: me?.pseudo,
              })}
              rows={3}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </TextAreaContainer>
        </ContentText>
        {files.length > 0 && (
          <PicturesDisplay
            create
            style={{
              marginLeft: 43,
              marginTop: 10,
              marginBottom: 10,
            }}
            canUpdate
            deletePicture={() => {
              const filesCopy = [...files];
              filesCopy.splice(0, 1);
              setFiles(filesCopy);
            }}
            pictures={
              files.length > 0 &&
              files.map((file) => {
                if (file.id)
                  return { path: file.path, moderation: file.moderation };
                else
                  return {
                    path: URL.createObjectURL(file),
                    moderation: "nonSexual",
                  };
              })
            }
          />
        )}
      </Content>
      <Footer>
        <FooterContent>
          <Adons>
            <Icons
              onClick={() => addPicture()}
              style={{
                cursor: "pointer",
              }}
              name={"photoUpload-outlined-white"}
              width="19"
              height="19"
            />
          </Adons>
          <Button
            disabled={!isLogin || (!text && files.length === 0)}
            onClick={submit}
            text={locale("feed.create_post.submit")}
            color={"primary"}
          />
        </FooterContent>
      </Footer>
    </ModalTemplate>
  );
};

export default UpdatePostModal;
