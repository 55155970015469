import React from "react";
import styled from "styled-components/macro";
import { JSONLD, Generic } from "react-structured-data";

/*
{
      "@context": "https://schema.org",
      "@type": "Event",
      "name": "title",
      "startDate": "2025-07-21T19:00-05:00",
      "endDate": "2025-07-21T23:00-05:00",
      "location": {
        "@type": "Place",
        "name": "Snickerpark Stadium",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "100 West Snickerpark Dr",
          "addressLocality": "Snickertown",
          "postalCode": "19019",
          "addressRegion": "PA",
          "addressCountry": "US"
        }
      },
      "image": [
        "https://example.com/photos/1x1/photo.jpg",
        "https://example.com/photos/4x3/photo.jpg",
        "https://example.com/photos/16x9/photo.jpg"
       ],
      "description": "The Adventures of Kira and Morrison is coming to Snickertown in a can't miss performance.",
    }
    */

type StructuredDataEventProps = {
  venue: string;
  city: string;
  startDate: Date;
  endDate: Date;
  description: string;
  name: string;
  eventAttendanceMode: string;
  organizer: string;
  image: string;
};

const StructuredDataEvent = (props: StructuredDataEventProps) => {
  return (
    <JSONLD>
      <Generic
        type="event"
        jsonldtype="Event"
        schema={{
          startDate: props.startDate,
          endDate: props.endDate,
          description: props.description,
          name: props.name,
          eventAttendanceMode: props.eventAttendanceMode,
          organizer: props.organizer,
          image: props.image,
        }}
      >
        <Generic type="location" jsonldtype="Place">
          <Generic
            type="address"
            jsonldtype="PostalAddress"
            schema={{
              streetAddress: props.venue,
              addressLocality: props.city,
            }}
          />
        </Generic>
      </Generic>
    </JSONLD>
  );
};

export default StructuredDataEvent;
