import React, { useState } from "react";
import styled from "styled-components/macro";
import Text from "../../../../components/style/text.component";
import RatingBar from "../rating-bar.component";
import Stepper from "../stepper.comonent";
import useLocale from "../../../../utils/locale/locale.hook";
import PictureWrapperComponent from "./pictureWrapper.component";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  width: 101%;
  height: 101%;
  filter: blur(4px);
  background: linear-gradient(180.05deg, #fedd63 -52.94%, #000000 99.96%);
`;

const ContinueButton = styled.div`
  width: 142px;
  border: 2px solid white;
  color: white;
  padding: 5px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Title = styled(Text)`
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
`;

const Description = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  max-width: 871px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Step = (props) => {
  const locale = useLocale();
  return (
    <Container
      style={{
        backgroundImage:
          props.step > 5 ? "url('/assets/imgs/moderation/5-0.png')" : "",
      }}
    >
      <Background />
      <PictureWrapperComponent step={props.step} style={{ zIndex: 2 }} />
      <div style={{ marginTop: -60, zIndex: 10 }}>
        <img
          alt=""
          src={`/assets/icons/moderation/Logo-${props.step - 1}.png`}
          style={{ height: "60px", width: "60px" }}
        />
      </div>
      <Title style={{ zIndex: 2 }}>
        {locale(`moderation.tutorial.title.${props.step}`)}
      </Title>
      <Description style={{ zIndex: 2 }}>
        {locale(`moderation.tutorial.description.${props.step}.1`)} <br />
        {locale(`moderation.tutorial.description.${props.step}.2`)}
      </Description>

      <Bottom style={{ zIndex: 2 }}>
        <ContinueButton onClick={props.onClick}>
          <Text style={{ fontSize: "16px" }}>
            {locale(`moderation.tutorial.continue`)}
          </Text>
        </ContinueButton>
        <Stepper currentStep={props.step} />
      </Bottom>
    </Container>
  );
};

export default Step;
