import { useState, useRef } from "react";
import styled from "styled-components/macro";
import TextareaInput from "../../../../../components/forms/textarea.input";
import Icons from "../../../../../components/style/icons.component";
import Text from "../../../../../components/style/text.component";
import colors from "../../../../../themes/colors-v2.theme";
import useLocale from "../../../../../utils/locale/locale.hook";
import { useDrag, useDrop } from "react-dnd";
import { useOnClickOutside } from "../../../../../utils/clickOutside.utils";
import { PrimaryButton } from "../../../../../components/forms/primaryButton.component";
import Image from "../../../../../components/image.component";

interface PictureProps {
  path: string;
  isMain: boolean;
  onDelete?: () => void;
  onMainPicture?: () => void;
  onChangeMainPicture?: (e: any) => Promise<void>;
  moderation?: string;
  description?: string;
  movePicture?: (dragIndex: any, hoverIndex: any) => void;
  onDragEnd?: () => void;
  onDescription?: (text: any) => void;
  isMyPhotos?: boolean;
  isPrivatePhotos?: boolean;
  isQuickshare?: boolean;
  index?: number;
  id?: string;
  openFullSizeImages?: () => void;
  handlePictureTransfer?: (
    newAlbum: "myPhotos" | "myPrivatePhotos" | "quickshare",
    pictureId: string
  ) => Promise<void>;
}

const Picture = (props: PictureProps) => {
  const locale = useLocale();
  const ref = useRef(null);
  const menuButtonRef = useRef(null);
  const menuRef = useRef(null);
  const fileInput = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOnClickOutside([menuButtonRef, menuRef], () => {
    setIsMenuOpen(false);
  });

  const toggleMenuOpen = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const [, drop] = useDrop({
    accept: "photo",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as any).index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      props.movePicture(dragIndex, hoverIndex);
      (item as any).index = hoverIndex;
    },
  });

  const [{ isDragging }, drag]: any = useDrag({
    type: "photo",
    item: { type: "photo", id: props.id, index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => !props.isMain,
    end: () => props.onDragEnd(),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Container ref={ref} style={{ opacity }}>
      <PictureContainer>
        <Menu ref={menuButtonRef} onClick={toggleMenuOpen}>
          <img
            src="/assets/icons/user-profile/dots.svg"
            width={24}
            height={24}
          />
        </Menu>

        {isMenuOpen && (
          <MenuOpen ref={menuRef}>
            {props?.isMain ? (
              <>
                <input
                  ref={fileInput}
                  style={{ display: "none" }}
                  type="file"
                  name="filename"
                  onChange={props?.onChangeMainPicture}
                  accept="image/*, .heic"
                />
                <MenuItem onClick={() => fileInput.current.click()}>
                  <Text>{locale("global.edit")}</Text>
                </MenuItem>
                <MenuItem onClick={props?.onDelete}>
                  <Text>{locale("edit_photos.picture.menu.delete")}</Text>
                </MenuItem>
              </>
            ) : (
              <>
                {props?.isMyPhotos && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(false);
                      props?.onMainPicture();
                    }}
                  >
                    <Text>
                      {locale("edit_photos.picture.menu.profile_picture")}
                    </Text>
                  </MenuItem>
                )}
                {!props.isMyPhotos && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(false);
                      props.handlePictureTransfer("myPhotos", props.id);
                    }}
                  >
                    <Text>
                      {locale("edit_photos.picture.menu.move_to_public")}
                    </Text>
                  </MenuItem>
                )}
                {!props.isPrivatePhotos && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(false);
                      props.handlePictureTransfer("myPrivatePhotos", props.id);
                    }}
                  >
                    <Text>
                      {locale("edit_photos.picture.menu.move_to_private")}
                    </Text>
                  </MenuItem>
                )}
                {!props.isQuickshare && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(false);
                      props.handlePictureTransfer("quickshare", props.id);
                    }}
                  >
                    <Text>
                      {locale("edit_photos.picture.menu.move_to_quickshare")}
                    </Text>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setIsMenuOpen(false);
                    props?.onDelete();
                  }}
                >
                  <Text>{locale("edit_photos.picture.menu.delete")}</Text>
                </MenuItem>
              </>
            )}
          </MenuOpen>
        )}

        <Image 
          alt=""
          src={props.path}
          width="100%"
          height="100%"
          style={{
            borderRadius: 8,
            objectFit: "cover",
            objectPosition: "center",
            cursor: "pointer",
          }}
          optiWidth={300}
        />
        {props?.isMain ? (
          <ProfilePictureNote>
            {locale("settings.pictures.public.profile_pic")}
          </ProfilePictureNote>
        ) : null}
      </PictureContainer>
      {/* <TextareaInput
        placeholder={locale("edit_photos.description.placeholder")}
        textareaStyle={{
          padding: "6px",
          margin: "0",
          fontSize: "10",
          width: "calc(100% - 12px)",
          resize: "none",
          height: "calc(100% - 12px)",
          backgroundColor: colors.backgroundLight,
          borderRadius: "4px",
          overflow: "auto",
          fontFamily: "Segoe UI",
          color: colors.darkerGrey,
        }}
        onBlur={(text) => props?.onDescription(text)}
        onChange={(text) => setDescription(text)}
        value={description}
        maxLength={500}
        placeholderColor={colors.darkGrey}
      /> */}
    </Container>
  );
};

const Cross = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 6;
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  margin: 0;
`;

const PictureContainer = styled.div<any>`
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 8px;
  cursor: move;
  cursor: grab;
  border-radius: 4px;

  &:active {
    cursor: grabbing;
  }
`;

const Menu = styled.div`
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(123, 123, 123, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    background-color: rgba(123, 123, 123, 0.3);
  }
`;

const MenuOpen = styled.div`
  position: absolute;
  background-color: ${colors.backgroundLight};
  right: 5px;
  top: 5px;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
`;

const MenuItem = styled.div`
  cursor: pointer;
  width: 100px;
  display: flex;
  align-items: center;
  background: ${colors.darkGray};
  color: ${colors.white};
  padding: 8px;

  & p {
    font-size: 12px;
  }

  &:hover {
    background-color: ${colors.lightGrey1};
    &:first-child {
      border-radius: 4px 4px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
  }
`;

const ProfilePictureNote = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  bottom: 8px;
  left: 8px;
  font-size: 12px;
  line-height: 12px;
  color: #f1f1f1;
`;

export default Picture;

// const Pending = styled.div`
//   width: 100%;
//   height: 30%;
//   z-index: 10;
//   display: flex;
//   justify-content: center;
//   padding-top: 15px;
//   border-radius: 3px;
//   background: linear-gradient(${colors.greyDark2}, rgba(0, 0, 0, 0));
//   position: absolute;
//   display: flex;
//   justify-content: center;
// `;

/* {props?.moderation === "pending" ? (
  <Pending>
    <Text style={{ textAlign: "center", maxWidth: "100px" }}>
      {locale("edit_photos.pending_approval")}
    </Text>
  </Pending>
) : (
  <>
    {" "}
    {props?.moderation != "private" && (
      <img
        alt=""
        src={`/assets/icons/moderation/Logo-${
          moderationPicture[props?.moderation]
        }.png`}
        width="20"
        height="20"
        style={{
          position: "absolute",
          bottom: 8,
          right: 7,
        }}
      />
    )}
  </>
)} */
