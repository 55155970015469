import styled from 'styled-components/macro';

export const Values = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex: 2;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
`;
