import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";

import { leaveVideoCall } from "../../../services/video-call.service";
import ModalSlice from "../../../store/slices/modal.slice";

import { VideoCall } from "../types/video-call.interface";

import * as TwilioVideo from "twilio-video";

interface ComponentProps {
  call: VideoCall;
  room: TwilioVideo.Room;
}

export default function VideoActions({ call, room }: ComponentProps) {
  const dispatch = useDispatch();

  const leaveCallHandler = () => {
    room.disconnect();
    leaveVideoCall(call.id).then((res) => {
      if (res?.success) {
        dispatch(
          ModalSlice.actions.setVideoCall({
            visible: false,
            callId: null,
            timeRemaining: null,
            token: null,
          })
        );
      }
    });
  };

  return (
    <Wrapper>
      <Action onClick={leaveCallHandler}>
        <img alt="" src="/assets/icons/video-call/leave.svg" />
      </Action>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin: 0 16px;
  }
`;

const Action = styled.div`
  width: 52px;
  height: 52px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
  }
`;
