import styled from "styled-components/macro";

//https://loading.io/css/

const LdsGrid = styled.div<{
  color?: string;
  size?: number;
  borderSize?: number;
}>`
  width: ${(p) => (p.size ? p.size + "px" : "30px")};
  height: ${(p) => (p.size ? p.size + "px" : "30px")};
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: "";
    display: block;
    width: ${(p) =>
      p.size ? `calc(${p.size}px - 16px)` : "calc(30px - 16px)"};
    height: ${(p) =>
      p.size ? `calc(${p.size}px - 16px)` : "calc(30px - 16px)"};
    border-radius: 50%;
    border: ${(p) => (p.borderSize ? p.borderSize + "px" : "3px")} solid
      ${(props) => props.color || "#fff"};
    border-color: ${(props) => props.color || "#fff"} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  style?: any;
  color?: string;
  size?: number;
  borderSize?: number;
}

export const Loader = (props: LoaderProps) => {
  return (
    <div style={props.style}>
      <LdsGrid
        color={props.color}
        size={props.size}
        borderSize={props.borderSize}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LdsGrid>
    </div>
  );
};
