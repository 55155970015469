import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import useLocale from '../../../utils/locale/locale.hook';

import fonts from '../../../themes/fonts.theme';
import colors from '../../../themes/colors-v2.theme';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';

interface GoBackProps {
  isWatchLiveScreen?: boolean;
  shouldAddVideoPadding?: boolean;
}

export default function GoBack(props: GoBackProps) {
  const { isWatchLiveScreen = false, shouldAddVideoPadding = false } = props;
  const locale = useLocale();
  return (
    <GoBackWrapper isWatchLiveScreen={isWatchLiveScreen} shouldAddVideoPadding={shouldAddVideoPadding}>
      <Link to={'/live'} style={{ display: 'block', width: 'min-content' }}>
        <PrimaryButton size="medium" style={{ borderColor: 'white', width: 36, height: 36 }}>
          <img alt="" src="/assets/icons/cross-outlined-white.svg" width={16} height={16} />
        </PrimaryButton>
      </Link>
    </GoBackWrapper>
  );
}

const GoBackWrapper = styled.div<{
  isWatchLiveScreen: boolean;
  shouldAddVideoPadding: boolean;
}>`
  margin-left: 8px;
  margin-top: 12px;
  margin-bottom: 18px;
  box-sizing: border-box;
  padding: 0;
  position: absolute;

  & .goBack {
    user-select: none;
    margin-right: auto;
    width: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fffefe;
    font-size: 16px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};

    & > img {
      width: 6px;
      height: 8px;
      margin-right: 15px;
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0;
    padding-left: ${(p) => (p.isWatchLiveScreen ? '18px' : '0')};
    padding-right: ${(p) => (p.isWatchLiveScreen ? '18px' : '0')};
    padding-top: ${(p) => (p.shouldAddVideoPadding ? '60vw' : '0')};
  }
`;

const CloseButton = styled.img`
  background-color: ${colors.white};
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
