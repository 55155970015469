import { useMemo } from "react";
import styled from "styled-components/macro";

type PostMessageProps = {
  message: any;
  sender: "me" | "other";
};

export default function PostMessage({ message, sender }: PostMessageProps) {
  const content = useMemo(() => {
    return message.content.replace(
      "bearwww-uploads-staging.s3.eu-west-3.amazonaws.com",
      "d2tiq3wo24jtl5.cloudfront.net"
    )
  }, [message.content]);

  return (
    <Message sender={sender}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Message>
  );
}

const Message = styled.div<{ sender: "me" | "other"; isAnonymous?: boolean }>`
  padding: 12px 12px 8px;
  border-radius: 8px;

  font-size: 14px;
  color: ${(props) => (props.sender === "me" ? "#d8dadc" : "#fff")};

  & > p {
    margin: 0;
    overflow-wrap: break-word;
    font-size: 14px;
  }

  img {
    width: 100%;
  }

  a {
    color: ${(props) => (props.sender === "me" ? "#d8dadc" : "#fff")};
    text-decoration: underline;
  }
`;
