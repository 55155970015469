import styled from "styled-components/macro";
import useLocale from "../../utils/locale/locale.hook";

type ComingSoonTooltipProps = {
  phraseKey: string;
  style?: object;
};

const ComingSoonTooltip = (props: ComingSoonTooltipProps) => {
  const locale = useLocale();

  return <Tooltip style={props.style}>{locale(props.phraseKey)}</Tooltip>;
};

const Tooltip = styled.div`
  position: absolute;
  bottom: -30px;
  right: -30px;
  padding: 7px 13px;
  background-color: white;
  color: black;
  white-space: nowrap;
  font-size: 10px;
  border-radius: 4px;
`;

export default ComingSoonTooltip;
