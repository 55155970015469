import { getS3Url } from '../services/s3.service';
import convert from 'heic-convert';

export const uploadFile = async (
  file: File
): Promise<{
  path?: string;
  error?: string;
}> => {
  // 50MB
  if (file.size > 50 * 1024 * 1024) {
    return { error: 'file too big' };
  }

  const type = file.type;
  if (type === 'image/heic') {
    const arrayBuffer = await file.arrayBuffer();
    const result = await convert({
      buffer: Buffer.from(arrayBuffer),
      format: 'JPEG',
      quality: 0.3,
    });

    if (result === undefined) {
      return { error: 'global.error.picture_format' };
    }

    const filename = file.name.replace('.heic', '.jpeg');

    const blob = new Blob([result], { type: 'image/jpeg' });
    file = new File([blob], filename, { type: 'image/jpeg' });
  }

  const { url } = await getS3Url(file.type);

  if (url === undefined) {
    return { error: 'global.error.picture_format' };
  }

  const headersContent = {
    'Content-Type': file.type,
    Accept: 'application/json',
  };
  const headers = new Headers(headersContent);
  const response = await fetch(url, {
    method: 'PUT',
    body: file,
    headers,
  });

  if (!response) return { error: 'error_upload' };
  const resUrl = new URL(response?.url);

  return { path: resUrl.protocol + '//' + resUrl.host + resUrl.pathname };
};
