import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import ModalTemplate from '../../../components/miscellaneous/modal-template.component';
import Text from '../../../components/style/text.component';

import useLocale from '../../../utils/locale/locale.hook';
import { deleteGuide } from '../../../services/guide.service';
import fonts from '../../../themes/fonts.theme';
import colors from '../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';

enum DeleteStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  guide: any;
}

export default function DeleteGuideModal({ visible, closeModal, guide }: ModalProps) {
  const locale = useLocale();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [deleteStatus, setDeleteStatus] = useState(DeleteStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteGuideHandler = () => {
    if (deleteStatus === DeleteStatus.LOADING) {
      return;
    }

    setDeleteStatus(DeleteStatus.LOADING);
    deleteGuide(guide.id).then((res) => {
      if (res?.success) {
        closeModal();
        history.push('/guide/events');
      } else {
        setErrorMessage('An error occured, please try again.');
        setDeleteStatus(DeleteStatus.ERROR);
      }
    });
  };

  // Réinitialise le formulaire lorsque le modal est caché et qu'un guide a été créé
  useEffect(() => {
    if (!visible) {
      setDeleteStatus(DeleteStatus.IDLE);
    }
  }, [visible]);

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModal}
      width="300px"
      closeOutside
      style={{
        borderRadius: isDesktop ? '8px' : 0,
        background: colors.black,
      }}
      fullsize={!isDesktop}
    >
      <ModalContent>
        <TitleWrapper>
          <Text>{locale('guide.delete.confirm')}</Text>
        </TitleWrapper>
        {deleteStatus === DeleteStatus.ERROR && (
          <ErrorWrapper>
            <Text>{errorMessage}</Text>
          </ErrorWrapper>
        )}
        <ButtonWrapper>
          <Button onClick={closeModal}>
            <p>{locale('global.no')}</p>
          </Button>
          <Button onClick={deleteGuideHandler} outlined>
            <p>{locale('global.yes')}</p>
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalTemplate>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 28px 18px;
  overflow-y: auto;
  max-height: 70vh;
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;

  & > p {
    font-size: 14px;
    letter-spacing: -0.39px;
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0px auto;
`;

const ErrorWrapper = styled.div`
  margin: 10px 0;
`;

const Button = styled.button<{ outlined?: boolean }>`
  width: 90px;
  height: 27px;
  margin: 11px;

  cursor: pointer;

  border: ${(p) => (p.outlined ? 'none' : 'solid 1px #d8dadc')};
  border-radius: 4px;

  background-color: ${(p) => (p.outlined ? colors.darkBlue : '#1a1a1b')};

  & > p {
    margin: 0;
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    font-size: 10px;
    letter-spacing: 0.33px;
    color: ${(p) => (p.outlined ? '#ffffff' : '#d8dadc')};
  }
`;
