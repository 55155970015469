import React, { useState } from "react";
import styled from "styled-components/macro";

import Text from "./style/text.component";
import { useNotifContext, REMOVE } from "../context/notif-context";
import colors from "../themes/colors-v2.theme";

const Notif = ({ notif }) => {
  const { notifDispatch } = useNotifContext();

  return (
    <NotifContainer>
      {notif.map((n) => {
        let visible = true;
        setTimeout(function () {
          visible = false;
        }, 100);
        setTimeout(function () {
          notifDispatch({ type: REMOVE, payload: { id: n.id } });
        }, 5000);
        return (
          <NotifDiv key={n.id} visible={visible}>
            <div style={{ padding: "5px", width: "90%" }}>
              <Text>{n.content}</Text>
            </div>
            <Icon
              width="10px"
              height="10px"
              src="/assets/icons/icons-actions-close-dark.svg"
              onClick={() =>
                notifDispatch({ type: REMOVE, payload: { id: n.id } })
              }
            />
          </NotifDiv>
        );
      })}
    </NotifContainer>
  );
};

const NotifContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  left: 24px;
  overflow-y: hidden;
  padding: 12px;

  @media (max-width: 1000px) {
    bottom: 150px;
  }
`;

const NotifDiv = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: row;
  width: 282px;
  padding: 6px 4px 8px 10px;
  border-radius: 8px;
  background-color: ${colors.green};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  opacity: 100;
  height: 40px;
  & p {
    color: ${colors.white};
  }
  &:not(:hover) {
    ${(props) =>
      props.visible
        ? "opacity: 100;"
        : `    
    transition: 5s;
    opacity: 0;`}
  }
`;

const Icon = styled.img`
  cursor: pointer;
  margin-left: 8px;
  margin-right: 15px;
`;

export default Notif;
