export const getQueryFilters = (filters) => {
  let filterChanged = {};
  if (filters) {
    if (filters['ageMax'] && filters['ageMax'] != 99) {
      filterChanged['ageMax'] = filters['ageMax'];
    }
    if (filters['ageMin'] && filters['ageMin'] != 18) {
      filterChanged['ageMin'] = filters['ageMin'];
    }
    if (filters['heightMax'] && filters['heightMax'] !== 210) {
      filterChanged['heightMax'] = filters['heightMax'];
    }
    if (filters['heightMin'] && filters['heightMin'] !== 140) {
      filterChanged['heightMin'] = filters['heightMin'];
    }
    if (filters['weightMax'] && filters['weightMax'] !== 250) {
      filterChanged['weightMax'] = filters['weightMax'];
    }
    if (filters['weightMin'] && filters['weightMin'] !== 45) {
      filterChanged['weightMin'] = filters['weightMin'];
    }
    if (filters['status'] && filters['status'].length > 0) {
      filterChanged['status'] = filters['status'];
    }
    if (filters['sexualRole'] && filters['sexualRole'].length > 0) {
      filterChanged['sexualRole'] = filters['sexualRole'];
    }
    if ('online' in filters) {
      filterChanged['online'] = filters['online'];
    }
    if ('withProfilePictureOnly' in filters && filters['withProfilePictureOnly']) {
      filterChanged['withProfilePictureOnly'] = filters['withProfilePictureOnly'];
    }
    if ('isNew' in filters && filters['isNew']) {
      filterChanged['isNew'] = filters['isNew'];
    }
    if ('isMeetNow' in filters && filters['isMeetNow']) {
      filterChanged['isMeetNow'] = filters['isMeetNow'];
    }
    if (filters['communities'] && filters['communities'].length > 0) {
      filterChanged['communities'] = filters['communities'];
    }
    if (filters['languages'] && filters['languages'].length > 0) {
      filterChanged['languages'] = filters['languages'];
    }
    if (filters['facialHair'] && filters['facialHair'].length > 0) {
      filterChanged['facialHair'] = filters['facialHair'];
    }
    if (filters['sexualPractices'] && filters['sexualPractices'].length > 0) {
      filterChanged['sexualPractices'] = filters['sexualPractices'];
    }

    if (filters['ethnicities'] && filters['ethnicities'].length > 0) {
      filterChanged['ethnicities'] = filters['ethnicities'];
    }
    if (filters['lookingFor'] && filters['lookingFor'].length > 0) {
      filterChanged['lookingFor'] = filters['lookingFor'];
    }
    if (filters['appearance'] && filters['appearance'].length > 0) {
      filterChanged['appearance'] = filters['appearance'];
    }
  }
  return filterChanged;
};
