import { get } from './utils.service';
import * as queryString from 'query-string';

type Category = 'myKinks' | 'myHobbies' | 'myPersonality' | 'myOtherTags' | 'iSpeak';

type Hashtag = {
  id: number;
  category: Category;
  traductionKey: string;
};

export type GroupedHashtags = { categoryKey: `hashtag.${Category}.title`; hashtags: Hashtag[] };

export const getHashtags = async (): Promise<GroupedHashtags[]> => {
  const res = await get(`/hashtag/all`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getTrendingHashtags = async (query: any): Promise<Hashtag[]> => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    return rslt;
  }, {});

  const res = await get(`/hashtag/trending?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
