import { createSlice } from "@reduxjs/toolkit";

const ClassificationSlice = createSlice({
  name: "classification",
  initialState: {
    overlayVisible: false,
  },
  reducers: {
    setOverlayVisibility: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        overlayVisible: payload,
      };
    },
  },
});

export default ClassificationSlice;
