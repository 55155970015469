import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";

import ModalTemplate from "../../components/miscellaneous/modal-template.component";

import ModalSlice from "../../store/slices/modal.slice";
import { answerVideoCall } from "../../services/video-call.service";
import { getWebsocketConnection } from "../../utils/websocket.utils";

import fonts from "../../themes/fonts.theme";
import Icons from "../../components/style/icons.component";

enum AnswerStatus {
  IDLE = "IDLE",
  CALLING = "CALLING",
  ENDED = "ENDED",
}

interface ModalProps {
  visible: boolean;
}

let endedTimeout = null;

export default function AnswerCallModal({ visible }: ModalProps) {
  const dispatch = useDispatch();
  const { answerCall } = useSelector((state: any) => state.modalStore);
  const [answerStatus, setAnswerStatus] = useState(AnswerStatus.IDLE);

  const answerCallHander = (answer: "joined" | "refused") => {
    answerVideoCall(answerCall.callId, answer).then((res) => {
      if (res?.success) {
        if (answer === "joined") {
          dispatch(
            ModalSlice.actions.setVideoCall({
              visible: true,
              callId: answerCall.callId,
              timeRemaining: null,
              token: res.token,
            })
          );
        }
        closeModalHandler();
      }
    });
  };

  const closeModalHandler = () => {
    dispatch(
      ModalSlice.actions.setAnswerCall({
        visible: false,
        callId: null,
        user: null,
      })
    );
  };

  const onWSMessage = (e: any) => {
    const data = JSON.parse(e.data);

    if (data.callId !== answerCall.callId) {
      return;
    }

    if (data.type === "video_call_stop") {
      setAnswerStatus(AnswerStatus.ENDED);
    }
  };

  useEffect(() => {
    const ws = getWebsocketConnection();
    if (!!ws) {
      ws.removeEventListener("message", onWSMessage);
      ws.addEventListener("message", onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener("message", onWSMessage);
      }
    };
  }, [answerCall.callId]);

  useEffect(() => {
    if (visible) {
      setAnswerStatus(AnswerStatus.CALLING);
    } else {
      setAnswerStatus(AnswerStatus.IDLE);
    }
  }, [visible]);

  useEffect(() => {
    if (answerStatus === AnswerStatus.ENDED) {
      endedTimeout = setTimeout(closeModalHandler, 5000);
    }

    return () => clearTimeout(endedTimeout);
  }, [answerStatus]);

  // !! TODO : faire jouer une sonnerie durant la demande d'un appel

  return (
    <ModalTemplate
      visible={visible}
      canCloseModal={false}
      width="450px"
      style={modalCustomStyle}
    >
      <ContentWrapper>
        <ProfilePicture alt="" src={answerCall.user?.profilePicture?.path} />
        <div>
          <TopMiddleContainer>
            <Pseudo>{answerCall.user?.pseudo}</Pseudo>
          </TopMiddleContainer>
          <UnderText>t’appelle en visio</UnderText>
        </div>
        <ButtonsContainer>
          <Icons
            name="chat-video-accept"
            onClick={() => answerCallHander("joined")}
            width="42px"
            height="42px"
            style={{ cursor: "pointer" }}
          />
          <Icons
            name="chat-video-refuse"
            onClick={() => answerCallHander("refused")}
            width="42px"
            height="42px"
            style={{ cursor: "pointer" }}
          />
        </ButtonsContainer>
      </ContentWrapper>
    </ModalTemplate>
  );
}

const modalCustomStyle = {
  height: "70px",
  "border-radius": "8px",
  position: "absolute",
  top: "calc(50vh)",
  transform: "translateY(-50%)",
};

const ContentWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;

const ProfilePicture = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px 15px;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  height: 42px;
  right: 24px;

  & > img {
    padding-left: 12px;
  }
`;

const TopMiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const UnderText = styled.div`
  color: #4e4f50;
  font-size: 14px;
`;
const Pseudo = styled.div`
  color: #d8dadc;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 16px;
`;
