import React, { useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import { GuideType } from '../guides-displayer.component';

import Text from '../../../../components/style/text.component';
import ParticipationDropdown from '../participation-dropdown.component';
import GuideParticipants from './guide-participants.component';

import { toggleParticipation } from '../../../../services/guide.service';
import { useLogin } from '../../../../utils/auth.utils';
import { openProfile } from '../../../user-profile/profile.utils';
import useLocale from '../../../../utils/locale/locale.hook';

import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import MetaData from '../../../../components/seo/meta-data.component';
import StructuredDataEvent from '../../../../components/seo/structured-data-event.component';
import { FancyScrollbar } from '../../../../components/style/fancy-scrollbar.style';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { Flex } from '../../../../components/style/flex.component';

enum SubmitStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

interface CardProps {
  guide: any;
  updateMyParticipation: (newStatus: string) => void;
  openDeleteGuideModal: (guide: any) => void;
  openUpdateGuideModal: (guide: any) => void;
}

export default function GuideDetailCard({
  guide,
  updateMyParticipation,
  openDeleteGuideModal,
  openUpdateGuideModal,
}: CardProps) {
  const locale = useLocale();
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const hasDuration = guide.type === 'event' || guide.type === 'accommodation' || guide.type === 'art_culture';
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);

  const selectParticipationHandler = (newStatus: string) => {
    if (submitStatus === SubmitStatus.LOADING) {
      return;
    }

    setSubmitStatus(SubmitStatus.LOADING);
    toggleParticipation(guide.id, { status: newStatus }).then((res) => {
      setSubmitStatus(SubmitStatus.IDLE);
      if (res.success) {
        updateMyParticipation(newStatus);
      }
    });
  };

  const handleDelete = () => {
    openDeleteGuideModal(guide);
  };

  const handleEdit = () => {
    openUpdateGuideModal(guide);
  };

  const handleShare = () => {};

  const numberDisplay = (number: number) => {
    return number.toString();
  };

  const getWebsite = (website: string) => {
    if (website.slice(0, 7) === 'http://') {
      console.log('website', website);
      return website;
    }
    if (website.slice(0, 8) === 'https://') {
      console.log('website', website);
      return website;
    }
    return 'https://' + website;
  };

  const myParticipation = guide.participants?.find((participant: any) => participant.user?.id === me.id);
  const myParticipationStatus = myParticipation?.status || null;

  const isOwner = me.id === guide.owner.id;

  const mapTypeToLabel = {
    event: locale('guide.type.event.label'),
    bar_night_club: locale('guide.type.bar.label'),
    restaurant: locale('guide.type.restaurant.label'),
    shop: locale('guide.type.shopping.label'),
    association: locale('guide.type.association.label'),
    accommodation: locale('guide.type.accommodation.label'),
    art_culture: locale('guide.type.art.label'),
  };

  const participantsGoing = guide.participants?.filter((participant: any) => participant.status === 'going');
  const startDate = moment(guide.start_datetime).format('DD/MM/YYYY');
  const endDate = moment(guide.end_datetime).format('DD/MM/YYYY');

  return (
    <Wrapper isDesktop={isDesktop} center={!hasDuration}>
      {guide && (
        <MetaData
          title={locale('seo.guide.title', {
            title: guide.title,
            city: guide.city,
          })}
          description={
            guide.description
              ? guide.description
              : locale('seo.guide.description', {
                  title: guide.title,
                  type: mapTypeToLabel[guide.type],
                  city: guide.city,
                })
          }
          ogImage={guide.picture.path ? guide.picture.path : null}
        />
      )}
      {guide && guide.type == 'event' && (
        <StructuredDataEvent
          venue={guide.venue}
          city={guide.city}
          startDate={guide.start_datetime}
          endDate={guide.end_datetime}
          description={guide.description}
          name={guide.title}
          eventAttendanceMode="https://schema.org/OfflineEventAttendanceMode"
          organizer={guide.organizer}
          image={guide?.picture?.path ? guide.picture.path : null}
        />
      )}
      <CardContent bigSize={!hasDuration}>
        <CardPicture alt="" src={guide.picture.path} />
        <MainContent>
          <Flex gap="8px" justify="space-between" direction={isDesktop ? 'row' : 'column'}>
            <GeneralInfo isOwner={isOwner}>
              <Text fontSize="20px" bold>
                {guide.title}
              </Text>
              <Text fontSize="12px" style={{ fontWeight: 400 }}>
                {hasDuration ? guide.address : guide.city}
              </Text>

              {hasDuration && (
                <Flex alignItems="center" style={{ marginTop: 8 }}>
                  <img
                    src="/assets/icons/sidebar/navbar/calendar-mini.svg"
                    alt="calendar"
                    style={{ marginRight: 8 }}
                    width={12}
                    height={12}
                  />
                  <Text fontSize="12px" color={colors.lightGrey9} style={{ marginRight: 16, fontWeight: 400 }}>
                    {hasDuration && startDate !== endDate ? `${startDate} - ${endDate}` : startDate}
                  </Text>
                </Flex>
              )}

              <Text className="participants" style={{ marginTop: 8 }}>
                {`${numberDisplay(participantsGoing.length)} ${locale('guide.events.participants')} • ${numberDisplay(
                  guide.participants.length - participantsGoing.length
                )} ${locale('guide.events.interested')}`}
              </Text>
            </GeneralInfo>
            <Actions>
              <ShareAndParticipationContainer>
                <PrimaryButton size="large" onClick={handleShare} isActive={true}>
                  <img alt="" width="18.23px" height="16.83px" src="/assets/icons/icons-actions-share.svg" />
                </PrimaryButton>
                <ParticipationDropdown
                  status={myParticipationStatus}
                  loading={submitStatus === SubmitStatus.LOADING}
                  onSelect={selectParticipationHandler}
                />
              </ShareAndParticipationContainer>
              {isOwner && (
                <EditAndDeleteButtonsContainer>
                  <EditButton onClick={handleEdit}>
                    <img alt="" width="12px" height="12px" src="/assets/icons/guides/icons-edit.svg" />
                    <p>{locale('global.edit')}</p>
                  </EditButton>
                  <DeleteButton onClick={handleDelete}>
                    <DeletePicture alt="" src="/assets/icons/guides/icons-delete.svg" />
                  </DeleteButton>
                </EditAndDeleteButtonsContainer>
              )}
            </Actions>
          </Flex>
          <Links bigLine={!hasDuration}>
            <LinkItem>
              <img src="/assets/icons/guides/icons-map.svg" />
              <div>
                <p className="title">Address</p>
                <p className="adress">{guide.address}</p>
              </div>
            </LinkItem>
            <LinkItem>
              <img src="/assets/icons/icons-share-site.svg" />
              <div>
                <p className="title">Website</p>
                {/* <a className="value" target="_blank" href={getWebsite(guide.website)}>
                  {guide.website}
                </a> */}
                <p style={{ textOverflow: 'ellipsis' }} className="adress">
                  {guide.website}
                </p>
              </div>
            </LinkItem>

            {/* <LinkItem>
              <img src="/assets/icons/guides/flag.svg" />
              <div>
                <p className="title">Organizer</p>
                <p className="value">{guide.organizer}</p>
              </div>
            </LinkItem> */}

            {/* <LinkItem>
              <img src="/assets/icons/guides/ticket.svg" />
              <div>
                <p className="title">Tickets</p>
                {guide.ticket === '' ? (
                  <p className="value"> There is no tickets </p>
                ) : (
                  <a className="value" target="_blank" href={getWebsite(guide.ticket)}>
                    {guide.ticket}
                  </a>
                )}
              </div>
            </LinkItem> */}
            {/* {guide.email ? (
              <LinkItem>
                <img src="/assets/icons/guides/message-question-circle.svg" />
                <div>
                  <p className="title">Contact</p>
                  <a className="value" href={'mailto:' + guide.email}>
                    {guide.email}
                  </a>
                </div>
              </LinkItem>
            ) : null} */}
          </Links>
          <Description>
            <Text>{guide.description}</Text>
          </Description>
          <Owner adminCreated={guide.admin_created}>
            <OwnerNameAndPicture>
              <img
                alt=""
                src={guide.admin_created ? '/assets/imgs/logo_white.svg' : guide.owner.profilePicture?.path}
                onClick={() => {
                  if (!guide.admin_created) {
                    openProfile(guide.owner.pseudo);
                  }
                }}
              />
            </OwnerNameAndPicture>
            <Flex direction="column" gap="4px">
              <Text bold>{guide.admin_created ? locale('guide.owner.bearwww') : guide.owner.pseudo}</Text>
              <Text fontSize="12px" style={{ fontWeight: 400, color: colors.darkText }}>
                created this guide, thanks !
              </Text>
            </Flex>
          </Owner>
        </MainContent>
      </CardContent>
      {hasDuration && <GuideParticipants participants={guide.participants} />}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isDesktop: boolean; center?: boolean }>`
  width: 100%;
  border-radius: 4px;
  color: ${colors.white};
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  background: ${colors.darkGradient};

  padding: 22px 8px 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  ${(p) => p.center && 'justify-content: center;'}

  @media (max-width: 1000px) {
    max-width: 100vw;
    width: 100vw;
    flex-direction: column;
  }
`;

const CardPicture = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 13px;

  @media (max-width: 1000px) {
    max-width: 100vw;
    height: 211px;
    border-radius: 0;
    margin-bottom: 0;
  }
`;

const CardContent = styled.div<{ bigSize?: boolean }>`
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1000px) {
    max-width: 100vw;
    margin-right: 0;
    margin-bottom: 12px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  }
`;

const MainContent = styled.div`
  position: relative;
  padding: 16px;
  background-color: transparent;
  border-radius: 4px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

const Description = styled.div`
  width: calc(100% - 2px);
  margin-top: 16px;
  margin-bottom: 24px;
  white-space: pre-line;

  & > p {
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
  }

  ${FancyScrollbar}
`;

const GeneralInfo = styled.div<{ isOwner?: boolean }>`
  max-width: ${(p) => (p.isOwner ? 'calc(100% - 381px)' : 'calc(100% - 238px)')};
  text-overflow: ellipsis;
  margin-bottom: 24px;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  & > .date {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    color: ${colors.white};
    margin-bottom: 8px;
  }

  & > .title {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    color: ${colors.white};
    margin-bottom: 8px;
  }

  & > .infos {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    color: ${colors.white};
    margin-bottom: 8px;
  }

  & > .participants {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    color: ${colors.white};
  }

  @media (max-width: 1000px) {
    width: 100%;
    max-width: unset;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  top: 16px;
  right: 16px;

  @media (max-width: 1000px) {
    position: static;
    width: 100%;
    flex-direction: column;
  }
`;

const ShareAndParticipationContainer = styled.div`
  display: flex;
  gap: 8px;
  height: fit-content;
`;

const EditAndDeleteButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    margin-top: 8px;
  }
`;

const EditButton = styled.button`
  width: 89px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #2d2d2e;
  border: none;
  border-radius: 4px;
  margin-right: 8px;
  margin-left: 8px;

  & > p {
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: white;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

const DeleteButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(255, 0, 109, 0.1);
  border: none;
  border-radius: 4px;
`;

const DeletePicture = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
`;

const Links = styled.div<{ bigLine?: boolean }>`
  margin-top: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: wrap;
  flex-wrap: wrap;
  margin-right: 4px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 12px;
  }
`;

const LinkItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  & > img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    & > a {
      cursor: pointer;
    }

    & > .title {
      margin: 0;
      font-size: 14px;
      color: ${colors.white};
      font-family: 'Inter';
    }

    & > .value {
      margin: 0;
      font-size: 14px;
      font-family: 'Inter';
      color: ${colors.darkBlue};
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & > .adress {
      margin: 0;
      font-size: 12px;
      color: ${colors.white};
    }
  }
}
`;

const Owner = styled.div<{ adminCreated: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  gap: 8px;

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  & > p {
    color: ${colors.white};
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
  }

  & > div {
    & > img {
      cursor: ${(props) => (props.adminCreated ? 'auto' : 'pointer')};
      border-radius: ${(props) => (props.adminCreated ? '0' : '50%')};
      width: 24px;
      height: 24px;
      object-fit: cover;
      margin-right: 8px;
    }

    & > p {
      color: ${colors.white};
      font-size: 14px;
      font-family: 'Inter';
      font-weight: 600;
    }
  }
`;

const OwnerNameAndPicture = styled.div`
  display: flex;
  align-items: center;
`;
