import { validDaily, validPornhub, validRedtube, validXhamster, validXvideos, validYT } from './regex.util';

export const getEmbedLink = (url) => {
  const idDaily = validDaily(url);
  const idYt = validYT(url);
  const idPornhub = validPornhub(url);
  const idXvideos = validXvideos(url);
  const idXhamster = validXhamster(url);
  const idRedtube = validRedtube(url);

  if (idYt) {
    return 'https://www.youtube.com/embed/' + idYt + '?origin=https://d13s4g3q9ouuo3.cloudfront.net/';
  }
  if (idDaily) {
    return 'https://www.dailymotion.com/embed/video/' + idDaily;
  }
  if (idPornhub) {
    return 'https://www.pornhub.com/embed/' + idPornhub;
  }
  if (idXvideos) {
    return 'https://www.xvideos.com/embedframe/' + idXvideos;
  }
  if (idXhamster) {
    return 'https://www.xHamster.com/embed/' + idXhamster;
  }
  if (idRedtube) {
    return 'https://embed.redtube.com/?id=' + idRedtube;
  }
  return '';
};
