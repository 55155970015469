import { del, get, post, put, queryBuilder } from './utils.service';
import * as queryString from 'query-string';

export const setRole = async (role) => {
  const res = await put(`/users/role/${role}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getMe = async () => {
  try {
    const res = await get(`/users/me`);
    const payload = await res.json();
    return payload.status === 'success' ? payload.data : { error: payload.message };
  } catch (e) {
    return null;
  }
};

export const getMyAlbums = async () => {
  const res = await get(`/users/me/albums`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserProfile = async (pseudo: string) => {
  try {
    const res = await get(`/users/user-profile/${pseudo}`);
    const payload = await res.json();
    return payload.status === 'success' ? payload.data : { error: payload.message };
  } catch (e) {
    return null;
  }
};

export const getAllUserLikedMyPictures = async () => {
  const res = await get('/users/liked-my-pictures');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserVideosByUserId = async (userId: string) => {
  const res = await get(`/users/user-videos/${userId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateLocation = async (query) => {
  const res = await put(`/users/update-location${queryBuilder(query)}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateUser = async (user) => {
  const res = await put(`/users`, { ...user });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const pseudoIsFree = async (pseudo: string) => {
  const res = await get(`/users/pseudo-is-free/${pseudo}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const emailIsFree = async (email: string) => {
  const res = await get(`/users/email-free/${email}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const phoneIsFree = async (phone: string) => {
  const res = await get(`/phone-verification/phone-free/${phone}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const setProfilePicture = async (pictureId: string): Promise<any> => {
  const res = await put(`/users/profile-picture/${pictureId}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleFavorite = async (body: { targetId: string }) => {
  const res = await post('/users/toggle-favorite', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const visitProfile = async (body: { targetId: string }) => {
  const res = await post('/users/visit-profile', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getProfilesVisited = async () => {
  const res = await get('/users/profiles-visited');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getOwnProfileVisits = async () => {
  const res = await get('/users/profile-visits');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleBlock = async (targetId: string) => {
  const body = { targetId };
  const res = await post('/users/toggle-block', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUsersBlocked = async () => {
  const res = await get('/users/users-blocked');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getNumberOfOnlineUsers = async () => {
  const res = await get('/users/number-of-online-users');
  const payload = await res.json();
  return payload.status === 'success' ? payload?.data?.count : '-';
};

export const setPersonalNote = async (body: any) => {
  const res = await post('/users/personal-note', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserBySearch = async (query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/users/search?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserHashtags = async () => {
  const res = await get('/users/hashtags');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleUserHashtag = async (hashtagId: number) => {
  const res = await post('/users/toggle-hashtag', { hashtagId });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const resetUserHashtags = async () => {
  const res = await del('/users/reset-hashtags');
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
