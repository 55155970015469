import { store } from "../store/configure.store";

export const isSexual = (text) => {
    const keys = store.getState().localeStore.keys;
    if (keys["blacklist.sexual"]) {
        let blacklistSexualWord = keys["blacklist.sexual"].message.trim().toLowerCase().split(",");
        for (let word of blacklistSexualWord) {
            if (text.toLowerCase().includes(word)) return true;
        }
    } else {
        return true;
    }
    return false;
}

