import styled from "styled-components/macro";
import Button from "../../../components/forms/button.component";
import Text from "../../../components/style/text.component";
import useLocale from "../../../utils/locale/locale.hook";

const Container = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  :after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;

    /* Chrome / Safari / etc. */
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      /* background-color: rgba(255, 255, 255, .5); */
      background-color: none;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }

    /* Firefox */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const Modal = styled.div`
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100px);
`;

type NoGeolocationPermissionProps = {
  onDone: () => void;
};

const NoGeolocationPermission = (props: NoGeolocationPermissionProps) => {
  const locale = useLocale();

  return (
    <Container>
      <Modal>
        <Text h2 style={{ marginRight: 16, color: "black" }}>
          {locale("nearby.no_geolocation_permission")}
        </Text>
        <Button
          color={"primary"}
          text={locale("global.done")}
          onClick={() => props.onDone()}
        />
      </Modal>
    </Container>
  );
};

export default NoGeolocationPermission;
