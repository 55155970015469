import { getCurrentLanguage } from "../../utils/locale/hreflang.utils";

const AppStoreButton = ({ style }) => {
  const linkAppleStore = () => {
    switch (getCurrentLanguage()) {
      case "en":
        return "https://apps.apple.com/us/app/bearwww-gay-bear/id531299792";
      case "fr":
        return "https://apps.apple.com/fr/app/bearwww-appli-de-chat-gay/id531299792";
      case "it":
        return "https://apps.apple.com/it/app/bearwww-gay-bear/id531299792";
      case "es":
        return "https://apps.apple.com/es/app/bearwww-gay-bear/id531299792";
      case "ca":
        return "https://apps.apple.com/es/app/bearwww-gay-bear/id531299792";
      case "pt":
        return "https://apps.apple.com/pt/app/bearwww-gay-bear/id531299792";
      case "el":
        return "https://apps.apple.com/gr/app/bearwww-gay-bear/id531299792";
      case "ja":
        return "https://apps.apple.com/us/app/bearwww-gay-bear/id531299792";
      case "nl":
        return "https://apps.apple.com/nl/app/bearwww-gay-bear/id531299792?l=en";
      case "de":
        return "https://apps.apple.com/de/app/bearwww-gay-bear/id531299792";
      case "pl":
        return "https://apps.apple.com/pl/app/bearwww-gay-bear/id531299792";
    }
  };

  return (
    <a href={linkAppleStore()} target="_blank">
      <img
        alt="Bearwww App on the App Store"
        src="/assets/imgs/signin/appstore.svg"
        style={style}
      />
    </a>
  );
};

export default AppStoreButton;
