import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AlbumSection from '../settings/components/edit-pictures/album-section.component';
import styled from 'styled-components';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';
import useLocale from '../../utils/locale/locale.hook';
import { useState } from 'react';
import { Flex } from '../../components/style/flex.component';
import Text from '../../components/style/text.component';

type Tabs = 'private' | 'quickshare';

export const PicturesScreen = () => {
  const locale = useLocale();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<Tabs>('private');

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab);
    history.push(`/pictures/${tab}`);
  };

  return (
    <div>
      <FixedHeader>
        <TitleWrapper>
          <img src="/assets/icons/sidebar/navbar/lock-album-white.svg" width={26} height={24} />
          <Title>{locale('account.side_menu.my_pictures')}</Title>
        </TitleWrapper>
        <Header>
          <Row>
            <FilterButton active={activeTab === 'private'} onClick={() => handleTabClick('private')}>
              {locale('global.settings.pictures.private_pictures')}
            </FilterButton>
            <FilterButton active={activeTab === 'quickshare'} onClick={() => handleTabClick('quickshare')}>
              {locale('global.settings.pictures.quickshare')}
            </FilterButton>
          </Row>
        </Header>
      </FixedHeader>

      <Content>
        <Flex
          direction="column"
          gap="16px"
          style={{ background: '#15191FE5', border: '1px solid #202939', padding: 14, marginLeft: 10, marginRight: 10 }}
        >
          <Flex alignItems="flex-start" gap="18px">
            <img src="/assets/icons/shared-albums/info.svg" />
            <Flex direction="column" gap="4px">
              <Text style={{ fontWeight: 700 }}> {locale('edit-albums.info.title')}</Text>
              <Text fontSize="12px" bold color={colors.darkText}>
                {locale(`edit-albums.info.${activeTab}`)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Switch>
          <Route exact path="/pictures/private" render={() => <AlbumSection albumName="myPrivatePhotos" />} />
          <Route exact path="/pictures/quickshare" render={() => <AlbumSection albumName="quickshare" />} />
          <Route exact path="/pictures">
            <Redirect to="/pictures/private" />
          </Route>
        </Switch>
      </Content>
    </div>
  );
};

const Content = styled.div`
  padding-top: 100px;
`;

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: ${fonts.bold.weight};
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
    width: 100%;
    margin: 0;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
`;
