import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';

import Text from '../style/text.component';

type RadioBoxProps = {
  style?: any;
  text?: string;
  value?: boolean;
  onChange?: () => void;
  size?: 'default' | 'small';
};

const RadioBox = (props: RadioBoxProps) => {
  return (
    <Container onClick={() => props?.onChange()} style={props?.style}>
      <Case style={{
        width: props.size === 'small' ? '16px' : '24px',
        height: props.size === 'small' ? '16px' : '24px',
      }}>{props.value && <WhiteCircle style={{
        width: props.size === 'small' ? '8px' : '12px',
        height: props.size === 'small' ? '8px' : '12px',
      }} />}</Case>
      <Text color={colors.white}>{props?.text}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0px;
  cursor: pointer;
`;

const Case = styled.div`
  border: solid 1px ${colors.lightGrey2};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border-radius: 50%;
  background: white;
`;

const WhiteCircle = styled.div`
  border-radius: 50%;
  background-color: ${colors.blue};
`;

export default RadioBox;
