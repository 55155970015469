import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Text from '../style/text.component';
import Button from '../forms-v2/button.component';

import { store } from '../../store/configure.store';
import NavbarSlice from '../../store/slices/navbar.slice';
import { sendActivationEmail } from '../../services/auth.service';
import { useLogin } from '../../utils/auth.utils';
import useLocale from '../../utils/locale/locale.hook';

import colors from '../../themes/colors-v2.theme';

enum EmailStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface BannerProps {
  visible: boolean;
}

export default function AccountActivationBanner({ visible }: BannerProps) {
  const { isLogin, me } = useLogin();
  const locale = useLocale();
  const localeStore = useSelector((state: any) => state.localeStore);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [status, setStatus] = useState(EmailStatus.IDLE);

  const sendEmailHandler = async () => {
    if (status === EmailStatus.LOADING || status === EmailStatus.SUCCESS) {
      return;
    }

    setStatus(EmailStatus.LOADING);
    const res = await sendActivationEmail({ language: localeStore.language });
    if (res.success) {
      setStatus(EmailStatus.SUCCESS);
    } else {
      setStatus(EmailStatus.ERROR);
    }
  };

  if (!isLogin) return <></>;

  return (
    <BannerWrapper hidden={!visible} isDesktop={isDesktop}>
      <LeftWrapper isDesktop={isDesktop}>
        {status !== EmailStatus.SUCCESS ? (
          <>
            <img alt="" src="/assets/icons/info-outlined-black.svg" />
            <Text style={{ color: colors.backgroundDark, fontWeight: 600 }}>
              {locale('email_activation.banner.email_not_validated', {
                email: me.email,
              })}
            </Text>
          </>
        ) : (
          <>
            <img alt="" src="/assets/icons/check-filled-black.svg" />
            <Text style={{ color: colors.backgroundDark, fontWeight: 600 }}>
              {locale('email_activation.banner.new_email_sent', {
                email: me.email,
              })}
            </Text>
          </>
        )}
      </LeftWrapper>
      <RightWrapper>
        <Button
          text={locale('email_activation.banner.send_new_email')}
          style={{ padding: '0 40px' }}
          loading={status === EmailStatus.LOADING}
          onClick={sendEmailHandler}
        />
        <img
          alt=""
          src="/assets/icons/close-filled-black.svg"
          width="16"
          height="16"
          style={{ cursor: 'pointer' }}
          onClick={() => store.dispatch(NavbarSlice.actions.setShowAccountActivationBanner(false))}
        />
      </RightWrapper>
    </BannerWrapper>
  );
}

const BannerWrapper = styled.div<{ hidden: boolean; isDesktop: boolean }>`
  z-index: 11;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.hidden ? '0' : '4px 17px')};
  background-color: #fff;

  overflow: hidden;
  max-height: ${(props) => (props.hidden ? '0' : props.isDesktop ? '40px' : '250px')};
  transition: all 300ms ease;

  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;

const LeftWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.isDesktop ? '0' : '10px')};

  & > img {
    margin-right: ${(props) => (props.isDesktop ? '12px' : '6px')};
  }
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-left: 12px;
  }

  @media (max-width: 1000px) {
    & > img {
      margin-left: 24px;
    }
  }
`;
