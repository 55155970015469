const fonts = {
  black: { name: 'Poppins', weight: '900' },
  extraBold: { name: 'Poppins', weight: '700' },
  bold: { name: 'Poppins', weight: '600' },
  semiBold: { name: 'Poppins', weight: '500' },
  regular: { name: 'Poppins', weight: '400' },
  light: { name: 'Poppins', weight: '300' },
};

export default fonts;
