import { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyledInput } from '../../../../components/forms/input.component';
import { updateUser } from '../../../../services/users.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { useDebounce } from 'use-debounce';

type Props = {
  me?: any;
  onChange?: () => void;
};

const City = (props: Props) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const inputRef = useRef();

  const [city, setCity] = useState(props?.me?.city);
  const [debouncedValue] = useDebounce(city, 500);

  async function updateCity() {
    await updateUser({ city: debouncedValue });
    props?.onChange?.();
  }

  useEffect(() => {
    if (debouncedValue !== props.me?.city) {
      updateCity();
    }
  }, [debouncedValue]);

  return (
    <>
      <StyledInput
        ref={inputRef}
        value={city}
        onChange={(e) => setCity(e.target.value)}
        placeholder={locale('profil_edit.no_city')}
      />
    </>
  );
};
export default City;
