import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import ModalTemplate from '../../../../components/miscellaneous/modal-template.component';
import ModalSlice from '../../../../store/slices/modal.slice';
import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import useLocale from '../../../../utils/locale/locale.hook';
import { UserModalTemplate } from '../../../user-profile/components/user-modal.component';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';

export const LiveTermsModal = (props) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [termsAccepted, setTermsAccepted] = useState(false);

  const closeModal = () => {
    dispatch(ModalSlice.actions.setLiveTerms(false));
  };

  const clickAcceptTerms = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <UserModalTemplate
      isOpen={props.visible}
      onClose={closeModal}
      style={{
        width: isDesktop ? '500px' : '100vw',
        height: 'fit-content',
        background: colors.darkGray,
        position: 'absolute',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Container>
        <div
          style={{
            border: `1px solid ${colors.grayBorder}`,
            borderRadius: '100%',
            width: 56,
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: ' center',
          }}
        >
          <img src="/assets/icons/sidebar/navbar/live-white.svg" alt="live" width={24} height={24} />
        </div>
        {!isDesktop && (
          <MobileClose onClick={closeModal}>
            <img alt="close" width="14" height="14" src={`/assets/icons/arrow-left-black.svg`} />
            <p>{locale('live.back')}</p>
          </MobileClose>
        )}
        <ModalContent>
          <Title style={{ paddingRight: '12px' }}>{locale('stream.condition.title')}</Title>

          <SmallText>{locale('stream.condition.desc')}</SmallText>
          {/*<Item>
          <Icon alt="" src="/assets/icons/forbidden.svg" />
          <ItemText>{locale("stream.condition.warning.1")}</ItemText>
        </Item>*/}
          <Item>
            <Icon alt="" src="/assets/icons/forbidden.svg" />
            <ItemText>{locale('stream.condition.warning.2')}</ItemText>
          </Item>
          <Item>
            <Icon alt="" src="/assets/icons/forbidden.svg" />
            <ItemText>{locale('stream.condition.warning.3')}</ItemText>
          </Item>
          <Item>
            <Icon alt="" src="/assets/icons/forbidden.svg" />
            <ItemText>{locale('stream.condition.warning.4')}</ItemText>
          </Item>
        </ModalContent>
        <ModalFooter>
          <CheckBox onClick={clickAcceptTerms} isAccepted={termsAccepted}>
            <CheckItem>{termsAccepted && <CheckedItem />}</CheckItem>
            <Label>{locale('stream.condition.accept')}</Label>
          </CheckBox>
          {termsAccepted ? (
            <Link to="/live/start">
              <PrimaryButton size="large" isActive={termsAccepted} style={{ width: '100%' }}>
                {locale('stream.condition.got.it')}
              </PrimaryButton>
            </Link>
          ) : (
            <PrimaryButton size="large" isActive={termsAccepted} style={{ width: '100%' }}>
              {locale('stream.condition.got.it')}
            </PrimaryButton>
          )}
        </ModalFooter>
      </Container>
    </UserModalTemplate>
  );
};

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 21px;

  @media (max-width: 1000px) {
    padding: 16px 12px;
    background-color: ${colors.white};
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 4px;
  }
`;

const Container = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${colors.darkerGrey};
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  font-size: 14px;
  letter-spacing: -0.39px;
`;

const Title = styled(Text)`
  color: ${colors.white};
  font-size: 18px;
  font-weight: ${fonts.bold.weight};
  line-height: 28px;
`;

const ItemText = styled(Text)`
  color: ${colors.white};
  font-size: 14px;
  font-weight: ${fonts.regular.weight};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    background-color: ${colors.white};
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 1000px) {
    padding-left: 12px;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  padding-right: 20px;
`;

const SmallText = styled.div`
  font-weight: ${fonts.semiBold.weight};
  font-size: 14px;
  padding-bottom: 21px;
  margin-top: 8px;

  @media (max-width: 1000px) {
    padding: 0 12px 16px 12px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    background-color: ${colors.white};
    padding: 12px;
    box-shadow: 0px -1px 2px rgba(16, 24, 40, 0.05);
  }
`;

const CheckBox = styled.div<{ isAccepted?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 21px;
  cursor: pointer;
  background: ${(props) => (props.isAccepted ? '#0E493B' : '')};
  border: 1px solid transparent;
  border-color: ${(props) => (props.isAccepted ? '#00CA99' : '')};
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const CheckItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  border: solid 0.3px ${colors.lightGrey9};
  border-radius: 4px;
  margin-right: 8px;
  box-sizing: border-box;
  padding: 2px;
`;

const CheckedItem = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00ca99;
  border-radius: 3px;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-weight: ${fonts.semiBold.weight};
  font-size: 14px;
  color: ${colors.white};
`;

const Button = styled.div<{ active: boolean }>`
  ${(props) => (props.active ? 'cursor: pointer;' : '')}
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? colors.darkOrange1 : colors.darkGrey2)};
  color: ${(props) => (props.active ? colors.white : colors.lightGrey9)};
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 10px;
  letter-spacing: 0.33px;
`;

const MobileClose = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
  padding: 21px 17px;
  margin: 0;
  background-color: ${colors.lightGrey8};

  & p {
    margin: 0;
    padding: 0;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;
