import { useState, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components/macro';
import { FancyScrollbar } from '../../../components/style/fancy-scrollbar.style';
import { deleteWoof, getWoofs } from '../../../services/chat.service';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';
import InfiniteScroller from 'react-infinite-scroller';
import { useLogin } from '../../../utils/auth.utils';
import { UserWoof, WoofListItem } from './woof-list-item.component';
import { NoConversations } from './no-conversations.component';
import { useIsPremium } from '../../../utils/useUser.utils';

type Props = {
  onChatClick: (conversationId: string) => void;
};

export const WoofList = ({ onChatClick }: Props) => {
  const locale = useLocale();
  const { isLogin } = useLogin();
  const woofsref = useRef(null);
  const isPremium = useIsPremium();

  const [hasMoreWoofs, setHasMoreWoofs] = useState(false);
  const [scrollerKey, setScrollerKey] = useState(Math.random());
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [hasNoWoof, setHasNoWoof] = useState(true);
  const [lastWoofs, setLastWoofs] = useState<UserWoof[]>([]);

  /**
   * Fonction permettant de récupérer les derniers woofs de l'utilisateur.
   */
  const refreshUserWoofs = () => {
    setHasMoreWoofs(false);
    if (setHasNoWoof) {
      setHasNoWoof(false);
    }
    setShouldResetPagination(true);
    getUserWoofs(0, 20).then((lastWoofs) => {
      if (Array.isArray(lastWoofs)) {
        setShouldResetPagination(false);
        setHasMoreWoofs(lastWoofs.length === 20);
        setLastWoofs(lastWoofs);
        if (setHasNoWoof) {
          setHasNoWoof(lastWoofs.length === 0);
        }
      }
    });
  };

  const getUserWoofs = async (page: number | string, limit: number | string) => {
    const res = await getWoofs(page, limit);
    if (!Array.isArray(res) || res.length === 0) {
      setHasMoreWoofs(false);
      return;
    }
    const newWoofs = lastWoofs.concat(res);
    setLastWoofs(newWoofs);

    if (res.length === 20) {
      setTimeout(() => {
        setHasMoreWoofs(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (shouldResetPagination) {
      setScrollerKey(Math.random());
    }
  }, [shouldResetPagination]);

  useImperativeHandle(woofsref, () => ({
    refreshWoofs: () => {
      refreshUserWoofs();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshUserWoofs();
    }
  }, [isLogin]); // eslint-disable-line

  const getMoreWoofs = async (page: number | string, limit: number | string) => {
    setHasMoreWoofs(false);
    getUserWoofs(page, limit);
  };

  const handleDeleteWoof = (woof: UserWoof) => {
    if (woof.type !== 'woof') {
      return;
    }
    deleteWoof(woof.id);
    refreshUserWoofs();
  };

  return (
    <WoofsWrapper key={scrollerKey} isPremium={isPremium}>
      {hasNoWoof ? (
        <NoConversations iconePath="/assets/icons/user-profile/woof-active.svg" title={locale('messages.empty.woof')} />
      ) : (
        <>
          <InfiniteScroller
            pageStart={0}
            loadMore={getMoreWoofs}
            hasMore={hasMoreWoofs}
            useWindow={false}
            initialLoad={false}
          >
            {lastWoofs.map((woof, index) => (
              <WoofListItem key={index} woof={woof} handleDeleteWoof={handleDeleteWoof} onChatClick={onChatClick} />
            ))}
          </InfiniteScroller>
        </>
      )}
    </WoofsWrapper>
  );
};

const WoofsWrapper = styled.div<{ isPremium?: boolean }>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 8px;
  padding: 0px 5px;
  ${FancyScrollbar}

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    margin-bottom: ${(props) => (props.isPremium ? '60px' : '110px')};
  }
`;
