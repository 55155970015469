import styled from 'styled-components/macro';

import Icons from '../style/icons.component';
import IosSwitch from '../ios-switch/ios-switch.component';
import Text from '../style/text.component';
import colors from '../../themes/colors-v2.theme';
import fonts from '../../themes/fonts.theme';

type CheckboxProps = {
  value: boolean;
  onChange?: (value: boolean) => void;
  icon?: string;
  style?: any;
  text?: string;
  description?: string;
  isHidden?: boolean;
};

const CheckboxSwitch = (props: CheckboxProps) => {
  return (
    <Container onClick={props.onChange ? () => props.onChange(!props.value) : undefined} style={props?.style}>
      <Texts isHidden={props.isHidden}>
        <Title bold style={{ fontSize: 18 }}>
          {!props.icon ? null : (
            <Icons name={props.icon} width="16" height="16" style={{ marginRight: 16, padding: '5px 0px 3px 0px' }} />
          )}
          {props?.text ?? null}
        </Title>
        {props?.description ? <Description>{props.description}</Description> : null}
      </Texts>
      {!props.isHidden && <IosSwitch isOn={props.value} />}
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  color: ${colors.darkerGrey};
  font-weight: bold;
  font-size: 14px;
`;

const Texts = styled.div<{ isHidden: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: ${({ isHidden }) => (isHidden ? 0 : '23px')};
`;

const Description = styled(Text)`
  margin-top: 12px;
  font-size: 15px;
  color: ${colors.darkText};
  font-weight: ${fonts.regular.weight};
`;

const Title = styled(Text)`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

export default CheckboxSwitch;
