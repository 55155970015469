import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { Flex } from '../../../../components/style/flex.component';
import Text from '../../../../components/style/text.component';
import { GroupedHashtags, getHashtags } from '../../../../services/hashtags.service';
import { resetUserHashtags, toggleUserHashtag } from '../../../../services/users.service';
import colors from '../../../../themes/colors-v2.theme';
import { useLogin } from '../../../../utils/auth.utils';
import { useOnClickOutside } from '../../../../utils/clickOutside.utils';
import useLocale from '../../../../utils/locale/locale.hook';

const Hashtags = () => {
  const { me } = useLogin();
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const containerRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [hashtagCategories, setHashtagCategories] = useState([]);
  const [selectedHashtags, setSelectedHashtags] = useState(me?.hashtags?.map((hashtag) => hashtag.id) || []);

  useOnClickOutside([containerRef], () => {
    setIsOpen(false);
  });

  const handleReset = () => {
    setSelectedHashtags([]);
    resetUserHashtags();
  };

  const onSelect = async (hashtagId: GroupedHashtags['hashtags'][number]['id']) => {
    const selectedHashtagsCopy = [...selectedHashtags];
    const index = selectedHashtagsCopy.findIndex((_item) => _item === hashtagId);
    if (index !== -1) {
      selectedHashtagsCopy.splice(index, 1);
    } else {
      selectedHashtagsCopy.push(hashtagId);
    }
    setSelectedHashtags(selectedHashtagsCopy);
    await toggleUserHashtag(hashtagId);
  };

  useEffect(() => {
    async function fetchHashtags() {
      const hashtags = await getHashtags();
      if (hashtags) {
        setHashtagCategories(hashtags);
      }
    }
    fetchHashtags();
  }, []);

  return (
    <>
      {selectedHashtags.length === 0 ? (
        <Box>
          <img src="/assets/icons/hashtag.svg" alt="video" />
          <Title>{locale('profile_edit.hashtag.title')}</Title>

          <PrimaryButton isActive onClick={() => setIsOpen(true)}>
            <div style={{ transform: 'scale(1.4)' }}>+</div>
          </PrimaryButton>
        </Box>
      ) : (
        <Flex direction="column" gap="16px">
          <Flex gap="8px" style={{ flexWrap: 'wrap' }}>
            {hashtagCategories.length > 0 &&
              selectedHashtags?.map((hashtagId) => {
                const hashtag = hashtagCategories
                  .map((category) => category.hashtags)
                  .flat()
                  .find((hashtag) => hashtag.id === hashtagId);
                return (
                  <PrimaryButton
                    size="small"
                    key={hashtag.id}
                    onClick={() => onSelect(hashtag.id)}
                    style={{ fontSize: 14 }}
                  >
                    {locale(hashtag?.traductionKey)}
                    <img src="/assets/icons/cross-outlined-white.svg" alt="cross" width={14} height={14} />
                  </PrimaryButton>
                );
              })}
          </Flex>
          <PrimaryButton isActive size="large" style={{ width: '100%', padding: 8 }} onClick={() => setIsOpen(true)}>
            {locale('profile_edit.hashtag.modify')}
          </PrimaryButton>
        </Flex>
      )}
      {isOpen ? (
        <Container ref={containerRef}>
          <Flex justify="space-between">
            <CloseButtonWrapper
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img alt="" src="/assets/icons/cross-outlined-white.svg" width={24} height={24} />
            </CloseButtonWrapper>
            {selectedHashtags.length > 0 && (
              <div onClick={handleReset} style={{ color: colors.darkBlue, fontWeight: 600, cursor: 'pointer' }}>
                {locale('radar.quickfilter.sidebar.reset')}
              </div>
            )}
          </Flex>

          {hashtagCategories.map((category, index) => {
            return (
              <div key={index} style={{ marginBottom: 32 }}>
                <Text fontSize="14px" style={{ fontWeight: 700, marginBottom: 16 }}>
                  {locale(category.categoryKey)}
                </Text>
                <Content>
                  {category.hashtags.map((hashtag) => {
                    const isActive = selectedHashtags.includes(hashtag.id);
                    return (
                      <PrimaryButton
                        size="small"
                        key={hashtag.id}
                        onClick={() => onSelect(hashtag.id)}
                        isActive={isActive}
                        style={{ fontSize: 14 }}
                      >
                        {locale(hashtag.traductionKey)}
                        {isActive ? (
                          <img src="/assets/icons/cross-outlined-white.svg" alt="cross" width={14} height={14} />
                        ) : (
                          ''
                        )}
                      </PrimaryButton>
                    );
                  })}
                </Content>
              </div>
            );
          })}
        </Container>
      ) : null}
    </>
  );
};
export default Hashtags;

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  width: 362px;
  height: 100vh;
  z-index: 1000;
  background-color: ${colors.dark};
  right: 0;
  top: 0;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.darkGrey2};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;

  @media (max-width: 362px) {
    width: 100%;
  }
`;

const CloseButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  margin-bottom: 16px;
`;

const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 16px;
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.darkGray};
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  padding: 35px;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
`;
