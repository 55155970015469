import { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import Button from '../../../../components/forms/button.component';
import Error from '../../../../components/forms/error.component';
import PasswordInput from '../../../../components/forms/password.input';

import { deleteAccount, updatePseudo } from '../../../../services/auth.service';
import { logout } from '../../../../utils/auth.utils';
import useLocale from '../../../../utils/locale/locale.hook';
import Text from '../../../../components/style/text.component';
import colors from '../../../../themes/colors-v2.theme';
import { useHistory } from 'react-router-dom';

const DeleteAccount = () => {
  const [editOpen, setEditOpen] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [password, setPassword] = useState(null);
  const locale = useLocale();
  const meStore = useSelector((state: any) => state.meStore);

  const submit = async () => {
    setErrorMsg(null);
    const res = await deleteAccount(meStore.id, password);
    if (res?.error === 'invalid_id') {
      setErrorMsg(locale('account.account_gestion.wrong_password'));
      return;
    }
    logout();
    window.location.replace('/');
  };

  return (
    <>
      <Container>
        {editOpen ? (
          <Form>
            <PasswordInput
              onChange={(text) => setPassword(text)}
              style={{
                zoom: 0.7,
                marginBottom: 15,
              }}
              borderRadius={8}
              placeholder={locale('signup.credentials.password')}
            />
            {errorMsg && <Error errorMsg={errorMsg} style={{ zoom: 0.8, marginBottom: 8 }} />}
            <Button
              disabled={!password}
              text={locale('account.account_gestion.delete_account')}
              onClick={() => submit()}
              style={{
                borderRadius: 8,
                backgroundColor: password ? colors.darkOrange1 : colors.lightGrey7,
                color: password ? colors.white : colors.darkGrey2,
                borderColor: password ? colors.darkOrange3 : colors.lightGrey7,
              }}
            />
          </Form>
        ) : (
          <DeleteButton onClick={() => setEditOpen(true)}>
            <Text>{locale('account.account_gestion.delete_account')}</Text>
          </DeleteButton>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;

  & input {
    box-shadow: '0px 1px 2px rgba(16, 24, 40, 0.05)';
    border-color: ${colors.lightGrey9};

    &::placeholder {
      font-size: 16px;
      color: ${colors.darkGrey1};
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const DeleteButton = styled.div`
  padding: 8px 25px;
  border: 1px solid ${colors.lightRed};
  margin: auto;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  color: ${colors.white};
  background: ${colors.lightRed};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;

  &:hover {
    background-color: rgb(255, 255, 255, 0.1);
    transition: all 0.2s;
  }
`;

export default DeleteAccount;
