import React from "react";
import styled from "styled-components/macro";
import colors from "../../../themes/colors.theme";
import Icons from "../../style/icons.component";
import Text from "../../style/text.component";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { convertUrlToFile } from "../photos.utils";

const Container = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Content = styled.div`
  background-color: ${colors.light};
  border-radius: 20px;
  width: 500px;
`;

const Header = styled.div`
  height: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 25px;
`;

const Footer = styled.div`
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Image = styled.div`
  min-height: 200px;
  background-color: #828282;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const Done = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 10px;
  right: 20px;
  z-index: 100;
`;

type PhotoEditorProps = {
  photo: any;
  onDone: (file: any) => void;
  onClose: () => void;
};

const PhotoEditor = (props: PhotoEditorProps) => {
  const cropperRef = React.useRef<any>(null);
  const [rotate, setRotate] = React.useState(0);

  const onRotate = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    if (cropper) cropper.rotate(90);
  };

  const onClose = () => {
    props?.onClose();
  };

  const onDone = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    var file = convertUrlToFile(cropper.getCroppedCanvas().toDataURL());
    props?.onDone(file);
  };

  return (
    <Container>
      <Content>
        <Header>
          <Text body dark>
            Edit your photo
          </Text>
          <IconButton onClick={onClose}>
            <Icons name="cross-outlined-black" width="25" height="25" />
          </IconButton>
        </Header>
        <Image>
          <Cropper
            src={props?.photo}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            aspectRatio={8 / 10}
            viewMode={1}
            rotateTo={rotate}
            guides={true}
            ref={cropperRef}
          />
        </Image>
        <Footer>
          <IconButton onClick={onRotate}>
            <Icons name="rotate-outlined-black" width="20" height="20" />
          </IconButton>
          <Done onClick={onDone}>
            <Text bodySemiBold dark>
              Done
            </Text>
          </Done>
        </Footer>
      </Content>
    </Container>
  );
};

export default PhotoEditor;
