import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import ModalSlice from '../../../../../store/slices/modal.slice';
import { checkEphemeral } from '../../../../../services/chat.service';
import ChatSlice from '../../../../../store/slices/chat.slice';
import fonts from '../../../../../themes/fonts.theme';
import colors from '../../../../../themes/colors-v2.theme';
import useLocale from '../../../../../utils/locale/locale.hook';
import Gradient from '../gradient';
import CountdownComponent from '../countdown.component';

interface EphemeralMessageProps {
  message: any;
  sender: 'other' | 'me';
  setConversationMessages: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function EphemeralMessage({ message, sender, setConversationMessages }: EphemeralMessageProps) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const [hasTimer, setHasTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(null);
  const [expired, setExpired] = useState<boolean>(null);

  const shouldSeePictures = sender === 'other';

  const MorePictures = (props) => {
    if (props.id !== 3 || props.size <= 4) return <> </>;
    const value = props.size - 3;

    return (
      <div>
        <MoreValue>{`+${value}`}</MoreValue>
      </div>
    );
  };

  const checkEphemeralHandler = () => {
    checkEphemeral({
      messageId: message.id,
    }).then((res) => {
      if (res?.success && res.available) {
        setExpired(false);
        setConversationMessages((prevMessages) => {
          const newMessages: any[] = JSON.parse(JSON.stringify(prevMessages));
          const messageIndex = newMessages.findIndex((_message) => _message.id === message.id);
          const foundMessage = newMessages[messageIndex];
          if (!!foundMessage) {
            setTimeLeft(res.timeRemaining);
            setHasTimer(true);
            foundMessage.pictures = res.pictures;
            if (messageIndex + 10 > newMessages.length) {
              dispatch(ChatSlice.actions.setScrollToBottom(true));
            }
          }
          return newMessages;
        });
      } else {
        setExpired(true);
      }
    });
  };

  // !! TODO : voir avec les message_seen si on peut pas améliorer le composant et faire l'appel API uniquement si le message n'a pas encore été lu
  useEffect(() => {
    if (shouldSeePictures) {
      checkEphemeralHandler();
    } else {
      setExpired(true);
    }
  }, [shouldSeePictures]);

  useEffect(() => {
    let interval = null;

    if (hasTimer) {
      interval = setInterval(() => {
        setTimeLeft((current) => {
          if (current <= 0) {
            clearInterval(interval);
            setHasTimer(false);
            return 0;
          }

          return current - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [hasTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      setConversationMessages((prevMessages) => {
        const newMessages: any[] = JSON.parse(JSON.stringify(prevMessages));
        const foundMessage = newMessages.find((_message) => _message.id === message.id);
        foundMessage.pictures = [];
        return newMessages;
      });
      setExpired(true);
    }
  }, [timeLeft]);

  return (
    <Wrapper sender={sender}>
      {expired === true && (
        <Disclaimer sentByMe={sender === 'me'}>
          {sender === 'me' ? locale('chat.ephemeral.available') : locale('chat.ephemeral.expired')}
        </Disclaimer>
      )}
      {expired === false && (
        <>
          <MessagePictures sender={sender}>
            {message.pictures.slice(0, 4).map((picture: any, index: number) => (
              <Picture
                key={index}
                onClick={() => {
                  dispatch(
                    ModalSlice.actions.setFullSizeImageVisibleVisible({
                      visible: true,
                      images: message.pictures,
                      start: index,
                      timeLeft: timeLeft,
                      type: 'ephemeral',
                      isLikeable: true,
                    })
                  );
                }}
                isAlone={message.pictures.length === 1}
              >
                <img alt="" src={picture.path} />
                <MorePictures
                  id={message.pictures.findIndex((_picture: any) => _picture.id === picture.id)}
                  size={message.pictures.length}
                />
              </Picture>
            ))}
          </MessagePictures>
          <CountdownComponent timeleft={timeLeft} style={{ top: '8px', left: '8px', position: 'absolute' }} />
          <Gradient />
        </>
      )}
      {expired === null && <Disclaimer style={{ width: 150 }}>&nbsp;</Disclaimer>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ sender: 'me' | 'other' }>`
  display: flex;
  align-items: center;
  margin-left: ${(p) => (p.sender === 'me' ? 'auto' : '0')};
  margin-right: ${(p) => (p.sender === 'me' ? '0' : 'auto')};
  background-color: none;
`;

const Disclaimer = styled.div<{ sentByMe?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;

  font-size: 14px;
  color: ${colors.white};
`;

const MessagePictures = styled.div<{ sender: 'me' | 'other' }>`
  margin: ${(p) => (p.sender === 'me' ? '0 0 0 auto' : '0 auto 0 0')};
  max-width: 400px;

  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.sender === 'me' ? 'flex-end' : 'flex-start')};
`;

const Picture = styled.div<{ isAlone: boolean }>`
  width: ${(p) => (p.isAlone ? '280px' : '138px')};
  height: ${(p) => (p.isAlone ? '280px' : '138px')};
  cursor: pointer;

  & > img {
    border-radius: 4px;
    object-fit: cover;
    width: ${(p) => (p.isAlone ? '280px' : '136px')};
    height: ${(p) => (p.isAlone ? '280px' : '136px')};
  }
`;

const MoreValue = styled.p`
  margin: 0;
  left: 24px;
  bottom: 29px;
  font-size: 64px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.white};
  position: absolute;
  text-shadow: 0 1px 11px rgb(16 17 19 / 50%);
`;
