import { useCallback, useEffect, useMemo, useState } from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import styled from 'styled-components';
import colors from '../../../../themes/colors-v2.theme';
import Text from '../../../../components/style/text.component';
import { Flex } from '../../../../components/style/flex.component';
import { GroupedHashtags } from '../../../../services/hashtags.service';
import SearchBar from '../../../../components/forms/search.component';

type Props<T> = {
  onChange: (data) => void;
  dataSelected: number[];
  getData: () => Promise<T[]>;
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
};

export const HashtagQuickFiltersSidebar = <T extends GroupedHashtags>(props: Props<T>) => {
  const [dataSelected, setDataSelected] = useState(props.dataSelected || []);
  const [data, setData] = useState<T[]>([]);
  const locale = useLocale();
  const [searchTerm, setSearchTerm] = useState('');

  const selectedCategory = {
    category: 'Selected Hashtags',
    hashtags: dataSelected.map((id) =>
      data
        .find((category) => category.hashtags.some((hashtag) => hashtag.id === id))
        ?.hashtags?.find((hashtag) => hashtag.id === id)
    ),
  };

  const filteredHashtags = useMemo(
    () =>
      data.map((category) => ({
        ...category,
        hashtags: category.hashtags.filter((hashtag) =>
          locale(hashtag.traductionKey).toLowerCase().includes(searchTerm.toLowerCase())
        ),
      })),
    [searchTerm, data]
  );

  const onSelect = (hashtagId: GroupedHashtags['hashtags'][number]['id']) => {
    let dataSelectedCopy = [...dataSelected];
    const index = dataSelectedCopy.findIndex((_item) => _item === hashtagId);
    if (props.isDisabled) {
      dataSelectedCopy = [hashtagId];
    } else if (index !== -1) {
      dataSelectedCopy.splice(index, 1);
    } else {
      if (dataSelected.length === 10) return;
      dataSelectedCopy.push(hashtagId);
    }
    setDataSelected(dataSelectedCopy);
    props.onChange(dataSelectedCopy);
  };

  const getData = async () => {
    const _data = await props.getData();
    setData(_data);
  };

  const handleReset = () => {
    setDataSelected([]);
    props.onChange([]);
  };

  useEffect(() => {
    setDataSelected(props.dataSelected);
  }, [props.dataSelected]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {props.isOpen && (
        <Container>
          <Header isDesktop={true}>
            <Flex alignItems="center" gap="8px">
              <CloseButtonWrapper
                onClick={() => {
                  props.onClose();
                }}
              >
                <img alt="" src="/assets/icons/cross-outlined-white.svg" width={24} height={24} />
              </CloseButtonWrapper>
              <Text bold fontSize="16px">
                {locale('radar.quickfilter.sidebar.lookfor')}
              </Text>
            </Flex>

            {dataSelected.length > 0 && (
              <div onClick={handleReset} style={{ color: colors.darkBlue, fontWeight: 600, cursor: 'pointer' }}>
                {locale('radar.quickfilter.sidebar.reset')}
              </div>
            )}
          </Header>

          <SearchBar
            placeholder={locale('hashtag.searchby')}
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
          />

          <CategoriesContainer>
            <Flex justify="space-between">
              <Text fontSize="14px" style={{ fontWeight: 700, marginBottom: 16 }}>
                {locale('hashtag.selected')}
              </Text>
              <Text fontSize="14px" style={{ fontWeight: 700, marginBottom: 16 }}>
                {dataSelected.length}/10
              </Text>
            </Flex>
            <Content>
              {selectedCategory?.hashtags.map((hashtag) => {
                const isActive = dataSelected.includes(hashtag.id) && !props.isDisabled;
                return (
                  <PrimaryButton
                    size="small"
                    key={hashtag.id}
                    onClick={() => onSelect(hashtag.id)}
                    isActive={isActive}
                    style={{ fontSize: 14 }}
                  >
                    {locale(hashtag.traductionKey)}
                    {isActive ? (
                      <img src="/assets/icons/cross-outlined-white.svg" alt="cross" width={14} height={14} />
                    ) : (
                      ''
                    )}
                  </PrimaryButton>
                );
              })}
            </Content>
            {filteredHashtags.map((category, index) => {
              return (
                <div key={index} style={{ marginBottom: 32 }}>
                  <Text fontSize="14px" style={{ fontWeight: 700, marginBottom: 16 }}>
                    {locale(category.categoryKey)}
                  </Text>
                  <Content>
                    {category.hashtags.map((hashtag) => {
                      const isActive = dataSelected.includes(hashtag.id) && !props.isDisabled;
                      return (
                        <PrimaryButton
                          size="small"
                          key={hashtag.id}
                          onClick={() => onSelect(hashtag.id)}
                          isActive={isActive}
                          style={{ fontSize: 14 }}
                        >
                          {locale(hashtag.traductionKey)}
                          {isActive ? (
                            <img src="/assets/icons/cross-outlined-white.svg" alt="cross" width={14} height={14} />
                          ) : (
                            ''
                          )}
                        </PrimaryButton>
                      );
                    })}
                  </Content>
                </div>
              );
            })}
          </CategoriesContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  width: 362px;
  height: 100vh;
  z-index: 1000;
  background-color: ${colors.dark};
  right: 0;
  top: 0;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.darkGrey2};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 362px) {
    width: 100%;
  }
`;

const CloseButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
`;

const CategoriesContainer = styled.div`
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 16px;
`;

const Content = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 16px;
`;

const Header = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 20px;
  ${(props) =>
    !props.isDesktop &&
    `
        justify-content: space-between;
    `};
`;
