import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import useLocale from '../../utils/locale/locale.hook';

import UserViews, { ModalMode } from '../viewers/user-views.component';
import FullHeightPane from '../../components/full-height-pane/full-height-pane.component';

import fonts from '../../themes/fonts.theme';
import ViewsSlice from '../../store/slices/views.slice';
import NotifSlice from '../../store/slices/notification.slice';
import colors from '../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';

const OverlayViews = () => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const viewsStore = useSelector((state: any) => state.viewsStore);
  const [mode, setMode] = useState(ModalMode.VISITORS);
  const fullHeightRef = useRef(null);

  useEffect(() => {
    if (viewsStore.overlayVisible) {
      dispatch(NotifSlice.actions.resetViewsNotSeen(null));
    }
  }, [viewsStore.overlayVisible]);

  const handleClickOutside = (e) => {
    if (!fullHeightRef?.current?.contains(e.target)) {
      dispatch(ViewsSlice.actions.setOverlayVisibility(false));
    }
  };

  return (
    <OverlayWrapper onClick={handleClickOutside}>
      <ViewsModal ref={fullHeightRef}>
        <FullHeightPane isDesktop={isDesktop} width={isDesktop ? 835 : undefined} height={isDesktop ? '90vh' : '100vh'}>
          <TitleAndClose>
            <Title>{locale('views.visitors.label')}</Title>
            <HeaderActions>
              <img
                alt=""
                src="/assets/icons/icons-actions-close-dark.svg"
                width={10}
                height={10}
                onClick={() => {
                  dispatch(ViewsSlice.actions.setOverlayVisibility(false));
                }}
              />
            </HeaderActions>
          </TitleAndClose>
          <Header>
            <Row>
              <FilterButton active={mode === ModalMode.VISITORS} onClick={() => setMode(ModalMode.VISITORS)}>
                {locale('views.visitors.label')}
              </FilterButton>
              <FilterButton active={mode === ModalMode.VISITED} onClick={() => setMode(ModalMode.VISITED)}>
                {locale('views.visited.label')}{' '}
              </FilterButton>
            </Row>
          </Header>
          <OverlayContent>
            <UserViews mode={mode} />
          </OverlayContent>
        </FullHeightPane>
      </ViewsModal>
    </OverlayWrapper>
  );
};

const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 500;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background: none;
  }
`;

const ViewsModal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1000px) {
    position: fixed;
    top: calc(50% + 70px);
    width: calc(100% + 1px);
    padding-top: 40px;
    background-color: ${colors.backgroundLightGrey};
    box-sizing: border-box;
  }
`;

const TitleAndClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: ${fonts.bold.weight};
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  letter-spacing: 0.6px;
  padding-bottom: 10px;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 16px 12px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17px 15px 15px 16px;
  background-color: ${colors.lightGrey8};
  padding: 4px;
  border-radius: 8px;
  gap: 8px;
  margin-top: 16px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
    width: 100%;
    margin: 0;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? colors.white : colors.lightGrey8)};
  outline: none;
  border: none;
  padding: 8px 12px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  color: ${({ active }) => (active ? colors.darkGrey2 : colors.darkGrey1)};
  flex: 1;
  ${({ active }) => active && 'box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),0px 1px 2px rgba(16, 24, 40, 0.06);'}
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
    margin: 0 6px;
    width: 24px;
    height: 24px;
  }
`;

const OverlayContent = styled.div`
  box-sizing: border-box;
  padding-top: 12px;
  width: fit-content;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  @media (max-width: 1000px) {
    padding-top: 0;
  }
`;

export default OverlayViews;
