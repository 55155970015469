import styled from 'styled-components/macro';
import Image from '../../../components/image.component';
import colors from '../../../themes/colors-v2.theme';
import { getStatusColor, Status } from '../../../utils/status.utils';
import { getDurationDate } from '../../../utils/date.utils';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { woofs } from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { useState, useMemo, useRef, useEffect } from 'react';
import { toggleFavorite } from '../../../services/favorite.service';
import MeSlice from '../../../store/slices/me.slice';
import { useLogin } from '../../../utils/auth.utils';
import WoofMenu from '../../user-profile/components/action-menus/woof-menu.component';
import { deleteWoof, initiatePrivateConversation } from '../../../services/chat.service';
import DisplayedConversation from './displayed-conversation.component';
import ChatSlice from '../../../store/slices/chat.slice';
import { openProfile } from '../../user-profile/profile.utils';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';

export interface UserWoof {
  id: number;
  type: string;
  content: string | null;
  createdAt: Date;
  isPhraseKey: string | null;
  woof: { category: string };
  conversation: Conversation;
}

interface Conversation {
  id: number;
  conversation_users: ConversationUser[];
}

interface ConversationUser {
  deletedAt: Date | null;
  user: User;
}

interface User {
  id: string;
  pseudo: string;
  profilePicture: ProfilePicture;
  status: Status;
  isFavorite: boolean;
}

interface ProfilePicture {
  id: string;
  path: string;
  description: string | null;
  uploadDate: Date;
  order: number;
  moderationHive: string;
  moderationRating: string;
  moderationAdmin: string;
  adminCheck: boolean;
  moderation: string;
}

enum ToggleFavoriteStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

interface Props {
  woof: UserWoof;
  handleDeleteWoof: (woof: UserWoof) => void;
  onChatClick: (conversationId: string) => void;
}
export const WoofListItem = (props: Props) => {
  const { woof, handleDeleteWoof } = props;
  const { user } = woof.conversation.conversation_users[0];
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();
  const optionsRef = useRef(null);

  const [isFav, setIsFav] = useState(user.isFavorite);
  const [toggleFavoriteStatus, setToggleFavoriteStatus] = useState(ToggleFavoriteStatus.IDLE);
  const [isWoofMenuOpen, setIsWoofMenuOpen] = useState(false);
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [newConversationUser, setNewConversationUser] = useState(null);

  const isFavorite = useMemo(() => !!me?.favorites?.find((favorite: any) => favorite.id === user.id), [me?.favorites]);

  const toggleFavoriteHandler = (isFavorite: boolean) => {
    if (toggleFavoriteStatus === ToggleFavoriteStatus.LOADING) {
      return;
    }
    setToggleFavoriteStatus(ToggleFavoriteStatus.LOADING);

    toggleFavorite(user.id).then((res) => {
      setToggleFavoriteStatus(ToggleFavoriteStatus.IDLE);
      if (!res.error) {
        if (isFavorite) {
          setIsFav(false);
          dispatch(
            MeSlice.actions.updateMe({
              favorites: me.favorites.filter((favorite: any) => favorite.id !== user.id),
            })
          );
        } else {
          setIsFav(true);
          dispatch(
            MeSlice.actions.updateMe({
              favorites: me.favorites.concat(user),
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    initiatePrivateConversation(user.id).then((res) => {
      if (!!res.error && !!res.message) {
        return;
      }
      setConversationId(res.conversationId);
    });
  }, []);

  return (
    <Container>
      <Content>
        <ProfilePictureContainer>
          <Image
            src={user.profilePicture.path}
            style={{ cursor: 'pointer' }}
            alt="Profile picture"
            width={'72px'}
            height={'72px'}
            optiWidth={100}
            onClick={() => {
              openProfile(user.pseudo, false);
            }}
          />
        </ProfilePictureContainer>
        <TextContainer>
          <Header>
            <Left>
              <StatusCircle color={getStatusColor(user.status).centerCircle} />
              {isFav && (
                <Image src="/assets/icons/messages/star.svg" style={{}} alt="Star" width={'10px'} height={'10px'} />
              )}
              <Pseudo>{user.pseudo}</Pseudo>
            </Left>
            <TimeElapsed>{getDurationDate(woof.createdAt)}</TimeElapsed>
          </Header>
          <Body>
            <Image src={woofs[woof.woof.category].img} style={{}} alt="Woof icon" width="36px" height="36px" />

            <WoofText>{locale(woofs[woof.woof.category].label)}</WoofText>
          </Body>
        </TextContainer>
      </Content>
      <ButtonsContainer>
        <PrimaryButton size="medium" onClick={() => props.onChatClick(conversationId)}>
          {locale('chat.woof.button.chat')}
        </PrimaryButton>
        <PrimaryButton size="medium" onClick={() => setIsWoofMenuOpen(!isWoofMenuOpen)}>
          {locale('chat.woof.button.woof')}
        </PrimaryButton>
        <PrimaryButton size="medium" onClick={() => toggleFavoriteHandler(isFavorite)} isActive={isFav}>
          {locale('chat.woof.button.to_fave')}
        </PrimaryButton>
        <PrimaryButton size="medium" onClick={() => handleDeleteWoof(woof)}>
          <img src="/assets/icons/controls/bin.svg" alt="Delete icon" />
        </PrimaryButton>
      </ButtonsContainer>
      {/* <PrimaryButton onClick={() => handleDeleteWoof(woof)}></PrimaryButton> */}

      {isWoofMenuOpen && (
        <WoofMenu user={user} setIsWoofMenuOpen={setIsWoofMenuOpen} style={{ width: '350px', top: 0 }} />
      )}
      {isConversationOpen && (
        <MessagesWrapper isScreen={true}>
          <DisplayedConversation
            conversationId={woof.conversation.id}
            newConversationUser={null}
            closeConversationHandler={() => {
              setIsConversationOpen(false);
            }}
            options={{
              showCloseConversation: true,
            }}
            optionsRef={optionsRef}
          />
        </MessagesWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 95%;
  padding: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  margin-bottom: 21px;
  position: relative;
  border-bottom: 1px solid ${colors.grayBorder};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

const ProfilePictureContainer = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 4px;

  & img {
    border-radius: 4px;
    object-fit: cover;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  height: 12px;
`;

const StatusCircle = styled.div<{ color: string }>`
  background-color: ${(props) => (props.color === '#000000' ? 'transparent' : props.color)};
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
`;

const Pseudo = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: ${fonts.bold.weight};
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TimeElapsed = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${fonts.regular.weight};
  color: ${colors.lightGrey9};
`;

const Body = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const WoofText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
  font-weight: ${fonts.regular.weight};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 12px 0 0 0;
  > button {
    width: 79px;
    height: 36px;
    border-radius: 8px;
    font-size: 14px;
  }
`;

const MessagesWrapper = styled.div<{ isScreen: boolean }>`
  width: 375px;
  margin-right: 8px;
  height: ${(p) => (p.isScreen ? '70vh' : '100%')};
  background-color: ${colors.backgroundLight};
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  right: 375px;
  z-index: 1012;
`;
