import { Container, Section } from "../settings-styled.component";

import Caracteristics from "./caracteristics.component";
import Community from "./community.component";
import Description from "./description.component";
import Firstname from "./firstname.component";
import Languages from "./language.component";
import LookingFor from "./looking-for.component";
import SexualPractices from "./sexual-practices.component";
import SexualRole from "./sexual-role.component";
import WeightHeightCaracteristics from "./weight-height.component";

import { setMe, useLogin } from "../../../../utils/auth.utils";
import Age from "./age.component";
import styled from "styled-components";
import Attribute from "./attribute.component";
import colors from "../../../../themes/colors-v2.theme";
import { useState } from "react";
import useLocale from "../../../../utils/locale/locale.hook";
import City from "./city.component";
import PresentationVideo from "./presentation-video.component";
import AlbumSection from "../edit-pictures/album-section.component";
import Hashtags from "./hashtags.components";
import { useNotification } from "../../../../utils/useNotification";
import { useIsPremium } from "../../../../utils/useUser.utils";
import { PrimaryButton } from "../../../../components/forms/primaryButton.component";
import { openProfile } from "../../../user-profile/profile.utils";
import { useMediaQuery } from "react-responsive";
import { useRedirectUnsignedUser } from "../../../../utils/useRedirectUnsignedUser";
import Country from "./country.component";

const SectionAboutMe = () => {
  useRedirectUnsignedUser();
  const { me } = useLogin();
  const locale = useLocale();
  const { triggerNotification } = useNotification();
  const isPremium = useIsPremium();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [pictureTab, setPictureTab] = useState<
    "public" | "private" | "quickshare"
  >("public");

  const handleChange = () => {
    setMe();
    triggerNotification({
      title: locale("settings.modification_success"),
    });
  };

  return (
    <>
      <Container isPremium={isPremium}>
        <Section style={{ gap: 8 }}>
          <Title>{locale("edit_profile.my_pictures")}</Title>

          <Row>
            <FilterButton
              active={pictureTab === "public"}
              onClick={() => setPictureTab("public")}
            >
              {locale("global.settings.pictures.public_pictures")}
            </FilterButton>
            <FilterButton
              active={pictureTab === "private"}
              onClick={() => setPictureTab("private")}
            >
              {locale("global.settings.pictures.private_pictures")}
            </FilterButton>
            <FilterButton
              active={pictureTab === "quickshare"}
              onClick={() => setPictureTab("quickshare")}
            >
              {locale("global.settings.pictures.quickshare")}
            </FilterButton>
          </Row>
          {pictureTab === "public" && <AlbumSection albumName="myPhotos" />}
          {pictureTab === "private" && (
            <AlbumSection albumName="myPrivatePhotos" />
          )}
          {pictureTab === "quickshare" && (
            <AlbumSection albumName="quickshare" />
          )}
        </Section>

        <Section>
          <Title>{locale("edit_profile.informations")}</Title>
          <Firstname me={me} onChange={handleChange} />
          <Country me={me} onChange={handleChange} />
          <City me={me} onChange={handleChange} />
        </Section>
        <Section>
          <Title>{locale("edit_profile.description")}</Title>
          <Description me={me} onChange={handleChange} />
        </Section>
        <Section>
          <Title>{locale("edit_profile.presentation-video")}</Title>
          <PresentationVideo me={me} onChange={() => setMe()} />
        </Section>

        <Section>
          <Title>{locale("edit_profile.hashtag")}</Title>
          <Hashtags />
        </Section>
        <Section>
          <Title>{locale("edit_profile.appearance")}</Title>
          <Age me={me} onChange={handleChange} />
          <WeightHeightCaracteristics
            me={me}
            onChange={handleChange}
            isHeight={true}
          />
          <WeightHeightCaracteristics
            me={me}
            onChange={handleChange}
            isWeight={true}
          />
          <Caracteristics
            me={me}
            onChange={handleChange}
            characteristic="ethnicity"
          />
          <Caracteristics
            me={me}
            onChange={handleChange}
            characteristic="pilosity"
          />
          <Caracteristics
            me={me}
            onChange={handleChange}
            characteristic="facial-hair"
          />
        </Section>
        <Section>
          <SexualPractices me={me} onChange={handleChange} />
        </Section>
        <Section>
          <Community me={me} onChange={handleChange} />
        </Section>
        <Section>
          <LookingFor me={me} onChange={handleChange} />
        </Section>
        <Section>
          <Languages me={me} onChange={handleChange} />
        </Section>
        <Section>
          <Title>{locale("edit_profile.youtube-videos")}</Title>
          <Attribute me={me} onChange={handleChange} type="video1" />
          <Attribute me={me} onChange={handleChange} type="video2" />
        </Section>
        {!isDesktop ? (
          <PrimaryButton
            onClick={() => openProfile(me.pseudo)}
            isActive
            size="large"
            style={{ margin: 12 }}
          >
            {locale("global.settings.see_my_profile")}
          </PrimaryButton>
        ) : null}
      </Container>
    </>
  );
};

export default SectionAboutMe;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};
  @media (max-width: 1000px) {
    height: 40px;
  }
`;