import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import { VideoCustom } from "../../pages/messages/components/messages/video.component";
import {
  getUserLikedPicture,
  likePicture,
} from "../../services/pictures.service";
import ModalSlice from "../../store/slices/modal.slice";
import colors from "../../themes/colors-v2.theme";
import fonts from "../../themes/fonts.theme";
import { useLogin } from "../../utils/auth.utils";
import useLocale from "../../utils/locale/locale.hook";
import Image from "../image.component";
import ModalTemplate from "../miscellaneous/modal-template.component";
import PictureModeration from "../picture-moderation.component";
import { Flex } from "../style/flex.component";
import Text from "../style/text.component";

type FullSizeImagesModalProps = {
  images: Array<any>;
  visible: boolean;
  start: number;
  timeLeft?: number;
  type?: "ephemeral" | "quickshare" | "pictures";
  isLikeable?: boolean;
};

const FullSizeImagesModal = (props: FullSizeImagesModalProps) => {
  const dispatch = useDispatch();
  const carouselRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { me } = useLogin();
  const locale = useLocale();

  const [images, setImages] = useState(props.images);
  const [currentIndex, setCurrentIndex] = useState(props.start);
  const [userLikedPicture, setUserLikedPicture] = useState<any[]>([]);

  const horizontalScroll = (direction: "left" | "right") => {
    if (carouselRef.current !== null) {
      direction === "left" && (carouselRef.current.scrollLeft -= 103);
      direction === "right" && (carouselRef.current.scrollLeft += 103);
    }
  };

  const onClose = () => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: false,
        images: [],
      })
    );
  };

  const onNext = () => {
    if (currentIndex < props.images.length - 1)
      setCurrentIndex(currentIndex + 1);
  };

  const onPrev = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  const hasCurrentUserLikedPicture = useMemo(() => {
    return userLikedPicture.find((user) => user.id === me.id);
  }, [userLikedPicture, me.id]);

  const mobileMapUserLikedPictures = async (pictureIds: string[]) => {
    const usersLikedPictures = await Promise.all(
      pictureIds.map((id) => getUserLikedPicture(id))
    );
    const likeStatusMap = {};
    pictureIds.forEach((id) => {
      likeStatusMap[id] = { hasLiked: false, count: 0 };
    });
    usersLikedPictures.forEach((usersLikedPicture, index) => {
      likeStatusMap[pictureIds[index]] = {
        hasLiked: usersLikedPicture?.find((user) => user.id === me.id),
        count: usersLikedPicture.length,
      };
    });
    return likeStatusMap;
  };

  // Inside your component
  const [likeStatusMap, setLikeStatusMap] = useState({});

  useEffect(() => {
    const pictureIds = images.map((image) => image.id);
    mobileMapUserLikedPictures(pictureIds).then((map) => {
      setLikeStatusMap(map);
    });
  }, [images]);

  useEffect(() => {
    if (props.images) {
      setImages(props.images);
    }
  }, [props.images]);

  useEffect(() => {
    if (props.visible) {
      setCurrentIndex(0);
      setCurrentIndex(props.start);
    } else {
      setImages([]);
    }
  }, [props.visible]);

  const handleLike = async (pictureId: string) => {
    await likePicture(pictureId);
    await getUserLiked();
    if (!isDesktop) {
      setLikeStatusMap((map) => ({
        ...map,
        [pictureId]: {
          hasLiked: !map[pictureId].hasLiked,
          count: map[pictureId].hasLiked
            ? map[pictureId].count--
            : map[pictureId].count++,
        },
      }));
    }
  };

  async function getUserLiked() {
    const users = await getUserLikedPicture(images[currentIndex].id);
    setUserLikedPicture(users);
  }

  useEffect(() => {
    getUserLiked();
  }, [currentIndex]);

  if (!props.visible) return <></>;

  return (
    <ModalTemplate
      visible={props.visible}
      closeModal={() => {
        onClose();
      }}
      style={{
        maxWidth: "100vw",
        maxHeight: isDesktop ? "90vh" : "100vh",
        minHeight: isDesktop ? "auto" : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: isDesktop ? "16px" : 0,
        backgroundColor: !isDesktop && colors.lightGrey11,
        padding: !isDesktop && "48px 16px",
        boxSizing: "border-box",
        background: "transparent",
      }}
      canCloseModalUsingOverlay={true}
      zIndex={1100}
      showCloseButton={false}
      mobileWidth="100vw"
    >
      <Content>
        <TopContainer onClick={() => onClose()}>
          <img
            src="/assets/icons/cross-outlined-white.svg"
            width="16px"
            height="16px"
          />
          <Text bold fontSize="16px">
            {locale("global.close")}
          </Text>
        </TopContainer>
        {images[currentIndex] && isDesktop && (
          <>
            <MiddleContainer>
              {currentIndex > 0 && (
                <IconButtonLeft
                  onClick={() => onPrev()}
                  style={{ transform: "rotate(180deg)" }}
                >
                  <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron-right"
                    width="12px"
                    height="12px"
                  />
                </IconButtonLeft>
              )}

              <PictureContainer>
                {!images[currentIndex].isVideo ? (
                  <PictureModeration
                    moderation={images[currentIndex].moderation}
                    alt=""
                    width="100%"
                    height="100%"
                    style={stylePicture}
                    src={images[currentIndex].path}
                  />
                ) : (
                  <VideoCustom
                    src={images[currentIndex].path}
                    title={images[currentIndex].title}
                  />
                )}
              </PictureContainer>
              {currentIndex < images.length - 1 && (
                <IconButtonRight onClick={() => onNext()}>
                  <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron-right"
                    width="12px"
                    height="12px"
                  />
                </IconButtonRight>
              )}
            </MiddleContainer>
            {props.isLikeable && (
              <Flex alignItems="center" gap="19px" style={{ margin: "16px 0" }}>
                <Flex>
                  <Text>{locale("radar.picture.like_by")}&nbsp;</Text>
                  {hasCurrentUserLikedPicture ? (
                    <Text>
                      {locale("radar.picture.likes_and_me", {
                        count: userLikedPicture.length - 1,
                      })}
                    </Text>
                  ) : (
                    <Text>
                      {locale("radar.picture.likes", {
                        count: userLikedPicture.length,
                      })}
                    </Text>
                  )}
                </Flex>
                <img
                  width={48}
                  height={48}
                  onClick={() => handleLike(images[currentIndex].id)}
                  style={{ cursor: "pointer" }}
                  src={`/assets/icons/nearby/like${
                    hasCurrentUserLikedPicture ? "-red" : ""
                  }.svg`}
                />
              </Flex>
            )}

            {images.length > 1 && (
              <BottomContainer>
                {images.length > 9 && <IconCarouselButtonLeft
                  onClick={() => horizontalScroll("left")}
                >
                  <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron-right"
                    width="12px"
                    height="12px"
                  />
                </IconCarouselButtonLeft>}
                <ImagesCarousel ref={carouselRef}>
                  {images.length > 1 &&
                    images.map((image, index) => {
                      return !image.isVideo ? (
                        <Image
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          alt=""
                          width="66px"
                          height="66px"
                          src={image.path}
                        />
                      ) : (
                        <video
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          width="66px"
                          height="66px"
                          src={image.path}
                        />
                      );
                    })}
                </ImagesCarousel>
                {images.length > 9 && <IconCarouselButtonRight
                  onClick={() => horizontalScroll("right")}
                >
                  <img
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron-right"
                    width="12px"
                    height="12px"
                  />
                </IconCarouselButtonRight>}
              </BottomContainer>
            )}
          </>
        )}
        {!isDesktop && (
          <MobileImagesWrapper>
            {images.map((image) => (
              <MobileImageContainer key={image.path}>
                <PictureContainer>
                  <PictureModeration
                    moderation={image.moderation}
                    alt=""
                    width="100%"
                    height="100%"
                    style={stylePicture}
                    src={image.path}
                  />
                  {props.isLikeable && (
                    <Flex
                      alignItems="center"
                      gap="19px"
                      style={{
                        margin: "16px 0",
                        position: "absolute",
                        bottom: 0,
                      }}
                    >
                      <Flex>
                        <Text>{locale("radar.picture.like_by")}&nbsp;</Text>
                        {likeStatusMap[image.id]?.hasLiked ? (
                          <Text>
                            {locale("radar.picture.likes_and_me", {
                              count: likeStatusMap[image.id]?.count,
                            })}
                          </Text>
                        ) : (
                          <Text>
                            {locale("radar.picture.likes", {
                              count: likeStatusMap[image.id]?.count,
                            })}
                          </Text>
                        )}
                      </Flex>
                      <img
                        width={48}
                        height={48}
                        onClick={() => handleLike(image.id)}
                        style={{ cursor: "pointer" }}
                        src={`/assets/icons/nearby/like${
                          likeStatusMap[image.id]?.hasLiked ? "-red" : ""
                        }.svg`}
                      />
                    </Flex>
                  )}
                </PictureContainer>
              </MobileImageContainer>
            ))}
          </MobileImagesWrapper>
        )}
      </Content>
    </ModalTemplate>
  );
};

export default FullSizeImagesModal;

const Content = styled.div`
  width: auto;
  padding: 16px;
  height: 100%;
  min-height: 80vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 16px;

  @media (max-width: 1000px) {
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    gap: 12px;
    max-height: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: flex-start;
  }
`;

const TopContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 1000;
  background: ${colors.darkBlue};
  padding: 9px 10px 9px 10px;
  border-radius: 48px;
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  top: calc(50% - 16px);
  z-index: 310;
  background-color: ${colors.white};
  border-radius: 50%;
`;

const IconButtonLeft = styled(IconButton)`
  position: absolute;
  left: 0px;
  background: ${colors.darkBlue};

  @media (max-width: 1000px) {
    margin-left: 0px;
  }
`;

const IconButtonRight = styled(IconButton)`
  position: absolute;
  right: 0px;
  background: ${colors.darkBlue};
  @media (max-width: 1000px) {
    right: 0px;
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  min-height: 85%;
  max-height: 85%;
  position: relative;

  & > img {
    margin: auto;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
`;

const PictureContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    position: relative;
  }
`;

const LikeButton = styled.button`
  min-width: 300px;
  width: 50%;
  border: 1px solid ${colors.lightGrey9};
  outline: none;
  background-color: ${colors.white};
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  color: ${colors.darkGrey2};
  font-size: 14px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    background-color: ${colors.darkOrange};
    color: ${colors.white};
    border-color: ${colors.darkOrange};
  }

  @media (max-width: 1000px) {
    position: relative;
    transform: unset;
    width: 100%;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;

    & > div {
      top: 0;
      height: fit-content;
    }
  }
`;

const BottomContainer = styled.div`
  height: 95px;
  position: relative;
  min-width: calc(103px * 7 - 8px + 2 * 56px);
`;

const stylePicture = {
  objectFit: "contain",
  borderRadius: "16px",
  // height: '-webkit-fill-available',
  width: "auto",
  height: "auto",
  maxWidth: "100%",
  maxHeight: "100%",
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* top: 50%; */
  /* left: 50%; */
  // transform: 'translate(50%, 50%)',
};

const ImagesCarousel = styled.div`
  height: 66px;
  margin: 8px auto 0 auto;
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  max-width: calc(103px * 7 - 8px);
  overflow: hidden;
  gap: 3px;
  scroll-behavior: smooth;

  & > img {
    cursor: pointer;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
`;

const IconCarouselButtonLeft = styled(IconButton)`
  position: absolute;
  left: 30px;
  top: calc(50% - 9px);
  transform: rotate(180deg);
  background: ${colors.darkBlue};

  @media (max-width: 1000px) {
    margin-left: 0px;
  }
`;

const IconCarouselButtonRight = styled(IconButton)`
  position: absolute;
  right: 30px;
  top: calc(50% - 9px);
  background: ${colors.darkBlue};

  @media (max-width: 1000px) {
    right: 0px;
  }
`;

const MobileImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MobileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
`;

const MobileClose = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
  margin-left: 0;
  margin-right: auto;
  padding-bottom: 19.5px;
  box-sizing: border-box;

  & p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: ${fonts.semiBold.weight};
  }
`;
