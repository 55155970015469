import styled from 'styled-components/macro';
import Icons from '../../../components/style/icons.component';
import Text from '../../../components/style/text.component';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';

export const Container = styled.div<{ isPremium?: boolean }>`
  max-width: 798px;
  width: 798px;
  padding: 28px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 16px;
    margin-bottom: ${(props) => (props.isPremium ? '60px' : '100px')};
  }
`;

export const Section = styled.div`
  background-color: #00000080;
  border-radius: 16px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${colors.graySeparator2};

  @media (max-width: 1000px) {
    border-radius: 0;
    padding: 12px;
  }
`;

export const SubSection = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #343536;

  & p {
    color: ${colors.darkerGrey};
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
`;

export const ExplicitContentWarning = () => {
  const locale = useLocale();

  return (
    <Warning>
      <Icons name="warning-outlined-white" width="16" height="16" />
      <p>{locale('settings.pictures.public.warning')}</p>
    </Warning>
  );
};

const Warning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
  border: 1px solid #ff006d;
  border-radius: 4px;
  max-width: 203px;

  & > p {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Segoe UI';
    margin: 8px 0 8px 12px;
    color: ${colors.darkerGrey};
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  color: #ff006d;
`;

export const Error = ({ errorMsg, style }) => (
  <StyledError style={style}>
    <Icons name="warning-outlined-white" width="16" height="16" style={{ marginRight: '12px' }} />
    {errorMsg}
  </StyledError>
);

const StyledError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px 10px;
  border-radius: 4px;
  background-color: #ff5050;
  color: ${colors.darkerGrey};
  font-size: 12px;
  line-height: 12px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
`;

type TransparentButtonProps = {
  onClick?: () => void;
  style?: any;
  text: string;
};

export const TransparentButton = ({ onClick, style, text }: TransparentButtonProps) => (
  <Button onClick={onClick} style={style}>
    <Text
      bold
      style={{
        fontSize: '14px',
        lineHeight: '20px',
        color: style?.color || colors.darkGrey2,
      }}
    >
      {text}
    </Text>
  </Button>
);

const Button = styled.div`
  margin-top: 18px;
  box-sizing: border-box;
  width: fit-content;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid ${colors.lightGrey9};
  background-color: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: orange;
`;

export const SubmitButton = styled.button`
  align-self: 'center';
  border: 1px solid ${(p) => (p.disabled ? colors.lightGrey11 : colors.darkOrange3)};
  border-radius: 8px;
  background-color: ${(p) => (p.disabled ? colors.lightGrey11 : colors.darkOrange1)};
  color: ${(p) => (p.disabled ? 'grey' : colors.white)};
  cursor: ${(p) => (p.disabled ? '' : 'pointer')};
  padding: 10px 12px;
  margin-top: 12px;
`;
