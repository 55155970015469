import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const useScrollDisplayArrows = (filtersRef: any) => {
  const [displayLeftArrow, setDisplayLeftArrow] = useState(false);
  const [displayRightArrow, setDisplayRightArrow] = useState(false);

  const handleScroll = (event?: any) => {
    if (!filtersRef.current) return;
    const element = filtersRef.current;

    const isEndReached = element.scrollLeft >= element.scrollWidth - element.clientWidth;
    if (element.scrollLeft > 0) {
      if (!isEndReached) {
        setDisplayLeftArrow(true);
        setDisplayRightArrow(true);
      } else {
        setDisplayLeftArrow(true);
        setDisplayRightArrow(false);
      }
    } else {
      if (!isEndReached && element.scrollLeft !== undefined) {
        setDisplayRightArrow(true);
        setDisplayLeftArrow(false);
      } else {
        setDisplayRightArrow(false);
        setDisplayLeftArrow(false);
      }
    }
  };

  const handleArrowClick = useCallback(
    (direction: 'left' | 'right') => {
      const speed = 10;
      const distance = 400;
      const step = 10;
      let scrollAmount = 0;

      const slideTimer = setInterval(() => {
        if (!filtersRef.current) return;
        if (direction === 'left') {
          filtersRef.current.scrollLeft -= step;
        } else {
          filtersRef.current.scrollLeft += step;
        }
        scrollAmount += step;

        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
      }, speed);

      return () => slideTimer;
    },
    [filtersRef]
  );

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (filtersRef.current) {
        handleScroll();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [filtersRef, handleScroll]);

  return {
    displayLeftArrow,
    displayRightArrow,
    handleScroll,
    handleArrowClick,
  };
};

export function useScrollUpState() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(currentScrollPos < 10 || prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return visible;
}
