import { del, get, post, put } from './utils.service';

export const setOrderPictures = async (pictures) => {
  const res = await put(`/pictures/order`, pictures);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const addPictures = async (pictures) => {
  const res = await post(`/pictures`, pictures);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const changeMainPicture = async (picture) => {
  const res = await post(`/pictures/changeMain`, picture);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deletePictures = async (pictureId) => {
  const res = await del(`/pictures/${pictureId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updatePictures = async (pictureId, picture) => {
  const res = await put(`/pictures/${pictureId}`, { ...picture });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const likePicture = async (pictureId: string) => {
  const res = await post(`/pictures/like/${pictureId}`, {});
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserLikedPicture = async (pictureId: string) => {
  const res = await get(`/pictures/like/${pictureId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getRandomNotModeratedPicture = async () => {
  const res = await get(`/pictures/not-moderated`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getNotModeratedPicturesCount = async () => {
  const res = await get(`/pictures/not-moderated-count`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const moderatePicture = async ({
  picture,
  userId,
  moderation,
}: {
  picture: any;
  userId: string;
  moderation: 'skip' | 'nonSexual' | 'someSkin' | 'softcore' | 'hardcore' | 'illegal';
}) => {
  const res = await post(`/pictures/moderate`, {
    picture,
    userId,
    moderation,
  });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
