import React from 'react';
import { UserModalTemplate } from '../user-profile/components/user-modal.component';
import ModalSlice from '../../store/slices/modal.slice';
import { useDispatch } from 'react-redux';
import colors from '../../themes/colors-v2.theme';
import styled from 'styled-components';
import { Flex } from '../../components/style/flex.component';
import { PrimaryButton } from '../../components/forms/primaryButton.component';
import Text from '../../components/style/text.component';
import useLocale from '../../utils/locale/locale.hook';
import { useHistory } from 'react-router-dom';

type Props = {
  visible?: boolean;
  redirectTo?: '/live' | '/members-videos' | '/porn-videos';
};

const mapRedirectToEmoji = {
  '/live': '🍆',
  '/members-videos': '🍑',
  '/porn-videos': '🍌',
};

export const WarningModal = ({ visible, redirectTo }: Props) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const history = useHistory();

  const onClose = () => {
    dispatch(
      ModalSlice.actions.setSexualContentWarning({
        visible: false,
        redirectTo: '',
      })
    );
  };

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={onClose}
      style={{
        borderRadius: 8,
        width: '465px',
        height: 'fit-content',
        background: colors.darkGray,
        position: 'fixed',
        border: `1px solid ${colors.lightGrey10}`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
    >
      <Container>
        <Eggplant>{mapRedirectToEmoji[redirectTo]}</Eggplant>
        <Text bold fontSize="16px" style={{ textAlign: 'center' }}>
          {locale('modal.sexual_content.title')}
        </Text>
        <Flex gap="8px">
          <PrimaryButton size="medium" style={{ borderRadius: 8, width: '87px' }} onClick={onClose}>
            {locale('cancel')}
          </PrimaryButton>
          <PrimaryButton
            size="medium"
            style={{ borderRadius: 8, width: '87px' }}
            onClick={() => {
              onClose();
              history.push(redirectTo);
            }}
            isActive
          >
            {locale('go')}
          </PrimaryButton>
        </Flex>
      </Container>
    </UserModalTemplate>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 16px;
`;

const Eggplant = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid ${colors.lightGrey10};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;
