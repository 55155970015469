import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import useLocale from '../../../../utils/locale/locale.hook';
import DigitCodeInput from './digitcodeinput.component';
import Button from '../../../../components/forms/button.component';
import { sendVerificationCode } from '../../../../services/phone-verification.service';
import colors from '../../../../themes/colors-v2.theme';
import Image from '../../../../components/image.component';
import Text from '../../../../components/style/text.component';
import fonts from '../../../../themes/fonts.theme';

type PhoneVerificationProps = {
  phone: string;
  onNext: (code: string) => void;
  token: string;
};

const PhoneVerification = ({ phone, onNext, token }: PhoneVerificationProps) => {
  const locale = useLocale();
  const [loading, setLoading] = useState(false);

  return (
    <FormWrapper>
      <ContentWrapper>
        <LoginHeader>
          <HeaderLogoWrapper>
            <Image
              src="/assets/icons/logo/logo-white-with-text.svg"
              alt="Bearwww logo"
              width={'185px'}
              height={'35px'}
            />
          </HeaderLogoWrapper>
          <HeaderTitleWrapper>
            <Text style={{ fontSize: 14, fontWeight: fonts.bold.weight }}>Vérification du compte</Text>
          </HeaderTitleWrapper>
        </LoginHeader>
        <HelpText
          style={{
            marginBottom: 16,
            color: 'white',
            fontWeight: 400,
            fontSize: 14,
          }}
          dangerouslySetInnerHTML={{
            __html: locale('phone_verification.sent_to', { phone: phone }),
          }}
        ></HelpText>
        <DigitCodeInput codeLength={6} onNext={onNext} />
        <Button
          text={locale('send_phone_verification_code_again') || 'Send code again'}
          onClick={async () => {
            setLoading(true);
            await sendVerificationCode(phone, token);
            setLoading(false);
          }}
          loading={loading}
          style={{
            width: '75%',
            borderRadius: 8,
            marginTop: '24px',
            backgroundColor: colors.white,
            color: colors.dark,
            borderColor: colors.white,
          }}
        />
      </ContentWrapper>
    </FormWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  margin: auto;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
  width: 369px;
`;

const LoginHeader = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;

  @media (max-width: 1000px) {
    text-align: center;
    padding: 0 16px;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const HelpText = styled.div``;

export default PhoneVerification;
