import React, { useEffect } from "react";
import { getCurrentLanguage } from "../../utils/locale/hreflang.utils";

const PlayStoreButton = ({ style }) => {
  const linkGooglePlay = () => {
    switch (getCurrentLanguage()) {
      case "en":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=en&gl=US";
      case "fr":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=fr";
      case "it":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=it";
      case "es":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=es";
      case "ca":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=es";
      case "pt":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=pt";
      case "el":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=el";
      case "ja":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=en&gl=US";
      case "nl":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=nl&gl=US";
      case "de":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=de";
      case "pl":
        return "https://play.google.com/store/apps/details?id=com.bearwww2.android&hl=pl";
    }
  };

  return (
    <a href={linkGooglePlay()} target="_blank">
      <img
        alt="Bearwww App on the Play Store"
        src="/assets/imgs/signin/googleplay.svg"
        style={style}
      />
    </a>
  );
};

export default PlayStoreButton;
