import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from './components/header.component';

import SectionAboutMe from './components/about/section-about-me.component';
import SectionAccount from './components/account/section-account.component';
import Privacy from './components/privacy.component';
import UnblockGestion from './components/section-unblock.component';
import { useRouterNavigationTracker } from '../../utils/history.utils';

const SettingsScreen = () => {
  const location = useLocation();
  useRouterNavigationTracker();

  const [selectedTab, setselectedTab] = useState('');

  useEffect(() => {
    // DEBUG: might not be useful
    const settingsSubTabs = ['general', 'privacy', 'blocked'];
    const [main] = location.pathname
      .replace('/settings', '')
      .split('/')
      .filter((e) => e.length);

    setselectedTab(settingsSubTabs.includes(main) ? '' : main ?? '');
  }, [location.pathname]);

  return (
    <div>
      <Wrapper>
        <SettingsHeader>
          <Header selectedTab={selectedTab} />
        </SettingsHeader>
        <SettingsWrapper>
          <Switch>
            <Route exact path="/settings/edit-profile" component={SectionAboutMe} />
            <Route exact path="/settings/general" component={SectionAccount} />
            <Route exact path="/settings/privacy" component={Privacy} />
            <Route exact path="/settings/blocked" component={UnblockGestion} />
            <Route exact path="/settings/pictures">
              <Redirect to="/settings/pictures/public" />
            </Route>
            <Route exact path="/settings">
              <Redirect to="/settings/general" />
            </Route>
          </Switch>
        </SettingsWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const SettingsHeader = styled.div`
  box-sizing: border-box;
`;

const SettingsWrapper = styled.div`
  margin-top: 80px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    margin-top: 64px;
  }
`;

export default SettingsScreen;
