import { get, post } from "./utils.service";

export const getLiveStreams = async () => {
  const res = await get("/live");
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getUserLivestream = async (userPseudo: string) => {
  const res = await get(`/live/${userPseudo}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const startLive = async (body: {
  title: string;
  selectedCategory: string;
}) => {
  const res = await post("/live/start", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const stopLive = async (streamName: string) => {
  const res = await post(`/live/${streamName}/stop`, {});
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const joinLive = async (liveId: number | string) => {
  const res = await post(`/live/${liveId}/join`, {});
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const leaveLive = async (liveId: number | string) => {
  const res = await post(`/live/${liveId}/leave`, {});
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const sendMessage = async (
  liveId: number | string,
  body: { content: string }
) => {
  const res = await post(`/live/${liveId}/send-message`, body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const moderateLive = async (streamName: string, body: any) => {
  const res = await post(`/live/${streamName}/moderate-live`, body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getViewerCount = async (streamName: string) => {
  const res = await get(`/live/${streamName}/viewer-count`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
