import { useState, useRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import DisplayedConversation from "../displayed-conversation.component";
import UserConversations from "../user-conversations.component";
import ChatSlice from "../../../../store/slices/chat.slice";

import colors from "../../../../themes/colors-v2.theme";

export default function QuickChatAccessOverlay() {
  const dispatch = useDispatch();
  const chatStore = useSelector((state: any) => state.chatStore);

  const conversationsSelectorRef = useRef(null);
  const conversationDisplayedSelectorRef = useRef(null);
  const optionsRef = useRef(null);
  const userConversationsRef = useRef(null);

  const handleClick = (e) => {
    if (
      !conversationsSelectorRef?.current?.contains(e.target) &&
      !conversationDisplayedSelectorRef?.current?.contains(e.target) &&
      !optionsRef?.current?.contains(e.target) &&
      !e.target.closest(".ReactModalPortal")
    ) {
      dispatch(ChatSlice.actions.setOverlayVisibility(false));
    }
  };

  const refreshConversationList = () => {
    // Uses useImperativeHandle to allow one component to refresh the conversation
    // list of UserConversations.
    userConversationsRef?.current?.refreshConversations();
  };

  return (
    <OverlayWrapper onClick={handleClick}>
      <PanesWrapper>
        {chatStore.openedConversation && (
          <OpenedConversationWrapper ref={conversationDisplayedSelectorRef}>
            <DisplayedConversation
              conversationId={chatStore.openedConversation.id}
              newConversationUser={null}
              closeConversationHandler={() => {
                dispatch(ChatSlice.actions.setOpenedConversation(null));
              }}
              options={{
                showCloseConversation: true,
              }}
              optionsRef={optionsRef}
              refreshConversationList={refreshConversationList}
            />
          </OpenedConversationWrapper>
        )}
        <ConversationsWrapper ref={conversationsSelectorRef}>
          <UserConversations
            ref={userConversationsRef}
            selectedConversation={chatStore.openedConversation}
            selectConversationHandler={(conversation) => {
              dispatch(ChatSlice.actions.setOpenedConversation(conversation));
            }}
            newConversationUser={null}
            options={{ showExpandConversation: true }}
          />
        </ConversationsWrapper>
      </PanesWrapper>
    </OverlayWrapper>
  );
}

const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background: rgba(46, 52, 56, 0.73);
  z-index: 500;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background: none;
  }
`;

const PanesWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  right: 0;
`;

const ConversationsWrapper = styled.div`
  margin-left: 0px;

  @media (max-width: 1000px) {
    margin-top: 60px;
    padding-top: 12px;
    background-color: ${colors.backgroundLightGrey};
    max-width: 100vw;
    width: 100vw;

    & * {
      box-sizing: border-box;
    }
  }
`;

const OpenedConversationWrapper = styled.div`
  width: 375px;
  height: 70vh;
  max-height: 70vh;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  background-color: ${colors.backgroundDark};
  margin-right: 8px;
`;
