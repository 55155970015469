import { useState, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components/macro';
import { FancyScrollbar } from '../../../components/style/fancy-scrollbar.style';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { useLogin } from '../../../utils/auth.utils';
import { useSelector } from 'react-redux';
import Notifications from '../../navbar/Notifications/Notifications';
import {
  getSharedByOtherQuickshare,
  getSharedWithQuickshare,
  revokeAllQuickshareAccess,
} from '../../../services/chat.service';
import { SharedByAlbum } from './shared-by-album-item.component';
import { SharedWithAlbum } from './shared-with-album-item.component';
import { CustomGrid } from '../../nearby/components/custom-grid.component';
import { Flex } from '../../../components/style/flex.component';
import Text from '../../../components/style/text.component';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';

export const AlbumList = () => {
  const locale = useLocale();
  const { isLogin } = useLogin();
  const sharedByAlbumsRef = useRef(null);
  const notifStore = useSelector((state: any) => state.notifStore);

  const [scrollerKey, setScrollerKey] = useState(Math.random());
  const [hasNoSharedByAlbum, setHasNoSharedByAlbum] = useState(true);
  const [sharedByAlbums, setSharedByAlbums] = useState<SharedByAlbum[]>([]);
  const [hasNoSharedWithAlbum, setHasNoSharedWithAlbum] = useState(true);
  const [sharedWithAlbums, setSharedWithAlbums] = useState<SharedWithAlbum[]>([]);
  const [activeTab, setActiveTab] = useState<'my-access' | 'shared-with'>('my-access');

  /**
   * Fonction permettant de récupérer les albums pour lesquels l'utilisateur a un accès partagé.
   */
  const refreshSharedByAlbums = () => {
    if (setHasNoSharedByAlbum) {
      setHasNoSharedByAlbum(false);
    }
    getSharedByAlbums().then((sharedByAlbums) => {
      if (Array.isArray(sharedByAlbums)) {
        setSharedByAlbums(sharedByAlbums);
        if (setHasNoSharedByAlbum) {
          setHasNoSharedByAlbum(sharedByAlbums.length === 0);
        }
      }
    });
  };

  const getSharedByAlbums = async () => {
    const res = await getSharedByOtherQuickshare();
    if (!Array.isArray(res) || res.length === 0) {
      setHasNoSharedByAlbum(true);
      return;
    }
    setSharedByAlbums(res);
  };

  useImperativeHandle(sharedByAlbumsRef, () => ({
    refreshSharedByAlbums: () => {
      refreshSharedByAlbums();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshSharedByAlbums();
    }
  }, [isLogin]); // eslint-disable-line

  /**
   * Fonction permettant de récupérer les utilisateurs avec lesquels l'utilisateur a partagé son album.
   */
  const refreshSharedWithAlbums = () => {
    if (setHasNoSharedWithAlbum) {
      setHasNoSharedWithAlbum(false);
    }
    getSharedWithAlbums().then((sharedByAlbums) => {
      if (Array.isArray(sharedByAlbums)) {
        setSharedWithAlbums(sharedByAlbums);
        if (setHasNoSharedWithAlbum) {
          setHasNoSharedWithAlbum(sharedByAlbums.length === 0);
        }
      }
    });
  };

  const revokeAlbumAccessForAll = async () => {
    try {
      await revokeAllQuickshareAccess();
      refreshSharedWithAlbums();
    } catch (error) {
      console.error('Error revoking access for all users:', error);
    }
  };

  const getSharedWithAlbums = async () => {
    const res = await getSharedWithQuickshare();
    if (!Array.isArray(res) || res.length === 0) {
      setHasNoSharedWithAlbum(true);
      return;
    }
    setSharedWithAlbums(res);
  };

  useImperativeHandle(sharedByAlbumsRef, () => ({
    refreshSharedWithAlbums: () => {
      refreshSharedWithAlbums();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshSharedWithAlbums();
    }
  }, [isLogin]); // eslint-disable-line

  return (
    <AlbumsWrapper key={scrollerKey}>
      <Row>
        <FilterButton active={activeTab === 'my-access'} onClick={() => setActiveTab('my-access')}>
          {locale('chat.album.selector.shared_to_me')}
          {notifStore.albumsNotSeen > 0 && (
            <NotificationContainer>
              <Notifications
                notificationNumber={notifStore.albumsNotSeen}
                maxNumberOfChar={1}
                position={'inline-block'}
              />
            </NotificationContainer>
          )}
        </FilterButton>
        <FilterButton active={activeTab === 'shared-with'} onClick={() => setActiveTab('shared-with')}>
          {locale('chat.album.selector.shared_with_me')}
        </FilterButton>
      </Row>
      <div style={{ padding: 4 }}>
        <div>
          {activeTab === 'my-access' && (
            <CustomGrid
              users={sharedByAlbums.map((sharedBy) => sharedBy.shared_by)}
              cellType="square"
              isFromSharedAlbum={true}
            />
          )}
        </div>
        {activeTab === 'shared-with' && (
          <>
            {hasNoSharedByAlbum ? (
              <NoAlbums>{locale('chat.no_conversation')}</NoAlbums>
            ) : (
              <Flex direction="column" gap="8px">
                <Flex
                  direction="column"
                  gap="16px"
                  style={{ background: '#15191FE5', border: '1px solid #202939', padding: 14 }}
                >
                  <Flex alignItems="flex-start" gap="18px">
                    <img src="/assets/icons/shared-albums/info.svg" />
                    <Flex direction="column" gap="4px">
                      <Text style={{ fontWeight: 700 }}> {locale('shared-albums.warning.title')}</Text>
                      <Text fontSize="12px" bold color={colors.darkText}>
                        {locale('shared-albums.warning.label')}
                      </Text>
                    </Flex>
                  </Flex>
                  <PrimaryButton
                    size="large"
                    style={{
                      background: 'white',
                      color: 'black',
                      width: 'fit-content',
                      fontSize: 14,
                      padding: '8px 14px 8px 14px',
                    }}
                    onClick={revokeAlbumAccessForAll}
                  >
                    {locale('shared-albums.revoke_access')}
                  </PrimaryButton>
                </Flex>
                <Flex style={{ flexWrap: 'wrap' }} gap="8px 32px">
                  {sharedWithAlbums.map((sharedWithAlbum) => (
                    <SharedWithAlbum
                      key={sharedWithAlbum.shared_with.id}
                      sharedWithAlbum={sharedWithAlbum}
                      refreshSharedWithAlbums={refreshSharedWithAlbums}
                    />
                  ))}
                </Flex>
              </Flex>
            )}
          </>
        )}
      </div>
    </AlbumsWrapper>
  );
};

const AlbumsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${FancyScrollbar}

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    margin-bottom: 80px;
  }
`;

const NoAlbums = styled.div`
  margin: auto;
  width: 80%;
  text-align: center;
  font-size: 16px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkGrey};
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 38px;
    width: 100%;
    margin: 0;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  flex: 1;
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
  height: 38px;
`;
const NotificationContainer = styled.div`
  width: fit-content;
  display: inline-block;

  @media (max-width: 1000px) {
    top: 12px;
    left: calc(50% - 14px + 35px);
  }
`;

const SharedByAlbumContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-top: 21px;
`;

const SharedWithAlbumContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
  margin-top: 21px;
`;
