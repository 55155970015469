import { addPictures, updatePictures, changeMainPicture } from "../../../../../services/pictures.service";
import { uploadFile } from "../../../../../utils/s3.utils";

type Picture = {};

// DEBUG: might be moved somewhere here, sometime
type Album = {
  id: number;
  name: string;
  pictures: Picture[];
  type: "myPhotos" | 'myPrivatePhotos' | 'quickshare';
  visibility: "public" | 'private';
};

export const pictureAlbumTranfert = (
  myAlbums: {
    myPhotos: Album,
    myPrivatePhotos: Album,
    quickshare: Album,
    profilePicture: Album,
  },
  setError: React.Dispatch<any>,
  getData: () => Promise<void>,
) => {
  return async (
    newAlbum: 'myPhotos' | 'myPrivatePhotos' | 'quickshare',
    pictureId: string,
  ): Promise<void> => {
    const albumId = myAlbums[newAlbum].id;
    const res = await updatePictures(pictureId, {
      new_album_id: albumId,
    });
    if (res.error) {
      setError(res.error);
      return;
    }
    getData();
  };
};

export const uploadPictures = async (
  files: FileList,
  selectedAlbum: 'myPhotos' | 'myPrivatePhotos' | 'quickshare',
  setError: React.Dispatch<any>,
): Promise<void> => {
  const pictures = [];
  for (const file of files) {
    const { path, error } = await uploadFile(file);
    if (error) {
      setError(error);
      return;
    }
    pictures.push({ path, album: selectedAlbum });
  }

  const res = await addPictures(pictures);
  if (res.error) setError(res.error);
};


export const uploadAndChangeMainPicture = async (
  file: File,
  setError: React.Dispatch<any>,
): Promise<void> => {
  const { path, error } = await uploadFile(file);
  if (error) {
    setError(error);
    return;
  }
  const res = await changeMainPicture({ path });
  if (res.error) setError(res.error);
};
