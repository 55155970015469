import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import ProfileGridVertical from '../../components/miscellaneous/profile-grid-vertical.component';

import { getProfilesVisited, getOwnProfileVisits } from '../../services/users.service';

import { FancyScrollbar } from '../../components/style/fancy-scrollbar.style';
import { useLogin } from '../../utils/auth.utils';
import { CustomGrid } from '../nearby/components/custom-grid.component';
import useLocale from '../../utils/locale/locale.hook';
import { NoViewers } from './components/no-viewers.component';
import { Flex } from '../../components/style/flex.component';
import { useMediaQuery } from 'react-responsive';

export enum ModalMode {
  VISITED = 'VISITED',
  VISITORS = 'VISITORS',
}

const UserViews = ({ mode }) => {
  const { me } = useLogin();
  const locale = useLocale();
  const [visited, setVisited] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  useEffect(() => {
    if (mode === ModalMode.VISITED) {
      getProfilesVisited().then((views) => {
        if (Array.isArray(views)) {
          const formattedVisited = views.map((view: any, index) => ({
            ...view.visited,
            blurred: !['admin', 'premium'].includes(me.role) && index > 0,
          }));
          setVisited(formattedVisited);
        }
      });
    } else {
      getOwnProfileVisits().then((views) => {
        if (Array.isArray(views)) {
          const formattedVisitors = views.map((view: any, index) => ({
            ...view.visitor,
            blurred: !['admin', 'premium'].includes(me.role) && index > 0,
          }));
          setVisitors(formattedVisitors);
        }
      });
    }
  }, [mode]);

  return (
    <MainWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {mode === ModalMode.VISITORS &&
        (visitors.length > 0 ? (
          <CustomGrid
            users={visitors}
            cellType={isDesktop ? 'bigSquare' : 'rectangle'}
            showBoostAd
            premiumTitle={locale('viewers.premium.title')}
          />
        ) : (
          <Flex alignItems="center" justify="center" style={{ height: '100%' }}>
            <NoViewers />
          </Flex>
        ))}
      {mode === ModalMode.VISITED && (
        <CustomGrid
          users={visited}
          cellType={isDesktop ? 'bigSquare' : 'rectangle'}
          showBoostAd
          premiumTitle={locale('viewers.premium.title')}
        />
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  ${FancyScrollbar};
`;

export default UserViews;
