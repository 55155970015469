import { ReactElement, useEffect, useState } from 'react';
import { TabTitle } from './TabTitle';
import React from 'react';
import styled from 'styled-components';
import colors from '../../themes/colors-v2.theme';
import { useScrollUpState } from '../../utils/useScroll';

type TabsProps = {
  children: ReactElement<{ title: string; onClick?: () => void; titleStyle?: React.CSSProperties }>[];
  style?: React.CSSProperties;
  styleHeaders?: React.CSSProperties;
  endIcon?: React.ReactNode;
  isFixed?: boolean;
  isDark?: boolean;
  selectedTab?: number;
  isScrollUp?: boolean;
};

export const Tabs = ({
  children,
  style,
  endIcon,
  isFixed,
  selectedTab: selected,
  isDark,
  isScrollUp,
  styleHeaders: styleChildren,
}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(selected ?? 0);
  const visible = useScrollUpState();

  useEffect(() => {
    setSelectedTab(selected ?? 0);
  }, [selected]);

  return (
    <TabsContainer style={style}>
      <StyledUl isFixed={isFixed} isDark={isDark} visible={isScrollUp ? visible : true} style={styleChildren}>
        {React.Children.map(children, (child, index) => {
          return (
            <TabTitle
              isSelected={index === selectedTab}
              key={index}
              title={child.props.title}
              titleStyle={child.props.titleStyle}
              onClick={child.props.onClick}
              index={index}
              setSelectedTab={setSelectedTab}
            />
          );
        })}
        {endIcon}
      </StyledUl>
      <ChildrenContainer isFixed={isFixed}>{children[selectedTab]}</ChildrenContainer>
    </TabsContainer>
  );
};

const StyledUl = styled.ul<{ isFixed?: boolean; isDark?: boolean; visible?: boolean }>`
  display: flex;
  gap: 5px;
  padding: 0;
  margin: 0px;
  cursor: pointer;
  align-items: center;
  background: ${(props) => (props.isDark ? colors.dark : colors.darkGradient)};
  background-attachment: fixed;
  width: 100%;
  position: ${(props) => (props.isFixed ? 'fixed' : 'static')};
  top: ${(props) => (props.isFixed ? '59px' : 'auto')};
  z-index: ${(props) => (props.isFixed ? '999' : 'auto')};

  @media (max-width: 1000px) {
    padding-top: 5px;
    justify-content: space-around;
    transition: transform 0.3s ease;
    scroll-behavior: smooth;
    ${({ visible }) =>
      !visible &&
      `
    transform: translateY(calc(-100% - 25px));
    background: #04091d;
    background-attachment: fixed;
  `}
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenContainer = styled.div<{ isFixed?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.isFixed ? '94px' : 'auto')};

  @media (max-width: 1000px) {
    margin-top: ${(props) => (props.isFixed ? '70px' : 'auto')};
  }
`;
