import React, { useEffect } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 44,
      height: 24,
      padding: 2,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#106BF2',
          opacity: 1,
        },
      },
      '&$focusVisible $thumb': {
        color: '#106BF2',
        border: '6px solid #106BF2',
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 24 / 2,
      backgroundColor: 'lightgrey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

type IosSwitchProps = {
  isOn: boolean;
  onChange?: (isOn: boolean) => void;
};

const IosSwitch = ({ isOn, onChange }: IosSwitchProps) => {
  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    if (typeof isOn !== 'undefined') {
      setIsChecked(isOn);
    }
  }, [isOn]);

  return <IOSSwitch checked={isChecked} onChange={onChange ? (e) => onChange(e.target.checked) : undefined} />;
};

export default IosSwitch;
