import React, { useState } from "react";
import styled from "styled-components/macro";

import Step1 from "./steps/step-1.component";
import Step2 from "./steps/step-2.component";
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

const ModerationTutorial = (props) => {
  //const [step, setStep] = useState(props.step || 1);
  return (
    <Container>
      {props.step === 1 && <Step1 onClick={props.exitTutorial} />}
      {props.step !== 1 && (
        <Step2 onClick={props.exitTutorial} step={props.step} />
      )}
      <CloseButton onClick={props.closeTutorial}>
        <img
          alt=""
          src="/assets/icons/cross-outlined-white.svg"
          style={{ width: 50, height: 50 }}
        />
      </CloseButton>
    </Container>
  );
};

export default ModerationTutorial;
