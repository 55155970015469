import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import LiveStreamsDisplayer from './components/home/live-streams-displayer.component';
import { getLiveStreams } from '../../services/live.service';
import useLocale from '../../utils/locale/locale.hook';
import NotifSlice from '../../store/slices/notification.slice';
import { getWebsocketConnection } from '../../utils/websocket.utils';
import { Header } from './components/home/header.component';
import VideosDisplayer from './components/home/videos-displayer.component';
import { UserVideo } from '../settings/components/videos/section-videos.component';
import { getAllPornVideos, getAllPublicVideos } from '../../services/user-videos.service';
import { Loader } from '../../components/style/loader.component';
import colors from '../../themes/colors-v2.theme';
import InfiniteScroll from 'react-infinite-scroller';
import ModalSlice from '../../store/slices/modal.slice';
import { PrimaryButton } from '../../components/forms/primaryButton.component';
import { Route, Switch } from 'react-router-dom';
import StartLiveScreen from './components/start-live.component';
import Text from '../../components/style/text.component';
import WatchLive from './components/watch-live.component';

const videosPerPage = 20;

export default function LiveScreenV2() {
  const dispatch = useDispatch();
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const isLoadingRef = useRef(false);

  const [category, setCategory] = useState('All');
  const [liveStreams, setLiveStreams] = useState([]);
  const [displayedLiveStreams, setDisplayedLiveStreams] = useState([]);
  const [activeTab, setActiveTab] = useState<'live' | 'member-videos' | 'porn-videos'>('live');
  const [memberVideos, setMemberVideos] = useState<UserVideo[]>([]);
  const [pornVideos, setPornVideos] = useState<UserVideo[]>([]);
  const [hasMoreMemberVideos, setHasMoreMemberVideos] = useState(true);
  const [hasMorePornVideos, setHasMorePornVideos] = useState(true);
  const [currentPageMemberVideos, setCurrentPageMemberVideos] = useState(-1);
  const [currentPagePornVideos, setCurrentPagePornVideos] = useState(-1);
  const [memberVideosInfiniteScrollKey, setMemberVideosInfiniteScrollKey] = useState(0);
  const [pornVideosInfiniteScrollKey, setPornVideosInfiniteScrollKey] = useState(0);

  const refreshLiveStreams = () => {
    getLiveStreams().then((liveStreams) => {
      if (Array.isArray(liveStreams)) {
        dispatch(NotifSlice.actions.setOngoingLiveStreams(liveStreams.length));
        setLiveStreams(liveStreams);
      }
    });
  };

  /**
   * Fonction appelée lorsqu'on reçoit un nouveau message via la connexion WebSocket.
   */
  const onWSMessage = (e: any) => {
    const data = JSON.parse(e.data);
    if (data.type === 'live_started') {
      refreshLiveStreams();
    } else if (data.type === 'live_ended') {
      setLiveStreams((prevLiveStreams) => prevLiveStreams.filter((live) => live.id !== data.liveId));
    }
  };

  const getPublicVideos = async (page) => {
    const query = {
      limit: 20,
      page: page,
    };
    const videosData = await getAllPublicVideos(query);
    setMemberVideos(videosData);
    return videosData;
  };

  const loadMorePublicVideos = async () => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    const data = await getPublicVideos(currentPageMemberVideos + 1);
    setMemberVideos(memberVideos?.concat(data));
    setHasMoreMemberVideos(data.length === videosPerPage);
    hasMoreMemberVideos && setCurrentPageMemberVideos(currentPageMemberVideos + 1);
    isLoadingRef.current = false;
    setMemberVideosInfiniteScrollKey(Math.random());
  };

  const getPornVideos = async (page) => {
    const query = {
      limit: videosPerPage,
      page: page,
    };
    const videosData = await getAllPornVideos(query);
    setPornVideos(videosData);
    return videosData;
  };

  const loadMorePornVideos = async () => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    const data = await getPornVideos(currentPagePornVideos + 1);
    setPornVideos(pornVideos?.concat(data));
    setHasMorePornVideos(data.length === videosPerPage);
    hasMorePornVideos && setCurrentPagePornVideos(currentPagePornVideos + 1);
    isLoadingRef.current = false;
    setPornVideosInfiniteScrollKey(Math.random());
  };

  const handleRefreshMemberVideos = () => {
    setHasMoreMemberVideos(true);
    setCurrentPageMemberVideos(-1);
    setMemberVideosInfiniteScrollKey(0);
    setMemberVideos([]);
    setActiveTab('member-videos');
    setTimeout(() => {
      loadMorePublicVideos();
    }, 1000);
  };

  const handleRefreshPornVideos = () => {
    setHasMorePornVideos(true);
    setCurrentPagePornVideos(-1);
    setPornVideosInfiniteScrollKey(0);
    setPornVideos([]);
    setActiveTab('porn-videos');
    setTimeout(() => {
      loadMorePornVideos();
    }, 1000);
  };

  const handleClickStartLive = () => {
    dispatch(ModalSlice.actions.setLiveTerms(true));
  };

  useEffect(() => {
    if (category === 'All') {
      setDisplayedLiveStreams(liveStreams);
    } else {
      const filteredLiveStreams = liveStreams.filter((live) =>
        live.categories.find((cat) => category === cat.category)
      );
      setDisplayedLiveStreams(filteredLiveStreams);
    }
  }, [liveStreams, category]);

  useEffect(() => {
    refreshLiveStreams();
  }, []); // eslint-disable-line

  // nécessaire pour permettre le bon fonctionnement de la connexion WebSocket
  useEffect(() => {
    const ws = getWebsocketConnection();
    if (ws) {
      ws.removeEventListener('message', onWSMessage);
      ws.addEventListener('message', onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onWSMessage);
      }
    };
  }, []);

  useEffect(() => {
    if (memberVideos.length === 0) {
      loadMorePublicVideos();
    }
  }, [memberVideos]);

  useEffect(() => {
    if (pornVideos.length === 0) {
      loadMorePornVideos();
    }
  }, [pornVideos]);

  return (
    <Switch>
      <Route exact path="/live">
        <MainWrapper isDesktop={isDesktop}>
          <FixedHeader>
            <TitleWrapper>
              <img src="/assets/icons/sidebar/navbar/live-white.svg" width={26} height={24} />
              <Title>{locale('live.home.livestream')}</Title>
            </TitleWrapper>
            <PrimaryButton
              size="medium"
              style={{ background: '#F04438', borderRadius: 8, border: 'none' }}
              onClick={handleClickStartLive}
            >
              <Text style={{ textShadow: '0px 1px 2px rgba(0, 0, 0, 0.85)' }}>{locale('live.launch_your_live')}</Text>
            </PrimaryButton>
          </FixedHeader>
          {/* <Header
        setCategory={setCategory}
        refresh={refreshLiveStreams}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        handleRefreshMemberVideos={handleRefreshMemberVideos}
        handleRefreshPornVideos={handleRefreshPornVideos}
      /> */}

          <ContentWrapper>
            <LiveStreamsDisplayer liveStreams={displayedLiveStreams} />
          </ContentWrapper>
        </MainWrapper>
      </Route>
      <Route exact path="/live/start">
        <StartLiveScreen />
      </Route>
      <Route exact path="/live/:pseudo" component={WatchLive} />
    </Switch>
  );
}

const MainWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
`;

const ContentWrapper = styled.div`
  margin-top: 60px;
`;

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: calc(100% - 250px);
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 12px;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;
