import { useState, useEffect } from 'react';
import useLocale from '../../../../utils/locale/locale.hook';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';

type Props = {
  onChange: (data) => void;
  dataSelected: string[];
  getData: () => Promise<any>;
  keyName: string;
  phraseKey: string;
};

export const RandomProfilesFilters = (props: Props) => {
  const [dataSelected, setDataSelected] = useState(props.dataSelected || []);
  const [data, setData] = useState([]);
  const locale = useLocale();

  const onSelect = (item) => {
    const dataSelectedCopy = [...dataSelected];
    const index = dataSelectedCopy.findIndex((_item) => _item === item);
    if (index !== -1) {
      dataSelectedCopy.splice(index, 1);
    } else {
      dataSelectedCopy.push(item);
    }
    setDataSelected(dataSelectedCopy);
    props.onChange(dataSelectedCopy);
  };

  const getData = async () => {
    const _data = await props.getData();
    setData(_data);
  };

  useEffect(() => {
    setDataSelected(props.dataSelected);
  }, [props.dataSelected]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data.map((dataItem) => {
        return (
          <PrimaryButton
            key={dataItem[props.keyName]}
            onClick={() => onSelect(dataItem[props.keyName])}
            isActive={dataSelected.includes(dataItem[props.keyName])}
          >
            {locale(props.phraseKey + '.name.' + dataItem[props.keyName])}
          </PrimaryButton>
        );
      })}
    </>
  );
};
