import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import MapContainer from '../../../components/map-container.component';

import colors from '../../../themes/colors.theme';
import ChatSlice from '../../../store/slices/chat.slice';
import MapExploreV2 from '../../../components/explore/map-explorev2.component';

interface ComponentProps {
  latitude: number;
  longitude: number;
}

export default function MapLocation({ latitude, longitude }: ComponentProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        ChatSlice.actions.setMapLocation({
          visible: false,
          latitude: null,
          longitude: null,
        })
      );
    };
  }, []);

  return (
    <OverlayWrapper>
      <MapWrapper>
        <MapExploreV2
          exploreLatitude={latitude}
          exploreLongitude={longitude}
          style={{ height: '100%', width: '100%' }}
        />
        <CloseMap
          alt=""
          src="/assets/icons/cross-outlined-white.svg"
          onClick={() =>
            dispatch(
              ChatSlice.actions.setMapLocation({
                visible: false,
                latitude: null,
                longitude: null,
              })
            )
          }
        />
      </MapWrapper>
    </OverlayWrapper>
  );
}

const OverlayWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
`;

const MapWrapper = styled.div`
  width: 90%;
  height: 70%;
  background-color: ${colors.greyDark};
  position: relative;

  & #map {
    border-radius: 4px;
  }
`;

const CloseMap = styled.img`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  background-color: #1d2024;
  z-index: 500;
`;
