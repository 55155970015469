import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { useMediaQuery } from "react-responsive";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import Image from "../../components/image.component";
import { Loader } from "../../components/style/loader.component";
import Text from "../../components/style/text.component";
import {
  getNotModeratedPicturesCount,
  getRandomNotModeratedPicture,
  moderatePicture,
} from "../../services/pictures.service";
import ClassificationSlice from "../../store/slices/classification.slice";
import colors from "../../themes/colors-v2.theme";
import fonts from "../../themes/fonts.theme";
import { useLogin } from "../../utils/auth.utils";
import useLocale from "../../utils/locale/locale.hook";
import { replace } from "../../utils/locale/locale.utils";
import { Flex } from "../../components/style/flex.component";

const OverlayClassification = () => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const fullHeightRef = useRef(null);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { me } = useLogin();

  const [isLoading, setIsLoading] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [tutorialCategory, setTutorialCategory] = useState<
    "nonSexual" | "someSkin" | "softcore" | "hardcore" | "illegal" | "forbidden"
  >("nonSexual");
  const [numberOfPicturesToClassify, setNumberOfPicturesToClassify] =
    useState<number>(0);
  const [currentPicture, setCurrentPicture] = useState(null);

  const handleClickOutside = (e) => {
    if (!fullHeightRef?.current?.contains(e.target)) {
      dispatch(ClassificationSlice.actions.setOverlayVisibility(false));
    }
  };

  const getRandomPictureToClassify = async () => {
    setIsLoading(true);
    const data = await getRandomNotModeratedPicture();
    if (!data || !data?.picture) {
      setIsLoading(false);
      return;
    }
    setCurrentPicture(data.picture);
    setIsLoading(false);
  };

  const getPicturesToClassifyCount = async () => {
    const data = await getNotModeratedPicturesCount();
    if (!data) {
      setNumberOfPicturesToClassify(0);
      return;
    }
    const picturesCount: number = data?.picturesCount || 0;
    setNumberOfPicturesToClassify(picturesCount);
  };

  const handleModeratePicture = async (
    moderation:
      | "skip"
      | "nonSexual"
      | "someSkin"
      | "softcore"
      | "hardcore"
      | "illegal"
  ) => {
    setIsLoading(true);
    const data = await moderatePicture({
      picture: currentPicture,
      userId: me.id,
      moderation: moderation,
    });
    if (!data) {
      setIsLoading(false);
      console.log("ERROR: Could not moderate picture", data);
      return;
    }
    getRandomPictureToClassify();
  };

  useEffect(() => {
    getRandomPictureToClassify();
  }, []);

  useEffect(() => {
    getPicturesToClassifyCount();
  }, [currentPicture]);

  const user = {
    username: "Bearwbot",
    profilePicture: "/assets/imgs/no-profile-picture.svg",
  };

  const mapClassificationToIconAndName = {
    nonSexual: {
      icon: "/assets/icons/classification/non-sexual.svg",
      name: "global.nonsexual",
      classificationHtml: `<img src='/assets/icons/classification/non-sexual.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.nonSexual
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.nonsexual"
      )}</span></b>`,
    },
    someSkin: {
      icon: "/assets/icons/classification/some-skin.svg",
      name: "global.someskin",
      classificationHtml: `<img src='/assets/icons/classification/some-skin.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.someSkin
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.someskin"
      )}</span></b>`,
    },
    softcore: {
      icon: "/assets/icons/classification/softcore.svg",
      name: "global.softcore",
      classificationHtml: `<img src='/assets/icons/classification/softcore.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.softcore
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.softcore"
      )}</span></b>`,
    },
    hardcore: {
      icon: "/assets/icons/classification/hardcore.svg",
      name: "global.hardcore",
      classificationHtml: `<img src='/assets/icons/classification/hardcore.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.hardcore
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.hardcore"
      )}</span></b>`,
    },
    illegal: {
      icon: "/assets/icons/classification/illegal.svg",
      name: "global.illegal",
      classificationHtml: `<img src='/assets/icons/classification/illegal.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.illegal
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.illegal"
      )}</span></b>`,
    },
    forbidden: {
      icon: "/assets/icons/classification/forbidden.svg",
      name: "global.forbidden",
      classificationHtml: `<img src='/assets/icons/classification/forbidden.svg' alt='icon' style='margin-left: 12px; margin-right: 6px; ' /><b><span style='color: ${
        colors.classification.forbidden
      }; font-size: 16px; margin-right: 12px;'>${locale(
        "global.forbidden"
      )}</span></b>`,
    },
  };

  const usernameAndIconHtml = `<img src=${user.profilePicture} alt="profile picture" style="width: 18px; height: 18px; margin-left: 12px; margin-right: 4px; border-radius: 4px;" />
                              <span style="font-weight: bold;">
                                ${user.username}
                              </span>`;

  return (
    <div style={{ height: "100%" }}>
      <FixedHeader>
        <TitleWrapper>
          <img
            src="/assets/icons/sidebar/navbar/classification-white.svg"
            width={26}
            height={24}
          />
          <Title>{locale("global.classification")}</Title>
        </TitleWrapper>
      </FixedHeader>
      <MainContentWrapper isDesktop={isDesktop}>
        <MainContent isDesktop={isDesktop}>
          {!isTutorialOpen && (
            <TitleAndClose>
              <Title>{locale("classification.modal_title")}</Title>
            </TitleAndClose>
          )}
          {isTutorialOpen && (
            <TutorialHeader>
              <BackButtonContainer onClick={() => setIsTutorialOpen(false)}>
                <img
                  src="/assets/icons/nearby/chevron-right-white.svg"
                  alt="back button"
                  style={{ transform: "rotate(180deg)" }}
                />
                {!isDesktop && (
                  <Text style={{ marginLeft: 17 }}>
                    {locale("classification.back")}
                  </Text>
                )}
              </BackButtonContainer>
              <TutorialTitle>Tutoriel</TutorialTitle>
            </TutorialHeader>
          )}
          <OverlayContent isTutorialOpen={isTutorialOpen}>
            {!isTutorialOpen && (
              <ActionsContainer>
                <Text
                  fontSize="18px"
                  dangerouslySetInnerHTML={{
                    __html: replace(
                      locale("classification.pictures_remaining"),
                      {
                        number: numberOfPicturesToClassify,
                      }
                    ),
                  }}
                />
                <HeaderButtonsContainer>
                  <PrimaryButton
                    onClick={() => setIsTutorialOpen(true)}
                    style={{
                      borderRadius: 8,
                      whiteSpace: "nowrap",
                      background: colors.white,
                      color: colors.darkGrey2,
                      height: 44,
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    {locale("rating.read.tutorial")}
                  </PrimaryButton>
                  {numberOfPicturesToClassify > 0 && (
                    <PrimaryButton
                      isActive
                      style={{
                        borderRadius: 8,
                        whiteSpace: "nowrap",
                        height: 44,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                      onClick={() => handleModeratePicture("skip")}
                    >
                      {locale("global.skip")}
                    </PrimaryButton>
                  )}
                </HeaderButtonsContainer>
              </ActionsContainer>
            )}
            {!isTutorialOpen && (
              <ImageContainer>
                {!isLoading ? (
                  <>
                    {numberOfPicturesToClassify > 0 ? (
                      <Image
                        src={
                          currentPicture?.path ||
                          "/assets/imgs/no-profile-picture.svg"
                        }
                        width="auto"
                        height="100%"
                        alt=""
                        style={{
                          borderRadius: 8,
                          height: '320px',
                        }}
                      />
                    ) : (
                      <NoImageContainer>
                        <CongratsContainer>
                          <img
                            src="/assets/icons/classification/thumb.svg"
                            alt="icon"
                          />
                          <Text
                            style={{
                              color: colors.middleGreen,
                              fontSize: 38,
                              fontWeight: fonts.bold.weight,
                            }}
                          >
                            {locale("classification.bravo")}
                          </Text>
                        </CongratsContainer>
                        <Text
                          style={{
                            color: colors.darkGrey1,
                            fontSize: 18,
                          }}
                        >
                          {locale("classification.no_image_left_to_classify")}
                        </Text>
                      </NoImageContainer>
                    )}
                  </>
                ) : (
                  <Flex
                    style={{
                      height: "100%",
                      minHeight: "340px",
                      marginBottom: "32px"
                    }}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Loader color={colors.white} />
                  </Flex>
                )}
              </ImageContainer>
            )}
            {isTutorialOpen && (
              <TutorialContent isTutorialOpen={isTutorialOpen}>
                <TutorialSubHeader>
                  <Text
                    style={{
                      color: colors.classification[tutorialCategory],
                      fontSize: 26,
                      fontWeight: fonts.bold.weight,
                      gap: 17,
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 12,
                    }}
                  >
                    <img
                      src={
                        mapClassificationToIconAndName[tutorialCategory].icon
                      }
                      height={21.33}
                      width={21.33}
                    />
                    {locale(
                      mapClassificationToIconAndName[tutorialCategory].name
                    )}
                  </Text>
                  <Text
                    style={{
                      color: colors.darkGrey1,
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    {locale(`classification.tutorial.${tutorialCategory}`)}
                  </Text>
                </TutorialSubHeader>
                <ImagesContainer
                  isIllegalCategory={tutorialCategory === "illegal"}
                >
                  {tutorialCategory === "nonSexual" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/nonsexual1.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual2.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual3.jpg" />
                      <TutorialImage src="/assets/imgs/classification/nonsexual4.jpg" />
                    </>
                  )}
                  {tutorialCategory === "someSkin" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/someskin1.jpg" />
                      <TutorialImage src="/assets/imgs/classification/someskin2.webp" />
                      <TutorialImage src="/assets/imgs/classification/someskin3.jfif" />
                      <TutorialImage src="/assets/imgs/classification/someskin4.jpg" />
                    </>
                  )}
                  {tutorialCategory === "softcore" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/soft1.webp" />
                      <TutorialImage src="/assets/imgs/classification/soft2.webp" />
                      <TutorialImage src="/assets/imgs/classification/soft3.jfif" />
                      <TutorialImage src="/assets/imgs/classification/soft4.jfif" />
                    </>
                  )}
                  {tutorialCategory === "hardcore" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/hardcore1.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore2.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore3.webp" />
                      <TutorialImage src="/assets/imgs/classification/hardcore4.webp" />
                    </>
                  )}
                  {tutorialCategory === "illegal" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/illegal1.png" />
                    </>
                  )}
                  {tutorialCategory === "forbidden" && (
                    <>
                      <TutorialImage src="/assets/imgs/classification/forbidden1.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden2.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden3.png" />
                      <TutorialImage src="/assets/imgs/classification/forbidden4.png" />
                    </>
                  )}
                </ImagesContainer>
              </TutorialContent>
            )}
            {numberOfPicturesToClassify > 0 &&
              !isTutorialOpen &&
              !isLoading && (
                <Text
                  style={{
                    marginTop: 27,
                    textAlign: "center",
                    fontSize: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replace(locale("classification.considered_as"), {
                      classification:
                        mapClassificationToIconAndName[
                          currentPicture?.moderationHive || "softcore"
                        ].classificationHtml,
                      username: usernameAndIconHtml,
                    }),
                  }}
                />
              )}
            {(numberOfPicturesToClassify > 0 || isTutorialOpen) && (
              <ClassificationButtonsContainer isTutorialOpen={isTutorialOpen}>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("nonSexual")
                      : handleModeratePicture("nonSexual");
                  }}
                  active={isTutorialOpen && tutorialCategory === "nonSexual"}
                >
                  <img
                    src={mapClassificationToIconAndName["nonSexual"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === "nonSexual"
                          ? colors.white
                          : colors.classification.nonSexual,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["nonSexual"].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("someSkin")
                      : handleModeratePicture("someSkin");
                  }}
                  active={isTutorialOpen && tutorialCategory === "someSkin"}
                >
                  <img
                    src={mapClassificationToIconAndName["someSkin"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === "someSkin"
                          ? colors.white
                          : colors.classification.someSkin,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["someSkin"].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("softcore")
                      : handleModeratePicture("softcore");
                  }}
                  active={isTutorialOpen && tutorialCategory === "softcore"}
                >
                  <img
                    src={mapClassificationToIconAndName["softcore"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === "softcore"
                          ? colors.white
                          : colors.classification.softcore,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["softcore"].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("hardcore")
                      : handleModeratePicture("hardcore");
                  }}
                  active={isTutorialOpen && tutorialCategory === "hardcore"}
                >
                  <img
                    src={mapClassificationToIconAndName["hardcore"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color:
                        isTutorialOpen && tutorialCategory === "hardcore"
                          ? colors.white
                          : colors.classification.hardcore,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["hardcore"].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("illegal")
                      : handleModeratePicture("illegal");
                  }}
                  active={isTutorialOpen && tutorialCategory === "illegal"}
                >
                  <img
                    src={mapClassificationToIconAndName["illegal"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color: colors.classification.illegal,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["illegal"].name)}
                  </Text>
                </ClassificationButton>
                <ClassificationButton
                  onClick={() => {
                    isTutorialOpen
                      ? setTutorialCategory("forbidden")
                      : handleModeratePicture("illegal");
                  }}
                  active={isTutorialOpen && tutorialCategory === "forbidden"}
                >
                  <img
                    src={mapClassificationToIconAndName["forbidden"].icon}
                    alt="icon"
                  />
                  <Text
                    bold
                    style={{
                      color: colors.classification.forbidden,
                      fontSize: 16,
                    }}
                  >
                    {locale(mapClassificationToIconAndName["forbidden"].name)}
                  </Text>
                </ClassificationButton>
              </ClassificationButtonsContainer>
            )}
          </OverlayContent>
        </MainContent>
      </MainContentWrapper>
    </div>
  );
};

const MainContentWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isDesktop }) => (isDesktop ? "100%" : undefined)};
`;

const MainContent = styled.div<{ isDesktop: boolean }>`
  width: 713px;
  margin-top: ${({ isDesktop }) => (isDesktop ? "48px" : "0px")};
  background: #00000066;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
`;

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: ${fonts.bold.weight};
`;

const TitleAndClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 600;

  & img {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
    margin: 0 6px;
    width: 24px;
    height: 24px;
  }
`;

const OverlayContent = styled.div<{ isTutorialOpen: boolean }>`
  box-sizing: border-box;
  padding-top: ${({ isTutorialOpen }) => (isTutorialOpen ? "0" : "12px")};
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    padding: 12px;
    padding-top: 0;
    height: calc(100% - 120px);
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 1000px) {
    max-width: calc(100vw - 24px);
    min-width: calc(100vw - 24px);
  }
`;

const NoImageContainer = styled.div`
  background-color: ${colors.veryLightGrey};
  width: 100%;
  padding: 32px 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    text-align: center;
  }

  & img {
    width: 27px;
    height: 27px;
    min-width: unset;
  }
`;

const CongratsContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;
  width: 100%;
`;

const ClassificationButtonsContainer = styled.div<{ isTutorialOpen?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px 12px;
  ${({ isTutorialOpen }) => isTutorialOpen && "order: 1"};
`;

const ClassificationButton = styled.button<{ active?: boolean }>`
  outline: none;
  border: none;
  padding: 8px 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  background-color: ${({ active }) =>
    active ? colors.darkGrey1 : colors.veryLightGrey};

  &:hover {
    background-color: ${colors.lightGrey9};
  }
`;

const TutorialHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    background-color: ${colors.backgroundLightGrey};
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & img {
    width: 14px;
    height: 14px;
  }

  @media (max-width: 1000px) {
    margin-left: 12px;
  }
`;

const TutorialTitle = styled(Title)`
  margin: 0 auto;
  transform: translateX(-12px);

  @media (max-width: 1000px) {
    margin-left: 24px;
    margin-top: 21px;
    margin-bottom: 21px;
    font-size: 19px;
  }
`;

const TutorialSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32.5px;
`;

const TutorialContent = styled.div<{ isTutorialOpen?: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    ${({ isTutorialOpen }) => isTutorialOpen && "order: 2"};
  }
`;

const ImagesContainer = styled.div<{ isIllegalCategory: boolean }>`
  display: grid;
  grid-template-columns: ${({ isIllegalCategory }) =>
    isIllegalCategory ? "unset" : "repeat(2, 1fr)"};
  gap: 8px 8px;
  overflow: hidden;
`;

const TutorialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
`;

export default OverlayClassification;
