const colors = {
  primary: '#0D121C',
  primaryHover: '#f39f00',
  dark: '#000000',
  darkGradient:
    'linear-gradient(192deg, #093f8e -14.43%, #020414 25.27%, #020414 66.88%, #130538 87.37%, #05224e 108.5%)',
  darkText: '#98A2B3',
  darkBlue: '#106BF2',
  darkGray: '#16191E',
  graySeparator: '#71727633',
  graySeparator2: '#202939',
  grayBorder: '#475467',
  error: '#F63D68',
  blue: '#175CD3',
  greyDark: '#4d5565',
  greyDarkHover: '#343c4b',
  accent: '#dd6a00',
  accent2: '#D92D20',
  accent3: '#EF6820',
  premiumYellow: '#FAC515',
  veryLightOrange: '#FEFAF5',
  lightOrange: '#F9DBAF',
  darkOrange: '#DC6803',
  darkOrange1: '#E04F16',
  darkOrange2: '#FDB022',
  darkOrange3: '#B93815',
  darkGrey: '#717171',
  darkGrey1: '#667085',
  darkGrey2: '#344054',
  darkerGrey: '#101828',
  backgroundLightGrey: '#f7f8f9',
  backgroundLight: '#fff',
  backgroundDark: '#090c0f',
  backgroundDarkHover: '#07090c',
  veryLightGrey: '#F9FAFB',
  lightGrey1: '#5e6772',
  lightGrey2: '#a0a6b1',
  lightGrey3: '#cfd3da',
  lightGrey4: '#f0f1f3',
  lightGrey5: '#f0f1f3',
  lightGrey6: '#494e54',
  lightGrey7: '#EAECF0',
  lightGrey8: '#F2F4F7',
  lightGrey9: '#D0D5DD',
  lightGrey10: '#475467',
  lightGrey11: '#F1F2F5',
  lightGrey12: '#F9FAFB',
  danger: '#e91817',
  danger1: '#FDA29B',
  success: '#44D57B',
  grey: '#828282',
  white: '#ffffff',
  black: '#000000',
  green: '#32D583',
  darkGreen: '#027A48',
  lightViolet: '#875BF7',
  violet: '#7839EE',
  lightGreen: '#32D583',
  middleGreen: '#16B364',
  lightRed: '#F04438',
  classification: {
    nonSexual: '#44BD62',
    someSkin: '#FF9E47',
    softcore: '#FF5050',
    hardcore: '#FF006D',
    illegal: '#FF0000',
    forbidden: '#000000',
  },
};

export default colors;
