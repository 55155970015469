import { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components/macro";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import AlbumPreview from "./components/album-preview.component";

import {
  pictureAlbumTranfert,
  uploadPictures,
} from "./components/edit-pictures.utils";
import useLocale from "../../../../utils/locale/locale.hook";
import { getMyAlbums } from "../../../../services/users.service";
import { useLogin } from "../../../../utils/auth.utils";
import { ErrorWrapper } from "../settings-styled.component";
import { Loader } from "../../../../components/style/loader.component";
import colors from "../../../../themes/colors-v2.theme";

type AlbumSectionProps = {
  albumName: "myPhotos" | "myPrivatePhotos" | "quickshare";
};

const AlbumSection = ({ albumName }: AlbumSectionProps) => {
  const locale = useLocale();
  const { me } = useLogin();

  const [myAlbums, setMyAlbums] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const getData = async () => {
    setFetching(true);
    const res = await getMyAlbums();
    if (!res || !res[albumName] || !res[albumName].pictures) {
      setMyAlbums(null);
      setFetching(false);
      return;
    }
    setMyAlbums(res);
  };

  useEffect(() => {
    setSelectedAlbum(null);
    setLoading(null);
    setError(null);
    getData();
  }, [albumName]);

  useEffect(() => {
    if (myAlbums) {
      setSelectedAlbum(myAlbums[albumName]);
      setFetching(false);
    }
  }, [myAlbums]);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    await uploadPictures(e.target.files, selectedAlbum, setError);
    setLoading(false);
    getData();
  };

  const handlePictureTransfer = pictureAlbumTranfert(
    myAlbums,
    setError,
    getData
  );

  return (
    <Container>
      {error && <ErrorWrapper>{locale(error)}</ErrorWrapper>}
      {fetching ? <Loader color={colors.primary} /> : null}
      {!fetching && myAlbums && (
        <DndProvider backend={HTML5Backend}>
          <AlbumPreview
            setError={setError}
            selectedAlbum={selectedAlbum}
            setFetching={setFetching}
            myAlbums={myAlbums}
            getData={() => getData()}
            onUpdateAlbum={(pics) =>
              setSelectedAlbum((e) => ({ ...e, pictures: pics }))
            }
            handlePictureTransfer={handlePictureTransfer}
            handleFileUpload={handleFileUpload}
          />
        </DndProvider>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  margin: auto;
  border-radius: 4px;

  @media (max-width: 1000px) {
    margin-top: 12px;
    padding: 4px;
  }
`;

export default AlbumSection;
