import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  titleStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const Tab = ({ children, style }: PropsWithChildren<Props>) => {
  return <TabContainer style={style}>{children}</TabContainer>;
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
