import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components/macro";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ProfileInformations from "./components/profile-informations.component";
import ProfilePictures from "./components/profile-pictures.component";
import UserNotVisible from "./components/user-not-visible.component";
import MetaData from "../../components/seo/meta-data.component";
import QuickshareMenu from "./components/action-menus/quickshare-menu.component";
import WoofMenu from "./components/action-menus/woof-menu.component";

import useLocale from "../../utils/locale/locale.hook";
import { getUserProfile, visitProfile } from "../../services/users.service";
import {
  getUserFavorites,
  getUserFavoredBy,
} from "../../services/favorite.service";
import DisplayedConversation from "../messages/components/displayed-conversation.component";

import FavoritesDisplay from "./components/favorites-display.component";

import { Mode } from "./types/mode.enum";
import { useLogin } from "../../utils/auth.utils";
import { MenusShowed } from "./types/menus-showed.interface";
import ModalSlice from "../../store/slices/modal.slice";
import colors from "../../themes/colors-v2.theme";
import ProfileActions from "./components/profile-actions.component";
import SubmitReportModal from "../../components/miscellaneous/modals/submit-report.modal";
import BlockUserModal from "../../components/miscellaneous/modals/block-user.modal";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import fonts from "../../themes/fonts.theme";

enum VisitStatus {
  WAITING = "WAITING",
  SENT = "SENT",
  CANCELED = "CANCELED",
}

interface UserProfileProps {
  visible: boolean;
  isScreen?: boolean;
  closeModal?: () => void;
  onCloseAndGoBack?: () => void;
  scaleMainPicture?: number;
}

const AUTHORIZED_ROLES = ["premium", "admin"];

const UserProfile = (props: UserProfileProps) => {
  const {
    visible,
    isScreen,
    closeModal,
    scaleMainPicture,
    onCloseAndGoBack,
  } = props;
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me, isLogin } = useLogin();
  const modalStore = useSelector((state: any) => state.modalStore);
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [user, setUser] = useState(null);
  const [notExist, setNotExist] = useState(false);
  const [notVisible, setNotVisible] = useState(false);
  const [mode, setMode] = useState(Mode.INFORMATIONS);
  const [conversationId, setConversationId] = useState(null);
  const [newConversationUser, setNewConversationUser] = useState(null);
  const [visitStatus, setVisitStatus] = useState(VisitStatus.WAITING);
  const [hasTimer, setHasTimer] = useState<boolean>(null);
  const [timeLeft, setTimeLeft] = useState<number>(null);
  const [menusShowed, setMenusShowed] = useState<MenusShowed>({
    quickshare: false,
    woof: false,
  });
  const [actionsVisible, setActionsVisible] = useState(false);

  const actionsMenuRef = useRef(null);

  const modalRef = useRef(null);
  const onScroll = () => {
    console.log("scroll : ", window.scrollY);
  };
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener("scroll", onScroll);
      return () => modalRef.current.removeEventListener("scroll", onScroll);
    }
  }, [modalRef]);

  // on navigator back action
  useEffect(() => {
    window.onpopstate = () => {
      handleClose();
    };

    return () => {
      window.onpopstate = null;
    };
  }, [mode]);

  const getData = async (pseudo: string) => {
    const user = await getUserProfile(pseudo);
    if (!user || !user.id) {
      setUser(null);
      history.push("/404");
      return;
    }

    const userFavorites = await getUserFavorites(user.id, 10, 0);
    const userFavoredBy = await getUserFavoredBy(user.id, 10, 0);

    setNotVisible(!user?.isVisible);
    setUser({
      ...user,
      favorites: userFavorites,
      favored_by: userFavoredBy,
    });
  };

  const openConversationHandler = (conversationId: number) => {
    if (mode !== Mode.MESSAGES) {
      if (conversationId === null) {
        setConversationId(null);
        setNewConversationUser(user);
        setMode(Mode.MESSAGES);
      } else {
        setNewConversationUser(null);
        setConversationId(conversationId);
        setMode(Mode.MESSAGES);
      }
    }
  };

  const cancelVisitHandler = () => {
    if (
      AUTHORIZED_ROLES.includes(me.role) &&
      visitStatus === VisitStatus.WAITING
    ) {
      setVisitStatus(VisitStatus.CANCELED);
      setHasTimer(null);
      setTimeLeft(null);
    } else {
      dispatch(ModalSlice.actions.setPremium({ visible: true }));
    }
  };

  useEffect(() => {
    return () => {
      if (!!user?.id && visitStatus === VisitStatus.WAITING) {
        visitProfile({ targetId: user.id });
      }
    };
  }, [user]);

  useEffect(() => {
    const pseudo = modalStore.isUserProfileAnonymousAndPseudo.pseudo;
    if (pseudo) {
      getData(pseudo);
    } else {
      setUser(null);
    }
  }, [
    visible,
    window.location.pathname,
    modalStore.isUserProfileAnonymousAndPseudo.pseudo,
    modalStore.isUserProfileAnonymousAndPseudo.isAnonymous,
  ]);

  useEffect(() => {
    if (!!user?.id && user.id !== me.id) {
      setHasTimer(true);
      setTimeLeft(30);
    } else if (!!user?.id && user.id === me.id) {
      setHasTimer(false);
      setVisitStatus(VisitStatus.CANCELED);
    }
  }, [user, me]);

  useEffect(() => {
    let interval = null;

    if (hasTimer) {
      interval = setInterval(() => {
        setTimeLeft((current) => {
          if (current <= 0) {
            clearInterval(interval);
            setHasTimer(false);
            return 0;
          }

          return current - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [hasTimer]);

  useEffect(() => {
    if (timeLeft === 0 && visitStatus === VisitStatus.WAITING && !!user?.id) {
      visitProfile({ targetId: user.id });
      setVisitStatus(VisitStatus.SENT);
    }
  }, [timeLeft, visitStatus]);

  useEffect(() => {
    if (!visible && !!user?.id && visitStatus === VisitStatus.WAITING) {
      visitProfile({ targetId: user.id });
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      setVisitStatus(VisitStatus.WAITING);
      setHasTimer(null);
      setTimeLeft(null);
    }
  }, [visible]);

  const handleClose = useCallback(() => {
    if (!isDesktop) {
      document.body.style.position = "";
    }
    closeModal?.();
  }, []);

  const handleCloseAndGoBack = () => {
    if (!isDesktop) {
      document.body.style.position = "";
    }
    onCloseAndGoBack?.();
  };

  if (notExist) {
    handleClose();
    return <Redirect to="/404" />;
  }
  if (notVisible) return <UserNotVisible />;

  if (user) {
    return (
      <Container isScreen={isScreen} ref={modalRef}>
        {isScreen && (
          <MetaData
            title={locale("seo.profile.title", {
              pseudo: user.pseudo,
              community: user.community
                ? locale("community.name." + user.community?.communityKey)
                : locale("community.name.bear"),
              city: user?.city || "Paris",
            })}
            description={locale("seo.profile.description", {
              pseudo: user.pseudo,
              community: user.community
                ? locale("community.name." + user.community?.communityKey)
                : locale("community.name.bear"),
              city: user?.city || "Paris",
            })}
          />
        )}

        {!isDesktop && mode === Mode.INFORMATIONS ? (
          <div style={{ position: "fixed", top: 10, zIndex: 9 }}>
            <PrimaryButton
              size="medium"
              style={{
                border: "none",
                width: 36,
                height: 36,
                position: "fixed",
                left: 20,
                background: "#16191E33",
              }}
              onClick={() => {
                handleCloseAndGoBack()
              }}
            >
              <img
                alt="chevron"
                src="/assets/icons/nearby/chevron-right-white.svg"
                style={{ transform: "rotate(180deg)" }}
                width={16}
                height={16}
              />
            </PrimaryButton>
            {isLogin && me.id !== user?.id && mode === Mode.INFORMATIONS && (
              <PrimaryButton
                size="medium"
                style={{
                  border: "none",
                  width: 36,
                  height: 36,
                  position: "fixed",
                  right: 20,
                  background: "#16191E33",
                }}
                onClick={() => setActionsVisible((action) => !action)}
              >
                <img
                  alt=""
                  src={"/assets/icons/user-profile/flag-report.svg"}
                  width={16}
                  height={16}
                />
                <ActionsMenu visible={actionsVisible} ref={actionsMenuRef}>
                  <ActionsItem
                    onClick={() =>
                      dispatch(
                        ModalSlice.actions.setSubmitReport({
                          visible: true,
                          type: "user",
                          key: user?.id,
                        })
                      )
                    }
                    style={{ marginBottom: 16 }}
                  >
                    <p>{`${locale("report.general")} ${user.pseudo}`}</p>
                  </ActionsItem>
                  <ActionsItem
                    onClick={() =>
                      dispatch(
                        ModalSlice.actions.setBlockUser({
                          visible: true,
                          userId: user?.id ?? null,
                        })
                      )
                    }
                  >
                    <p>{`${locale("block.general")} ${user.pseudo}`}</p>
                  </ActionsItem>
                </ActionsMenu>
              </PrimaryButton>
            )}
          </div>
        ) : null}

        <ProfilePictures
          user={user}
          isScreen={isScreen}
          openConversationHandler={openConversationHandler}
          mode={mode}
          setMode={setMode}
          timerData={{
            hasTimer,
            timeLeft,
          }}
          cancelVisitHandler={cancelVisitHandler}
          menusShowed={menusShowed}
          setMenusShowed={setMenusShowed}
          scaleMainPicture={scaleMainPicture}
          isAnonymous={modalStore.isUserProfileAnonymousAndPseudo.isAnonymous}
        />
        <div style={{ flex: 1, position: isDesktop ? "relative" : "initial" }}>
          {mode === Mode.INFORMATIONS && (
            <ProfileInformations
              user={user}
              isScreen={isScreen}
              setMode={setMode}
              setMenusShowed={setMenusShowed}
              cancelVisitHandler={cancelVisitHandler}
              timerData={{
                hasTimer,
                timeLeft,
              }}
              menusShowed={menusShowed}
              openConversationHandler={openConversationHandler}
              mode={mode}
              isAnonymous={
                modalStore.isUserProfileAnonymousAndPseudo.isAnonymous
              }
              closeModal={handleCloseAndGoBack}
            />
          )}
          {mode === Mode.MESSAGES && (
            <MessagesWrapper isScreen={isScreen}>
              <DisplayedConversation
                conversationId={conversationId}
                newConversationUser={newConversationUser}
                conversationCreationCallback={(conversation) => {
                  setConversationId(conversation.id);
                  setNewConversationUser(null);
                }}
                fromProfile={true}
                goBackToProfile={() => setMode(Mode.INFORMATIONS)}
                messageMode={true}
              />
            </MessagesWrapper>
          )}
          {(mode === Mode.FAVORITES || mode === Mode.FAVORED_BY) && (
            <FavoritesDisplay user={user} mode={mode} setMode={setMode} />
          )}
          {isLogin && user.id !== me?.id && mode !== Mode.MESSAGES && (
            <ProfileActions
              user={user}
              menusShowed={menusShowed}
              setMenusShowed={setMenusShowed}
              openConversationHandler={openConversationHandler}
              mode={mode}
              setMode={setMode}
              isAnonymous={
                modalStore.isUserProfileAnonymousAndPseudo.isAnonymous
              }
            >
              <SubmitReportModal visible={modalStore.submitReportVisible} />

              <BlockUserModal visible={modalStore.blockUserVisible} />

              {menusShowed.quickshare && (
                <QuickshareMenu
                  user={user}
                  onClose={() =>
                    setMenusShowed({ quickshare: false, woof: false })
                  }
                />
              )}

              {menusShowed.woof && (
                <WoofMenu user={user} setMenusShowed={setMenusShowed} />
              )}
            </ProfileActions>
          )}
        </div>
      </Container>
    );
  }

  return <></>;
};

export default UserProfile;

const Container = styled.div<{ isScreen: boolean }>`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
  }
`;

const MessagesWrapper = styled.div<{ isScreen: boolean }>`
  height: ${(p) => (p.isScreen ? "90vh" : "100%")};
  background-color: ${colors.backgroundLight};
  border-radius: 0px 16px 16px 0px;
  overflow: hidden;
`;

const ActionsMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  width: 244px;
  top: 60px;
  right: 16px;
  padding: 12px 16px 12px 16px;
  background-color: ${colors.darkGray};
  border: solid 1px ${colors.grayBorder};
  z-index: 1;
  border-radius: 8px;

  @media (max-width: 1000px) {
    top: 40px;
    right: 0px;
  }
`;

const ActionsItem = styled.div`
  cursor: pointer;
  & > p {
    margin: 0;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 12px;
    color: ${colors.white};
  }
`;
