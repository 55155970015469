import React, { useState, useEffect, useRef } from "react";
import ReactCodeInput from "react-code-input";
import "./digitcodeinput.component.css";

type DigitCodeInputProps = {
  codeLength: number;
  onNext: (code: string) => void;
};

const DigitCodeInput = ({ codeLength, onNext }: DigitCodeInputProps) => {
  const [code, setCode] = useState("");

  async function makeApiCall(code: string) {
    onNext(code);
  }

  const handleChange = (code) => {
    setCode(code);
    if (code.length === codeLength) {
      makeApiCall(code);
    }
  };

  return (
    <ReactCodeInput
      type="number"
      fields={6}
      onChange={handleChange}
      value={code}
      name={""}
      inputMode={"numeric"}
    />
  );
};

export default DigitCodeInput;
