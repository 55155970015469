import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import colors from "../../themes/colors-v2.theme";
import useLocale from "../../utils/locale/locale.hook";
import fonts from "../../themes/fonts.theme";

const NotFoundScreen = () => {
  const locale = useLocale();

  return (
    <Wrapper>
      <Container>
        <InnerContainer>
          <img
            src="/assets/imgs/404/stuffed-bear.png"
            alt="stuffed bear"
            loading="lazy"
          />
          <Title>{locale("error.not-found")}</Title>
          <Subtitle>{locale("error.no-longer-accessible")}</Subtitle>
        </InnerContainer>
        <ButtonsContainer>
          <Link to="/">
            <Button>{locale("error.gay-dating")}</Button>
          </Link>
          <Link to="/live">
            <Button>{locale("error.see-videos")}</Button>
          </Link>
          <Link to="/">
            <Button>{locale("error.home")}</Button>
          </Link>
        </ButtonsContainer>
      </Container>
    </Wrapper>
  );
};

export default NotFoundScreen;

const Wrapper = styled.div`
  background-color: ${colors.backgroundLightGrey};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Container = styled.div`
  width: 595px;
  background-color: ${colors.white};
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);

  @media (max-width: 1000px) {
    max-width: 95vw;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: ${colors.veryLightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  padding: 32px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  margin: 0;
  color: ${colors.accent3};
  font-size: 38px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: ${fonts.regular.weight};
  color: ${colors.darkGrey1};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
  width: 100%;

  & > a {
    flex: 1;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  display: flex;
  background-color: ${colors.darkOrange1};
  border: 1px solid ${colors.darkOrange3};
  border-radius: 8px;
  outline: none;
  color: ${colors.white};
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  cursor: pointer;
  font-weight: ${fonts.bold.weight};
  font-size: 16px;
`;
