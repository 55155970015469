import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import colors from '../../themes/colors-v2.theme';

type Props = {
  isSelected: boolean;
  title: string;
  titleStyle?: React.CSSProperties;
  onClick?: () => void;
  index: number;
  setSelectedTab: (index: number) => void;
};

export const TabTitle = ({ title, setSelectedTab, onClick, index, isSelected, titleStyle }: Props) => {
  const handleClick = () => {
    setSelectedTab(index);
    onClick?.();
  };
  return (
    <StyledTabTitle isSelected={isSelected} onClick={handleClick} style={titleStyle}>
      {title}
    </StyledTabTitle>
  );
};

const StyledTabTitle = styled.div<{ isSelected: boolean }>`
  background: transparent;
  color: ${({ isSelected }) => (isSelected ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ isSelected }) => (isSelected ? `2px solid ${colors.darkBlue}` : `2px solid transparent`)};
`;
