import React, { useEffect, useRef } from 'react';
import Modal, { Styles as ModalStyles } from 'react-modal';
import styled from 'styled-components/macro';
import { disableBodyScroll } from 'body-scroll-lock';
import { useOnClickOutside } from '../../utils/clickOutside.utils';

import { FancyScrollbar } from '../style/fancy-scrollbar.style';
import colors from '../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';

Modal.setAppElement('#root');

interface ModalProps {
  visible: boolean;
  children: React.ReactNode;
  closeModal?: () => void;
  width?: string;
  mobileWidth?: string;
  canCloseModal?: boolean;
  canCloseModalUsingOverlay?: boolean;
  zIndex?: number;
  fullsize?: boolean;
  style?: any;
  closeOutside?: boolean;
  position?: { top: string; left: string };
  showCloseButton?: boolean;
  closeButtonSize?: number;
  left?: boolean;
  isPremiumModal?: boolean;
}

export default function ModalTemplate({
  visible,
  closeModal = () => {},
  children,
  width,
  mobileWidth,
  canCloseModal = true,
  canCloseModalUsingOverlay = true,
  zIndex = 1010,
  style,
  closeOutside = false,
  position,
  closeButtonSize,
  showCloseButton = true,
  left = false,
  isPremiumModal,
  fullsize,
}: ModalProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const modalCustomStyles: ModalStyles = {
    content: {
      borderRadius: 4,
      border: 'none',
      top: position ? position.top : isDesktop ? '58%' : '50%',
      left: position ? position.left : '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      zIndex: zIndex > 1000 ? zIndex + 1 : 1000,
      overflow: 'inherit',
      background: 'none',
      minHeight: '100%',
      maxHeight: '100%',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(9, 10, 13, 0.8)',
      zIndex: zIndex > 1000 ? zIndex : 1015,
    },
  };
  const modalWrapperRef = useRef(null);
  const modalRef = useRef(null);

  useOnClickOutside([modalWrapperRef], () => {
    if (canCloseModal && canCloseModalUsingOverlay) {
      closeModal();
    }
  });

  useEffect(() => {
    if (visible && navigator.platform !== 'iPhone') {
      disableBodyScroll(modalRef.current);
    }
  }, [visible]);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={!!closeModal && canCloseModal && canCloseModalUsingOverlay ? closeModal : () => {}}
      style={modalCustomStyles}
      ref={modalRef}
    >
      <ModalBody ref={modalWrapperRef} width={width} mobileWidth={mobileWidth} fullsize={fullsize}>
        {canCloseModal && showCloseButton && (
          <CloseButtonWrapper
            onClick={closeModal}
            closeOutside={closeOutside}
            left={left}
            isPremiumModal={isPremiumModal}
          ></CloseButtonWrapper>
        )}
        <Content style={style}>{children}</Content>
      </ModalBody>
    </Modal>
  );
}

const ModalBody = styled.div<{
  width?: string;
  mobileWidth?: string;
  fullsize?: boolean;
}>`
  position: relative;
  display: block;
  width: ${(props) => (props.fullsize ? '100vw' : props.width ? props.width : '640px')};
  padding: 0;
  ${(props) => props.fullsize && 'min-height: 100vh; min-height: -moz-available; min-height: -webkit-fill-available;'};

  @media (max-width: 1000px) {
    width: ${(props) => (props.fullsize ? '100vw' : props.mobileWidth || '95vw')};
  }
`;

const CloseButtonWrapper = styled.div<{
  closeOutside: boolean;
  left: boolean;
  isPremiumModal: boolean;
}>`
  width: 10px;
  height: 10px;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  position: absolute;
  z-index: 1000;
  top: ${(p) => (p.closeOutside ? '-30px' : '23.5px')};
  ${(p) => (p.left ? `left:${p.closeOutside ? '-50px;' : '10px;'}` : `right:${p.closeOutside ? '-50px;' : '18px;'}`)}
  cursor: pointer;

  ${({ isPremiumModal }) => isPremiumModal && 'display: none;'}

  @media (max-width: 1000px) {
    right: 5px;
  }
`;

const Content = styled.div`
  background-color: ${colors.dark};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & * {
    ${FancyScrollbar}
  }
`;
