import * as queryString from 'query-string';
import { del, get, post } from './utils.service';

export const getNearbyCategory = async (category, query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/nearby/${category}?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getSavedSearch = async () => {
  const res = await get(`/nearby/saved-search`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const saveSearch = async (filters, name) => {
  const res = await post(`/nearby/saved-search`, { filters, name });
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteSearch = async (id) => {
  const res = await del(`/nearby/saved-search/${id}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
