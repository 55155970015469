// Limit of conversations loaded on chat tab
export const CONVERSATION_PAGE_SIZE = 20;

// Links for the Guides categories
export const guidesCategoriesLinks = {
  guys_of_the_week: {
    key: 'guys_of_the_week',
    label: 'guys_of_the_week.shortcut',
    iconPath: '/assets/icons/shortcuts/gotw.svg',
    activeIconPath: '/assets/icons/shortcuts/gotw.svg',
    inactive: true,
  },
  shout_out: {
    key: 'shout_out',
    label: 'shout_out.shortcut',
    iconPath: '/assets/icons/shortcuts/shout-out.svg',
    activeIconPath: '/assets/icons/shortcuts/shout-out.svg',
    inactive: true,
  },
  guide_events: {
    key: 'guide_events',
    label: 'guide.shortcuts.events',
    iconPath: '/assets/icons/guides/events.svg',
    activeIconPath: '/assets/icons/shortcuts/events-active.svg',
    href: '/guide/events',
  },
  guide_bars: {
    key: 'guide_bars',
    label: 'guide.shortcuts.bar_night_club',
    iconPath: '/assets/icons/guides/bar.svg',
    activeIconPath: '/assets/icons/shortcuts/bar-active.svg',
    href: '/guide/bars',
  },
  guide_restaurants: {
    key: 'guide_restaurants',
    label: 'guide.shortcuts.restaurants',
    iconPath: '/assets/icons/guides/restaurants.svg',
    activeIconPath: '/assets/icons/shortcuts/restaurants-active.svg',
    href: '/guide/restaurants',
  },
  guide_shopping: {
    key: 'guide_shopping',
    label: 'guide.shortcuts.shopping',
    iconPath: '/assets/icons/guides/shopping.svg',
    activeIconPath: '/assets/icons/shortcuts/shopping-active.svg',
    href: '/guide/shopping',
  },
  guide_associations: {
    key: 'guide_associations',
    label: 'guide.shortcuts.associations',
    iconPath: '/assets/icons/guides/associations.svg',
    activeIconPath: '/assets/icons/shortcuts/associations-active.svg',
    href: '/guide/associations',
  },
  guide_accommodations: {
    key: 'guide_accommodations',
    label: 'guide.shortcuts.accommodations',
    iconPath: '/assets/icons/guides/accommodations.svg',
    activeIconPath: '/assets/icons/shortcuts/accommodations-active.svg',
    href: '/guide/accommodations',
  },
  guide_art_culture: {
    key: 'guide_art_culture',
    label: 'guide.shortcuts.art_culture',
    iconPath: '/assets/icons/guides/art-culture.svg',
    activeIconPath: '/assets/icons/shortcuts/art-culture-active.svg',
    href: '/guide/art-culture',
  },
};

// Correspondance between active page and mobile menu title
export const mobileMenuTitle = {
  '': 'Rencontre',
  guide: 'Guides',
  live: 'Live & Vidéos',
  classification: 'Classification',
};

// List of all woofs
export const woofs = {
  woof: { label: 'chat.woof.woof', img: '/assets/imgs/woof/woof.png' },
  pig: { label: 'chat.woof.pig', img: '/assets/imgs/woof/pig.png' },
  thinking_about_you: {
    label: 'chat.woof.think',
    img: '/assets/imgs/woof/brain.png',
  },
  nice_profile: {
    label: 'chat.woof.profile',
    img: '/assets/imgs/woof/profile.png',
  },
  dirty_game: {
    label: 'chat.woof.eggplant',
    img: '/assets/imgs/woof/eggplant.png',
  },
  get_me_pregnant: {
    label: 'chat.woof.avocado',
    img: '/assets/imgs/woof/avocado.png',
  },
  vaccinated: {
    label: 'chat.woof.vaccine',
    img: '/assets/imgs/woof/vaccine.png',
  },
  nice_beard: {
    label: 'chat.woof.beard',
    img: '/assets/imgs/woof/beard.png',
  },
  more_picture_of_you: {
    label: 'chat.woof.more-pictures',
    img: '/assets/imgs/woof/ghost.png',
  },
  hot_and_you: {
    label: 'chat.woof.hot',
    img: '/assets/imgs/woof/hot.png',
  },
  thanks_for_fav: {
    label: 'chat.woof.favorite',
    img: '/assets/imgs/woof/star-favorite.png',
  },
  adopt_me: {
    label: 'chat.woof.adopt_me',
    img: '/assets/imgs/woof/adopt_me.png',
  },
  hi_where_are_you: {
    label: 'chat.woof.location',
    img: '/assets/imgs/woof/location.png',
  },
  nice_ass: {
    label: 'chat.woof.peach',
    img: '/assets/imgs/woof/peach.png',
  },
  pizza_together: {
    label: 'chat.woof.pizza',
    img: '/assets/imgs/woof/pizza.png',
  },
  crush: {
    label: 'chat.woof.hearts',
    img: '/assets/imgs/woof/hearts.png',
  },
  nice_abs: {
    label: 'chat.woof.choco',
    img: '/assets/imgs/woof/choco.png',
  },
  love_at_first_sight: {
    label: 'chat.woof.thunder',
    img: '/assets/imgs/woof/coup-de-foudre.png',
  },
  beer: {
    label: 'chat.woof.beer',
    img: '/assets/imgs/woof/beer.png',
  },
  play: {
    label: 'chat.woof.want_to_play',
    img: '/assets/imgs/woof/want-to-play.png',
  },
  anonymous: {
    label: 'chat.woof.anonymous',
    img: '/assets/imgs/woof/woof.png',
  },
  pleased: {
    label: 'key_woof_type_pleased',
    img: '/assets/imgs/woof/woof.png',
  },
  'very-cute': {
    label: 'key_woof_type_very_cute',
    img: '/assets/imgs/woof/woof.png',
  },
  'nice-picture': {
    label: 'key_woof_type_nice_picture',
    img: '/assets/imgs/woof/woof.png',
  },
  kiss: { label: 'key_woof_type_kiss', img: '/assets/imgs/woof/woof.png' },
  hug: { label: 'key_woof_type_hug', img: '/assets/imgs/woof/woof.png' },
};
