import styled from "styled-components";
import colors from "../../themes/colors-v2.theme";
import { Flex } from "../style/flex.component";

export default function ExternalLink({
  href,
  label,
}: {
  href: string;
  label: string;
}) {
  return (
    <Flex
      gap="8px"
      style={{
        padding: "10px 16px",
        cursor: "pointer",
      }}
      direction="row"
      alignItems="center"
      onClick={() => window.open(href, "_blank")}
    >
      <Text>{label}</Text>
      <img
        alt="external link"
        width="12"
        height="12"
        src={"/assets/icons/link-external-01.svg"}
      />
    </Flex>
  );
}

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.white};
`;