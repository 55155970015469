import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import * as TwilioVideo from "twilio-video";
import { getRandomAnonymousText } from "../../utils/chat.utils";
import VideoActions from "./components/video-actions.component";
import { VideoCall } from "./types/video-call.interface";
import VideoCallUsers from "./video-call.users";

const TwilioVideoCall = ({ call }: { call: VideoCall }) => {
  const { videoCall } = useSelector((state: any) => state.modalStore);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [twilioRoom, setTwilioRoom] = useState(null);

  useEffect(() => {
    const getRoom = async () => {
      const room = await TwilioVideo.connect(videoCall.token);
      setTwilioRoom(room);
    };
    getRoom();
  }, [videoCall]);

  if (!twilioRoom || !videoCall) {
    return null;
  }

  return (
    <>
      <VideoCallUsers room={twilioRoom} />
      {isDesktop && <VideoActions call={call} room={twilioRoom} />}
    </>
  );
};

export default TwilioVideoCall;
