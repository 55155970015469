import { useSelector } from "react-redux";
import MeSlice from "../store/slices/me.slice";
import { store } from "../store/configure.store";
import AuthSlice from "../store/slices/auth.slice";
import {
  closeWebsocketConnection,
  initWebsocketConnection,
} from "./websocket.utils";
import NotifSlice from "../store/slices/notification.slice";
import ChatSlice from "../store/slices/chat.slice";
import { getNotSeenNotifications } from "../services/notifications.service";
import {
  getQuickshareUnseenCount,
  getUserMessagesNotSeen,
} from "../services/chat.service";
import { getLiveStreams } from "../services/live.service";
import ModalSlice from "../store/slices/modal.slice";
import { getRefreshToken } from "../services/auth.service";
import { updateUserLocation } from "./geolocation.utils";
import Cookies from "universal-cookie";

export const useLogin = () => {
  const me = useSelector((state: any) => state.meStore);
  const token = useSelector((state: any) => state.authStore.token);
  return {
    isLogin: Boolean(token && me !== null && Object.keys(me).length),
    me,
  };
};

export const setMe = async () => {
  const res = await getRefreshToken();

  if (res.error) {
    store.dispatch(MeSlice.actions.setMe({}));
    store.dispatch(AuthSlice.actions.setToken(null));
    localStorage.removeItem("token");
    const cookies = new Cookies();
    cookies.remove("token", { domain: "bearwww.com" });
    return;
  }

  if (window.localStorage.getItem("token")) {
    const cookies = new Cookies();
    cookies.set("token", res.jwt, {
      domain: ".bearwww.com",
      path: "/",
      secure: true,
    });
    localStorage.setItem("token", res.jwt);
  }
  store.dispatch(AuthSlice.actions.setToken(res.jwt));
  store.dispatch(MeSlice.actions.setMe(res.user));

  setTimeout(() => {
    initWebsocketConnection(store.getState().authStore.token);
  }, 2000);

  (async () => {
    const notSeen = await getNotSeenNotifications();
    store.dispatch(
      NotifSlice.actions.setNotificationsNotSeen(Number(notSeen.count))
    );
    const messagesNotSeen = await getUserMessagesNotSeen();
    store.dispatch(ChatSlice.actions.setMessagesNotSeen(messagesNotSeen));
    const totalMessagesNotSeen = Object.keys(messagesNotSeen).reduce(
      (acc: any, curr: any) => acc + messagesNotSeen[curr],
      0
    );
    store.dispatch(NotifSlice.actions.setMessagesNotSeen(totalMessagesNotSeen));
    const liveStreams = await getLiveStreams();
    if (Array.isArray(liveStreams)) {
      store.dispatch(
        NotifSlice.actions.setOngoingLiveStreams(liveStreams.length)
      );
    }
    const albumsNotSeen = await getQuickshareUnseenCount();
    store.dispatch(NotifSlice.actions.setAlbumsNotSeen(albumsNotSeen));
  })();

  updateUserLocation();

  if (Array.isArray(res.sanctions) && res.sanctions.length > 0) {
    const [sanction] = res.sanctions;
    store.dispatch(
      ModalSlice.actions.setSanctionAppeal({
        visible: true,
        type: sanction.type,
        key: sanction.id,
        canAppealSanction: sanction.canAppealSanction,
      })
    );
  }
};

export const logout = () => {
  store.dispatch(MeSlice.actions.resetMe());
  store.dispatch(AuthSlice.actions.resetToken());
  closeWebsocketConnection();
  store.dispatch(NotifSlice.actions.setNotificationsNotSeen(0));
  store.dispatch(NotifSlice.actions.setMessagesNotSeen(0));
  localStorage.removeItem("token");
  const cookies = new Cookies();
  cookies.remove("token", { domain: "bearwww.com" });
};
