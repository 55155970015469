import styled from 'styled-components/macro';
import { GuideType, ActivityDisplay } from './guides-displayer.component';
import useLocale from '../../../utils/locale/locale.hook';
import colors from '../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { useState, Dispatch, SetStateAction } from 'react';
import fonts from '../../../themes/fonts.theme';
import { useHistory } from 'react-router-dom';

interface ComponentProps {
  type: GuideType;
  setThisWeek?: (boolean) => void;
  weekOnly?: boolean;
  activityToDisplay?: ActivityDisplay;
  setActivityToDisplay?: (ActivityDisplay) => void;
  setIsSearchOpen?: Dispatch<SetStateAction<boolean>>;
  activeCategory: string;
  setActiveCategory: Dispatch<SetStateAction<string>>;
}

const GuidesHeader = ({
  type,
  activityToDisplay,
  setActivityToDisplay,
  setIsSearchOpen,
  activeCategory,
  setActiveCategory,
}: ComponentProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const history = useHistory();

  return (
    <Container>
      {/* {!isDesktop && activeCategory === '' && (
        <SearchButton onClick={() => setIsSearchOpen(true)}>
          {locale('nearby.search')}
          <img src="/assets/icons/sidebar/search.svg" alt="search icon" width={12} height={12} />
        </SearchButton>
      )} */}
      {activeCategory && (
        <ActiveFilter>
          {activeCategory}
          <img
            src="/assets/icons/guides/delete.svg"
            alt="delete button"
            width={7}
            height={7}
            onClick={() => {
              setActiveCategory('');
              history.push('/guide/events');
            }}
          />
        </ActiveFilter>
      )}
      <RowContainer>
        <Row>
          <FilterButton
            active={activityToDisplay === ActivityDisplay.ALL}
            onClick={() => setActivityToDisplay(ActivityDisplay.ALL)}
          >
            {isDesktop ? locale('guide.all-events') : locale('guide.all')}
          </FilterButton>
          <FilterButton
            active={activityToDisplay === ActivityDisplay.GOING}
            onClick={() => setActivityToDisplay(ActivityDisplay.GOING)}
          >
            {locale('guide.participation.going')}
          </FilterButton>
          <FilterButton
            active={activityToDisplay === ActivityDisplay.INTERESTED}
            onClick={() => setActivityToDisplay(ActivityDisplay.INTERESTED)}
          >
            {locale('guide.participation.interested')}
          </FilterButton>
          <FilterButton
            active={activityToDisplay === ActivityDisplay.CREATED}
            onClick={() => setActivityToDisplay(ActivityDisplay.CREATED)}
          >
            {isDesktop ? locale('mes_guides_cr_s') : locale('guide.my-guides')}
          </FilterButton>
        </Row>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div<{ isActivities?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: 0;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.grayBorder};
  }
`;

const RowContainer = styled.div`
  @media (max-width: 1000px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

const Row = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
  }
`;

const FilterButton = styled.div<{ active: boolean }>`
  cursor: pointer;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
`;

const SearchButton = styled.button`
  background: transparent;
  color: ${colors.darkGrey1};
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
`;

const ActiveFilter = styled.div`
  background: transparent;
  padding: 4px 4px 4px 10px;
  font-size: 14px;
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkGrey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  width: fit-content;
  margin-top: 13px;

  & > img {
    cursor: pointer;
    padding: 6px;
  }
`;

export default GuidesHeader;
