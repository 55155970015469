import React, { useState, useEffect, useRef, FC } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import ConversationHeader from "./conversation-header.component";
import ConversationMessages from "./messages/conversation-messages.component";
import ConversationActions from "./conversation-actions.component";
import MapLocation from "./map-location.component";
import SendLocationModal from "../modals/send-location.modal";

import useLocale from "../../../utils/locale/locale.hook";
import ChatSlice from "../../../store/slices/chat.slice";
import { getWebsocketConnection } from "../../../utils/websocket.utils";
import NotifSlice from "../../../store/slices/notification.slice";
import {
  getConversation,
  getConversationMessages,
  sendEphemeral,
  sendMessage,
  sendTyping,
  postConversationSeen,
} from "../../../services/chat.service";

import fonts from "../../../themes/fonts.theme";
import colors from "../../../themes/colors-v2.theme";

import { AddMediaStatus } from "../types/add-media-status.enum";
import { MobileDisplay } from "../types/mobile-display.enum";
import { ModalsShown } from "../types/modals-shown.type";
import moment from "moment";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import SelectUserPicturesModal from "../../../components/photos-management/modals/select-user-pictures.modal";
import SelectPicturesModal from "../../../components/miscellaneous/modals/select-picture.modal";
import { useLogin } from "../../../utils/auth.utils";

type SendTextMessageApiPayload = {
  content: string;
  type: "text";
  pictures: Array<{ path: string; isVideo?: boolean }>;
  conversationId?: string;
  members?: Array<{ userId: string }>;
};

enum SendMessageStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
}

enum TypingStatus {
  IDLE = "IDLE",
  SENDING = "SENDING",
}

interface ComponentProps {
  conversationId: number;
  newConversationUser: any;
  conversationCreationCallback?: (conversation: any) => void;
  setMobileDisplay?: React.Dispatch<React.SetStateAction<MobileDisplay>>;
  hasNoConversations?: boolean;
  options?: {
    showCloseConversation?: boolean;
  };
  closeConversationHandler?: () => void;
  optionsRef?: any;
  fromProfile?: boolean;
  goBackToProfile?: () => void;
  refreshConversationList?: () => void;
  messageMode?: boolean;
}

let ownTypingTimeout = null;
let typingTimeouts = [];

// il faudrait placer ce composant dans son propre fichier & utiliser `react-router` pour changer de page
// pas sûr de comprendre l'utilité de ce composant aujourd'hui
const BannedPost: FC<{ uuid: string }> = (props) => {
  return (
    <a href={`/posts/${props.uuid}`}>
      <BannedPostWrapper>
        {/* !! TODO : ajouter Phrase */}
        <p style={{ margin: 0 }}>Access to the banned post</p>
      </BannedPostWrapper>
    </a>
  );
};

export default function DisplayedConversation({
  conversationId,
  newConversationUser,
  conversationCreationCallback,
  setMobileDisplay,
  hasNoConversations,
  closeConversationHandler,
  options,
  optionsRef,
  fromProfile,
  goBackToProfile,
  refreshConversationList,
  messageMode,
}: ComponentProps) {
  const { me } = useLogin();

  const locale = useLocale();
  const dispatch = useDispatch();
  const location = useLocation();
  const { mapLocation, messagesNotSeen } = useSelector(
    (state: any) => state.chatStore
  );
  const limit = 10;

  const [displayedConversation, setDisplayedConversation] = useState(null);

  const [conversationMessages, setConversationMessages] = useState([]);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);

  const [typingStatus, setTypingStatus] = useState(TypingStatus.IDLE);
  const [usersTyping, setUsersTyping] = useState([]);
  const [sendMessageStatus, setSendMessageStatus] = useState(
    SendMessageStatus.IDLE
  );
  const [addMediaStatus, setAddMediaStatus] = useState(AddMediaStatus.HIDDEN);
  const { conversationId: paramConversationId }: any = useParams();

  const [messageContent, setMessageContent] = useState("");
  const [modals, setModals] = useState<ModalsShown>({
    showQuickshare: false,
    showLocation: false,
  });
  const [showQuickshareForm, setShowQuickshareForm] = useState(false);
  const conversationSelectorRef = useRef(null);

  const modalStore = useSelector((state: any) => state.modalStore);

  useEffect(() => {
    dispatch(ChatSlice.actions.setOpenedConversation(displayedConversation));

    return () => {
      dispatch(ChatSlice.actions.setOpenedConversation(null));
    };
  }, [displayedConversation]);

  // on id change
  useEffect(() => {
    setConversationMessages([]);
  }, []);

  /**
   * Function generated a new API payload for the `send-message` endpoint.
   */
  const generateNewMessagePayload = (
    content: string,
    pictures: any[]
  ): { body: SendTextMessageApiPayload; conversationCreated: boolean } => {
    if (displayedConversation?.id) {
      return {
        body: {
          content,
          type: "text",
          conversationId: displayedConversation.id,
          pictures,
        },
        conversationCreated: false,
      };
    } else {
      return {
        body: {
          content,
          type: "text",
          members: [{ userId: newConversationUser.id }],
          pictures,
        },
        conversationCreated: true,
      };
    }
  };

  const makeNewMessageApiCall = (
    body: SendTextMessageApiPayload,
    conversationCreated: boolean,
    newMessageIndex: number
  ) => {
    setSendMessageStatus(SendMessageStatus.LOADING);
    sendMessage(body)
      .then((res) => {
        setSendMessageStatus(SendMessageStatus.IDLE);
        if (res.success) {
          setAddMediaStatus(AddMediaStatus.HIDDEN);
          setMessageContent("");
          if (conversationCreated && !!conversationCreationCallback) {
            conversationCreationCallback(res.conversation);
          }
        } else {
          setConversationMessages((messages) => {
            const newMessages = JSON.parse(JSON.stringify(messages));
            newMessages[newMessageIndex].error = locale("chat.error.not_sent");
            return newMessages;
          });
        }
      })
      .catch(() => {
        setSendMessageStatus(SendMessageStatus.IDLE);
      });
  };

  const addMessageFromUser = (newMessage) => {
    setConversationMessages((messages) => [...messages, newMessage]);
    setMessageContent("");
    dispatch(ChatSlice.actions.setScrollToBottom(true));
  };

  const sendMessageHandler = () => {
    if (
      sendMessageStatus === SendMessageStatus.LOADING ||
      addMediaStatus === AddMediaStatus.LOADING
    ) {
      return;
    }
    if (!messageContent.trim()) {
      return;
    }

    const { body, conversationCreated } = generateNewMessagePayload(
      messageContent,
      []
    );

    const index = conversationMessages.length;
    const newMessage = {
      content: messageContent,
      created_at: moment().toISOString(),
      pictures: [],
      type: "text",
      sender: {
        id: me.id,
      },
    };
    addMessageFromUser(newMessage);
    makeNewMessageApiCall(body, conversationCreated, index);
  };

  const sendLocationHandler = (data: any) => {
    const { body, conversationCreated } = generateNewMessagePayload(
      messageContent,
      []
    );

    const index = conversationMessages.length;
    const newMessage = {
      content: messageContent,
      created_at: moment().toISOString(),
      pictures: [],
      type: "location",
      location: {
        latitude: data.latitude,
        longitude: data.longitude,
      },
      sender: {
        id: me.id,
      },
    };
    addMessageFromUser(newMessage);
    makeNewMessageApiCall(body, conversationCreated, index);
  }

  const sendPicturesMessageHandler = async (
    pictures: Array<{ path: string; isVideo?: boolean }>
  ) => {
    const { body, conversationCreated } = generateNewMessagePayload(
      messageContent,
      pictures
    );
    const index = conversationMessages.length;
    const newPictureMessage = {
      content: messageContent,
      created_at: moment().toISOString(),
      pictures,
      type: "text",
      conversation: {
        id: displayedConversation?.id,
      },
      sender: {
        id: me.id,
      },
    };
    addMessageFromUser(newPictureMessage);
    makeNewMessageApiCall(body, conversationCreated, index);
  };

  const sendEphemeralMessageHandler = (pictures: any[]) => {
    sendEphemeral({
      conversationId: displayedConversation.id,
      pictures,
    }).then((res) => {
      if (res?.success) {
        setAddMediaStatus(AddMediaStatus.HIDDEN);
      }
    });
  };

  const getMessages = async (page: number) => {
    const res = await getConversationMessages(
      displayedConversation.id,
      page,
      limit
    );

    if (!Array.isArray(res) || res.length === 0) {
      setHasMoreMessages(false);
      return;
    }

    const newMessages = res.concat(conversationMessages);
    setConversationMessages(newMessages);

    if (res.length === 30) {
      setTimeout(() => {
        setHasMoreMessages(true);
      }, 300);
    }
  };

  const getMoreMessages = async (page: number) => {
    setHasMoreMessages(false);
    getMessages(page);
  };

  /**
   * Fonction appelée lorsqu'on reçoit un nouveau message via la connexion WebSocket.
   */
  const onWSMessage = (e: any) => {
    const data = JSON.parse(e.data);

    if (data?.message?.sender_id === me.id) {
      return null;
    }

    if (data.type === "chat_message") {
      // nouveau message (quelque soit le type)
      if (data.message.conversation.id === displayedConversation?.id) {
        const messageSender = data.conversation.conversation_users.find(
          (member: any) => member.user.id === data.message.sender.id
        );
        const newMessage = {
          ...data.message,
          sender: messageSender.user,
        };
        setConversationMessages((messages) => [...messages, newMessage]);
        setUsersTyping((prevUsers) =>
          prevUsers.filter((_user) => _user.id !== messageSender.user.id)
        );
        dispatch(ChatSlice.actions.setScrollToBottom(true));
        postConversationSeen({ conversationId: data.message.conversation.id });
      }
    } else if (data.type === "chat_typing") {
      // indique que quelqu'un est en train d'écrire un message
      if (data.conversation.id === displayedConversation?.id) {
        const found = usersTyping.find((_user) => _user.id === data.user.id);
        if (found) {
          return;
        }

        setUsersTyping((prevUsers) => prevUsers.concat(data.user));
        dispatch(ChatSlice.actions.setScrollToBottom(true));
        const timeout = setTimeout(() => {
          setUsersTyping((prevUsers) =>
            prevUsers.filter((user) => user.id !== data.user.id)
          );
        }, 15000);
        typingTimeouts.push(timeout);
      }
    } else if (data.type === "chat_quickshare_updated") {
      // indique que quelqu'un vient d'accepter ou de refuser une demande de quickshare
      if (data.conversation.id === displayedConversation?.id) {
        setConversationMessages((prevMessages) => {
          const newMessages: any[] = JSON.parse(JSON.stringify(prevMessages));
          const messageIndex = newMessages.findIndex(
            (_message) => _message.id === data.messageId
          );
          const foundMessage = newMessages[messageIndex];
          if (foundMessage) {
            foundMessage.quickshare.status = data.newStatus;
            if (messageIndex + 5 > newMessages.length) {
              dispatch(ChatSlice.actions.setScrollToBottom(true));
            }
          }
          return newMessages;
        });
      }
    } else if (data.type === "chat_conversation_seen") {
      if (data.conversationId === displayedConversation?.id) {
        setDisplayedConversation((c) => ({
          ...c,
          last_message_seen: data.timestamp,
        }));
        refreshConversationList();
      }
    }
  };

  /**
   * Fonction permettant d'enlever les messages non-lus d'une conversation grâce à son ID.
   */
  const updateMessagesNotSeen = (conversationId: number) => {
    const updatedMessagesNotSeen = { ...messagesNotSeen };
    delete updatedMessagesNotSeen[conversationId];
    dispatch(ChatSlice.actions.setMessagesNotSeen(updatedMessagesNotSeen));
    const totalUpdatedMessagesNotSeen = Object.keys(
      updatedMessagesNotSeen
    ).reduce((acc: any, curr: any) => acc + updatedMessagesNotSeen[curr], 0);
    dispatch(
      NotifSlice.actions.setMessagesNotSeen(totalUpdatedMessagesNotSeen)
    );
  };

  // Récupére les messages d'une conversation lorsque celle-ci est sélectionnée
  useEffect(() => {
    if (displayedConversation) {
      setConversationMessages([]);
      getConversationMessages(displayedConversation.id, 0, limit).then(
        (messages) => {
          if (Array.isArray(messages)) {
            setConversationMessages(messages);
            updateMessagesNotSeen(displayedConversation.id);
            dispatch(ChatSlice.actions.setScrollToBottom(true));
            setHasMoreMessages(messages.length === 30);
          }
        }
      );
    }
  }, [displayedConversation?.id]);

  // nécessaire pour permettre le bon fonctionnement de la connexion WebSocket
  useEffect(() => {
    const ws = getWebsocketConnection();
    if (ws) {
      ws.removeEventListener("message", onWSMessage);
      ws.addEventListener("message", onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener("message", onWSMessage);
      }
    };
  }, [displayedConversation, usersTyping]);

  useEffect(() => {
    if (
      typingStatus !== TypingStatus.SENDING &&
      displayedConversation &&
      !!messageContent.trim()
    ) {
      setTypingStatus(TypingStatus.SENDING);
      clearTimeout(ownTypingTimeout);
      ownTypingTimeout = setTimeout(() => {
        setTypingStatus(TypingStatus.IDLE);
      }, 15000);
      sendTyping({ conversationId: displayedConversation?.id });
    }
  }, [messageContent, displayedConversation]);

  useEffect(() => {
    if (sendMessageStatus === SendMessageStatus.LOADING) {
      clearTimeout(ownTypingTimeout);
      setTypingStatus(TypingStatus.IDLE);
    }
  }, [sendMessageStatus]);

  // cleanup screen effect
  useEffect(() => {
    return () => {
      for (const timeout of typingTimeouts) {
        clearTimeout(timeout);
      }
      typingTimeouts = [];
      clearTimeout(ownTypingTimeout);
      ownTypingTimeout = null;
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        ChatSlice.actions.setMapLocation({
          visible: false,
          latitude: null,
          longitude: null,
        })
      );
    };
  }, [displayedConversation]);

  useEffect(() => {
    const id = paramConversationId ?? conversationId;
    if (id) {
      getConversation(id).then((res) => {
        if (!res.error && !res.message && !!res.conversation) {
          setDisplayedConversation(res.conversation);
        } else {
          setDisplayedConversation(null);
        }
      });
    } else {
      setDisplayedConversation(null);
    }
  }, [paramConversationId, conversationId]);

  const isAdminConversation = displayedConversation?.conversation_users?.some(
    (user) => user.user.role === "admin"
  );

  return (
    <Wrapper>
      {modalStore.selectUserPictures.visible && (
        <SelectUserPicturesModal
          visible={modalStore.selectUserPictures.visible && !fromProfile}
        />
      )}
      <ConversationWrapper ref={conversationSelectorRef}>
        {(!hasNoConversations && displayedConversation) ||
        newConversationUser ? (
          <>
            <ConversationHeader
              conversation={displayedConversation}
              newConversationUser={newConversationUser}
              setMobileDisplay={setMobileDisplay}
              canCloseConversation={
                !!options?.showCloseConversation && !!closeConversationHandler
              }
              closeConversationHandler={closeConversationHandler}
              optionsRef={optionsRef}
              fromProfile={fromProfile}
              GoBackToProfile={goBackToProfile}
              refreshConversationList={refreshConversationList}
              messageMode={messageMode}
            />
            <PatternBackground>
              {newConversationUser ? (
                <NewConversationWrapper>
                  {locale("chat.messages.new_conversation", {
                    pseudo: newConversationUser.pseudo,
                  })}
                </NewConversationWrapper>
              ) : (
                <ConversationMessages
                  conversation={displayedConversation}
                  messages={conversationMessages}
                  setConversationMessages={setConversationMessages}
                  getMoreMessages={getMoreMessages}
                  hasMoreMessages={hasMoreMessages}
                  usersTyping={usersTyping}
                  showQuickshareForm={showQuickshareForm}
                  setShowQuickshareForm={setShowQuickshareForm}
                />
              )}
              {displayedConversation &&
                displayedConversation?.postUUID !== null && (
                  <BannedPost uuid={displayedConversation?.postUUID} />
                )}
              {!isAdminConversation ? (
                <ConversationActions
                  conversation={displayedConversation}
                  addMediaStatus={addMediaStatus}
                  setAddMediaStatus={setAddMediaStatus}
                  setModals={setModals}
                  setShowQuickshareForm={setShowQuickshareForm}
                  sendMessageHandler={sendMessageHandler}
                  messageContent={messageContent}
                  setMessageContent={setMessageContent}
                  sendPicturesMessageHandler={sendPicturesMessageHandler}
                  sendEphemeralMessageHandler={sendEphemeralMessageHandler}
                  onSendLocation={sendLocationHandler}
                />
              ) : (
                <div style={{ marginTop: 4 }}>
                  <p
                    style={{
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    {locale("chat.messages.cannot_chat")}
                  </p>
                </div>
              )}
            </PatternBackground>
          </>
        ) : (
          <>
            {hasNoConversations && !newConversationUser && (
              <NoConversations>
                {locale("chat.messages.no_messages")}
              </NoConversations>
            )}
          </>
        )}
        {mapLocation.visible && (
          <MapLocation
            latitude={mapLocation.latitude}
            longitude={mapLocation.longitude}
          />
        )}
        <SendLocationModal
          visible={modals.showLocation}
          closeModal={() =>
            setModals((prevState) => ({
              ...prevState,
              showLocation: false,
            }))
          }
          conversation={displayedConversation}
          setAddMediaStatus={setAddMediaStatus}
        />
      </ConversationWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  bottom: 0;

  @media (max-width: 1000px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
  }
`;

const ConversationWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PatternBackground = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

const NewConversationWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.darkerGrey};
  font-size: 16px;
  background: ${colors.darkGradient};
`;

const NoConversations = styled.div`
  margin: auto;
  width: 92%;
  text-align: center;
  font-size: 16px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkGrey};
`;

const BannedPostWrapper = styled.div`
  width: calc(100% - 20px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  border-radius: 4px;
  border: solid 1px #343536;
  background-color: ${colors.backgroundLight};

  & > p {
    margin: 0;
    color: ${colors.darkGrey};
  }
`;
