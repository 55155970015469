import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../../components/forms-v2/button.component';
import Textarea from '../../../components/forms-v2/textarea.input';
import Dropdown from '../../../components/forms/dropdown.component';
import Text from '../../../components/style/text.component';

import useLocale from '../../../utils/locale/locale.hook';
import { createReport } from '../../../services/reports.service';

import ModalSlice from '../../../store/slices/modal.slice';

import fonts from '../../../themes/fonts.theme';
import { REPORT_MOTIVES } from '../../../utils/constants.util';
import colors from '../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../forms/primaryButton.component';
import { UserModalTemplate } from '../../../pages/user-profile/components/user-modal.component';
import { Flex } from '../../style/flex.component';
import { toggleBlock } from '../../../services/users.service';
import { useNotification } from '../../../utils/useNotification';

enum ReportStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface ModalProps {
  visible: boolean;
  style?: React.CSSProperties;
}

export default function SubmitReportModal({ visible, style }: ModalProps) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { submitReport } = useSelector((state: any) => state.modalStore);
  const [status, setStatus] = useState(ReportStatus.IDLE);
  const [hasBlocked, setHasBlocked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { triggerNotification } = useNotification();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [motive, setMotive] = useState('illegal');
  const [comment, setComment] = useState('');

  const submitReportHandler = async () => {
    if (comment.length < 10) {
      return;
    }
    if (status === ReportStatus.LOADING || status === ReportStatus.SUCCESS) {
      return;
    }

    if (!comment.trim() && submitReport.type === 'user') {
      setStatus(ReportStatus.ERROR);
      setErrorMessage(locale('report.errors.add_comment') || 'Please add a comment.');
      return;
    }

    setStatus(ReportStatus.LOADING);
    const body = {
      type: submitReport.type,
      motive,
      comment,
    } as any;

    if (submitReport.type === 'post') {
      body.postSlug = submitReport.key;
    } else if (submitReport.type === 'user') {
      body.userId = submitReport.key;
    } else if (submitReport.type === 'live') {
      body.liveId = submitReport.key;
    }

    createReport(body)
      .then((res) => {
        if (res?.success) {
          setStatus(ReportStatus.SUCCESS);
        } else {
          const errorMessage = locale(res.localeErrorKey) || locale('report.errors.error_occured');
          setErrorMessage(errorMessage);
          setStatus(ReportStatus.ERROR);
        }
      })
      .then(() => {
        if (hasBlocked) {
          toggleBlock(submitReport.key).then((res) => {
            if (res?.success) {
              triggerNotification({
                visible: true,
                title: locale('blocking.profile_tootip.success_notice'),
              });
            }
            closeModal();
          });
        }
      });
  };

  const closeModal = () =>
    dispatch(
      ModalSlice.actions.setSubmitReport({
        visible: false,
        type: null,
        key: null,
      })
    );

  useEffect(() => {
    if (!!visible && (submitReport.type === null || submitReport.key === null)) {
      closeModal();
    }
  }, [visible, submitReport]);

  useEffect(() => {
    if (!visible) {
      setMotive('illegal');
      setComment('');
      setStatus(ReportStatus.IDLE);
      setErrorMessage('');
    }
  }, [visible]);

  const mapReportTypeToLabel = {
    comment: locale('report.types.comment'),
    post: locale('report.types.post'),
    user: locale('report.types.user'),
  };

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModal}
      style={{
        width: '100%',
        borderRadius: 16,
        height: 'fit-content',
        background: colors.darkGray,
        position: 'absolute',
        bottom: 80,
        border: `1px solid ${colors.grayBorder}`,
        padding: '14px 24px',
        ...style,
      }}
      title={
        <Flex alignItems="center" gap="16px">
          <img
            src="/assets/icons/nearby/chevron-right.svg"
            alt="chevron"
            style={{ width: 8, height: 14, transform: 'rotate(180deg)' }}
            onClick={closeModal}
          />
          <Text bold fontSize="16px">
            {locale('report.title')} {mapReportTypeToLabel[submitReport.type]}
          </Text>
        </Flex>
      }
    >
      <ContentWrapper>
        <Form>
          <MarginWrapper>
            <Label>{locale('report.motive.label')}</Label>
            <Dropdown
              lines={REPORT_MOTIVES.map((e) => ({
                value: e.value,
                label: locale(e.phraseKey),
              }))}
              placeholder={locale('report.motive.label')}
              value={motive}
              onSelect={(value) => setMotive(value)}
            />
          </MarginWrapper>
          <MarginWrapper>
            <Textarea
              onChange={(text) => setComment(text)}
              value={comment}
              placeholder={locale('report.comment.label')}
              resize="vertical"
            />
            <Text>{locale('report.requirement')}</Text>
          </MarginWrapper>

          <Flex>
            <input
              type="checkbox"
              checked={hasBlocked}
              onClick={() => setHasBlocked((confirm) => !confirm)}
              style={{ alignSelf: 'flex-start', margin: '4px 16px 0 0', cursor: 'pointer' }}
            />
            <Flex direction="column" alignItems="flex-start">
              <Text style={{ marginBottom: 8 }}>{locale('chat.block_user')}</Text>
              <Text style={{ marginBottom: 10, fontSize: '12px' }}>{locale('chat.block_user_text1')}</Text>
              <Text style={{ fontSize: '12px' }}>{locale('chat.block_user_text2')}</Text>
            </Flex>
          </Flex>

          {status === ReportStatus.ERROR && (
            <MarginWrapper>
              <Text>{errorMessage}</Text>
            </MarginWrapper>
          )}
          <ButtonWrapper>
            <PrimaryButton
              size="large"
              isActive={true}
              style={{ width: '100%', marginTop: 16 }}
              onClick={submitReportHandler}
              disabled={comment.length < 10}
            >
              {locale('global.form.submit')}
            </PrimaryButton>
          </ButtonWrapper>
        </Form>
      </ContentWrapper>
    </UserModalTemplate>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: black;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 16px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: white;
  margin-bottom: 8px;
`;

const MarginWrapper = styled.div`
  margin-bottom: 24px;

  & textarea {
    border-color: ${colors.grayBorder};
    background: ${colors.darkGray};
    height: 10vh;
    caret-color: white;
    color: white;
    font-size: 16px;
    ::placeholder {
      color: ${colors.lightGrey1};
      font-size: 16px;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
