import moment from "moment";
import { store } from "../store/configure.store";
import { replace } from "./locale/locale.utils";

export const getFormatedDate = (date, format?: string) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format("LLL");
};

export const getDurationDate = (date) => {
  const keys = store.getState().localeStore.keys;

  var today = new Date();
  var a = moment(today);
  var b = moment(date);

  const minutes = a.diff(b, "minutes");
  if (minutes === 0) return keys && keys["time.less_than_minute"]?.message;

  const hours = a.diff(b, "hours");
  if (hours === 0)
    return keys && replace(keys["time.minutes"]?.message, { number: minutes });

  const days = a.diff(b, "days");
  if (days === 0)
    return keys && replace(keys["time.hours"]?.message, { number: hours });

  const weeks = a.diff(b, "weeks");
  if (weeks === 0)
    return keys && replace(keys["time.days"]?.message, { number: days });

  return keys && replace(keys["time.weeks"]?.message, { number: weeks });
};

export const getAge = (date) => {
  if (!date) return 0;
  var today = new Date();
  let birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
