import { get, post } from "./utils.service";

export const startVideoCall = async (targetId: string) => {
  const res = await post("/video-call/start", { targetId });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getVideoCall = async (callId: number | string) => {
  const res = await get(`/video-call/${callId}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const answerVideoCall = async (
  callId: number | string,
  answer: "joined" | "refused"
) => {
  const res = await post(`/video-call/${callId}/answer`, { answer });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const leaveVideoCall = async (callId: number | string) => {
  const res = await post(`/video-call/${callId}/leave`, {});
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const reportUserDisconnection = async (callId: number | string) => {
  const res = await post(`/video-call/${callId}/user-disconnect`, {});
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
