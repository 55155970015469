import React, { Fragment, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";
import { VideoCustom } from "./messages/video.component";
import { Flex } from "../../../components/style/flex.component";
import styled from "styled-components";
import { uploadFile } from "../../../utils/s3.utils";
import useLocale from "../../../utils/locale/locale.hook";

// interface HTMLVideoElement extends globalThis.HTMLVideoElement {
//   captureStream?(frameRate?: number): MediaStream;
// }

import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../../../components/style/loader.component";
import colors from "../../../themes/colors-v2.theme";

const ffmpeg = createFFmpeg({ log: true });

const convertWebMtoMP4 = async (webmBlob) => {
  await ffmpeg.load();
  ffmpeg.FS("writeFile", "input.webm", await fetchFile(webmBlob));
  await ffmpeg.run("-i", "input.webm", "output.mp4");
  const data = await ffmpeg.FS("readFile", "output.mp4");
  const mp4Blob = new Blob([data], { type: "video/mp4" });
  return mp4Blob;
};

type Props = {
  onSelectedPictures: (
    pictures: Array<{ path: string; isVideo?: boolean }>
  ) => void;
  onClose: () => void;
};

export const WebcamStreamCapture = ({ onSelectedPictures, onClose }: Props) => {
  const locale = useLocale();
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [timer, setTimer] = useState(0);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const [uploading, setUploading] = useState(false);

  const handleStartCaptureClick = React.useCallback(
    (e) => {
      setTimer(0);
      setCapturing(true);

      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );

      setTimeout(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
      }, 30000);

      mediaRecorderRef.current.start();
      e.stopPropagation();
    },
    [webcamRef, setCapturing, mediaRecorderRef]
  );

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleUpload = async () => {
    if (recordedChunks.length === 0) return;
    const webmBlob = new Blob(recordedChunks, {
      type: 'video/webm',
    });

    setUploading(true);
    
    const mp4Blob = await convertWebMtoMP4(webmBlob);

    const videoFile = new File([mp4Blob], "recorded-video.mp4", {
      type: "video/mp4",
    });

    const uploadedPictures: Array<{ path: string; isVideo?: boolean }> = [];

    const isVideo = true;
    const { path, error } = await uploadFile(videoFile);
    if (error) {
      alert(locale(error));
    } else {
      uploadedPictures.push({ path, isVideo });
    }
    onSelectedPictures(uploadedPictures);
    onClose();
    setUploading(false);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (capturing) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [capturing]);

  if (uploading) {
    return (
      <Flex
        direction="column"
        gap={"12px"}
        alignItems="center"
        style={{ paddingBottom: "8px" }}
      >
        <Loader color={colors.white} />
        <Text>{locale("global.loading")}</Text>
      </Flex>
    );
  }

  return (
    <>
      {recordedChunks.length === 0 && (
        <CameraWrapper>
          <Webcam
            height={"80%"}
            width={"80%"}
            audio={false}
            ref={webcamRef}
            style={{
              visibility: recordedChunks.length === 0 ? "visible" : "hidden",
            }}
            videoConstraints={!isDesktop ? { height: 400, width: 300 } : {}}
          />
          <Timer>{formatTime(timer)}</Timer>
          <div style={{ marginTop: 8, width: "100%" }}>
            {capturing ? (
              <PrimaryButton
                onClick={handleStopCaptureClick}
                style={{ backgroundColor: "red", width: "100%" }}
                size="large"
              >
                {locale("chat.video.stop")}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={(e) => handleStartCaptureClick(e)}
                isActive
                size="large"
                style={{ width: "100%" }}
              >
                {locale("chat.video.start")}
              </PrimaryButton>
            )}
          </div>
        </CameraWrapper>
      )}
      {recordedChunks.length > 0 && (
        <div style={{ height: "65%", width: "65%" }}>
          <Timer>{formatTime(timer)}</Timer>
          <VideoCustom
            src={URL.createObjectURL(
              new Blob(recordedChunks, { type: "video/mp4" })
            )}
            title=""
          />
          <Flex gap="8px" justify="center" style={{ marginTop: 8 }}>
            <PrimaryButton
              onClick={() => {
                setRecordedChunks([]);
                setCapturing(false);
                setTimer(0);
              }}
              size="large"
              style={{ background: "white", color: "black", width: "100%" }}
            >
              {locale("chat.video.restart")}
            </PrimaryButton>
            <PrimaryButton
              onClick={handleUpload}
              size="large"
              isActive
              style={{ width: "100%" }}
            >
              {locale("chat.video.send")}
            </PrimaryButton>
          </Flex>
        </div>
      )}
    </>
  );
};

const Timer = styled.div`
  position: absolute;
  top: 75px;
  right: 20px;
  color: white;
  font-size: 20px;
  font-weight: 700;
`;

const Text = styled.div`
  color: ${colors.white};
  font-size: 16px;
`;

const CameraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80dvh;
`;
