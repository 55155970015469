import { post, get, put, del } from "./utils.service";

export const deleteAccount = async (userId, password) => {
  const res = await del(`/auth`, { userId: userId, password });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const updatePassword = async (oldPassword, newPassword) => {
  const res = await put(`/auth/password`, { oldPassword, newPassword });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const updateEmail = async (email, password, language) => {
  const res = await put(`/auth/email`, { email, password, language });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const updatePseudo = async (pseudo, password) => {
  const res = await put(`/auth/pseudo`, { pseudo, password });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const signin = async (pseudo: string, password: string) => {
  const res = await post(`/auth/signin`, {
    pseudo,
    password,
  });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const signinWithCookies = async (
  cookyid,
  cookynick,
  cookystyle,
  cookysort
) => {
  const res = await post(`/auth/signinWithCookies`, {
    cookyid,
    cookynick,
    cookystyle,
    cookysort,
  });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const signup = async (user: {
  email: string;
  pseudo: string;
  password: string;
  birthdate: Date;
  locationOptin: boolean;
  language: string;
  phone: string;
  phoneVerificationCode: string;
  captchaResponse?: string;
}) => {
  const res = await post(`/auth/signup`, user);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getRefreshToken = async () => {
  const res = await get(`/auth/refresh-token`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const isEmailFree = async (email) => {
  const res = await get(`/auth/email-free/${email}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const registerSignupAttempt = async (
  pseudo: string,
  email: string,
  castleRequestToken: string
) => {
  const res = await post(`/auth/register-signup-attempt`, {
    email,
    pseudo,
    castleRequestToken,
  });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const sendResetPasswordEmail = async (body: {
  email: string;
  language: string;
}) => {
  const res = await post("/auth/reset-password-email", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const resetPassword = async (body: {
  token: string;
  password: string;
}) => {
  const res = await post("/auth/reset-password", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const sendActivationEmail = async (body: { language: string }) => {
  const res = await post("/auth/activate-account-email", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const activateAccount = async (body: { token: string }) => {
  const res = await post("/auth/activate-account", body);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
