import React, { useState, useEffect } from "react";
import { preProcessFile } from "typescript";
import { validBucketUrl } from "../utils/regex.util";

type ImageProps = {
  alt: string;
  src: string;
  width: string;
  height?: string;
  style?: any;
  onClick?: () => void;
  optiWidth?: number;
};

const sizeKey = {
  100: "/resized-pictures/w_100",
  200: "/resized-pictures/w_200",
  300: "/resized-pictures/w_300",
  400: "/resized-pictures/w_400",
  500: "/resized-pictures/w_500",
  600: "/resized-pictures/w_600",
  700: "/resized-pictures/w_700",
  800: "/resized-pictures/w_800",
  900: "/resized-pictures/w_900",
  1000: "/resized-pictures/w_1000",
};

const getSrc = (src: string, width: string, optiWidth: number) => {
  const getNewSrc = (width) => {
    if (!src) return src;
    if (width <= 1000) {
      const key = Math.ceil(width / 100) * 100;
      const newFolder = sizeKey[key];
      return src.replace(
        /(amazonaws\.com|cloudfront\.net).*(\/.*)/g,
        "$1" + newFolder + "$2"
      );
    }
    return src;
  };
  if (optiWidth) {
    const source = getNewSrc(optiWidth);
    return source;
  } else if (width && validBucketUrl(src) && width.includes("px")) {
    const newWidth = Number(width.substring(0, width.length - 2));
    const source = getNewSrc(newWidth);
    return source;
  }
};

const Image = (props: ImageProps) => {
  const [useDefaultPicture, setUseDefaultPicture] = useState(false);

  // For cross origin isolation, we cannot set the appropriate headers on the S3 bucket
  // So using cloudfront instead
  const src = props.src?.replace(
    "bearwww-uploads-staging.s3.eu-west-3.amazonaws.com",
    "d2tiq3wo24jtl5.cloudfront.net"
  );

  const srcToUse = useDefaultPicture
    ? src
    : getSrc(src, props.width, props.optiWidth) || src;

  return (
    <img
      alt={props.alt}
      src={srcToUse || src}
      width={props.width}
      height={props.height}
      style={props.style}
      onClick={props.onClick}
      onError={(e: any) => {
        if (srcToUse !== props.src) {
          setUseDefaultPicture(true);
        }
      }}
      loading="lazy"
    />
  );
};

export default Image;
