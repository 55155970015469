import React from "react";
import styled, { keyframes } from "styled-components";

export default function TypingLoader() {
  return (
    <LoaderBadge>
      <PulsePill index={0} />
      <PulsePill index={1} />
      <PulsePill index={2} />
    </LoaderBadge>
  );
}

const Pulse = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #090c11;
  }
  100% {
    background-color: transparent;
  }
`;

const LoaderBadge = styled.div`
  width: min-content;
  display: flex;
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(73, 78, 84, 0.6);
`;

const PulsePill = styled.div<{ index: number }>`
  margin: 0 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: 2s ${Pulse} ease infinite;
  animation-delay: ${(p) => p.index * 0.1}s;
`;
