import { css } from "styled-components";

import colors from "../../themes/colors.theme";

export const FancyScrollbar = css`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${colors.transparent};
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${colors.transparent};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
`;
