import { createSlice } from '@reduxjs/toolkit';
import { FiltersType } from '../../pages/nearby/nearby.screenv2';

const initialState: FiltersType = {
  status: ['online'],
};

const UserFiltersSlice = createSlice({
  name: 'userfilters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFiltersWithReset: (state, action) => {
      return {
        ...action.payload,
      };
    },
  },
});

export default UserFiltersSlice;
