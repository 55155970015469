import React from 'react';
import styled from 'styled-components/macro';
import Button from '../../../../components/forms-v2/button.component';
import Tag from '../../../../components/style/tag.component';
import { getCommunities } from '../../../../services/caracteristics.service';
import { updateUser } from '../../../../services/users.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { Subtitle } from '../../../../components/settings/subtitle.component';
import { Values } from '../../../../components/settings/values.component';
import { Tags } from '../../../../components/settings/tags.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';

type LookingProps = {
  me?: any;
  onChange?: (communitiesLike) => void;
};

const LookingFor = (props: LookingProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [editOpen, setEditOpen] = React.useState(null);
  const [communities, setCommunities] = React.useState([]);
  const [communitiesLike, setCommunitiesLike] = React.useState(props?.me?.communitiesLike);
  const locale = useLocale();

  const getData = async () => {
    const res = await getCommunities();
    setCommunities(res);
  };

  const onSelect = async (selectedCommunity) => {
    if (communitiesLike) {
      const index = communitiesLike?.findIndex(
        (community) => community.communityKey === selectedCommunity.communityKey
      );
      let communitiesLikeCopy = [...communitiesLike];
      if (index === -1) {
        communitiesLikeCopy.push(selectedCommunity);
      } else {
        communitiesLikeCopy.splice(index, 1);
      }
      setCommunitiesLike(communitiesLikeCopy);
      await updateUser({
        communitiesLike: communitiesLikeCopy,
      });
      props?.onChange(communitiesLike);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Title>{locale('edit_profil.looking_for.title')}</Title>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tags>
          {communities?.length > 0 &&
            communities.map((community, idx) => (
              <PrimaryButton
                key={idx}
                onClick={() => onSelect(community)}
                isActive={
                  communitiesLike?.findIndex(
                    (communityInterest) => communityInterest.communityKey === community.communityKey
                  ) !== -1
                }
              >
                {locale('community.name.' + community.communityKey)}
              </PrimaryButton>
            ))}
        </Tags>
      </Values>
    </>
  );
};

export default LookingFor;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
`;
