import * as queryString from 'query-string';
import { get } from './utils.service';
import { Category } from '../pages/discover/discover.screen';

export const getForYouCategory = async (category: Category, query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/users/discover/${category}?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
