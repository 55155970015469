import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@material-ui/core";

import Icons from "../../../components/style/icons.component";
import Text from "../../../components/style/text.component";
import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import Button from "../../../components/forms/button.component";
import PicturesDisplay from "./pictures-display.component";

import useLocale from "../../../utils/locale/locale.hook";
import ModalSlice from "../../../store/slices/modal.slice";
import { createPost } from "../../../services/posts.service";
import { useLogin } from "../../../utils/auth.utils";
import { uploadFile } from "../../../utils/s3.utils";

import fonts from "../../../themes/fonts.theme";
import ComingSoonTooltip from "../../../components/miscellaneous/ComingSoonTooltip";
import { Link } from "react-router-dom";

enum SubmitStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
}

type CreatePostModalProps = {
  visible?: boolean;
  style?: any;
  openPhoto?: any;
};

export default function CreatePostModal(props: CreatePostModalProps) {
  const { visible } = props;
  const locale = useLocale();
  const { isLogin, me } = useLogin();

  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const closeModalHandler = () => {
    dispatch(
      ModalSlice.actions.setCreatePostVisible({
        visible: false,
        photo: false,
      })
    );
  };

  const addPicture = () => {
    fileInput.current.click();
  };

  const submit = async () => {
    if (submitStatus === SubmitStatus.LOADING) {
      return;
    }

    setSubmitStatus(SubmitStatus.LOADING);
    const filesFormated = [];
    for (let file of files) {
      const { path, error } = await uploadFile(file);
      // DEBUG: error not handled
      if (error) return;

      filesFormated.push({
        path,
      });
    }
    const res = await createPost({
      text,
      pictures: filesFormated,
    });
    setSubmitStatus(SubmitStatus.IDLE);
    if (res?.success) {
      setText("");
      setFiles([]);
      dispatch(
        ModalSlice.actions.setCreatePostVisible({
          visible: false,
          photo: false,
        })
      );
    }
  };

  const onChange = (e: any) => {
    const files = e.target.files;
    setFiles((_files) => _files.concat(Array.from(files)));
    if (fileInput.current && props.openPhoto) addPicture();
  };

  const [textAreaPlaceholder, setTextAreaPlaceholder] = useState(
    locale("feed.create_post.placeholder", {
      username: me?.pseudo,
    })
  );

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModalHandler}
      canCloseModal
      canCloseModalUsingOverlay={false}
      width="500px"
      style={{
        maxHeight: "calc(100vh - 150px)",
        borderRadius: "4px",
        border: "solid 1px #343536",
      }}
      zIndex={300}
      closeButtonSize={24}
    >
      <input
        ref={fileInput}
        style={{ display: "none" }}
        type="file"
        id="myFile"
        name="filename"
        onChange={onChange}
        multiple
        accept="image/*"
      />
      <Header>
        <Text>{locale("feed.create_post.title")}</Text>
      </Header>
      <Content>
        <ContentText>
          <TextAreaContainer>
            <Textarea
              placeholder={textAreaPlaceholder}
              maxLength={200}
              rows={3}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              onFocus={() => setTextAreaPlaceholder("")}
              onBlur={() =>
                setTextAreaPlaceholder(
                  locale("feed.create_post.placeholder", {
                    username: me?.pseudo,
                  })
                )
              }
            />
          </TextAreaContainer>
          <NumberOfCharacters>
            {locale("feed.create_post.characters_left") + (200 - text.length)}
          </NumberOfCharacters>
        </ContentText>
        {files.length > 0 && (
          <PicturesDisplay
            create
            style={{
              marginLeft: 43,
              marginTop: 10,
              marginBottom: 10,
            }}
            canUpdate
            deletePicture={() => {
              const filesCopy = [...files];
              filesCopy.splice(0, 1);
              setFiles(filesCopy);
            }}
            pictures={
              files.length > 0 &&
              files.map((file) => ({ path: URL.createObjectURL(file) }))
            }
          />
        )}
      </Content>
      <Footer>
        <Addons>
          <Text
            style={{
              fontSize: "14px",
              color: "#d8dadc",
            }}
          >
            {locale("feed.create_post.add_to_post")}
          </Text>
          <IconList>
            <Link to="/live/start">
              <IconCircle>
                <Icons
                  name="icons-header-video-color"
                  width="16px"
                  height="16px"
                />
                <ComingSoonTooltip
                  phraseKey={"feed.create_post.launch_live"}
                  style={{
                    visibility: "hidden",
                    left: "-30px",
                    right: "unset",
                    top: "-35px",
                    bottom: "unset",
                  }}
                />
              </IconCircle>
            </Link>
            <IconCircle onClick={() => addPicture()}>
              <Icons
                name="icons-menu-picture-color"
                width="16px"
                height="16px"
              />
              <ComingSoonTooltip
                phraseKey={"feed.create_post.send_pictures"}
                style={{
                  visibility: "hidden",
                  left: "-15px",
                  right: "unset",
                  top: "-35px",
                  bottom: "unset",
                }}
              />
            </IconCircle>
            <IconCircle isComingSoon>
              <Icons name="icons-menu-video-color" width="16px" height="16px" />
              <ComingSoonTooltip
                phraseKey={"global.coming_soon.videos"}
                style={{ visibility: "hidden", bottom: "-35px" }}
              />
            </IconCircle>
            <IconCircle isComingSoon>
              <Icons
                name="icons-actions-gif-filled"
                width="16px"
                height="16px"
              />
              <ComingSoonTooltip
                phraseKey={"global.coming_soon.gif"}
                style={{ visibility: "hidden", bottom: "-35px" }}
              />
            </IconCircle>
            <IconCircle isComingSoon>
              <Icons
                name="icons-actions-smiley-filled"
                width="16px"
                height="16px"
              />
              <ComingSoonTooltip
                phraseKey={"global.coming_soon.smiley"}
                style={{ visibility: "hidden", bottom: "-35px" }}
              />
            </IconCircle>
          </IconList>
        </Addons>
        <Button
          disabled={!isLogin || (!text.trim() && files.length === 0)}
          onClick={submit}
          text={locale("feed.create_post.submit")}
          loading={submitStatus === SubmitStatus.LOADING}
          style={{
            border: "none",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "SegoeUI",
          }}
        />
      </Footer>
    </ModalTemplate>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-bottom: solid 1px #343536;

  > p {
    font-size: 14px;
    font-weight: 600;
    color: #d8dadc;
  }
`;

const Content = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextAreaContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
`;

const Textarea = styled(TextareaAutosize)`
  max-height: calc(100vh - 450px - 100px);
  min-height: 55px;
  padding: 16px;
  background-color: #1a1a1b;

  border: none;
  /* width: calc(100% - 14px - 17px); */
  width: 100%;
  box-sizing: border-box;

  resize: none;

  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  color: white;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: #cfd3da;
  }
`;

const NumberOfCharacters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 12px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
`;

const Addons = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #343536;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 8px 12px;
`;

const IconList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconCircle = styled.div<{ isComingSoon?: boolean }>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 4px;

  &:hover {
    background-color: #272729;

    filter: grayscale(${(props) => (props.isComingSoon ? 1 : 0)});
    transition: filter 0.4s;

    > div {
      visibility: visible !important;
    }
  }
`;
