import { useRef, useState, useEffect } from 'react';
import { StyledInput } from '../../../../components/forms/input.component';
import { updateUser } from '../../../../services/users.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { useDebounce } from 'use-debounce';

type Props = {
  me?: any;
  onChange?: () => void;
};

const Country = (props: Props) => {
  const locale = useLocale();
  const inputRef = useRef();

  const [country, setCountry] = useState(props?.me?.country);
  const [debouncedValue] = useDebounce(country, 500);

  async function updateCountry() {
    await updateUser({ country: debouncedValue });
    props?.onChange?.();
  }

  useEffect(() => {
    if (debouncedValue !== props.me?.country) {
      updateCountry();
    }
  }, [debouncedValue]);

  return (
    <>
      <StyledInput
        ref={inputRef}
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        placeholder={locale('profil_edit.no_country')}
      />
    </>
  );
};
export default Country;
