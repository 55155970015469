import styled from "styled-components/macro";

import ModalTemplate from "../../components/miscellaneous/modal-template.component";
import UserProfile from "./user-profile.component";

import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SelectPicturesModal from "../../components/miscellaneous/modals/select-picture.modal";
import SelectUserPicturesModal from "../../components/photos-management/modals/select-user-pictures.modal";
import { closeProfile, closeProfileGoBackHistory } from "./profile.utils";

type UserProfileModalProps = {
  visible: boolean;
};

const UserProfileModal = (props: UserProfileModalProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const modalStore = useSelector((state: any) => state.modalStore);
  const onClose = () => {
    closeProfile();
  };

  const onCloseAndGoBack = () => {
    closeProfileGoBackHistory();
  };

  return (
    <ModalTemplate
      fullsize={isMobile}
      visible={props.visible}
      closeModal={onClose}
      width="1000px"
      style={{ top: isMobile ? "0" : "10%", borderRadius: isMobile ? 0 : 16 }}
      zIndex={1010}
      closeOutside={isMobile}
      position={
        !isMobile && {
          top: "60%",
          left: "50%",
        }
      }
    >
      <ModalContent>
        {modalStore.selectPictures && (
          <SelectPicturesModal visible={modalStore.selectPictures.visible} />
        )}
        {modalStore.selectUserPictures.visible && (
          <SelectUserPicturesModal
            visible={modalStore.selectUserPictures.visible}
          />
        )}
        <UserProfile
          visible={props.visible}
          closeModal={onClose}
          onCloseAndGoBack={onCloseAndGoBack}
        />
      </ModalContent>
    </ModalTemplate>
  );
};

export default UserProfileModal;

const ModalContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  height: 70vh;
  padding-bottom: env(safe-area-inset-bottom);

  @media (max-width: 1000px) {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100vh;
    min-height: -moz-available;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
`;
