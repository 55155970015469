import styled from 'styled-components/macro';

import useLocale from '../../../../../utils/locale/locale.hook';
import { useLogin } from '../../../../../utils/auth.utils';

import fonts from '../../../../../themes/fonts.theme';
import colors from '../../../../../themes/colors-v2.theme';
import { woofs } from '../../../../../constants/constants';

interface TextMessageProps {
  message: any;
  conversation: any;
  sender: 'other' | 'me';
}

export default function WoofMessage({ message, sender }: TextMessageProps) {
  const locale = useLocale();
  const { me } = useLogin();

  return message.isAnonymous ? (
    <Message sender={sender}>
      <img
        src={woofs[message.isAnonymous ? 'anonymous' : message.woof.category].img}
        alt="Woof icon"
        width={64}
        height={64}
        loading="lazy"
      />
      <Label sender={sender}>{locale(woofs[message.isAnonymous ? 'anonymous' : message.woof.category].label)}</Label>
    </Message>
  ) : (
    <Message sender={sender}>
      <img src={woofs[message.woof.category].img} alt="Woof icon" width={64} height={64} loading="lazy" />
      <Label sender={sender}>{locale(woofs[message.woof.category].label)}</Label>
    </Message>
  );
}

const Message = styled.div<{ sender: 'me' | 'other' }>`
  padding: 12px;
  border-radius: 4px;

  font-size: 14px;
  color: ${colors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > p {
    margin: 0;
  }
`;

const Label = styled.div<{ sender: 'me' | 'other' }>`
  color: ${colors.white};
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
`;
