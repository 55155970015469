import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";

import Text from "../../../components/style/text.component";
import { addACity, City } from "../../../services/city.service";
import { addATravel } from "../../../services/travel.service";

import { useOnClickOutside } from "../../../utils/clickOutside.utils";
import { getCityAndCountry } from "../../../utils/google-maps.utils";

import { useNotifContext, ADD } from "../../../context/notif-context";
import useLocale from "../../../utils/locale/locale.hook";
import fonts from "../../../themes/fonts.theme";
import colors from "../../../themes/colors-v2.theme";

interface TripFormProps {
  onClose: (boolean) => void;
  coords: any;
  refresh: () => void;
}

const TripForm = (props: TripFormProps) => {
  const locale = useLocale();

  const [isValid, setIsValid] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cityData, setCityData] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [loading, setLoading] = useState(true);

  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const statusSelectorRef = useRef(null);
  const { notifDispatch } = useNotifContext();

  useOnClickOutside([statusSelectorRef], () => {
    props.onClose(false);
  });

  const onChangeStartDate = (date: string) => {
    if (date !== null) {
      setStartDate(date);
    }
  };

  const onChangeEndDate = (date: string) => {
    if (date !== null) {
      setEndDate(date);
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setIsValid(Date.parse(startDate) <= Date.parse(endDate));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const getData = async () => {
      const { city, country, cityName, countryName } = await getCityAndCountry(
        props.coords.lat,
        props.coords.lng
      );
      if (!city || !country) return;

      if (!city || !country) {
        return;
      } else {
        setCityData(city);
        setCountryData(country);
        setCityName(cityName);
        setCountryName(countryName);

        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleSubmit = async () => {
    const cityToSend = {
      cityName,
      cityPlaceId: cityData.place_id,
      countryName,
      countryPlaceId: countryData.place_id,
      latitude: cityData.geometry.location.lat().toString(),
      longitude: cityData.geometry.location.lng().toString(),
    };

    const res: any = await addACity(cityToSend);
    if (res.error) {
      // DEBUG: handle the error here
    }
    const newCity: City = res;

    const travelToSend = {
      cityUid: newCity.uid,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    };

    const responseTravel = await addATravel(travelToSend);

    props.refresh();

    notifDispatch({
      type: ADD,
      payload: {
        content: locale("travel.added"),
      },
    });

    props.onClose(false);
  };

  const handleOpenStartDate = () => {
    setStartDateOpen(true);
    document.getElementById("startDate").focus();
  };

  const handleOpenEndDate = () => {
    setEndDateOpen(true);
    document.getElementById("endDate").focus();
  };

  if (loading) return <></>;

  return (
    <Container ref={statusSelectorRef}>
      <Row>
        <img
          style={{ cursor: "pointer" }}
          alt=""
          width="22px"
          height="24px"
          src="/assets/icons/explore/icons-actions-arrow-left.svg"
          onClick={() => props.onClose(false)}
        />
        <Text color={colors.darkerGrey}>{locale("travel.selectDates")}</Text>
        <div />
      </Row>
      <form>
        <div style={{ width: "100%", marginBottom: "8px" }}>
          <img
            style={{
              position: "absolute",
              marginTop: "7px",
              marginLeft: "10px",
            }}
            alt=""
            width="16px"
            height="16px"
            src="/assets/icons/explore/localisation.svg"
          />
          <TextInput
            type="text"
            onChange={(e) => {
              return;
            }}
            value={cityName + ", " + countryName}
          />
        </div>
        <Row>
          <div
            style={{ width: "50%", margin: "0 3px" }}
            onClick={handleOpenStartDate}
          >
            {!startDateOpen && (
              <FakeDateInput startDate>
                {locale("travel.arrival")}
              </FakeDateInput>
            )}
            <DateInput
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => onChangeStartDate(e.target.value)}
            ></DateInput>
          </div>
          <div
            style={{ width: "50%", margin: "0 3px" }}
            onClick={handleOpenEndDate}
          >
            {!endDateOpen && (
              <FakeDateInput>{locale("travel.departure")}</FakeDateInput>
            )}
            <DateInput
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => onChangeEndDate(e.target.value)}
            ></DateInput>
          </div>
        </Row>
        <div style={{ width: "100%" }}>
          <Button
            disabled={!isValid}
            onClick={isValid ? handleSubmit : undefined}
          >
            {locale("global.create")}
          </Button>
        </div>
      </form>
    </Container>
  );
};

const Container = styled.div`
  width: calc(75% - 22px);
  padding: 16px 11px;
  position: absolute;
  bottom: 2%;
  z-index: 21;
  background-color: ${colors.backgroundLight};
  border: solid 1px #343536;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled.div<{ disabled: boolean }>`
  width: calc(100% - 8px);
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  font-family: "Segoe-Regular";
  ${(props) =>
    props.disabled
      ? `
    background-color: #4e4f50;
    color: #aaaaaa;
  `
      : `
    cursor: pointer;
    background-color: ${colors.accent};
    color: #ffffff;
  `}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const FakeDateInput = styled.div<{ startDate?: boolean }>`
  width: calc(48.5% - 50px);
  height: 34px;
  position: absolute;
  padding-left: 39px;
  background-color: ${colors.backgroundLight};
  border: 1px solid ${colors.darkerGrey};
  border-radius: 20px;
  color: ${colors.darkerGrey};
  font-family: "Segoe-Regular";
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DateInput = styled.input`
  width: 90%;
  height: 32px;
  background-color: ${colors.backgroundLight};
  border: 1px solid ${colors.darkerGrey};
  border-radius: 20px;
  color: ${colors.darkerGrey};
  font-family: "Segoe-Regular";
  padding-left: 15px;
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    margin-inline-start: 8px;
    background-image: url("/assets/icons/explore/icons-menu-trip-calendar.svg");
    cursor: pointer;
  }
  ::-webkit-datetime-edit {
    margin-inline-start: 40px;
    padding: 10px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  height: 32px;
  border: 1px solid ${colors.darkerGrey};
  outline: none;
  border-radius: 20px;
  padding: 6px 30px;
  box-sizing: border-box;
  background-color: ${colors.backgroundLight};
  color: ${colors.darkerGrey};
  font-size: 12px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};

  &:hover {
    background-color: ${colors.lightGrey4};
  }

  &:focus {
    color: ${colors.darkerGrey};
  }
`;

export default TripForm;
