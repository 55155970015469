import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import styled from "styled-components/macro";

import { Loader } from "../../style/loader.component";
import Text from "../../style/text.component";

import ModalSlice from "../../../store/slices/modal.slice";
import { useLogin } from "../../../utils/auth.utils";
import { getFileFromDataURL } from "../../../utils/file.utils";
import useLocale from "../../../utils/locale/locale.hook";
import { uploadFile } from "../../../utils/s3.utils";

import { useMediaQuery } from "react-responsive";
import { UserModalTemplate } from "../../../pages/user-profile/components/user-modal.component";
import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import { PrimaryButton } from "../../forms/primaryButton.component";
import { Flex } from "../../style/flex.component";

enum ModalMode {
  OPTIONS = "OPTIONS", // on affiche les 2 options possibles
  CAMERA = "CAMERA", // on affiche la caméra
  LOADING = "LOADING", // on affiche un loader (pendant l'upload des photos)
}

interface ModalProps {
  visible: boolean;
}

export default function SelectPictureModal({ visible }: ModalProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { selectPictures } = useSelector((state: any) => state.modalStore);
  const { isLogin } = useLogin();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const [mode, setMode] = useState(ModalMode.OPTIONS);
  const webcamRef = useRef<Webcam>(null);

  const closeModalHandler = () => {
    dispatch(
      ModalSlice.actions.setSelectPictures({
        visible: false,
        type: null,
        onSelectedPictures: null,
        onSelectedFiles: null,
      })
    );
  };

  const selectPicturesHandler = async (files: File[]) => {
    if (files.length > 0) {
      if (selectPictures.type === "file") {
        selectPictures.onSelectedFiles(files);
        closeModalHandler();
      } else if (selectPictures.type === "upload") {
        const uploadedPictures: Array<{ path: string; isVideo?: boolean }> = [];
        setMode(ModalMode.LOADING);

        const promises = files.map(async (file) => {
          const isVideo = file.type === "video/mp4";
          const { path, error } = await uploadFile(file);
          if (error) {
            alert(locale(error));
          } else {
            uploadedPictures.push({ path, isVideo });
          }
        });
        await Promise.all(promises);
        selectPictures.onSelectedPictures(uploadedPictures);
        closeModalHandler();
      }
    }
  };

  const takeCameraScreenshot = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = getFileFromDataURL(imageSrc, "screenshot.jpeg");
    selectPicturesHandler([file]);
  };

  useEffect(() => {
    if (!visible) {
      setMode(ModalMode.OPTIONS);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (
        selectPictures.type === "file" &&
        selectPictures.onSelectedFiles === null
      ) {
        closeModalHandler();
      }
      if (
        selectPictures.type === "upload" &&
        selectPictures.onSelectedPictures === null
      ) {
        closeModalHandler();
      }
    }
  }, [visible, selectPictures]);

  const getTransform = () => {
    if (selectPictures.type === "file") {
      return "translate(-50%, -50%)";
    } else if (!isDesktop) {
      return "translate(-50%, -50%)";
    } else {
      return "translate(0%, -50%)";
    }
  };

  const [facingMode, setFacingMode] = useState<
    "user" | { exact: "environment" }
  >("user");

  const handleSwapFacingMode = () => {
    setFacingMode((prev) =>
      prev === "user" ? { exact: "environment" } : "user"
    );
  };

  const availableHeight = window.innerHeight;

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModalHandler}
      style={{
        width: mode === ModalMode.CAMERA && !isDesktop ? "95%" : "fit-content",
        minWidth: 300,
        height: "fit-content",
        background: colors.darkGray,
        border: `1px solid ${colors.lightGrey10}`,
        top: "50%",
        left: "50%",
        padding: 12,
        paddingBottom: 16,
        zIndex: 1011,
        boxSizing: "border-box",

        // Workaround for placement because the modal is used in both the signup and message pages
        transform: getTransform(),
      }}
    >
      <ModalHeader>
        <div
          style={{
            border: `1px solid ${colors.grayBorder}`,
            borderRadius: 100,
            width: 56,
            height: 56,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/assets/icons/messages/preview/picture-inactive.svg"
            alt="image icon"
            width={24}
            height={24}
          />
        </div>
        <Text color="white" bold fontSize="16px">
          {locale("modal.select_picture.header_text")}
        </Text>
      </ModalHeader>

      {mode == ModalMode.CAMERA && (
        <CameraWrapper>
          <Webcam
            width={isDesktop ? "100%" : "160%"}
            height={availableHeight * 0.5}
            style={{ marginBottom: 16 }}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={
              isDesktop
                ? { height: 1600, width: 900 }
                : {
                    facingMode,
                  }
            }
          />
          <Flex direction="row" gap="12px">
            <PrimaryButton size="large" isActive onClick={takeCameraScreenshot}>
              {locale("modal.select_picture.button_label")}
            </PrimaryButton>
            {!isDesktop && (
              <PrimaryButton
                size="large"
                isActive
                onClick={handleSwapFacingMode}
              >
                {locale("modal.select_picture.change_side")}
              </PrimaryButton>
            )}
          </Flex>
        </CameraWrapper>
      )}
      <ModalContent>
        {mode === ModalMode.OPTIONS && (
          <Flex
            direction={isDesktop ? "row" : "column"}
            justify="center"
            alignItems="center"
            gap="16px"
          >
            <label style={{ width: "100%" }}>
              <Option onClick={() => setMode(ModalMode.CAMERA)}>
                <img
                  alt="icon action"
                  src="/assets/icons/messages/icons-actions-video-white.svg"
                  width={24}
                  height={24}
                />
                <Text
                  color="white"
                  fontSize="12px"
                  bold
                  style={{ textAlign: "center" }}
                >
                  {locale("modal.select_picture.camera_option_label")}
                </Text>
              </Option>
            </label>
            {isLogin && (
              <label style={{ width: "100%" }}>
                <Option
                  onClick={() => {
                    closeModalHandler();
                    dispatch(
                      ModalSlice.actions.setSelectUserPictures({
                        visible: true,
                        onPicturesConfirm: selectPictures.onSelectedAlbum,
                      })
                    );
                  }}
                >
                  <img
                    alt="icon action"
                    src="/assets/icons/messages/preview/picture-inactive.svg"
                  />
                  <Text
                    color="white"
                    fontSize="12px"
                    bold
                    style={{ textAlign: "center" }}
                  >
                    {locale("modal.select_picture.album_option_label")}
                  </Text>
                </Option>
              </label>
            )}
            <label style={{ width: "100%" }}>
              <input
                type="file"
                onChange={(e) => {
                  const files = [...e.target?.files];
                  if (files.length === 0) {
                    return;
                  }
                  const maxSize = 50 * 1024 * 1024; // 50MB
                  for (let i = 0; i < files.length; i++) {
                    if (files[i].size > maxSize) {
                      //eslint-disable-next-line
                      console.error("File too big");
                      return;
                    }
                  }
                  selectPicturesHandler(files);
                  e.target.value = "";
                }}
                accept="image/*, .heic"
                style={{ display: "none" }}
              />
              <Option>
                <img
                  alt="icon action"
                  src="/assets/icons/messages/icons-actions-plus-white.svg"
                />
                <Text
                  color="white"
                  fontSize="12px"
                  bold
                  style={{ textAlign: "center" }}
                >
                  {locale("modal.select_picture.computer_option_label")}
                </Text>
              </Option>
            </label>
          </Flex>
        )}
        {mode === ModalMode.LOADING && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Loader size={50} color={colors.white} />
            <Text color="white" fontSize="14px" bold>
              {locale("global.loading")}
            </Text>
          </div>
        )}
      </ModalContent>
    </UserModalTemplate>
  );
}

const ModalContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: ${colors.darkGrey};
  border-radius: 8px;

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${colors.darkerGrey};
  margin-bottom: 16px;
`;

const Option = styled.div`
  width: 176px;
  height: 110px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 8px;
  background: ${colors.darkBlue};

  font-size: 12px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  color: ${colors.darkGrey};

  & > img {
    margin-bottom: 12px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const CameraWrapper = styled.div`
  height: "fit-content";
  width: "fit-content";
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80dvh;
  overflow: hidden;
`;
