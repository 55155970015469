import React from "react";
import styled from "styled-components/macro";
import Icons from "../../../components/style/icons.component";
import Text from "../../../components/style/text.component";
import { useDispatch } from "react-redux";
import ModalSlice from "../../../store/slices/modal.slice";
import PictureModeration from "../../../components/picture-moderation.component";

type PicturesDisplayProps = {
  pictures: any[];
  deletePicture?: (index) => void;
  canUpdate?: boolean;
  style?: any;
  create?: boolean;
};

export default function PicturesDisplay(props: PicturesDisplayProps) {
  const dispatch = useDispatch();

  const openFullSizeImages = (start: any) => {
    dispatch(
      ModalSlice.actions.setFullSizeImageVisibleVisible({
        visible: true,
        images: props.pictures,
        start,
        timeLeft: 60,
        type: "pictures",
      })
    );
  };
  return (
    <Images style={props?.style} create={props.create}>
      <MainImage fullwidth={props.pictures.length <= 1} create={props.create}>
        {props?.canUpdate && (
          <Icons
            onClick={() => {
              props.deletePicture(0);
            }}
            name="cross-filled-grey"
            width="25"
            height="25"
            style={{
              top: 15,
              right: 15,
              cursor: "pointer",
              position: "absolute",
            }}
          />
        )}
        <PictureModeration
          alt=""
          moderation={props.pictures[0].moderation}
          onClick={() => openFullSizeImages(0)}
          src={props.pictures[0].path}
          height="100%"
          width="100%"
          style={{
            cursor: "pointer",
            objectFit: "cover",
            borderRadius: 4,
            flex: 1,
          }}
          optiWidth={550}
        />
      </MainImage>
      {props.pictures.length === 2 && (
        <SecondImage create={props.create}>
          {props?.canUpdate && (
            <Icons
              onClick={() => {
                props.deletePicture(1);
              }}
              name="cross-filled-grey"
              width="25"
              height="25"
              style={{
                top: 15,
                right: 15,
                cursor: "pointer",
                position: "absolute",
              }}
            />
          )}
          <PictureModeration
            alt=""
            onClick={() => openFullSizeImages(1)}
            moderation={props.pictures[1].moderation}
            src={props.pictures[1].path}
            height="100%"
            width="100%"
            style={{
              cursor: "pointer",
              objectFit: "cover",
              borderRadius: 4,
            }}
            optiWidth={550}
          />
        </SecondImage>
      )}
      {props.pictures.length > 2 && (
        <MultipleImages create={props.create}>
          <MultipleImage style={{ marginBottom: 8 }}>
            {props?.canUpdate && (
              <Icons
                onClick={() => {
                  props.deletePicture(1);
                }}
                name="cross-filled-grey"
                width="25"
                height="25"
                style={{
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                  position: "absolute",
                }}
              />
            )}
            <PictureModeration
              alt=""
              onClick={() => openFullSizeImages(1)}
              moderation={props.pictures[1].moderation}
              src={props.pictures[1].path}
              height="100%"
              width="100%"
              style={{
                cursor: "pointer",
                objectFit: "cover",
                borderRadius: 4,
              }}
              optiWidth={550}
            />
          </MultipleImage>
          <MultipleImage>
            {props.pictures.length > 3 && (
              <More onClick={() => openFullSizeImages(2)}>
                <Text h3>+ {props.pictures.length - 3}</Text>
              </More>
            )}
            {props?.canUpdate && (
              <Icons
                onClick={() => {
                  props.deletePicture(2);
                }}
                name="cross-filled-grey"
                width="25"
                height="25"
                style={{
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                  position: "absolute",
                }}
              />
            )}
            <PictureModeration
              alt=""
              onClick={() => openFullSizeImages(2)}
              moderation={props.pictures[2].moderation}
              src={props.pictures[2].path}
              height="100%"
              width="100%"
              style={{
                cursor: "pointer",
                objectFit: "cover",
                borderRadius: 4,
              }}
              optiWidth={550}
            />
          </MultipleImage>
        </MultipleImages>
      )}
    </Images>
  );
}

const Images = styled.div<{ create: boolean }>`
  max-height: ${(props) => (props.create ? "250px" : "450px")};
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const MainImage = styled.div<{ fullwidth: boolean; create: boolean }>`
  max-height: ${(props) => (props.create ? "250px" : "450px")};
  width: ${(props) => (props.fullwidth ? "100%" : "50%")};
  ${(props) => !props.fullwidth && "margin-right: 12px;"}
  position: relative;
  display: flex;
`;

const SecondImage = styled.div<{ create: boolean }>`
  max-height: ${(props) => (props.create ? "250px" : "450px")};
  width: 50%;
  position: relative;
`;

const MultipleImages = styled.div<{ create: boolean }>`
  max-height: ${(props) => (props.create ? "250px" : "450px")};
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const MultipleImage = styled.div`
  height: calc(50% - 4px);
  position: relative;
`;

const More = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
