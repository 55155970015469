import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './slices/auth.slice';
import LocaleSlice from './slices/locale.slice';
import MeSlice from './slices/me.slice';
import ModalSlice from './slices/modal.slice';
import NotifSlice from './slices/notification.slice';
import WebsocketSlice from './slices/websocket.slice';
import ChatSlice from './slices/chat.slice';
import NavbarSlice from './slices/navbar.slice';
import ViewsSlice from './slices/views.slice';
import FavoriteSlice from './slices/favorite.slice';
import ClassificationSlice from './slices/classification.slice';
import NearbySlice from './slices/nearby.slice';
import UserFiltersSlice from './slices/userfilter.slice';

export const store = configureStore({
  reducer: {
    localeStore: LocaleSlice.reducer,
    meStore: MeSlice.reducer,
    modalStore: ModalSlice.reducer,
    authStore: AuthSlice.reducer,
    websocketStore: WebsocketSlice.reducer,
    notifStore: NotifSlice.reducer,
    chatStore: ChatSlice.reducer,
    navbarStore: NavbarSlice.reducer,
    viewsStore: ViewsSlice.reducer,
    favoriteStore: FavoriteSlice.reducer,
    classificationStore: ClassificationSlice.reducer,
    nearbyStore: NearbySlice.reducer,
    userFiltersStore: UserFiltersSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
