import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Text from "../../components/style/text.component";
import { useDispatch } from "react-redux";
import ModalSlice from "../../store/slices/modal.slice";
import Tutorial from "./components/tutorial.component";
import RatingBar from "./components/rating-bar.component";
import { get, post } from "../../services/utils.service";
import { Loader } from "../../components/style/loader.component";
import useLocale from "../../utils/locale/locale.hook";

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100vw - 20px);
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  :after {
    content: "";
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
  }
`;

const Modal = styled.div`
  width: 75%;
  height: 90%;
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

const ModalContent = styled.div`
  /*background-color: linear-gradient(180deg, #000000 0%, #333333 100%);*/
  border-radius: 10px;
  width: 90%;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0c0f16, #07090c);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChevronWrapper = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ModerationHeader = styled.div`
  color: white;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
`;

const SkipButton = styled.div`
  padding: 10px 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ModerationModalProps = {
  visible: boolean;
};

const ModerationModal = (props: ModerationModalProps) => {
  const [isDemoVisible, setDemoVisible] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [pictureIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const locale = useLocale();
  const dispatch = useDispatch();

  const exitTutorial = () => {
    setStep(1);
    setDemoVisible(false);
  };

  const fetchPictures = async () => {
    let res = await get("/pictures/moderation");
    if (res?.status === 200) {
      const { data } = await res.json();
      if (data.pictures) {
        let newPictures = [...data.pictures];
        setPictures(newPictures);
        setLoading(false);
      }
    } else {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPictures();
  }, []);

  const ratePicture = async (moderation) => {
    await post("/pictures/moderate", {
      moderation: moderation,
      picture: pictures[0],
    });
    let newPictures = pictures.filter((el, index) => index > 0);
    setPictures(newPictures);

    if (pictures.length === 1) {
      setLoading(true);
      fetchPictures();
    }
  };

  if (props.visible)
    return (
      <Container>
        <Modal>
          <ChevronWrapper style={{ justifyContent: "flex-start" }}>
            {isDemoVisible && step > 1 && (
              <img
                alt=""
                src="/assets/icons/moderation/chevron-left.png"
                onClick={() => setStep(Math.max(1, step - 1))}
                style={{ cursor: "pointer" }}
              />
            )}
          </ChevronWrapper>
          {isDemoVisible ? (
            <Tutorial
              step={step}
              exitTutorial={() => exitTutorial()}
              closeTutorial={() =>
                dispatch(ModalSlice.actions.setModerationVisible(false))
              }
            />
          ) : (
            <ModalContent>
              <ModerationHeader>
                <Text
                  style={{
                    marginBottom: 11,
                    fontWeight: 600,
                  }}
                >
                  {locale("rating.picture")}
                </Text>
                <Text
                  style={{
                    marginBottom: 11,
                    fontWeight: 600,
                  }}
                >
                  {pictures.length} {locale("rating.picture.left")}
                </Text>
                <Text
                  style={{
                    marginBottom: 11,
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={() => setDemoVisible(true)}
                >
                  {locale("rating.read.tutorial")}
                </Text>
              </ModerationHeader>
              <ImageWrapper>
                {loading && <Loader />}
                {error && (
                  <Error>
                    <Text>{locale("rating.no_picture")}</Text>
                    {error && !loading && (
                      <div>
                        <br />
                        <img alt="" src="/assets/imgs/moderation/logo.png" />
                      </div>
                    )}
                  </Error>
                )}
                {!loading && !error && pictures.length > 0 && (
                  <img
                    alt=""
                    src={`${pictures[pictureIndex].path}`}
                    style={{
                      marginBottom: 11,
                      textDecoration: "underline",
                      cursor: "pointer",
                      objectFit: "contain",
                      maxHeight: "100%",
                    }}
                  />
                )}
              </ImageWrapper>
              {!loading && !error && (
                <>
                  <SkipButton
                    onClick={() => {
                      ratePicture("skip");
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: "18px",
                      }}
                    >
                      {locale("global.skip")}
                    </Text>
                  </SkipButton>
                  <RatingBar clickable={true} ratePicture={ratePicture} />
                </>
              )}
              <CloseButton
                onClick={() => {
                  dispatch(ModalSlice.actions.setModerationVisible(false));
                }}
              >
                <img
                  alt=""
                  src="/assets/icons/cross-outlined-white.svg"
                  style={{ width: 50, height: 50 }}
                />
              </CloseButton>
            </ModalContent>
          )}
          <ChevronWrapper style={{ justifyContent: "flex-end" }}>
            {isDemoVisible && step < 7 && (
              <img
                alt=""
                src="/assets/icons/moderation/chevron-right.png"
                onClick={() => {
                  setStep(Math.min(7, step + 1));
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </ChevronWrapper>
        </Modal>
      </Container>
    );
  return <></>;
};

export default ModerationModal;
