import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import { Flex } from "../../components/style/flex.component";
import { Loader } from "../../components/style/loader.component";
import ScreenWrapper from "../../components/style/screen-wrapper.component";
import { getNearbyCategory } from "../../services/nearby.service";
import { toggleUserHashtag } from "../../services/users.service";
import colors from "../../themes/colors-v2.theme";
import { useLogin } from "../../utils/auth.utils";
import useLocale from "../../utils/locale/locale.hook";
import { useIsPremium } from "../../utils/useUser.utils";
import { CustomGrid } from "../nearby/components/custom-grid.component";

type HashtagLocationState = {
  id: string;
  name: string;
  previousPath: string;
  category: "hashtags" | "sexualPractices" | "communities";
};

export const HahstagsScreen = () => {
  const location = useLocation<HashtagLocationState>();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const id = location?.state?.id;
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const locale = useLocale();
  const { me } = useLogin();
  const isPremium = useIsPremium();

  const [userHashtagState, setUserHashtagState] = useState<
    "add" | "remove" | "manage"
  >(null);

  useEffect(() => {
    if (!me || !location?.state?.id) return;
    const hashtagId = location.state.id;
    const hashtags = me.hashtags;

    if (hashtags.find((h) => h.id === hashtagId)) {
      setUserHashtagState("remove");
    } else {
      if (hashtags.length >= 10) {
        setUserHashtagState("manage");
      } else {
        setUserHashtagState("add");
      }
    }
  }, [location?.state?.id, me?.hashtags]);

  async function fetchUsersByCategory(id: string) {
    const limit = 70;
    const query: Record<string, unknown> = {
      limit,
      page,
    };

    const category = location?.state?.category;
    query[category] = [id];

    const categoryUsers = await getNearbyCategory("hashtags", query);
    setHasMore(categoryUsers.users.length === limit);
    setUsers([...users, ...categoryUsers.users]);
    setPage((page) => page + 1);
  }

  const handleSwitchHashtagClick = async () => {
    const hashtagId = location.state.id;

    setIsDisabled(true);
    if (userHashtagState === "add") {
      await toggleUserHashtag(Number(hashtagId));
      setUserHashtagState("remove");
    } else {
      if (userHashtagState === "manage") {
        setUserHashtagState("manage");
        history.push(`/settings/edit-profile`);
      } else {
        await toggleUserHashtag(Number(hashtagId));
        setUserHashtagState("add");
      }
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    if (id) {
      setInfiniteScrollKey(Math.random());
      setUsers([]);
      setHasMore(true);
      setPage(0);
    }
  }, [id]);

  return (
    <ScreenWrapper style={{ marginBottom: isPremium ? 220 : 220 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          position: "fixed",
          zIndex: 10,
          top: 0,
        }}
      >
        <Flex
          alignItems="center"
          gap="16px"
          style={{
            flex: 1,
            background: colors.darkGradient,
            backgroundAttachment: "fixed",
            padding: "20px 16px 16px 16px",
            width: "100%",
          }}
        >
          <img
            src="/assets/icons/nearby/chevron-right-white.svg"
            alt="radar"
            width={16}
            height={16}
            style={{ transform: "rotate(180deg)", cursor: "pointer" }}
            onClick={() => {
              window.history.go(-1);
            }}
          />
          <div
            style={{
              fontWeight: 600,
              fontSize: isDesktop ? "16px" : "20px",
              color: "white",
              margin: !isDesktop ? "auto" : "",
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <img
              src="/assets/icons/nearby/hashtag.svg"
              width={16}
              height={16}
            />
            {location?.state?.name}
          </div>
          <div style={{ width: 16 }} />
        </Flex>
      </div>
      <SectionContainer>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            fetchUsersByCategory(id);
          }}
          hasMore={hasMore}
          key={infiniteScrollKey}
          loader={
            <div
              style={{ display: "flex", justifyContent: "center" }}
              key={Math.random().toString()}
            >
              <Loader color={colors.primary} key={Math.random().toString()} />
            </div>
          }
        >
          <CustomGrid users={users} cellType={"rectangle"} />
        </InfiniteScroll>
      </SectionContainer>
      {!isDesktop ? (
        <div
          style={{
            background: "black",
            padding: 8,
            position: "fixed",
            bottom: 0,
            zIndex: 10000,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {userHashtagState === "manage" ? (
            <div
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: 700,
                textAlign: "center",
                padding: 12,
              }}
            >
              {locale("hashtag.manage.warn")}
            </div>
          ) : null}
          <PrimaryButton
            style={{
              width: "100%",
              color: userHashtagState !== "add" ? "black" : "white",
              background: userHashtagState !== "add" ? "white" : colors.blue,
            }}
            size="large"
            disabled={isDisabled}
            isActive
            onClick={handleSwitchHashtagClick}
          >
            {locale(`hashtag.${userHashtagState}`)}
          </PrimaryButton>
        </div>
      ) : null}
    </ScreenWrapper>
  );
};

const SectionContainer = styled.div`
  background-color: transparent;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 60px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  padding: 8px;

  @media (max-width: 1000px) {
    padding: 16px 12px 12px 12px;
    border-radius: 0;
  }
`;
