import { createSlice } from "@reduxjs/toolkit";

const NearbySlice = createSlice({
  name: "nearby",
  initialState: {
    nearbyActiveTab: "aroundMe",
    communityChosen: "all_communities",
  },
  reducers: {
    setNearbyActiveTab: (state, { payload }) => {
      return {
        ...state,
        nearbyActiveTab: payload,
      };
    },
    setCommunityChosen: (state, { payload }) => {
      return {
        ...state,
        communityChosen: payload,
      };
    },
  },
});

export default NearbySlice;
