import { PropsWithChildren, useEffect } from 'react';
import Modal, { Styles as ModalStyles } from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import ModalSlice from '../../../store/slices/modal.slice';
import styled, { keyframes } from 'styled-components';
import Text from '../../style/text.component';

type Props = {
  style?: any;
};

export const ModalNotificationSuccess = ({ style, children }: PropsWithChildren<Props>) => {
  const { notification }: { notification: { visible: boolean; title: string } } = useSelector(
    (state: any) => state.modalStore
  );
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      ModalSlice.actions.setNotification({
        visible: false,
        title: '',
      })
    );
  };

  return (
    <NotificationContainer style={{ transform: notification.visible ? 'translateY(0)' : 'translateY(100%)' }}>
      <NotificationWrapper>
        <img src="/assets/icons/notification-sucess.svg" alt="sucess" />

        <Text color="#067647">{notification.title}</Text>
        <div onClick={closeModal} style={{ marginLeft: 8, cursor: 'pointer' }}>
          <img alt="cross" src="/assets/icons/cross-success.svg" width={10} height={10} />
        </div>
      </NotificationWrapper>
    </NotificationContainer>
  );
};

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6fef9;
  color: white;
  border: 1px solid #75e0a7;
  border-radius: 12px;
  padding: 8px 20px 8px 8px;
  gap: 8px;
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f2f2f2;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.3s ease-in-out;
  z-index: 10000;
  @media (max-width: 500px) {
    right: 2px;
  }
`;
