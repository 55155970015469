import { post } from "./utils.service";

export const sendVerificationCode = async (phone: string, token: string) => {
  const res = await post(`/phone-verification/send-code`, {
    phone,
    captchaResponse: token,
  });
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
}