import styled from "styled-components/macro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import colors from "../../themes/colors-v2.theme";

const Wrapper = styled(DatePicker)`
  width: ${(props) => props.width || "100%"};
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 12px;
  box-sizing: border-box;
  background-color: rgba(73, 78, 84, 0.6);
  color: #cfd3da;
  font-size: 12px;
  font-family: "Inter";

  &:hover {
    color: #eff0f1;
  }

  &:focus {
    color: #eff0f1;
    background-color: ${colors.lightGrey6};
  }
`;

const DateTimePickerComponent = (props) => {
  return (
    <Wrapper
      showTimeSelect={true}
      onChange={(val: any) => {
        props.onChange(val);
      }}
      selected={props.value}
      dateFormat="dd/MM/yyyy 'at' h:mm aa"
    />
  );
};

export default DateTimePickerComponent;
