import React from "react";
import styled from "styled-components/macro";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import colors from "../../themes/colors.theme";

interface CustomTooltipProps extends TooltipProps {
  backgroundColor?: string;
}

export default function CustomTooltip(props: CustomTooltipProps) {
  return <StyledTooltip {...props}>{props.children}</StyledTooltip>;
}

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltip: "tooltip",
      arrow: "arrow",
    }}
    {...props}
  />
))`
  & .tooltip {
    margin: 4px;
    background-color: ${(props) =>
      props.backgroundColor || colors.backgroundDark};
  }

  & .arrow {
    color: ${colors.backgroundDark};
  }
`;
