import { useDispatch } from 'react-redux';
import ModalSlice from '../store/slices/modal.slice';
import { useEffect, useState } from 'react';

type Notification = {
  visible?: boolean;
  title: string;
};

export const useNotification = () => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState<Notification>({
    visible: false,
    title: '',
  });

  useEffect(() => {
    dispatch(
      ModalSlice.actions.setNotification({
        visible: notification.visible,
        title: notification.title,
      })
    );
    let timer;
    if (notification.visible) {
      timer = setTimeout(() => {
        setNotification((notif) => ({ ...notif, visible: false }));
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [dispatch, notification]);

  const triggerNotification = (newNotification: Notification) => {
    setNotification({ ...newNotification, visible: newNotification.visible ? newNotification.visible : true });
  };

  return { notification, triggerNotification };
};
