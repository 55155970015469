import styled from 'styled-components/macro';
import useLocale from '../../../utils/locale/locale.hook';

import Notifications from '../Notifications/Notifications';

import { useMediaQuery } from 'react-responsive';

const NavIcon = (props: { onClick?: () => void; style?: any; notif?: number; icon: string; activated?: boolean }) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  return (
    <Wrapper>
      <Nav style={props?.style} onClick={() => props?.onClick && props?.onClick()}>
        {props.activated ? (
          <img
            src={'/assets/icons/sidebar/navbar/' + props.icon + '-active.svg'}
            style={{ width: 23, marginBottom: isDesktop ? 8 : 0 }}
          />
        ) : (
          <img
            src={'/assets/icons/sidebar/navbar/' + props.icon + '.svg'}
            style={{ width: 23, marginBottom: isDesktop ? 8 : 0 }}
          />
        )}
        {!!props.notif && props.notif > 0 && <Notifications notificationNumber={props.notif} maxNumberOfChar={1} />}
      </Nav>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  align-items: center;
  position: relative;
`;

export default NavIcon;
