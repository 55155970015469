import React from "react";
import styled from "styled-components/macro";

// import useLocale from "../../../../utils/locale/locale.hook";

import colors from "../../../../themes/colors-v2.theme";
import fonts from "../../../../themes/fonts.theme";
import useLocale from "../../../../utils/locale/locale.hook";
import { ReadReceiptIndicator } from "./message.component";
import { useLogin } from "../../../../utils/auth.utils";

interface ComponentProps {
  message: any;
  active: boolean;
}

const mapMessageTypeToIconPath = {
  picture: "/assets/icons/messages/preview/picture.svg",
  "picture-inactive": "/assets/icons/messages/preview/picture-inactive.svg",
  quickshare: "/assets/icons/messages/preview/quickshare.svg",
  "quickshare-inactive":
    "/assets/icons/messages/preview/quickshare-inactive.svg",
  ephemeral: "/assets/icons/messages/icons-actions-ephemeral.svg",
  "ephemeral-inactive": "/assets/icons/messages/icons-actions-ephemeral.svg",
  location: "/assets/icons/messages/preview/location.svg",
  "location-inactive": "/assets/icons/messages/preview/location-inactive.svg",
  video: "/assets/icons/messages/preview/picture.svg",
  "video-inactive": "/assets/icons/messages/preview/picture-inactive.svg",
};

export default function LastMessagePreview({
  message,
  active,
}: ComponentProps) {
  const { me } = useLogin();
  const locale = useLocale();
  const getTime = (date: string) => {
    const date1 = new Date(date).getTime();
    const date2 = Date.now();
    let tmp = date2 - date1;
    let sec = Math.floor(tmp / 1000);
    let min = Math.floor(sec / 60);
    let hour = Math.floor(min / 60);
    let day = Math.floor(hour / 24);
    if (day > 0) return day.toString() + "d";
    if (hour > 0) return hour.toString() + "h";
    if (min > 0) return min.toString() + "m";
    if (sec > 0) return sec.toString() + "s";
    return locale("key_now");
  };

  const mapMessageTypeToTextPreview = {
    picture: locale("chat.conversations.picture_last_message"),
    quickshare: locale("global.settings.pictures.quickshare"),
    ephemeral: locale("conversation.has_sent.ephemeral"),
    location: locale("conversation.has_sent.location"),
    woof: locale("conversation.has_sent.woof"),
    video: locale("conversation.has_sent.video"),
  };

  return (
    <Wrapper>
      {(message.type !== "text" || !message.content) && (
        <Picture>
          <img
            alt=""
            src={
              mapMessageTypeToIconPath[
                `${message.type}${!active ? "-inactive" : ""}`
              ] ||
              mapMessageTypeToIconPath[`picture${!active ? "-inactive" : ""}`]
            }
            width={18}
            height={18}
          />
        </Picture>
      )}
      <TextPreview active={active}>
        {message.type !== "text"
          ? mapMessageTypeToTextPreview[message.type]
          : !!message.content
          ? message.isPhraseKey
            ? locale(message.content)
            : message.content
          : mapMessageTypeToTextPreview.picture}
      </TextPreview>
      {message.isCurrentUserSender && me?.role === "premium" && (
        <ReadReceiptIndicator isReadByOther={message.isReadByOther} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Picture = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 6px;
`;

const TextPreview = styled.p<{ active: boolean }>`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: 4px;
  font-size: 12px;
  font-family: ${(p) => (p.active ? fonts.bold.name : fonts.regular.name)};
  color: ${(p) => (p.active ? colors.white : colors.lightGrey7)};
  font-weight: ${(p) => (p.active ? fonts.bold.weight : fonts.regular.weight)};
`;
