import { useState } from 'react';
import styled from 'styled-components/macro';
import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';
import { useSelector } from 'react-redux';
import useLocale from '../../utils/locale/locale.hook';
import UserFavorites, { ModalMode } from './user-favorites.component';
import Notifications from '../navbar/Notifications/Notifications';
import { useMediaQuery } from 'react-responsive';

export default function FavoritesScreen() {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const notifStore = useSelector((state: any) => state.notifStore);

  const [mode, setMode] = useState(ModalMode.FAVORITES);

  return (
    <div style={{ height: '100%' }}>
      <FixedHeader>
        <TitleWrapper>
          <img src="/assets/icons/sidebar/navbar/star-white.svg" width={24} height={24} />
          <Title>{locale('favorite.favorites.label')}</Title>
        </TitleWrapper>
        <Header>
          <Row>
            <FilterButton active={mode === ModalMode.FAVORITES} onClick={() => setMode(ModalMode.FAVORITES)}>
              {locale('favorites.filters.all')}
            </FilterButton>
            <FilterButton
              active={mode === ModalMode.FAVORITES_ONLINE}
              onClick={() => setMode(ModalMode.FAVORITES_ONLINE)}
            >
              {isDesktop ? locale('favorites.filters.my-favorites-online') : locale('filter_online')}
            </FilterButton>
            <FilterButton active={mode === ModalMode.FAVORED_BY} onClick={() => setMode(ModalMode.FAVORED_BY)}>
              {isDesktop ? locale('favorite.favored_by.label') : locale('favorites.filters.fans')}
              {notifStore.favoritesNotSeen > 0 && (
                <NotificationContainer>
                  <Notifications notificationNumber={notifStore.favoritesNotSeen} maxNumberOfChar={1} />
                </NotificationContainer>
              )}
            </FilterButton>
          </Row>
        </Header>
      </FixedHeader>
      <Content>
        <UserFavorites mode={mode} />
      </Content>
    </div>
  );
}

const FixedHeader = styled.div`
  position: fixed;
  background: ${colors.darkGradient};
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid ${colors.graySeparator};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 16px 16px;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: ${fonts.bold.weight};
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : 'white')};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};

  @media (max-width: 1000px) {
    flex: 1;
  }
`;

const NotificationContainer = styled.div`
  position: absolute;
  width: fit-content;
  top: 6px;
  left: calc(50% - 14px + 53px);
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 110px 10px 10px 10px;
  width: fit-content;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: ${colors.darkGradient};
`;
