import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";

import ModalTemplate from "../../miscellaneous/modal-template.component";

import { getMyAlbums } from "../../../services/users.service";
import ModalSlice from "../../../store/slices/modal.slice";
import useLocale from "../../../utils/locale/locale.hook";

import fonts from "../../../themes/fonts.theme";
import colors from "../../../themes/colors-v2.theme";
import { FancyScrollbar } from "../../style/fancy-scrollbar.style";
import { UserModalTemplate } from "../../../pages/user-profile/components/user-modal.component";
import { PrimaryButton } from "../../forms/primaryButton.component";

interface ModalProps {
  visible: boolean;
}

const Selected = (props) => {
  return (
    <>
      <SelectedOverlay />
      <SelectedValue>{props.value}</SelectedValue>
    </>
  );
};

export default function SelectUserPicturesModal({ visible }: ModalProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const { selectUserPictures } = useSelector((state: any) => state.modalStore);

  const [myAlbums, setMyAlbums] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [picturesSelected, setPicturesSelected] = useState([]);

  const confirmPicturesHandler = () => {
    if (picturesSelected.length > 0) {
      selectUserPictures.onPicturesConfirm(picturesSelected);
    }
    closeModalHandler();
  };

  const closeModalHandler = () => {
    dispatch(
      ModalSlice.actions.setSelectUserPictures({
        visible: false,
        onPicturesConfirm: null,
      })
    );
    setSelectedAlbum(null);
    setMyAlbums(null);
  };

  const getAlbumsHandler = async () => {
    const res = await getMyAlbums();

    if (res.error || (!!res.statusCode && !!res.message)) {
      return;
    }

    const index = res?.myPhotos?.pictures?.findIndex(
      (picture: any) => picture.id === res?.profilePicture?.id
    );
    res?.myPhotos?.pictures?.splice(index, 1);
    res?.myPhotos?.pictures?.unshift(res.profilePicture);

    setMyAlbums(res);
    setSelectedAlbum(res?.myPhotos);
  };

  const togglePictureHandler = (picture: any) => {
    const found = picturesSelected.find(
      (_picture) => _picture.id === picture.id
    );
    if (!found) {
      setPicturesSelected((prevPictures) => prevPictures.concat(picture));
    } else {
      setPicturesSelected((prevPictures) =>
        prevPictures.filter((_picture: any) => _picture.id !== picture.id)
      );
    }
  };

  useEffect(() => {
    setPicturesSelected([]);
  }, [selectedAlbum]);

  useEffect(() => {
    if (visible) {
      getAlbumsHandler();
    }
  }, [visible]);

  const mapAlbumTypeToLabel = {
    myPhotos: locale("account.photos_management.my_photos"),
    myPrivatePhotos: locale("account.photos_management.my_private_photos"),
    quickshare: locale("account.photos_management.quickshare"),
  };

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModalHandler}
      style={{
        borderRadius: 8,
        width: isDesktop ? "fit-content" : "90%",
        height: isDesktop ? "fit-content" : "90%",
        background: colors.darkGray,
        position: "absolute",
        border: `1px solid ${colors.lightGrey10}`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1011,
        boxSizing: "border-box",
      }}
    >
      <ContentWrapper>
        <Header>
          <div
            style={{
              border: `1px solid ${colors.grayBorder}`,
              borderRadius: "100%",
              width: isDesktop ? 56 : 26,
              minHeight: isDesktop ? 56 : 26,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/icons/messages/icons-actions-video-white.svg"
              alt="image icon"
              width={isDesktop ? 24 : 14}
              height={24}
            />
          </div>
          <Title>
            {selectedAlbum && !isDesktop
              ? mapAlbumTypeToLabel[selectedAlbum.type]
              : locale("message.ephemeral.share-from-gallery")}
          </Title>
        </Header>
        <SelectionWrapper>
          {myAlbums && (
            <>
              <Row>
                <FilterButton
                  active={selectedAlbum?.id === myAlbums.myPhotos.id}
                  onClick={() => setSelectedAlbum(myAlbums.myPhotos)}
                >
                  {isDesktop
                    ? locale("global.settings.pictures.public_pictures")
                    : locale("global.settings.pictures.public")}
                </FilterButton>
                <FilterButton
                  active={selectedAlbum?.id === myAlbums.myPrivatePhotos.id}
                  onClick={() => setSelectedAlbum(myAlbums.myPrivatePhotos)}
                >
                  {isDesktop
                    ? locale("global.settings.pictures.private_pictures")
                    : locale("global.settings.pictures.private")}
                </FilterButton>
                <FilterButton
                  active={selectedAlbum?.id === myAlbums.quickshare.id}
                  onClick={() => setSelectedAlbum(myAlbums.quickshare)}
                >
                  {locale("account.photos_management.quickshare")}
                </FilterButton>
              </Row>
              {!!selectedAlbum && (
                <PreviewWrapper>
                  <GridPreview>
                    {selectedAlbum?.pictures.map((picture: any) => {
                      const pictureSelected =
                        picturesSelected.findIndex(
                          (_picture) => _picture === picture
                        ) > -1;
                      const value =
                        picturesSelected.findIndex(
                          (_picture) => _picture === picture
                        ) + 1;
                      return (
                        <PicturePreview
                          key={picture?.id}
                          onClick={() => {
                            togglePictureHandler(picture);
                          }}
                          pictureSelected={pictureSelected}
                        >
                          <img src={picture?.path} />
                          {pictureSelected && <Selected value={value} />}
                        </PicturePreview>
                      );
                    })}
                  </GridPreview>
                  <div className="confirmWrapper">
                    <PrimaryButton
                      size="large"
                      isActive
                      onClick={confirmPicturesHandler}
                      style={{
                        position: "absolute",
                        bottom: 18,
                        width: "fit-content",
                      }}
                    >
                      {picturesSelected.length > 0
                        ? `Select these ${picturesSelected.length} photos`
                        : locale("cancel")}
                    </PrimaryButton>
                  </div>
                </PreviewWrapper>
              )}
            </>
          )}
        </SelectionWrapper>
      </ContentWrapper>
    </UserModalTemplate>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  height: 700px;
  overflow: scroll;
  box-sizing: border-box;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  @media (max-width: 1200px) {
    height: 85vh;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 8px;

  @media (max-width: 1200px) {
    height: 24px;
  }
`;

const Title = styled.div`
  font-weight: ${fonts.bold.weight};
  font-size: 24px;
  color: ${colors.white};

  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;

  & > .confirmWrapper {
    width: 100%;
    margin: auto;
    justify-content: center;
    display: flex;

    & button {
      width: 100%;
    }
  }
`;

const GridPreview = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding-right: 2px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 240px;
  margin-bottom: 24px;

  @media (max-width: 1000px) {
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 120px;
  }

  ${FancyScrollbar}
`;

const PicturePreview = styled.div<{ pictureSelected: boolean }>`
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  user-select: none;
  position: relative;

  cursor: pointer;

  & > img {
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  width: 100%;
  height: 100%;
`;

const SelectedOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  background-color: rgba(16, 17, 19, 0.75);
`;

const Button = styled.div<{ outlined?: boolean }>`
  position: absolute;
  bottom: 18px;
  background-color: ${colors.darkOrange1};
  display: flex;
  cursor: pointer;
  color: ${colors.white};
  border: solid 1px ${colors.darkOrange3};
  padding: 12px 35px;
  align-items: center;
  justify-content: center;
  font-weight: ${fonts.semiBold.weight};
  border-radius: 4px;
  margin: 2px auto;
  ${(p) => p.outlined && "background-color: transparent;"}
`;

const SelectedValue = styled.div`
  width: 42px;
  height: 42px;
  position: absolute;
  top: 117px;
  left: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  background-color: #3281fe;

  @media (max-width: 1000px) {
    top: ${window.innerWidth / 11}px;
    left: ${window.innerWidth / 11}px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 17px 15px 15px 16px;
  padding: 4px;
  border-radius: 8px;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
  }
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: 200px;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};
`;
