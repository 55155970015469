import { store } from "../store/configure.store";

// cm to in  / 2.54
// kg to ln * 2.205

export const getWeight = (weight: any) => {
  let units = localStorage.getItem("units");
  if (units === "metric") return weight;
  if (units === "imperial") return (weight * 2.20462).toFixed();
};

export const getHeight = (height: any) => {
  let units = localStorage.getItem("units");
  if (units === "metric") return height / 100;
  if (units === "imperial") return (height / 3.048 / 10).toFixed(1);
};

export const getCmBddToClient = (cm: any) => {
  cm = Number(cm);
  let units = localStorage.getItem("units");
  if (units === "metric") return Math.round(cm);
  if (units === "imperial") return Math.round(cm / 2.54);
};

export const getKgBddToClient = (kg: any) => {
  kg = Number(kg);
  let units = localStorage.getItem("units");
  if (units === "metric") return Math.round(kg);
  if (units === "imperial") return Math.round(kg * 2.205);
};

export const getCmClientToBdd = (cm: any) => {
  cm = Number(cm);
  let units = localStorage.getItem("units");
  if (units === "metric") return Math.round(cm);
  if (units === "imperial") return Math.round(cm * 2.54);
};

export const getKgClientToBdd = (kg: any) => {
  kg = Number(kg);
  let units = localStorage.getItem("units");
  if (units === "metric") return Math.round(kg);
  if (units === "imperial") return Math.round(kg / 2.205);
};

export const getDistance = (distance: any) => {
  const keys = store.getState().localeStore.keys;
  let units = localStorage.getItem("units");
  if (units === "metric") {
    if (distance < 1000) {
      const unit = keys ? keys["global.metter.metric"]?.message : "";
      return distance.toFixed(0) + unit;
    } else {
      const unit = keys ? keys["global.kilometers.metric"]?.message : "";
      return (distance / 1000).toFixed(1) + unit;
    }
  }
  if (units === "imperial") {
    distance = distance * 3.281; // m to ft
    if (distance < 1000) {
      const unit = keys ? keys["global.metter.imperial"]?.message : "";
      return distance.toFixed(0) + unit;
    } else {
      const unit = keys ? keys["global.kilometers.imperial"]?.message : "";
      return (distance / 5080).toFixed(1) + unit; // ft to miles
    }
  }
};
