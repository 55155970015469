import { createSlice } from "@reduxjs/toolkit";

const NotifSlice = createSlice({
  name: "Notification",
  initialState: {
    albumsNotSeen: 0,
    feedNotSeen: 0,
    messagesNotSeen: 0,
    notificationsNotSeen: 0,
    viewsNotSeen: 0,
    ongoingLiveStreams: 0,
    overlayVisible: false,
    favoritesNotSeen: 0,
  },
  reducers: {
    setAlbumsNotSeen: (state, { payload }) => {
      return {
        ...state,
        albumsNotSeen: payload,
      };
    },
    setFeedNotSeen: (state, { payload }) => {
      return {
        ...state,
        feedNotSeen: payload,
      };
    },
    setNotificationsNotSeen: (state, { payload }) => {
      return {
        ...state,
        notificationsNotSeen: payload,
      };
    },
    setMessagesNotSeen: (state, { payload }) => {
      return {
        ...state,
        messagesNotSeen: payload,
      };
    },
    addViewsNotSeen: (state, { payload }: { payload: number }) => {
      const addedViews = Number(payload);
      return {
        ...state,
        viewsNotSeen: isNaN(addedViews)
          ? state.viewsNotSeen
          : state.viewsNotSeen + addedViews,
      };
    },
    resetViewsNotSeen: (state, _) => {
      return {
        ...state,
        viewsNotSeen: 0,
      };
    },
    addFavoritesNotSeen: (state, { payload }: { payload: number }) => {
      const addedFavorites = Number(payload);
      return {
        ...state,
        favoritesNotSeen: isNaN(addedFavorites)
          ? state.favoritesNotSeen
          : state.favoritesNotSeen + addedFavorites,
      };
    },
    resetFavoritesNotSeen: (state, _) => {
      return {
        ...state,
        favoritesNotSeen: 0,
      };
    },
    setOngoingLiveStreams: (state, { payload }: { payload: number }) => {
      return {
        ...state,
        ongoingLiveStreams: payload,
      };
    },
    // DEBUG: is that only client-side?
    incrementOngoingLiveStreams: (state, _) => {
      return {
        ...state,
        ongoingLiveStreams: state.ongoingLiveStreams + 1,
      };
    },
    decrementOngoingLiveStreams: (state, _) => {
      const newCount =
        state.ongoingLiveStreams > 0
          ? state.ongoingLiveStreams - 1
          : state.ongoingLiveStreams;
      return {
        ...state,
        ongoingLiveStreams: newCount,
      };
    },
    setOverlayVisibility: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        overlayVisible: payload,
      };
    },
  },
});

export default NotifSlice;
