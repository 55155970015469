import React, { useState, useEffect, useRef } from 'react';
import useLocale from '../../utils/locale/locale.hook';
import styled from 'styled-components/macro';
import colors from '../../themes/colors-v2.theme';
import Icons from '../style/icons.component';
import Text from '../style/text.component';
import { useOnClickOutside } from '../../utils/clickOutside.utils';

type DropdownProps = {
  onSelect: (value: any) => void;
  placeholder?: string;
  value?: any;
  lines?: Array<{ value: any; label: string; keyPhrase?: string }>;
  style?: Object;
  error?: boolean;
  transparant?: boolean;
  fontSize?: number;
  big?: boolean;
};

export default function Dropdown(props: DropdownProps) {
  const locale = useLocale();
  const [selectedLine, setSelectedLine] = useState(props?.lines?.find((line) => line.value === props?.value));
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);

  useOnClickOutside([dropdownRef], () => {
    setOpen(false);
  });

  useEffect(() => {
    setSelectedLine(props?.lines?.find((line) => line.value === props?.value));
  });

  const onSelect = (line) => {
    setSelectedLine(line);
    setOpen(false);
    props?.onSelect(line.value);
  };

  return (
    <Container
      onClick={() => setOpen(!open)}
      style={props.style}
      error={props?.error}
      transparant={props?.transparant}
      ref={dropdownRef}
      big={props.big}
    >
      <Text
        body
        style={{
          color: colors.white,
          fontSize: props.fontSize ? props.fontSize : 14,
        }}
      >
        {selectedLine?.keyPhrase
          ? locale(selectedLine?.keyPhrase)
          : selectedLine?.label
          ? selectedLine?.label
          : props?.placeholder}
      </Text>
      {props?.transparant ? (
        <Arrow style={{ top: props.big ? 22 : 10 }}>
          <Icons
            name={'arrow-outlined-white'}
            width="10"
            height="10"
            style={
              open
                ? {
                    transform: 'rotate(180deg)',
                    transition: 'all 400ms',
                  }
                : { transition: 'all 400ms' }
            }
          />
        </Arrow>
      ) : (
        <Arrow>
          <Icons
            style={
              open
                ? {
                    transform: 'rotate(-180deg)',
                    transition: 'all 400ms',
                  }
                : { transition: 'all 400ms' }
            }
            name="arrow-outlined-white"
            width="20"
            height="20"
          />
        </Arrow>
      )}
      {open && (
        <Menu big={props.big}>
          {props?.lines?.map((line, index) => (
            <MenuItem big={props.big} key={index} onClick={() => onSelect(line)}>
              <Text style={{ fontSize: props.fontSize ? props.fontSize : 14 }}>
                {line.keyPhrase ? locale(line.keyPhrase) : line.label}
              </Text>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
}

const Container = styled.div<{
  error: boolean;
  transparant: boolean;
  big: boolean;
}>`
  border: none;
  border-radius: 8px;
  padding: ${({ big }) => (big ? '12px' : '0px 12px')};
  font-size: 22px;
  height: 40px;
  background-color: ${(props) => (props.transparant ? 'transparant' : colors.darkGray)};
  border: 1px solid ${(props) => (props.transparant ? 'transparant' : colors.grayBorder)};
  color: ${colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.error &&
    `
        border: 2px solid ${colors.danger};
    `}
  & > p {
    color: ${colors.white};
    font-size: 12px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  right: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div<{ big: boolean }>`
  position: absolute;
  background-color: ${colors.darkGray};
  top: 50px;
  left: 0px;
  width: 100%;
  z-index: 100;
  border-radius: 2px;
  max-height: ${({ big }) => (big ? '500px' : '300px')};
  overflow-y: scroll;
  border: 1px solid black;
  border-radius: 8px;
`;

const MenuItem = styled.div<{ big: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ big }) => (big ? '24px 12px' : '12px')};
  cursor: pointer;
  border-bottom: 1px solid ${colors.grayBorder};
  &:last-child {
    border-bottom: none;
  }
  & > p {
    color: ${colors.white};
    font-size: 12px;
  }
  &:hover {
    background: ${colors.lightGrey10};
  }
`;
