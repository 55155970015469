import React from "react";
import styled from "styled-components/macro";
import colors from "../../../themes/colors.theme";
import Webcam from "react-webcam";
import "cropperjs/dist/cropper.css";
import Icons from "../../style/icons.component";
import { convertUrlToFile } from "../photos.utils";

const Container = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Content = styled.div`
  background-color: ${colors.light};
  position: relative;
  border-radius: 20px;
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 101;
`;

type PhotoCameraProps = {
  onClose: () => void;
  onDone: (file: File) => void;
};

const PhotoCamera = (props: PhotoCameraProps) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const onClose = () => {
    props?.onClose();
  };

  React.useEffect(() => {
    if (imgSrc) {
      props?.onDone(convertUrlToFile(imgSrc));
    }
  }, [imgSrc]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  return (
    <Container>
      <Content>
        <IconButton onClick={onClose} style={{ top: 20, right: 25 }}>
          <Icons name="cross-outlined-black" width="25" height="25" />
        </IconButton>
        <Webcam height={"100%"} width={"100%"} ref={webcamRef} />
        <IconButton onClick={capture} style={{ bottom: 10 }}>
          <Icons name="photo-outlined-black" width="35" height="35" />
        </IconButton>
      </Content>
    </Container>
  );
};

export default PhotoCamera;
