import { Helmet } from "react-helmet";

type MetaDataProps = {
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
};

const MetaData = (props: MetaDataProps) => {
  let currentUrl = new URL(window.location.href);
  let url = currentUrl.protocol + "//" + currentUrl.host + currentUrl.pathname;
  //TODO add og:locale
  return (
    <>
      <Helmet>
        <meta name="title" content={props.title} />
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta
          property="og:title"
          content={props.ogTitle ? props.ogTitle : props.title}
        />
        <meta
          property="og:description"
          content={
            props.ogDescription ? props.ogDescription : props.description
          }
        />
        <meta
          property="og:image"
          content={
            props.ogImage ||
            "https://d2tiq3wo24jtl5.cloudfront.net/default/og-post-default.png"
          }
        />
        <meta property="og:url" content={url} />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <div id="rendering" />
    </>
  );
};

export default MetaData;
