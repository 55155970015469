import { Dispatch, SetStateAction } from "react";
import styled from "styled-components/macro";
import { TitleOptions } from "../../pages/nearby/components/filters/title-option.component";
import colors from "../../themes/colors-v2.theme";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setRangeType?: Dispatch<SetStateAction<"age" | "height" | "weight">>;
  title: string;
  value: string;
}
const MobileFilterButton = (props: Props) => {
  const { setIsOpen, setRangeType, title, value } = props;

  return (
    <Container
      onClick={() => {
        setIsOpen(true);
        setRangeType && setRangeType("weight");
      }}
    >
      <RangeTextContainer>
        <TitleOptions style={{ margin: 0 }}>{title}</TitleOptions>
        <Value isSpecified>{value}</Value>
      </RangeTextContainer>
      <img
        src="/assets/icons/nearby/chevron-right.svg"
        alt="arrow right"
        width={6}
        height={12}
      />
    </Container>
  );
};

export default MobileFilterButton;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
`;

const RangeTextContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Value = styled.p<{ isSpecified: boolean }>`
  margin: 0 0 0 3px;
  padding: 0;
  font-weight: ${({ isSpecified }) => (isSpecified ? 700 : 500)};
  font-size: 14px;
  color: ${({ isSpecified }) =>
    isSpecified ? colors.darkGrey2 : colors.lightGrey10};
`;
