import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import IosSwitch from '../../../../components/ios-switch/ios-switch.component';
import {
  getCommunities,
  getSexualPractices,
  getEthnicities,
  getfacialHair,
  getAppearances,
} from '../../../../services/caracteristics.service';
import fonts from '../../../../themes/fonts.theme';
import { useOnClickOutside } from '../../../../utils/clickOutside.utils';
import useLocale from '../../../../utils/locale/locale.hook';
import MultipleAdvanced from './advanced/multiple-advanced.component';
import CaracteristicFilter from './caracteristic-filter.component';
import SexualRoleFilter from './sexual-role-filter.component';
import StatusFilter from './status-filter.component';
import WithPictureFilter from './with-picture-filter.component';
import colors from '../../../../themes/colors-v2.theme';
import SearchBar from '../../../../components/forms/search.component';
import Text from '../../../../components/style/text.component';
import { FiltersType } from '../../nearby.screenv2';

type Props = {
  filters: FiltersType;
  setFilters: Dispatch<SetStateAction<FiltersType>>;
  filtersOpen: boolean;
  setFiltersOpen: (str: string) => void;
  areFiltersDeactivated: boolean;
  setAreFiltersDeactivated: Dispatch<SetStateAction<boolean>>;
  selectedAddress?: SearchResult;
  setSelectedAddress?: Dispatch<SetStateAction<SearchResult>>;
  setSearchUser?: Dispatch<SetStateAction<any>>;
  searchUser?: string;
  handleSearchUser?: (value?: string) => void;
  setIsSearchResults?: Dispatch<SetStateAction<any>>;
};

export const RightSidebarAdvancedFilters = ({
  filters,
  setFilters,
  filtersOpen,
  handleSearchUser,
  searchUser,
  setFiltersOpen,
  areFiltersDeactivated,
  setAreFiltersDeactivated,
}: Props) => {
  const locale = useLocale();
  const [isOpen, setIsOpen] = useState(filtersOpen);

  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const statusSelectorRef = useRef(null);

  const handleDeactivateFilter = (isDeactivated: boolean) => {
    setAreFiltersDeactivated(!isDeactivated);
  };

  useOnClickOutside([statusSelectorRef], () => {
    if (isDesktop) {
      setIsOpen(false);
      setFiltersOpen('close');
    }
  });

  const closeFilters = () => {
    setIsOpen(false);
    setFiltersOpen('close');
  };

  useEffect(() => {
    setIsOpen(filtersOpen);
  }, [filtersOpen]);

  if (!isOpen) return <></>;

  return (
    <Container ref={statusSelectorRef}>
      <Header isDesktop={isDesktop}>
        <CloseButtonWrapper onClick={() => closeFilters()}>
          <img alt="" src="/assets/icons/cross-outlined-white.svg" width={24} height={24} />
        </CloseButtonWrapper>
        <HeaderText>
          <Text bold fontSize="16px">
            {areFiltersDeactivated
              ? locale('radar.search.filters.deactivated')
              : locale('radar.search.filters.activated')}
          </Text>
          <Text>
            {areFiltersDeactivated
              ? locale('radar.search.deactivated_filters.message')
              : locale('radar.search.activated_filters.message')}
          </Text>
        </HeaderText>

        <SwitchButtonContainer>
          <IosSwitch isOn={!areFiltersDeactivated} onChange={handleDeactivateFilter} />
        </SwitchButtonContainer>
      </Header>

      <Content>
        <SearchUserInputContainer>
          <SearchBar
            style={{ width: '100%' }}
            onChange={(value) => handleSearchUser(value)}
            value={searchUser}
            placeholder={locale('radar.search.placeholder')}
          />
        </SearchUserInputContainer>
        <QuickFilters>
          <StatusFilter
            isDisabled={areFiltersDeactivated}
            statusSelected={filters?.status}
            onChange={(status) => setFilters({ status })}
          />
          <WithPictureFilter
            isDisabled={areFiltersDeactivated}
            value={filters?.withProfilePictureOnly}
            onChange={(value) => {
              setFilters({ withProfilePictureOnly: value });
            }}
          />
        </QuickFilters>
        <CaracteristicFilter
          ageRange={filters?.ageMin && filters?.ageMax && [filters?.ageMin, filters?.ageMax]}
          heightRange={filters?.heightMin && filters?.heightMax && [filters?.heightMin, filters?.heightMax]}
          weightRange={filters?.weightMin && filters?.weightMax && [filters?.weightMin, filters?.weightMax]}
          onChange={(values) => {
            setFilters({ ...values });
          }}
        />
        <SexualRoleFilter
          sexualRole={filters?.sexualRole}
          onChange={(values) => setFilters({ ...values })}
          isDisabled={areFiltersDeactivated}
        />
        <Line>
          <MultipleAdvanced
            onChange={(values) =>
              setFilters({
                communities: values,
              })
            }
            dataSelected={filters['communities'] || []}
            getData={async () => await getCommunities()}
            keyName={'communityKey'}
            phraseKey={'community'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
        <Line>
          <MultipleAdvanced
            onChange={(_filters) => {
              setFilters({
                ...filters,
                lookingFor: _filters,
              });
            }}
            dataSelected={filters['lookingFor'] || []}
            getData={async () => await getCommunities()}
            title={locale('nearby.advanced_filter.looking_for')}
            keyName={'communityKey'}
            phraseKey={'community'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
        <Line>
          <MultipleAdvanced
            onChange={(_filters) => {
              setFilters({
                sexualPractices: _filters,
              });
            }}
            dataSelected={filters['sexualPractices'] || []}
            getData={async () => await getSexualPractices()}
            title={locale('nearby.advanced_filter.sexual_practice')}
            keyName={'sexualPracticeKey'}
            phraseKey={'sexual_practice'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
        <Line>
          <MultipleAdvanced
            onChange={(_filters) => {
              setFilters({
                ethnicities: _filters,
              });
            }}
            dataSelected={filters['ethnicities'] || []}
            getData={async () => await getEthnicities()}
            keyName={'ethnicityKey'}
            phraseKey={'ethnicity'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
        <Line>
          <MultipleAdvanced
            onChange={(_filters) => {
              setFilters({
                facialHair: _filters,
              });
            }}
            dataSelected={filters['facialHair'] || []}
            getData={async () => await getfacialHair()}
            keyName={'facialHairKey'}
            phraseKey={'facial_hair'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
        <Line>
          <MultipleAdvanced
            onChange={(_filters) => {
              setFilters({
                appearance: _filters,
              });
            }}
            dataSelected={filters['appearance'] || []}
            getData={async () => await getAppearances()}
            keyName={'appearanceKey'}
            phraseKey={'appearance'}
            isDisabled={areFiltersDeactivated}
          />
        </Line>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  width: 362px;
  height: 100dvh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(12px);
  right: 0;
  top: 0;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.darkGrey2};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 362px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    background: #20252b;
  }
`;

const CloseButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
`;

const Content = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Header = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  margin-bottom: 20px;
  ${(props) =>
    !props.isDesktop &&
    `
        justify-content: space-between;
    `};
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

const QuickFilters = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #344054;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding-left: 0;
  }
`;

const SwitchButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 14px;
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.darkGrey2};
    margin: 0;
  }
`;

const SearchUserInputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;

  & button {
    width: 150px;
    border-radius: 8px;
    padding: 19px 12px;
  }
`;
