import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import GoBack from './go-back.component';
import LiveChat from './chat/live-chat.component';
import TextInput from '../../../components/forms-v2/text.input';
import Dropdown from '../../../components/forms/dropdown.component';

import ModalSlice from '../../../store/slices/modal.slice';
import { getViewerCount, moderateLive, startLive, stopLive } from '../../../services/live.service';
import { getWebsocketConnection } from '../../../utils/websocket.utils';
import { useLogin } from '../../../utils/auth.utils';
import useLocale from '../../../utils/locale/locale.hook';

import fonts from '../../../themes/fonts.theme';
import colors from '../../../themes/colors-v2.theme';
import Text from '../../../components/style/text.component';
import { getCommunities } from '../../../services/caracteristics.service';
import Community from '../../auth/signup/components/community.component';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../../components/style/loader.component';
import { useScript } from '../../../utils/useScript.hook';
import { getFileFromDataURL } from '../../../utils/file.utils';
import { uploadFile } from '../../../utils/s3.utils';
import { openProfile } from '../../user-profile/profile.utils';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { UserModalTemplate } from '../../user-profile/components/user-modal.component';

enum StartStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  BANNED = 'BANNED',
  SUCCESS = 'SUCCESS',
  ADMIN_STOPPED = 'ADMIN_STOPPED',
}

enum LiveStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

const update_thumbnail_threshold = 6; // every six moderation picture, we choose one as the thumbnail

export default function StartLiveScreen() {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { isLogin, me } = useLogin();
  const history = useHistory();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pictureInterval = useRef(null);
  const viewerCountInterval = useRef(null);
  const thumbnailIterator = useRef(1);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const [videoTrack, setVideoTrack] = useState(null);
  const [startStatus, setStartStatus] = useState(StartStatus.IDLE);
  const [liveStatus, setLiveStatus] = useState(LiveStatus.OFFLINE);
  const [errorMesssage, setErrorMessage] = useState('');
  const [banSanctionId, setBanSanctionId] = useState<number>(null);
  const [canAppealSanction, setCanAppealSanction] = useState<boolean>(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [viewerCount, setViewerCount] = useState('-');
  const [live, setLive] = useState(null);
  const [streamName, setStreamName] = useState('');
  const [room, setRoom] = useState(null);
  const [isStopLiveModalOpen, setIsStopLiveModalOpen] = useState(false);

  const { Twilio } = useScript('https://sdk.twilio.com/js/video/releases/2.18.0/twilio-video.min.js', 'Twilio');

  const onWSMessage = (e: any) => {
    const data = JSON.parse(e.data);
    if (data.type === 'live_ended' && data.liveId === live?.id) {
      setLiveStatus(LiveStatus.OFFLINE);
      setStartStatus(data.fromModeration ? StartStatus.ADMIN_STOPPED : StartStatus.IDLE);
      setLive(null);
    }
  };

  const startLiveHandler = async () => {
    if (
      startStatus === StartStatus.LOADING ||
      startStatus === StartStatus.SUCCESS ||
      startStatus === StartStatus.ADMIN_STOPPED
    ) {
      return;
    }

    setTitle(me.pseudo);

    if (selectedCategory === '') {
      setStartStatus(StartStatus.ERROR);
      setErrorMessage(locale('live.select_community'));
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (stream.getVideoTracks().length == 0 || stream.getAudioTracks().length == 0) {
        throw 'Missing permission';
      }
    } catch (e) {
      setStartStatus(StartStatus.ERROR);
      setErrorMessage(locale('live.authorize_camera_and_microphone'));
      return;
    }

    setStartStatus(StartStatus.LOADING);

    startLive({ title, selectedCategory }).then(async (res) => {
      if (res?.success) {
        setLive(res.live);
        setStreamName(res.live.stream_name);
        const room = await Twilio.Video.connect(res.token);
        setRoom(room);
        setLiveStatus(LiveStatus.ONLINE);
      } else {
        if (!!res?.sanctionId) {
          setBanSanctionId(res?.sanctionId);
          setCanAppealSanction(res?.canAppealSanction);
          setStartStatus(StartStatus.BANNED);
        } else if (res?.error) {
          setErrorMessage(res?.error);
          setStartStatus(StartStatus.ERROR);
        } else {
          setErrorMessage(locale('live.error_message'));
          setStartStatus(StartStatus.ERROR);
        }
      }
    });
  };

  const stopLiveHandler = () => {
    if (liveStatus === LiveStatus.OFFLINE) {
      return;
    }
    room?.disconnect();
    const trackElement = videoTrack?.detach();
    trackElement?.forEach((element) => element.remove());
    videoTrack?.stop();
    setLiveStatus(LiveStatus.OFFLINE);
    setStartStatus(StartStatus.IDLE);
    setLive(null);
    history.push('/live');

    stopLive(streamName).then((res) => {
      if (!res?.success) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    if (liveStatus === LiveStatus.ONLINE) {
      setShowChat(true);
      setStartStatus(StartStatus.SUCCESS);
    } else {
      setShowChat(false);
    }
  }, [liveStatus]);

  useEffect(() => {
    const ws = getWebsocketConnection();
    if (!!live && !!ws) {
      ws.removeEventListener('message', onWSMessage);
      ws.addEventListener('message', onWSMessage);
    }

    return () => {
      if (ws) {
        ws.removeEventListener('message', onWSMessage);
      }
    };
  }, [live]);

  useEffect(() => {
    if (!isLogin) {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: true,
          canCloseSignin: false,
        })
      );
    }

    return () => {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: false,
          canCloseSignin: true,
        })
      );
    };
  }, [isLogin]);

  useEffect(() => {
    dispatch(ModalSlice.actions.setLiveTerms(false));
    const getData = async () => {
      const data = await getCommunities();
      const communities = data.map((community) => {
        return {
          value: community.communityKey,
          label: community.communityKey,
          keyPhrase: community.phraseKey,
        };
      });

      setAvailableCategories(communities);
    };
    getData();
  }, []);

  useEffect(() => {
    if (Twilio && !videoTrack) {
      (async () => {
        const videoTrack = await Twilio.Video.createLocalVideoTrack();
        const trackElement = videoTrack.attach();
        document.getElementById('stream').appendChild(trackElement);
        setVideoTrack(videoTrack);
      })();
    }
    return () => {
      const trackElement = videoTrack?.detach();
      trackElement?.forEach((element) => element.remove());
      videoTrack?.stop();
    };
  }, [Twilio, videoTrack]);

  const useWindowUnloadEffect = () => {
    const handler = () => {
      if (streamName) {
        room?.disconnect();
        setLiveStatus(LiveStatus.OFFLINE);
        setStartStatus(StartStatus.IDLE);
        setLive(null);
        stopLive(streamName).then((res) => {
          if (!res?.success) {
            window.location.reload();
          }
        });
      }
    };

    useEffect(() => {
      window.addEventListener('beforeunload', handler);
      return () => {
        handler();
        window.removeEventListener('beforeunload', handler);
      };
    }, [streamName]);
  };

  useWindowUnloadEffect();

  /**
   * Fonction permettant de prendre une capture d'écran de la webcam et de l'envoyer à Hive pour la modération.
   */
  const takeModerationPicture = async () => {
    const canvas = canvasRef.current;
    const video = document.getElementById('stream')?.querySelector('video');
    if (!!canvas && !!video) {
      const context = canvas.getContext('2d');
      const width = video?.videoWidth || 800;
      const height = video?.videoHeight || 600;
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);

      const dataUrl = canvas.toDataURL('image/png');
      const file = getFileFromDataURL(dataUrl, 'moderation');

      const { path, error } = await uploadFile(file);
      // DEBUG: error not handled
      if (error) return;

      if (!!path) {
        moderateLive(streamName, {
          picturePath: path,
          updateThumbnail: thumbnailIterator.current === 1,
        });
        thumbnailIterator.current =
          thumbnailIterator.current === update_thumbnail_threshold ? 1 : thumbnailIterator.current + 1;
      }
    }
  };

  useEffect(() => {
    if (liveStatus === LiveStatus.ONLINE) {
      clearInterval(pictureInterval.current);
      takeModerationPicture();
      pictureInterval.current = setInterval(() => {
        takeModerationPicture();
      }, 10000);
    } else {
      clearInterval(pictureInterval.current);
    }
    return () => {
      clearInterval(pictureInterval.current);
    };
  }, [liveStatus]);

  useEffect(() => {
    if (liveStatus === LiveStatus.ONLINE && streamName) {
      clearInterval(viewerCountInterval.current);
      viewerCountInterval.current = setInterval(() => {
        getViewerCount(streamName).then((data) => {
          setViewerCount(data.success ? data.viewerCount : '-');
        });
      }, 2000);
    } else {
      clearInterval(viewerCountInterval.current);
    }
    return () => {
      clearInterval(viewerCountInterval.current);
    };
  }, [streamName, liveStatus]);

  return (
    <Wrapper>
      <LiveContainer>
        {startStatus === StartStatus.SUCCESS && <GoBack />}
        {startStatus !== StartStatus.ADMIN_STOPPED ? (
          <>
            {startStatus === StartStatus.SUCCESS && (
              <LiveInfo>
                <LiveBadge>
                  <Text style={{ fontWeight: 800 }}>{locale('live.badge')}</Text>
                </LiveBadge>
                <Viewers>
                  <img src="/assets/icons/sidebar/viewers-white.svg" height={12} width={12} alt="Viewers icon" />
                  <Text color={colors.white}>{viewerCount}</Text>
                </Viewers>
              </LiveInfo>
            )}
            <StreamContainer id="stream" isLive={startStatus === StartStatus.SUCCESS}></StreamContainer>
            <ScreenshotCanvas ref={canvasRef} />
            {startStatus === StartStatus.SUCCESS && (
              <StreamerInfos>
                <Streamer>
                  <img src={me.profilePicture.path} height={29} width={29} alt="Streamer profile picture" />
                  <InfosContainer>
                    <StreamerUsername>{me.pseudo}</StreamerUsername>
                    <StreamerCategory>
                      {locale(availableCategories.find((category) => category.value === selectedCategory).keyPhrase)}
                    </StreamerCategory>
                  </InfosContainer>
                </Streamer>
                <ButtonsContainer>
                  {live?.user.id === me.id && (
                    <PrimaryButton
                      onClick={() => setIsStopLiveModalOpen(true)}
                      isActive
                      size="medium"
                      style={{ borderRadius: 8 }}
                    >
                      {locale('live.start.stop_live.label')}
                    </PrimaryButton>
                  )}
                </ButtonsContainer>
              </StreamerInfos>
            )}
          </>
        ) : (
          <CenteredMessage>{locale('live.start.moderation_stop')}</CenteredMessage>
        )}
      </LiveContainer>
      {showChat ? (
        <LiveChat live={live} stopLiveHandler={stopLiveHandler} />
      ) : (
        <StartLiveForm>
          <img
            style={{ marginBottom: '24px' }}
            alt=""
            width="161px"
            height="32px"
            src="/assets/icons/logo/logo-white-with-text.svg"
          />
          <Text style={{ marginBottom: '13px', color: colors.white }}>{locale('live.launch.message')}</Text>
          <Dropdown
            placeholder={locale('live.launch.select_community')}
            lines={availableCategories}
            value={selectedCategory}
            onSelect={(category) => setSelectedCategory(category)}
            style={{
              width: '100%',
              marginBottom: '24px',
              borderRadius: '4px',
              fontSize: '12px',
              fontFamily: fonts.bold.name,
              fontWeight: fonts.bold.weight,
              boxSizing: 'border-box',
            }}
          />
          {startStatus === StartStatus.LOADING ? (
            <Loader />
          ) : (
            <>
              <Button onClick={startLiveHandler} active={selectedCategory !== ''} activable={true}>
                {locale('start.live.launch')}
              </Button>
              {errorMesssage ? <Error>{errorMesssage}</Error> : null}
              <Link to="/live" style={{ width: '100%', boxSizing: 'border-box' }}>
                <Button activable={false}>{locale('cancel')}</Button>
              </Link>
            </>
          )}
        </StartLiveForm>
      )}
      <UserModalTemplate
        isOpen={isStopLiveModalOpen}
        onClose={(e) => {
          setIsStopLiveModalOpen(false);
          (e) => e.stopPropagation();
        }}
        style={{
          width: 'fit-content',
          height: 'fit-content',
          background: colors.darkGray,
          position: 'absolute',
          border: `1px solid ${colors.lightGrey10}`,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <StopModalContainer>
          <div
            style={{
              border: `1px solid ${colors.grayBorder}`,
              borderRadius: '100%',
              width: 56,
              height: 56,
              display: 'flex',
              alignItems: 'center',
              justifyContent: ' center',
            }}
          >
            <img src="/assets/icons/sidebar/navbar/live-white.svg" alt="live" width={24} height={24} />
          </div>
          <Text
            style={{
              fontSize: 14,
              fontWeight: fonts.semiBold.weight,
              padding: '10px 0',
            }}
          >
            {locale('live.stop_live_confirmation')}
          </Text>
          <CustomeButtonsContainer>
            <PrimaryButton
              onClick={() => setIsStopLiveModalOpen(false)}
              size="large"
              style={{ color: 'black', background: 'white', width: '192px' }}
            >
              {locale('global.no')}
            </PrimaryButton>
            <PrimaryButton
              isActive
              onClick={() => {
                setIsStopLiveModalOpen(false);
                stopLiveHandler();
              }}
              size="large"
              style={{ width: '192px' }}
            >
              {locale('global.yes')}
            </PrimaryButton>
          </CustomeButtonsContainer>
        </StopModalContainer>
      </UserModalTemplate>
      {/* {isStopLiveModalOpen && (
        <StopLiveOverlay onClick={() => setIsStopLiveModalOpen(false)}>
          <StopLiveModal onClick={(e) => e.stopPropagation()}>
            
          </StopLiveModal>
        </StopLiveOverlay>
      )} */}
    </Wrapper>
  );
}

const Error = styled.div`
  border: 1px solid rgb(255, 0, 109);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  color: ${colors.white};
`;

const StreamContainer = styled.div<{ isLive: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${({ isLive }) => isLive && `background-color: ${colors.darkGradient}`};

  > video {
    height: calc(100vh - 180px);
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: black;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const StopModalContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LiveContainer = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 120px);
  box-sizing: border-box;
  margin-right: 370px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${colors.darkGradient};

  @media (max-width: 1000px) {
    padding: 0 0px 18px 0px;
    margin-right: 0;
  }
`;

const StartLiveForm = styled.div`
  position: fixed;
  right: 0;
  width: 370px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 12px 12px 12px;
  box-sizing: border-box;
  text-align: center;
  background: black;
  border-left: 1px solid ${colors.graySeparator2};

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Button = styled.div<{ activable: boolean; active?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  ${(p) => (p.active ? 'cursor: pointer;' : !p.activable ? 'cursor: pointer;' : 'cursor: auto;')};
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: ${(p) => (!p.activable ? colors.darkGrey : p.active ? colors.darkBlue : colors.darkGrey2)};
  color: ${(p) => (!p.activable ? colors.lightGrey9 : p.active ? colors.white : colors.lightGrey9)};
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 10px;
`;

const CenteredMessage = styled.div`
  height: 70vh;
  box-sizing: border-box;
  padding: 0 20px;
  display: grid;
  place-items: center;
  text-align: center;

  font-size: 20px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.lightGrey3};

  @media (max-width: 1000px) {
    height: 200px;
  }
`;

const LiveInfo = styled.div`
  position: absolute;
  z-index: 20;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  & > .viewers {
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    font-size: 12px;
    color: #f1f1f1;
    text-transform: uppercase;
  }

  & > .category {
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    font-size: 12px;
    color: #f1f1f1;
  }
`;

const Viewers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${colors.lightGrey9};
  padding: 2px 8px;
  border-radius: 25px;
  color: ${colors.white};
`;

const LiveBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  background: #d92d20;
  padding: 2px 8px 2px 8px;
`;

const ScreenshotCanvas = styled.canvas`
  display: none;
`;

const StreamerInfos = styled.div`
  background-color: ${colors.dark};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  box-sizing: border-box;

  & img {
    border-radius: 4px;
  }
`;

const Streamer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StreamerUsername = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: ${fonts.bold.weight};
  color: ${colors.white};
`;

const StreamerCategory = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.lightGrey9};
`;

const CustomButton = styled.button<{ accent?: boolean }>`
  padding: 8px 32px;
  color: ${({ accent }) => (accent ? colors.white : colors.darkGrey2)};
  background-color: ${({ accent }) => (accent ? colors.darkOrange1 : colors.white)};
  border: 1px solid ${({ accent }) => (accent ? colors.darkOrange3 : colors.lightGrey9)};
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const StopLiveOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
`;

const StopLiveModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 350px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    top: calc(100% - 60px);
    width: 100vw;
    border-radius: 0;
  }
`;

const CustomeButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const CloseStopLiveModal = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
