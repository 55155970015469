import React from 'react';
import styled from 'styled-components/macro';
import fonts from '../../themes/fonts.theme';
import colors from '../../themes/colors-v2.theme';
import Icons from '../style/icons.component';
import Text from '../style/text.component';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface TextInputProps {
  onChange?: (text: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  style?: Object;
  error?: boolean;
  small?: boolean;
  success?: boolean;
  errorMsg?: string;
  type?: 'text' | 'password';
  inputBorderRadius?: number;
  isPhoneInput?: boolean;
  width?: string;
}

export default function TextInput(props: TextInputProps) {
  return (
    <Container style={props.style}>
      {props.isPhoneInput ? (
        <PhoneInputWithCountrySelect
          value={props.value}
          onChange={(value) => props.onChange(value)}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
          numberInputProps={{
            small: props.small,
            maxLength: props?.maxLength,
            inputBorderRadius: props.inputBorderRadius,
            style: {
              borderColor: props.error ? colors.error : colors.grayBorder,
            },
          }}
          defaultCountry="FR"
          inputComponent={Input}
          international
          countryCallingCodeEditable={false}
        />
      ) : (
        <Input
          type={props.type || 'text'}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          maxLength={props?.maxLength}
          placeholder={props.placeholder}
          style={{
            borderColor: props.error ? colors.error : colors.grayBorder,
          }}
          width={props.width}
        />
      )}
      {props?.success && !props.errorMsg && (
        <IconRight>
          <Icons name="success-filled-green" width="20" height="20" />
        </IconRight>
      )}
      {props.errorMsg && (
        <IconRight>
          <Icons name="cross-filled-red" width="29" height="29" />
        </IconRight>
      )}
      {props?.error && (
        <IconRight>
          <Icons name="auth/error" width="13.33" height="13.33" />
        </IconRight>
      )}
      {props.errorMsg && (
        <ErrorText>
          <Text>{props.errorMsg}</Text>
        </ErrorText>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ width?: string }>`
  width: ${(props) => props.width || '100%'};
  border: 1px solid ${colors.grayBorder};
  outline: none;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${colors.darkGray};
  color: ${colors.white};
  font-size: 14px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  padding: 8px 12px;

  &::placeholder {
    font-weight: 400;
    font-size: 14px !important;
  }
`;

const ErrorText = styled.div`
  color: ${colors.danger};
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  position: absolute;
  top: 63px;
  z-index: 10;
`;

const IconRight = styled.div`
  position: absolute;
  top: 11px;
  right: 16px;
`;
