import React from 'react';
import styled from 'styled-components/macro';

import Image from './image.component';
import Text from './style/text.component';

import { Moderation } from '../models/moderation.model';
import { useLogin } from '../utils/auth.utils';
import fonts from '../themes/fonts.theme';
import colors from '../themes/colors-v2.theme';
import useLocale from '../utils/locale/locale.hook';

type PictureModerationProps = {
  src: string;
  width: string;
  height: string;
  style?: any;
  alt?: any;
  moderation: Moderation;
  onClick?: () => void;
  noText?: boolean; // !! TODO : à enlever quand on affichera plus le texte pour les devs
  optiWidth?: number;
};

const AUTHORIZED_ROLES = ['premium', 'admin'];

export default function PictureModeration(props: PictureModerationProps) {
  const locale = useLocale();
  const { me } = useLogin();

  // !! TODO : déterminer comment on affiche les photos "pending" (j'aurai tendance à dire qu'on les affiche quoiqu'il arrive)
  // if (props.moderation === "pending") {
  //   return (
  //     <Pending
  //       width={props.width}
  //       height={props.height}
  //       style={props.style}
  //       onClick={props.onClick}
  //     >
  //       {Number(props.width) > 150 ||
  //         (props.width == "100%" && !props.noText && (
  //           <ModerationText style={{ color: "black" }}>
  //             {props.moderation}
  //           </ModerationText>
  //         ))}
  //       {Number(props.width) > 150 ||
  //         (props.width == "100%" && (
  //           <Text style={{ color: "black" }}>Moderation pending</Text>
  //         ))}
  //     </Pending>
  //   );
  // }

  if (props.moderation === 'hardcore' && !AUTHORIZED_ROLES.includes(me.role)) {
    return (
      <HiddenPremium
        width={props.width}
        height={props.height}
        style={props.style}
        onClick={props.onClick}
        photoSrc={props.src}
      >
        <div className="premiumWarning" style={{ overflow: 'hidden' }}>
          <img alt="" className="premiumIcon" src="/assets/icons/user-profile/premium.svg" loading="lazy" />
          <span>{locale('picture.premium_warning')}</span>
        </div>
        <div className="blurredImage" />
      </HiddenPremium>
    );
  }

  // !! TODO : déterminer l'affichage pour les photos dont la modération est illégale
  if (props.moderation === 'illegal' || props.moderation === 'unAuthorized') {
    return (
      // <NotSafe
      //   width={props.width}
      //   height={props.height}
      //   style={props.style}
      //   onClick={props.onClick}
      // >
      //   {Number(props.width) > 150 ||
      //     (props.width == "100%" && !props.noText && (
      //       <ModerationText style={{ color: "black" }}>
      //         {props.moderation}
      //       </ModerationText>
      //     ))}
      //   {Number(props.width) > 150 ||
      //     (props.width == "100%" && (
      //       <Text style={{ color: "black" }}>Not safe</Text>
      //     ))}
      // </NotSafe>
      <>
        {Number(props.width) > 300 ||
          (props.width === '100%' && !props.noText && process.env.NODE_ENV === 'development' && (
            <ModerationText style={{ color: 'black' }}>{props.moderation}</ModerationText>
          ))}
        <Image
          alt={props.alt}
          src={'/assets/imgs/no-profile-picture.svg'}
          width={props.width}
          height={props.height}
          style={props.style}
          onClick={props.onClick}
          optiWidth={props.optiWidth}
        />
      </>
    );
  }

  return (
    <>
      <Image
        alt={props.alt}
        src={props.src}
        width={props.width}
        height={props.height}
        style={props.style}
        onClick={props.onClick}
        optiWidth={props.optiWidth}
      />
    </>
  );
}

const HiddenPremium = styled.div<{
  width: string;
  height: string;
  photoSrc: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  display: flex;
  overflow: hidden;

  & .blurredImage {
    opacity: 0.7;
    flex: 1;
    background-image: url(${(props) => props.photoSrc});
    filter: blur(18px);
    -webkit-filter: blur(18px);
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .premiumWarning {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 200px;

    font-size: 15px;
    font-family: ${fonts.regular.name};
    font-weight: ${fonts.regular.weight};
    color: ${colors.darkGrey2};

    & .premiumIcon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }
`;

const ModerationText = styled.div`
  position: absolute;
  color: black;
  font-size: 8px;
  top: 0px;
  left: 0px;
`;
