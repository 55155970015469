import React, { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components/macro';
import { initiatePrivateConversation, sendWoof } from '../../../../services/chat.service';
import colors from '../../../../themes/colors-v2.theme';
import fonts from '../../../../themes/fonts.theme';
import { MenusShowed } from '../../types/menus-showed.interface';
import useLocale from '../../../../utils/locale/locale.hook';
import { WoofItem } from '../../../../components/chat/woof-item.component';
import { useMediaQuery } from 'react-responsive';
import { UserModalTemplate } from '../user-modal.component';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { useDispatch } from 'react-redux';
import ModalSlice from '../../../../store/slices/modal.slice';
import { useNotification } from '../../../../utils/useNotification';

enum SendStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

interface ComponentProps {
  user: any;
  setMenusShowed?: React.Dispatch<React.SetStateAction<MenusShowed>>;
  setIsWoofMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
  ref?: any;
}

export default forwardRef(function WoofMenu({ user, setMenusShowed, setIsWoofMenuOpen, style, ref }: ComponentProps) {
  const [sendStatus, setSendStatus] = useState(SendStatus.IDLE);
  const [selectedWoofCategory, setSelectedWoofCategory] = useState<string>('');
  const locale = useLocale();
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const dispatch = useDispatch();
  const { triggerNotification } = useNotification();

  const sendWoofHandler = async (category: string) => {
    if (sendStatus === SendStatus.LOADING) {
      return;
    }

    setSendStatus(SendStatus.LOADING);
    const conversationRes = await initiatePrivateConversation(user.id);

    if (conversationRes?.conversationId) {
      const res = await sendWoof({
        conversationId: conversationRes?.conversationId,
        category,
      });

      if (res?.success) {
        triggerNotification({
          visible: true,
          title: locale('chat.woof.success'),
        });
        setMenusShowed && setMenusShowed({ quickshare: false, woof: false });
        setIsWoofMenuOpen && setIsWoofMenuOpen(false);
      } else {
        setSendStatus(SendStatus.ERROR);
      }
    } else if (!conversationRes.existing) {
      const res = await sendWoof({
        members: [
          {
            userId: user.id,
          },
        ],
        category,
      });
      if (res?.success) {
        triggerNotification({
          visible: true,
          title: locale('chat.woof.success'),
        });
        setMenusShowed && setMenusShowed({ quickshare: false, woof: false });
        setIsWoofMenuOpen && setIsWoofMenuOpen(false);
      } else {
        setSendStatus(SendStatus.ERROR);
      }
    }
  };

  useEffect(() => {
    sendWoofHandler(selectedWoofCategory);
  }, [selectedWoofCategory]);

  return (
    <UserModalTemplate
      ref={ref ?? null}
      isOpen={true}
      onClose={() => {
        setMenusShowed && setMenusShowed({ quickshare: false, woof: false });
        setIsWoofMenuOpen && setIsWoofMenuOpen(false);
      }}
      style={{
        width: isMobile ? '100%' : '465px',
        borderRadius: 16,
        height: isMobile ? '77dvh' : '52vh',
        background: colors.darkGray,
        position: 'absolute',
        paddingBottom: 16,
        bottom: 80,
        border: `1px solid ${colors.lightGrey10}`,
        overflow: 'hidden',
        ...style,
      }}
    >
      <Title>
        <PrimaryButton
          size="medium"
          style={{
            width: 54,
            height: 54,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'default',
          }}
        >
          <img src="/assets/imgs/woof/paw.svg" height={24} width={24} />
        </PrimaryButton>
        {locale('chat.woof.title')}
      </Title>
      <Content>
        <OptionWrapper>
          <WoofItem
            woofCategory="woof"
            img="/assets/imgs/woof/woof.svg"
            phraseKey="chat.woof.woof"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="pig"
            img="/assets/imgs/woof/pig.png"
            phraseKey="chat.woof.pig"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="thinking_about_you"
            img="/assets/imgs/woof/brain.png"
            phraseKey="chat.woof.think"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="nice_profile"
            img="/assets/imgs/woof/profile.png"
            phraseKey="chat.woof.profile"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="dirty_game"
            img="/assets/imgs/woof/eggplant.png"
            phraseKey="chat.woof.eggplant"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="get_me_pregnant"
            img="/assets/imgs/woof/avocado.png"
            phraseKey="chat.woof.avocado"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="vaccinated"
            img="/assets/imgs/woof/vaccine.png"
            phraseKey="chat.woof.vaccine"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="nice_beard"
            img="/assets/imgs/woof/beard.png"
            phraseKey="chat.woof.beard"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="more_picture_of_you"
            img="/assets/imgs/woof/ghost.png"
            phraseKey="chat.woof.more-pictures"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="hot_and_you"
            img="/assets/imgs/woof/hot.png"
            phraseKey="chat.woof.hot"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="thanks_for_fav"
            img="/assets/imgs/woof/star-favorite.png"
            phraseKey="chat.woof.favorite"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="adopt_me"
            img="/assets/imgs/woof/adopt-me.png"
            phraseKey="chat.woof.adopt_me"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="hi_where_are_you"
            img="/assets/imgs/woof/location.png"
            phraseKey="chat.woof.location"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="nice_ass"
            img="/assets/imgs/woof/peach.png"
            phraseKey="chat.woof.peach"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="pizza_together"
            img="/assets/imgs/woof/pizza.png"
            phraseKey="chat.woof.pizza"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="crush"
            img="/assets/imgs/woof/hearts.png"
            phraseKey="chat.woof.hearts"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="nice_abs"
            img="/assets/imgs/woof/choco.png"
            phraseKey="chat.woof.choco"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="love_at_first_sight"
            img="/assets/imgs/woof/coup-de-foudre.png"
            phraseKey="chat.woof.thunder"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="beer"
            img="/assets/imgs/woof/beer.png"
            phraseKey="chat.woof.beer"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
          <WoofItem
            woofCategory="play"
            img="/assets/imgs/woof/want-to-play.png"
            phraseKey="chat.woof.want_to_play"
            selectedWoofCategory={selectedWoofCategory}
            setSelectedWoofCategory={setSelectedWoofCategory}
          />
        </OptionWrapper>
      </Content>
    </UserModalTemplate>
  );
});

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
  height: 340px;
  @media (max-width: 1000px) {
    height: 64dvh;
  }
`;

const Title = styled.p`
  font-size: 18px;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${colors.white};
  padding: 16px 16px 18px 16px;
  margin: 0;
  align-self: flex-start;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    background-color: ${colors.white};
  }
`;

const MobileClose = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
  padding: 21px 17px;
  margin: 0;
  & p {
    margin: 0;
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  background: ${colors.backgroundLightGrey};
`;
