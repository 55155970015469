import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import CustomTooltip from '../../../components/miscellaneous/custom-tooltip.component';
import { guidesCategoriesLinks } from '../../../constants/constants';
import { useOpenStreetMapsContext } from '../../../context/openstreetmaps';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';
import useLocale from '../../../utils/locale/locale.hook';
import { debouncedCitySearch } from '../../../utils/search.utils';
import { CategoryButton } from './category-button.component';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { Divider } from '../../../components/miscellaneous/divider.component';
import { useScrollDisplayArrows } from '../../../utils/useScroll';

interface Props {
  openSubmitEventModal: () => void;
  activeKey?: string;
  coords: { lat: number; lng: number };
  setCoords: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
  mapOpen: boolean;
  setMapOpen: Dispatch<SetStateAction<boolean>>;
  links: Array<string>;
  setActiveCategory?: Dispatch<SetStateAction<string>>;
}

export const GuidesCategories = (props: Props) => {
  const { activeKey, setCoords, links, openSubmitEventModal } = props;
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [showOnMobile, setShowOnMobile] = useState(false);
  const [displayedLinks, setDisplayedLinks] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult | null>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<SearchResult<RawResult>[]>([]);
  const [activeLink, setActiveLink] = useState('');
  const locale = useLocale();
  const history = useHistory();
  const { location } = history;
  const locationInputRef = useRef(null);
  const filtersRef = useRef(null);
  const mapProvider = useOpenStreetMapsContext();

  const { displayLeftArrow, displayRightArrow, handleScroll, handleArrowClick } = useScrollDisplayArrows(filtersRef);
  const debouncedSearch = useRef(debouncedCitySearch(mapProvider, setAddressAutocomplete)).current;

  const handleAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setCoords({ lat: address.raw.lat, lng: address.raw.lon });
    setAddressAutocomplete([]);
  };

  const resetLocation = () => {
    setAddressAutocomplete([]);
    setSelectedAddress(null);
    if (locationInputRef !== null) {
      locationInputRef.current.value = '';
    }
  };

  // Hook permettant de déterminer quels sont les shortcuts à afficher
  useEffect(() => {
    const linksSelected = [];
    for (const link of links) {
      const linkSelected = guidesCategoriesLinks[link];
      if (linkSelected) {
        linksSelected.push(linkSelected);
      }
    }
    setDisplayedLinks(linksSelected);
  }, [links]);

  // Hook permettant de déterminer si un lien est actif ou non
  useEffect(() => {
    for (const key in guidesCategoriesLinks) {
      if (location.pathname.includes(guidesCategoriesLinks[key].href)) {
        setActiveLink(key);
        break;
      } else {
        setActiveLink('');
      }
    }
  }, [location]);

  useEffect(() => {
    setShowOnMobile(false);
  }, [location]);

  return (
    <Wrapper style={{ padding: '8px 4px' }}>
      <PrimaryButton isActive={true} onClick={openSubmitEventModal}>
        <img src="/assets/icons/guides/plus-white.svg" width={20} height={20} />
        {isDesktop ? locale('guide.create') : ''}
      </PrimaryButton>
      {isDesktop ? (
        <SearchInputContainer>
          <ResetLocationSearch width="9px" height="13.33px" src="/assets/icons/sidebar/navbar/explore-gray.svg" />
          <LocationTextInput
            placeholder={selectedAddress?.label ? selectedAddress.label : locale('guides.current-location')}
            onChange={(e) => debouncedSearch(e.target.value)}
            ref={locationInputRef}
          />

          {addressAutocomplete.length > 0 && (
            <Autocomplete>
              {addressAutocomplete.slice(0, 5).map((address, index) => (
                <Line key={`${address.label}-${index}`} onClick={() => handleAddress(address)}>
                  {address.label}
                </Line>
              ))}
            </Autocomplete>
          )}
        </SearchInputContainer>
      ) : null}

      <Divider />
      <FiltersListArrowWrapper onScroll={handleScroll}>
        {displayLeftArrow ? (
          <PrimaryButton
            size="medium"
            isActive={true}
            style={{ position: 'absolute', left: 0, top: 0, zIndex: 10, transform: 'rotate(180deg)' }}
            onClick={() => handleArrowClick('left')}
          >
            <img src="/assets/icons/nearby/chevron-right-white.svg" alt="chevron-left" width={6} height={6} />
          </PrimaryButton>
        ) : null}
        {displayRightArrow ? (
          <PrimaryButton
            size="medium"
            isActive={true}
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
            onClick={() => handleArrowClick('right')}
          >
            <img src="/assets/icons/nearby/chevron-right-white.svg" alt="chevron-right" width={6} height={6} />
          </PrimaryButton>
        ) : null}

        <FiltersListWrapper ref={filtersRef}>
          {displayedLinks.map((link, idx) => {
            const isActiveLink = link.key === activeLink || link.key === activeKey;
            return link.inactive ? (
              <CustomTooltip key={idx} title="Coming soon" placement="bottom" backgroundColor={colors.dark}>
                <PrimaryButton isActive={false}>{locale(link.label)}</PrimaryButton>
              </CustomTooltip>
            ) : (
              <StyledLink key={link.key} to={`/discover${link.href}`}>
                <CategoryButton
                  isActiveLink={isActiveLink}
                  text={locale(link.label)}
                  setActiveCategory={props.setActiveCategory}
                />
              </StyledLink>
            );
          })}
        </FiltersListWrapper>
      </FiltersListArrowWrapper>
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  gap: 8px;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  background-color: transparent;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.grayBorder};
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: visible;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    box-sizing: border-box;
    width: 100vw;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 0;
  }
`;

const FiltersListArrowWrapper = styled.div`
  position: relative;
  width: fit-content;

  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  // overflow: scroll;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FiltersListWrapper = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SearchInputContainer = styled.div`
  height: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-width: 150px;
  box-sizing: border-box;
  border-radius: 48px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #d93427, #f69101, #dfd902, #14aa5f, #2d56c5, #4c2e7b) border-box;
  color: white;
`;

const LocationTextInput = styled.input`
  padding-left: 24px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
  height: 23px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 5px;
  cursor: pointer;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};

  @media (max-width: 1000px) {
    top: 60px;
    width: 93%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: ${fonts.regular.weight};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;
