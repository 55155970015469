const colors = {
  primary: "#DD6A00",
  primaryHover: "#f39f00",
  secondary: "#6d2b93",
  success: "#44D57B",
  danger: "#e91817",
  warning: "#FFC107",
  info: "#17A2B8",
  dark: "black",
  darkHover: "#232323",
  darkGrey: "#393939",
  darkGreyHover: "#212121",
  grey: "#828282",
  light: "#F8F9FA",
  transparent: "transparent",
  transparentHover: "transparent",

  greyDark: "#4d5565",
  greyDark2: "#1d2024",
  greyDarkHover: "#343c4b",
  backgroundDark: "#090c0f",
  backgroundDarkHover: "#07090c",
  lightGrey1: "#5e6772",
  lightGrey2: "#a0a6b1",
  lightGrey3: "#cfd3da",
  lightGrey4: "#f0f1f3",
  lightGrey6: "#494e54",
};

export default colors;
