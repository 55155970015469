import { useEffect } from 'react';
import { useLogin } from './auth.utils';
import { useDispatch } from 'react-redux';
import ModalSlice from '../store/slices/modal.slice';

export function useRedirectUnsignedUser() {
  const dispatch = useDispatch();
  const { isLogin } = useLogin();

  useEffect(() => {
    if (!isLogin) {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: true,
          canCloseSignin: false,
        })
      );
    }

    return () => {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: false,
          canCloseSignin: true,
        })
      );
    };
  }, [isLogin, dispatch]);
}
