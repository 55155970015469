import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import "cropperjs/dist/cropper.css";

import Icons from "../../style/icons.component";
import Text from "../../style/text.component";
import PhotoCamera from "../photo-camera/photo-camera.component";

import useLocale from "../../../utils/locale/locale.hook";

import colors from "../../../themes/colors.theme";

type PhotoSelectionProps = {
  onClose: () => void;
  onDone: (file: File) => void;
};

export default function PhotoSelection(props: PhotoSelectionProps) {
  const locale = useLocale();
  const [photoCameraSelected, setPhotoCameraSelected] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const onClose = () => {
    props?.onClose();
  };

  const getFile = () => {
    fileInput.current.click();
  };

  const onDone = (file) => {
    props?.onClose();
    props?.onDone(file);
  };

  const onChange = async (e) => {
    onDone(e.target.files[0]);
  };

  if (photoCameraSelected)
    return <PhotoCamera onClose={onClose} onDone={(file) => onDone(file)} />;

  return (
    <Container>
      <input
        ref={fileInput}
        style={{ display: "none" }}
        type="file"
        id="myFile"
        name="filename"
        onChange={onChange}
        accept="image/png,image/jpeg,image/heic"
      />
      <Content>
        <Header>
          <IconButton onClick={onClose}>
            <Icons name="cross-outlined-black" width="25" height="25" />
          </IconButton>
        </Header>
        <Text dark h3>
          {locale("photo_selection.title")}
        </Text>

        <Selection>
          <Choice
            style={{ marginRight: 36 }}
            onClick={() => setPhotoCameraSelected(true)}
          >
            <Icons name="photo-outlined-black" width="78" height="64" />
            <Text dark style={{ marginTop: 10 }}>
              {locale("photo_selection.my_camera")}
            </Text>
          </Choice>
          <Choice onClick={() => getFile()}>
            <Icons name="file-outlined-black" width="78" height="64" />
            <Text dark style={{ marginTop: 10 }}>
              {locale("photo_selection.my_pc")}
            </Text>
          </Choice>
        </Selection>
        <Text
          color={colors.grey}
          style={{
            marginTop: 20,
            marginBottom: 58,
            textAlign: "center",
            padding: "10px",
          }}
        >
          {locale("signup.picture.description")}
        </Text>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Content = styled.div`
  background-color: ${colors.light};
  border-radius: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  height: 41px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  padding-top: 10px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 33px;
`;

const Choice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
