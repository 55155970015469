export const REPORT_MOTIVES = [
  {
    value: "illegal",
    phraseKey: "report.motives.illegal",
  },
  {
    value: "spam_scam",
    phraseKey: "report.motives.spam_scam",
  },
  {
    value: "bullying",
    phraseKey: "report.motives.bullying",
  },
  {
    value: "hate_discrimination",
    phraseKey: "report.motives.hate_discrimination",
  },
  {
    value: "nudity_pornography",
    phraseKey: "report.motives.nudity_pornography",
  },
  {
    value: "minor",
    phraseKey: "report.motives.minor",
  },
  {
    value: "identity_theft",
    phraseKey: "report.motives.identity_theft",
  },
];
